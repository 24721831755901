export type Maybe<T> = T | null
export type InputMaybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Leftovers: Record<number, number>
  ModulesSettingsThatCanUpdate: Record<keyof ServerModulesSettingsInput, boolean | 'not updated'>
  bigint: number
  jsonb: any
  numeric: number
  point: string
  smallint: number
  timestamptz: Date
  uuid: string
}

export enum ServerArchiveEmployeeTypes {
  Archive = 'archive',
  Check = 'check',
}

export enum ServerArchiveTypes {
  Archive = 'archive',
  Check = 'check',
  Restore = 'restore',
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type ServerBooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>
  _gt?: InputMaybe<Scalars['Boolean']>
  _gte?: InputMaybe<Scalars['Boolean']>
  _in?: InputMaybe<Array<Scalars['Boolean']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Boolean']>
  _lte?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<Scalars['Boolean']>
  _nin?: InputMaybe<Array<Scalars['Boolean']>>
}

export type ServerCheckAvailabilityForBookingResult = {
  code: Scalars['Int']
  message: Scalars['String']
  timeOptions: Maybe<Array<Maybe<ServerTimeOption>>>
}

export type ServerCheckFreeTimeForMonthResult = {
  code: Scalars['Int']
  dates: Maybe<Array<ServerFreeTimeForMonthDate>>
  message: Scalars['String']
}

export type ServerCheckNearestFreeTimeResult = {
  code: Scalars['Int']
  dates: Maybe<Array<ServerNearestFreeTimeDate>>
  message: Scalars['String']
}

export type ServerCheckPromoCodeResult = {
  code: Scalars['Int']
  message: Scalars['String']
  promoCode: Maybe<ServerGcfPromoCode>
}

export type ServerCheckboxCreateBulkReceiptsResult = {
  code: Scalars['Int']
  message: Scalars['String']
  receipts: Maybe<Array<ServerReceiptWithStatus>>
}

export type ServerCheckboxCreateReceiptResult = {
  code: Scalars['Int']
  message: Scalars['String']
  receipt: Maybe<ServerReceiptWithStatus>
}

export type ServerCheckboxGetShiftsResult = {
  code: Scalars['Int']
  message: Scalars['String']
  openedAt: Maybe<Scalars['String']>
}

export type ServerCloudFunctionResult = {
  code: Scalars['Int']
  message: Scalars['String']
}

export type ServerCreateBusinessResult = {
  branchId: Maybe<Scalars['Int']>
  businessId: Maybe<Scalars['Int']>
  email: Maybe<Scalars['String']>
  employeeId: Maybe<Scalars['Int']>
  message: Maybe<Scalars['String']>
}

export type ServerCreateReceiptDto = {
  goods: Array<ServerGood>
  isReturn?: InputMaybe<Scalars['Boolean']>
  payments: Array<ServerPaymentOption>
  receiptId: Scalars['String']
  relatedReceiptId?: InputMaybe<Scalars['String']>
}

export enum ServerCreateServiceReceiptType {
  BetweenAccounts = 'betweenAccounts',
  Transaction = 'transaction',
}

export type ServerCustomFirebaseTokenResponse = {
  customToken: Scalars['String']
}

export type ServerDeleteFileResult = {
  message: Maybe<Scalars['String']>
  ok: Scalars['Boolean']
}

export enum ServerDeleteTypes {
  Check = 'check',
  Delete = 'delete',
}

export type ServerDiscount = {
  type: ServerDiscountType
  value: Scalars['String']
}

export enum ServerDiscountType {
  Fixed = 'fixed',
  Percent = 'percent',
}

export type ServerExtendBillingResult = {
  billingId: Maybe<Scalars['uuid']>
  code: Scalars['Int']
  message: Scalars['String']
  paidMonths: Maybe<Scalars['Int']>
  planId: Maybe<Scalars['smallint']>
  value: Maybe<Scalars['Int']>
}

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type ServerFloatComparisonExp = {
  _eq?: InputMaybe<Scalars['Float']>
  _gt?: InputMaybe<Scalars['Float']>
  _gte?: InputMaybe<Scalars['Float']>
  _in?: InputMaybe<Array<Scalars['Float']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Float']>
  _lte?: InputMaybe<Scalars['Float']>
  _neq?: InputMaybe<Scalars['Float']>
  _nin?: InputMaybe<Array<Scalars['Float']>>
}

export type ServerFreeTimeForMonthDate = {
  date: Scalars['timestamptz']
  timeOptions: Array<ServerTimeOption>
}

export type ServerGcfPromoCode = {
  discount: Scalars['Int']
  id: Scalars['String']
  term: Scalars['Int']
}

export type ServerGetAvailableClientsResponse = {
  clientsIds: Array<Scalars['Int']>
}

export type ServerGetLeftoversOutput = {
  message: Maybe<Scalars['String']>
  productAmount: Maybe<Scalars['Leftovers']>
}

export type ServerGood = {
  code: Scalars['String']
  discount?: InputMaybe<ServerDiscount>
  name: Scalars['String']
  price: Scalars['Float']
  quantity: Scalars['Int']
  unit: Scalars['Int']
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type ServerIntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>
  _gt?: InputMaybe<Scalars['Int']>
  _gte?: InputMaybe<Scalars['Int']>
  _in?: InputMaybe<Array<Scalars['Int']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Int']>
  _lte?: InputMaybe<Scalars['Int']>
  _neq?: InputMaybe<Scalars['Int']>
  _nin?: InputMaybe<Array<Scalars['Int']>>
}

export enum ServerLanguages {
  En = 'en',
  Ru = 'ru',
  Uk = 'uk',
}

export enum ServerManipulateSuperAdminTypes {
  Check = 'check',
  Create = 'create',
  Delete = 'delete',
}

export type ServerModulesSettingsInput = {
  branches: Scalars['Boolean']
  checkbox: Scalars['Boolean']
  consumables: Scalars['Boolean']
  giftCards: Scalars['Boolean']
  managingEmployee: Scalars['Boolean']
  productsForSale: Scalars['Boolean']
  servicesForSale: Scalars['Boolean']
  subscriptions: Scalars['Boolean']
}

export type ServerNearestFreeTimeDate = {
  date: Scalars['timestamptz']
  employeeId: Maybe<Scalars['Int']>
  timeOptions: Array<ServerTimeOption>
}

export type ServerOutput = {
  message: Maybe<Scalars['String']>
}

export type ServerPaySalaryResult = {
  success: Scalars['Boolean']
}

export type ServerPaymentOption = {
  accountId: Scalars['Int']
  value: Scalars['Float']
}

export enum ServerPeriod {
  HalfYear = 'halfYear',
  Month = 'month',
  Year = 'year',
}

export enum ServerReceiptStatus {
  Cancellation = 'CANCELLATION',
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Done = 'DONE',
  Error = 'ERROR',
}

export type ServerReceiptWithStatus = {
  receiptId: Scalars['String']
  status: ServerReceiptStatus
}

export type ServerSalaryRateIssue = {
  employeeId: Scalars['Int']
  endPeriod: Scalars['timestamptz']
  salarySettingId: Scalars['Int']
  startPeriod: Scalars['timestamptz']
  value: Scalars['numeric']
}

export type ServerServiceImportDto = {
  id: Scalars['Int']
  locationIds: Array<Scalars['Int']>
  price: Scalars['Int']
}

export type ServerSetCurrentBusinessAsClientResult = {
  availableBranchIds: Maybe<Array<Scalars['Int']>>
  businessId: Maybe<Scalars['Int']>
  clientId: Maybe<Scalars['Int']>
  message: Maybe<Scalars['String']>
}

export type ServerSetCurrentBusinessResult = {
  availableBranchIds: Maybe<Array<Scalars['Int']>>
  businessId: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  message: Maybe<Scalars['String']>
}

export type ServerSetCurrentClaimsForReferrerResult = {
  referrerId: Maybe<Scalars['Int']>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type ServerStringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

export type ServerSuccessOutput = {
  success: Scalars['Boolean']
}

export type ServerTimeOption = {
  date: Scalars['timestamptz']
  employeeId: Maybe<Scalars['Int']>
  locationId: Scalars['Int']
  warningType: Maybe<Scalars['Int']>
}

export type ServerUpdateModulesSettingsResult = {
  code: Scalars['Int']
  message: Scalars['String']
  modulesSettingsThatCanUpdate: Maybe<Scalars['ModulesSettingsThatCanUpdate']>
}

export enum ServerUpdateModulesSettingsTypes {
  Check = 'check',
  Update = 'update',
}

export enum ServerUpdatePosTypes {
  Create = 'create',
  Update = 'update',
}

export type ServerUploadFileResult = {
  url: Scalars['String']
}

export type ServerValidateBookingInput = {
  bookingId?: InputMaybe<Scalars['Int']>
  branchId: Scalars['Int']
  clientId?: InputMaybe<Scalars['Int']>
  clientIds?: InputMaybe<Array<Scalars['Int']>>
  duration: Scalars['Int']
  employeeId?: InputMaybe<Scalars['Int']>
  isGroupBooking: Scalars['Boolean']
  locationId: Scalars['Int']
  serviceId?: InputMaybe<Scalars['Int']>
  serviceIds?: InputMaybe<Array<Scalars['Int']>>
  startDate: Scalars['timestamptz']
}

export type ServerValidateBookingOutput = {
  validationResults: Array<ServerValidationResult>
}

export type ServerValidationResult = {
  busyClientIds: Maybe<Array<Scalars['Int']>>
  type: Maybe<Scalars['Int']>
}

/** Используется в модуле Финансы для хранения информации о счетах (withdrawToAccount - счет для автоматического перевода. exchangeAmount - сумма, которую нужно оставить при переводе. maximumDebt - сумма максимального минуса на счету) */
export type ServerAccount = {
  /** An array relationship */
  accountEmployeeGroups: Array<ServerAccountEmployeeGroup>
  /** An array relationship */
  accountSoftwarePOS: Array<ServerAccountSoftwarePos>
  /** A computed field, executes function "countAccountBalance" */
  balance: Maybe<Scalars['numeric']>
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  cash: Scalars['Boolean']
  commission: Maybe<Scalars['Float']>
  createdAt: Scalars['timestamptz']
  /** An object relationship */
  defaultForBranch: Maybe<ServerBranch>
  description: Maybe<Scalars['String']>
  exchangeAmount: Maybe<Scalars['numeric']>
  forPayments: Scalars['Boolean']
  id: Scalars['Int']
  maximumDebt: Scalars['numeric']
  name: Scalars['String']
  /** An object relationship */
  withdrawalToAccount: Maybe<ServerAccount>
  withdrawalToAccountId: Maybe<Scalars['Int']>
}

/** Используется в модуле Финансы для хранения информации о счетах (withdrawToAccount - счет для автоматического перевода. exchangeAmount - сумма, которую нужно оставить при переводе. maximumDebt - сумма максимального минуса на счету) */
export type ServerAccountAccountEmployeeGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerAccountEmployeeGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerAccountEmployeeGroupOrderBy>>
  where?: InputMaybe<ServerAccountEmployeeGroupBoolExp>
}

/** Используется в модуле Финансы для хранения информации о счетах (withdrawToAccount - счет для автоматического перевода. exchangeAmount - сумма, которую нужно оставить при переводе. maximumDebt - сумма максимального минуса на счету) */
export type ServerAccountAccountSoftwarePosArgs = {
  distinct_on?: InputMaybe<Array<ServerAccountSoftwarePosSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerAccountSoftwarePosOrderBy>>
  where?: InputMaybe<ServerAccountSoftwarePosBoolExp>
}

/** columns and relationships of "accountEmployeeGroup" */
export type ServerAccountEmployeeGroup = {
  /** An object relationship */
  account: ServerAccount
  accountId: Scalars['Int']
  /** An object relationship */
  employeeGroup: ServerEmployeeGroup
  employeeGroupId: Scalars['Int']
  id: Scalars['Int']
}

/** order by aggregate values of table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupAggregateOrderBy = {
  avg?: InputMaybe<ServerAccountEmployeeGroupAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerAccountEmployeeGroupMaxOrderBy>
  min?: InputMaybe<ServerAccountEmployeeGroupMinOrderBy>
  stddev?: InputMaybe<ServerAccountEmployeeGroupStddevOrderBy>
  stddev_pop?: InputMaybe<ServerAccountEmployeeGroupStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerAccountEmployeeGroupStddevSampOrderBy>
  sum?: InputMaybe<ServerAccountEmployeeGroupSumOrderBy>
  var_pop?: InputMaybe<ServerAccountEmployeeGroupVarPopOrderBy>
  var_samp?: InputMaybe<ServerAccountEmployeeGroupVarSampOrderBy>
  variance?: InputMaybe<ServerAccountEmployeeGroupVarianceOrderBy>
}

/** input type for inserting array relation for remote table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupArrRelInsertInput = {
  data: Array<ServerAccountEmployeeGroupInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerAccountEmployeeGroupOnConflict>
}

/** order by avg() on columns of table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupAvgOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "accountEmployeeGroup". All fields are combined with a logical 'AND'. */
export type ServerAccountEmployeeGroupBoolExp = {
  _and?: InputMaybe<Array<ServerAccountEmployeeGroupBoolExp>>
  _not?: InputMaybe<ServerAccountEmployeeGroupBoolExp>
  _or?: InputMaybe<Array<ServerAccountEmployeeGroupBoolExp>>
  account?: InputMaybe<ServerAccountBoolExp>
  accountId?: InputMaybe<ServerIntComparisonExp>
  employeeGroup?: InputMaybe<ServerEmployeeGroupBoolExp>
  employeeGroupId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "accountEmployeeGroup" */
export enum ServerAccountEmployeeGroupConstraint {
  /** unique or primary key constraint on columns "id" */
  AccountEmployeeGroupPkey = 'accountEmployeeGroup_pkey',
}

/** input type for inserting data into table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupInsertInput = {
  account?: InputMaybe<ServerAccountObjRelInsertInput>
  accountId?: InputMaybe<Scalars['Int']>
  employeeGroup?: InputMaybe<ServerEmployeeGroupObjRelInsertInput>
  employeeGroupId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupMaxOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupMinOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerAccountEmployeeGroup>
}

/** on_conflict condition type for table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupOnConflict = {
  constraint: ServerAccountEmployeeGroupConstraint
  update_columns?: Array<ServerAccountEmployeeGroupUpdateColumn>
  where?: InputMaybe<ServerAccountEmployeeGroupBoolExp>
}

/** Ordering options when selecting data from "accountEmployeeGroup". */
export type ServerAccountEmployeeGroupOrderBy = {
  account?: InputMaybe<ServerAccountOrderBy>
  accountId?: InputMaybe<ServerOrderBy>
  employeeGroup?: InputMaybe<ServerEmployeeGroupOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** select columns of table "accountEmployeeGroup" */
export enum ServerAccountEmployeeGroupSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  EmployeeGroupId = 'employeeGroupId',
  /** column name */
  Id = 'id',
}

/** order by stddev() on columns of table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupStddevOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupStddevPopOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupStddevSampOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupSumOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "accountEmployeeGroup" (current role has no relevant permissions) */
export enum ServerAccountEmployeeGroupUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupVarPopOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupVarSampOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "accountEmployeeGroup" */
export type ServerAccountEmployeeGroupVarianceOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "accountSoftwarePOS" */
export type ServerAccountSoftwarePos = {
  /** An object relationship */
  account: ServerAccount
  accountId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  softwarePOS: ServerSoftwarePos
  softwarePOSId: Scalars['Int']
}

/** order by aggregate values of table "accountSoftwarePOS" */
export type ServerAccountSoftwarePosAggregateOrderBy = {
  avg?: InputMaybe<ServerAccountSoftwarePosAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerAccountSoftwarePosMaxOrderBy>
  min?: InputMaybe<ServerAccountSoftwarePosMinOrderBy>
  stddev?: InputMaybe<ServerAccountSoftwarePosStddevOrderBy>
  stddev_pop?: InputMaybe<ServerAccountSoftwarePosStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerAccountSoftwarePosStddevSampOrderBy>
  sum?: InputMaybe<ServerAccountSoftwarePosSumOrderBy>
  var_pop?: InputMaybe<ServerAccountSoftwarePosVarPopOrderBy>
  var_samp?: InputMaybe<ServerAccountSoftwarePosVarSampOrderBy>
  variance?: InputMaybe<ServerAccountSoftwarePosVarianceOrderBy>
}

/** order by avg() on columns of table "accountSoftwarePOS" */
export type ServerAccountSoftwarePosAvgOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "accountSoftwarePOS". All fields are combined with a logical 'AND'. */
export type ServerAccountSoftwarePosBoolExp = {
  _and?: InputMaybe<Array<ServerAccountSoftwarePosBoolExp>>
  _not?: InputMaybe<ServerAccountSoftwarePosBoolExp>
  _or?: InputMaybe<Array<ServerAccountSoftwarePosBoolExp>>
  account?: InputMaybe<ServerAccountBoolExp>
  accountId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  softwarePOS?: InputMaybe<ServerSoftwarePosBoolExp>
  softwarePOSId?: InputMaybe<ServerIntComparisonExp>
}

/** order by max() on columns of table "accountSoftwarePOS" */
export type ServerAccountSoftwarePosMaxOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "accountSoftwarePOS" */
export type ServerAccountSoftwarePosMinOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
}

/** Ordering options when selecting data from "accountSoftwarePOS". */
export type ServerAccountSoftwarePosOrderBy = {
  account?: InputMaybe<ServerAccountOrderBy>
  accountId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOS?: InputMaybe<ServerSoftwarePosOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "accountSoftwarePOS" */
export enum ServerAccountSoftwarePosSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  SoftwarePosId = 'softwarePOSId',
}

/** order by stddev() on columns of table "accountSoftwarePOS" */
export type ServerAccountSoftwarePosStddevOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "accountSoftwarePOS" */
export type ServerAccountSoftwarePosStddevPopOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "accountSoftwarePOS" */
export type ServerAccountSoftwarePosStddevSampOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
}

/** Streaming cursor of the table "accountSoftwarePOS" */
export type ServerAccountSoftwarePosStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerAccountSoftwarePosStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerAccountSoftwarePosStreamCursorValueInput = {
  accountId?: InputMaybe<Scalars['Int']>
  businessId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  softwarePOSId?: InputMaybe<Scalars['Int']>
}

/** order by sum() on columns of table "accountSoftwarePOS" */
export type ServerAccountSoftwarePosSumOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
}

/** order by var_pop() on columns of table "accountSoftwarePOS" */
export type ServerAccountSoftwarePosVarPopOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "accountSoftwarePOS" */
export type ServerAccountSoftwarePosVarSampOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "accountSoftwarePOS" */
export type ServerAccountSoftwarePosVarianceOrderBy = {
  accountId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
}

/** order by aggregate values of table "account" */
export type ServerAccountAggregateOrderBy = {
  avg?: InputMaybe<ServerAccountAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerAccountMaxOrderBy>
  min?: InputMaybe<ServerAccountMinOrderBy>
  stddev?: InputMaybe<ServerAccountStddevOrderBy>
  stddev_pop?: InputMaybe<ServerAccountStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerAccountStddevSampOrderBy>
  sum?: InputMaybe<ServerAccountSumOrderBy>
  var_pop?: InputMaybe<ServerAccountVarPopOrderBy>
  var_samp?: InputMaybe<ServerAccountVarSampOrderBy>
  variance?: InputMaybe<ServerAccountVarianceOrderBy>
}

/** input type for inserting array relation for remote table "account" */
export type ServerAccountArrRelInsertInput = {
  data: Array<ServerAccountInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerAccountOnConflict>
}

/** order by avg() on columns of table "account" */
export type ServerAccountAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  commission?: InputMaybe<ServerOrderBy>
  exchangeAmount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  withdrawalToAccountId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "account". All fields are combined with a logical 'AND'. */
export type ServerAccountBoolExp = {
  _and?: InputMaybe<Array<ServerAccountBoolExp>>
  _not?: InputMaybe<ServerAccountBoolExp>
  _or?: InputMaybe<Array<ServerAccountBoolExp>>
  accountEmployeeGroups?: InputMaybe<ServerAccountEmployeeGroupBoolExp>
  accountSoftwarePOS?: InputMaybe<ServerAccountSoftwarePosBoolExp>
  balance?: InputMaybe<ServerNumericComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  cash?: InputMaybe<ServerBooleanComparisonExp>
  commission?: InputMaybe<ServerFloatComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  defaultForBranch?: InputMaybe<ServerBranchBoolExp>
  description?: InputMaybe<ServerStringComparisonExp>
  exchangeAmount?: InputMaybe<ServerNumericComparisonExp>
  forPayments?: InputMaybe<ServerBooleanComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  maximumDebt?: InputMaybe<ServerNumericComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  withdrawalToAccount?: InputMaybe<ServerAccountBoolExp>
  withdrawalToAccountId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "account" */
export enum ServerAccountConstraint {
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'accounts_pkey',
}

/** input type for incrementing numeric columns in table "account" */
export type ServerAccountIncInput = {
  branchId?: InputMaybe<Scalars['Int']>
  commission?: InputMaybe<Scalars['Float']>
  exchangeAmount?: InputMaybe<Scalars['numeric']>
  maximumDebt?: InputMaybe<Scalars['numeric']>
  withdrawalToAccountId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "account" */
export type ServerAccountInsertInput = {
  accountEmployeeGroups?: InputMaybe<ServerAccountEmployeeGroupArrRelInsertInput>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  cash?: InputMaybe<Scalars['Boolean']>
  commission?: InputMaybe<Scalars['Float']>
  defaultForBranch?: InputMaybe<ServerBranchObjRelInsertInput>
  description?: InputMaybe<Scalars['String']>
  exchangeAmount?: InputMaybe<Scalars['numeric']>
  forPayments?: InputMaybe<Scalars['Boolean']>
  maximumDebt?: InputMaybe<Scalars['numeric']>
  name?: InputMaybe<Scalars['String']>
  withdrawalToAccount?: InputMaybe<ServerAccountObjRelInsertInput>
  withdrawalToAccountId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "account" */
export type ServerAccountMaxOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  commission?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  exchangeAmount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  withdrawalToAccountId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "account" */
export type ServerAccountMinOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  commission?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  exchangeAmount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  withdrawalToAccountId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "account" */
export type ServerAccountMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerAccount>
}

/** input type for inserting object relation for remote table "account" */
export type ServerAccountObjRelInsertInput = {
  data: ServerAccountInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerAccountOnConflict>
}

/** on_conflict condition type for table "account" */
export type ServerAccountOnConflict = {
  constraint: ServerAccountConstraint
  update_columns?: Array<ServerAccountUpdateColumn>
  where?: InputMaybe<ServerAccountBoolExp>
}

/** Ordering options when selecting data from "account". */
export type ServerAccountOrderBy = {
  accountEmployeeGroups_aggregate?: InputMaybe<ServerAccountEmployeeGroupAggregateOrderBy>
  accountSoftwarePOS_aggregate?: InputMaybe<ServerAccountSoftwarePosAggregateOrderBy>
  balance?: InputMaybe<ServerOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cash?: InputMaybe<ServerOrderBy>
  commission?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  defaultForBranch?: InputMaybe<ServerBranchOrderBy>
  description?: InputMaybe<ServerOrderBy>
  exchangeAmount?: InputMaybe<ServerOrderBy>
  forPayments?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  withdrawalToAccount?: InputMaybe<ServerAccountOrderBy>
  withdrawalToAccountId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: account */
export type ServerAccountPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "account" */
export enum ServerAccountSelectColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  Cash = 'cash',
  /** column name */
  Commission = 'commission',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  ExchangeAmount = 'exchangeAmount',
  /** column name */
  ForPayments = 'forPayments',
  /** column name */
  Id = 'id',
  /** column name */
  MaximumDebt = 'maximumDebt',
  /** column name */
  Name = 'name',
  /** column name */
  WithdrawalToAccountId = 'withdrawalToAccountId',
}

/** input type for updating data in table "account" */
export type ServerAccountSetInput = {
  branchId?: InputMaybe<Scalars['Int']>
  cash?: InputMaybe<Scalars['Boolean']>
  commission?: InputMaybe<Scalars['Float']>
  description?: InputMaybe<Scalars['String']>
  exchangeAmount?: InputMaybe<Scalars['numeric']>
  forPayments?: InputMaybe<Scalars['Boolean']>
  maximumDebt?: InputMaybe<Scalars['numeric']>
  name?: InputMaybe<Scalars['String']>
  withdrawalToAccountId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "account" */
export type ServerAccountStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  commission?: InputMaybe<ServerOrderBy>
  exchangeAmount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  withdrawalToAccountId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "account" */
export type ServerAccountStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  commission?: InputMaybe<ServerOrderBy>
  exchangeAmount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  withdrawalToAccountId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "account" */
export type ServerAccountStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  commission?: InputMaybe<ServerOrderBy>
  exchangeAmount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  withdrawalToAccountId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "account" */
export type ServerAccountSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  commission?: InputMaybe<ServerOrderBy>
  exchangeAmount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  withdrawalToAccountId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "account" */
export enum ServerAccountUpdateColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  Cash = 'cash',
  /** column name */
  Commission = 'commission',
  /** column name */
  Description = 'description',
  /** column name */
  ExchangeAmount = 'exchangeAmount',
  /** column name */
  ForPayments = 'forPayments',
  /** column name */
  MaximumDebt = 'maximumDebt',
  /** column name */
  Name = 'name',
  /** column name */
  WithdrawalToAccountId = 'withdrawalToAccountId',
}

export type ServerAccountUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerAccountIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerAccountSetInput>
  /** filter the rows which have to be updated */
  where: ServerAccountBoolExp
}

/** order by var_pop() on columns of table "account" */
export type ServerAccountVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  commission?: InputMaybe<ServerOrderBy>
  exchangeAmount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  withdrawalToAccountId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "account" */
export type ServerAccountVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  commission?: InputMaybe<ServerOrderBy>
  exchangeAmount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  withdrawalToAccountId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "account" */
export type ServerAccountVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  commission?: InputMaybe<ServerOrderBy>
  exchangeAmount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  withdrawalToAccountId?: InputMaybe<ServerOrderBy>
}

/** Адрес */
export type ServerAddress = {
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  city: Scalars['String']
  country: Scalars['String']
  house: Scalars['String']
  id: Scalars['Int']
  office: Maybe<Scalars['String']>
  postcode: Maybe<Scalars['String']>
  street: Scalars['String']
}

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type ServerAddressBoolExp = {
  _and?: InputMaybe<Array<ServerAddressBoolExp>>
  _not?: InputMaybe<ServerAddressBoolExp>
  _or?: InputMaybe<Array<ServerAddressBoolExp>>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  city?: InputMaybe<ServerStringComparisonExp>
  country?: InputMaybe<ServerStringComparisonExp>
  house?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  office?: InputMaybe<ServerStringComparisonExp>
  postcode?: InputMaybe<ServerStringComparisonExp>
  street?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "address" */
export enum ServerAddressConstraint {
  /** unique or primary key constraint on columns "id" */
  AddressPkey = 'address_pkey',
}

/** input type for inserting data into table "address" */
export type ServerAddressInsertInput = {
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  house?: InputMaybe<Scalars['String']>
  office?: InputMaybe<Scalars['String']>
  postcode?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "address" */
export type ServerAddressMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerAddress>
}

/** input type for inserting object relation for remote table "address" */
export type ServerAddressObjRelInsertInput = {
  data: ServerAddressInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerAddressOnConflict>
}

/** on_conflict condition type for table "address" */
export type ServerAddressOnConflict = {
  constraint: ServerAddressConstraint
  update_columns?: Array<ServerAddressUpdateColumn>
  where?: InputMaybe<ServerAddressBoolExp>
}

/** Ordering options when selecting data from "address". */
export type ServerAddressOrderBy = {
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  city?: InputMaybe<ServerOrderBy>
  country?: InputMaybe<ServerOrderBy>
  house?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  office?: InputMaybe<ServerOrderBy>
  postcode?: InputMaybe<ServerOrderBy>
  street?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: address */
export type ServerAddressPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "address" */
export enum ServerAddressSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  House = 'house',
  /** column name */
  Id = 'id',
  /** column name */
  Office = 'office',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  Street = 'street',
}

/** input type for updating data in table "address" */
export type ServerAddressSetInput = {
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  house?: InputMaybe<Scalars['String']>
  office?: InputMaybe<Scalars['String']>
  postcode?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
}

/** update columns of table "address" */
export enum ServerAddressUpdateColumn {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  House = 'house',
  /** column name */
  Office = 'office',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  Street = 'street',
}

export type ServerAddressUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerAddressSetInput>
  /** filter the rows which have to be updated */
  where: ServerAddressBoolExp
}

/** Банковские реквизиты. (edrpou - ЕГРПОУ, itn - ИНН, bic - МФО, vatCertificate - Свидетельство НДС)  */
export type ServerBankDetails = {
  accountNumber: Maybe<Scalars['String']>
  accountType: Maybe<Scalars['Int']>
  bic: Scalars['String']
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  edrpou: Scalars['String']
  iban: Scalars['String']
  id: Scalars['Int']
  itn: Maybe<Scalars['String']>
  name: Scalars['String']
  /** An object relationship */
  supplier: Maybe<ServerSupplier>
  supplierId: Maybe<Scalars['Int']>
  vatCertificate: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "bankDetails". All fields are combined with a logical 'AND'. */
export type ServerBankDetailsBoolExp = {
  _and?: InputMaybe<Array<ServerBankDetailsBoolExp>>
  _not?: InputMaybe<ServerBankDetailsBoolExp>
  _or?: InputMaybe<Array<ServerBankDetailsBoolExp>>
  accountNumber?: InputMaybe<ServerStringComparisonExp>
  accountType?: InputMaybe<ServerIntComparisonExp>
  bic?: InputMaybe<ServerStringComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  edrpou?: InputMaybe<ServerStringComparisonExp>
  iban?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  itn?: InputMaybe<ServerStringComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  supplier?: InputMaybe<ServerSupplierBoolExp>
  supplierId?: InputMaybe<ServerIntComparisonExp>
  vatCertificate?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "bankDetails" */
export enum ServerBankDetailsConstraint {
  /** unique or primary key constraint on columns "id" */
  BankDetailsPkey = 'bankDetails_pkey',
  /** unique or primary key constraint on columns "supplierId" */
  BankDetailsSupplierIdKey = 'bankDetails_supplierId_key',
}

/** input type for incrementing numeric columns in table "bankDetails" */
export type ServerBankDetailsIncInput = {
  accountType?: InputMaybe<Scalars['Int']>
  supplierId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "bankDetails" */
export type ServerBankDetailsInsertInput = {
  accountNumber?: InputMaybe<Scalars['String']>
  accountType?: InputMaybe<Scalars['Int']>
  bic?: InputMaybe<Scalars['String']>
  edrpou?: InputMaybe<Scalars['String']>
  iban?: InputMaybe<Scalars['String']>
  itn?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  supplier?: InputMaybe<ServerSupplierObjRelInsertInput>
  supplierId?: InputMaybe<Scalars['Int']>
  vatCertificate?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "bankDetails" */
export type ServerBankDetailsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBankDetails>
}

/** input type for inserting object relation for remote table "bankDetails" */
export type ServerBankDetailsObjRelInsertInput = {
  data: ServerBankDetailsInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerBankDetailsOnConflict>
}

/** on_conflict condition type for table "bankDetails" */
export type ServerBankDetailsOnConflict = {
  constraint: ServerBankDetailsConstraint
  update_columns?: Array<ServerBankDetailsUpdateColumn>
  where?: InputMaybe<ServerBankDetailsBoolExp>
}

/** Ordering options when selecting data from "bankDetails". */
export type ServerBankDetailsOrderBy = {
  accountNumber?: InputMaybe<ServerOrderBy>
  accountType?: InputMaybe<ServerOrderBy>
  bic?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  edrpou?: InputMaybe<ServerOrderBy>
  iban?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  itn?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  supplier?: InputMaybe<ServerSupplierOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
  vatCertificate?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: bankDetails */
export type ServerBankDetailsPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "bankDetails" */
export enum ServerBankDetailsSelectColumn {
  /** column name */
  AccountNumber = 'accountNumber',
  /** column name */
  AccountType = 'accountType',
  /** column name */
  Bic = 'bic',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Edrpou = 'edrpou',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  Itn = 'itn',
  /** column name */
  Name = 'name',
  /** column name */
  SupplierId = 'supplierId',
  /** column name */
  VatCertificate = 'vatCertificate',
}

/** input type for updating data in table "bankDetails" */
export type ServerBankDetailsSetInput = {
  accountNumber?: InputMaybe<Scalars['String']>
  accountType?: InputMaybe<Scalars['Int']>
  bic?: InputMaybe<Scalars['String']>
  edrpou?: InputMaybe<Scalars['String']>
  iban?: InputMaybe<Scalars['String']>
  itn?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  supplierId?: InputMaybe<Scalars['Int']>
  vatCertificate?: InputMaybe<Scalars['String']>
}

/** update columns of table "bankDetails" */
export enum ServerBankDetailsUpdateColumn {
  /** column name */
  AccountNumber = 'accountNumber',
  /** column name */
  AccountType = 'accountType',
  /** column name */
  Bic = 'bic',
  /** column name */
  Edrpou = 'edrpou',
  /** column name */
  Iban = 'iban',
  /** column name */
  Itn = 'itn',
  /** column name */
  Name = 'name',
  /** column name */
  SupplierId = 'supplierId',
  /** column name */
  VatCertificate = 'vatCertificate',
}

export type ServerBankDetailsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerBankDetailsIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerBankDetailsSetInput>
  /** filter the rows which have to be updated */
  where: ServerBankDetailsBoolExp
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type ServerBigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']>
  _gt?: InputMaybe<Scalars['bigint']>
  _gte?: InputMaybe<Scalars['bigint']>
  _in?: InputMaybe<Array<Scalars['bigint']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['bigint']>
  _lte?: InputMaybe<Scalars['bigint']>
  _neq?: InputMaybe<Scalars['bigint']>
  _nin?: InputMaybe<Array<Scalars['bigint']>>
}

/** Describes which business uses which plan */
export type ServerBillingInfo = {
  /** An object relationship */
  author: ServerEmployee
  /** An object relationship */
  business: ServerBusiness
  /** An object relationship */
  businessPromoCode: Maybe<ServerBusinessPromoCode>
  businessPromoCodeId: Maybe<Scalars['Int']>
  fromDate: Scalars['timestamptz']
  id: Scalars['uuid']
  paid: Scalars['Boolean']
  /** An object relationship */
  plan: ServerPlan
  planId: Scalars['Int']
  toDate: Scalars['timestamptz']
  /** 1 - deposit, 2 - payment */
  type: Scalars['smallint']
  value: Scalars['numeric']
}

/** order by aggregate values of table "billingInfo" */
export type ServerBillingInfoAggregateOrderBy = {
  avg?: InputMaybe<ServerBillingInfoAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerBillingInfoMaxOrderBy>
  min?: InputMaybe<ServerBillingInfoMinOrderBy>
  stddev?: InputMaybe<ServerBillingInfoStddevOrderBy>
  stddev_pop?: InputMaybe<ServerBillingInfoStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerBillingInfoStddevSampOrderBy>
  sum?: InputMaybe<ServerBillingInfoSumOrderBy>
  var_pop?: InputMaybe<ServerBillingInfoVarPopOrderBy>
  var_samp?: InputMaybe<ServerBillingInfoVarSampOrderBy>
  variance?: InputMaybe<ServerBillingInfoVarianceOrderBy>
}

/** order by avg() on columns of table "billingInfo" */
export type ServerBillingInfoAvgOrderBy = {
  businessPromoCodeId?: InputMaybe<ServerOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  /** 1 - deposit, 2 - payment */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "billingInfo". All fields are combined with a logical 'AND'. */
export type ServerBillingInfoBoolExp = {
  _and?: InputMaybe<Array<ServerBillingInfoBoolExp>>
  _not?: InputMaybe<ServerBillingInfoBoolExp>
  _or?: InputMaybe<Array<ServerBillingInfoBoolExp>>
  author?: InputMaybe<ServerEmployeeBoolExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessPromoCode?: InputMaybe<ServerBusinessPromoCodeBoolExp>
  businessPromoCodeId?: InputMaybe<ServerIntComparisonExp>
  fromDate?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerUuidComparisonExp>
  paid?: InputMaybe<ServerBooleanComparisonExp>
  plan?: InputMaybe<ServerPlanBoolExp>
  planId?: InputMaybe<ServerIntComparisonExp>
  toDate?: InputMaybe<ServerTimestamptzComparisonExp>
  type?: InputMaybe<ServerSmallintComparisonExp>
  value?: InputMaybe<ServerNumericComparisonExp>
}

/** order by max() on columns of table "billingInfo" */
export type ServerBillingInfoMaxOrderBy = {
  businessPromoCodeId?: InputMaybe<ServerOrderBy>
  fromDate?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  toDate?: InputMaybe<ServerOrderBy>
  /** 1 - deposit, 2 - payment */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "billingInfo" */
export type ServerBillingInfoMinOrderBy = {
  businessPromoCodeId?: InputMaybe<ServerOrderBy>
  fromDate?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  toDate?: InputMaybe<ServerOrderBy>
  /** 1 - deposit, 2 - payment */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** Ordering options when selecting data from "billingInfo". */
export type ServerBillingInfoOrderBy = {
  author?: InputMaybe<ServerEmployeeOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessPromoCode?: InputMaybe<ServerBusinessPromoCodeOrderBy>
  businessPromoCodeId?: InputMaybe<ServerOrderBy>
  fromDate?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  paid?: InputMaybe<ServerOrderBy>
  plan?: InputMaybe<ServerPlanOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  toDate?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** select columns of table "billingInfo" */
export enum ServerBillingInfoSelectColumn {
  /** column name */
  BusinessPromoCodeId = 'businessPromoCodeId',
  /** column name */
  FromDate = 'fromDate',
  /** column name */
  Id = 'id',
  /** column name */
  Paid = 'paid',
  /** column name */
  PlanId = 'planId',
  /** column name */
  ToDate = 'toDate',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value',
}

/** order by stddev() on columns of table "billingInfo" */
export type ServerBillingInfoStddevOrderBy = {
  businessPromoCodeId?: InputMaybe<ServerOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  /** 1 - deposit, 2 - payment */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "billingInfo" */
export type ServerBillingInfoStddevPopOrderBy = {
  businessPromoCodeId?: InputMaybe<ServerOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  /** 1 - deposit, 2 - payment */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "billingInfo" */
export type ServerBillingInfoStddevSampOrderBy = {
  businessPromoCodeId?: InputMaybe<ServerOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  /** 1 - deposit, 2 - payment */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "billingInfo" */
export type ServerBillingInfoSumOrderBy = {
  businessPromoCodeId?: InputMaybe<ServerOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  /** 1 - deposit, 2 - payment */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by var_pop() on columns of table "billingInfo" */
export type ServerBillingInfoVarPopOrderBy = {
  businessPromoCodeId?: InputMaybe<ServerOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  /** 1 - deposit, 2 - payment */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "billingInfo" */
export type ServerBillingInfoVarSampOrderBy = {
  businessPromoCodeId?: InputMaybe<ServerOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  /** 1 - deposit, 2 - payment */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "billingInfo" */
export type ServerBillingInfoVarianceOrderBy = {
  businessPromoCodeId?: InputMaybe<ServerOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  /** 1 - deposit, 2 - payment */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** Записи в календаре */
export type ServerBooking = {
  /** An object relationship */
  author: Maybe<ServerEmployee>
  authorId: Maybe<Scalars['Int']>
  /** An array relationship */
  bookingClients: Array<ServerBookingClient>
  /** An array relationship */
  bookingProducts: Array<ServerBookingProduct>
  /** An array relationship */
  bookingServices: Array<ServerBookingService>
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  businessId: Scalars['Int']
  canceledDate: Maybe<Scalars['timestamptz']>
  canceledReason: Maybe<Scalars['String']>
  /** An object relationship */
  client: Maybe<ServerClient>
  /** Клиент, если это обычная запись(не групповая) */
  clientId: Maybe<Scalars['Int']>
  clientNote: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  duration: Scalars['Int']
  /** An object relationship */
  employee: Maybe<ServerEmployee>
  employeeId: Maybe<Scalars['Int']>
  id: Scalars['Int']
  isGroupBooking: Scalars['Boolean']
  /** Checks if the booking is paid */
  isPaid: Maybe<Scalars['Boolean']>
  /** At least one item is paid/one client paid */
  isPartlyPaid: Maybe<Scalars['Boolean']>
  /** An object relationship */
  location: ServerLocation
  locationId: Scalars['Int']
  note: Maybe<Scalars['String']>
  /** An array relationship */
  notifications: Array<ServerNotification>
  /** An aggregate relationship */
  notifications_aggregate: ServerNotificationAggregate
  /** An object relationship */
  recurringBooking: Maybe<ServerRecurringBooking>
  recurringBookingId: Maybe<Scalars['Int']>
  /** An object relationship */
  review: Maybe<ServerReview>
  /** An array relationship */
  salaryIssues: Array<ServerSalaryIssue>
  /** An object relationship */
  service: Maybe<ServerService>
  /** Услуга, если это групповая запись */
  serviceId: Maybe<Scalars['Int']>
  startDate: Scalars['timestamptz']
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status: Maybe<Scalars['smallint']>
  /** An array relationship */
  transactions: Array<ServerTransaction>
  /** An aggregate relationship */
  transactions_aggregate: ServerTransactionAggregate
  /** An object relationship */
  unitedBooking: Maybe<ServerUnitedBooking>
  unitedId: Maybe<Scalars['Int']>
}

/** Записи в календаре */
export type ServerBookingBookingClientsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingClientSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingClientOrderBy>>
  where?: InputMaybe<ServerBookingClientBoolExp>
}

/** Записи в календаре */
export type ServerBookingBookingProductsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingProductOrderBy>>
  where?: InputMaybe<ServerBookingProductBoolExp>
}

/** Записи в календаре */
export type ServerBookingBookingServicesArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingServiceSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingServiceOrderBy>>
  where?: InputMaybe<ServerBookingServiceBoolExp>
}

/** Записи в календаре */
export type ServerBookingNotificationsArgs = {
  distinct_on?: InputMaybe<Array<ServerNotificationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerNotificationOrderBy>>
  where?: InputMaybe<ServerNotificationBoolExp>
}

/** Записи в календаре */
export type ServerBookingNotificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerNotificationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerNotificationOrderBy>>
  where?: InputMaybe<ServerNotificationBoolExp>
}

/** Записи в календаре */
export type ServerBookingSalaryIssuesArgs = {
  distinct_on?: InputMaybe<Array<ServerSalaryIssueSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSalaryIssueOrderBy>>
  where?: InputMaybe<ServerSalaryIssueBoolExp>
}

/** Записи в календаре */
export type ServerBookingTransactionsArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

/** Записи в календаре */
export type ServerBookingTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

/** Клиенты в групповых записях */
export type ServerBookingClient = {
  /** An object relationship */
  booking: ServerBooking
  bookingId: Scalars['Int']
  /** An object relationship */
  client: ServerClient
  clientId: Scalars['Int']
  id: Scalars['Int']
}

/** columns and relationships of "bookingClientSettings" */
export type ServerBookingClientSettings = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate: Scalars['Int']
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime: Scalars['Int']
  /** Дозвіл на створення записів клієнтом, якщо є гроші на рахунку. */
  allowedToCreateWithFunds: Scalars['Boolean']
  /** Дозвіл на створення записів клієнтом за абонементом. */
  allowedToCreateWithSubscription: Scalars['Boolean']
  /** Дозвіл на створення записів клієнтом без грошей на рахунку. Дозвіл створення записів анонімами */
  allowedToCreateWithoutFunds: Scalars['Boolean']
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  id: Scalars['Int']
  /** Дозвіл клієнту додавати коментар під час створення запису. */
  isClientNoteEnabled: Scalars['Boolean']
  /** Увімкнути відображення коментаря для клієнта після створення запису. */
  isNoteEnabled: Scalars['Boolean']
  /** Коментарь для клієнта після створення запису. */
  note: Maybe<Scalars['String']>
}

/** columns and relationships of "bookingClientSettingsView" */
export type ServerBookingClientSettingsView = {
  allowableTimeForEditingStartDate: Maybe<Scalars['Int']>
  allowableUpdateTime: Maybe<Scalars['Int']>
  allowedToCreateWithFunds: Maybe<Scalars['Boolean']>
  allowedToCreateWithSubscription: Maybe<Scalars['Boolean']>
  allowedToCreateWithoutFunds: Maybe<Scalars['Boolean']>
  businessId: Maybe<Scalars['Int']>
  isClientNoteEnabled: Maybe<Scalars['Boolean']>
  note: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "bookingClientSettingsView". All fields are combined with a logical 'AND'. */
export type ServerBookingClientSettingsViewBoolExp = {
  _and?: InputMaybe<Array<ServerBookingClientSettingsViewBoolExp>>
  _not?: InputMaybe<ServerBookingClientSettingsViewBoolExp>
  _or?: InputMaybe<Array<ServerBookingClientSettingsViewBoolExp>>
  allowableTimeForEditingStartDate?: InputMaybe<ServerIntComparisonExp>
  allowableUpdateTime?: InputMaybe<ServerIntComparisonExp>
  allowedToCreateWithFunds?: InputMaybe<ServerBooleanComparisonExp>
  allowedToCreateWithSubscription?: InputMaybe<ServerBooleanComparisonExp>
  allowedToCreateWithoutFunds?: InputMaybe<ServerBooleanComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  isClientNoteEnabled?: InputMaybe<ServerBooleanComparisonExp>
  note?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "bookingClientSettingsView". */
export type ServerBookingClientSettingsViewOrderBy = {
  allowableTimeForEditingStartDate?: InputMaybe<ServerOrderBy>
  allowableUpdateTime?: InputMaybe<ServerOrderBy>
  allowedToCreateWithFunds?: InputMaybe<ServerOrderBy>
  allowedToCreateWithSubscription?: InputMaybe<ServerOrderBy>
  allowedToCreateWithoutFunds?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  isClientNoteEnabled?: InputMaybe<ServerOrderBy>
  note?: InputMaybe<ServerOrderBy>
}

/** select columns of table "bookingClientSettingsView" */
export enum ServerBookingClientSettingsViewSelectColumn {
  /** column name */
  AllowableTimeForEditingStartDate = 'allowableTimeForEditingStartDate',
  /** column name */
  AllowableUpdateTime = 'allowableUpdateTime',
  /** column name */
  AllowedToCreateWithFunds = 'allowedToCreateWithFunds',
  /** column name */
  AllowedToCreateWithSubscription = 'allowedToCreateWithSubscription',
  /** column name */
  AllowedToCreateWithoutFunds = 'allowedToCreateWithoutFunds',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  IsClientNoteEnabled = 'isClientNoteEnabled',
  /** column name */
  Note = 'note',
}

/** Streaming cursor of the table "bookingClientSettingsView" */
export type ServerBookingClientSettingsViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBookingClientSettingsViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBookingClientSettingsViewStreamCursorValueInput = {
  allowableTimeForEditingStartDate?: InputMaybe<Scalars['Int']>
  allowableUpdateTime?: InputMaybe<Scalars['Int']>
  allowedToCreateWithFunds?: InputMaybe<Scalars['Boolean']>
  allowedToCreateWithSubscription?: InputMaybe<Scalars['Boolean']>
  allowedToCreateWithoutFunds?: InputMaybe<Scalars['Boolean']>
  businessId?: InputMaybe<Scalars['Int']>
  isClientNoteEnabled?: InputMaybe<Scalars['Boolean']>
  note?: InputMaybe<Scalars['String']>
}

/** order by aggregate values of table "bookingClientSettings" */
export type ServerBookingClientSettingsAggregateOrderBy = {
  avg?: InputMaybe<ServerBookingClientSettingsAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerBookingClientSettingsMaxOrderBy>
  min?: InputMaybe<ServerBookingClientSettingsMinOrderBy>
  stddev?: InputMaybe<ServerBookingClientSettingsStddevOrderBy>
  stddev_pop?: InputMaybe<ServerBookingClientSettingsStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerBookingClientSettingsStddevSampOrderBy>
  sum?: InputMaybe<ServerBookingClientSettingsSumOrderBy>
  var_pop?: InputMaybe<ServerBookingClientSettingsVarPopOrderBy>
  var_samp?: InputMaybe<ServerBookingClientSettingsVarSampOrderBy>
  variance?: InputMaybe<ServerBookingClientSettingsVarianceOrderBy>
}

/** order by avg() on columns of table "bookingClientSettings" */
export type ServerBookingClientSettingsAvgOrderBy = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<ServerOrderBy>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "bookingClientSettings". All fields are combined with a logical 'AND'. */
export type ServerBookingClientSettingsBoolExp = {
  _and?: InputMaybe<Array<ServerBookingClientSettingsBoolExp>>
  _not?: InputMaybe<ServerBookingClientSettingsBoolExp>
  _or?: InputMaybe<Array<ServerBookingClientSettingsBoolExp>>
  allowableTimeForEditingStartDate?: InputMaybe<ServerIntComparisonExp>
  allowableUpdateTime?: InputMaybe<ServerIntComparisonExp>
  allowedToCreateWithFunds?: InputMaybe<ServerBooleanComparisonExp>
  allowedToCreateWithSubscription?: InputMaybe<ServerBooleanComparisonExp>
  allowedToCreateWithoutFunds?: InputMaybe<ServerBooleanComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  isClientNoteEnabled?: InputMaybe<ServerBooleanComparisonExp>
  isNoteEnabled?: InputMaybe<ServerBooleanComparisonExp>
  note?: InputMaybe<ServerStringComparisonExp>
}

/** input type for incrementing numeric columns in table "bookingClientSettings" */
export type ServerBookingClientSettingsIncInput = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<Scalars['Int']>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "bookingClientSettings" */
export type ServerBookingClientSettingsMaxOrderBy = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<ServerOrderBy>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  /** Коментарь для клієнта після створення запису. */
  note?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "bookingClientSettings" */
export type ServerBookingClientSettingsMinOrderBy = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<ServerOrderBy>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  /** Коментарь для клієнта після створення запису. */
  note?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "bookingClientSettings" */
export type ServerBookingClientSettingsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBookingClientSettings>
}

/** Ordering options when selecting data from "bookingClientSettings". */
export type ServerBookingClientSettingsOrderBy = {
  allowableTimeForEditingStartDate?: InputMaybe<ServerOrderBy>
  allowableUpdateTime?: InputMaybe<ServerOrderBy>
  allowedToCreateWithFunds?: InputMaybe<ServerOrderBy>
  allowedToCreateWithSubscription?: InputMaybe<ServerOrderBy>
  allowedToCreateWithoutFunds?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  isClientNoteEnabled?: InputMaybe<ServerOrderBy>
  isNoteEnabled?: InputMaybe<ServerOrderBy>
  note?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: bookingClientSettings */
export type ServerBookingClientSettingsPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "bookingClientSettings" */
export enum ServerBookingClientSettingsSelectColumn {
  /** column name */
  AllowableTimeForEditingStartDate = 'allowableTimeForEditingStartDate',
  /** column name */
  AllowableUpdateTime = 'allowableUpdateTime',
  /** column name */
  AllowedToCreateWithFunds = 'allowedToCreateWithFunds',
  /** column name */
  AllowedToCreateWithSubscription = 'allowedToCreateWithSubscription',
  /** column name */
  AllowedToCreateWithoutFunds = 'allowedToCreateWithoutFunds',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  IsClientNoteEnabled = 'isClientNoteEnabled',
  /** column name */
  IsNoteEnabled = 'isNoteEnabled',
  /** column name */
  Note = 'note',
}

/** input type for updating data in table "bookingClientSettings" */
export type ServerBookingClientSettingsSetInput = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<Scalars['Int']>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<Scalars['Int']>
  /** Дозвіл на створення записів клієнтом, якщо є гроші на рахунку. */
  allowedToCreateWithFunds?: InputMaybe<Scalars['Boolean']>
  /** Дозвіл на створення записів клієнтом за абонементом. */
  allowedToCreateWithSubscription?: InputMaybe<Scalars['Boolean']>
  /** Дозвіл на створення записів клієнтом без грошей на рахунку. Дозвіл створення записів анонімами */
  allowedToCreateWithoutFunds?: InputMaybe<Scalars['Boolean']>
  /** Дозвіл клієнту додавати коментар під час створення запису. */
  isClientNoteEnabled?: InputMaybe<Scalars['Boolean']>
  /** Увімкнути відображення коментаря для клієнта після створення запису. */
  isNoteEnabled?: InputMaybe<Scalars['Boolean']>
  /** Коментарь для клієнта після створення запису. */
  note?: InputMaybe<Scalars['String']>
}

/** order by stddev() on columns of table "bookingClientSettings" */
export type ServerBookingClientSettingsStddevOrderBy = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<ServerOrderBy>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "bookingClientSettings" */
export type ServerBookingClientSettingsStddevPopOrderBy = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<ServerOrderBy>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "bookingClientSettings" */
export type ServerBookingClientSettingsStddevSampOrderBy = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<ServerOrderBy>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Streaming cursor of the table "bookingClientSettings" */
export type ServerBookingClientSettingsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBookingClientSettingsStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBookingClientSettingsStreamCursorValueInput = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<Scalars['Int']>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<Scalars['Int']>
  /** Дозвіл на створення записів клієнтом, якщо є гроші на рахунку. */
  allowedToCreateWithFunds?: InputMaybe<Scalars['Boolean']>
  /** Дозвіл на створення записів клієнтом за абонементом. */
  allowedToCreateWithSubscription?: InputMaybe<Scalars['Boolean']>
  /** Дозвіл на створення записів клієнтом без грошей на рахунку. Дозвіл створення записів анонімами */
  allowedToCreateWithoutFunds?: InputMaybe<Scalars['Boolean']>
  businessId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  /** Дозвіл клієнту додавати коментар під час створення запису. */
  isClientNoteEnabled?: InputMaybe<Scalars['Boolean']>
  /** Увімкнути відображення коментаря для клієнта після створення запису. */
  isNoteEnabled?: InputMaybe<Scalars['Boolean']>
  /** Коментарь для клієнта після створення запису. */
  note?: InputMaybe<Scalars['String']>
}

/** order by sum() on columns of table "bookingClientSettings" */
export type ServerBookingClientSettingsSumOrderBy = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<ServerOrderBy>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

export type ServerBookingClientSettingsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerBookingClientSettingsIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerBookingClientSettingsSetInput>
  /** filter the rows which have to be updated */
  where: ServerBookingClientSettingsBoolExp
}

/** order by var_pop() on columns of table "bookingClientSettings" */
export type ServerBookingClientSettingsVarPopOrderBy = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<ServerOrderBy>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "bookingClientSettings" */
export type ServerBookingClientSettingsVarSampOrderBy = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<ServerOrderBy>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "bookingClientSettings" */
export type ServerBookingClientSettingsVarianceOrderBy = {
  /** Мінімальний час, за який можна створити запис або змінити час запису. 0 – дозволено завжди. */
  allowableTimeForEditingStartDate?: InputMaybe<ServerOrderBy>
  /** Час, протягом якого клієнт може редагувати співробітника або скасувати запис. 0 – дозволено завжди. */
  allowableUpdateTime?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by aggregate values of table "bookingClient" */
export type ServerBookingClientAggregateOrderBy = {
  avg?: InputMaybe<ServerBookingClientAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerBookingClientMaxOrderBy>
  min?: InputMaybe<ServerBookingClientMinOrderBy>
  stddev?: InputMaybe<ServerBookingClientStddevOrderBy>
  stddev_pop?: InputMaybe<ServerBookingClientStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerBookingClientStddevSampOrderBy>
  sum?: InputMaybe<ServerBookingClientSumOrderBy>
  var_pop?: InputMaybe<ServerBookingClientVarPopOrderBy>
  var_samp?: InputMaybe<ServerBookingClientVarSampOrderBy>
  variance?: InputMaybe<ServerBookingClientVarianceOrderBy>
}

/** input type for inserting array relation for remote table "bookingClient" */
export type ServerBookingClientArrRelInsertInput = {
  data: Array<ServerBookingClientInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerBookingClientOnConflict>
}

/** order by avg() on columns of table "bookingClient" */
export type ServerBookingClientAvgOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "bookingClient". All fields are combined with a logical 'AND'. */
export type ServerBookingClientBoolExp = {
  _and?: InputMaybe<Array<ServerBookingClientBoolExp>>
  _not?: InputMaybe<ServerBookingClientBoolExp>
  _or?: InputMaybe<Array<ServerBookingClientBoolExp>>
  booking?: InputMaybe<ServerBookingBoolExp>
  bookingId?: InputMaybe<ServerIntComparisonExp>
  client?: InputMaybe<ServerClientBoolExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "bookingClient" */
export enum ServerBookingClientConstraint {
  /** unique or primary key constraint on columns "id" */
  GroupBookingClientsPkey = 'groupBookingClients_pkey',
  /** unique or primary key constraint on columns "clientId", "bookingId" */
  UniqueBookingClientPair = 'unique_booking_client_pair',
}

/** input type for inserting data into table "bookingClient" */
export type ServerBookingClientInsertInput = {
  booking?: InputMaybe<ServerBookingObjRelInsertInput>
  bookingId?: InputMaybe<Scalars['Int']>
  client?: InputMaybe<ServerClientObjRelInsertInput>
  clientId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "bookingClient" */
export type ServerBookingClientMaxOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "bookingClient" */
export type ServerBookingClientMinOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "bookingClient" */
export type ServerBookingClientMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBookingClient>
}

/** on_conflict condition type for table "bookingClient" */
export type ServerBookingClientOnConflict = {
  constraint: ServerBookingClientConstraint
  update_columns?: Array<ServerBookingClientUpdateColumn>
  where?: InputMaybe<ServerBookingClientBoolExp>
}

/** Ordering options when selecting data from "bookingClient". */
export type ServerBookingClientOrderBy = {
  booking?: InputMaybe<ServerBookingOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** select columns of table "bookingClient" */
export enum ServerBookingClientSelectColumn {
  /** column name */
  BookingId = 'bookingId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Id = 'id',
}

/** order by stddev() on columns of table "bookingClient" */
export type ServerBookingClientStddevOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "bookingClient" */
export type ServerBookingClientStddevPopOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "bookingClient" */
export type ServerBookingClientStddevSampOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "bookingClient" */
export type ServerBookingClientSumOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "bookingClient" (current role has no relevant permissions) */
export enum ServerBookingClientUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "bookingClient" */
export type ServerBookingClientVarPopOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "bookingClient" */
export type ServerBookingClientVarSampOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "bookingClient" */
export type ServerBookingClientVarianceOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "bookingProduct" */
export type ServerBookingProduct = {
  /** An object relationship */
  booking: ServerBooking
  bookingId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  product: ServerProduct
  productId: Scalars['Int']
  quantity: Scalars['Int']
}

/** order by aggregate values of table "bookingProduct" */
export type ServerBookingProductAggregateOrderBy = {
  avg?: InputMaybe<ServerBookingProductAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerBookingProductMaxOrderBy>
  min?: InputMaybe<ServerBookingProductMinOrderBy>
  stddev?: InputMaybe<ServerBookingProductStddevOrderBy>
  stddev_pop?: InputMaybe<ServerBookingProductStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerBookingProductStddevSampOrderBy>
  sum?: InputMaybe<ServerBookingProductSumOrderBy>
  var_pop?: InputMaybe<ServerBookingProductVarPopOrderBy>
  var_samp?: InputMaybe<ServerBookingProductVarSampOrderBy>
  variance?: InputMaybe<ServerBookingProductVarianceOrderBy>
}

/** input type for inserting array relation for remote table "bookingProduct" */
export type ServerBookingProductArrRelInsertInput = {
  data: Array<ServerBookingProductInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerBookingProductOnConflict>
}

/** order by avg() on columns of table "bookingProduct" */
export type ServerBookingProductAvgOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "bookingProduct". All fields are combined with a logical 'AND'. */
export type ServerBookingProductBoolExp = {
  _and?: InputMaybe<Array<ServerBookingProductBoolExp>>
  _not?: InputMaybe<ServerBookingProductBoolExp>
  _or?: InputMaybe<Array<ServerBookingProductBoolExp>>
  booking?: InputMaybe<ServerBookingBoolExp>
  bookingId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  product?: InputMaybe<ServerProductBoolExp>
  productId?: InputMaybe<ServerIntComparisonExp>
  quantity?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "bookingProduct" */
export enum ServerBookingProductConstraint {
  /** unique or primary key constraint on columns "id" */
  BookingProductPkey = 'bookingProduct_pkey',
}

/** input type for incrementing numeric columns in table "bookingProduct" */
export type ServerBookingProductIncInput = {
  bookingId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  productId?: InputMaybe<Scalars['Int']>
  quantity?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "bookingProduct" */
export type ServerBookingProductInsertInput = {
  booking?: InputMaybe<ServerBookingObjRelInsertInput>
  bookingId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  product?: InputMaybe<ServerProductObjRelInsertInput>
  productId?: InputMaybe<Scalars['Int']>
  quantity?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "bookingProduct" */
export type ServerBookingProductMaxOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "bookingProduct" */
export type ServerBookingProductMinOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "bookingProduct" */
export type ServerBookingProductMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBookingProduct>
}

/** on_conflict condition type for table "bookingProduct" */
export type ServerBookingProductOnConflict = {
  constraint: ServerBookingProductConstraint
  update_columns?: Array<ServerBookingProductUpdateColumn>
  where?: InputMaybe<ServerBookingProductBoolExp>
}

/** Ordering options when selecting data from "bookingProduct". */
export type ServerBookingProductOrderBy = {
  booking?: InputMaybe<ServerBookingOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  product?: InputMaybe<ServerProductOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: bookingProduct */
export type ServerBookingProductPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "bookingProduct" */
export enum ServerBookingProductSelectColumn {
  /** column name */
  BookingId = 'bookingId',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Quantity = 'quantity',
}

/** input type for updating data in table "bookingProduct" */
export type ServerBookingProductSetInput = {
  bookingId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  productId?: InputMaybe<Scalars['Int']>
  quantity?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "bookingProduct" */
export type ServerBookingProductStddevOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "bookingProduct" */
export type ServerBookingProductStddevPopOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "bookingProduct" */
export type ServerBookingProductStddevSampOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "bookingProduct" */
export type ServerBookingProductSumOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** update columns of table "bookingProduct" */
export enum ServerBookingProductUpdateColumn {
  /** column name */
  BookingId = 'bookingId',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Quantity = 'quantity',
}

export type ServerBookingProductUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerBookingProductIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerBookingProductSetInput>
  /** filter the rows which have to be updated */
  where: ServerBookingProductBoolExp
}

/** order by var_pop() on columns of table "bookingProduct" */
export type ServerBookingProductVarPopOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "bookingProduct" */
export type ServerBookingProductVarSampOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "bookingProduct" */
export type ServerBookingProductVarianceOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "bookingReviewView" */
export type ServerBookingReviewView = {
  branchId: Maybe<Scalars['Int']>
  /** An object relationship */
  business: Maybe<ServerBusiness>
  businessId: Maybe<Scalars['Int']>
  /** An object relationship */
  client: Maybe<ServerClient>
  clientFirstName: Maybe<Scalars['String']>
  clientId: Maybe<Scalars['Int']>
  clientLastName: Maybe<Scalars['String']>
  clientPhoto: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  employee: Maybe<ServerEmployee>
  employeeId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  rating: Maybe<Scalars['smallint']>
  text: Maybe<Scalars['String']>
}

/** order by aggregate values of table "bookingReviewView" */
export type ServerBookingReviewViewAggregateOrderBy = {
  avg?: InputMaybe<ServerBookingReviewViewAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerBookingReviewViewMaxOrderBy>
  min?: InputMaybe<ServerBookingReviewViewMinOrderBy>
  stddev?: InputMaybe<ServerBookingReviewViewStddevOrderBy>
  stddev_pop?: InputMaybe<ServerBookingReviewViewStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerBookingReviewViewStddevSampOrderBy>
  sum?: InputMaybe<ServerBookingReviewViewSumOrderBy>
  var_pop?: InputMaybe<ServerBookingReviewViewVarPopOrderBy>
  var_samp?: InputMaybe<ServerBookingReviewViewVarSampOrderBy>
  variance?: InputMaybe<ServerBookingReviewViewVarianceOrderBy>
}

/** order by avg() on columns of table "bookingReviewView" */
export type ServerBookingReviewViewAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rating?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "bookingReviewView". All fields are combined with a logical 'AND'. */
export type ServerBookingReviewViewBoolExp = {
  _and?: InputMaybe<Array<ServerBookingReviewViewBoolExp>>
  _not?: InputMaybe<ServerBookingReviewViewBoolExp>
  _or?: InputMaybe<Array<ServerBookingReviewViewBoolExp>>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  client?: InputMaybe<ServerClientBoolExp>
  clientFirstName?: InputMaybe<ServerStringComparisonExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  clientLastName?: InputMaybe<ServerStringComparisonExp>
  clientPhoto?: InputMaybe<ServerStringComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  rating?: InputMaybe<ServerSmallintComparisonExp>
  text?: InputMaybe<ServerStringComparisonExp>
}

/** order by max() on columns of table "bookingReviewView" */
export type ServerBookingReviewViewMaxOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  clientFirstName?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  clientLastName?: InputMaybe<ServerOrderBy>
  clientPhoto?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rating?: InputMaybe<ServerOrderBy>
  text?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "bookingReviewView" */
export type ServerBookingReviewViewMinOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  clientFirstName?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  clientLastName?: InputMaybe<ServerOrderBy>
  clientPhoto?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rating?: InputMaybe<ServerOrderBy>
  text?: InputMaybe<ServerOrderBy>
}

/** Ordering options when selecting data from "bookingReviewView". */
export type ServerBookingReviewViewOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  clientFirstName?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  clientLastName?: InputMaybe<ServerOrderBy>
  clientPhoto?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rating?: InputMaybe<ServerOrderBy>
  text?: InputMaybe<ServerOrderBy>
}

/** select columns of table "bookingReviewView" */
export enum ServerBookingReviewViewSelectColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  ClientFirstName = 'clientFirstName',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientLastName = 'clientLastName',
  /** column name */
  ClientPhoto = 'clientPhoto',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  Text = 'text',
}

/** order by stddev() on columns of table "bookingReviewView" */
export type ServerBookingReviewViewStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rating?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "bookingReviewView" */
export type ServerBookingReviewViewStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rating?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "bookingReviewView" */
export type ServerBookingReviewViewStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rating?: InputMaybe<ServerOrderBy>
}

/** Streaming cursor of the table "bookingReviewView" */
export type ServerBookingReviewViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBookingReviewViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBookingReviewViewStreamCursorValueInput = {
  branchId?: InputMaybe<Scalars['Int']>
  businessId?: InputMaybe<Scalars['Int']>
  clientFirstName?: InputMaybe<Scalars['String']>
  clientId?: InputMaybe<Scalars['Int']>
  clientLastName?: InputMaybe<Scalars['String']>
  clientPhoto?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  employeeId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  rating?: InputMaybe<Scalars['smallint']>
  text?: InputMaybe<Scalars['String']>
}

/** order by sum() on columns of table "bookingReviewView" */
export type ServerBookingReviewViewSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rating?: InputMaybe<ServerOrderBy>
}

/** order by var_pop() on columns of table "bookingReviewView" */
export type ServerBookingReviewViewVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rating?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "bookingReviewView" */
export type ServerBookingReviewViewVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rating?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "bookingReviewView" */
export type ServerBookingReviewViewVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rating?: InputMaybe<ServerOrderBy>
}

/** Услуги в обычной(не групповой) записи */
export type ServerBookingService = {
  /** An object relationship */
  booking: ServerBooking
  bookingId: Scalars['Int']
  businessId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  service: ServerService
  serviceId: Scalars['Int']
}

/** order by aggregate values of table "bookingService" */
export type ServerBookingServiceAggregateOrderBy = {
  avg?: InputMaybe<ServerBookingServiceAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerBookingServiceMaxOrderBy>
  min?: InputMaybe<ServerBookingServiceMinOrderBy>
  stddev?: InputMaybe<ServerBookingServiceStddevOrderBy>
  stddev_pop?: InputMaybe<ServerBookingServiceStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerBookingServiceStddevSampOrderBy>
  sum?: InputMaybe<ServerBookingServiceSumOrderBy>
  var_pop?: InputMaybe<ServerBookingServiceVarPopOrderBy>
  var_samp?: InputMaybe<ServerBookingServiceVarSampOrderBy>
  variance?: InputMaybe<ServerBookingServiceVarianceOrderBy>
}

/** input type for inserting array relation for remote table "bookingService" */
export type ServerBookingServiceArrRelInsertInput = {
  data: Array<ServerBookingServiceInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerBookingServiceOnConflict>
}

/** order by avg() on columns of table "bookingService" */
export type ServerBookingServiceAvgOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "bookingService". All fields are combined with a logical 'AND'. */
export type ServerBookingServiceBoolExp = {
  _and?: InputMaybe<Array<ServerBookingServiceBoolExp>>
  _not?: InputMaybe<ServerBookingServiceBoolExp>
  _or?: InputMaybe<Array<ServerBookingServiceBoolExp>>
  booking?: InputMaybe<ServerBookingBoolExp>
  bookingId?: InputMaybe<ServerIntComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  service?: InputMaybe<ServerServiceBoolExp>
  serviceId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "bookingService" */
export enum ServerBookingServiceConstraint {
  /** unique or primary key constraint on columns "id" */
  BookingServicesPkey = 'bookingServices_pkey',
}

/** input type for inserting data into table "bookingService" */
export type ServerBookingServiceInsertInput = {
  booking?: InputMaybe<ServerBookingObjRelInsertInput>
  bookingId?: InputMaybe<Scalars['Int']>
  service?: InputMaybe<ServerServiceObjRelInsertInput>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "bookingService" */
export type ServerBookingServiceMaxOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "bookingService" */
export type ServerBookingServiceMinOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "bookingService" */
export type ServerBookingServiceMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBookingService>
}

/** on_conflict condition type for table "bookingService" */
export type ServerBookingServiceOnConflict = {
  constraint: ServerBookingServiceConstraint
  update_columns?: Array<ServerBookingServiceUpdateColumn>
  where?: InputMaybe<ServerBookingServiceBoolExp>
}

/** Ordering options when selecting data from "bookingService". */
export type ServerBookingServiceOrderBy = {
  booking?: InputMaybe<ServerBookingOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  service?: InputMaybe<ServerServiceOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "bookingService" */
export enum ServerBookingServiceSelectColumn {
  /** column name */
  BookingId = 'bookingId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
}

/** order by stddev() on columns of table "bookingService" */
export type ServerBookingServiceStddevOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "bookingService" */
export type ServerBookingServiceStddevPopOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "bookingService" */
export type ServerBookingServiceStddevSampOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "bookingService" */
export type ServerBookingServiceSumOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "bookingService" (current role has no relevant permissions) */
export enum ServerBookingServiceUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "bookingService" */
export type ServerBookingServiceVarPopOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "bookingService" */
export type ServerBookingServiceVarSampOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "bookingService" */
export type ServerBookingServiceVarianceOrderBy = {
  bookingId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "bookingStatusView" */
export type ServerBookingStatusView = {
  businessId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  status: Maybe<Scalars['smallint']>
}

/** Boolean expression to filter rows from the table "bookingStatusView". All fields are combined with a logical 'AND'. */
export type ServerBookingStatusViewBoolExp = {
  _and?: InputMaybe<Array<ServerBookingStatusViewBoolExp>>
  _not?: InputMaybe<ServerBookingStatusViewBoolExp>
  _or?: InputMaybe<Array<ServerBookingStatusViewBoolExp>>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  status?: InputMaybe<ServerSmallintComparisonExp>
}

/** input type for incrementing numeric columns in table "bookingStatusView" */
export type ServerBookingStatusViewIncInput = {
  status?: InputMaybe<Scalars['smallint']>
}

/** response of any mutation on the table "bookingStatusView" */
export type ServerBookingStatusViewMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBookingStatusView>
}

/** Ordering options when selecting data from "bookingStatusView". */
export type ServerBookingStatusViewOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  status?: InputMaybe<ServerOrderBy>
}

/** select columns of table "bookingStatusView" */
export enum ServerBookingStatusViewSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "bookingStatusView" */
export type ServerBookingStatusViewSetInput = {
  status?: InputMaybe<Scalars['smallint']>
}

/** Streaming cursor of the table "bookingStatusView" */
export type ServerBookingStatusViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBookingStatusViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBookingStatusViewStreamCursorValueInput = {
  businessId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Scalars['smallint']>
}

export type ServerBookingStatusViewUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerBookingStatusViewIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerBookingStatusViewSetInput>
  /** filter the rows which have to be updated */
  where: ServerBookingStatusViewBoolExp
}

/** aggregated selection of "booking" */
export type ServerBookingAggregate = {
  aggregate: Maybe<ServerBookingAggregateFields>
  nodes: Array<ServerBooking>
}

export type ServerBookingAggregateBoolExp = {
  bool_and?: InputMaybe<ServerBookingAggregateBoolExpBoolAnd>
  bool_or?: InputMaybe<ServerBookingAggregateBoolExpBoolOr>
  count?: InputMaybe<ServerBookingAggregateBoolExpCount>
}

export type ServerBookingAggregateBoolExpBoolAnd = {
  arguments: ServerBookingSelectColumnBookingAggregateBoolExpBoolAndArgumentsColumns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerBookingBoolExp>
  predicate: ServerBooleanComparisonExp
}

export type ServerBookingAggregateBoolExpBoolOr = {
  arguments: ServerBookingSelectColumnBookingAggregateBoolExpBoolOrArgumentsColumns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerBookingBoolExp>
  predicate: ServerBooleanComparisonExp
}

export type ServerBookingAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServerBookingSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerBookingBoolExp>
  predicate: ServerIntComparisonExp
}

/** aggregate fields of "booking" */
export type ServerBookingAggregateFields = {
  avg: Maybe<ServerBookingAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerBookingMaxFields>
  min: Maybe<ServerBookingMinFields>
  stddev: Maybe<ServerBookingStddevFields>
  stddev_pop: Maybe<ServerBookingStddevPopFields>
  stddev_samp: Maybe<ServerBookingStddevSampFields>
  sum: Maybe<ServerBookingSumFields>
  var_pop: Maybe<ServerBookingVarPopFields>
  var_samp: Maybe<ServerBookingVarSampFields>
  variance: Maybe<ServerBookingVarianceFields>
}

/** aggregate fields of "booking" */
export type ServerBookingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerBookingSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "booking" */
export type ServerBookingAggregateOrderBy = {
  avg?: InputMaybe<ServerBookingAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerBookingMaxOrderBy>
  min?: InputMaybe<ServerBookingMinOrderBy>
  stddev?: InputMaybe<ServerBookingStddevOrderBy>
  stddev_pop?: InputMaybe<ServerBookingStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerBookingStddevSampOrderBy>
  sum?: InputMaybe<ServerBookingSumOrderBy>
  var_pop?: InputMaybe<ServerBookingVarPopOrderBy>
  var_samp?: InputMaybe<ServerBookingVarSampOrderBy>
  variance?: InputMaybe<ServerBookingVarianceOrderBy>
}

/** input type for inserting array relation for remote table "booking" */
export type ServerBookingArrRelInsertInput = {
  data: Array<ServerBookingInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerBookingOnConflict>
}

/** aggregate avg on columns */
export type ServerBookingAvgFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  /** Клиент, если это обычная запись(не групповая) */
  clientId: Maybe<Scalars['Float']>
  duration: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  locationId: Maybe<Scalars['Float']>
  recurringBookingId: Maybe<Scalars['Float']>
  /** Услуга, если это групповая запись */
  serviceId: Maybe<Scalars['Float']>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status: Maybe<Scalars['Float']>
  unitedId: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "booking" */
export type ServerBookingAvgOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<ServerOrderBy>
  duration?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  recurringBookingId?: InputMaybe<ServerOrderBy>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<ServerOrderBy>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status?: InputMaybe<ServerOrderBy>
  unitedId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "booking". All fields are combined with a logical 'AND'. */
export type ServerBookingBoolExp = {
  _and?: InputMaybe<Array<ServerBookingBoolExp>>
  _not?: InputMaybe<ServerBookingBoolExp>
  _or?: InputMaybe<Array<ServerBookingBoolExp>>
  author?: InputMaybe<ServerEmployeeBoolExp>
  authorId?: InputMaybe<ServerIntComparisonExp>
  bookingClients?: InputMaybe<ServerBookingClientBoolExp>
  bookingProducts?: InputMaybe<ServerBookingProductBoolExp>
  bookingServices?: InputMaybe<ServerBookingServiceBoolExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  canceledDate?: InputMaybe<ServerTimestamptzComparisonExp>
  canceledReason?: InputMaybe<ServerStringComparisonExp>
  client?: InputMaybe<ServerClientBoolExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  clientNote?: InputMaybe<ServerStringComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  duration?: InputMaybe<ServerIntComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  isGroupBooking?: InputMaybe<ServerBooleanComparisonExp>
  isPaid?: InputMaybe<ServerBooleanComparisonExp>
  isPartlyPaid?: InputMaybe<ServerBooleanComparisonExp>
  location?: InputMaybe<ServerLocationBoolExp>
  locationId?: InputMaybe<ServerIntComparisonExp>
  note?: InputMaybe<ServerStringComparisonExp>
  notifications?: InputMaybe<ServerNotificationBoolExp>
  notifications_aggregate?: InputMaybe<ServerNotificationAggregateBoolExp>
  recurringBooking?: InputMaybe<ServerRecurringBookingBoolExp>
  recurringBookingId?: InputMaybe<ServerIntComparisonExp>
  review?: InputMaybe<ServerReviewBoolExp>
  salaryIssues?: InputMaybe<ServerSalaryIssueBoolExp>
  service?: InputMaybe<ServerServiceBoolExp>
  serviceId?: InputMaybe<ServerIntComparisonExp>
  startDate?: InputMaybe<ServerTimestamptzComparisonExp>
  status?: InputMaybe<ServerSmallintComparisonExp>
  transactions?: InputMaybe<ServerTransactionBoolExp>
  transactions_aggregate?: InputMaybe<ServerTransactionAggregateBoolExp>
  unitedBooking?: InputMaybe<ServerUnitedBookingBoolExp>
  unitedId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "booking" */
export enum ServerBookingConstraint {
  /** unique or primary key constraint on columns "id" */
  BookingsPkey = 'bookings_pkey',
}

/** input type for incrementing numeric columns in table "booking" */
export type ServerBookingIncInput = {
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<Scalars['Int']>
  duration?: InputMaybe<Scalars['Int']>
  employeeId?: InputMaybe<Scalars['Int']>
  locationId?: InputMaybe<Scalars['Int']>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "booking" */
export type ServerBookingInsertInput = {
  author?: InputMaybe<ServerEmployeeObjRelInsertInput>
  bookingClients?: InputMaybe<ServerBookingClientArrRelInsertInput>
  bookingProducts?: InputMaybe<ServerBookingProductArrRelInsertInput>
  bookingServices?: InputMaybe<ServerBookingServiceArrRelInsertInput>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  client?: InputMaybe<ServerClientObjRelInsertInput>
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<Scalars['Int']>
  duration?: InputMaybe<Scalars['Int']>
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employeeId?: InputMaybe<Scalars['Int']>
  isGroupBooking?: InputMaybe<Scalars['Boolean']>
  location?: InputMaybe<ServerLocationObjRelInsertInput>
  locationId?: InputMaybe<Scalars['Int']>
  note?: InputMaybe<Scalars['String']>
  notifications?: InputMaybe<ServerNotificationArrRelInsertInput>
  recurringBooking?: InputMaybe<ServerRecurringBookingObjRelInsertInput>
  review?: InputMaybe<ServerReviewObjRelInsertInput>
  salaryIssues?: InputMaybe<ServerSalaryIssueArrRelInsertInput>
  service?: InputMaybe<ServerServiceObjRelInsertInput>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['timestamptz']>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status?: InputMaybe<Scalars['smallint']>
  transactions?: InputMaybe<ServerTransactionArrRelInsertInput>
  unitedBooking?: InputMaybe<ServerUnitedBookingObjRelInsertInput>
  unitedId?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type ServerBookingMaxFields = {
  authorId: Maybe<Scalars['Int']>
  branchId: Maybe<Scalars['Int']>
  businessId: Maybe<Scalars['Int']>
  canceledDate: Maybe<Scalars['timestamptz']>
  canceledReason: Maybe<Scalars['String']>
  /** Клиент, если это обычная запись(не групповая) */
  clientId: Maybe<Scalars['Int']>
  clientNote: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['timestamptz']>
  duration: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  locationId: Maybe<Scalars['Int']>
  note: Maybe<Scalars['String']>
  recurringBookingId: Maybe<Scalars['Int']>
  /** Услуга, если это групповая запись */
  serviceId: Maybe<Scalars['Int']>
  startDate: Maybe<Scalars['timestamptz']>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status: Maybe<Scalars['smallint']>
  unitedId: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "booking" */
export type ServerBookingMaxOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  canceledDate?: InputMaybe<ServerOrderBy>
  canceledReason?: InputMaybe<ServerOrderBy>
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<ServerOrderBy>
  clientNote?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  duration?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  note?: InputMaybe<ServerOrderBy>
  recurringBookingId?: InputMaybe<ServerOrderBy>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<ServerOrderBy>
  startDate?: InputMaybe<ServerOrderBy>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status?: InputMaybe<ServerOrderBy>
  unitedId?: InputMaybe<ServerOrderBy>
}

/** aggregate min on columns */
export type ServerBookingMinFields = {
  authorId: Maybe<Scalars['Int']>
  branchId: Maybe<Scalars['Int']>
  businessId: Maybe<Scalars['Int']>
  canceledDate: Maybe<Scalars['timestamptz']>
  canceledReason: Maybe<Scalars['String']>
  /** Клиент, если это обычная запись(не групповая) */
  clientId: Maybe<Scalars['Int']>
  clientNote: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['timestamptz']>
  duration: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  locationId: Maybe<Scalars['Int']>
  note: Maybe<Scalars['String']>
  recurringBookingId: Maybe<Scalars['Int']>
  /** Услуга, если это групповая запись */
  serviceId: Maybe<Scalars['Int']>
  startDate: Maybe<Scalars['timestamptz']>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status: Maybe<Scalars['smallint']>
  unitedId: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "booking" */
export type ServerBookingMinOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  canceledDate?: InputMaybe<ServerOrderBy>
  canceledReason?: InputMaybe<ServerOrderBy>
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<ServerOrderBy>
  clientNote?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  duration?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  note?: InputMaybe<ServerOrderBy>
  recurringBookingId?: InputMaybe<ServerOrderBy>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<ServerOrderBy>
  startDate?: InputMaybe<ServerOrderBy>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status?: InputMaybe<ServerOrderBy>
  unitedId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "booking" */
export type ServerBookingMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBooking>
}

/** input type for inserting object relation for remote table "booking" */
export type ServerBookingObjRelInsertInput = {
  data: ServerBookingInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerBookingOnConflict>
}

/** on_conflict condition type for table "booking" */
export type ServerBookingOnConflict = {
  constraint: ServerBookingConstraint
  update_columns?: Array<ServerBookingUpdateColumn>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** Ordering options when selecting data from "booking". */
export type ServerBookingOrderBy = {
  author?: InputMaybe<ServerEmployeeOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  bookingClients_aggregate?: InputMaybe<ServerBookingClientAggregateOrderBy>
  bookingProducts_aggregate?: InputMaybe<ServerBookingProductAggregateOrderBy>
  bookingServices_aggregate?: InputMaybe<ServerBookingServiceAggregateOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  canceledDate?: InputMaybe<ServerOrderBy>
  canceledReason?: InputMaybe<ServerOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  clientNote?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  duration?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  isGroupBooking?: InputMaybe<ServerOrderBy>
  isPaid?: InputMaybe<ServerOrderBy>
  isPartlyPaid?: InputMaybe<ServerOrderBy>
  location?: InputMaybe<ServerLocationOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  note?: InputMaybe<ServerOrderBy>
  notifications_aggregate?: InputMaybe<ServerNotificationAggregateOrderBy>
  recurringBooking?: InputMaybe<ServerRecurringBookingOrderBy>
  recurringBookingId?: InputMaybe<ServerOrderBy>
  review?: InputMaybe<ServerReviewOrderBy>
  salaryIssues_aggregate?: InputMaybe<ServerSalaryIssueAggregateOrderBy>
  service?: InputMaybe<ServerServiceOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  startDate?: InputMaybe<ServerOrderBy>
  status?: InputMaybe<ServerOrderBy>
  transactions_aggregate?: InputMaybe<ServerTransactionAggregateOrderBy>
  unitedBooking?: InputMaybe<ServerUnitedBookingOrderBy>
  unitedId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: booking */
export type ServerBookingPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "booking" */
export enum ServerBookingSelectColumn {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  CanceledDate = 'canceledDate',
  /** column name */
  CanceledReason = 'canceledReason',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientNote = 'clientNote',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Duration = 'duration',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  Id = 'id',
  /** column name */
  IsGroupBooking = 'isGroupBooking',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Note = 'note',
  /** column name */
  RecurringBookingId = 'recurringBookingId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Status = 'status',
  /** column name */
  UnitedId = 'unitedId',
}

/** select "booking_aggregate_bool_exp_bool_and_arguments_columns" columns of table "booking" */
export enum ServerBookingSelectColumnBookingAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsGroupBooking = 'isGroupBooking',
}

/** select "booking_aggregate_bool_exp_bool_or_arguments_columns" columns of table "booking" */
export enum ServerBookingSelectColumnBookingAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsGroupBooking = 'isGroupBooking',
}

/** input type for updating data in table "booking" */
export type ServerBookingSetInput = {
  canceledDate?: InputMaybe<Scalars['timestamptz']>
  canceledReason?: InputMaybe<Scalars['String']>
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<Scalars['Int']>
  duration?: InputMaybe<Scalars['Int']>
  employeeId?: InputMaybe<Scalars['Int']>
  isGroupBooking?: InputMaybe<Scalars['Boolean']>
  locationId?: InputMaybe<Scalars['Int']>
  note?: InputMaybe<Scalars['String']>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type ServerBookingStddevFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  /** Клиент, если это обычная запись(не групповая) */
  clientId: Maybe<Scalars['Float']>
  duration: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  locationId: Maybe<Scalars['Float']>
  recurringBookingId: Maybe<Scalars['Float']>
  /** Услуга, если это групповая запись */
  serviceId: Maybe<Scalars['Float']>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status: Maybe<Scalars['Float']>
  unitedId: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "booking" */
export type ServerBookingStddevOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<ServerOrderBy>
  duration?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  recurringBookingId?: InputMaybe<ServerOrderBy>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<ServerOrderBy>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status?: InputMaybe<ServerOrderBy>
  unitedId?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_pop on columns */
export type ServerBookingStddevPopFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  /** Клиент, если это обычная запись(не групповая) */
  clientId: Maybe<Scalars['Float']>
  duration: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  locationId: Maybe<Scalars['Float']>
  recurringBookingId: Maybe<Scalars['Float']>
  /** Услуга, если это групповая запись */
  serviceId: Maybe<Scalars['Float']>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status: Maybe<Scalars['Float']>
  unitedId: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "booking" */
export type ServerBookingStddevPopOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<ServerOrderBy>
  duration?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  recurringBookingId?: InputMaybe<ServerOrderBy>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<ServerOrderBy>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status?: InputMaybe<ServerOrderBy>
  unitedId?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_samp on columns */
export type ServerBookingStddevSampFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  /** Клиент, если это обычная запись(не групповая) */
  clientId: Maybe<Scalars['Float']>
  duration: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  locationId: Maybe<Scalars['Float']>
  recurringBookingId: Maybe<Scalars['Float']>
  /** Услуга, если это групповая запись */
  serviceId: Maybe<Scalars['Float']>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status: Maybe<Scalars['Float']>
  unitedId: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "booking" */
export type ServerBookingStddevSampOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<ServerOrderBy>
  duration?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  recurringBookingId?: InputMaybe<ServerOrderBy>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<ServerOrderBy>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status?: InputMaybe<ServerOrderBy>
  unitedId?: InputMaybe<ServerOrderBy>
}

/** aggregate sum on columns */
export type ServerBookingSumFields = {
  authorId: Maybe<Scalars['Int']>
  branchId: Maybe<Scalars['Int']>
  businessId: Maybe<Scalars['Int']>
  /** Клиент, если это обычная запись(не групповая) */
  clientId: Maybe<Scalars['Int']>
  duration: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  locationId: Maybe<Scalars['Int']>
  recurringBookingId: Maybe<Scalars['Int']>
  /** Услуга, если это групповая запись */
  serviceId: Maybe<Scalars['Int']>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status: Maybe<Scalars['smallint']>
  unitedId: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "booking" */
export type ServerBookingSumOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<ServerOrderBy>
  duration?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  recurringBookingId?: InputMaybe<ServerOrderBy>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<ServerOrderBy>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status?: InputMaybe<ServerOrderBy>
  unitedId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "booking" */
export enum ServerBookingUpdateColumn {
  /** column name */
  CanceledDate = 'canceledDate',
  /** column name */
  CanceledReason = 'canceledReason',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Duration = 'duration',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  IsGroupBooking = 'isGroupBooking',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Note = 'note',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  StartDate = 'startDate',
}

export type ServerBookingUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerBookingIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerBookingSetInput>
  /** filter the rows which have to be updated */
  where: ServerBookingBoolExp
}

/** aggregate var_pop on columns */
export type ServerBookingVarPopFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  /** Клиент, если это обычная запись(не групповая) */
  clientId: Maybe<Scalars['Float']>
  duration: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  locationId: Maybe<Scalars['Float']>
  recurringBookingId: Maybe<Scalars['Float']>
  /** Услуга, если это групповая запись */
  serviceId: Maybe<Scalars['Float']>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status: Maybe<Scalars['Float']>
  unitedId: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "booking" */
export type ServerBookingVarPopOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<ServerOrderBy>
  duration?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  recurringBookingId?: InputMaybe<ServerOrderBy>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<ServerOrderBy>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status?: InputMaybe<ServerOrderBy>
  unitedId?: InputMaybe<ServerOrderBy>
}

/** aggregate var_samp on columns */
export type ServerBookingVarSampFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  /** Клиент, если это обычная запись(не групповая) */
  clientId: Maybe<Scalars['Float']>
  duration: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  locationId: Maybe<Scalars['Float']>
  recurringBookingId: Maybe<Scalars['Float']>
  /** Услуга, если это групповая запись */
  serviceId: Maybe<Scalars['Float']>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status: Maybe<Scalars['Float']>
  unitedId: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "booking" */
export type ServerBookingVarSampOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<ServerOrderBy>
  duration?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  recurringBookingId?: InputMaybe<ServerOrderBy>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<ServerOrderBy>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status?: InputMaybe<ServerOrderBy>
  unitedId?: InputMaybe<ServerOrderBy>
}

/** aggregate variance on columns */
export type ServerBookingVarianceFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  /** Клиент, если это обычная запись(не групповая) */
  clientId: Maybe<Scalars['Float']>
  duration: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  locationId: Maybe<Scalars['Float']>
  recurringBookingId: Maybe<Scalars['Float']>
  /** Услуга, если это групповая запись */
  serviceId: Maybe<Scalars['Float']>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status: Maybe<Scalars['Float']>
  unitedId: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "booking" */
export type ServerBookingVarianceOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  /** Клиент, если это обычная запись(не групповая) */
  clientId?: InputMaybe<ServerOrderBy>
  duration?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  recurringBookingId?: InputMaybe<ServerOrderBy>
  /** Услуга, если это групповая запись */
  serviceId?: InputMaybe<ServerOrderBy>
  /** 0 - booking, 1 - notified, 2 - in place, 3 - did not come, 4 - done */
  status?: InputMaybe<ServerOrderBy>
  unitedId?: InputMaybe<ServerOrderBy>
}

/** Филиалы, как часть бизнеса */
export type ServerBranch = {
  /** An array relationship */
  accounts: Array<ServerAccount>
  address: Maybe<Scalars['String']>
  /** An object relationship */
  business: ServerBusiness
  createdAt: Scalars['timestamptz']
  currency: Scalars['String']
  /** An object relationship */
  defaultAccount: Maybe<ServerAccount>
  defaultAccountId: Maybe<Scalars['Int']>
  /** An object relationship */
  defaultStorage: Maybe<ServerStorage>
  defaultStorageId: Maybe<Scalars['Int']>
  description: Maybe<Scalars['String']>
  displayCoins: Scalars['Boolean']
  /** An array relationship */
  employeeSchedules: Array<ServerEmployeeSchedule>
  /** An aggregate relationship */
  employeeSchedules_aggregate: ServerEmployeeScheduleAggregate
  geo: Maybe<Scalars['point']>
  geocode: Maybe<Scalars['jsonb']>
  id: Scalars['Int']
  /** An object relationship */
  location: Maybe<ServerLocation>
  locationId: Maybe<Scalars['Int']>
  /** An array relationship */
  locations: Array<ServerLocation>
  name: Scalars['String']
  phone: Maybe<Scalars['String']>
  /** An object relationship */
  schedule: Maybe<ServerSchedule>
  /** A computed field, executes function "getBranchScheduleData" */
  scheduleData: Maybe<Scalars['jsonb']>
  scheduleId: Maybe<Scalars['Int']>
  /** An array relationship */
  services: Array<ServerService>
  /** An array relationship */
  storages: Array<ServerStorage>
  timezone: Scalars['String']
}

/** Филиалы, как часть бизнеса */
export type ServerBranchAccountsArgs = {
  distinct_on?: InputMaybe<Array<ServerAccountSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerAccountOrderBy>>
  where?: InputMaybe<ServerAccountBoolExp>
}

/** Филиалы, как часть бизнеса */
export type ServerBranchEmployeeSchedulesArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeScheduleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeScheduleOrderBy>>
  where?: InputMaybe<ServerEmployeeScheduleBoolExp>
}

/** Филиалы, как часть бизнеса */
export type ServerBranchEmployeeSchedulesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeScheduleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeScheduleOrderBy>>
  where?: InputMaybe<ServerEmployeeScheduleBoolExp>
}

/** Филиалы, как часть бизнеса */
export type ServerBranchGeocodeArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** Филиалы, как часть бизнеса */
export type ServerBranchLocationsArgs = {
  distinct_on?: InputMaybe<Array<ServerLocationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerLocationOrderBy>>
  where?: InputMaybe<ServerLocationBoolExp>
}

/** Филиалы, как часть бизнеса */
export type ServerBranchScheduleDataArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** Филиалы, как часть бизнеса */
export type ServerBranchServicesArgs = {
  distinct_on?: InputMaybe<Array<ServerServiceSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServiceOrderBy>>
  where?: InputMaybe<ServerServiceBoolExp>
}

/** Филиалы, как часть бизнеса */
export type ServerBranchStoragesArgs = {
  distinct_on?: InputMaybe<Array<ServerStorageSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerStorageOrderBy>>
  where?: InputMaybe<ServerStorageBoolExp>
}

/** columns and relationships of "branchToSwitchAsClientView" */
export type ServerBranchToSwitchAsClientView = {
  address: Maybe<Scalars['String']>
  /** An object relationship */
  business: Maybe<ServerBusiness>
  businessId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "branchToSwitchAsClientView". All fields are combined with a logical 'AND'. */
export type ServerBranchToSwitchAsClientViewBoolExp = {
  _and?: InputMaybe<Array<ServerBranchToSwitchAsClientViewBoolExp>>
  _not?: InputMaybe<ServerBranchToSwitchAsClientViewBoolExp>
  _or?: InputMaybe<Array<ServerBranchToSwitchAsClientViewBoolExp>>
  address?: InputMaybe<ServerStringComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  phone?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "branchToSwitchAsClientView". */
export type ServerBranchToSwitchAsClientViewOrderBy = {
  address?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
}

/** select columns of table "branchToSwitchAsClientView" */
export enum ServerBranchToSwitchAsClientViewSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
}

/** Streaming cursor of the table "branchToSwitchAsClientView" */
export type ServerBranchToSwitchAsClientViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBranchToSwitchAsClientViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBranchToSwitchAsClientViewStreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']>
  businessId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "branchView" */
export type ServerBranchView = {
  businessId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "branchView". All fields are combined with a logical 'AND'. */
export type ServerBranchViewBoolExp = {
  _and?: InputMaybe<Array<ServerBranchViewBoolExp>>
  _not?: InputMaybe<ServerBranchViewBoolExp>
  _or?: InputMaybe<Array<ServerBranchViewBoolExp>>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "branchView". */
export type ServerBranchViewOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** select columns of table "branchView" */
export enum ServerBranchViewSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "branchView" */
export type ServerBranchViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBranchViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBranchViewStreamCursorValueInput = {
  businessId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

/** order by aggregate values of table "branch" */
export type ServerBranchAggregateOrderBy = {
  avg?: InputMaybe<ServerBranchAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerBranchMaxOrderBy>
  min?: InputMaybe<ServerBranchMinOrderBy>
  stddev?: InputMaybe<ServerBranchStddevOrderBy>
  stddev_pop?: InputMaybe<ServerBranchStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerBranchStddevSampOrderBy>
  sum?: InputMaybe<ServerBranchSumOrderBy>
  var_pop?: InputMaybe<ServerBranchVarPopOrderBy>
  var_samp?: InputMaybe<ServerBranchVarSampOrderBy>
  variance?: InputMaybe<ServerBranchVarianceOrderBy>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type ServerBranchAppendInput = {
  geocode?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "branch" */
export type ServerBranchArrRelInsertInput = {
  data: Array<ServerBranchInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerBranchOnConflict>
}

/** order by avg() on columns of table "branch" */
export type ServerBranchAvgOrderBy = {
  defaultAccountId?: InputMaybe<ServerOrderBy>
  defaultStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "branch". All fields are combined with a logical 'AND'. */
export type ServerBranchBoolExp = {
  _and?: InputMaybe<Array<ServerBranchBoolExp>>
  _not?: InputMaybe<ServerBranchBoolExp>
  _or?: InputMaybe<Array<ServerBranchBoolExp>>
  accounts?: InputMaybe<ServerAccountBoolExp>
  address?: InputMaybe<ServerStringComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  currency?: InputMaybe<ServerStringComparisonExp>
  defaultAccount?: InputMaybe<ServerAccountBoolExp>
  defaultAccountId?: InputMaybe<ServerIntComparisonExp>
  defaultStorage?: InputMaybe<ServerStorageBoolExp>
  defaultStorageId?: InputMaybe<ServerIntComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  displayCoins?: InputMaybe<ServerBooleanComparisonExp>
  employeeSchedules?: InputMaybe<ServerEmployeeScheduleBoolExp>
  employeeSchedules_aggregate?: InputMaybe<ServerEmployeeScheduleAggregateBoolExp>
  geo?: InputMaybe<ServerPointComparisonExp>
  geocode?: InputMaybe<ServerJsonbComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  location?: InputMaybe<ServerLocationBoolExp>
  locationId?: InputMaybe<ServerIntComparisonExp>
  locations?: InputMaybe<ServerLocationBoolExp>
  name?: InputMaybe<ServerStringComparisonExp>
  phone?: InputMaybe<ServerStringComparisonExp>
  schedule?: InputMaybe<ServerScheduleBoolExp>
  scheduleData?: InputMaybe<ServerJsonbComparisonExp>
  scheduleId?: InputMaybe<ServerIntComparisonExp>
  services?: InputMaybe<ServerServiceBoolExp>
  storages?: InputMaybe<ServerStorageBoolExp>
  timezone?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "branch" */
export enum ServerBranchConstraint {
  /** unique or primary key constraint on columns "defaultAccountId" */
  BranchDefaultAccountIdKey = 'branch_defaultAccountId_key',
  /** unique or primary key constraint on columns "defaultStorageId" */
  BranchDefaultStorageIdKey = 'branch_defaultStorageId_key',
  /** unique or primary key constraint on columns "locationId" */
  BranchLocationIdKey = 'branch_locationId_key',
  /** unique or primary key constraint on columns "id" */
  BranchesPkey = 'branches_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ServerBranchDeleteAtPathInput = {
  geocode?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ServerBranchDeleteElemInput = {
  geocode?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ServerBranchDeleteKeyInput = {
  geocode?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "branch" */
export type ServerBranchIncInput = {
  defaultAccountId?: InputMaybe<Scalars['Int']>
  defaultStorageId?: InputMaybe<Scalars['Int']>
  locationId?: InputMaybe<Scalars['Int']>
  scheduleId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "branch" */
export type ServerBranchInsertInput = {
  accounts?: InputMaybe<ServerAccountArrRelInsertInput>
  address?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  currency?: InputMaybe<Scalars['String']>
  defaultAccount?: InputMaybe<ServerAccountObjRelInsertInput>
  defaultAccountId?: InputMaybe<Scalars['Int']>
  defaultStorage?: InputMaybe<ServerStorageObjRelInsertInput>
  defaultStorageId?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  displayCoins?: InputMaybe<Scalars['Boolean']>
  employeeSchedules?: InputMaybe<ServerEmployeeScheduleArrRelInsertInput>
  geo?: InputMaybe<Scalars['point']>
  geocode?: InputMaybe<Scalars['jsonb']>
  location?: InputMaybe<ServerLocationObjRelInsertInput>
  locationId?: InputMaybe<Scalars['Int']>
  locations?: InputMaybe<ServerLocationArrRelInsertInput>
  name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  schedule?: InputMaybe<ServerScheduleObjRelInsertInput>
  scheduleId?: InputMaybe<Scalars['Int']>
  services?: InputMaybe<ServerServiceArrRelInsertInput>
  storages?: InputMaybe<ServerStorageArrRelInsertInput>
  timezone?: InputMaybe<Scalars['String']>
}

/** order by max() on columns of table "branch" */
export type ServerBranchMaxOrderBy = {
  address?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  currency?: InputMaybe<ServerOrderBy>
  defaultAccountId?: InputMaybe<ServerOrderBy>
  defaultStorageId?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
  timezone?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "branch" */
export type ServerBranchMinOrderBy = {
  address?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  currency?: InputMaybe<ServerOrderBy>
  defaultAccountId?: InputMaybe<ServerOrderBy>
  defaultStorageId?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
  timezone?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "branch" */
export type ServerBranchMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBranch>
}

/** input type for inserting object relation for remote table "branch" */
export type ServerBranchObjRelInsertInput = {
  data: ServerBranchInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerBranchOnConflict>
}

/** on_conflict condition type for table "branch" */
export type ServerBranchOnConflict = {
  constraint: ServerBranchConstraint
  update_columns?: Array<ServerBranchUpdateColumn>
  where?: InputMaybe<ServerBranchBoolExp>
}

/** Ordering options when selecting data from "branch". */
export type ServerBranchOrderBy = {
  accounts_aggregate?: InputMaybe<ServerAccountAggregateOrderBy>
  address?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  currency?: InputMaybe<ServerOrderBy>
  defaultAccount?: InputMaybe<ServerAccountOrderBy>
  defaultAccountId?: InputMaybe<ServerOrderBy>
  defaultStorage?: InputMaybe<ServerStorageOrderBy>
  defaultStorageId?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  displayCoins?: InputMaybe<ServerOrderBy>
  employeeSchedules_aggregate?: InputMaybe<ServerEmployeeScheduleAggregateOrderBy>
  geo?: InputMaybe<ServerOrderBy>
  geocode?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  location?: InputMaybe<ServerLocationOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  locations_aggregate?: InputMaybe<ServerLocationAggregateOrderBy>
  name?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  schedule?: InputMaybe<ServerScheduleOrderBy>
  scheduleData?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
  services_aggregate?: InputMaybe<ServerServiceAggregateOrderBy>
  storages_aggregate?: InputMaybe<ServerStorageAggregateOrderBy>
  timezone?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: branch */
export type ServerBranchPkColumnsInput = {
  id: Scalars['Int']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ServerBranchPrependInput = {
  geocode?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "branch" */
export enum ServerBranchSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  DefaultAccountId = 'defaultAccountId',
  /** column name */
  DefaultStorageId = 'defaultStorageId',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayCoins = 'displayCoins',
  /** column name */
  Geo = 'geo',
  /** column name */
  Geocode = 'geocode',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  ScheduleId = 'scheduleId',
  /** column name */
  Timezone = 'timezone',
}

/** input type for updating data in table "branch" */
export type ServerBranchSetInput = {
  address?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  currency?: InputMaybe<Scalars['String']>
  defaultAccountId?: InputMaybe<Scalars['Int']>
  defaultStorageId?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  displayCoins?: InputMaybe<Scalars['Boolean']>
  geo?: InputMaybe<Scalars['point']>
  geocode?: InputMaybe<Scalars['jsonb']>
  locationId?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  scheduleId?: InputMaybe<Scalars['Int']>
  timezone?: InputMaybe<Scalars['String']>
}

/** order by stddev() on columns of table "branch" */
export type ServerBranchStddevOrderBy = {
  defaultAccountId?: InputMaybe<ServerOrderBy>
  defaultStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "branch" */
export type ServerBranchStddevPopOrderBy = {
  defaultAccountId?: InputMaybe<ServerOrderBy>
  defaultStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "branch" */
export type ServerBranchStddevSampOrderBy = {
  defaultAccountId?: InputMaybe<ServerOrderBy>
  defaultStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "branch" */
export type ServerBranchSumOrderBy = {
  defaultAccountId?: InputMaybe<ServerOrderBy>
  defaultStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "branch" */
export enum ServerBranchUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  DefaultAccountId = 'defaultAccountId',
  /** column name */
  DefaultStorageId = 'defaultStorageId',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayCoins = 'displayCoins',
  /** column name */
  Geo = 'geo',
  /** column name */
  Geocode = 'geocode',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  ScheduleId = 'scheduleId',
  /** column name */
  Timezone = 'timezone',
}

export type ServerBranchUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ServerBranchAppendInput>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ServerBranchDeleteAtPathInput>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ServerBranchDeleteElemInput>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ServerBranchDeleteKeyInput>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerBranchIncInput>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ServerBranchPrependInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerBranchSetInput>
  /** filter the rows which have to be updated */
  where: ServerBranchBoolExp
}

/** order by var_pop() on columns of table "branch" */
export type ServerBranchVarPopOrderBy = {
  defaultAccountId?: InputMaybe<ServerOrderBy>
  defaultStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "branch" */
export type ServerBranchVarSampOrderBy = {
  defaultAccountId?: InputMaybe<ServerOrderBy>
  defaultStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "branch" */
export type ServerBranchVarianceOrderBy = {
  defaultAccountId?: InputMaybe<ServerOrderBy>
  defaultStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** Бизнессы */
export type ServerBusiness = {
  /** An array relationship */
  billingInfos: Array<ServerBillingInfo>
  /** An array relationship */
  bookingClientSetting: Array<ServerBookingClientSettings>
  /** An array relationship */
  branches: Array<ServerBranch>
  /** An array relationship */
  businessPromoCodes: Array<ServerBusinessPromoCode>
  /** An object relationship */
  businessSettings: Maybe<ServerBusinessSettings>
  /** An object relationship */
  businessType: Maybe<ServerBusinessType>
  /** An array relationship */
  clients: Array<ServerClient>
  /** An aggregate relationship */
  clients_aggregate: ServerClientAggregate
  createdAt: Scalars['timestamptz']
  /** An array relationship */
  employees: Array<ServerEmployee>
  /** An aggregate relationship */
  employees_aggregate: ServerEmployeeAggregate
  id: Scalars['Int']
  /** An object relationship */
  landingSettings: Maybe<ServerBusinessLanding>
  logo: Maybe<Scalars['String']>
  name: Scalars['String']
  onHold: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  owner: Maybe<ServerEmployee>
  ownerId: Maybe<Scalars['Int']>
  photo: Maybe<Scalars['String']>
  typeId: Maybe<Scalars['Int']>
  /** An array relationship */
  waitingLists: Array<ServerWaitingList>
}

/** Бизнессы */
export type ServerBusinessBillingInfosArgs = {
  distinct_on?: InputMaybe<Array<ServerBillingInfoSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBillingInfoOrderBy>>
  where?: InputMaybe<ServerBillingInfoBoolExp>
}

/** Бизнессы */
export type ServerBusinessBookingClientSettingArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingClientSettingsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingClientSettingsOrderBy>>
  where?: InputMaybe<ServerBookingClientSettingsBoolExp>
}

/** Бизнессы */
export type ServerBusinessBranchesArgs = {
  distinct_on?: InputMaybe<Array<ServerBranchSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBranchOrderBy>>
  where?: InputMaybe<ServerBranchBoolExp>
}

/** Бизнессы */
export type ServerBusinessBusinessPromoCodesArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessPromoCodeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessPromoCodeOrderBy>>
  where?: InputMaybe<ServerBusinessPromoCodeBoolExp>
}

/** Бизнессы */
export type ServerBusinessClientsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientOrderBy>>
  where?: InputMaybe<ServerClientBoolExp>
}

/** Бизнессы */
export type ServerBusinessClientsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerClientSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientOrderBy>>
  where?: InputMaybe<ServerClientBoolExp>
}

/** Бизнессы */
export type ServerBusinessEmployeesArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeOrderBy>>
  where?: InputMaybe<ServerEmployeeBoolExp>
}

/** Бизнессы */
export type ServerBusinessEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeOrderBy>>
  where?: InputMaybe<ServerEmployeeBoolExp>
}

/** Бизнессы */
export type ServerBusinessWaitingListsArgs = {
  distinct_on?: InputMaybe<Array<ServerWaitingListSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerWaitingListOrderBy>>
  where?: InputMaybe<ServerWaitingListBoolExp>
}

/** columns and relationships of "businessCurrentPlanIdView" */
export type ServerBusinessCurrentPlanIdView = {
  /** An object relationship */
  plan: Maybe<ServerPlan>
  planId: Maybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "businessCurrentPlanIdView". All fields are combined with a logical 'AND'. */
export type ServerBusinessCurrentPlanIdViewBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessCurrentPlanIdViewBoolExp>>
  _not?: InputMaybe<ServerBusinessCurrentPlanIdViewBoolExp>
  _or?: InputMaybe<Array<ServerBusinessCurrentPlanIdViewBoolExp>>
  plan?: InputMaybe<ServerPlanBoolExp>
  planId?: InputMaybe<ServerIntComparisonExp>
}

/** Ordering options when selecting data from "businessCurrentPlanIdView". */
export type ServerBusinessCurrentPlanIdViewOrderBy = {
  plan?: InputMaybe<ServerPlanOrderBy>
  planId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "businessCurrentPlanIdView" */
export enum ServerBusinessCurrentPlanIdViewSelectColumn {
  /** column name */
  PlanId = 'planId',
}

/** Streaming cursor of the table "businessCurrentPlanIdView" */
export type ServerBusinessCurrentPlanIdViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBusinessCurrentPlanIdViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBusinessCurrentPlanIdViewStreamCursorValueInput = {
  planId?: InputMaybe<Scalars['Int']>
}

/** columns and relationships of "businessInfoReferrerView" */
export type ServerBusinessInfoReferrerView = {
  active: Maybe<Scalars['Boolean']>
  businessId: Maybe<Scalars['Int']>
  /** An object relationship */
  businessPromoCodes: Maybe<ServerBusinessPromoCodeReferrerView>
  createdAt: Maybe<Scalars['timestamptz']>
  expirationDate: Maybe<Scalars['timestamptz']>
  name: Maybe<Scalars['String']>
  planId: Maybe<Scalars['Int']>
  promoCodeId: Maybe<Scalars['String']>
  totalPurchase: Maybe<Scalars['numeric']>
}

/** Boolean expression to filter rows from the table "businessInfoReferrerView". All fields are combined with a logical 'AND'. */
export type ServerBusinessInfoReferrerViewBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessInfoReferrerViewBoolExp>>
  _not?: InputMaybe<ServerBusinessInfoReferrerViewBoolExp>
  _or?: InputMaybe<Array<ServerBusinessInfoReferrerViewBoolExp>>
  active?: InputMaybe<ServerBooleanComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  businessPromoCodes?: InputMaybe<ServerBusinessPromoCodeReferrerViewBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  expirationDate?: InputMaybe<ServerTimestamptzComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  planId?: InputMaybe<ServerIntComparisonExp>
  promoCodeId?: InputMaybe<ServerStringComparisonExp>
  totalPurchase?: InputMaybe<ServerNumericComparisonExp>
}

/** Ordering options when selecting data from "businessInfoReferrerView". */
export type ServerBusinessInfoReferrerViewOrderBy = {
  active?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  businessPromoCodes?: InputMaybe<ServerBusinessPromoCodeReferrerViewOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  expirationDate?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  promoCodeId?: InputMaybe<ServerOrderBy>
  totalPurchase?: InputMaybe<ServerOrderBy>
}

/** select columns of table "businessInfoReferrerView" */
export enum ServerBusinessInfoReferrerViewSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpirationDate = 'expirationDate',
  /** column name */
  Name = 'name',
  /** column name */
  PlanId = 'planId',
  /** column name */
  PromoCodeId = 'promoCodeId',
  /** column name */
  TotalPurchase = 'totalPurchase',
}

/** Streaming cursor of the table "businessInfoReferrerView" */
export type ServerBusinessInfoReferrerViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBusinessInfoReferrerViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBusinessInfoReferrerViewStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>
  businessId?: InputMaybe<Scalars['Int']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  expirationDate?: InputMaybe<Scalars['timestamptz']>
  name?: InputMaybe<Scalars['String']>
  planId?: InputMaybe<Scalars['Int']>
  promoCodeId?: InputMaybe<Scalars['String']>
  totalPurchase?: InputMaybe<Scalars['numeric']>
}

/** columns and relationships of "businessInfoView" */
export type ServerBusinessInfoView = {
  active: Maybe<Scalars['Boolean']>
  businessId: Maybe<Scalars['Int']>
  /** An object relationship */
  businessPromoCode: Maybe<ServerBusinessPromoCode>
  clientsCount: Maybe<Scalars['bigint']>
  createdAt: Maybe<Scalars['timestamptz']>
  email: Maybe<Scalars['String']>
  employeesCount: Maybe<Scalars['bigint']>
  expirationDate: Maybe<Scalars['timestamptz']>
  isTest: Maybe<Scalars['Boolean']>
  name: Maybe<Scalars['String']>
  owner: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  planId: Maybe<Scalars['Int']>
  promoCodeId: Maybe<Scalars['String']>
  totalPurchase: Maybe<Scalars['numeric']>
}

/** Boolean expression to filter rows from the table "businessInfoView". All fields are combined with a logical 'AND'. */
export type ServerBusinessInfoViewBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessInfoViewBoolExp>>
  _not?: InputMaybe<ServerBusinessInfoViewBoolExp>
  _or?: InputMaybe<Array<ServerBusinessInfoViewBoolExp>>
  active?: InputMaybe<ServerBooleanComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  businessPromoCode?: InputMaybe<ServerBusinessPromoCodeBoolExp>
  clientsCount?: InputMaybe<ServerBigintComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  email?: InputMaybe<ServerStringComparisonExp>
  employeesCount?: InputMaybe<ServerBigintComparisonExp>
  expirationDate?: InputMaybe<ServerTimestamptzComparisonExp>
  isTest?: InputMaybe<ServerBooleanComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  owner?: InputMaybe<ServerStringComparisonExp>
  phone?: InputMaybe<ServerStringComparisonExp>
  planId?: InputMaybe<ServerIntComparisonExp>
  promoCodeId?: InputMaybe<ServerStringComparisonExp>
  totalPurchase?: InputMaybe<ServerNumericComparisonExp>
}

/** Ordering options when selecting data from "businessInfoView". */
export type ServerBusinessInfoViewOrderBy = {
  active?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  businessPromoCode?: InputMaybe<ServerBusinessPromoCodeOrderBy>
  clientsCount?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  employeesCount?: InputMaybe<ServerOrderBy>
  expirationDate?: InputMaybe<ServerOrderBy>
  isTest?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  owner?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  planId?: InputMaybe<ServerOrderBy>
  promoCodeId?: InputMaybe<ServerOrderBy>
  totalPurchase?: InputMaybe<ServerOrderBy>
}

/** select columns of table "businessInfoView" */
export enum ServerBusinessInfoViewSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  ClientsCount = 'clientsCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EmployeesCount = 'employeesCount',
  /** column name */
  ExpirationDate = 'expirationDate',
  /** column name */
  IsTest = 'isTest',
  /** column name */
  Name = 'name',
  /** column name */
  Owner = 'owner',
  /** column name */
  Phone = 'phone',
  /** column name */
  PlanId = 'planId',
  /** column name */
  PromoCodeId = 'promoCodeId',
  /** column name */
  TotalPurchase = 'totalPurchase',
}

/** Streaming cursor of the table "businessInfoView" */
export type ServerBusinessInfoViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBusinessInfoViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBusinessInfoViewStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>
  businessId?: InputMaybe<Scalars['Int']>
  clientsCount?: InputMaybe<Scalars['bigint']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  employeesCount?: InputMaybe<Scalars['bigint']>
  expirationDate?: InputMaybe<Scalars['timestamptz']>
  isTest?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  owner?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  planId?: InputMaybe<Scalars['Int']>
  promoCodeId?: InputMaybe<Scalars['String']>
  totalPurchase?: InputMaybe<Scalars['numeric']>
}

/** columns and relationships of "businessIsTestView" */
export type ServerBusinessIsTestView = {
  id: Maybe<Scalars['Int']>
  isTest: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "businessIsTestView". All fields are combined with a logical 'AND'. */
export type ServerBusinessIsTestViewBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessIsTestViewBoolExp>>
  _not?: InputMaybe<ServerBusinessIsTestViewBoolExp>
  _or?: InputMaybe<Array<ServerBusinessIsTestViewBoolExp>>
  id?: InputMaybe<ServerIntComparisonExp>
  isTest?: InputMaybe<ServerBooleanComparisonExp>
}

/** response of any mutation on the table "businessIsTestView" */
export type ServerBusinessIsTestViewMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBusinessIsTestView>
}

/** Ordering options when selecting data from "businessIsTestView". */
export type ServerBusinessIsTestViewOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  isTest?: InputMaybe<ServerOrderBy>
}

/** select columns of table "businessIsTestView" */
export enum ServerBusinessIsTestViewSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  IsTest = 'isTest',
}

/** input type for updating data in table "businessIsTestView" */
export type ServerBusinessIsTestViewSetInput = {
  isTest?: InputMaybe<Scalars['Boolean']>
}

/** Streaming cursor of the table "businessIsTestView" */
export type ServerBusinessIsTestViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBusinessIsTestViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBusinessIsTestViewStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>
  isTest?: InputMaybe<Scalars['Boolean']>
}

export type ServerBusinessIsTestViewUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerBusinessIsTestViewSetInput>
  /** filter the rows which have to be updated */
  where: ServerBusinessIsTestViewBoolExp
}

/** columns and relationships of "businessLanding" */
export type ServerBusinessLanding = {
  bannerDescription: Maybe<Scalars['String']>
  bannerImage: Maybe<Scalars['String']>
  bannerTitle: Maybe<Scalars['String']>
  businessId: Scalars['Int']
  enabled: Scalars['Boolean']
  facebook: Maybe<Scalars['String']>
  id: Scalars['uuid']
  images: Scalars['jsonb']
  instagram: Maybe<Scalars['String']>
  path: Maybe<Scalars['String']>
  telegram: Maybe<Scalars['String']>
  theme: Scalars['String']
  viber: Maybe<Scalars['String']>
}

/** columns and relationships of "businessLanding" */
export type ServerBusinessLandingImagesArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "businessLandingView" */
export type ServerBusinessLandingView = {
  bannerDescription: Maybe<Scalars['String']>
  bannerImage: Maybe<Scalars['String']>
  bannerTitle: Maybe<Scalars['String']>
  businessId: Maybe<Scalars['Int']>
  facebook: Maybe<Scalars['String']>
  images: Maybe<Scalars['jsonb']>
  instagram: Maybe<Scalars['String']>
  path: Maybe<Scalars['String']>
  telegram: Maybe<Scalars['String']>
  theme: Maybe<Scalars['String']>
  viber: Maybe<Scalars['String']>
}

/** columns and relationships of "businessLandingView" */
export type ServerBusinessLandingViewImagesArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "businessLandingView". All fields are combined with a logical 'AND'. */
export type ServerBusinessLandingViewBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessLandingViewBoolExp>>
  _not?: InputMaybe<ServerBusinessLandingViewBoolExp>
  _or?: InputMaybe<Array<ServerBusinessLandingViewBoolExp>>
  bannerDescription?: InputMaybe<ServerStringComparisonExp>
  bannerImage?: InputMaybe<ServerStringComparisonExp>
  bannerTitle?: InputMaybe<ServerStringComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  facebook?: InputMaybe<ServerStringComparisonExp>
  images?: InputMaybe<ServerJsonbComparisonExp>
  instagram?: InputMaybe<ServerStringComparisonExp>
  path?: InputMaybe<ServerStringComparisonExp>
  telegram?: InputMaybe<ServerStringComparisonExp>
  theme?: InputMaybe<ServerStringComparisonExp>
  viber?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "businessLandingView". */
export type ServerBusinessLandingViewOrderBy = {
  bannerDescription?: InputMaybe<ServerOrderBy>
  bannerImage?: InputMaybe<ServerOrderBy>
  bannerTitle?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  facebook?: InputMaybe<ServerOrderBy>
  images?: InputMaybe<ServerOrderBy>
  instagram?: InputMaybe<ServerOrderBy>
  path?: InputMaybe<ServerOrderBy>
  telegram?: InputMaybe<ServerOrderBy>
  theme?: InputMaybe<ServerOrderBy>
  viber?: InputMaybe<ServerOrderBy>
}

/** select columns of table "businessLandingView" */
export enum ServerBusinessLandingViewSelectColumn {
  /** column name */
  BannerDescription = 'bannerDescription',
  /** column name */
  BannerImage = 'bannerImage',
  /** column name */
  BannerTitle = 'bannerTitle',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Images = 'images',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Path = 'path',
  /** column name */
  Telegram = 'telegram',
  /** column name */
  Theme = 'theme',
  /** column name */
  Viber = 'viber',
}

/** Streaming cursor of the table "businessLandingView" */
export type ServerBusinessLandingViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBusinessLandingViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBusinessLandingViewStreamCursorValueInput = {
  bannerDescription?: InputMaybe<Scalars['String']>
  bannerImage?: InputMaybe<Scalars['String']>
  bannerTitle?: InputMaybe<Scalars['String']>
  businessId?: InputMaybe<Scalars['Int']>
  facebook?: InputMaybe<Scalars['String']>
  images?: InputMaybe<Scalars['jsonb']>
  instagram?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
  telegram?: InputMaybe<Scalars['String']>
  theme?: InputMaybe<Scalars['String']>
  viber?: InputMaybe<Scalars['String']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type ServerBusinessLandingAppendInput = {
  images?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "businessLanding". All fields are combined with a logical 'AND'. */
export type ServerBusinessLandingBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessLandingBoolExp>>
  _not?: InputMaybe<ServerBusinessLandingBoolExp>
  _or?: InputMaybe<Array<ServerBusinessLandingBoolExp>>
  bannerDescription?: InputMaybe<ServerStringComparisonExp>
  bannerImage?: InputMaybe<ServerStringComparisonExp>
  bannerTitle?: InputMaybe<ServerStringComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  enabled?: InputMaybe<ServerBooleanComparisonExp>
  facebook?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerUuidComparisonExp>
  images?: InputMaybe<ServerJsonbComparisonExp>
  instagram?: InputMaybe<ServerStringComparisonExp>
  path?: InputMaybe<ServerStringComparisonExp>
  telegram?: InputMaybe<ServerStringComparisonExp>
  theme?: InputMaybe<ServerStringComparisonExp>
  viber?: InputMaybe<ServerStringComparisonExp>
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ServerBusinessLandingDeleteAtPathInput = {
  images?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ServerBusinessLandingDeleteElemInput = {
  images?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ServerBusinessLandingDeleteKeyInput = {
  images?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "businessLanding" */
export type ServerBusinessLandingMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBusinessLanding>
}

/** Ordering options when selecting data from "businessLanding". */
export type ServerBusinessLandingOrderBy = {
  bannerDescription?: InputMaybe<ServerOrderBy>
  bannerImage?: InputMaybe<ServerOrderBy>
  bannerTitle?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  enabled?: InputMaybe<ServerOrderBy>
  facebook?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  images?: InputMaybe<ServerOrderBy>
  instagram?: InputMaybe<ServerOrderBy>
  path?: InputMaybe<ServerOrderBy>
  telegram?: InputMaybe<ServerOrderBy>
  theme?: InputMaybe<ServerOrderBy>
  viber?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: businessLanding */
export type ServerBusinessLandingPkColumnsInput = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ServerBusinessLandingPrependInput = {
  images?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "businessLanding" */
export enum ServerBusinessLandingSelectColumn {
  /** column name */
  BannerDescription = 'bannerDescription',
  /** column name */
  BannerImage = 'bannerImage',
  /** column name */
  BannerTitle = 'bannerTitle',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Path = 'path',
  /** column name */
  Telegram = 'telegram',
  /** column name */
  Theme = 'theme',
  /** column name */
  Viber = 'viber',
}

/** input type for updating data in table "businessLanding" */
export type ServerBusinessLandingSetInput = {
  bannerDescription?: InputMaybe<Scalars['String']>
  bannerImage?: InputMaybe<Scalars['String']>
  bannerTitle?: InputMaybe<Scalars['String']>
  enabled?: InputMaybe<Scalars['Boolean']>
  facebook?: InputMaybe<Scalars['String']>
  images?: InputMaybe<Scalars['jsonb']>
  instagram?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
  telegram?: InputMaybe<Scalars['String']>
  theme?: InputMaybe<Scalars['String']>
  viber?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "businessLanding" */
export type ServerBusinessLandingStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBusinessLandingStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBusinessLandingStreamCursorValueInput = {
  bannerDescription?: InputMaybe<Scalars['String']>
  bannerImage?: InputMaybe<Scalars['String']>
  bannerTitle?: InputMaybe<Scalars['String']>
  businessId?: InputMaybe<Scalars['Int']>
  enabled?: InputMaybe<Scalars['Boolean']>
  facebook?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  images?: InputMaybe<Scalars['jsonb']>
  instagram?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
  telegram?: InputMaybe<Scalars['String']>
  theme?: InputMaybe<Scalars['String']>
  viber?: InputMaybe<Scalars['String']>
}

export type ServerBusinessLandingUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ServerBusinessLandingAppendInput>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ServerBusinessLandingDeleteAtPathInput>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ServerBusinessLandingDeleteElemInput>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ServerBusinessLandingDeleteKeyInput>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ServerBusinessLandingPrependInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerBusinessLandingSetInput>
  /** filter the rows which have to be updated */
  where: ServerBusinessLandingBoolExp
}

/** columns and relationships of "businessPromoCode" */
export type ServerBusinessPromoCode = {
  /** An array relationship */
  billingInfos: Array<ServerBillingInfo>
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  createdAt: Scalars['timestamptz']
  id: Scalars['Int']
  /** An object relationship */
  promoCode: ServerPromoCode
  promoCodeId: Scalars['String']
}

/** columns and relationships of "businessPromoCode" */
export type ServerBusinessPromoCodeBillingInfosArgs = {
  distinct_on?: InputMaybe<Array<ServerBillingInfoSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBillingInfoOrderBy>>
  where?: InputMaybe<ServerBillingInfoBoolExp>
}

/** columns and relationships of "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerView = {
  businessId: Maybe<Scalars['Int']>
  createdAt: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
  /** An object relationship */
  promoCode: Maybe<ServerPromoCode>
  promoCodeId: Maybe<Scalars['String']>
}

/** order by aggregate values of table "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerViewAggregateOrderBy = {
  avg?: InputMaybe<ServerBusinessPromoCodeReferrerViewAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerBusinessPromoCodeReferrerViewMaxOrderBy>
  min?: InputMaybe<ServerBusinessPromoCodeReferrerViewMinOrderBy>
  stddev?: InputMaybe<ServerBusinessPromoCodeReferrerViewStddevOrderBy>
  stddev_pop?: InputMaybe<ServerBusinessPromoCodeReferrerViewStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerBusinessPromoCodeReferrerViewStddevSampOrderBy>
  sum?: InputMaybe<ServerBusinessPromoCodeReferrerViewSumOrderBy>
  var_pop?: InputMaybe<ServerBusinessPromoCodeReferrerViewVarPopOrderBy>
  var_samp?: InputMaybe<ServerBusinessPromoCodeReferrerViewVarSampOrderBy>
  variance?: InputMaybe<ServerBusinessPromoCodeReferrerViewVarianceOrderBy>
}

/** order by avg() on columns of table "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerViewAvgOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "businessPromoCodeReferrerView". All fields are combined with a logical 'AND'. */
export type ServerBusinessPromoCodeReferrerViewBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessPromoCodeReferrerViewBoolExp>>
  _not?: InputMaybe<ServerBusinessPromoCodeReferrerViewBoolExp>
  _or?: InputMaybe<Array<ServerBusinessPromoCodeReferrerViewBoolExp>>
  businessId?: InputMaybe<ServerIntComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  promoCode?: InputMaybe<ServerPromoCodeBoolExp>
  promoCodeId?: InputMaybe<ServerStringComparisonExp>
}

/** order by max() on columns of table "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerViewMaxOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  promoCodeId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerViewMinOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  promoCodeId?: InputMaybe<ServerOrderBy>
}

/** Ordering options when selecting data from "businessPromoCodeReferrerView". */
export type ServerBusinessPromoCodeReferrerViewOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  promoCode?: InputMaybe<ServerPromoCodeOrderBy>
  promoCodeId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "businessPromoCodeReferrerView" */
export enum ServerBusinessPromoCodeReferrerViewSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PromoCodeId = 'promoCodeId',
}

/** order by stddev() on columns of table "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerViewStddevOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerViewStddevPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerViewStddevSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Streaming cursor of the table "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBusinessPromoCodeReferrerViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBusinessPromoCodeReferrerViewStreamCursorValueInput = {
  businessId?: InputMaybe<Scalars['Int']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['Int']>
  promoCodeId?: InputMaybe<Scalars['String']>
}

/** order by sum() on columns of table "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerViewSumOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by var_pop() on columns of table "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerViewVarPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerViewVarSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "businessPromoCodeReferrerView" */
export type ServerBusinessPromoCodeReferrerViewVarianceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "businessPromoCodeView" */
export type ServerBusinessPromoCodeView = {
  businessId: Maybe<Scalars['Int']>
  discount: Maybe<Scalars['Int']>
  promoCodeId: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "businessPromoCodeView". All fields are combined with a logical 'AND'. */
export type ServerBusinessPromoCodeViewBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessPromoCodeViewBoolExp>>
  _not?: InputMaybe<ServerBusinessPromoCodeViewBoolExp>
  _or?: InputMaybe<Array<ServerBusinessPromoCodeViewBoolExp>>
  businessId?: InputMaybe<ServerIntComparisonExp>
  discount?: InputMaybe<ServerIntComparisonExp>
  promoCodeId?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "businessPromoCodeView". */
export type ServerBusinessPromoCodeViewOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  promoCodeId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "businessPromoCodeView" */
export enum ServerBusinessPromoCodeViewSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Discount = 'discount',
  /** column name */
  PromoCodeId = 'promoCodeId',
}

/** Streaming cursor of the table "businessPromoCodeView" */
export type ServerBusinessPromoCodeViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBusinessPromoCodeViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBusinessPromoCodeViewStreamCursorValueInput = {
  businessId?: InputMaybe<Scalars['Int']>
  discount?: InputMaybe<Scalars['Int']>
  promoCodeId?: InputMaybe<Scalars['String']>
}

/** order by aggregate values of table "businessPromoCode" */
export type ServerBusinessPromoCodeAggregateOrderBy = {
  avg?: InputMaybe<ServerBusinessPromoCodeAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerBusinessPromoCodeMaxOrderBy>
  min?: InputMaybe<ServerBusinessPromoCodeMinOrderBy>
  stddev?: InputMaybe<ServerBusinessPromoCodeStddevOrderBy>
  stddev_pop?: InputMaybe<ServerBusinessPromoCodeStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerBusinessPromoCodeStddevSampOrderBy>
  sum?: InputMaybe<ServerBusinessPromoCodeSumOrderBy>
  var_pop?: InputMaybe<ServerBusinessPromoCodeVarPopOrderBy>
  var_samp?: InputMaybe<ServerBusinessPromoCodeVarSampOrderBy>
  variance?: InputMaybe<ServerBusinessPromoCodeVarianceOrderBy>
}

/** input type for inserting array relation for remote table "businessPromoCode" */
export type ServerBusinessPromoCodeArrRelInsertInput = {
  data: Array<ServerBusinessPromoCodeInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerBusinessPromoCodeOnConflict>
}

/** order by avg() on columns of table "businessPromoCode" */
export type ServerBusinessPromoCodeAvgOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "businessPromoCode". All fields are combined with a logical 'AND'. */
export type ServerBusinessPromoCodeBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessPromoCodeBoolExp>>
  _not?: InputMaybe<ServerBusinessPromoCodeBoolExp>
  _or?: InputMaybe<Array<ServerBusinessPromoCodeBoolExp>>
  billingInfos?: InputMaybe<ServerBillingInfoBoolExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  promoCode?: InputMaybe<ServerPromoCodeBoolExp>
  promoCodeId?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "businessPromoCode" */
export enum ServerBusinessPromoCodeConstraint {
  /** unique or primary key constraint on columns "id" */
  BusinessPromoCodePkey = 'businessPromoCode_pkey',
  /** unique or primary key constraint on columns "promoCodeId", "businessId" */
  BusinessPromoCodePromoCodeIdBusinessIdKey = 'businessPromoCode_promoCodeId_businessId_key',
}

/** input type for inserting data into table "businessPromoCode" */
export type ServerBusinessPromoCodeInsertInput = {
  businessId?: InputMaybe<Scalars['Int']>
  promoCode?: InputMaybe<ServerPromoCodeObjRelInsertInput>
  promoCodeId?: InputMaybe<Scalars['String']>
}

/** order by max() on columns of table "businessPromoCode" */
export type ServerBusinessPromoCodeMaxOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  promoCodeId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "businessPromoCode" */
export type ServerBusinessPromoCodeMinOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  promoCodeId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "businessPromoCode" */
export type ServerBusinessPromoCodeMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBusinessPromoCode>
}

/** on_conflict condition type for table "businessPromoCode" */
export type ServerBusinessPromoCodeOnConflict = {
  constraint: ServerBusinessPromoCodeConstraint
  update_columns?: Array<ServerBusinessPromoCodeUpdateColumn>
  where?: InputMaybe<ServerBusinessPromoCodeBoolExp>
}

/** Ordering options when selecting data from "businessPromoCode". */
export type ServerBusinessPromoCodeOrderBy = {
  billingInfos_aggregate?: InputMaybe<ServerBillingInfoAggregateOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  promoCode?: InputMaybe<ServerPromoCodeOrderBy>
  promoCodeId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "businessPromoCode" */
export enum ServerBusinessPromoCodeSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PromoCodeId = 'promoCodeId',
}

/** order by stddev() on columns of table "businessPromoCode" */
export type ServerBusinessPromoCodeStddevOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "businessPromoCode" */
export type ServerBusinessPromoCodeStddevPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "businessPromoCode" */
export type ServerBusinessPromoCodeStddevSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Streaming cursor of the table "businessPromoCode" */
export type ServerBusinessPromoCodeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBusinessPromoCodeStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBusinessPromoCodeStreamCursorValueInput = {
  businessId?: InputMaybe<Scalars['Int']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['Int']>
  promoCodeId?: InputMaybe<Scalars['String']>
}

/** order by sum() on columns of table "businessPromoCode" */
export type ServerBusinessPromoCodeSumOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "businessPromoCode" (current role has no relevant permissions) */
export enum ServerBusinessPromoCodeUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "businessPromoCode" */
export type ServerBusinessPromoCodeVarPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "businessPromoCode" */
export type ServerBusinessPromoCodeVarSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "businessPromoCode" */
export type ServerBusinessPromoCodeVarianceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Общие настройки для бизнесов */
export type ServerBusinessSettings = {
  binotelIntegration: Maybe<Scalars['jsonb']>
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  /** Used to for notification settings in mobile app */
  hasSms: Maybe<Scalars['Boolean']>
  hideClientsDeposit: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  messageTemplate: Maybe<Scalars['String']>
  modules: Scalars['jsonb']
  smsToken: Maybe<Scalars['String']>
  /** name? - telegram bot name, token? - telegram bot token, isUseDefault - true if use expane bot */
  telegramIntegration: Scalars['jsonb']
}

/** Общие настройки для бизнесов */
export type ServerBusinessSettingsBinotelIntegrationArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** Общие настройки для бизнесов */
export type ServerBusinessSettingsModulesArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** Общие настройки для бизнесов */
export type ServerBusinessSettingsTelegramIntegrationArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type ServerBusinessSettingsAppendInput = {
  binotelIntegration?: InputMaybe<Scalars['jsonb']>
  /** name? - telegram bot name, token? - telegram bot token, isUseDefault - true if use expane bot */
  telegramIntegration?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "businessSettings". All fields are combined with a logical 'AND'. */
export type ServerBusinessSettingsBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessSettingsBoolExp>>
  _not?: InputMaybe<ServerBusinessSettingsBoolExp>
  _or?: InputMaybe<Array<ServerBusinessSettingsBoolExp>>
  binotelIntegration?: InputMaybe<ServerJsonbComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  hasSms?: InputMaybe<ServerBooleanComparisonExp>
  hideClientsDeposit?: InputMaybe<ServerBooleanComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  messageTemplate?: InputMaybe<ServerStringComparisonExp>
  modules?: InputMaybe<ServerJsonbComparisonExp>
  smsToken?: InputMaybe<ServerStringComparisonExp>
  telegramIntegration?: InputMaybe<ServerJsonbComparisonExp>
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ServerBusinessSettingsDeleteAtPathInput = {
  binotelIntegration?: InputMaybe<Array<Scalars['String']>>
  /** name? - telegram bot name, token? - telegram bot token, isUseDefault - true if use expane bot */
  telegramIntegration?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ServerBusinessSettingsDeleteElemInput = {
  binotelIntegration?: InputMaybe<Scalars['Int']>
  /** name? - telegram bot name, token? - telegram bot token, isUseDefault - true if use expane bot */
  telegramIntegration?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ServerBusinessSettingsDeleteKeyInput = {
  binotelIntegration?: InputMaybe<Scalars['String']>
  /** name? - telegram bot name, token? - telegram bot token, isUseDefault - true if use expane bot */
  telegramIntegration?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "businessSettings" */
export type ServerBusinessSettingsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBusinessSettings>
}

/** Ordering options when selecting data from "businessSettings". */
export type ServerBusinessSettingsOrderBy = {
  binotelIntegration?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  hasSms?: InputMaybe<ServerOrderBy>
  hideClientsDeposit?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  messageTemplate?: InputMaybe<ServerOrderBy>
  modules?: InputMaybe<ServerOrderBy>
  smsToken?: InputMaybe<ServerOrderBy>
  telegramIntegration?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: businessSettings */
export type ServerBusinessSettingsPkColumnsInput = {
  id: Scalars['Int']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ServerBusinessSettingsPrependInput = {
  binotelIntegration?: InputMaybe<Scalars['jsonb']>
  /** name? - telegram bot name, token? - telegram bot token, isUseDefault - true if use expane bot */
  telegramIntegration?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "businessSettings" */
export enum ServerBusinessSettingsSelectColumn {
  /** column name */
  BinotelIntegration = 'binotelIntegration',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  HideClientsDeposit = 'hideClientsDeposit',
  /** column name */
  Id = 'id',
  /** column name */
  MessageTemplate = 'messageTemplate',
  /** column name */
  Modules = 'modules',
  /** column name */
  SmsToken = 'smsToken',
  /** column name */
  TelegramIntegration = 'telegramIntegration',
}

/** input type for updating data in table "businessSettings" */
export type ServerBusinessSettingsSetInput = {
  binotelIntegration?: InputMaybe<Scalars['jsonb']>
  hideClientsDeposit?: InputMaybe<Scalars['Boolean']>
  messageTemplate?: InputMaybe<Scalars['String']>
  smsToken?: InputMaybe<Scalars['String']>
  /** name? - telegram bot name, token? - telegram bot token, isUseDefault - true if use expane bot */
  telegramIntegration?: InputMaybe<Scalars['jsonb']>
}

export type ServerBusinessSettingsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ServerBusinessSettingsAppendInput>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ServerBusinessSettingsDeleteAtPathInput>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ServerBusinessSettingsDeleteElemInput>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ServerBusinessSettingsDeleteKeyInput>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ServerBusinessSettingsPrependInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerBusinessSettingsSetInput>
  /** filter the rows which have to be updated */
  where: ServerBusinessSettingsBoolExp
}

/** columns and relationships of "businessTelegramIntegrationView" */
export type ServerBusinessTelegramIntegrationView = {
  businessId: Maybe<Scalars['Int']>
  isUseDefault: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "businessTelegramIntegrationView". All fields are combined with a logical 'AND'. */
export type ServerBusinessTelegramIntegrationViewBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessTelegramIntegrationViewBoolExp>>
  _not?: InputMaybe<ServerBusinessTelegramIntegrationViewBoolExp>
  _or?: InputMaybe<Array<ServerBusinessTelegramIntegrationViewBoolExp>>
  businessId?: InputMaybe<ServerIntComparisonExp>
  isUseDefault?: InputMaybe<ServerStringComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "businessTelegramIntegrationView". */
export type ServerBusinessTelegramIntegrationViewOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  isUseDefault?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** select columns of table "businessTelegramIntegrationView" */
export enum ServerBusinessTelegramIntegrationViewSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  IsUseDefault = 'isUseDefault',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "businessTelegramIntegrationView" */
export type ServerBusinessTelegramIntegrationViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBusinessTelegramIntegrationViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBusinessTelegramIntegrationViewStreamCursorValueInput = {
  businessId?: InputMaybe<Scalars['Int']>
  isUseDefault?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

/** Нужна только для типа возвращаемого в функции getBusinessToSwitch */
export type ServerBusinessToSwitch = {
  asClient: Scalars['Boolean']
  asEmployee: Scalars['Boolean']
  id: Scalars['Int']
  name: Scalars['String']
}

/** Boolean expression to filter rows from the table "businessToSwitch". All fields are combined with a logical 'AND'. */
export type ServerBusinessToSwitchBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessToSwitchBoolExp>>
  _not?: InputMaybe<ServerBusinessToSwitchBoolExp>
  _or?: InputMaybe<Array<ServerBusinessToSwitchBoolExp>>
  asClient?: InputMaybe<ServerBooleanComparisonExp>
  asEmployee?: InputMaybe<ServerBooleanComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "businessToSwitch". */
export type ServerBusinessToSwitchOrderBy = {
  asClient?: InputMaybe<ServerOrderBy>
  asEmployee?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** select columns of table "businessToSwitch" */
export enum ServerBusinessToSwitchSelectColumn {
  /** column name */
  AsClient = 'asClient',
  /** column name */
  AsEmployee = 'asEmployee',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "businessToSwitch" */
export type ServerBusinessToSwitchStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBusinessToSwitchStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBusinessToSwitchStreamCursorValueInput = {
  asClient?: InputMaybe<Scalars['Boolean']>
  asEmployee?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

/** Типы бизнесов */
export type ServerBusinessType = {
  id: Scalars['Int']
  name: Scalars['String']
}

/** Boolean expression to filter rows from the table "businessType". All fields are combined with a logical 'AND'. */
export type ServerBusinessTypeBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessTypeBoolExp>>
  _not?: InputMaybe<ServerBusinessTypeBoolExp>
  _or?: InputMaybe<Array<ServerBusinessTypeBoolExp>>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "businessType". */
export type ServerBusinessTypeOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** select columns of table "businessType" */
export enum ServerBusinessTypeSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "businessType" */
export type ServerBusinessTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerBusinessTypeStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerBusinessTypeStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "business" */
export type ServerBusinessAggregate = {
  aggregate: Maybe<ServerBusinessAggregateFields>
  nodes: Array<ServerBusiness>
}

/** aggregate fields of "business" */
export type ServerBusinessAggregateFields = {
  avg: Maybe<ServerBusinessAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerBusinessMaxFields>
  min: Maybe<ServerBusinessMinFields>
  stddev: Maybe<ServerBusinessStddevFields>
  stddev_pop: Maybe<ServerBusinessStddevPopFields>
  stddev_samp: Maybe<ServerBusinessStddevSampFields>
  sum: Maybe<ServerBusinessSumFields>
  var_pop: Maybe<ServerBusinessVarPopFields>
  var_samp: Maybe<ServerBusinessVarSampFields>
  variance: Maybe<ServerBusinessVarianceFields>
}

/** aggregate fields of "business" */
export type ServerBusinessAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerBusinessSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type ServerBusinessAvgFields = {
  id: Maybe<Scalars['Float']>
  ownerId: Maybe<Scalars['Float']>
  typeId: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "business". All fields are combined with a logical 'AND'. */
export type ServerBusinessBoolExp = {
  _and?: InputMaybe<Array<ServerBusinessBoolExp>>
  _not?: InputMaybe<ServerBusinessBoolExp>
  _or?: InputMaybe<Array<ServerBusinessBoolExp>>
  billingInfos?: InputMaybe<ServerBillingInfoBoolExp>
  bookingClientSetting?: InputMaybe<ServerBookingClientSettingsBoolExp>
  branches?: InputMaybe<ServerBranchBoolExp>
  businessPromoCodes?: InputMaybe<ServerBusinessPromoCodeBoolExp>
  businessSettings?: InputMaybe<ServerBusinessSettingsBoolExp>
  businessType?: InputMaybe<ServerBusinessTypeBoolExp>
  clients?: InputMaybe<ServerClientBoolExp>
  clients_aggregate?: InputMaybe<ServerClientAggregateBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  employees?: InputMaybe<ServerEmployeeBoolExp>
  employees_aggregate?: InputMaybe<ServerEmployeeAggregateBoolExp>
  id?: InputMaybe<ServerIntComparisonExp>
  landingSettings?: InputMaybe<ServerBusinessLandingBoolExp>
  logo?: InputMaybe<ServerStringComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  onHold?: InputMaybe<ServerTimestamptzComparisonExp>
  owner?: InputMaybe<ServerEmployeeBoolExp>
  ownerId?: InputMaybe<ServerIntComparisonExp>
  photo?: InputMaybe<ServerStringComparisonExp>
  typeId?: InputMaybe<ServerIntComparisonExp>
  waitingLists?: InputMaybe<ServerWaitingListBoolExp>
}

/** input type for incrementing numeric columns in table "business" */
export type ServerBusinessIncInput = {
  typeId?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type ServerBusinessMaxFields = {
  createdAt: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
  logo: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  onHold: Maybe<Scalars['timestamptz']>
  ownerId: Maybe<Scalars['Int']>
  photo: Maybe<Scalars['String']>
  typeId: Maybe<Scalars['Int']>
}

/** aggregate min on columns */
export type ServerBusinessMinFields = {
  createdAt: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
  logo: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  onHold: Maybe<Scalars['timestamptz']>
  ownerId: Maybe<Scalars['Int']>
  photo: Maybe<Scalars['String']>
  typeId: Maybe<Scalars['Int']>
}

/** response of any mutation on the table "business" */
export type ServerBusinessMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerBusiness>
}

/** Ordering options when selecting data from "business". */
export type ServerBusinessOrderBy = {
  billingInfos_aggregate?: InputMaybe<ServerBillingInfoAggregateOrderBy>
  bookingClientSetting_aggregate?: InputMaybe<ServerBookingClientSettingsAggregateOrderBy>
  branches_aggregate?: InputMaybe<ServerBranchAggregateOrderBy>
  businessPromoCodes_aggregate?: InputMaybe<ServerBusinessPromoCodeAggregateOrderBy>
  businessSettings?: InputMaybe<ServerBusinessSettingsOrderBy>
  businessType?: InputMaybe<ServerBusinessTypeOrderBy>
  clients_aggregate?: InputMaybe<ServerClientAggregateOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  employees_aggregate?: InputMaybe<ServerEmployeeAggregateOrderBy>
  id?: InputMaybe<ServerOrderBy>
  landingSettings?: InputMaybe<ServerBusinessLandingOrderBy>
  logo?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  onHold?: InputMaybe<ServerOrderBy>
  owner?: InputMaybe<ServerEmployeeOrderBy>
  ownerId?: InputMaybe<ServerOrderBy>
  photo?: InputMaybe<ServerOrderBy>
  typeId?: InputMaybe<ServerOrderBy>
  waitingLists_aggregate?: InputMaybe<ServerWaitingListAggregateOrderBy>
}

/** primary key columns input for table: business */
export type ServerBusinessPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "business" */
export enum ServerBusinessSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  OnHold = 'onHold',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  Photo = 'photo',
  /** column name */
  TypeId = 'typeId',
}

/** input type for updating data in table "business" */
export type ServerBusinessSetInput = {
  logo?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['String']>
  typeId?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type ServerBusinessStddevFields = {
  id: Maybe<Scalars['Float']>
  ownerId: Maybe<Scalars['Float']>
  typeId: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type ServerBusinessStddevPopFields = {
  id: Maybe<Scalars['Float']>
  ownerId: Maybe<Scalars['Float']>
  typeId: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type ServerBusinessStddevSampFields = {
  id: Maybe<Scalars['Float']>
  ownerId: Maybe<Scalars['Float']>
  typeId: Maybe<Scalars['Float']>
}

/** aggregate sum on columns */
export type ServerBusinessSumFields = {
  id: Maybe<Scalars['Int']>
  ownerId: Maybe<Scalars['Int']>
  typeId: Maybe<Scalars['Int']>
}

export type ServerBusinessUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerBusinessIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerBusinessSetInput>
  /** filter the rows which have to be updated */
  where: ServerBusinessBoolExp
}

/** aggregate var_pop on columns */
export type ServerBusinessVarPopFields = {
  id: Maybe<Scalars['Float']>
  ownerId: Maybe<Scalars['Float']>
  typeId: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type ServerBusinessVarSampFields = {
  id: Maybe<Scalars['Float']>
  ownerId: Maybe<Scalars['Float']>
  typeId: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type ServerBusinessVarianceFields = {
  id: Maybe<Scalars['Float']>
  ownerId: Maybe<Scalars['Float']>
  typeId: Maybe<Scalars['Float']>
}

/** columns and relationships of "card" */
export type ServerCard = {
  activatedAt: Maybe<Scalars['timestamptz']>
  canceledDate: Maybe<Scalars['timestamptz']>
  cardPeriod: Scalars['Int']
  /** An object relationship */
  cardTemplate: ServerCardTemplate
  cardTemplateId: Scalars['Int']
  /** An object relationship */
  client: Maybe<ServerClient>
  clientId: Maybe<Scalars['Int']>
  code: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  id: Scalars['Int']
  /** { [serviceId: string]: {used: number, quantity: number} } */
  subscriptionInfo: Maybe<Scalars['jsonb']>
  /** An array relationship */
  subscriptionTransactions: Array<ServerTransaction>
  /** An aggregate relationship */
  subscriptionTransactions_aggregate: ServerTransactionAggregate
  /** An array relationship */
  transactionCards: Array<ServerTransactionCard>
  type: Scalars['smallint']
}

/** columns and relationships of "card" */
export type ServerCardSubscriptionInfoArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "card" */
export type ServerCardSubscriptionTransactionsArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

/** columns and relationships of "card" */
export type ServerCardSubscriptionTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

/** columns and relationships of "card" */
export type ServerCardTransactionCardsArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionCardSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionCardOrderBy>>
  where?: InputMaybe<ServerTransactionCardBoolExp>
}

/** columns and relationships of "cardTemplate" */
export type ServerCardTemplate = {
  archived: Maybe<Scalars['timestamptz']>
  availableToAllBranches: Scalars['Boolean']
  /** An object relationship */
  branch: Maybe<ServerBranch>
  branchId: Maybe<Scalars['Int']>
  cardPeriod: Scalars['Int']
  /** An array relationship */
  cardTemplateServices: Array<ServerCardTemplateService>
  /** An array relationship */
  cards: Array<ServerCard>
  createdAt: Scalars['timestamptz']
  discount: Maybe<Scalars['numeric']>
  id: Scalars['Int']
  name: Scalars['String']
  price: Scalars['numeric']
  type: Scalars['Int']
}

/** columns and relationships of "cardTemplate" */
export type ServerCardTemplateCardTemplateServicesArgs = {
  distinct_on?: InputMaybe<Array<ServerCardTemplateServiceSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerCardTemplateServiceOrderBy>>
  where?: InputMaybe<ServerCardTemplateServiceBoolExp>
}

/** columns and relationships of "cardTemplate" */
export type ServerCardTemplateCardsArgs = {
  distinct_on?: InputMaybe<Array<ServerCardSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerCardOrderBy>>
  where?: InputMaybe<ServerCardBoolExp>
}

/** columns and relationships of "cardTemplateNameView" */
export type ServerCardTemplateNameView = {
  id: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "cardTemplateNameView". All fields are combined with a logical 'AND'. */
export type ServerCardTemplateNameViewBoolExp = {
  _and?: InputMaybe<Array<ServerCardTemplateNameViewBoolExp>>
  _not?: InputMaybe<ServerCardTemplateNameViewBoolExp>
  _or?: InputMaybe<Array<ServerCardTemplateNameViewBoolExp>>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
}

/** response of any mutation on the table "cardTemplateNameView" */
export type ServerCardTemplateNameViewMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerCardTemplateNameView>
}

/** Ordering options when selecting data from "cardTemplateNameView". */
export type ServerCardTemplateNameViewOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** select columns of table "cardTemplateNameView" */
export enum ServerCardTemplateNameViewSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "cardTemplateNameView" */
export type ServerCardTemplateNameViewSetInput = {
  name?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "cardTemplateNameView" */
export type ServerCardTemplateNameViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerCardTemplateNameViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerCardTemplateNameViewStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

export type ServerCardTemplateNameViewUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerCardTemplateNameViewSetInput>
  /** filter the rows which have to be updated */
  where: ServerCardTemplateNameViewBoolExp
}

/** columns and relationships of "cardTemplateService" */
export type ServerCardTemplateService = {
  businessId: Scalars['Int']
  /** An object relationship */
  cardTemplate: ServerCardTemplate
  cardTemplateId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  service: ServerService
  serviceId: Scalars['Int']
  servicePrice: Scalars['numeric']
  serviceQuantity: Scalars['Int']
}

/** order by aggregate values of table "cardTemplateService" */
export type ServerCardTemplateServiceAggregateOrderBy = {
  avg?: InputMaybe<ServerCardTemplateServiceAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerCardTemplateServiceMaxOrderBy>
  min?: InputMaybe<ServerCardTemplateServiceMinOrderBy>
  stddev?: InputMaybe<ServerCardTemplateServiceStddevOrderBy>
  stddev_pop?: InputMaybe<ServerCardTemplateServiceStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerCardTemplateServiceStddevSampOrderBy>
  sum?: InputMaybe<ServerCardTemplateServiceSumOrderBy>
  var_pop?: InputMaybe<ServerCardTemplateServiceVarPopOrderBy>
  var_samp?: InputMaybe<ServerCardTemplateServiceVarSampOrderBy>
  variance?: InputMaybe<ServerCardTemplateServiceVarianceOrderBy>
}

/** input type for inserting array relation for remote table "cardTemplateService" */
export type ServerCardTemplateServiceArrRelInsertInput = {
  data: Array<ServerCardTemplateServiceInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerCardTemplateServiceOnConflict>
}

/** order by avg() on columns of table "cardTemplateService" */
export type ServerCardTemplateServiceAvgOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  servicePrice?: InputMaybe<ServerOrderBy>
  serviceQuantity?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "cardTemplateService". All fields are combined with a logical 'AND'. */
export type ServerCardTemplateServiceBoolExp = {
  _and?: InputMaybe<Array<ServerCardTemplateServiceBoolExp>>
  _not?: InputMaybe<ServerCardTemplateServiceBoolExp>
  _or?: InputMaybe<Array<ServerCardTemplateServiceBoolExp>>
  businessId?: InputMaybe<ServerIntComparisonExp>
  cardTemplate?: InputMaybe<ServerCardTemplateBoolExp>
  cardTemplateId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  service?: InputMaybe<ServerServiceBoolExp>
  serviceId?: InputMaybe<ServerIntComparisonExp>
  servicePrice?: InputMaybe<ServerNumericComparisonExp>
  serviceQuantity?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "cardTemplateService" */
export enum ServerCardTemplateServiceConstraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionServicesPkey = 'subscriptionServices_pkey',
}

/** input type for incrementing numeric columns in table "cardTemplateService" */
export type ServerCardTemplateServiceIncInput = {
  cardTemplateId?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
  servicePrice?: InputMaybe<Scalars['numeric']>
  serviceQuantity?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "cardTemplateService" */
export type ServerCardTemplateServiceInsertInput = {
  cardTemplate?: InputMaybe<ServerCardTemplateObjRelInsertInput>
  cardTemplateId?: InputMaybe<Scalars['Int']>
  service?: InputMaybe<ServerServiceObjRelInsertInput>
  serviceId?: InputMaybe<Scalars['Int']>
  servicePrice?: InputMaybe<Scalars['numeric']>
  serviceQuantity?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "cardTemplateService" */
export type ServerCardTemplateServiceMaxOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  servicePrice?: InputMaybe<ServerOrderBy>
  serviceQuantity?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "cardTemplateService" */
export type ServerCardTemplateServiceMinOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  servicePrice?: InputMaybe<ServerOrderBy>
  serviceQuantity?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "cardTemplateService" */
export type ServerCardTemplateServiceMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerCardTemplateService>
}

/** on_conflict condition type for table "cardTemplateService" */
export type ServerCardTemplateServiceOnConflict = {
  constraint: ServerCardTemplateServiceConstraint
  update_columns?: Array<ServerCardTemplateServiceUpdateColumn>
  where?: InputMaybe<ServerCardTemplateServiceBoolExp>
}

/** Ordering options when selecting data from "cardTemplateService". */
export type ServerCardTemplateServiceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  cardTemplate?: InputMaybe<ServerCardTemplateOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  service?: InputMaybe<ServerServiceOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  servicePrice?: InputMaybe<ServerOrderBy>
  serviceQuantity?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: cardTemplateService */
export type ServerCardTemplateServicePkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "cardTemplateService" */
export enum ServerCardTemplateServiceSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  CardTemplateId = 'cardTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  ServicePrice = 'servicePrice',
  /** column name */
  ServiceQuantity = 'serviceQuantity',
}

/** input type for updating data in table "cardTemplateService" */
export type ServerCardTemplateServiceSetInput = {
  cardTemplateId?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
  servicePrice?: InputMaybe<Scalars['numeric']>
  serviceQuantity?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "cardTemplateService" */
export type ServerCardTemplateServiceStddevOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  servicePrice?: InputMaybe<ServerOrderBy>
  serviceQuantity?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "cardTemplateService" */
export type ServerCardTemplateServiceStddevPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  servicePrice?: InputMaybe<ServerOrderBy>
  serviceQuantity?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "cardTemplateService" */
export type ServerCardTemplateServiceStddevSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  servicePrice?: InputMaybe<ServerOrderBy>
  serviceQuantity?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "cardTemplateService" */
export type ServerCardTemplateServiceSumOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  servicePrice?: InputMaybe<ServerOrderBy>
  serviceQuantity?: InputMaybe<ServerOrderBy>
}

/** update columns of table "cardTemplateService" */
export enum ServerCardTemplateServiceUpdateColumn {
  /** column name */
  CardTemplateId = 'cardTemplateId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  ServicePrice = 'servicePrice',
  /** column name */
  ServiceQuantity = 'serviceQuantity',
}

export type ServerCardTemplateServiceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerCardTemplateServiceIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerCardTemplateServiceSetInput>
  /** filter the rows which have to be updated */
  where: ServerCardTemplateServiceBoolExp
}

/** order by var_pop() on columns of table "cardTemplateService" */
export type ServerCardTemplateServiceVarPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  servicePrice?: InputMaybe<ServerOrderBy>
  serviceQuantity?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "cardTemplateService" */
export type ServerCardTemplateServiceVarSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  servicePrice?: InputMaybe<ServerOrderBy>
  serviceQuantity?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "cardTemplateService" */
export type ServerCardTemplateServiceVarianceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  servicePrice?: InputMaybe<ServerOrderBy>
  serviceQuantity?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "cardTemplate". All fields are combined with a logical 'AND'. */
export type ServerCardTemplateBoolExp = {
  _and?: InputMaybe<Array<ServerCardTemplateBoolExp>>
  _not?: InputMaybe<ServerCardTemplateBoolExp>
  _or?: InputMaybe<Array<ServerCardTemplateBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  availableToAllBranches?: InputMaybe<ServerBooleanComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  cardPeriod?: InputMaybe<ServerIntComparisonExp>
  cardTemplateServices?: InputMaybe<ServerCardTemplateServiceBoolExp>
  cards?: InputMaybe<ServerCardBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  discount?: InputMaybe<ServerNumericComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  price?: InputMaybe<ServerNumericComparisonExp>
  type?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "cardTemplate" */
export enum ServerCardTemplateConstraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionsPkey = 'subscriptions_pkey',
}

/** input type for incrementing numeric columns in table "cardTemplate" */
export type ServerCardTemplateIncInput = {
  cardPeriod?: InputMaybe<Scalars['Int']>
  discount?: InputMaybe<Scalars['numeric']>
  price?: InputMaybe<Scalars['numeric']>
  type?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "cardTemplate" */
export type ServerCardTemplateInsertInput = {
  availableToAllBranches?: InputMaybe<Scalars['Boolean']>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  cardPeriod?: InputMaybe<Scalars['Int']>
  cardTemplateServices?: InputMaybe<ServerCardTemplateServiceArrRelInsertInput>
  cards?: InputMaybe<ServerCardArrRelInsertInput>
  discount?: InputMaybe<Scalars['numeric']>
  name?: InputMaybe<Scalars['String']>
  price?: InputMaybe<Scalars['numeric']>
  type?: InputMaybe<Scalars['Int']>
}

/** response of any mutation on the table "cardTemplate" */
export type ServerCardTemplateMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerCardTemplate>
}

/** input type for inserting object relation for remote table "cardTemplate" */
export type ServerCardTemplateObjRelInsertInput = {
  data: ServerCardTemplateInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerCardTemplateOnConflict>
}

/** on_conflict condition type for table "cardTemplate" */
export type ServerCardTemplateOnConflict = {
  constraint: ServerCardTemplateConstraint
  update_columns?: Array<ServerCardTemplateUpdateColumn>
  where?: InputMaybe<ServerCardTemplateBoolExp>
}

/** Ordering options when selecting data from "cardTemplate". */
export type ServerCardTemplateOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  availableToAllBranches?: InputMaybe<ServerOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cardPeriod?: InputMaybe<ServerOrderBy>
  cardTemplateServices_aggregate?: InputMaybe<ServerCardTemplateServiceAggregateOrderBy>
  cards_aggregate?: InputMaybe<ServerCardAggregateOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: cardTemplate */
export type ServerCardTemplatePkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "cardTemplate" */
export enum ServerCardTemplateSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  AvailableToAllBranches = 'availableToAllBranches',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  CardPeriod = 'cardPeriod',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "cardTemplate" */
export type ServerCardTemplateSetInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
  cardPeriod?: InputMaybe<Scalars['Int']>
  discount?: InputMaybe<Scalars['numeric']>
  name?: InputMaybe<Scalars['String']>
  price?: InputMaybe<Scalars['numeric']>
  type?: InputMaybe<Scalars['Int']>
}

/** update columns of table "cardTemplate" */
export enum ServerCardTemplateUpdateColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  CardPeriod = 'cardPeriod',
  /** column name */
  Discount = 'discount',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Type = 'type',
}

export type ServerCardTemplateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerCardTemplateIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerCardTemplateSetInput>
  /** filter the rows which have to be updated */
  where: ServerCardTemplateBoolExp
}

/** order by aggregate values of table "card" */
export type ServerCardAggregateOrderBy = {
  avg?: InputMaybe<ServerCardAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerCardMaxOrderBy>
  min?: InputMaybe<ServerCardMinOrderBy>
  stddev?: InputMaybe<ServerCardStddevOrderBy>
  stddev_pop?: InputMaybe<ServerCardStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerCardStddevSampOrderBy>
  sum?: InputMaybe<ServerCardSumOrderBy>
  var_pop?: InputMaybe<ServerCardVarPopOrderBy>
  var_samp?: InputMaybe<ServerCardVarSampOrderBy>
  variance?: InputMaybe<ServerCardVarianceOrderBy>
}

/** input type for inserting array relation for remote table "card" */
export type ServerCardArrRelInsertInput = {
  data: Array<ServerCardInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerCardOnConflict>
}

/** order by avg() on columns of table "card" */
export type ServerCardAvgOrderBy = {
  cardPeriod?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "card". All fields are combined with a logical 'AND'. */
export type ServerCardBoolExp = {
  _and?: InputMaybe<Array<ServerCardBoolExp>>
  _not?: InputMaybe<ServerCardBoolExp>
  _or?: InputMaybe<Array<ServerCardBoolExp>>
  activatedAt?: InputMaybe<ServerTimestamptzComparisonExp>
  canceledDate?: InputMaybe<ServerTimestamptzComparisonExp>
  cardPeriod?: InputMaybe<ServerIntComparisonExp>
  cardTemplate?: InputMaybe<ServerCardTemplateBoolExp>
  cardTemplateId?: InputMaybe<ServerIntComparisonExp>
  client?: InputMaybe<ServerClientBoolExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  code?: InputMaybe<ServerStringComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  subscriptionInfo?: InputMaybe<ServerJsonbComparisonExp>
  subscriptionTransactions?: InputMaybe<ServerTransactionBoolExp>
  subscriptionTransactions_aggregate?: InputMaybe<ServerTransactionAggregateBoolExp>
  transactionCards?: InputMaybe<ServerTransactionCardBoolExp>
  type?: InputMaybe<ServerSmallintComparisonExp>
}

/** unique or primary key constraints on table "card" */
export enum ServerCardConstraint {
  /** unique or primary key constraint on columns "id" */
  ClientsSubscriptionsPkey = 'clientsSubscriptions_pkey',
}

/** input type for incrementing numeric columns in table "card" */
export type ServerCardIncInput = {
  cardPeriod?: InputMaybe<Scalars['Int']>
  clientId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "card" */
export type ServerCardInsertInput = {
  activatedAt?: InputMaybe<Scalars['timestamptz']>
  canceledDate?: InputMaybe<Scalars['timestamptz']>
  cardPeriod?: InputMaybe<Scalars['Int']>
  cardTemplate?: InputMaybe<ServerCardTemplateObjRelInsertInput>
  cardTemplateId?: InputMaybe<Scalars['Int']>
  client?: InputMaybe<ServerClientObjRelInsertInput>
  clientId?: InputMaybe<Scalars['Int']>
  code?: InputMaybe<Scalars['String']>
  subscriptionTransactions?: InputMaybe<ServerTransactionArrRelInsertInput>
  transactionCards?: InputMaybe<ServerTransactionCardArrRelInsertInput>
  type?: InputMaybe<Scalars['smallint']>
}

/** order by max() on columns of table "card" */
export type ServerCardMaxOrderBy = {
  activatedAt?: InputMaybe<ServerOrderBy>
  canceledDate?: InputMaybe<ServerOrderBy>
  cardPeriod?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  code?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "card" */
export type ServerCardMinOrderBy = {
  activatedAt?: InputMaybe<ServerOrderBy>
  canceledDate?: InputMaybe<ServerOrderBy>
  cardPeriod?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  code?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "card" */
export type ServerCardMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerCard>
}

/** input type for inserting object relation for remote table "card" */
export type ServerCardObjRelInsertInput = {
  data: ServerCardInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerCardOnConflict>
}

/** on_conflict condition type for table "card" */
export type ServerCardOnConflict = {
  constraint: ServerCardConstraint
  update_columns?: Array<ServerCardUpdateColumn>
  where?: InputMaybe<ServerCardBoolExp>
}

/** Ordering options when selecting data from "card". */
export type ServerCardOrderBy = {
  activatedAt?: InputMaybe<ServerOrderBy>
  canceledDate?: InputMaybe<ServerOrderBy>
  cardPeriod?: InputMaybe<ServerOrderBy>
  cardTemplate?: InputMaybe<ServerCardTemplateOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  code?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  subscriptionInfo?: InputMaybe<ServerOrderBy>
  subscriptionTransactions_aggregate?: InputMaybe<ServerTransactionAggregateOrderBy>
  transactionCards_aggregate?: InputMaybe<ServerTransactionCardAggregateOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: card */
export type ServerCardPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "card" */
export enum ServerCardSelectColumn {
  /** column name */
  ActivatedAt = 'activatedAt',
  /** column name */
  CanceledDate = 'canceledDate',
  /** column name */
  CardPeriod = 'cardPeriod',
  /** column name */
  CardTemplateId = 'cardTemplateId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "card" */
export type ServerCardSetInput = {
  activatedAt?: InputMaybe<Scalars['timestamptz']>
  canceledDate?: InputMaybe<Scalars['timestamptz']>
  cardPeriod?: InputMaybe<Scalars['Int']>
  clientId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "card" */
export type ServerCardStddevOrderBy = {
  cardPeriod?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "card" */
export type ServerCardStddevPopOrderBy = {
  cardPeriod?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "card" */
export type ServerCardStddevSampOrderBy = {
  cardPeriod?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "card" */
export type ServerCardSumOrderBy = {
  cardPeriod?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** update columns of table "card" */
export enum ServerCardUpdateColumn {
  /** column name */
  ActivatedAt = 'activatedAt',
  /** column name */
  CanceledDate = 'canceledDate',
  /** column name */
  CardPeriod = 'cardPeriod',
  /** column name */
  ClientId = 'clientId',
}

export type ServerCardUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerCardIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerCardSetInput>
  /** filter the rows which have to be updated */
  where: ServerCardBoolExp
}

/** order by var_pop() on columns of table "card" */
export type ServerCardVarPopOrderBy = {
  cardPeriod?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "card" */
export type ServerCardVarSampOrderBy = {
  cardPeriod?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "card" */
export type ServerCardVarianceOrderBy = {
  cardPeriod?: InputMaybe<ServerOrderBy>
  cardTemplateId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

export enum ServerCheckAvailabilityForBookingRoles {
  Client = 'client',
  Employee = 'employee',
}

/** Клиенты */
export type ServerClient = {
  additionalInformation: Maybe<Scalars['String']>
  /** An array relationship */
  archivedInBranches: Array<ServerClientBranchArchived>
  /** An object relationship */
  author: ServerEmployee
  birthDate: Maybe<Scalars['timestamptz']>
  /** An array relationship */
  bookings: Array<ServerBooking>
  /** An aggregate relationship */
  bookings_aggregate: ServerBookingAggregate
  calendarId: Maybe<Scalars['String']>
  /** An array relationship */
  cards: Array<ServerCard>
  /** An array relationship */
  clientDocuments: Array<ServerClientDocument>
  /** An array relationship */
  clientNotes: Array<ServerClientNote>
  /** An array relationship */
  clientRelationsWhereClientIsFirst: Array<ServerClientRelation>
  /** An array relationship */
  clientRelationsWhereClientIsSecond: Array<ServerClientRelation>
  /** An object relationship */
  clientSettings: Maybe<ServerClientSettings>
  /** An array relationship */
  clientTags: Array<ServerClientTag>
  createdAt: Scalars['timestamptz']
  email: Maybe<Scalars['String']>
  /** An object relationship */
  emailView: Maybe<ServerClientEmailView>
  firstName: Scalars['String']
  gender: Maybe<Scalars['String']>
  /** An array relationship */
  groupBookingClients: Array<ServerBookingClient>
  /** A computed field, executes function "clientHasTelegram" */
  hasTelegram: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "clientHasViber" */
  hasViber: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  initialBranchId: Scalars['Int']
  lastName: Maybe<Scalars['String']>
  /** An object relationship */
  lead: Maybe<ServerLead>
  leadId: Maybe<Scalars['Int']>
  /** An object relationship */
  managingEmployee: Maybe<ServerEmployee>
  managingEmployeeId: Maybe<Scalars['Int']>
  maximumDebt: Maybe<Scalars['numeric']>
  middleName: Maybe<Scalars['String']>
  /** An array relationship */
  notifications: Array<ServerNotification>
  /** An aggregate relationship */
  notifications_aggregate: ServerNotificationAggregate
  phone: Maybe<Scalars['String']>
  /** An object relationship */
  phoneView: Maybe<ServerClientPhoneView>
  photo: Maybe<Scalars['String']>
  /** An array relationship */
  transactions: Array<ServerTransaction>
  /** An aggregate relationship */
  transactions_aggregate: ServerTransactionAggregate
}

/** Клиенты */
export type ServerClientArchivedInBranchesArgs = {
  distinct_on?: InputMaybe<Array<ServerClientBranchArchivedSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientBranchArchivedOrderBy>>
  where?: InputMaybe<ServerClientBranchArchivedBoolExp>
}

/** Клиенты */
export type ServerClientBookingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** Клиенты */
export type ServerClientBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** Клиенты */
export type ServerClientCardsArgs = {
  distinct_on?: InputMaybe<Array<ServerCardSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerCardOrderBy>>
  where?: InputMaybe<ServerCardBoolExp>
}

/** Клиенты */
export type ServerClientClientDocumentsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientDocumentSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientDocumentOrderBy>>
  where?: InputMaybe<ServerClientDocumentBoolExp>
}

/** Клиенты */
export type ServerClientClientNotesArgs = {
  distinct_on?: InputMaybe<Array<ServerClientNoteSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientNoteOrderBy>>
  where?: InputMaybe<ServerClientNoteBoolExp>
}

/** Клиенты */
export type ServerClientClientRelationsWhereClientIsFirstArgs = {
  distinct_on?: InputMaybe<Array<ServerClientRelationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientRelationOrderBy>>
  where?: InputMaybe<ServerClientRelationBoolExp>
}

/** Клиенты */
export type ServerClientClientRelationsWhereClientIsSecondArgs = {
  distinct_on?: InputMaybe<Array<ServerClientRelationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientRelationOrderBy>>
  where?: InputMaybe<ServerClientRelationBoolExp>
}

/** Клиенты */
export type ServerClientClientTagsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientTagSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientTagOrderBy>>
  where?: InputMaybe<ServerClientTagBoolExp>
}

/** Клиенты */
export type ServerClientGroupBookingClientsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingClientSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingClientOrderBy>>
  where?: InputMaybe<ServerBookingClientBoolExp>
}

/** Клиенты */
export type ServerClientNotificationsArgs = {
  distinct_on?: InputMaybe<Array<ServerNotificationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerNotificationOrderBy>>
  where?: InputMaybe<ServerNotificationBoolExp>
}

/** Клиенты */
export type ServerClientNotificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerNotificationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerNotificationOrderBy>>
  where?: InputMaybe<ServerNotificationBoolExp>
}

/** Клиенты */
export type ServerClientTransactionsArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

/** Клиенты */
export type ServerClientTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

/** columns and relationships of "clientBalanceView" */
export type ServerClientBalanceView = {
  balance: Maybe<Scalars['numeric']>
  branchId: Maybe<Scalars['Int']>
  clientId: Maybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "clientBalanceView". All fields are combined with a logical 'AND'. */
export type ServerClientBalanceViewBoolExp = {
  _and?: InputMaybe<Array<ServerClientBalanceViewBoolExp>>
  _not?: InputMaybe<ServerClientBalanceViewBoolExp>
  _or?: InputMaybe<Array<ServerClientBalanceViewBoolExp>>
  balance?: InputMaybe<ServerNumericComparisonExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
}

/** Ordering options when selecting data from "clientBalanceView". */
export type ServerClientBalanceViewOrderBy = {
  balance?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "clientBalanceView" */
export enum ServerClientBalanceViewSelectColumn {
  /** column name */
  Balance = 'balance',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  ClientId = 'clientId',
}

/** Streaming cursor of the table "clientBalanceView" */
export type ServerClientBalanceViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerClientBalanceViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerClientBalanceViewStreamCursorValueInput = {
  balance?: InputMaybe<Scalars['numeric']>
  branchId?: InputMaybe<Scalars['Int']>
  clientId?: InputMaybe<Scalars['Int']>
}

/** On which branch the client is archived */
export type ServerClientBranchArchived = {
  archived: Scalars['timestamptz']
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** An object relationship */
  client: ServerClient
  clientId: Scalars['Int']
  id: Scalars['Int']
}

/** order by aggregate values of table "clientBranchArchived" */
export type ServerClientBranchArchivedAggregateOrderBy = {
  avg?: InputMaybe<ServerClientBranchArchivedAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerClientBranchArchivedMaxOrderBy>
  min?: InputMaybe<ServerClientBranchArchivedMinOrderBy>
  stddev?: InputMaybe<ServerClientBranchArchivedStddevOrderBy>
  stddev_pop?: InputMaybe<ServerClientBranchArchivedStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerClientBranchArchivedStddevSampOrderBy>
  sum?: InputMaybe<ServerClientBranchArchivedSumOrderBy>
  var_pop?: InputMaybe<ServerClientBranchArchivedVarPopOrderBy>
  var_samp?: InputMaybe<ServerClientBranchArchivedVarSampOrderBy>
  variance?: InputMaybe<ServerClientBranchArchivedVarianceOrderBy>
}

/** input type for inserting array relation for remote table "clientBranchArchived" */
export type ServerClientBranchArchivedArrRelInsertInput = {
  data: Array<ServerClientBranchArchivedInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerClientBranchArchivedOnConflict>
}

/** order by avg() on columns of table "clientBranchArchived" */
export type ServerClientBranchArchivedAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "clientBranchArchived". All fields are combined with a logical 'AND'. */
export type ServerClientBranchArchivedBoolExp = {
  _and?: InputMaybe<Array<ServerClientBranchArchivedBoolExp>>
  _not?: InputMaybe<ServerClientBranchArchivedBoolExp>
  _or?: InputMaybe<Array<ServerClientBranchArchivedBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  client?: InputMaybe<ServerClientBoolExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "clientBranchArchived" */
export enum ServerClientBranchArchivedConstraint {
  /** unique or primary key constraint on columns "id" */
  ClientBranchArchivedPkey = 'clientBranchArchived_pkey',
}

/** input type for inserting data into table "clientBranchArchived" */
export type ServerClientBranchArchivedInsertInput = {
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  client?: InputMaybe<ServerClientObjRelInsertInput>
  clientId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "clientBranchArchived" */
export type ServerClientBranchArchivedMaxOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "clientBranchArchived" */
export type ServerClientBranchArchivedMinOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "clientBranchArchived" */
export type ServerClientBranchArchivedMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerClientBranchArchived>
}

/** on_conflict condition type for table "clientBranchArchived" */
export type ServerClientBranchArchivedOnConflict = {
  constraint: ServerClientBranchArchivedConstraint
  update_columns?: Array<ServerClientBranchArchivedUpdateColumn>
  where?: InputMaybe<ServerClientBranchArchivedBoolExp>
}

/** Ordering options when selecting data from "clientBranchArchived". */
export type ServerClientBranchArchivedOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** select columns of table "clientBranchArchived" */
export enum ServerClientBranchArchivedSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Id = 'id',
}

/** order by stddev() on columns of table "clientBranchArchived" */
export type ServerClientBranchArchivedStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "clientBranchArchived" */
export type ServerClientBranchArchivedStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "clientBranchArchived" */
export type ServerClientBranchArchivedStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "clientBranchArchived" */
export type ServerClientBranchArchivedSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "clientBranchArchived" (current role has no relevant permissions) */
export enum ServerClientBranchArchivedUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "clientBranchArchived" */
export type ServerClientBranchArchivedVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "clientBranchArchived" */
export type ServerClientBranchArchivedVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "clientBranchArchived" */
export type ServerClientBranchArchivedVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "clientDocument" */
export type ServerClientDocument = {
  /** An object relationship */
  author: ServerEmployee
  authorId: Scalars['Int']
  /** An object relationship */
  client: ServerClient
  clientId: Scalars['Int']
  createdAt: Scalars['timestamptz']
  endDate: Maybe<Scalars['timestamptz']>
  fileUrl: Maybe<Scalars['String']>
  id: Scalars['Int']
  /** An object relationship */
  managingEmployee: Maybe<ServerEmployee>
  managingEmployeeId: Maybe<Scalars['Int']>
  name: Scalars['String']
  startDate: Maybe<Scalars['timestamptz']>
  type: Scalars['smallint']
}

/** order by aggregate values of table "clientDocument" */
export type ServerClientDocumentAggregateOrderBy = {
  avg?: InputMaybe<ServerClientDocumentAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerClientDocumentMaxOrderBy>
  min?: InputMaybe<ServerClientDocumentMinOrderBy>
  stddev?: InputMaybe<ServerClientDocumentStddevOrderBy>
  stddev_pop?: InputMaybe<ServerClientDocumentStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerClientDocumentStddevSampOrderBy>
  sum?: InputMaybe<ServerClientDocumentSumOrderBy>
  var_pop?: InputMaybe<ServerClientDocumentVarPopOrderBy>
  var_samp?: InputMaybe<ServerClientDocumentVarSampOrderBy>
  variance?: InputMaybe<ServerClientDocumentVarianceOrderBy>
}

/** input type for inserting array relation for remote table "clientDocument" */
export type ServerClientDocumentArrRelInsertInput = {
  data: Array<ServerClientDocumentInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerClientDocumentOnConflict>
}

/** order by avg() on columns of table "clientDocument" */
export type ServerClientDocumentAvgOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "clientDocument". All fields are combined with a logical 'AND'. */
export type ServerClientDocumentBoolExp = {
  _and?: InputMaybe<Array<ServerClientDocumentBoolExp>>
  _not?: InputMaybe<ServerClientDocumentBoolExp>
  _or?: InputMaybe<Array<ServerClientDocumentBoolExp>>
  author?: InputMaybe<ServerEmployeeBoolExp>
  authorId?: InputMaybe<ServerIntComparisonExp>
  client?: InputMaybe<ServerClientBoolExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  endDate?: InputMaybe<ServerTimestamptzComparisonExp>
  fileUrl?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  managingEmployee?: InputMaybe<ServerEmployeeBoolExp>
  managingEmployeeId?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  startDate?: InputMaybe<ServerTimestamptzComparisonExp>
  type?: InputMaybe<ServerSmallintComparisonExp>
}

/** unique or primary key constraints on table "clientDocument" */
export enum ServerClientDocumentConstraint {
  /** unique or primary key constraint on columns "id" */
  ClientDocumentPkey = 'clientDocument_pkey',
}

/** input type for incrementing numeric columns in table "clientDocument" */
export type ServerClientDocumentIncInput = {
  managingEmployeeId?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<Scalars['smallint']>
}

/** input type for inserting data into table "clientDocument" */
export type ServerClientDocumentInsertInput = {
  author?: InputMaybe<ServerEmployeeObjRelInsertInput>
  client?: InputMaybe<ServerClientObjRelInsertInput>
  clientId?: InputMaybe<Scalars['Int']>
  endDate?: InputMaybe<Scalars['timestamptz']>
  fileUrl?: InputMaybe<Scalars['String']>
  managingEmployee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  managingEmployeeId?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['timestamptz']>
  type?: InputMaybe<Scalars['smallint']>
}

/** order by max() on columns of table "clientDocument" */
export type ServerClientDocumentMaxOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  endDate?: InputMaybe<ServerOrderBy>
  fileUrl?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  startDate?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "clientDocument" */
export type ServerClientDocumentMinOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  endDate?: InputMaybe<ServerOrderBy>
  fileUrl?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  startDate?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "clientDocument" */
export type ServerClientDocumentMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerClientDocument>
}

/** on_conflict condition type for table "clientDocument" */
export type ServerClientDocumentOnConflict = {
  constraint: ServerClientDocumentConstraint
  update_columns?: Array<ServerClientDocumentUpdateColumn>
  where?: InputMaybe<ServerClientDocumentBoolExp>
}

/** Ordering options when selecting data from "clientDocument". */
export type ServerClientDocumentOrderBy = {
  author?: InputMaybe<ServerEmployeeOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  endDate?: InputMaybe<ServerOrderBy>
  fileUrl?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  managingEmployee?: InputMaybe<ServerEmployeeOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  startDate?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: clientDocument */
export type ServerClientDocumentPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "clientDocument" */
export enum ServerClientDocumentSelectColumn {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  FileUrl = 'fileUrl',
  /** column name */
  Id = 'id',
  /** column name */
  ManagingEmployeeId = 'managingEmployeeId',
  /** column name */
  Name = 'name',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "clientDocument" */
export type ServerClientDocumentSetInput = {
  endDate?: InputMaybe<Scalars['timestamptz']>
  fileUrl?: InputMaybe<Scalars['String']>
  managingEmployeeId?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['timestamptz']>
  type?: InputMaybe<Scalars['smallint']>
}

/** order by stddev() on columns of table "clientDocument" */
export type ServerClientDocumentStddevOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "clientDocument" */
export type ServerClientDocumentStddevPopOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "clientDocument" */
export type ServerClientDocumentStddevSampOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "clientDocument" */
export type ServerClientDocumentSumOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** update columns of table "clientDocument" */
export enum ServerClientDocumentUpdateColumn {
  /** column name */
  EndDate = 'endDate',
  /** column name */
  FileUrl = 'fileUrl',
  /** column name */
  ManagingEmployeeId = 'managingEmployeeId',
  /** column name */
  Name = 'name',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Type = 'type',
}

export type ServerClientDocumentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerClientDocumentIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerClientDocumentSetInput>
  /** filter the rows which have to be updated */
  where: ServerClientDocumentBoolExp
}

/** order by var_pop() on columns of table "clientDocument" */
export type ServerClientDocumentVarPopOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "clientDocument" */
export type ServerClientDocumentVarSampOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "clientDocument" */
export type ServerClientDocumentVarianceOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "clientEmailView" */
export type ServerClientEmailView = {
  alreadyLoggedIn: Maybe<Scalars['Boolean']>
  businessId: Maybe<Scalars['Int']>
  /** An object relationship */
  client: Maybe<ServerClient>
  email: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "clientEmailView". All fields are combined with a logical 'AND'. */
export type ServerClientEmailViewBoolExp = {
  _and?: InputMaybe<Array<ServerClientEmailViewBoolExp>>
  _not?: InputMaybe<ServerClientEmailViewBoolExp>
  _or?: InputMaybe<Array<ServerClientEmailViewBoolExp>>
  alreadyLoggedIn?: InputMaybe<ServerBooleanComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  client?: InputMaybe<ServerClientBoolExp>
  email?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
}

/** response of any mutation on the table "clientEmailView" */
export type ServerClientEmailViewMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerClientEmailView>
}

/** Ordering options when selecting data from "clientEmailView". */
export type ServerClientEmailViewOrderBy = {
  alreadyLoggedIn?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  email?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** select columns of table "clientEmailView" */
export enum ServerClientEmailViewSelectColumn {
  /** column name */
  AlreadyLoggedIn = 'alreadyLoggedIn',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "clientEmailView" */
export type ServerClientEmailViewSetInput = {
  email?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "clientEmailView" */
export type ServerClientEmailViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerClientEmailViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerClientEmailViewStreamCursorValueInput = {
  alreadyLoggedIn?: InputMaybe<Scalars['Boolean']>
  businessId?: InputMaybe<Scalars['Int']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
}

export type ServerClientEmailViewUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerClientEmailViewSetInput>
  /** filter the rows which have to be updated */
  where: ServerClientEmailViewBoolExp
}

/** Заметки от клиентах */
export type ServerClientNote = {
  archived: Maybe<Scalars['timestamptz']>
  businessId: Scalars['Int']
  /** An object relationship */
  client: ServerClient
  clientId: Scalars['Int']
  createdAt: Scalars['timestamptz']
  edited: Maybe<Scalars['Boolean']>
  /** An object relationship */
  employee: ServerEmployee
  employeeId: Scalars['Int']
  id: Scalars['Int']
  text: Scalars['String']
}

/** order by aggregate values of table "clientNote" */
export type ServerClientNoteAggregateOrderBy = {
  avg?: InputMaybe<ServerClientNoteAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerClientNoteMaxOrderBy>
  min?: InputMaybe<ServerClientNoteMinOrderBy>
  stddev?: InputMaybe<ServerClientNoteStddevOrderBy>
  stddev_pop?: InputMaybe<ServerClientNoteStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerClientNoteStddevSampOrderBy>
  sum?: InputMaybe<ServerClientNoteSumOrderBy>
  var_pop?: InputMaybe<ServerClientNoteVarPopOrderBy>
  var_samp?: InputMaybe<ServerClientNoteVarSampOrderBy>
  variance?: InputMaybe<ServerClientNoteVarianceOrderBy>
}

/** input type for inserting array relation for remote table "clientNote" */
export type ServerClientNoteArrRelInsertInput = {
  data: Array<ServerClientNoteInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerClientNoteOnConflict>
}

/** order by avg() on columns of table "clientNote" */
export type ServerClientNoteAvgOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "clientNote". All fields are combined with a logical 'AND'. */
export type ServerClientNoteBoolExp = {
  _and?: InputMaybe<Array<ServerClientNoteBoolExp>>
  _not?: InputMaybe<ServerClientNoteBoolExp>
  _or?: InputMaybe<Array<ServerClientNoteBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  client?: InputMaybe<ServerClientBoolExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  edited?: InputMaybe<ServerBooleanComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  text?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "clientNote" */
export enum ServerClientNoteConstraint {
  /** unique or primary key constraint on columns "id" */
  ClientNotesPkey = 'clientNotes_pkey',
}

/** input type for inserting data into table "clientNote" */
export type ServerClientNoteInsertInput = {
  client?: InputMaybe<ServerClientObjRelInsertInput>
  clientId?: InputMaybe<Scalars['Int']>
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  text?: InputMaybe<Scalars['String']>
}

/** order by max() on columns of table "clientNote" */
export type ServerClientNoteMaxOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  text?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "clientNote" */
export type ServerClientNoteMinOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  text?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "clientNote" */
export type ServerClientNoteMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerClientNote>
}

/** on_conflict condition type for table "clientNote" */
export type ServerClientNoteOnConflict = {
  constraint: ServerClientNoteConstraint
  update_columns?: Array<ServerClientNoteUpdateColumn>
  where?: InputMaybe<ServerClientNoteBoolExp>
}

/** Ordering options when selecting data from "clientNote". */
export type ServerClientNoteOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  edited?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  text?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: clientNote */
export type ServerClientNotePkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "clientNote" */
export enum ServerClientNoteSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Edited = 'edited',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
}

/** input type for updating data in table "clientNote" */
export type ServerClientNoteSetInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
  text?: InputMaybe<Scalars['String']>
}

/** order by stddev() on columns of table "clientNote" */
export type ServerClientNoteStddevOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "clientNote" */
export type ServerClientNoteStddevPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "clientNote" */
export type ServerClientNoteStddevSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "clientNote" */
export type ServerClientNoteSumOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** update columns of table "clientNote" */
export enum ServerClientNoteUpdateColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  Text = 'text',
}

export type ServerClientNoteUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerClientNoteSetInput>
  /** filter the rows which have to be updated */
  where: ServerClientNoteBoolExp
}

/** order by var_pop() on columns of table "clientNote" */
export type ServerClientNoteVarPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "clientNote" */
export type ServerClientNoteVarSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "clientNote" */
export type ServerClientNoteVarianceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "clientPhoneView" */
export type ServerClientPhoneView = {
  altPhone: Maybe<Scalars['String']>
  businessId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  notFormattedAltPhone: Maybe<Scalars['String']>
  notFormattedPhone: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "clientPhoneView". All fields are combined with a logical 'AND'. */
export type ServerClientPhoneViewBoolExp = {
  _and?: InputMaybe<Array<ServerClientPhoneViewBoolExp>>
  _not?: InputMaybe<ServerClientPhoneViewBoolExp>
  _or?: InputMaybe<Array<ServerClientPhoneViewBoolExp>>
  altPhone?: InputMaybe<ServerStringComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  notFormattedAltPhone?: InputMaybe<ServerStringComparisonExp>
  notFormattedPhone?: InputMaybe<ServerStringComparisonExp>
  phone?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "clientPhoneView". */
export type ServerClientPhoneViewOrderBy = {
  altPhone?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  notFormattedAltPhone?: InputMaybe<ServerOrderBy>
  notFormattedPhone?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
}

/** select columns of table "clientPhoneView" */
export enum ServerClientPhoneViewSelectColumn {
  /** column name */
  AltPhone = 'altPhone',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  NotFormattedAltPhone = 'notFormattedAltPhone',
  /** column name */
  NotFormattedPhone = 'notFormattedPhone',
  /** column name */
  Phone = 'phone',
}

/** Streaming cursor of the table "clientPhoneView" */
export type ServerClientPhoneViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerClientPhoneViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerClientPhoneViewStreamCursorValueInput = {
  altPhone?: InputMaybe<Scalars['String']>
  businessId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  notFormattedAltPhone?: InputMaybe<Scalars['String']>
  notFormattedPhone?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
}

/** Relations between clients (e.g. parent-child) */
export type ServerClientRelation = {
  /** An object relationship */
  firstClient: ServerClient
  firstClientId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  secondClient: ServerClient
  secondClientId: Scalars['Int']
  type: Scalars['smallint']
}

/** order by aggregate values of table "clientRelation" */
export type ServerClientRelationAggregateOrderBy = {
  avg?: InputMaybe<ServerClientRelationAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerClientRelationMaxOrderBy>
  min?: InputMaybe<ServerClientRelationMinOrderBy>
  stddev?: InputMaybe<ServerClientRelationStddevOrderBy>
  stddev_pop?: InputMaybe<ServerClientRelationStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerClientRelationStddevSampOrderBy>
  sum?: InputMaybe<ServerClientRelationSumOrderBy>
  var_pop?: InputMaybe<ServerClientRelationVarPopOrderBy>
  var_samp?: InputMaybe<ServerClientRelationVarSampOrderBy>
  variance?: InputMaybe<ServerClientRelationVarianceOrderBy>
}

/** input type for inserting array relation for remote table "clientRelation" */
export type ServerClientRelationArrRelInsertInput = {
  data: Array<ServerClientRelationInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerClientRelationOnConflict>
}

/** order by avg() on columns of table "clientRelation" */
export type ServerClientRelationAvgOrderBy = {
  firstClientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  secondClientId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "clientRelation". All fields are combined with a logical 'AND'. */
export type ServerClientRelationBoolExp = {
  _and?: InputMaybe<Array<ServerClientRelationBoolExp>>
  _not?: InputMaybe<ServerClientRelationBoolExp>
  _or?: InputMaybe<Array<ServerClientRelationBoolExp>>
  firstClient?: InputMaybe<ServerClientBoolExp>
  firstClientId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  secondClient?: InputMaybe<ServerClientBoolExp>
  secondClientId?: InputMaybe<ServerIntComparisonExp>
  type?: InputMaybe<ServerSmallintComparisonExp>
}

/** unique or primary key constraints on table "clientRelation" */
export enum ServerClientRelationConstraint {
  /** unique or primary key constraint on columns "id" */
  ClientRelationPkey = 'clientRelation_pkey',
}

/** input type for incrementing numeric columns in table "clientRelation" */
export type ServerClientRelationIncInput = {
  firstClientId?: InputMaybe<Scalars['Int']>
  secondClientId?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<Scalars['smallint']>
}

/** input type for inserting data into table "clientRelation" */
export type ServerClientRelationInsertInput = {
  firstClient?: InputMaybe<ServerClientObjRelInsertInput>
  firstClientId?: InputMaybe<Scalars['Int']>
  secondClient?: InputMaybe<ServerClientObjRelInsertInput>
  secondClientId?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<Scalars['smallint']>
}

/** order by max() on columns of table "clientRelation" */
export type ServerClientRelationMaxOrderBy = {
  firstClientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  secondClientId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "clientRelation" */
export type ServerClientRelationMinOrderBy = {
  firstClientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  secondClientId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "clientRelation" */
export type ServerClientRelationMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerClientRelation>
}

/** on_conflict condition type for table "clientRelation" */
export type ServerClientRelationOnConflict = {
  constraint: ServerClientRelationConstraint
  update_columns?: Array<ServerClientRelationUpdateColumn>
  where?: InputMaybe<ServerClientRelationBoolExp>
}

/** Ordering options when selecting data from "clientRelation". */
export type ServerClientRelationOrderBy = {
  firstClient?: InputMaybe<ServerClientOrderBy>
  firstClientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  secondClient?: InputMaybe<ServerClientOrderBy>
  secondClientId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: clientRelation */
export type ServerClientRelationPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "clientRelation" */
export enum ServerClientRelationSelectColumn {
  /** column name */
  FirstClientId = 'firstClientId',
  /** column name */
  Id = 'id',
  /** column name */
  SecondClientId = 'secondClientId',
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "clientRelation" */
export type ServerClientRelationSetInput = {
  firstClientId?: InputMaybe<Scalars['Int']>
  secondClientId?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<Scalars['smallint']>
}

/** order by stddev() on columns of table "clientRelation" */
export type ServerClientRelationStddevOrderBy = {
  firstClientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  secondClientId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "clientRelation" */
export type ServerClientRelationStddevPopOrderBy = {
  firstClientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  secondClientId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "clientRelation" */
export type ServerClientRelationStddevSampOrderBy = {
  firstClientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  secondClientId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "clientRelation" */
export type ServerClientRelationSumOrderBy = {
  firstClientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  secondClientId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** update columns of table "clientRelation" */
export enum ServerClientRelationUpdateColumn {
  /** column name */
  FirstClientId = 'firstClientId',
  /** column name */
  SecondClientId = 'secondClientId',
  /** column name */
  Type = 'type',
}

export type ServerClientRelationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerClientRelationIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerClientRelationSetInput>
  /** filter the rows which have to be updated */
  where: ServerClientRelationBoolExp
}

/** order by var_pop() on columns of table "clientRelation" */
export type ServerClientRelationVarPopOrderBy = {
  firstClientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  secondClientId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "clientRelation" */
export type ServerClientRelationVarSampOrderBy = {
  firstClientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  secondClientId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "clientRelation" */
export type ServerClientRelationVarianceOrderBy = {
  firstClientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  secondClientId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "clientSettings" */
export type ServerClientSettings = {
  /** An object relationship */
  client: ServerClient
  clientId: Scalars['Int']
  id: Scalars['Int']
  /** The lang the client has chosen in the app. keys from i18n */
  lang: Maybe<Scalars['String']>
  notificationSettings: Scalars['jsonb']
  preferredConnections: Scalars['jsonb']
}

/** columns and relationships of "clientSettings" */
export type ServerClientSettingsNotificationSettingsArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "clientSettings" */
export type ServerClientSettingsPreferredConnectionsArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type ServerClientSettingsAppendInput = {
  notificationSettings?: InputMaybe<Scalars['jsonb']>
  preferredConnections?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "clientSettings". All fields are combined with a logical 'AND'. */
export type ServerClientSettingsBoolExp = {
  _and?: InputMaybe<Array<ServerClientSettingsBoolExp>>
  _not?: InputMaybe<ServerClientSettingsBoolExp>
  _or?: InputMaybe<Array<ServerClientSettingsBoolExp>>
  client?: InputMaybe<ServerClientBoolExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  lang?: InputMaybe<ServerStringComparisonExp>
  notificationSettings?: InputMaybe<ServerJsonbComparisonExp>
  preferredConnections?: InputMaybe<ServerJsonbComparisonExp>
}

/** unique or primary key constraints on table "clientSettings" */
export enum ServerClientSettingsConstraint {
  /** unique or primary key constraint on columns "clientId" */
  ClientSettingsClientIdKey = 'clientSettings_clientId_key',
  /** unique or primary key constraint on columns "id" */
  ClientSettingsPkey = 'clientSettings_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ServerClientSettingsDeleteAtPathInput = {
  notificationSettings?: InputMaybe<Array<Scalars['String']>>
  preferredConnections?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ServerClientSettingsDeleteElemInput = {
  notificationSettings?: InputMaybe<Scalars['Int']>
  preferredConnections?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ServerClientSettingsDeleteKeyInput = {
  notificationSettings?: InputMaybe<Scalars['String']>
  preferredConnections?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "clientSettings" */
export type ServerClientSettingsInsertInput = {
  client?: InputMaybe<ServerClientObjRelInsertInput>
  clientId?: InputMaybe<Scalars['Int']>
  preferredConnections?: InputMaybe<Scalars['jsonb']>
}

/** response of any mutation on the table "clientSettings" */
export type ServerClientSettingsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerClientSettings>
}

/** input type for inserting object relation for remote table "clientSettings" */
export type ServerClientSettingsObjRelInsertInput = {
  data: ServerClientSettingsInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerClientSettingsOnConflict>
}

/** on_conflict condition type for table "clientSettings" */
export type ServerClientSettingsOnConflict = {
  constraint: ServerClientSettingsConstraint
  update_columns?: Array<ServerClientSettingsUpdateColumn>
  where?: InputMaybe<ServerClientSettingsBoolExp>
}

/** Ordering options when selecting data from "clientSettings". */
export type ServerClientSettingsOrderBy = {
  client?: InputMaybe<ServerClientOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  lang?: InputMaybe<ServerOrderBy>
  notificationSettings?: InputMaybe<ServerOrderBy>
  preferredConnections?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: clientSettings */
export type ServerClientSettingsPkColumnsInput = {
  id: Scalars['Int']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ServerClientSettingsPrependInput = {
  notificationSettings?: InputMaybe<Scalars['jsonb']>
  preferredConnections?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "clientSettings" */
export enum ServerClientSettingsSelectColumn {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  NotificationSettings = 'notificationSettings',
  /** column name */
  PreferredConnections = 'preferredConnections',
}

/** input type for updating data in table "clientSettings" */
export type ServerClientSettingsSetInput = {
  /** The lang the client has chosen in the app. keys from i18n */
  lang?: InputMaybe<Scalars['String']>
  notificationSettings?: InputMaybe<Scalars['jsonb']>
  preferredConnections?: InputMaybe<Scalars['jsonb']>
}

/** update columns of table "clientSettings" */
export enum ServerClientSettingsUpdateColumn {
  /** column name */
  Lang = 'lang',
  /** column name */
  NotificationSettings = 'notificationSettings',
  /** column name */
  PreferredConnections = 'preferredConnections',
}

export type ServerClientSettingsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ServerClientSettingsAppendInput>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ServerClientSettingsDeleteAtPathInput>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ServerClientSettingsDeleteElemInput>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ServerClientSettingsDeleteKeyInput>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ServerClientSettingsPrependInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerClientSettingsSetInput>
  /** filter the rows which have to be updated */
  where: ServerClientSettingsBoolExp
}

/** columns and relationships of "clientTag" */
export type ServerClientTag = {
  businessId: Scalars['Int']
  /** An object relationship */
  client: ServerClient
  clientId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  tag: ServerTag
  tagId: Scalars['Int']
}

/** order by aggregate values of table "clientTag" */
export type ServerClientTagAggregateOrderBy = {
  avg?: InputMaybe<ServerClientTagAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerClientTagMaxOrderBy>
  min?: InputMaybe<ServerClientTagMinOrderBy>
  stddev?: InputMaybe<ServerClientTagStddevOrderBy>
  stddev_pop?: InputMaybe<ServerClientTagStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerClientTagStddevSampOrderBy>
  sum?: InputMaybe<ServerClientTagSumOrderBy>
  var_pop?: InputMaybe<ServerClientTagVarPopOrderBy>
  var_samp?: InputMaybe<ServerClientTagVarSampOrderBy>
  variance?: InputMaybe<ServerClientTagVarianceOrderBy>
}

/** input type for inserting array relation for remote table "clientTag" */
export type ServerClientTagArrRelInsertInput = {
  data: Array<ServerClientTagInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerClientTagOnConflict>
}

/** order by avg() on columns of table "clientTag" */
export type ServerClientTagAvgOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  tagId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "clientTag". All fields are combined with a logical 'AND'. */
export type ServerClientTagBoolExp = {
  _and?: InputMaybe<Array<ServerClientTagBoolExp>>
  _not?: InputMaybe<ServerClientTagBoolExp>
  _or?: InputMaybe<Array<ServerClientTagBoolExp>>
  businessId?: InputMaybe<ServerIntComparisonExp>
  client?: InputMaybe<ServerClientBoolExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  tag?: InputMaybe<ServerTagBoolExp>
  tagId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "clientTag" */
export enum ServerClientTagConstraint {
  /** unique or primary key constraint on columns "id" */
  ClientTagsPkey = 'clientTags_pkey',
}

/** input type for incrementing numeric columns in table "clientTag" */
export type ServerClientTagIncInput = {
  clientId?: InputMaybe<Scalars['Int']>
  tagId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "clientTag" */
export type ServerClientTagInsertInput = {
  client?: InputMaybe<ServerClientObjRelInsertInput>
  clientId?: InputMaybe<Scalars['Int']>
  tag?: InputMaybe<ServerTagObjRelInsertInput>
  tagId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "clientTag" */
export type ServerClientTagMaxOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  tagId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "clientTag" */
export type ServerClientTagMinOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  tagId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "clientTag" */
export type ServerClientTagMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerClientTag>
}

/** on_conflict condition type for table "clientTag" */
export type ServerClientTagOnConflict = {
  constraint: ServerClientTagConstraint
  update_columns?: Array<ServerClientTagUpdateColumn>
  where?: InputMaybe<ServerClientTagBoolExp>
}

/** Ordering options when selecting data from "clientTag". */
export type ServerClientTagOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  tag?: InputMaybe<ServerTagOrderBy>
  tagId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: clientTag */
export type ServerClientTagPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "clientTag" */
export enum ServerClientTagSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tagId',
}

/** input type for updating data in table "clientTag" */
export type ServerClientTagSetInput = {
  clientId?: InputMaybe<Scalars['Int']>
  tagId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "clientTag" */
export type ServerClientTagStddevOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  tagId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "clientTag" */
export type ServerClientTagStddevPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  tagId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "clientTag" */
export type ServerClientTagStddevSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  tagId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "clientTag" */
export type ServerClientTagSumOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  tagId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "clientTag" */
export enum ServerClientTagUpdateColumn {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  TagId = 'tagId',
}

export type ServerClientTagUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerClientTagIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerClientTagSetInput>
  /** filter the rows which have to be updated */
  where: ServerClientTagBoolExp
}

/** order by var_pop() on columns of table "clientTag" */
export type ServerClientTagVarPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  tagId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "clientTag" */
export type ServerClientTagVarSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  tagId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "clientTag" */
export type ServerClientTagVarianceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  tagId?: InputMaybe<ServerOrderBy>
}

/** aggregated selection of "client" */
export type ServerClientAggregate = {
  aggregate: Maybe<ServerClientAggregateFields>
  nodes: Array<ServerClient>
}

export type ServerClientAggregateBoolExp = {
  count?: InputMaybe<ServerClientAggregateBoolExpCount>
}

export type ServerClientAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServerClientSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerClientBoolExp>
  predicate: ServerIntComparisonExp
}

/** aggregate fields of "client" */
export type ServerClientAggregateFields = {
  avg: Maybe<ServerClientAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerClientMaxFields>
  min: Maybe<ServerClientMinFields>
  stddev: Maybe<ServerClientStddevFields>
  stddev_pop: Maybe<ServerClientStddevPopFields>
  stddev_samp: Maybe<ServerClientStddevSampFields>
  sum: Maybe<ServerClientSumFields>
  var_pop: Maybe<ServerClientVarPopFields>
  var_samp: Maybe<ServerClientVarSampFields>
  variance: Maybe<ServerClientVarianceFields>
}

/** aggregate fields of "client" */
export type ServerClientAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerClientSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "client" */
export type ServerClientAggregateOrderBy = {
  avg?: InputMaybe<ServerClientAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerClientMaxOrderBy>
  min?: InputMaybe<ServerClientMinOrderBy>
  stddev?: InputMaybe<ServerClientStddevOrderBy>
  stddev_pop?: InputMaybe<ServerClientStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerClientStddevSampOrderBy>
  sum?: InputMaybe<ServerClientSumOrderBy>
  var_pop?: InputMaybe<ServerClientVarPopOrderBy>
  var_samp?: InputMaybe<ServerClientVarSampOrderBy>
  variance?: InputMaybe<ServerClientVarianceOrderBy>
}

/** input type for inserting array relation for remote table "client" */
export type ServerClientArrRelInsertInput = {
  data: Array<ServerClientInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerClientOnConflict>
}

/** aggregate avg on columns */
export type ServerClientAvgFields = {
  id: Maybe<Scalars['Float']>
  initialBranchId: Maybe<Scalars['Float']>
  leadId: Maybe<Scalars['Float']>
  managingEmployeeId: Maybe<Scalars['Float']>
  maximumDebt: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "client" */
export type ServerClientAvgOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  initialBranchId?: InputMaybe<ServerOrderBy>
  leadId?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "client". All fields are combined with a logical 'AND'. */
export type ServerClientBoolExp = {
  _and?: InputMaybe<Array<ServerClientBoolExp>>
  _not?: InputMaybe<ServerClientBoolExp>
  _or?: InputMaybe<Array<ServerClientBoolExp>>
  additionalInformation?: InputMaybe<ServerStringComparisonExp>
  archivedInBranches?: InputMaybe<ServerClientBranchArchivedBoolExp>
  author?: InputMaybe<ServerEmployeeBoolExp>
  birthDate?: InputMaybe<ServerTimestamptzComparisonExp>
  bookings?: InputMaybe<ServerBookingBoolExp>
  bookings_aggregate?: InputMaybe<ServerBookingAggregateBoolExp>
  calendarId?: InputMaybe<ServerStringComparisonExp>
  cards?: InputMaybe<ServerCardBoolExp>
  clientDocuments?: InputMaybe<ServerClientDocumentBoolExp>
  clientNotes?: InputMaybe<ServerClientNoteBoolExp>
  clientRelationsWhereClientIsFirst?: InputMaybe<ServerClientRelationBoolExp>
  clientRelationsWhereClientIsSecond?: InputMaybe<ServerClientRelationBoolExp>
  clientSettings?: InputMaybe<ServerClientSettingsBoolExp>
  clientTags?: InputMaybe<ServerClientTagBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  email?: InputMaybe<ServerStringComparisonExp>
  emailView?: InputMaybe<ServerClientEmailViewBoolExp>
  firstName?: InputMaybe<ServerStringComparisonExp>
  gender?: InputMaybe<ServerStringComparisonExp>
  groupBookingClients?: InputMaybe<ServerBookingClientBoolExp>
  hasTelegram?: InputMaybe<ServerBooleanComparisonExp>
  hasViber?: InputMaybe<ServerBooleanComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  initialBranchId?: InputMaybe<ServerIntComparisonExp>
  lastName?: InputMaybe<ServerStringComparisonExp>
  lead?: InputMaybe<ServerLeadBoolExp>
  leadId?: InputMaybe<ServerIntComparisonExp>
  managingEmployee?: InputMaybe<ServerEmployeeBoolExp>
  managingEmployeeId?: InputMaybe<ServerIntComparisonExp>
  maximumDebt?: InputMaybe<ServerNumericComparisonExp>
  middleName?: InputMaybe<ServerStringComparisonExp>
  notifications?: InputMaybe<ServerNotificationBoolExp>
  notifications_aggregate?: InputMaybe<ServerNotificationAggregateBoolExp>
  phone?: InputMaybe<ServerStringComparisonExp>
  phoneView?: InputMaybe<ServerClientPhoneViewBoolExp>
  photo?: InputMaybe<ServerStringComparisonExp>
  transactions?: InputMaybe<ServerTransactionBoolExp>
  transactions_aggregate?: InputMaybe<ServerTransactionAggregateBoolExp>
}

/** unique or primary key constraints on table "client" */
export enum ServerClientConstraint {
  /** unique or primary key constraint on columns "id" */
  ClientsPkey = 'clients_pkey',
}

/** input type for incrementing numeric columns in table "client" */
export type ServerClientIncInput = {
  leadId?: InputMaybe<Scalars['Int']>
  managingEmployeeId?: InputMaybe<Scalars['Int']>
  maximumDebt?: InputMaybe<Scalars['numeric']>
}

/** input type for inserting data into table "client" */
export type ServerClientInsertInput = {
  altPhone?: InputMaybe<Scalars['String']>
  archivedInBranches?: InputMaybe<ServerClientBranchArchivedArrRelInsertInput>
  author?: InputMaybe<ServerEmployeeObjRelInsertInput>
  birthDate?: InputMaybe<Scalars['timestamptz']>
  bookings?: InputMaybe<ServerBookingArrRelInsertInput>
  cards?: InputMaybe<ServerCardArrRelInsertInput>
  clientDocuments?: InputMaybe<ServerClientDocumentArrRelInsertInput>
  clientNotes?: InputMaybe<ServerClientNoteArrRelInsertInput>
  clientRelationsWhereClientIsFirst?: InputMaybe<ServerClientRelationArrRelInsertInput>
  clientRelationsWhereClientIsSecond?: InputMaybe<ServerClientRelationArrRelInsertInput>
  clientSettings?: InputMaybe<ServerClientSettingsObjRelInsertInput>
  clientTags?: InputMaybe<ServerClientTagArrRelInsertInput>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  groupBookingClients?: InputMaybe<ServerBookingClientArrRelInsertInput>
  initialBranchId?: InputMaybe<Scalars['Int']>
  lastName?: InputMaybe<Scalars['String']>
  lead?: InputMaybe<ServerLeadObjRelInsertInput>
  leadId?: InputMaybe<Scalars['Int']>
  managingEmployee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  managingEmployeeId?: InputMaybe<Scalars['Int']>
  maximumDebt?: InputMaybe<Scalars['numeric']>
  middleName?: InputMaybe<Scalars['String']>
  notifications?: InputMaybe<ServerNotificationArrRelInsertInput>
  phone?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['String']>
  transactions?: InputMaybe<ServerTransactionArrRelInsertInput>
}

/** aggregate max on columns */
export type ServerClientMaxFields = {
  additionalInformation: Maybe<Scalars['String']>
  birthDate: Maybe<Scalars['timestamptz']>
  calendarId: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['timestamptz']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  gender: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  initialBranchId: Maybe<Scalars['Int']>
  lastName: Maybe<Scalars['String']>
  leadId: Maybe<Scalars['Int']>
  managingEmployeeId: Maybe<Scalars['Int']>
  maximumDebt: Maybe<Scalars['numeric']>
  middleName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  photo: Maybe<Scalars['String']>
}

/** order by max() on columns of table "client" */
export type ServerClientMaxOrderBy = {
  additionalInformation?: InputMaybe<ServerOrderBy>
  birthDate?: InputMaybe<ServerOrderBy>
  calendarId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  firstName?: InputMaybe<ServerOrderBy>
  gender?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  initialBranchId?: InputMaybe<ServerOrderBy>
  lastName?: InputMaybe<ServerOrderBy>
  leadId?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  middleName?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  photo?: InputMaybe<ServerOrderBy>
}

/** aggregate min on columns */
export type ServerClientMinFields = {
  additionalInformation: Maybe<Scalars['String']>
  birthDate: Maybe<Scalars['timestamptz']>
  calendarId: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['timestamptz']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  gender: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  initialBranchId: Maybe<Scalars['Int']>
  lastName: Maybe<Scalars['String']>
  leadId: Maybe<Scalars['Int']>
  managingEmployeeId: Maybe<Scalars['Int']>
  maximumDebt: Maybe<Scalars['numeric']>
  middleName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  photo: Maybe<Scalars['String']>
}

/** order by min() on columns of table "client" */
export type ServerClientMinOrderBy = {
  additionalInformation?: InputMaybe<ServerOrderBy>
  birthDate?: InputMaybe<ServerOrderBy>
  calendarId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  firstName?: InputMaybe<ServerOrderBy>
  gender?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  initialBranchId?: InputMaybe<ServerOrderBy>
  lastName?: InputMaybe<ServerOrderBy>
  leadId?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  middleName?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  photo?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "client" */
export type ServerClientMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerClient>
}

/** input type for inserting object relation for remote table "client" */
export type ServerClientObjRelInsertInput = {
  data: ServerClientInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerClientOnConflict>
}

/** on_conflict condition type for table "client" */
export type ServerClientOnConflict = {
  constraint: ServerClientConstraint
  update_columns?: Array<ServerClientUpdateColumn>
  where?: InputMaybe<ServerClientBoolExp>
}

/** Ordering options when selecting data from "client". */
export type ServerClientOrderBy = {
  additionalInformation?: InputMaybe<ServerOrderBy>
  archivedInBranches_aggregate?: InputMaybe<ServerClientBranchArchivedAggregateOrderBy>
  author?: InputMaybe<ServerEmployeeOrderBy>
  birthDate?: InputMaybe<ServerOrderBy>
  bookings_aggregate?: InputMaybe<ServerBookingAggregateOrderBy>
  calendarId?: InputMaybe<ServerOrderBy>
  cards_aggregate?: InputMaybe<ServerCardAggregateOrderBy>
  clientDocuments_aggregate?: InputMaybe<ServerClientDocumentAggregateOrderBy>
  clientNotes_aggregate?: InputMaybe<ServerClientNoteAggregateOrderBy>
  clientRelationsWhereClientIsFirst_aggregate?: InputMaybe<ServerClientRelationAggregateOrderBy>
  clientRelationsWhereClientIsSecond_aggregate?: InputMaybe<ServerClientRelationAggregateOrderBy>
  clientSettings?: InputMaybe<ServerClientSettingsOrderBy>
  clientTags_aggregate?: InputMaybe<ServerClientTagAggregateOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  emailView?: InputMaybe<ServerClientEmailViewOrderBy>
  firstName?: InputMaybe<ServerOrderBy>
  gender?: InputMaybe<ServerOrderBy>
  groupBookingClients_aggregate?: InputMaybe<ServerBookingClientAggregateOrderBy>
  hasTelegram?: InputMaybe<ServerOrderBy>
  hasViber?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  initialBranchId?: InputMaybe<ServerOrderBy>
  lastName?: InputMaybe<ServerOrderBy>
  lead?: InputMaybe<ServerLeadOrderBy>
  leadId?: InputMaybe<ServerOrderBy>
  managingEmployee?: InputMaybe<ServerEmployeeOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
  middleName?: InputMaybe<ServerOrderBy>
  notifications_aggregate?: InputMaybe<ServerNotificationAggregateOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  phoneView?: InputMaybe<ServerClientPhoneViewOrderBy>
  photo?: InputMaybe<ServerOrderBy>
  transactions_aggregate?: InputMaybe<ServerTransactionAggregateOrderBy>
}

/** primary key columns input for table: client */
export type ServerClientPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "client" */
export enum ServerClientSelectColumn {
  /** column name */
  AdditionalInformation = 'additionalInformation',
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  CalendarId = 'calendarId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  InitialBranchId = 'initialBranchId',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LeadId = 'leadId',
  /** column name */
  ManagingEmployeeId = 'managingEmployeeId',
  /** column name */
  MaximumDebt = 'maximumDebt',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Phone = 'phone',
  /** column name */
  Photo = 'photo',
}

/** input type for updating data in table "client" */
export type ServerClientSetInput = {
  additionalInformation?: InputMaybe<Scalars['String']>
  altPhone?: InputMaybe<Scalars['String']>
  birthDate?: InputMaybe<Scalars['timestamptz']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  leadId?: InputMaybe<Scalars['Int']>
  managingEmployeeId?: InputMaybe<Scalars['Int']>
  maximumDebt?: InputMaybe<Scalars['numeric']>
  middleName?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type ServerClientStddevFields = {
  id: Maybe<Scalars['Float']>
  initialBranchId: Maybe<Scalars['Float']>
  leadId: Maybe<Scalars['Float']>
  managingEmployeeId: Maybe<Scalars['Float']>
  maximumDebt: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "client" */
export type ServerClientStddevOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  initialBranchId?: InputMaybe<ServerOrderBy>
  leadId?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_pop on columns */
export type ServerClientStddevPopFields = {
  id: Maybe<Scalars['Float']>
  initialBranchId: Maybe<Scalars['Float']>
  leadId: Maybe<Scalars['Float']>
  managingEmployeeId: Maybe<Scalars['Float']>
  maximumDebt: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "client" */
export type ServerClientStddevPopOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  initialBranchId?: InputMaybe<ServerOrderBy>
  leadId?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_samp on columns */
export type ServerClientStddevSampFields = {
  id: Maybe<Scalars['Float']>
  initialBranchId: Maybe<Scalars['Float']>
  leadId: Maybe<Scalars['Float']>
  managingEmployeeId: Maybe<Scalars['Float']>
  maximumDebt: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "client" */
export type ServerClientStddevSampOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  initialBranchId?: InputMaybe<ServerOrderBy>
  leadId?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
}

/** aggregate sum on columns */
export type ServerClientSumFields = {
  id: Maybe<Scalars['Int']>
  initialBranchId: Maybe<Scalars['Int']>
  leadId: Maybe<Scalars['Int']>
  managingEmployeeId: Maybe<Scalars['Int']>
  maximumDebt: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "client" */
export type ServerClientSumOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  initialBranchId?: InputMaybe<ServerOrderBy>
  leadId?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
}

/** update columns of table "client" */
export enum ServerClientUpdateColumn {
  /** column name */
  AdditionalInformation = 'additionalInformation',
  /** column name */
  AltPhone = 'altPhone',
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LeadId = 'leadId',
  /** column name */
  ManagingEmployeeId = 'managingEmployeeId',
  /** column name */
  MaximumDebt = 'maximumDebt',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Phone = 'phone',
  /** column name */
  Photo = 'photo',
}

export type ServerClientUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerClientIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerClientSetInput>
  /** filter the rows which have to be updated */
  where: ServerClientBoolExp
}

/** aggregate var_pop on columns */
export type ServerClientVarPopFields = {
  id: Maybe<Scalars['Float']>
  initialBranchId: Maybe<Scalars['Float']>
  leadId: Maybe<Scalars['Float']>
  managingEmployeeId: Maybe<Scalars['Float']>
  maximumDebt: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "client" */
export type ServerClientVarPopOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  initialBranchId?: InputMaybe<ServerOrderBy>
  leadId?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
}

/** aggregate var_samp on columns */
export type ServerClientVarSampFields = {
  id: Maybe<Scalars['Float']>
  initialBranchId: Maybe<Scalars['Float']>
  leadId: Maybe<Scalars['Float']>
  managingEmployeeId: Maybe<Scalars['Float']>
  maximumDebt: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "client" */
export type ServerClientVarSampOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  initialBranchId?: InputMaybe<ServerOrderBy>
  leadId?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
}

/** aggregate variance on columns */
export type ServerClientVarianceFields = {
  id: Maybe<Scalars['Float']>
  initialBranchId: Maybe<Scalars['Float']>
  leadId: Maybe<Scalars['Float']>
  managingEmployeeId: Maybe<Scalars['Float']>
  maximumDebt: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "client" */
export type ServerClientVarianceOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  initialBranchId?: InputMaybe<ServerOrderBy>
  leadId?: InputMaybe<ServerOrderBy>
  managingEmployeeId?: InputMaybe<ServerOrderBy>
  maximumDebt?: InputMaybe<ServerOrderBy>
}

/** ordering argument of a cursor */
export enum ServerCursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "dynamicScheduleDate" */
export type ServerDynamicScheduleDate = {
  /** An object relationship */
  business: ServerBusiness
  date: Scalars['timestamptz']
  /** An object relationship */
  employeeSchedule: ServerEmployeeSchedule
  employeeScheduleId: Scalars['Int']
  id: Scalars['uuid']
  timingEnd: Scalars['numeric']
  timingStart: Scalars['numeric']
}

/** order by aggregate values of table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateAggregateOrderBy = {
  avg?: InputMaybe<ServerDynamicScheduleDateAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerDynamicScheduleDateMaxOrderBy>
  min?: InputMaybe<ServerDynamicScheduleDateMinOrderBy>
  stddev?: InputMaybe<ServerDynamicScheduleDateStddevOrderBy>
  stddev_pop?: InputMaybe<ServerDynamicScheduleDateStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerDynamicScheduleDateStddevSampOrderBy>
  sum?: InputMaybe<ServerDynamicScheduleDateSumOrderBy>
  var_pop?: InputMaybe<ServerDynamicScheduleDateVarPopOrderBy>
  var_samp?: InputMaybe<ServerDynamicScheduleDateVarSampOrderBy>
  variance?: InputMaybe<ServerDynamicScheduleDateVarianceOrderBy>
}

/** input type for inserting array relation for remote table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateArrRelInsertInput = {
  data: Array<ServerDynamicScheduleDateInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerDynamicScheduleDateOnConflict>
}

/** order by avg() on columns of table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateAvgOrderBy = {
  employeeScheduleId?: InputMaybe<ServerOrderBy>
  timingEnd?: InputMaybe<ServerOrderBy>
  timingStart?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "dynamicScheduleDate". All fields are combined with a logical 'AND'. */
export type ServerDynamicScheduleDateBoolExp = {
  _and?: InputMaybe<Array<ServerDynamicScheduleDateBoolExp>>
  _not?: InputMaybe<ServerDynamicScheduleDateBoolExp>
  _or?: InputMaybe<Array<ServerDynamicScheduleDateBoolExp>>
  business?: InputMaybe<ServerBusinessBoolExp>
  date?: InputMaybe<ServerTimestamptzComparisonExp>
  employeeSchedule?: InputMaybe<ServerEmployeeScheduleBoolExp>
  employeeScheduleId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerUuidComparisonExp>
  timingEnd?: InputMaybe<ServerNumericComparisonExp>
  timingStart?: InputMaybe<ServerNumericComparisonExp>
}

/** unique or primary key constraints on table "dynamicScheduleDate" */
export enum ServerDynamicScheduleDateConstraint {
  /** unique or primary key constraint on columns "id" */
  DynamicScheduleDatePkey = 'dynamicScheduleDate_pkey',
}

/** input type for incrementing numeric columns in table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateIncInput = {
  timingEnd?: InputMaybe<Scalars['numeric']>
  timingStart?: InputMaybe<Scalars['numeric']>
}

/** input type for inserting data into table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateInsertInput = {
  date?: InputMaybe<Scalars['timestamptz']>
  employeeSchedule?: InputMaybe<ServerEmployeeScheduleObjRelInsertInput>
  employeeScheduleId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['uuid']>
  timingEnd?: InputMaybe<Scalars['numeric']>
  timingStart?: InputMaybe<Scalars['numeric']>
}

/** order by max() on columns of table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateMaxOrderBy = {
  date?: InputMaybe<ServerOrderBy>
  employeeScheduleId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timingEnd?: InputMaybe<ServerOrderBy>
  timingStart?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateMinOrderBy = {
  date?: InputMaybe<ServerOrderBy>
  employeeScheduleId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timingEnd?: InputMaybe<ServerOrderBy>
  timingStart?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerDynamicScheduleDate>
}

/** on_conflict condition type for table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateOnConflict = {
  constraint: ServerDynamicScheduleDateConstraint
  update_columns?: Array<ServerDynamicScheduleDateUpdateColumn>
  where?: InputMaybe<ServerDynamicScheduleDateBoolExp>
}

/** Ordering options when selecting data from "dynamicScheduleDate". */
export type ServerDynamicScheduleDateOrderBy = {
  business?: InputMaybe<ServerBusinessOrderBy>
  date?: InputMaybe<ServerOrderBy>
  employeeSchedule?: InputMaybe<ServerEmployeeScheduleOrderBy>
  employeeScheduleId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timingEnd?: InputMaybe<ServerOrderBy>
  timingStart?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: dynamicScheduleDate */
export type ServerDynamicScheduleDatePkColumnsInput = {
  id: Scalars['uuid']
}

/** select columns of table "dynamicScheduleDate" */
export enum ServerDynamicScheduleDateSelectColumn {
  /** column name */
  Date = 'date',
  /** column name */
  EmployeeScheduleId = 'employeeScheduleId',
  /** column name */
  Id = 'id',
  /** column name */
  TimingEnd = 'timingEnd',
  /** column name */
  TimingStart = 'timingStart',
}

/** input type for updating data in table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateSetInput = {
  timingEnd?: InputMaybe<Scalars['numeric']>
  timingStart?: InputMaybe<Scalars['numeric']>
}

/** order by stddev() on columns of table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateStddevOrderBy = {
  employeeScheduleId?: InputMaybe<ServerOrderBy>
  timingEnd?: InputMaybe<ServerOrderBy>
  timingStart?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateStddevPopOrderBy = {
  employeeScheduleId?: InputMaybe<ServerOrderBy>
  timingEnd?: InputMaybe<ServerOrderBy>
  timingStart?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateStddevSampOrderBy = {
  employeeScheduleId?: InputMaybe<ServerOrderBy>
  timingEnd?: InputMaybe<ServerOrderBy>
  timingStart?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateSumOrderBy = {
  employeeScheduleId?: InputMaybe<ServerOrderBy>
  timingEnd?: InputMaybe<ServerOrderBy>
  timingStart?: InputMaybe<ServerOrderBy>
}

/** update columns of table "dynamicScheduleDate" */
export enum ServerDynamicScheduleDateUpdateColumn {
  /** column name */
  TimingEnd = 'timingEnd',
  /** column name */
  TimingStart = 'timingStart',
}

export type ServerDynamicScheduleDateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerDynamicScheduleDateIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerDynamicScheduleDateSetInput>
  /** filter the rows which have to be updated */
  where: ServerDynamicScheduleDateBoolExp
}

/** order by var_pop() on columns of table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateVarPopOrderBy = {
  employeeScheduleId?: InputMaybe<ServerOrderBy>
  timingEnd?: InputMaybe<ServerOrderBy>
  timingStart?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateVarSampOrderBy = {
  employeeScheduleId?: InputMaybe<ServerOrderBy>
  timingEnd?: InputMaybe<ServerOrderBy>
  timingStart?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "dynamicScheduleDate" */
export type ServerDynamicScheduleDateVarianceOrderBy = {
  employeeScheduleId?: InputMaybe<ServerOrderBy>
  timingEnd?: InputMaybe<ServerOrderBy>
  timingStart?: InputMaybe<ServerOrderBy>
}

/** сотрудники */
export type ServerEmployee = {
  archived: Maybe<Scalars['timestamptz']>
  birthDate: Maybe<Scalars['timestamptz']>
  /** An array relationship */
  bookings: Array<ServerBooking>
  /** An aggregate relationship */
  bookings_aggregate: ServerBookingAggregate
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  createdAt: Scalars['timestamptz']
  email: Maybe<Scalars['String']>
  /** An object relationship */
  employeeGroup: Maybe<ServerEmployeeGroup>
  /** An array relationship */
  employeeSchedules: Array<ServerEmployeeSchedule>
  /** An aggregate relationship */
  employeeSchedules_aggregate: ServerEmployeeScheduleAggregate
  /** An object relationship */
  employeeSettings: Maybe<ServerEmployeeSettings>
  firstName: Scalars['String']
  gender: Maybe<Scalars['String']>
  groupId: Maybe<Scalars['Int']>
  /** A computed field, executes function "employeeHasTelegram" */
  hasTelegram: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "employeeHasViber" */
  hasViber: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  information: Maybe<Scalars['String']>
  lastName: Scalars['String']
  middleName: Maybe<Scalars['String']>
  /** An object relationship */
  ownBusiness: Maybe<ServerBusiness>
  phone: Maybe<Scalars['String']>
  photo: Maybe<Scalars['String']>
  preferredPayment: Maybe<Scalars['smallint']>
  /** An object relationship */
  reviewRating: Maybe<ServerEmployeeReviewView>
  /** An array relationship */
  reviews: Array<ServerBookingReviewView>
  /** An array relationship */
  salarySettings: Array<ServerSalarySetting>
  /** An aggregate relationship */
  salarySettings_aggregate: ServerSalarySettingAggregate
  /** An array relationship */
  serviceEmployees: Array<ServerServiceEmployee>
  /** An array relationship */
  servicePermissions: Array<ServerServicePermission>
  status: Maybe<Scalars['String']>
  /** An array relationship */
  timeoffs: Array<ServerTimeOff>
}

/** сотрудники */
export type ServerEmployeeBookingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** сотрудники */
export type ServerEmployeeBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** сотрудники */
export type ServerEmployeeEmployeeSchedulesArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeScheduleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeScheduleOrderBy>>
  where?: InputMaybe<ServerEmployeeScheduleBoolExp>
}

/** сотрудники */
export type ServerEmployeeEmployeeSchedulesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeScheduleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeScheduleOrderBy>>
  where?: InputMaybe<ServerEmployeeScheduleBoolExp>
}

/** сотрудники */
export type ServerEmployeeReviewsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingReviewViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingReviewViewOrderBy>>
  where?: InputMaybe<ServerBookingReviewViewBoolExp>
}

/** сотрудники */
export type ServerEmployeeSalarySettingsArgs = {
  distinct_on?: InputMaybe<Array<ServerSalarySettingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSalarySettingOrderBy>>
  where?: InputMaybe<ServerSalarySettingBoolExp>
}

/** сотрудники */
export type ServerEmployeeSalarySettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerSalarySettingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSalarySettingOrderBy>>
  where?: InputMaybe<ServerSalarySettingBoolExp>
}

/** сотрудники */
export type ServerEmployeeServiceEmployeesArgs = {
  distinct_on?: InputMaybe<Array<ServerServiceEmployeeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServiceEmployeeOrderBy>>
  where?: InputMaybe<ServerServiceEmployeeBoolExp>
}

/** сотрудники */
export type ServerEmployeeServicePermissionsArgs = {
  distinct_on?: InputMaybe<Array<ServerServicePermissionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServicePermissionOrderBy>>
  where?: InputMaybe<ServerServicePermissionBoolExp>
}

/** сотрудники */
export type ServerEmployeeTimeoffsArgs = {
  distinct_on?: InputMaybe<Array<ServerTimeOffSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTimeOffOrderBy>>
  where?: InputMaybe<ServerTimeOffBoolExp>
}

/** columns and relationships of "employeeBusinessOwnerView" */
export type ServerEmployeeBusinessOwnerView = {
  archived: Maybe<Scalars['timestamptz']>
  birthDate: Maybe<Scalars['timestamptz']>
  businessId: Maybe<Scalars['Int']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  gender: Maybe<Scalars['String']>
  groupId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  information: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  middleName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  photo: Maybe<Scalars['String']>
  status: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "employeeBusinessOwnerView". All fields are combined with a logical 'AND'. */
export type ServerEmployeeBusinessOwnerViewBoolExp = {
  _and?: InputMaybe<Array<ServerEmployeeBusinessOwnerViewBoolExp>>
  _not?: InputMaybe<ServerEmployeeBusinessOwnerViewBoolExp>
  _or?: InputMaybe<Array<ServerEmployeeBusinessOwnerViewBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  birthDate?: InputMaybe<ServerTimestamptzComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  email?: InputMaybe<ServerStringComparisonExp>
  firstName?: InputMaybe<ServerStringComparisonExp>
  gender?: InputMaybe<ServerStringComparisonExp>
  groupId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  information?: InputMaybe<ServerStringComparisonExp>
  lastName?: InputMaybe<ServerStringComparisonExp>
  middleName?: InputMaybe<ServerStringComparisonExp>
  phone?: InputMaybe<ServerStringComparisonExp>
  photo?: InputMaybe<ServerStringComparisonExp>
  status?: InputMaybe<ServerStringComparisonExp>
}

/** response of any mutation on the table "employeeBusinessOwnerView" */
export type ServerEmployeeBusinessOwnerViewMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerEmployeeBusinessOwnerView>
}

/** Ordering options when selecting data from "employeeBusinessOwnerView". */
export type ServerEmployeeBusinessOwnerViewOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  birthDate?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  firstName?: InputMaybe<ServerOrderBy>
  gender?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  information?: InputMaybe<ServerOrderBy>
  lastName?: InputMaybe<ServerOrderBy>
  middleName?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  photo?: InputMaybe<ServerOrderBy>
  status?: InputMaybe<ServerOrderBy>
}

/** select columns of table "employeeBusinessOwnerView" */
export enum ServerEmployeeBusinessOwnerViewSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  Information = 'information',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Phone = 'phone',
  /** column name */
  Photo = 'photo',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "employeeBusinessOwnerView" */
export type ServerEmployeeBusinessOwnerViewSetInput = {
  birthDate?: InputMaybe<Scalars['timestamptz']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  information?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  middleName?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "employeeBusinessOwnerView" */
export type ServerEmployeeBusinessOwnerViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerEmployeeBusinessOwnerViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerEmployeeBusinessOwnerViewStreamCursorValueInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
  birthDate?: InputMaybe<Scalars['timestamptz']>
  businessId?: InputMaybe<Scalars['Int']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  groupId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  information?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  middleName?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
}

export type ServerEmployeeBusinessOwnerViewUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerEmployeeBusinessOwnerViewSetInput>
  /** filter the rows which have to be updated */
  where: ServerEmployeeBusinessOwnerViewBoolExp
}

/** columns and relationships of "employeeEmailView" */
export type ServerEmployeeEmailView = {
  businessId: Maybe<Scalars['Int']>
  email: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "employeeEmailView". All fields are combined with a logical 'AND'. */
export type ServerEmployeeEmailViewBoolExp = {
  _and?: InputMaybe<Array<ServerEmployeeEmailViewBoolExp>>
  _not?: InputMaybe<ServerEmployeeEmailViewBoolExp>
  _or?: InputMaybe<Array<ServerEmployeeEmailViewBoolExp>>
  businessId?: InputMaybe<ServerIntComparisonExp>
  email?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
}

/** response of any mutation on the table "employeeEmailView" */
export type ServerEmployeeEmailViewMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerEmployeeEmailView>
}

/** Ordering options when selecting data from "employeeEmailView". */
export type ServerEmployeeEmailViewOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** select columns of table "employeeEmailView" */
export enum ServerEmployeeEmailViewSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "employeeEmailView" */
export type ServerEmployeeEmailViewSetInput = {
  email?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "employeeEmailView" */
export type ServerEmployeeEmailViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerEmployeeEmailViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerEmployeeEmailViewStreamCursorValueInput = {
  businessId?: InputMaybe<Scalars['Int']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
}

export type ServerEmployeeEmailViewUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerEmployeeEmailViewSetInput>
  /** filter the rows which have to be updated */
  where: ServerEmployeeEmailViewBoolExp
}

/** Группы сотрудников */
export type ServerEmployeeGroup = {
  archived: Maybe<Scalars['timestamptz']>
  createdAt: Scalars['timestamptz']
  description: Maybe<Scalars['String']>
  /** An array relationship */
  employees: Array<ServerEmployee>
  /** An aggregate relationship */
  employees_aggregate: ServerEmployeeAggregate
  id: Scalars['Int']
  maxDiscount: Maybe<Scalars['Int']>
  name: Scalars['String']
  /** An object relationship */
  role: ServerRole
  roleId: Scalars['String']
  /** An object relationship */
  storage: Maybe<ServerStorage>
  storageId: Maybe<Scalars['Int']>
}

/** Группы сотрудников */
export type ServerEmployeeGroupEmployeesArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeOrderBy>>
  where?: InputMaybe<ServerEmployeeBoolExp>
}

/** Группы сотрудников */
export type ServerEmployeeGroupEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeOrderBy>>
  where?: InputMaybe<ServerEmployeeBoolExp>
}

/** columns and relationships of "employeeGroupArchiveView" */
export type ServerEmployeeGroupArchiveView = {
  archived: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "employeeGroupArchiveView". All fields are combined with a logical 'AND'. */
export type ServerEmployeeGroupArchiveViewBoolExp = {
  _and?: InputMaybe<Array<ServerEmployeeGroupArchiveViewBoolExp>>
  _not?: InputMaybe<ServerEmployeeGroupArchiveViewBoolExp>
  _or?: InputMaybe<Array<ServerEmployeeGroupArchiveViewBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
}

/** response of any mutation on the table "employeeGroupArchiveView" */
export type ServerEmployeeGroupArchiveViewMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerEmployeeGroupArchiveView>
}

/** Ordering options when selecting data from "employeeGroupArchiveView". */
export type ServerEmployeeGroupArchiveViewOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** select columns of table "employeeGroupArchiveView" */
export enum ServerEmployeeGroupArchiveViewSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "employeeGroupArchiveView" */
export type ServerEmployeeGroupArchiveViewSetInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "employeeGroupArchiveView" */
export type ServerEmployeeGroupArchiveViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerEmployeeGroupArchiveViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerEmployeeGroupArchiveViewStreamCursorValueInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['Int']>
}

export type ServerEmployeeGroupArchiveViewUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerEmployeeGroupArchiveViewSetInput>
  /** filter the rows which have to be updated */
  where: ServerEmployeeGroupArchiveViewBoolExp
}

/** columns and relationships of "employeeGroupOwnerView" */
export type ServerEmployeeGroupOwnerView = {
  archived: Maybe<Scalars['timestamptz']>
  businessId: Maybe<Scalars['Int']>
  createdAt: Maybe<Scalars['timestamptz']>
  description: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  maxDiscount: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  roleId: Maybe<Scalars['String']>
  storageId: Maybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "employeeGroupOwnerView". All fields are combined with a logical 'AND'. */
export type ServerEmployeeGroupOwnerViewBoolExp = {
  _and?: InputMaybe<Array<ServerEmployeeGroupOwnerViewBoolExp>>
  _not?: InputMaybe<ServerEmployeeGroupOwnerViewBoolExp>
  _or?: InputMaybe<Array<ServerEmployeeGroupOwnerViewBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  maxDiscount?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  roleId?: InputMaybe<ServerStringComparisonExp>
  storageId?: InputMaybe<ServerIntComparisonExp>
}

/** input type for incrementing numeric columns in table "employeeGroupOwnerView" */
export type ServerEmployeeGroupOwnerViewIncInput = {
  maxDiscount?: InputMaybe<Scalars['Int']>
  storageId?: InputMaybe<Scalars['Int']>
}

/** response of any mutation on the table "employeeGroupOwnerView" */
export type ServerEmployeeGroupOwnerViewMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerEmployeeGroupOwnerView>
}

/** Ordering options when selecting data from "employeeGroupOwnerView". */
export type ServerEmployeeGroupOwnerViewOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  roleId?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "employeeGroupOwnerView" */
export enum ServerEmployeeGroupOwnerViewSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MaxDiscount = 'maxDiscount',
  /** column name */
  Name = 'name',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  StorageId = 'storageId',
}

/** input type for updating data in table "employeeGroupOwnerView" */
export type ServerEmployeeGroupOwnerViewSetInput = {
  description?: InputMaybe<Scalars['String']>
  maxDiscount?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  storageId?: InputMaybe<Scalars['Int']>
}

/** Streaming cursor of the table "employeeGroupOwnerView" */
export type ServerEmployeeGroupOwnerViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerEmployeeGroupOwnerViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerEmployeeGroupOwnerViewStreamCursorValueInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
  businessId?: InputMaybe<Scalars['Int']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  maxDiscount?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  roleId?: InputMaybe<Scalars['String']>
  storageId?: InputMaybe<Scalars['Int']>
}

export type ServerEmployeeGroupOwnerViewUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerEmployeeGroupOwnerViewIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerEmployeeGroupOwnerViewSetInput>
  /** filter the rows which have to be updated */
  where: ServerEmployeeGroupOwnerViewBoolExp
}

/** order by aggregate values of table "employeeGroup" */
export type ServerEmployeeGroupAggregateOrderBy = {
  avg?: InputMaybe<ServerEmployeeGroupAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerEmployeeGroupMaxOrderBy>
  min?: InputMaybe<ServerEmployeeGroupMinOrderBy>
  stddev?: InputMaybe<ServerEmployeeGroupStddevOrderBy>
  stddev_pop?: InputMaybe<ServerEmployeeGroupStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerEmployeeGroupStddevSampOrderBy>
  sum?: InputMaybe<ServerEmployeeGroupSumOrderBy>
  var_pop?: InputMaybe<ServerEmployeeGroupVarPopOrderBy>
  var_samp?: InputMaybe<ServerEmployeeGroupVarSampOrderBy>
  variance?: InputMaybe<ServerEmployeeGroupVarianceOrderBy>
}

/** order by avg() on columns of table "employeeGroup" */
export type ServerEmployeeGroupAvgOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "employeeGroup". All fields are combined with a logical 'AND'. */
export type ServerEmployeeGroupBoolExp = {
  _and?: InputMaybe<Array<ServerEmployeeGroupBoolExp>>
  _not?: InputMaybe<ServerEmployeeGroupBoolExp>
  _or?: InputMaybe<Array<ServerEmployeeGroupBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  employees?: InputMaybe<ServerEmployeeBoolExp>
  employees_aggregate?: InputMaybe<ServerEmployeeAggregateBoolExp>
  id?: InputMaybe<ServerIntComparisonExp>
  maxDiscount?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  role?: InputMaybe<ServerRoleBoolExp>
  roleId?: InputMaybe<ServerStringComparisonExp>
  storage?: InputMaybe<ServerStorageBoolExp>
  storageId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "employeeGroup" */
export enum ServerEmployeeGroupConstraint {
  /** unique or primary key constraint on columns "id" */
  EmployeeGroupsPkey = 'employeeGroups_pkey',
}

/** input type for incrementing numeric columns in table "employeeGroup" */
export type ServerEmployeeGroupIncInput = {
  maxDiscount?: InputMaybe<Scalars['Int']>
  storageId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "employeeGroup" */
export type ServerEmployeeGroupInsertInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  employees?: InputMaybe<ServerEmployeeArrRelInsertInput>
  maxDiscount?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  roleId?: InputMaybe<Scalars['String']>
  storage?: InputMaybe<ServerStorageObjRelInsertInput>
  storageId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "employeeGroup" */
export type ServerEmployeeGroupMaxOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  roleId?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "employeeGroup" */
export type ServerEmployeeGroupMinOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  roleId?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "employeeGroup" */
export type ServerEmployeeGroupMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerEmployeeGroup>
}

/** input type for inserting object relation for remote table "employeeGroup" */
export type ServerEmployeeGroupObjRelInsertInput = {
  data: ServerEmployeeGroupInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerEmployeeGroupOnConflict>
}

/** on_conflict condition type for table "employeeGroup" */
export type ServerEmployeeGroupOnConflict = {
  constraint: ServerEmployeeGroupConstraint
  update_columns?: Array<ServerEmployeeGroupUpdateColumn>
  where?: InputMaybe<ServerEmployeeGroupBoolExp>
}

/** Ordering options when selecting data from "employeeGroup". */
export type ServerEmployeeGroupOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  employees_aggregate?: InputMaybe<ServerEmployeeAggregateOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  role?: InputMaybe<ServerRoleOrderBy>
  roleId?: InputMaybe<ServerOrderBy>
  storage?: InputMaybe<ServerStorageOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: employeeGroup */
export type ServerEmployeeGroupPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "employeeGroup" */
export enum ServerEmployeeGroupSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MaxDiscount = 'maxDiscount',
  /** column name */
  Name = 'name',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  StorageId = 'storageId',
}

/** input type for updating data in table "employeeGroup" */
export type ServerEmployeeGroupSetInput = {
  description?: InputMaybe<Scalars['String']>
  maxDiscount?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  roleId?: InputMaybe<Scalars['String']>
  storageId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "employeeGroup" */
export type ServerEmployeeGroupStddevOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "employeeGroup" */
export type ServerEmployeeGroupStddevPopOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "employeeGroup" */
export type ServerEmployeeGroupStddevSampOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "employeeGroup" */
export type ServerEmployeeGroupSumOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "employeeGroup" */
export enum ServerEmployeeGroupUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  MaxDiscount = 'maxDiscount',
  /** column name */
  Name = 'name',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  StorageId = 'storageId',
}

export type ServerEmployeeGroupUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerEmployeeGroupIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerEmployeeGroupSetInput>
  /** filter the rows which have to be updated */
  where: ServerEmployeeGroupBoolExp
}

/** order by var_pop() on columns of table "employeeGroup" */
export type ServerEmployeeGroupVarPopOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "employeeGroup" */
export type ServerEmployeeGroupVarSampOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "employeeGroup" */
export type ServerEmployeeGroupVarianceOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "employeeReviewView" */
export type ServerEmployeeReviewView = {
  approved: Maybe<Scalars['Boolean']>
  approvedBy: Maybe<Scalars['Int']>
  averageRating: Maybe<Scalars['numeric']>
  /** An object relationship */
  business: Maybe<ServerBusiness>
  businessId: Maybe<Scalars['Int']>
  /** An object relationship */
  employee: Maybe<ServerEmployee>
  employeeId: Maybe<Scalars['Int']>
  firstName: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  quantity: Maybe<Scalars['bigint']>
}

/** Boolean expression to filter rows from the table "employeeReviewView". All fields are combined with a logical 'AND'. */
export type ServerEmployeeReviewViewBoolExp = {
  _and?: InputMaybe<Array<ServerEmployeeReviewViewBoolExp>>
  _not?: InputMaybe<ServerEmployeeReviewViewBoolExp>
  _or?: InputMaybe<Array<ServerEmployeeReviewViewBoolExp>>
  approved?: InputMaybe<ServerBooleanComparisonExp>
  approvedBy?: InputMaybe<ServerIntComparisonExp>
  averageRating?: InputMaybe<ServerNumericComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  firstName?: InputMaybe<ServerStringComparisonExp>
  lastName?: InputMaybe<ServerStringComparisonExp>
  quantity?: InputMaybe<ServerBigintComparisonExp>
}

/** Ordering options when selecting data from "employeeReviewView". */
export type ServerEmployeeReviewViewOrderBy = {
  approved?: InputMaybe<ServerOrderBy>
  approvedBy?: InputMaybe<ServerOrderBy>
  averageRating?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  firstName?: InputMaybe<ServerOrderBy>
  lastName?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** select columns of table "employeeReviewView" */
export enum ServerEmployeeReviewViewSelectColumn {
  /** column name */
  Approved = 'approved',
  /** column name */
  ApprovedBy = 'approvedBy',
  /** column name */
  AverageRating = 'averageRating',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Quantity = 'quantity',
}

/** Streaming cursor of the table "employeeReviewView" */
export type ServerEmployeeReviewViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerEmployeeReviewViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerEmployeeReviewViewStreamCursorValueInput = {
  approved?: InputMaybe<Scalars['Boolean']>
  approvedBy?: InputMaybe<Scalars['Int']>
  averageRating?: InputMaybe<Scalars['numeric']>
  businessId?: InputMaybe<Scalars['Int']>
  employeeId?: InputMaybe<Scalars['Int']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['bigint']>
}

/** Принадлежность расписания к конкретному сотруднику */
export type ServerEmployeeSchedule = {
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  /** An array relationship */
  dynamicDates: Array<ServerDynamicScheduleDate>
  /** An object relationship */
  employee: ServerEmployee
  employeeId: Scalars['Int']
  endDate: Maybe<Scalars['timestamptz']>
  id: Scalars['Int']
  isDynamic: Scalars['Boolean']
  /** An object relationship */
  schedule: Maybe<ServerSchedule>
  scheduleId: Maybe<Scalars['Int']>
  startDate: Scalars['timestamptz']
}

/** Принадлежность расписания к конкретному сотруднику */
export type ServerEmployeeScheduleDynamicDatesArgs = {
  distinct_on?: InputMaybe<Array<ServerDynamicScheduleDateSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerDynamicScheduleDateOrderBy>>
  where?: InputMaybe<ServerDynamicScheduleDateBoolExp>
}

/** aggregated selection of "employeeSchedule" */
export type ServerEmployeeScheduleAggregate = {
  aggregate: Maybe<ServerEmployeeScheduleAggregateFields>
  nodes: Array<ServerEmployeeSchedule>
}

export type ServerEmployeeScheduleAggregateBoolExp = {
  bool_and?: InputMaybe<ServerEmployeeScheduleAggregateBoolExpBoolAnd>
  bool_or?: InputMaybe<ServerEmployeeScheduleAggregateBoolExpBoolOr>
  count?: InputMaybe<ServerEmployeeScheduleAggregateBoolExpCount>
}

export type ServerEmployeeScheduleAggregateBoolExpBoolAnd = {
  arguments: ServerEmployeeScheduleSelectColumnEmployeeScheduleAggregateBoolExpBoolAndArgumentsColumns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerEmployeeScheduleBoolExp>
  predicate: ServerBooleanComparisonExp
}

export type ServerEmployeeScheduleAggregateBoolExpBoolOr = {
  arguments: ServerEmployeeScheduleSelectColumnEmployeeScheduleAggregateBoolExpBoolOrArgumentsColumns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerEmployeeScheduleBoolExp>
  predicate: ServerBooleanComparisonExp
}

export type ServerEmployeeScheduleAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServerEmployeeScheduleSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerEmployeeScheduleBoolExp>
  predicate: ServerIntComparisonExp
}

/** aggregate fields of "employeeSchedule" */
export type ServerEmployeeScheduleAggregateFields = {
  avg: Maybe<ServerEmployeeScheduleAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerEmployeeScheduleMaxFields>
  min: Maybe<ServerEmployeeScheduleMinFields>
  stddev: Maybe<ServerEmployeeScheduleStddevFields>
  stddev_pop: Maybe<ServerEmployeeScheduleStddevPopFields>
  stddev_samp: Maybe<ServerEmployeeScheduleStddevSampFields>
  sum: Maybe<ServerEmployeeScheduleSumFields>
  var_pop: Maybe<ServerEmployeeScheduleVarPopFields>
  var_samp: Maybe<ServerEmployeeScheduleVarSampFields>
  variance: Maybe<ServerEmployeeScheduleVarianceFields>
}

/** aggregate fields of "employeeSchedule" */
export type ServerEmployeeScheduleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerEmployeeScheduleSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "employeeSchedule" */
export type ServerEmployeeScheduleAggregateOrderBy = {
  avg?: InputMaybe<ServerEmployeeScheduleAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerEmployeeScheduleMaxOrderBy>
  min?: InputMaybe<ServerEmployeeScheduleMinOrderBy>
  stddev?: InputMaybe<ServerEmployeeScheduleStddevOrderBy>
  stddev_pop?: InputMaybe<ServerEmployeeScheduleStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerEmployeeScheduleStddevSampOrderBy>
  sum?: InputMaybe<ServerEmployeeScheduleSumOrderBy>
  var_pop?: InputMaybe<ServerEmployeeScheduleVarPopOrderBy>
  var_samp?: InputMaybe<ServerEmployeeScheduleVarSampOrderBy>
  variance?: InputMaybe<ServerEmployeeScheduleVarianceOrderBy>
}

/** input type for inserting array relation for remote table "employeeSchedule" */
export type ServerEmployeeScheduleArrRelInsertInput = {
  data: Array<ServerEmployeeScheduleInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerEmployeeScheduleOnConflict>
}

/** aggregate avg on columns */
export type ServerEmployeeScheduleAvgFields = {
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  scheduleId: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "employeeSchedule" */
export type ServerEmployeeScheduleAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "employeeSchedule". All fields are combined with a logical 'AND'. */
export type ServerEmployeeScheduleBoolExp = {
  _and?: InputMaybe<Array<ServerEmployeeScheduleBoolExp>>
  _not?: InputMaybe<ServerEmployeeScheduleBoolExp>
  _or?: InputMaybe<Array<ServerEmployeeScheduleBoolExp>>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  dynamicDates?: InputMaybe<ServerDynamicScheduleDateBoolExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  endDate?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  isDynamic?: InputMaybe<ServerBooleanComparisonExp>
  schedule?: InputMaybe<ServerScheduleBoolExp>
  scheduleId?: InputMaybe<ServerIntComparisonExp>
  startDate?: InputMaybe<ServerTimestamptzComparisonExp>
}

/** unique or primary key constraints on table "employeeSchedule" */
export enum ServerEmployeeScheduleConstraint {
  /** unique or primary key constraint on columns "id" */
  EmployeeSchedulePkey = 'employeeSchedule_pkey',
}

/** input type for inserting data into table "employeeSchedule" */
export type ServerEmployeeScheduleInsertInput = {
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  dynamicDates?: InputMaybe<ServerDynamicScheduleDateArrRelInsertInput>
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employeeId?: InputMaybe<Scalars['Int']>
  isDynamic?: InputMaybe<Scalars['Boolean']>
  schedule?: InputMaybe<ServerScheduleObjRelInsertInput>
  scheduleId?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type ServerEmployeeScheduleMaxFields = {
  branchId: Maybe<Scalars['Int']>
  businessId: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  endDate: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
  scheduleId: Maybe<Scalars['Int']>
  startDate: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "employeeSchedule" */
export type ServerEmployeeScheduleMaxOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  endDate?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
  startDate?: InputMaybe<ServerOrderBy>
}

/** aggregate min on columns */
export type ServerEmployeeScheduleMinFields = {
  branchId: Maybe<Scalars['Int']>
  businessId: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  endDate: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
  scheduleId: Maybe<Scalars['Int']>
  startDate: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "employeeSchedule" */
export type ServerEmployeeScheduleMinOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  endDate?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
  startDate?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "employeeSchedule" */
export type ServerEmployeeScheduleMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerEmployeeSchedule>
}

/** input type for inserting object relation for remote table "employeeSchedule" */
export type ServerEmployeeScheduleObjRelInsertInput = {
  data: ServerEmployeeScheduleInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerEmployeeScheduleOnConflict>
}

/** on_conflict condition type for table "employeeSchedule" */
export type ServerEmployeeScheduleOnConflict = {
  constraint: ServerEmployeeScheduleConstraint
  update_columns?: Array<ServerEmployeeScheduleUpdateColumn>
  where?: InputMaybe<ServerEmployeeScheduleBoolExp>
}

/** Ordering options when selecting data from "employeeSchedule". */
export type ServerEmployeeScheduleOrderBy = {
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  dynamicDates_aggregate?: InputMaybe<ServerDynamicScheduleDateAggregateOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  endDate?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  isDynamic?: InputMaybe<ServerOrderBy>
  schedule?: InputMaybe<ServerScheduleOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
  startDate?: InputMaybe<ServerOrderBy>
}

/** select columns of table "employeeSchedule" */
export enum ServerEmployeeScheduleSelectColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsDynamic = 'isDynamic',
  /** column name */
  ScheduleId = 'scheduleId',
  /** column name */
  StartDate = 'startDate',
}

/** select "employeeSchedule_aggregate_bool_exp_bool_and_arguments_columns" columns of table "employeeSchedule" */
export enum ServerEmployeeScheduleSelectColumnEmployeeScheduleAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDynamic = 'isDynamic',
}

/** select "employeeSchedule_aggregate_bool_exp_bool_or_arguments_columns" columns of table "employeeSchedule" */
export enum ServerEmployeeScheduleSelectColumnEmployeeScheduleAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDynamic = 'isDynamic',
}

/** aggregate stddev on columns */
export type ServerEmployeeScheduleStddevFields = {
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  scheduleId: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "employeeSchedule" */
export type ServerEmployeeScheduleStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_pop on columns */
export type ServerEmployeeScheduleStddevPopFields = {
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  scheduleId: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "employeeSchedule" */
export type ServerEmployeeScheduleStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_samp on columns */
export type ServerEmployeeScheduleStddevSampFields = {
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  scheduleId: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "employeeSchedule" */
export type ServerEmployeeScheduleStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** aggregate sum on columns */
export type ServerEmployeeScheduleSumFields = {
  branchId: Maybe<Scalars['Int']>
  businessId: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  scheduleId: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "employeeSchedule" */
export type ServerEmployeeScheduleSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "employeeSchedule" (current role has no relevant permissions) */
export enum ServerEmployeeScheduleUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** aggregate var_pop on columns */
export type ServerEmployeeScheduleVarPopFields = {
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  scheduleId: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "employeeSchedule" */
export type ServerEmployeeScheduleVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** aggregate var_samp on columns */
export type ServerEmployeeScheduleVarSampFields = {
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  scheduleId: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "employeeSchedule" */
export type ServerEmployeeScheduleVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** aggregate variance on columns */
export type ServerEmployeeScheduleVarianceFields = {
  branchId: Maybe<Scalars['Float']>
  businessId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  scheduleId: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "employeeSchedule" */
export type ServerEmployeeScheduleVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  scheduleId?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "employeeSettings" */
export type ServerEmployeeSettings = {
  /** An object relationship */
  employee: ServerEmployee
  employeeId: Scalars['Int']
  id: Scalars['Int']
  lang: Maybe<Scalars['String']>
  notificationSettings: Scalars['jsonb']
  preferredConnections: Scalars['jsonb']
}

/** columns and relationships of "employeeSettings" */
export type ServerEmployeeSettingsNotificationSettingsArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "employeeSettings" */
export type ServerEmployeeSettingsPreferredConnectionsArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type ServerEmployeeSettingsAppendInput = {
  notificationSettings?: InputMaybe<Scalars['jsonb']>
  preferredConnections?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "employeeSettings". All fields are combined with a logical 'AND'. */
export type ServerEmployeeSettingsBoolExp = {
  _and?: InputMaybe<Array<ServerEmployeeSettingsBoolExp>>
  _not?: InputMaybe<ServerEmployeeSettingsBoolExp>
  _or?: InputMaybe<Array<ServerEmployeeSettingsBoolExp>>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  lang?: InputMaybe<ServerStringComparisonExp>
  notificationSettings?: InputMaybe<ServerJsonbComparisonExp>
  preferredConnections?: InputMaybe<ServerJsonbComparisonExp>
}

/** unique or primary key constraints on table "employeeSettings" */
export enum ServerEmployeeSettingsConstraint {
  /** unique or primary key constraint on columns "employeeId" */
  EmployeeSettingsEmployeeIdKey = 'employeeSettings_employeeId_key',
  /** unique or primary key constraint on columns "id" */
  EmployeeSettingsPkey = 'employeeSettings_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ServerEmployeeSettingsDeleteAtPathInput = {
  notificationSettings?: InputMaybe<Array<Scalars['String']>>
  preferredConnections?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ServerEmployeeSettingsDeleteElemInput = {
  notificationSettings?: InputMaybe<Scalars['Int']>
  preferredConnections?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ServerEmployeeSettingsDeleteKeyInput = {
  notificationSettings?: InputMaybe<Scalars['String']>
  preferredConnections?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "employeeSettings" */
export type ServerEmployeeSettingsInsertInput = {
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employeeId?: InputMaybe<Scalars['Int']>
  lang?: InputMaybe<Scalars['String']>
  preferredConnections?: InputMaybe<Scalars['jsonb']>
}

/** response of any mutation on the table "employeeSettings" */
export type ServerEmployeeSettingsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerEmployeeSettings>
}

/** input type for inserting object relation for remote table "employeeSettings" */
export type ServerEmployeeSettingsObjRelInsertInput = {
  data: ServerEmployeeSettingsInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerEmployeeSettingsOnConflict>
}

/** on_conflict condition type for table "employeeSettings" */
export type ServerEmployeeSettingsOnConflict = {
  constraint: ServerEmployeeSettingsConstraint
  update_columns?: Array<ServerEmployeeSettingsUpdateColumn>
  where?: InputMaybe<ServerEmployeeSettingsBoolExp>
}

/** Ordering options when selecting data from "employeeSettings". */
export type ServerEmployeeSettingsOrderBy = {
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  lang?: InputMaybe<ServerOrderBy>
  notificationSettings?: InputMaybe<ServerOrderBy>
  preferredConnections?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: employeeSettings */
export type ServerEmployeeSettingsPkColumnsInput = {
  id: Scalars['Int']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ServerEmployeeSettingsPrependInput = {
  notificationSettings?: InputMaybe<Scalars['jsonb']>
  preferredConnections?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "employeeSettings" */
export enum ServerEmployeeSettingsSelectColumn {
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  NotificationSettings = 'notificationSettings',
  /** column name */
  PreferredConnections = 'preferredConnections',
}

/** input type for updating data in table "employeeSettings" */
export type ServerEmployeeSettingsSetInput = {
  lang?: InputMaybe<Scalars['String']>
  notificationSettings?: InputMaybe<Scalars['jsonb']>
  preferredConnections?: InputMaybe<Scalars['jsonb']>
}

/** update columns of table "employeeSettings" */
export enum ServerEmployeeSettingsUpdateColumn {
  /** column name */
  Lang = 'lang',
  /** column name */
  NotificationSettings = 'notificationSettings',
  /** column name */
  PreferredConnections = 'preferredConnections',
}

export type ServerEmployeeSettingsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ServerEmployeeSettingsAppendInput>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ServerEmployeeSettingsDeleteAtPathInput>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ServerEmployeeSettingsDeleteElemInput>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ServerEmployeeSettingsDeleteKeyInput>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ServerEmployeeSettingsPrependInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerEmployeeSettingsSetInput>
  /** filter the rows which have to be updated */
  where: ServerEmployeeSettingsBoolExp
}

/** aggregated selection of "employee" */
export type ServerEmployeeAggregate = {
  aggregate: Maybe<ServerEmployeeAggregateFields>
  nodes: Array<ServerEmployee>
}

export type ServerEmployeeAggregateBoolExp = {
  count?: InputMaybe<ServerEmployeeAggregateBoolExpCount>
}

export type ServerEmployeeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServerEmployeeSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerEmployeeBoolExp>
  predicate: ServerIntComparisonExp
}

/** aggregate fields of "employee" */
export type ServerEmployeeAggregateFields = {
  avg: Maybe<ServerEmployeeAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerEmployeeMaxFields>
  min: Maybe<ServerEmployeeMinFields>
  stddev: Maybe<ServerEmployeeStddevFields>
  stddev_pop: Maybe<ServerEmployeeStddevPopFields>
  stddev_samp: Maybe<ServerEmployeeStddevSampFields>
  sum: Maybe<ServerEmployeeSumFields>
  var_pop: Maybe<ServerEmployeeVarPopFields>
  var_samp: Maybe<ServerEmployeeVarSampFields>
  variance: Maybe<ServerEmployeeVarianceFields>
}

/** aggregate fields of "employee" */
export type ServerEmployeeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerEmployeeSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "employee" */
export type ServerEmployeeAggregateOrderBy = {
  avg?: InputMaybe<ServerEmployeeAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerEmployeeMaxOrderBy>
  min?: InputMaybe<ServerEmployeeMinOrderBy>
  stddev?: InputMaybe<ServerEmployeeStddevOrderBy>
  stddev_pop?: InputMaybe<ServerEmployeeStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerEmployeeStddevSampOrderBy>
  sum?: InputMaybe<ServerEmployeeSumOrderBy>
  var_pop?: InputMaybe<ServerEmployeeVarPopOrderBy>
  var_samp?: InputMaybe<ServerEmployeeVarSampOrderBy>
  variance?: InputMaybe<ServerEmployeeVarianceOrderBy>
}

/** input type for inserting array relation for remote table "employee" */
export type ServerEmployeeArrRelInsertInput = {
  data: Array<ServerEmployeeInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerEmployeeOnConflict>
}

/** aggregate avg on columns */
export type ServerEmployeeAvgFields = {
  businessId: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  preferredPayment: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "employee" */
export type ServerEmployeeAvgOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  preferredPayment?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "employee". All fields are combined with a logical 'AND'. */
export type ServerEmployeeBoolExp = {
  _and?: InputMaybe<Array<ServerEmployeeBoolExp>>
  _not?: InputMaybe<ServerEmployeeBoolExp>
  _or?: InputMaybe<Array<ServerEmployeeBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  birthDate?: InputMaybe<ServerTimestamptzComparisonExp>
  bookings?: InputMaybe<ServerBookingBoolExp>
  bookings_aggregate?: InputMaybe<ServerBookingAggregateBoolExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  email?: InputMaybe<ServerStringComparisonExp>
  employeeGroup?: InputMaybe<ServerEmployeeGroupBoolExp>
  employeeSchedules?: InputMaybe<ServerEmployeeScheduleBoolExp>
  employeeSchedules_aggregate?: InputMaybe<ServerEmployeeScheduleAggregateBoolExp>
  employeeSettings?: InputMaybe<ServerEmployeeSettingsBoolExp>
  firstName?: InputMaybe<ServerStringComparisonExp>
  gender?: InputMaybe<ServerStringComparisonExp>
  groupId?: InputMaybe<ServerIntComparisonExp>
  hasTelegram?: InputMaybe<ServerBooleanComparisonExp>
  hasViber?: InputMaybe<ServerBooleanComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  information?: InputMaybe<ServerStringComparisonExp>
  lastName?: InputMaybe<ServerStringComparisonExp>
  middleName?: InputMaybe<ServerStringComparisonExp>
  ownBusiness?: InputMaybe<ServerBusinessBoolExp>
  phone?: InputMaybe<ServerStringComparisonExp>
  photo?: InputMaybe<ServerStringComparisonExp>
  preferredPayment?: InputMaybe<ServerSmallintComparisonExp>
  reviewRating?: InputMaybe<ServerEmployeeReviewViewBoolExp>
  reviews?: InputMaybe<ServerBookingReviewViewBoolExp>
  salarySettings?: InputMaybe<ServerSalarySettingBoolExp>
  salarySettings_aggregate?: InputMaybe<ServerSalarySettingAggregateBoolExp>
  serviceEmployees?: InputMaybe<ServerServiceEmployeeBoolExp>
  servicePermissions?: InputMaybe<ServerServicePermissionBoolExp>
  status?: InputMaybe<ServerStringComparisonExp>
  timeoffs?: InputMaybe<ServerTimeOffBoolExp>
}

/** unique or primary key constraints on table "employee" */
export enum ServerEmployeeConstraint {
  /** unique or primary key constraint on columns "id" */
  EmployeesPkey = 'employees_pkey',
}

/** input type for incrementing numeric columns in table "employee" */
export type ServerEmployeeIncInput = {
  groupId?: InputMaybe<Scalars['Int']>
  preferredPayment?: InputMaybe<Scalars['smallint']>
}

/** input type for inserting data into table "employee" */
export type ServerEmployeeInsertInput = {
  birthDate?: InputMaybe<Scalars['timestamptz']>
  bookings?: InputMaybe<ServerBookingArrRelInsertInput>
  email?: InputMaybe<Scalars['String']>
  employeeGroup?: InputMaybe<ServerEmployeeGroupObjRelInsertInput>
  employeeSchedules?: InputMaybe<ServerEmployeeScheduleArrRelInsertInput>
  employeeSettings?: InputMaybe<ServerEmployeeSettingsObjRelInsertInput>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  groupId?: InputMaybe<Scalars['Int']>
  information?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  middleName?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['String']>
  preferredPayment?: InputMaybe<Scalars['smallint']>
  salarySettings?: InputMaybe<ServerSalarySettingArrRelInsertInput>
  serviceEmployees?: InputMaybe<ServerServiceEmployeeArrRelInsertInput>
  servicePermissions?: InputMaybe<ServerServicePermissionArrRelInsertInput>
  status?: InputMaybe<Scalars['String']>
  timeoffs?: InputMaybe<ServerTimeOffArrRelInsertInput>
}

/** aggregate max on columns */
export type ServerEmployeeMaxFields = {
  archived: Maybe<Scalars['timestamptz']>
  birthDate: Maybe<Scalars['timestamptz']>
  businessId: Maybe<Scalars['Int']>
  createdAt: Maybe<Scalars['timestamptz']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  gender: Maybe<Scalars['String']>
  groupId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  information: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  middleName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  photo: Maybe<Scalars['String']>
  preferredPayment: Maybe<Scalars['smallint']>
  status: Maybe<Scalars['String']>
}

/** order by max() on columns of table "employee" */
export type ServerEmployeeMaxOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  birthDate?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  firstName?: InputMaybe<ServerOrderBy>
  gender?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  information?: InputMaybe<ServerOrderBy>
  lastName?: InputMaybe<ServerOrderBy>
  middleName?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  photo?: InputMaybe<ServerOrderBy>
  preferredPayment?: InputMaybe<ServerOrderBy>
  status?: InputMaybe<ServerOrderBy>
}

/** aggregate min on columns */
export type ServerEmployeeMinFields = {
  archived: Maybe<Scalars['timestamptz']>
  birthDate: Maybe<Scalars['timestamptz']>
  businessId: Maybe<Scalars['Int']>
  createdAt: Maybe<Scalars['timestamptz']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  gender: Maybe<Scalars['String']>
  groupId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  information: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  middleName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  photo: Maybe<Scalars['String']>
  preferredPayment: Maybe<Scalars['smallint']>
  status: Maybe<Scalars['String']>
}

/** order by min() on columns of table "employee" */
export type ServerEmployeeMinOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  birthDate?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  firstName?: InputMaybe<ServerOrderBy>
  gender?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  information?: InputMaybe<ServerOrderBy>
  lastName?: InputMaybe<ServerOrderBy>
  middleName?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  photo?: InputMaybe<ServerOrderBy>
  preferredPayment?: InputMaybe<ServerOrderBy>
  status?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "employee" */
export type ServerEmployeeMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerEmployee>
}

/** input type for inserting object relation for remote table "employee" */
export type ServerEmployeeObjRelInsertInput = {
  data: ServerEmployeeInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerEmployeeOnConflict>
}

/** on_conflict condition type for table "employee" */
export type ServerEmployeeOnConflict = {
  constraint: ServerEmployeeConstraint
  update_columns?: Array<ServerEmployeeUpdateColumn>
  where?: InputMaybe<ServerEmployeeBoolExp>
}

/** Ordering options when selecting data from "employee". */
export type ServerEmployeeOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  birthDate?: InputMaybe<ServerOrderBy>
  bookings_aggregate?: InputMaybe<ServerBookingAggregateOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  employeeGroup?: InputMaybe<ServerEmployeeGroupOrderBy>
  employeeSchedules_aggregate?: InputMaybe<ServerEmployeeScheduleAggregateOrderBy>
  employeeSettings?: InputMaybe<ServerEmployeeSettingsOrderBy>
  firstName?: InputMaybe<ServerOrderBy>
  gender?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  hasTelegram?: InputMaybe<ServerOrderBy>
  hasViber?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  information?: InputMaybe<ServerOrderBy>
  lastName?: InputMaybe<ServerOrderBy>
  middleName?: InputMaybe<ServerOrderBy>
  ownBusiness?: InputMaybe<ServerBusinessOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  photo?: InputMaybe<ServerOrderBy>
  preferredPayment?: InputMaybe<ServerOrderBy>
  reviewRating?: InputMaybe<ServerEmployeeReviewViewOrderBy>
  reviews_aggregate?: InputMaybe<ServerBookingReviewViewAggregateOrderBy>
  salarySettings_aggregate?: InputMaybe<ServerSalarySettingAggregateOrderBy>
  serviceEmployees_aggregate?: InputMaybe<ServerServiceEmployeeAggregateOrderBy>
  servicePermissions_aggregate?: InputMaybe<ServerServicePermissionAggregateOrderBy>
  status?: InputMaybe<ServerOrderBy>
  timeoffs_aggregate?: InputMaybe<ServerTimeOffAggregateOrderBy>
}

/** primary key columns input for table: employee */
export type ServerEmployeePkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "employee" */
export enum ServerEmployeeSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  Information = 'information',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Phone = 'phone',
  /** column name */
  Photo = 'photo',
  /** column name */
  PreferredPayment = 'preferredPayment',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "employee" */
export type ServerEmployeeSetInput = {
  birthDate?: InputMaybe<Scalars['timestamptz']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  groupId?: InputMaybe<Scalars['Int']>
  information?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  middleName?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['String']>
  preferredPayment?: InputMaybe<Scalars['smallint']>
  status?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type ServerEmployeeStddevFields = {
  businessId: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  preferredPayment: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "employee" */
export type ServerEmployeeStddevOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  preferredPayment?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_pop on columns */
export type ServerEmployeeStddevPopFields = {
  businessId: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  preferredPayment: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "employee" */
export type ServerEmployeeStddevPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  preferredPayment?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_samp on columns */
export type ServerEmployeeStddevSampFields = {
  businessId: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  preferredPayment: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "employee" */
export type ServerEmployeeStddevSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  preferredPayment?: InputMaybe<ServerOrderBy>
}

/** aggregate sum on columns */
export type ServerEmployeeSumFields = {
  businessId: Maybe<Scalars['Int']>
  groupId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  preferredPayment: Maybe<Scalars['smallint']>
}

/** order by sum() on columns of table "employee" */
export type ServerEmployeeSumOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  preferredPayment?: InputMaybe<ServerOrderBy>
}

/** update columns of table "employee" */
export enum ServerEmployeeUpdateColumn {
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Information = 'information',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Phone = 'phone',
  /** column name */
  Photo = 'photo',
  /** column name */
  PreferredPayment = 'preferredPayment',
  /** column name */
  Status = 'status',
}

export type ServerEmployeeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerEmployeeIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerEmployeeSetInput>
  /** filter the rows which have to be updated */
  where: ServerEmployeeBoolExp
}

/** aggregate var_pop on columns */
export type ServerEmployeeVarPopFields = {
  businessId: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  preferredPayment: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "employee" */
export type ServerEmployeeVarPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  preferredPayment?: InputMaybe<ServerOrderBy>
}

/** aggregate var_samp on columns */
export type ServerEmployeeVarSampFields = {
  businessId: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  preferredPayment: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "employee" */
export type ServerEmployeeVarSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  preferredPayment?: InputMaybe<ServerOrderBy>
}

/** aggregate variance on columns */
export type ServerEmployeeVarianceFields = {
  businessId: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  preferredPayment: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "employee" */
export type ServerEmployeeVarianceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  preferredPayment?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "expaneInfo" */
export type ServerExpaneInfo = {
  activeUsers: Scalars['Int']
  companyGrowthPercentage: Scalars['Int']
  descriptionEn: Maybe<Scalars['String']>
  descriptionRu: Maybe<Scalars['String']>
  descriptionUk: Maybe<Scalars['String']>
  id: Scalars['Int']
  image: Maybe<Scalars['String']>
  percentageOfSatisfied: Scalars['Int']
  teamMembers: Scalars['Int']
  titleEn: Scalars['String']
  titleRu: Scalars['String']
  titleUk: Scalars['String']
}

/** columns and relationships of "expaneInfoCategory" */
export type ServerExpaneInfoCategory = {
  id: Scalars['Int']
  image: Scalars['String']
  titleEn: Scalars['String']
  titleRu: Scalars['String']
  titleUk: Scalars['String']
}

/** Boolean expression to filter rows from the table "expaneInfoCategory". All fields are combined with a logical 'AND'. */
export type ServerExpaneInfoCategoryBoolExp = {
  _and?: InputMaybe<Array<ServerExpaneInfoCategoryBoolExp>>
  _not?: InputMaybe<ServerExpaneInfoCategoryBoolExp>
  _or?: InputMaybe<Array<ServerExpaneInfoCategoryBoolExp>>
  id?: InputMaybe<ServerIntComparisonExp>
  image?: InputMaybe<ServerStringComparisonExp>
  titleEn?: InputMaybe<ServerStringComparisonExp>
  titleRu?: InputMaybe<ServerStringComparisonExp>
  titleUk?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "expaneInfoCategory" */
export enum ServerExpaneInfoCategoryConstraint {
  /** unique or primary key constraint on columns "id" */
  ExpaneInfoCategoryPkey = 'expaneInfoCategory_pkey',
}

/** input type for inserting data into table "expaneInfoCategory" */
export type ServerExpaneInfoCategoryInsertInput = {
  image?: InputMaybe<Scalars['String']>
  titleEn?: InputMaybe<Scalars['String']>
  titleRu?: InputMaybe<Scalars['String']>
  titleUk?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "expaneInfoCategory" */
export type ServerExpaneInfoCategoryMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerExpaneInfoCategory>
}

/** on_conflict condition type for table "expaneInfoCategory" */
export type ServerExpaneInfoCategoryOnConflict = {
  constraint: ServerExpaneInfoCategoryConstraint
  update_columns?: Array<ServerExpaneInfoCategoryUpdateColumn>
  where?: InputMaybe<ServerExpaneInfoCategoryBoolExp>
}

/** Ordering options when selecting data from "expaneInfoCategory". */
export type ServerExpaneInfoCategoryOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  image?: InputMaybe<ServerOrderBy>
  titleEn?: InputMaybe<ServerOrderBy>
  titleRu?: InputMaybe<ServerOrderBy>
  titleUk?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: expaneInfoCategory */
export type ServerExpaneInfoCategoryPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "expaneInfoCategory" */
export enum ServerExpaneInfoCategorySelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  TitleEn = 'titleEn',
  /** column name */
  TitleRu = 'titleRu',
  /** column name */
  TitleUk = 'titleUk',
}

/** input type for updating data in table "expaneInfoCategory" */
export type ServerExpaneInfoCategorySetInput = {
  image?: InputMaybe<Scalars['String']>
  titleEn?: InputMaybe<Scalars['String']>
  titleRu?: InputMaybe<Scalars['String']>
  titleUk?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "expaneInfoCategory" */
export type ServerExpaneInfoCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerExpaneInfoCategoryStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerExpaneInfoCategoryStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>
  image?: InputMaybe<Scalars['String']>
  titleEn?: InputMaybe<Scalars['String']>
  titleRu?: InputMaybe<Scalars['String']>
  titleUk?: InputMaybe<Scalars['String']>
}

/** update columns of table "expaneInfoCategory" */
export enum ServerExpaneInfoCategoryUpdateColumn {
  /** column name */
  Image = 'image',
  /** column name */
  TitleEn = 'titleEn',
  /** column name */
  TitleRu = 'titleRu',
  /** column name */
  TitleUk = 'titleUk',
}

export type ServerExpaneInfoCategoryUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerExpaneInfoCategorySetInput>
  /** filter the rows which have to be updated */
  where: ServerExpaneInfoCategoryBoolExp
}

/** Boolean expression to filter rows from the table "expaneInfo". All fields are combined with a logical 'AND'. */
export type ServerExpaneInfoBoolExp = {
  _and?: InputMaybe<Array<ServerExpaneInfoBoolExp>>
  _not?: InputMaybe<ServerExpaneInfoBoolExp>
  _or?: InputMaybe<Array<ServerExpaneInfoBoolExp>>
  activeUsers?: InputMaybe<ServerIntComparisonExp>
  companyGrowthPercentage?: InputMaybe<ServerIntComparisonExp>
  descriptionEn?: InputMaybe<ServerStringComparisonExp>
  descriptionRu?: InputMaybe<ServerStringComparisonExp>
  descriptionUk?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  image?: InputMaybe<ServerStringComparisonExp>
  percentageOfSatisfied?: InputMaybe<ServerIntComparisonExp>
  teamMembers?: InputMaybe<ServerIntComparisonExp>
  titleEn?: InputMaybe<ServerStringComparisonExp>
  titleRu?: InputMaybe<ServerStringComparisonExp>
  titleUk?: InputMaybe<ServerStringComparisonExp>
}

/** input type for incrementing numeric columns in table "expaneInfo" */
export type ServerExpaneInfoIncInput = {
  activeUsers?: InputMaybe<Scalars['Int']>
  companyGrowthPercentage?: InputMaybe<Scalars['Int']>
  percentageOfSatisfied?: InputMaybe<Scalars['Int']>
  teamMembers?: InputMaybe<Scalars['Int']>
}

/** response of any mutation on the table "expaneInfo" */
export type ServerExpaneInfoMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerExpaneInfo>
}

/** Ordering options when selecting data from "expaneInfo". */
export type ServerExpaneInfoOrderBy = {
  activeUsers?: InputMaybe<ServerOrderBy>
  companyGrowthPercentage?: InputMaybe<ServerOrderBy>
  descriptionEn?: InputMaybe<ServerOrderBy>
  descriptionRu?: InputMaybe<ServerOrderBy>
  descriptionUk?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  image?: InputMaybe<ServerOrderBy>
  percentageOfSatisfied?: InputMaybe<ServerOrderBy>
  teamMembers?: InputMaybe<ServerOrderBy>
  titleEn?: InputMaybe<ServerOrderBy>
  titleRu?: InputMaybe<ServerOrderBy>
  titleUk?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: expaneInfo */
export type ServerExpaneInfoPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "expaneInfo" */
export enum ServerExpaneInfoSelectColumn {
  /** column name */
  ActiveUsers = 'activeUsers',
  /** column name */
  CompanyGrowthPercentage = 'companyGrowthPercentage',
  /** column name */
  DescriptionEn = 'descriptionEn',
  /** column name */
  DescriptionRu = 'descriptionRu',
  /** column name */
  DescriptionUk = 'descriptionUk',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  PercentageOfSatisfied = 'percentageOfSatisfied',
  /** column name */
  TeamMembers = 'teamMembers',
  /** column name */
  TitleEn = 'titleEn',
  /** column name */
  TitleRu = 'titleRu',
  /** column name */
  TitleUk = 'titleUk',
}

/** input type for updating data in table "expaneInfo" */
export type ServerExpaneInfoSetInput = {
  activeUsers?: InputMaybe<Scalars['Int']>
  companyGrowthPercentage?: InputMaybe<Scalars['Int']>
  descriptionEn?: InputMaybe<Scalars['String']>
  descriptionRu?: InputMaybe<Scalars['String']>
  descriptionUk?: InputMaybe<Scalars['String']>
  image?: InputMaybe<Scalars['String']>
  percentageOfSatisfied?: InputMaybe<Scalars['Int']>
  teamMembers?: InputMaybe<Scalars['Int']>
  titleEn?: InputMaybe<Scalars['String']>
  titleRu?: InputMaybe<Scalars['String']>
  titleUk?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "expaneInfo" */
export type ServerExpaneInfoStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerExpaneInfoStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerExpaneInfoStreamCursorValueInput = {
  activeUsers?: InputMaybe<Scalars['Int']>
  companyGrowthPercentage?: InputMaybe<Scalars['Int']>
  descriptionEn?: InputMaybe<Scalars['String']>
  descriptionRu?: InputMaybe<Scalars['String']>
  descriptionUk?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  image?: InputMaybe<Scalars['String']>
  percentageOfSatisfied?: InputMaybe<Scalars['Int']>
  teamMembers?: InputMaybe<Scalars['Int']>
  titleEn?: InputMaybe<Scalars['String']>
  titleRu?: InputMaybe<Scalars['String']>
  titleUk?: InputMaybe<Scalars['String']>
}

export type ServerExpaneInfoUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerExpaneInfoIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerExpaneInfoSetInput>
  /** filter the rows which have to be updated */
  where: ServerExpaneInfoBoolExp
}

/** columns and relationships of "expensesReason" */
export type ServerExpensesReason = {
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  id: Scalars['Int']
  name: Scalars['String']
}

/** Boolean expression to filter rows from the table "expensesReason". All fields are combined with a logical 'AND'. */
export type ServerExpensesReasonBoolExp = {
  _and?: InputMaybe<Array<ServerExpensesReasonBoolExp>>
  _not?: InputMaybe<ServerExpensesReasonBoolExp>
  _or?: InputMaybe<Array<ServerExpensesReasonBoolExp>>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "expensesReason" */
export enum ServerExpensesReasonConstraint {
  /** unique or primary key constraint on columns "id" */
  ExpensesReasonPkey = 'expensesReason_pkey',
}

/** input type for inserting data into table "expensesReason" */
export type ServerExpensesReasonInsertInput = {
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "expensesReason" */
export type ServerExpensesReasonMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerExpensesReason>
}

/** input type for inserting object relation for remote table "expensesReason" */
export type ServerExpensesReasonObjRelInsertInput = {
  data: ServerExpensesReasonInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerExpensesReasonOnConflict>
}

/** on_conflict condition type for table "expensesReason" */
export type ServerExpensesReasonOnConflict = {
  constraint: ServerExpensesReasonConstraint
  update_columns?: Array<ServerExpensesReasonUpdateColumn>
  where?: InputMaybe<ServerExpensesReasonBoolExp>
}

/** Ordering options when selecting data from "expensesReason". */
export type ServerExpensesReasonOrderBy = {
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: expensesReason */
export type ServerExpensesReasonPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "expensesReason" */
export enum ServerExpensesReasonSelectColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "expensesReason" */
export type ServerExpensesReasonSetInput = {
  name?: InputMaybe<Scalars['String']>
}

/** update columns of table "expensesReason" */
export enum ServerExpensesReasonUpdateColumn {
  /** column name */
  Name = 'name',
}

export type ServerExpensesReasonUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerExpensesReasonSetInput>
  /** filter the rows which have to be updated */
  where: ServerExpensesReasonBoolExp
}

/** If a new feature is found to cause problems or bugs, feature flags allow for its quick deactivation without the need to deploy a new version of the application. */
export type ServerFeatureFlags = {
  enabled: Scalars['Boolean']
  key: Scalars['String']
}

/** Boolean expression to filter rows from the table "featureFlags". All fields are combined with a logical 'AND'. */
export type ServerFeatureFlagsBoolExp = {
  _and?: InputMaybe<Array<ServerFeatureFlagsBoolExp>>
  _not?: InputMaybe<ServerFeatureFlagsBoolExp>
  _or?: InputMaybe<Array<ServerFeatureFlagsBoolExp>>
  enabled?: InputMaybe<ServerBooleanComparisonExp>
  key?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "featureFlags". */
export type ServerFeatureFlagsOrderBy = {
  enabled?: InputMaybe<ServerOrderBy>
  key?: InputMaybe<ServerOrderBy>
}

/** select columns of table "featureFlags" */
export enum ServerFeatureFlagsSelectColumn {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Key = 'key',
}

/** Streaming cursor of the table "featureFlags" */
export type ServerFeatureFlagsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerFeatureFlagsStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerFeatureFlagsStreamCursorValueInput = {
  enabled?: InputMaybe<Scalars['Boolean']>
  key?: InputMaybe<Scalars['String']>
}

/** Контактные лица */
export type ServerHuman = {
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  createdAt: Scalars['timestamptz']
  email: Maybe<Scalars['String']>
  firstName: Scalars['String']
  id: Scalars['Int']
  lastName: Maybe<Scalars['String']>
  middleName: Maybe<Scalars['String']>
  note: Maybe<Scalars['String']>
  phone: Scalars['String']
  /** An object relationship */
  supplier: ServerSupplier
  supplierId: Scalars['Int']
}

/** order by aggregate values of table "human" */
export type ServerHumanAggregateOrderBy = {
  avg?: InputMaybe<ServerHumanAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerHumanMaxOrderBy>
  min?: InputMaybe<ServerHumanMinOrderBy>
  stddev?: InputMaybe<ServerHumanStddevOrderBy>
  stddev_pop?: InputMaybe<ServerHumanStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerHumanStddevSampOrderBy>
  sum?: InputMaybe<ServerHumanSumOrderBy>
  var_pop?: InputMaybe<ServerHumanVarPopOrderBy>
  var_samp?: InputMaybe<ServerHumanVarSampOrderBy>
  variance?: InputMaybe<ServerHumanVarianceOrderBy>
}

/** input type for inserting array relation for remote table "human" */
export type ServerHumanArrRelInsertInput = {
  data: Array<ServerHumanInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerHumanOnConflict>
}

/** order by avg() on columns of table "human" */
export type ServerHumanAvgOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "human". All fields are combined with a logical 'AND'. */
export type ServerHumanBoolExp = {
  _and?: InputMaybe<Array<ServerHumanBoolExp>>
  _not?: InputMaybe<ServerHumanBoolExp>
  _or?: InputMaybe<Array<ServerHumanBoolExp>>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  email?: InputMaybe<ServerStringComparisonExp>
  firstName?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  lastName?: InputMaybe<ServerStringComparisonExp>
  middleName?: InputMaybe<ServerStringComparisonExp>
  note?: InputMaybe<ServerStringComparisonExp>
  phone?: InputMaybe<ServerStringComparisonExp>
  supplier?: InputMaybe<ServerSupplierBoolExp>
  supplierId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "human" */
export enum ServerHumanConstraint {
  /** unique or primary key constraint on columns "id" */
  SupplierEmployeePkey = 'supplierEmployee_pkey',
}

/** input type for incrementing numeric columns in table "human" */
export type ServerHumanIncInput = {
  supplierId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "human" */
export type ServerHumanInsertInput = {
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  middleName?: InputMaybe<Scalars['String']>
  note?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  supplier?: InputMaybe<ServerSupplierObjRelInsertInput>
  supplierId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "human" */
export type ServerHumanMaxOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  firstName?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  lastName?: InputMaybe<ServerOrderBy>
  middleName?: InputMaybe<ServerOrderBy>
  note?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "human" */
export type ServerHumanMinOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  firstName?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  lastName?: InputMaybe<ServerOrderBy>
  middleName?: InputMaybe<ServerOrderBy>
  note?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "human" */
export type ServerHumanMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerHuman>
}

/** on_conflict condition type for table "human" */
export type ServerHumanOnConflict = {
  constraint: ServerHumanConstraint
  update_columns?: Array<ServerHumanUpdateColumn>
  where?: InputMaybe<ServerHumanBoolExp>
}

/** Ordering options when selecting data from "human". */
export type ServerHumanOrderBy = {
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  firstName?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  lastName?: InputMaybe<ServerOrderBy>
  middleName?: InputMaybe<ServerOrderBy>
  note?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  supplier?: InputMaybe<ServerSupplierOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: human */
export type ServerHumanPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "human" */
export enum ServerHumanSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Note = 'note',
  /** column name */
  Phone = 'phone',
  /** column name */
  SupplierId = 'supplierId',
}

/** input type for updating data in table "human" */
export type ServerHumanSetInput = {
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  middleName?: InputMaybe<Scalars['String']>
  note?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  supplierId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "human" */
export type ServerHumanStddevOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "human" */
export type ServerHumanStddevPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "human" */
export type ServerHumanStddevSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "human" */
export type ServerHumanSumOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "human" */
export enum ServerHumanUpdateColumn {
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Note = 'note',
  /** column name */
  Phone = 'phone',
  /** column name */
  SupplierId = 'supplierId',
}

export type ServerHumanUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerHumanIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerHumanSetInput>
  /** filter the rows which have to be updated */
  where: ServerHumanBoolExp
}

/** order by var_pop() on columns of table "human" */
export type ServerHumanVarPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "human" */
export type ServerHumanVarSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "human" */
export type ServerHumanVarianceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "inventory" */
export type ServerInventory = {
  /** An object relationship */
  author: ServerEmployee
  authorId: Scalars['Int']
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  createdAt: Scalars['timestamptz']
  fulfilled: Scalars['Boolean']
  id: Scalars['Int']
  inventoryDate: Scalars['timestamptz']
  /** An array relationship */
  inventoryProducts: Array<ServerInventoryProduct>
  /** An array relationship */
  movements: Array<ServerMovement>
  /** An aggregate relationship */
  movements_aggregate: ServerMovementAggregate
  number: Scalars['String']
  /** An object relationship */
  storage: ServerStorage
  storageId: Scalars['Int']
}

/** columns and relationships of "inventory" */
export type ServerInventoryInventoryProductsArgs = {
  distinct_on?: InputMaybe<Array<ServerInventoryProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerInventoryProductOrderBy>>
  where?: InputMaybe<ServerInventoryProductBoolExp>
}

/** columns and relationships of "inventory" */
export type ServerInventoryMovementsArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementOrderBy>>
  where?: InputMaybe<ServerMovementBoolExp>
}

/** columns and relationships of "inventory" */
export type ServerInventoryMovementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementOrderBy>>
  where?: InputMaybe<ServerMovementBoolExp>
}

/** columns and relationships of "inventoryProduct" */
export type ServerInventoryProduct = {
  actualQuantity: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  calculatedQuantity: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  inventory: ServerInventory
  inventoryId: Scalars['Int']
  price: Scalars['numeric']
  /** An object relationship */
  product: ServerProduct
  productId: Scalars['Int']
}

/** order by aggregate values of table "inventoryProduct" */
export type ServerInventoryProductAggregateOrderBy = {
  avg?: InputMaybe<ServerInventoryProductAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerInventoryProductMaxOrderBy>
  min?: InputMaybe<ServerInventoryProductMinOrderBy>
  stddev?: InputMaybe<ServerInventoryProductStddevOrderBy>
  stddev_pop?: InputMaybe<ServerInventoryProductStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerInventoryProductStddevSampOrderBy>
  sum?: InputMaybe<ServerInventoryProductSumOrderBy>
  var_pop?: InputMaybe<ServerInventoryProductVarPopOrderBy>
  var_samp?: InputMaybe<ServerInventoryProductVarSampOrderBy>
  variance?: InputMaybe<ServerInventoryProductVarianceOrderBy>
}

/** input type for inserting array relation for remote table "inventoryProduct" */
export type ServerInventoryProductArrRelInsertInput = {
  data: Array<ServerInventoryProductInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerInventoryProductOnConflict>
}

/** order by avg() on columns of table "inventoryProduct" */
export type ServerInventoryProductAvgOrderBy = {
  actualQuantity?: InputMaybe<ServerOrderBy>
  calculatedQuantity?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "inventoryProduct". All fields are combined with a logical 'AND'. */
export type ServerInventoryProductBoolExp = {
  _and?: InputMaybe<Array<ServerInventoryProductBoolExp>>
  _not?: InputMaybe<ServerInventoryProductBoolExp>
  _or?: InputMaybe<Array<ServerInventoryProductBoolExp>>
  actualQuantity?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  calculatedQuantity?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  inventory?: InputMaybe<ServerInventoryBoolExp>
  inventoryId?: InputMaybe<ServerIntComparisonExp>
  price?: InputMaybe<ServerNumericComparisonExp>
  product?: InputMaybe<ServerProductBoolExp>
  productId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "inventoryProduct" */
export enum ServerInventoryProductConstraint {
  /** unique or primary key constraint on columns "id" */
  InventoryProductPkey = 'inventoryProduct_pkey',
}

/** input type for inserting data into table "inventoryProduct" */
export type ServerInventoryProductInsertInput = {
  actualQuantity?: InputMaybe<Scalars['Int']>
  calculatedQuantity?: InputMaybe<Scalars['Int']>
  inventory?: InputMaybe<ServerInventoryObjRelInsertInput>
  inventoryId?: InputMaybe<Scalars['Int']>
  price?: InputMaybe<Scalars['numeric']>
  product?: InputMaybe<ServerProductObjRelInsertInput>
  productId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "inventoryProduct" */
export type ServerInventoryProductMaxOrderBy = {
  actualQuantity?: InputMaybe<ServerOrderBy>
  calculatedQuantity?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "inventoryProduct" */
export type ServerInventoryProductMinOrderBy = {
  actualQuantity?: InputMaybe<ServerOrderBy>
  calculatedQuantity?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "inventoryProduct" */
export type ServerInventoryProductMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerInventoryProduct>
}

/** on_conflict condition type for table "inventoryProduct" */
export type ServerInventoryProductOnConflict = {
  constraint: ServerInventoryProductConstraint
  update_columns?: Array<ServerInventoryProductUpdateColumn>
  where?: InputMaybe<ServerInventoryProductBoolExp>
}

/** Ordering options when selecting data from "inventoryProduct". */
export type ServerInventoryProductOrderBy = {
  actualQuantity?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  calculatedQuantity?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventory?: InputMaybe<ServerInventoryOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  product?: InputMaybe<ServerProductOrderBy>
  productId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "inventoryProduct" */
export enum ServerInventoryProductSelectColumn {
  /** column name */
  ActualQuantity = 'actualQuantity',
  /** column name */
  CalculatedQuantity = 'calculatedQuantity',
  /** column name */
  Id = 'id',
  /** column name */
  InventoryId = 'inventoryId',
  /** column name */
  Price = 'price',
  /** column name */
  ProductId = 'productId',
}

/** order by stddev() on columns of table "inventoryProduct" */
export type ServerInventoryProductStddevOrderBy = {
  actualQuantity?: InputMaybe<ServerOrderBy>
  calculatedQuantity?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "inventoryProduct" */
export type ServerInventoryProductStddevPopOrderBy = {
  actualQuantity?: InputMaybe<ServerOrderBy>
  calculatedQuantity?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "inventoryProduct" */
export type ServerInventoryProductStddevSampOrderBy = {
  actualQuantity?: InputMaybe<ServerOrderBy>
  calculatedQuantity?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "inventoryProduct" */
export type ServerInventoryProductSumOrderBy = {
  actualQuantity?: InputMaybe<ServerOrderBy>
  calculatedQuantity?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "inventoryProduct" (current role has no relevant permissions) */
export enum ServerInventoryProductUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "inventoryProduct" */
export type ServerInventoryProductVarPopOrderBy = {
  actualQuantity?: InputMaybe<ServerOrderBy>
  calculatedQuantity?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "inventoryProduct" */
export type ServerInventoryProductVarSampOrderBy = {
  actualQuantity?: InputMaybe<ServerOrderBy>
  calculatedQuantity?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "inventoryProduct" */
export type ServerInventoryProductVarianceOrderBy = {
  actualQuantity?: InputMaybe<ServerOrderBy>
  calculatedQuantity?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
}

/** aggregated selection of "inventory" */
export type ServerInventoryAggregate = {
  aggregate: Maybe<ServerInventoryAggregateFields>
  nodes: Array<ServerInventory>
}

/** aggregate fields of "inventory" */
export type ServerInventoryAggregateFields = {
  avg: Maybe<ServerInventoryAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerInventoryMaxFields>
  min: Maybe<ServerInventoryMinFields>
  stddev: Maybe<ServerInventoryStddevFields>
  stddev_pop: Maybe<ServerInventoryStddevPopFields>
  stddev_samp: Maybe<ServerInventoryStddevSampFields>
  sum: Maybe<ServerInventorySumFields>
  var_pop: Maybe<ServerInventoryVarPopFields>
  var_samp: Maybe<ServerInventoryVarSampFields>
  variance: Maybe<ServerInventoryVarianceFields>
}

/** aggregate fields of "inventory" */
export type ServerInventoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerInventorySelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type ServerInventoryAvgFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  storageId: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "inventory". All fields are combined with a logical 'AND'. */
export type ServerInventoryBoolExp = {
  _and?: InputMaybe<Array<ServerInventoryBoolExp>>
  _not?: InputMaybe<ServerInventoryBoolExp>
  _or?: InputMaybe<Array<ServerInventoryBoolExp>>
  author?: InputMaybe<ServerEmployeeBoolExp>
  authorId?: InputMaybe<ServerIntComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  fulfilled?: InputMaybe<ServerBooleanComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  inventoryDate?: InputMaybe<ServerTimestamptzComparisonExp>
  inventoryProducts?: InputMaybe<ServerInventoryProductBoolExp>
  movements?: InputMaybe<ServerMovementBoolExp>
  movements_aggregate?: InputMaybe<ServerMovementAggregateBoolExp>
  number?: InputMaybe<ServerStringComparisonExp>
  storage?: InputMaybe<ServerStorageBoolExp>
  storageId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "inventory" */
export enum ServerInventoryConstraint {
  /** unique or primary key constraint on columns "id" */
  InventoryPkey = 'inventory_pkey',
}

/** input type for inserting data into table "inventory" */
export type ServerInventoryInsertInput = {
  author?: InputMaybe<ServerEmployeeObjRelInsertInput>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  fulfilled?: InputMaybe<Scalars['Boolean']>
  inventoryDate?: InputMaybe<Scalars['timestamptz']>
  inventoryProducts?: InputMaybe<ServerInventoryProductArrRelInsertInput>
  movements?: InputMaybe<ServerMovementArrRelInsertInput>
  number?: InputMaybe<Scalars['String']>
  storage?: InputMaybe<ServerStorageObjRelInsertInput>
  storageId?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type ServerInventoryMaxFields = {
  authorId: Maybe<Scalars['Int']>
  branchId: Maybe<Scalars['Int']>
  createdAt: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
  inventoryDate: Maybe<Scalars['timestamptz']>
  number: Maybe<Scalars['String']>
  storageId: Maybe<Scalars['Int']>
}

/** aggregate min on columns */
export type ServerInventoryMinFields = {
  authorId: Maybe<Scalars['Int']>
  branchId: Maybe<Scalars['Int']>
  createdAt: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
  inventoryDate: Maybe<Scalars['timestamptz']>
  number: Maybe<Scalars['String']>
  storageId: Maybe<Scalars['Int']>
}

/** response of any mutation on the table "inventory" */
export type ServerInventoryMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerInventory>
}

/** input type for inserting object relation for remote table "inventory" */
export type ServerInventoryObjRelInsertInput = {
  data: ServerInventoryInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerInventoryOnConflict>
}

/** on_conflict condition type for table "inventory" */
export type ServerInventoryOnConflict = {
  constraint: ServerInventoryConstraint
  update_columns?: Array<ServerInventoryUpdateColumn>
  where?: InputMaybe<ServerInventoryBoolExp>
}

/** Ordering options when selecting data from "inventory". */
export type ServerInventoryOrderBy = {
  author?: InputMaybe<ServerEmployeeOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  fulfilled?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryDate?: InputMaybe<ServerOrderBy>
  inventoryProducts_aggregate?: InputMaybe<ServerInventoryProductAggregateOrderBy>
  movements_aggregate?: InputMaybe<ServerMovementAggregateOrderBy>
  number?: InputMaybe<ServerOrderBy>
  storage?: InputMaybe<ServerStorageOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: inventory */
export type ServerInventoryPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "inventory" */
export enum ServerInventorySelectColumn {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Fulfilled = 'fulfilled',
  /** column name */
  Id = 'id',
  /** column name */
  InventoryDate = 'inventoryDate',
  /** column name */
  Number = 'number',
  /** column name */
  StorageId = 'storageId',
}

/** input type for updating data in table "inventory" */
export type ServerInventorySetInput = {
  fulfilled?: InputMaybe<Scalars['Boolean']>
}

/** aggregate stddev on columns */
export type ServerInventoryStddevFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  storageId: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type ServerInventoryStddevPopFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  storageId: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type ServerInventoryStddevSampFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  storageId: Maybe<Scalars['Float']>
}

/** aggregate sum on columns */
export type ServerInventorySumFields = {
  authorId: Maybe<Scalars['Int']>
  branchId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  storageId: Maybe<Scalars['Int']>
}

/** update columns of table "inventory" */
export enum ServerInventoryUpdateColumn {
  /** column name */
  Fulfilled = 'fulfilled',
}

export type ServerInventoryUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerInventorySetInput>
  /** filter the rows which have to be updated */
  where: ServerInventoryBoolExp
}

/** aggregate var_pop on columns */
export type ServerInventoryVarPopFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  storageId: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type ServerInventoryVarSampFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  storageId: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type ServerInventoryVarianceFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  storageId: Maybe<Scalars['Float']>
}

export type ServerJsonbCastExp = {
  String?: InputMaybe<ServerStringComparisonExp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type ServerJsonbComparisonExp = {
  _cast?: InputMaybe<ServerJsonbCastExp>
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>
  _eq?: InputMaybe<Scalars['jsonb']>
  _gt?: InputMaybe<Scalars['jsonb']>
  _gte?: InputMaybe<Scalars['jsonb']>
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>
  _in?: InputMaybe<Array<Scalars['jsonb']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['jsonb']>
  _lte?: InputMaybe<Scalars['jsonb']>
  _neq?: InputMaybe<Scalars['jsonb']>
  _nin?: InputMaybe<Array<Scalars['jsonb']>>
}

/** Источник привлечения клиента */
export type ServerLead = {
  /** An object relationship */
  business: ServerBusiness
  /** An array relationship */
  clients: Array<ServerClient>
  /** An aggregate relationship */
  clients_aggregate: ServerClientAggregate
  createdAt: Scalars['timestamptz']
  id: Scalars['Int']
  name: Scalars['String']
}

/** Источник привлечения клиента */
export type ServerLeadClientsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientOrderBy>>
  where?: InputMaybe<ServerClientBoolExp>
}

/** Источник привлечения клиента */
export type ServerLeadClientsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerClientSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientOrderBy>>
  where?: InputMaybe<ServerClientBoolExp>
}

/** Boolean expression to filter rows from the table "lead". All fields are combined with a logical 'AND'. */
export type ServerLeadBoolExp = {
  _and?: InputMaybe<Array<ServerLeadBoolExp>>
  _not?: InputMaybe<ServerLeadBoolExp>
  _or?: InputMaybe<Array<ServerLeadBoolExp>>
  business?: InputMaybe<ServerBusinessBoolExp>
  clients?: InputMaybe<ServerClientBoolExp>
  clients_aggregate?: InputMaybe<ServerClientAggregateBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "lead" */
export enum ServerLeadConstraint {
  /** unique or primary key constraint on columns "id" */
  LeadPkey = 'lead_pkey',
}

/** input type for inserting data into table "lead" */
export type ServerLeadInsertInput = {
  clients?: InputMaybe<ServerClientArrRelInsertInput>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "lead" */
export type ServerLeadMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerLead>
}

/** input type for inserting object relation for remote table "lead" */
export type ServerLeadObjRelInsertInput = {
  data: ServerLeadInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerLeadOnConflict>
}

/** on_conflict condition type for table "lead" */
export type ServerLeadOnConflict = {
  constraint: ServerLeadConstraint
  update_columns?: Array<ServerLeadUpdateColumn>
  where?: InputMaybe<ServerLeadBoolExp>
}

/** Ordering options when selecting data from "lead". */
export type ServerLeadOrderBy = {
  business?: InputMaybe<ServerBusinessOrderBy>
  clients_aggregate?: InputMaybe<ServerClientAggregateOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: lead */
export type ServerLeadPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "lead" */
export enum ServerLeadSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "lead" */
export type ServerLeadSetInput = {
  name?: InputMaybe<Scalars['String']>
}

/** update columns of table "lead" */
export enum ServerLeadUpdateColumn {
  /** column name */
  Name = 'name',
}

export type ServerLeadUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerLeadSetInput>
  /** filter the rows which have to be updated */
  where: ServerLeadBoolExp
}

/** Локации */
export type ServerLocation = {
  archived: Maybe<Scalars['timestamptz']>
  /** An array relationship */
  bookings: Array<ServerBooking>
  /** An aggregate relationship */
  bookings_aggregate: ServerBookingAggregate
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  createdAt: Scalars['timestamptz']
  /** An object relationship */
  defaultForBranch: Maybe<ServerBranch>
  description: Maybe<Scalars['String']>
  groupId: Maybe<Scalars['Int']>
  id: Scalars['Int']
  /** An object relationship */
  locationGroup: Maybe<ServerLocationGroup>
  maxClients: Scalars['Int']
  maxEmployees: Scalars['Int']
  name: Scalars['String']
  /** An array relationship */
  serviceLocations: Array<ServerServiceLocation>
}

/** Локации */
export type ServerLocationBookingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** Локации */
export type ServerLocationBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** Локации */
export type ServerLocationServiceLocationsArgs = {
  distinct_on?: InputMaybe<Array<ServerServiceLocationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServiceLocationOrderBy>>
  where?: InputMaybe<ServerServiceLocationBoolExp>
}

/** columns and relationships of "locationGroup" */
export type ServerLocationGroup = {
  archived: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  createdAt: Scalars['timestamptz']
  description: Maybe<Scalars['String']>
  id: Scalars['Int']
  /** An array relationship */
  locations: Array<ServerLocation>
  name: Scalars['String']
}

/** columns and relationships of "locationGroup" */
export type ServerLocationGroupLocationsArgs = {
  distinct_on?: InputMaybe<Array<ServerLocationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerLocationOrderBy>>
  where?: InputMaybe<ServerLocationBoolExp>
}

/** Boolean expression to filter rows from the table "locationGroup". All fields are combined with a logical 'AND'. */
export type ServerLocationGroupBoolExp = {
  _and?: InputMaybe<Array<ServerLocationGroupBoolExp>>
  _not?: InputMaybe<ServerLocationGroupBoolExp>
  _or?: InputMaybe<Array<ServerLocationGroupBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  locations?: InputMaybe<ServerLocationBoolExp>
  name?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "locationGroup" */
export enum ServerLocationGroupConstraint {
  /** unique or primary key constraint on columns "id" */
  LocationGroupPkey = 'locationGroup_pkey',
}

/** input type for inserting data into table "locationGroup" */
export type ServerLocationGroupInsertInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  locations?: InputMaybe<ServerLocationArrRelInsertInput>
  name?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "locationGroup" */
export type ServerLocationGroupMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerLocationGroup>
}

/** input type for inserting object relation for remote table "locationGroup" */
export type ServerLocationGroupObjRelInsertInput = {
  data: ServerLocationGroupInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerLocationGroupOnConflict>
}

/** on_conflict condition type for table "locationGroup" */
export type ServerLocationGroupOnConflict = {
  constraint: ServerLocationGroupConstraint
  update_columns?: Array<ServerLocationGroupUpdateColumn>
  where?: InputMaybe<ServerLocationGroupBoolExp>
}

/** Ordering options when selecting data from "locationGroup". */
export type ServerLocationGroupOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locations_aggregate?: InputMaybe<ServerLocationAggregateOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: locationGroup */
export type ServerLocationGroupPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "locationGroup" */
export enum ServerLocationGroupSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "locationGroup" */
export type ServerLocationGroupSetInput = {
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

/** update columns of table "locationGroup" */
export enum ServerLocationGroupUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type ServerLocationGroupUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerLocationGroupSetInput>
  /** filter the rows which have to be updated */
  where: ServerLocationGroupBoolExp
}

/** order by aggregate values of table "location" */
export type ServerLocationAggregateOrderBy = {
  avg?: InputMaybe<ServerLocationAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerLocationMaxOrderBy>
  min?: InputMaybe<ServerLocationMinOrderBy>
  stddev?: InputMaybe<ServerLocationStddevOrderBy>
  stddev_pop?: InputMaybe<ServerLocationStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerLocationStddevSampOrderBy>
  sum?: InputMaybe<ServerLocationSumOrderBy>
  var_pop?: InputMaybe<ServerLocationVarPopOrderBy>
  var_samp?: InputMaybe<ServerLocationVarSampOrderBy>
  variance?: InputMaybe<ServerLocationVarianceOrderBy>
}

/** input type for inserting array relation for remote table "location" */
export type ServerLocationArrRelInsertInput = {
  data: Array<ServerLocationInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerLocationOnConflict>
}

/** order by avg() on columns of table "location" */
export type ServerLocationAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxClients?: InputMaybe<ServerOrderBy>
  maxEmployees?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "location". All fields are combined with a logical 'AND'. */
export type ServerLocationBoolExp = {
  _and?: InputMaybe<Array<ServerLocationBoolExp>>
  _not?: InputMaybe<ServerLocationBoolExp>
  _or?: InputMaybe<Array<ServerLocationBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  bookings?: InputMaybe<ServerBookingBoolExp>
  bookings_aggregate?: InputMaybe<ServerBookingAggregateBoolExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  defaultForBranch?: InputMaybe<ServerBranchBoolExp>
  description?: InputMaybe<ServerStringComparisonExp>
  groupId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  locationGroup?: InputMaybe<ServerLocationGroupBoolExp>
  maxClients?: InputMaybe<ServerIntComparisonExp>
  maxEmployees?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  serviceLocations?: InputMaybe<ServerServiceLocationBoolExp>
}

/** unique or primary key constraints on table "location" */
export enum ServerLocationConstraint {
  /** unique or primary key constraint on columns "id" */
  LocationsPkey = 'locations_pkey',
}

/** input type for incrementing numeric columns in table "location" */
export type ServerLocationIncInput = {
  branchId?: InputMaybe<Scalars['Int']>
  groupId?: InputMaybe<Scalars['Int']>
  maxClients?: InputMaybe<Scalars['Int']>
  maxEmployees?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "location" */
export type ServerLocationInsertInput = {
  bookings?: InputMaybe<ServerBookingArrRelInsertInput>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  defaultForBranch?: InputMaybe<ServerBranchObjRelInsertInput>
  description?: InputMaybe<Scalars['String']>
  groupId?: InputMaybe<Scalars['Int']>
  locationGroup?: InputMaybe<ServerLocationGroupObjRelInsertInput>
  maxClients?: InputMaybe<Scalars['Int']>
  maxEmployees?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  serviceLocations?: InputMaybe<ServerServiceLocationArrRelInsertInput>
}

/** order by max() on columns of table "location" */
export type ServerLocationMaxOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxClients?: InputMaybe<ServerOrderBy>
  maxEmployees?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "location" */
export type ServerLocationMinOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxClients?: InputMaybe<ServerOrderBy>
  maxEmployees?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "location" */
export type ServerLocationMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerLocation>
}

/** input type for inserting object relation for remote table "location" */
export type ServerLocationObjRelInsertInput = {
  data: ServerLocationInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerLocationOnConflict>
}

/** on_conflict condition type for table "location" */
export type ServerLocationOnConflict = {
  constraint: ServerLocationConstraint
  update_columns?: Array<ServerLocationUpdateColumn>
  where?: InputMaybe<ServerLocationBoolExp>
}

/** Ordering options when selecting data from "location". */
export type ServerLocationOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  bookings_aggregate?: InputMaybe<ServerBookingAggregateOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  defaultForBranch?: InputMaybe<ServerBranchOrderBy>
  description?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationGroup?: InputMaybe<ServerLocationGroupOrderBy>
  maxClients?: InputMaybe<ServerOrderBy>
  maxEmployees?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  serviceLocations_aggregate?: InputMaybe<ServerServiceLocationAggregateOrderBy>
}

/** primary key columns input for table: location */
export type ServerLocationPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "location" */
export enum ServerLocationSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  MaxClients = 'maxClients',
  /** column name */
  MaxEmployees = 'maxEmployees',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "location" */
export type ServerLocationSetInput = {
  branchId?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  groupId?: InputMaybe<Scalars['Int']>
  maxClients?: InputMaybe<Scalars['Int']>
  maxEmployees?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

/** order by stddev() on columns of table "location" */
export type ServerLocationStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxClients?: InputMaybe<ServerOrderBy>
  maxEmployees?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "location" */
export type ServerLocationStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxClients?: InputMaybe<ServerOrderBy>
  maxEmployees?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "location" */
export type ServerLocationStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxClients?: InputMaybe<ServerOrderBy>
  maxEmployees?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "location" */
export type ServerLocationSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxClients?: InputMaybe<ServerOrderBy>
  maxEmployees?: InputMaybe<ServerOrderBy>
}

/** update columns of table "location" */
export enum ServerLocationUpdateColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  Description = 'description',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  MaxClients = 'maxClients',
  /** column name */
  MaxEmployees = 'maxEmployees',
  /** column name */
  Name = 'name',
}

export type ServerLocationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerLocationIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerLocationSetInput>
  /** filter the rows which have to be updated */
  where: ServerLocationBoolExp
}

/** order by var_pop() on columns of table "location" */
export type ServerLocationVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxClients?: InputMaybe<ServerOrderBy>
  maxEmployees?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "location" */
export type ServerLocationVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxClients?: InputMaybe<ServerOrderBy>
  maxEmployees?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "location" */
export type ServerLocationVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxClients?: InputMaybe<ServerOrderBy>
  maxEmployees?: InputMaybe<ServerOrderBy>
}

/** Перемещения товаров */
export type ServerMovement = {
  /** An object relationship */
  author: ServerEmployee
  authorId: Scalars['Int']
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  createdAt: Scalars['timestamptz']
  description: Maybe<Scalars['String']>
  discount: Maybe<Scalars['numeric']>
  discountType: Maybe<Scalars['Int']>
  /** An object relationship */
  fromStorage: Maybe<ServerStorage>
  fromStorageId: Maybe<Scalars['Int']>
  fulfilled: Scalars['Boolean']
  id: Scalars['Int']
  /** An object relationship */
  inventory: Maybe<ServerInventory>
  inventoryId: Maybe<Scalars['Int']>
  /** An array relationship */
  movementProducts: Array<ServerMovementProduct>
  /** An aggregate relationship */
  movementProducts_aggregate: ServerMovementProductAggregate
  number: Scalars['String']
  /** An object relationship */
  supplier: Maybe<ServerSupplier>
  supplierId: Maybe<Scalars['Int']>
  /** An object relationship */
  toStorage: Maybe<ServerStorage>
  toStorageId: Maybe<Scalars['Int']>
  /** An array relationship */
  transactions: Array<ServerTransaction>
  /** An aggregate relationship */
  transactions_aggregate: ServerTransactionAggregate
  transportCosts: Maybe<Scalars['numeric']>
  type: Scalars['Int']
}

/** Перемещения товаров */
export type ServerMovementMovementProductsArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementProductOrderBy>>
  where?: InputMaybe<ServerMovementProductBoolExp>
}

/** Перемещения товаров */
export type ServerMovementMovementProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementProductOrderBy>>
  where?: InputMaybe<ServerMovementProductBoolExp>
}

/** Перемещения товаров */
export type ServerMovementTransactionsArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

/** Перемещения товаров */
export type ServerMovementTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

/** columns and relationships of "movementProduct" */
export type ServerMovementProduct = {
  /** An object relationship */
  business: ServerBusiness
  discount: Maybe<Scalars['numeric']>
  id: Scalars['Int']
  /** An object relationship */
  movement: ServerMovement
  movementId: Scalars['Int']
  price: Scalars['numeric']
  /** An object relationship */
  product: ServerProduct
  productId: Scalars['Int']
  quantity: Scalars['Int']
}

/** aggregated selection of "movementProduct" */
export type ServerMovementProductAggregate = {
  aggregate: Maybe<ServerMovementProductAggregateFields>
  nodes: Array<ServerMovementProduct>
}

export type ServerMovementProductAggregateBoolExp = {
  count?: InputMaybe<ServerMovementProductAggregateBoolExpCount>
}

export type ServerMovementProductAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServerMovementProductSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerMovementProductBoolExp>
  predicate: ServerIntComparisonExp
}

/** aggregate fields of "movementProduct" */
export type ServerMovementProductAggregateFields = {
  avg: Maybe<ServerMovementProductAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerMovementProductMaxFields>
  min: Maybe<ServerMovementProductMinFields>
  stddev: Maybe<ServerMovementProductStddevFields>
  stddev_pop: Maybe<ServerMovementProductStddevPopFields>
  stddev_samp: Maybe<ServerMovementProductStddevSampFields>
  sum: Maybe<ServerMovementProductSumFields>
  var_pop: Maybe<ServerMovementProductVarPopFields>
  var_samp: Maybe<ServerMovementProductVarSampFields>
  variance: Maybe<ServerMovementProductVarianceFields>
}

/** aggregate fields of "movementProduct" */
export type ServerMovementProductAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerMovementProductSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "movementProduct" */
export type ServerMovementProductAggregateOrderBy = {
  avg?: InputMaybe<ServerMovementProductAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerMovementProductMaxOrderBy>
  min?: InputMaybe<ServerMovementProductMinOrderBy>
  stddev?: InputMaybe<ServerMovementProductStddevOrderBy>
  stddev_pop?: InputMaybe<ServerMovementProductStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerMovementProductStddevSampOrderBy>
  sum?: InputMaybe<ServerMovementProductSumOrderBy>
  var_pop?: InputMaybe<ServerMovementProductVarPopOrderBy>
  var_samp?: InputMaybe<ServerMovementProductVarSampOrderBy>
  variance?: InputMaybe<ServerMovementProductVarianceOrderBy>
}

/** input type for inserting array relation for remote table "movementProduct" */
export type ServerMovementProductArrRelInsertInput = {
  data: Array<ServerMovementProductInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerMovementProductOnConflict>
}

/** aggregate avg on columns */
export type ServerMovementProductAvgFields = {
  discount: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  productId: Maybe<Scalars['Float']>
  quantity: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "movementProduct" */
export type ServerMovementProductAvgOrderBy = {
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "movementProduct". All fields are combined with a logical 'AND'. */
export type ServerMovementProductBoolExp = {
  _and?: InputMaybe<Array<ServerMovementProductBoolExp>>
  _not?: InputMaybe<ServerMovementProductBoolExp>
  _or?: InputMaybe<Array<ServerMovementProductBoolExp>>
  business?: InputMaybe<ServerBusinessBoolExp>
  discount?: InputMaybe<ServerNumericComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  movement?: InputMaybe<ServerMovementBoolExp>
  movementId?: InputMaybe<ServerIntComparisonExp>
  price?: InputMaybe<ServerNumericComparisonExp>
  product?: InputMaybe<ServerProductBoolExp>
  productId?: InputMaybe<ServerIntComparisonExp>
  quantity?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "movementProduct" */
export enum ServerMovementProductConstraint {
  /** unique or primary key constraint on columns "id" */
  WaybillGoodsPkey = 'waybillGoods_pkey',
}

/** input type for inserting data into table "movementProduct" */
export type ServerMovementProductInsertInput = {
  discount?: InputMaybe<Scalars['numeric']>
  movement?: InputMaybe<ServerMovementObjRelInsertInput>
  movementId?: InputMaybe<Scalars['Int']>
  price?: InputMaybe<Scalars['numeric']>
  product?: InputMaybe<ServerProductObjRelInsertInput>
  productId?: InputMaybe<Scalars['Int']>
  quantity?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type ServerMovementProductMaxFields = {
  discount: Maybe<Scalars['numeric']>
  id: Maybe<Scalars['Int']>
  movementId: Maybe<Scalars['Int']>
  price: Maybe<Scalars['numeric']>
  productId: Maybe<Scalars['Int']>
  quantity: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "movementProduct" */
export type ServerMovementProductMaxOrderBy = {
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** aggregate min on columns */
export type ServerMovementProductMinFields = {
  discount: Maybe<Scalars['numeric']>
  id: Maybe<Scalars['Int']>
  movementId: Maybe<Scalars['Int']>
  price: Maybe<Scalars['numeric']>
  productId: Maybe<Scalars['Int']>
  quantity: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "movementProduct" */
export type ServerMovementProductMinOrderBy = {
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "movementProduct" */
export type ServerMovementProductMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerMovementProduct>
}

/** on_conflict condition type for table "movementProduct" */
export type ServerMovementProductOnConflict = {
  constraint: ServerMovementProductConstraint
  update_columns?: Array<ServerMovementProductUpdateColumn>
  where?: InputMaybe<ServerMovementProductBoolExp>
}

/** Ordering options when selecting data from "movementProduct". */
export type ServerMovementProductOrderBy = {
  business?: InputMaybe<ServerBusinessOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movement?: InputMaybe<ServerMovementOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  product?: InputMaybe<ServerProductOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** select columns of table "movementProduct" */
export enum ServerMovementProductSelectColumn {
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  MovementId = 'movementId',
  /** column name */
  Price = 'price',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Quantity = 'quantity',
}

/** aggregate stddev on columns */
export type ServerMovementProductStddevFields = {
  discount: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  productId: Maybe<Scalars['Float']>
  quantity: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "movementProduct" */
export type ServerMovementProductStddevOrderBy = {
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_pop on columns */
export type ServerMovementProductStddevPopFields = {
  discount: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  productId: Maybe<Scalars['Float']>
  quantity: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "movementProduct" */
export type ServerMovementProductStddevPopOrderBy = {
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_samp on columns */
export type ServerMovementProductStddevSampFields = {
  discount: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  productId: Maybe<Scalars['Float']>
  quantity: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "movementProduct" */
export type ServerMovementProductStddevSampOrderBy = {
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** aggregate sum on columns */
export type ServerMovementProductSumFields = {
  discount: Maybe<Scalars['numeric']>
  id: Maybe<Scalars['Int']>
  movementId: Maybe<Scalars['Int']>
  price: Maybe<Scalars['numeric']>
  productId: Maybe<Scalars['Int']>
  quantity: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "movementProduct" */
export type ServerMovementProductSumOrderBy = {
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "movementProduct" (current role has no relevant permissions) */
export enum ServerMovementProductUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** aggregate var_pop on columns */
export type ServerMovementProductVarPopFields = {
  discount: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  productId: Maybe<Scalars['Float']>
  quantity: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "movementProduct" */
export type ServerMovementProductVarPopOrderBy = {
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** aggregate var_samp on columns */
export type ServerMovementProductVarSampFields = {
  discount: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  productId: Maybe<Scalars['Float']>
  quantity: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "movementProduct" */
export type ServerMovementProductVarSampOrderBy = {
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** aggregate variance on columns */
export type ServerMovementProductVarianceFields = {
  discount: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  productId: Maybe<Scalars['Float']>
  quantity: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "movementProduct" */
export type ServerMovementProductVarianceOrderBy = {
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
}

/** aggregated selection of "movement" */
export type ServerMovementAggregate = {
  aggregate: Maybe<ServerMovementAggregateFields>
  nodes: Array<ServerMovement>
}

export type ServerMovementAggregateBoolExp = {
  bool_and?: InputMaybe<ServerMovementAggregateBoolExpBoolAnd>
  bool_or?: InputMaybe<ServerMovementAggregateBoolExpBoolOr>
  count?: InputMaybe<ServerMovementAggregateBoolExpCount>
}

export type ServerMovementAggregateBoolExpBoolAnd = {
  arguments: ServerMovementSelectColumnMovementAggregateBoolExpBoolAndArgumentsColumns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerMovementBoolExp>
  predicate: ServerBooleanComparisonExp
}

export type ServerMovementAggregateBoolExpBoolOr = {
  arguments: ServerMovementSelectColumnMovementAggregateBoolExpBoolOrArgumentsColumns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerMovementBoolExp>
  predicate: ServerBooleanComparisonExp
}

export type ServerMovementAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServerMovementSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerMovementBoolExp>
  predicate: ServerIntComparisonExp
}

/** aggregate fields of "movement" */
export type ServerMovementAggregateFields = {
  avg: Maybe<ServerMovementAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerMovementMaxFields>
  min: Maybe<ServerMovementMinFields>
  stddev: Maybe<ServerMovementStddevFields>
  stddev_pop: Maybe<ServerMovementStddevPopFields>
  stddev_samp: Maybe<ServerMovementStddevSampFields>
  sum: Maybe<ServerMovementSumFields>
  var_pop: Maybe<ServerMovementVarPopFields>
  var_samp: Maybe<ServerMovementVarSampFields>
  variance: Maybe<ServerMovementVarianceFields>
}

/** aggregate fields of "movement" */
export type ServerMovementAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerMovementSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "movement" */
export type ServerMovementAggregateOrderBy = {
  avg?: InputMaybe<ServerMovementAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerMovementMaxOrderBy>
  min?: InputMaybe<ServerMovementMinOrderBy>
  stddev?: InputMaybe<ServerMovementStddevOrderBy>
  stddev_pop?: InputMaybe<ServerMovementStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerMovementStddevSampOrderBy>
  sum?: InputMaybe<ServerMovementSumOrderBy>
  var_pop?: InputMaybe<ServerMovementVarPopOrderBy>
  var_samp?: InputMaybe<ServerMovementVarSampOrderBy>
  variance?: InputMaybe<ServerMovementVarianceOrderBy>
}

/** input type for inserting array relation for remote table "movement" */
export type ServerMovementArrRelInsertInput = {
  data: Array<ServerMovementInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerMovementOnConflict>
}

/** aggregate avg on columns */
export type ServerMovementAvgFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  discount: Maybe<Scalars['Float']>
  discountType: Maybe<Scalars['Float']>
  fromStorageId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  inventoryId: Maybe<Scalars['Float']>
  supplierId: Maybe<Scalars['Float']>
  toStorageId: Maybe<Scalars['Float']>
  transportCosts: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "movement" */
export type ServerMovementAvgOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  discountType?: InputMaybe<ServerOrderBy>
  fromStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
  toStorageId?: InputMaybe<ServerOrderBy>
  transportCosts?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "movement". All fields are combined with a logical 'AND'. */
export type ServerMovementBoolExp = {
  _and?: InputMaybe<Array<ServerMovementBoolExp>>
  _not?: InputMaybe<ServerMovementBoolExp>
  _or?: InputMaybe<Array<ServerMovementBoolExp>>
  author?: InputMaybe<ServerEmployeeBoolExp>
  authorId?: InputMaybe<ServerIntComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  discount?: InputMaybe<ServerNumericComparisonExp>
  discountType?: InputMaybe<ServerIntComparisonExp>
  fromStorage?: InputMaybe<ServerStorageBoolExp>
  fromStorageId?: InputMaybe<ServerIntComparisonExp>
  fulfilled?: InputMaybe<ServerBooleanComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  inventory?: InputMaybe<ServerInventoryBoolExp>
  inventoryId?: InputMaybe<ServerIntComparisonExp>
  movementProducts?: InputMaybe<ServerMovementProductBoolExp>
  movementProducts_aggregate?: InputMaybe<ServerMovementProductAggregateBoolExp>
  number?: InputMaybe<ServerStringComparisonExp>
  supplier?: InputMaybe<ServerSupplierBoolExp>
  supplierId?: InputMaybe<ServerIntComparisonExp>
  toStorage?: InputMaybe<ServerStorageBoolExp>
  toStorageId?: InputMaybe<ServerIntComparisonExp>
  transactions?: InputMaybe<ServerTransactionBoolExp>
  transactions_aggregate?: InputMaybe<ServerTransactionAggregateBoolExp>
  transportCosts?: InputMaybe<ServerNumericComparisonExp>
  type?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "movement" */
export enum ServerMovementConstraint {
  /** unique or primary key constraint on columns "id" */
  MovementPkey = 'movement_pkey',
}

/** input type for inserting data into table "movement" */
export type ServerMovementInsertInput = {
  author?: InputMaybe<ServerEmployeeObjRelInsertInput>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  discount?: InputMaybe<Scalars['numeric']>
  discountType?: InputMaybe<Scalars['Int']>
  fromStorage?: InputMaybe<ServerStorageObjRelInsertInput>
  fromStorageId?: InputMaybe<Scalars['Int']>
  fulfilled?: InputMaybe<Scalars['Boolean']>
  inventory?: InputMaybe<ServerInventoryObjRelInsertInput>
  inventoryId?: InputMaybe<Scalars['Int']>
  movementProducts?: InputMaybe<ServerMovementProductArrRelInsertInput>
  number?: InputMaybe<Scalars['String']>
  supplier?: InputMaybe<ServerSupplierObjRelInsertInput>
  supplierId?: InputMaybe<Scalars['Int']>
  toStorage?: InputMaybe<ServerStorageObjRelInsertInput>
  toStorageId?: InputMaybe<Scalars['Int']>
  transactions?: InputMaybe<ServerTransactionArrRelInsertInput>
  transportCosts?: InputMaybe<Scalars['numeric']>
  type?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type ServerMovementMaxFields = {
  authorId: Maybe<Scalars['Int']>
  branchId: Maybe<Scalars['Int']>
  createdAt: Maybe<Scalars['timestamptz']>
  description: Maybe<Scalars['String']>
  discount: Maybe<Scalars['numeric']>
  discountType: Maybe<Scalars['Int']>
  fromStorageId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  inventoryId: Maybe<Scalars['Int']>
  number: Maybe<Scalars['String']>
  supplierId: Maybe<Scalars['Int']>
  toStorageId: Maybe<Scalars['Int']>
  transportCosts: Maybe<Scalars['numeric']>
  type: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "movement" */
export type ServerMovementMaxOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  discountType?: InputMaybe<ServerOrderBy>
  fromStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  number?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
  toStorageId?: InputMaybe<ServerOrderBy>
  transportCosts?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** aggregate min on columns */
export type ServerMovementMinFields = {
  authorId: Maybe<Scalars['Int']>
  branchId: Maybe<Scalars['Int']>
  createdAt: Maybe<Scalars['timestamptz']>
  description: Maybe<Scalars['String']>
  discount: Maybe<Scalars['numeric']>
  discountType: Maybe<Scalars['Int']>
  fromStorageId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  inventoryId: Maybe<Scalars['Int']>
  number: Maybe<Scalars['String']>
  supplierId: Maybe<Scalars['Int']>
  toStorageId: Maybe<Scalars['Int']>
  transportCosts: Maybe<Scalars['numeric']>
  type: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "movement" */
export type ServerMovementMinOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  discountType?: InputMaybe<ServerOrderBy>
  fromStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  number?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
  toStorageId?: InputMaybe<ServerOrderBy>
  transportCosts?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "movement" */
export type ServerMovementMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerMovement>
}

/** input type for inserting object relation for remote table "movement" */
export type ServerMovementObjRelInsertInput = {
  data: ServerMovementInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerMovementOnConflict>
}

/** on_conflict condition type for table "movement" */
export type ServerMovementOnConflict = {
  constraint: ServerMovementConstraint
  update_columns?: Array<ServerMovementUpdateColumn>
  where?: InputMaybe<ServerMovementBoolExp>
}

/** Ordering options when selecting data from "movement". */
export type ServerMovementOrderBy = {
  author?: InputMaybe<ServerEmployeeOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  discountType?: InputMaybe<ServerOrderBy>
  fromStorage?: InputMaybe<ServerStorageOrderBy>
  fromStorageId?: InputMaybe<ServerOrderBy>
  fulfilled?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventory?: InputMaybe<ServerInventoryOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  movementProducts_aggregate?: InputMaybe<ServerMovementProductAggregateOrderBy>
  number?: InputMaybe<ServerOrderBy>
  supplier?: InputMaybe<ServerSupplierOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
  toStorage?: InputMaybe<ServerStorageOrderBy>
  toStorageId?: InputMaybe<ServerOrderBy>
  transactions_aggregate?: InputMaybe<ServerTransactionAggregateOrderBy>
  transportCosts?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: movement */
export type ServerMovementPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "movement" */
export enum ServerMovementSelectColumn {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  DiscountType = 'discountType',
  /** column name */
  FromStorageId = 'fromStorageId',
  /** column name */
  Fulfilled = 'fulfilled',
  /** column name */
  Id = 'id',
  /** column name */
  InventoryId = 'inventoryId',
  /** column name */
  Number = 'number',
  /** column name */
  SupplierId = 'supplierId',
  /** column name */
  ToStorageId = 'toStorageId',
  /** column name */
  TransportCosts = 'transportCosts',
  /** column name */
  Type = 'type',
}

/** select "movement_aggregate_bool_exp_bool_and_arguments_columns" columns of table "movement" */
export enum ServerMovementSelectColumnMovementAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Fulfilled = 'fulfilled',
}

/** select "movement_aggregate_bool_exp_bool_or_arguments_columns" columns of table "movement" */
export enum ServerMovementSelectColumnMovementAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Fulfilled = 'fulfilled',
}

/** input type for updating data in table "movement" */
export type ServerMovementSetInput = {
  fulfilled?: InputMaybe<Scalars['Boolean']>
}

/** aggregate stddev on columns */
export type ServerMovementStddevFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  discount: Maybe<Scalars['Float']>
  discountType: Maybe<Scalars['Float']>
  fromStorageId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  inventoryId: Maybe<Scalars['Float']>
  supplierId: Maybe<Scalars['Float']>
  toStorageId: Maybe<Scalars['Float']>
  transportCosts: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "movement" */
export type ServerMovementStddevOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  discountType?: InputMaybe<ServerOrderBy>
  fromStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
  toStorageId?: InputMaybe<ServerOrderBy>
  transportCosts?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_pop on columns */
export type ServerMovementStddevPopFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  discount: Maybe<Scalars['Float']>
  discountType: Maybe<Scalars['Float']>
  fromStorageId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  inventoryId: Maybe<Scalars['Float']>
  supplierId: Maybe<Scalars['Float']>
  toStorageId: Maybe<Scalars['Float']>
  transportCosts: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "movement" */
export type ServerMovementStddevPopOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  discountType?: InputMaybe<ServerOrderBy>
  fromStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
  toStorageId?: InputMaybe<ServerOrderBy>
  transportCosts?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_samp on columns */
export type ServerMovementStddevSampFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  discount: Maybe<Scalars['Float']>
  discountType: Maybe<Scalars['Float']>
  fromStorageId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  inventoryId: Maybe<Scalars['Float']>
  supplierId: Maybe<Scalars['Float']>
  toStorageId: Maybe<Scalars['Float']>
  transportCosts: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "movement" */
export type ServerMovementStddevSampOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  discountType?: InputMaybe<ServerOrderBy>
  fromStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
  toStorageId?: InputMaybe<ServerOrderBy>
  transportCosts?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** aggregate sum on columns */
export type ServerMovementSumFields = {
  authorId: Maybe<Scalars['Int']>
  branchId: Maybe<Scalars['Int']>
  discount: Maybe<Scalars['numeric']>
  discountType: Maybe<Scalars['Int']>
  fromStorageId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  inventoryId: Maybe<Scalars['Int']>
  supplierId: Maybe<Scalars['Int']>
  toStorageId: Maybe<Scalars['Int']>
  transportCosts: Maybe<Scalars['numeric']>
  type: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "movement" */
export type ServerMovementSumOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  discountType?: InputMaybe<ServerOrderBy>
  fromStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
  toStorageId?: InputMaybe<ServerOrderBy>
  transportCosts?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** update columns of table "movement" */
export enum ServerMovementUpdateColumn {
  /** column name */
  Fulfilled = 'fulfilled',
}

export type ServerMovementUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerMovementSetInput>
  /** filter the rows which have to be updated */
  where: ServerMovementBoolExp
}

/** aggregate var_pop on columns */
export type ServerMovementVarPopFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  discount: Maybe<Scalars['Float']>
  discountType: Maybe<Scalars['Float']>
  fromStorageId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  inventoryId: Maybe<Scalars['Float']>
  supplierId: Maybe<Scalars['Float']>
  toStorageId: Maybe<Scalars['Float']>
  transportCosts: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "movement" */
export type ServerMovementVarPopOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  discountType?: InputMaybe<ServerOrderBy>
  fromStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
  toStorageId?: InputMaybe<ServerOrderBy>
  transportCosts?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** aggregate var_samp on columns */
export type ServerMovementVarSampFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  discount: Maybe<Scalars['Float']>
  discountType: Maybe<Scalars['Float']>
  fromStorageId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  inventoryId: Maybe<Scalars['Float']>
  supplierId: Maybe<Scalars['Float']>
  toStorageId: Maybe<Scalars['Float']>
  transportCosts: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "movement" */
export type ServerMovementVarSampOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  discountType?: InputMaybe<ServerOrderBy>
  fromStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
  toStorageId?: InputMaybe<ServerOrderBy>
  transportCosts?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** aggregate variance on columns */
export type ServerMovementVarianceFields = {
  authorId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  discount: Maybe<Scalars['Float']>
  discountType: Maybe<Scalars['Float']>
  fromStorageId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  inventoryId: Maybe<Scalars['Float']>
  supplierId: Maybe<Scalars['Float']>
  toStorageId: Maybe<Scalars['Float']>
  transportCosts: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "movement" */
export type ServerMovementVarianceOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  discountType?: InputMaybe<ServerOrderBy>
  fromStorageId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  inventoryId?: InputMaybe<ServerOrderBy>
  supplierId?: InputMaybe<ServerOrderBy>
  toStorageId?: InputMaybe<ServerOrderBy>
  transportCosts?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** mutation root */
export type ServerMutationRoot = {
  activateGiftCard: Maybe<ServerCloudFunctionResult>
  anonCreateBooking: ServerCloudFunctionResult
  archiveEmployee: Maybe<ServerCloudFunctionResult>
  archiveLocation: Maybe<ServerCloudFunctionResult>
  archiveLocationGroup: Maybe<ServerCloudFunctionResult>
  archiveService: Maybe<ServerCloudFunctionResult>
  archiveServiceGroup: Maybe<ServerCloudFunctionResult>
  bootstrapCalendar: Maybe<ServerSetCurrentBusinessResult>
  /** Import products/product groups between branches of the same business */
  branchImportProducts: Maybe<ServerSuccessOutput>
  /** Import services/service groups between branches of the same business */
  branchImportServices: Maybe<ServerSuccessOutput>
  checkPromoCode: Maybe<ServerCheckPromoCodeResult>
  checkboxCashierSignin: Maybe<ServerCloudFunctionResult>
  checkboxCashierSignout: Maybe<ServerCloudFunctionResult>
  checkboxCloseShift: Maybe<ServerCloudFunctionResult>
  checkboxCreateBulkReceipts: Maybe<ServerCheckboxCreateBulkReceiptsResult>
  checkboxCreateReceipt: Maybe<ServerCheckboxCreateReceiptResult>
  /** Створення сервісного чеку внесення або винесення коштів. Для чеку сума винесення повинна бути вказана зі знаком мінус, а для внесення зі знаком плюс. */
  checkboxCreateServiceReceipt: Maybe<ServerCheckboxCreateReceiptResult>
  checkboxOpenShift: Maybe<ServerCloudFunctionResult>
  /** Create a booking (as client, can be extended in future) */
  createBooking: Maybe<ServerSuccessOutput>
  /** Create a branch */
  createBranch: Maybe<ServerSuccessOutput>
  createBusiness: Maybe<ServerCreateBusinessResult>
  createNewClient: Maybe<ServerCloudFunctionResult>
  /** delete single row from the table: "accountEmployeeGroup" */
  deleteAccountEmployeeGroupById: Maybe<ServerAccountEmployeeGroup>
  /** delete data from the table: "accountEmployeeGroup" */
  deleteAccountEmployeeGroups: Maybe<ServerAccountEmployeeGroupMutationResponse>
  /** delete single row from the table: "bookingClient" */
  deleteBookingClientById: Maybe<ServerBookingClient>
  /** delete data from the table: "bookingClient" */
  deleteBookingClients: Maybe<ServerBookingClientMutationResponse>
  /** delete single row from the table: "bookingProduct" */
  deleteBookingProductById: Maybe<ServerBookingProduct>
  /** delete data from the table: "bookingProduct" */
  deleteBookingProducts: Maybe<ServerBookingProductMutationResponse>
  /** delete single row from the table: "bookingService" */
  deleteBookingServiceById: Maybe<ServerBookingService>
  /** delete data from the table: "bookingService" */
  deleteBookingServices: Maybe<ServerBookingServiceMutationResponse>
  /** delete single row from the table: "card" */
  deleteCardById: Maybe<ServerCard>
  /** delete single row from the table: "cardTemplateService" */
  deleteCardTemplateServiceById: Maybe<ServerCardTemplateService>
  /** delete data from the table: "cardTemplateService" */
  deleteCardTemplateServices: Maybe<ServerCardTemplateServiceMutationResponse>
  /** delete data from the table: "card" */
  deleteCards: Maybe<ServerCardMutationResponse>
  deleteClient: Maybe<ServerCloudFunctionResult>
  /** delete data from the table: "clientBranchArchived" */
  deleteClientBranchArchived: Maybe<ServerClientBranchArchivedMutationResponse>
  /** delete single row from the table: "clientBranchArchived" */
  deleteClientBranchArchivedById: Maybe<ServerClientBranchArchived>
  /** delete single row from the table: "clientRelation" */
  deleteClientRelationById: Maybe<ServerClientRelation>
  /** delete data from the table: "clientRelation" */
  deleteClientRelations: Maybe<ServerClientRelationMutationResponse>
  /** delete single row from the table: "clientTag" */
  deleteClientTagById: Maybe<ServerClientTag>
  /** delete data from the table: "clientTag" */
  deleteClientTags: Maybe<ServerClientTagMutationResponse>
  /** delete single row from the table: "dynamicScheduleDate" */
  deleteDynamicScheduleDateById: Maybe<ServerDynamicScheduleDate>
  /** delete data from the table: "dynamicScheduleDate" */
  deleteDynamicScheduleDates: Maybe<ServerDynamicScheduleDateMutationResponse>
  /** delete data from the table: "expaneInfoCategory" */
  deleteExpaneInfoCategories: Maybe<ServerExpaneInfoCategoryMutationResponse>
  /** delete single row from the table: "expaneInfoCategory" */
  deleteExpaneInfoCategoryById: Maybe<ServerExpaneInfoCategory>
  deleteFile: Maybe<ServerDeleteFileResult>
  /** delete single row from the table: "inventoryProduct" */
  deleteInventoryProductById: Maybe<ServerInventoryProduct>
  /** delete data from the table: "inventoryProduct" */
  deleteInventoryProducts: Maybe<ServerInventoryProductMutationResponse>
  /** delete single row from the table: "onlineBookingURL" */
  deleteOnlineBookingURLById: Maybe<ServerOnlineBookingUrl>
  /** delete data from the table: "onlineBookingURL" */
  deleteOnlineBookingURLs: Maybe<ServerOnlineBookingUrlMutationResponse>
  /** delete single row from the table: "productGroupEmployeeGroup" */
  deleteProductGroupEmployeeGroupById: Maybe<ServerProductGroupEmployeeGroup>
  /** delete data from the table: "productGroupEmployeeGroup" */
  deleteProductGroupEmployeeGroups: Maybe<ServerProductGroupEmployeeGroupMutationResponse>
  /** delete single row from the table: "rolePermission" */
  deleteRolePermissionById: Maybe<ServerRolePermission>
  /** delete data from the table: "rolePermission" */
  deleteRolePermissions: Maybe<ServerRolePermissionMutationResponse>
  /** delete single row from the table: "serviceEmployee" */
  deleteServiceEmployeeById: Maybe<ServerServiceEmployee>
  /** delete data from the table: "serviceEmployee" */
  deleteServiceEmployees: Maybe<ServerServiceEmployeeMutationResponse>
  /** delete single row from the table: "serviceLocation" */
  deleteServiceLocationById: Maybe<ServerServiceLocation>
  /** delete data from the table: "serviceLocation" */
  deleteServiceLocations: Maybe<ServerServiceLocationMutationResponse>
  /** delete single row from the table: "servicePermission" */
  deleteServicePermissionById: Maybe<ServerServicePermission>
  /** delete data from the table: "servicePermission" */
  deleteServicePermissions: Maybe<ServerServicePermissionMutationResponse>
  /** delete single row from the table: "serviceProduct" */
  deleteServiceProductById: Maybe<ServerServiceProduct>
  /** delete data from the table: "serviceProduct" */
  deleteServiceProducts: Maybe<ServerServiceProductMutationResponse>
  /** delete single row from the table: "storageEmployeeGroup" */
  deleteStorageEmployeeGroupById: Maybe<ServerStorageEmployeeGroup>
  /** delete data from the table: "storageEmployeeGroup" */
  deleteStorageEmployeeGroups: Maybe<ServerStorageEmployeeGroupMutationResponse>
  /** delete single row from the table: "super" */
  deleteSuperById: Maybe<ServerSuper>
  /** delete data from the table: "super" */
  deleteSupers: Maybe<ServerSuperMutationResponse>
  /** delete single row from the table: "timeOffReasonEmployeeGroup" */
  deleteTimeOffReasonEmployeeGroupById: Maybe<ServerTimeOffReasonEmployeeGroup>
  /** delete data from the table: "timeOffReasonEmployeeGroup" */
  deleteTimeOffReasonEmployeeGroups: Maybe<ServerTimeOffReasonEmployeeGroupMutationResponse>
  /** delete single row from the table: "waitingListEmployee" */
  deleteWaitingListEmployee: Maybe<ServerWaitingListEmployee>
  /** delete data from the table: "waitingListEmployee" */
  deleteWaitingListsEmployees: Maybe<ServerWaitingListEmployeeMutationResponse>
  extendBilling: Maybe<ServerExtendBillingResult>
  importData: Maybe<ServerCloudFunctionResult>
  /** insert a single row into the table: "movementProduct" */
  inserMovementProduct: Maybe<ServerMovementProduct>
  /** insert a single row into the table: "account" */
  insertAccount: Maybe<ServerAccount>
  /** insert a single row into the table: "accountEmployeeGroup" */
  insertAccountEmployeeGroup: Maybe<ServerAccountEmployeeGroup>
  /** insert data into the table: "accountEmployeeGroup" */
  insertAccountEmployeeGroups: Maybe<ServerAccountEmployeeGroupMutationResponse>
  /** insert data into the table: "account" */
  insertAccounts: Maybe<ServerAccountMutationResponse>
  /** insert a single row into the table: "address" */
  insertAddress: Maybe<ServerAddress>
  /** insert data into the table: "address" */
  insertAddresses: Maybe<ServerAddressMutationResponse>
  /** insert a single row into the table: "bankDetails" */
  insertBankDetails: Maybe<ServerBankDetails>
  /** insert data into the table: "bankDetails" */
  insertBanksDetails: Maybe<ServerBankDetailsMutationResponse>
  /** insert a single row into the table: "booking" */
  insertBooking: Maybe<ServerBooking>
  /** insert a single row into the table: "bookingClient" */
  insertBookingClientById: Maybe<ServerBookingClient>
  /** insert data into the table: "bookingClient" */
  insertBookingClients: Maybe<ServerBookingClientMutationResponse>
  /** insert a single row into the table: "bookingProduct" */
  insertBookingProduct: Maybe<ServerBookingProduct>
  /** insert data into the table: "bookingProduct" */
  insertBookingProducts: Maybe<ServerBookingProductMutationResponse>
  /** insert a single row into the table: "bookingService" */
  insertBookingService: Maybe<ServerBookingService>
  /** insert data into the table: "bookingService" */
  insertBookingServices: Maybe<ServerBookingServiceMutationResponse>
  /** insert data into the table: "booking" */
  insertBookings: Maybe<ServerBookingMutationResponse>
  /** insert a single row into the table: "branch" */
  insertBranch: Maybe<ServerBranch>
  /** insert data into the table: "branch" */
  insertBranches: Maybe<ServerBranchMutationResponse>
  /** insert a single row into the table: "businessPromoCode" */
  insertBusinessPromoCodeById: Maybe<ServerBusinessPromoCode>
  /** insert data into the table: "businessPromoCode" */
  insertBusinessPromoCodes: Maybe<ServerBusinessPromoCodeMutationResponse>
  /** insert a single row into the table: "card" */
  insertCard: Maybe<ServerCard>
  /** insert a single row into the table: "cardTemplate" */
  insertCardTemplate: Maybe<ServerCardTemplate>
  /** insert a single row into the table: "cardTemplateService" */
  insertCardTemplateService: Maybe<ServerCardTemplateService>
  /** insert data into the table: "cardTemplateService" */
  insertCardTemplateServices: Maybe<ServerCardTemplateServiceMutationResponse>
  /** insert data into the table: "cardTemplate" */
  insertCardTemplates: Maybe<ServerCardTemplateMutationResponse>
  /** insert data into the table: "card" */
  insertCards: Maybe<ServerCardMutationResponse>
  /** insert a single row into the table: "client" */
  insertClient: Maybe<ServerClient>
  /** insert a single row into the table: "clientBranchArchived" */
  insertClientBranchArchived: Maybe<ServerClientBranchArchived>
  /** insert a single row into the table: "clientDocument" */
  insertClientDocument: Maybe<ServerClientDocument>
  /** insert data into the table: "clientDocument" */
  insertClientDocuments: Maybe<ServerClientDocumentMutationResponse>
  /** insert a single row into the table: "clientNote" */
  insertClientNote: Maybe<ServerClientNote>
  /** insert data into the table: "clientNote" */
  insertClientNotes: Maybe<ServerClientNoteMutationResponse>
  /** insert a single row into the table: "clientRelation" */
  insertClientRelation: Maybe<ServerClientRelation>
  /** insert data into the table: "clientRelation" */
  insertClientRelations: Maybe<ServerClientRelationMutationResponse>
  /** insert a single row into the table: "clientSettings" */
  insertClientSettings: Maybe<ServerClientSettings>
  /** insert a single row into the table: "clientTag" */
  insertClientTag: Maybe<ServerClientTag>
  /** insert data into the table: "clientTag" */
  insertClientTags: Maybe<ServerClientTagMutationResponse>
  /** insert data into the table: "client" */
  insertClients: Maybe<ServerClientMutationResponse>
  /** insert data into the table: "clientSettings" */
  insertClientsSettings: Maybe<ServerClientSettingsMutationResponse>
  /** insert a single row into the table: "dynamicScheduleDate" */
  insertDynamicScheduleDate: Maybe<ServerDynamicScheduleDate>
  /** insert data into the table: "dynamicScheduleDate" */
  insertDynamicScheduleDates: Maybe<ServerDynamicScheduleDateMutationResponse>
  /** insert a single row into the table: "employee" */
  insertEmployee: Maybe<ServerEmployee>
  /** insert a single row into the table: "employeeGroup" */
  insertEmployeeGroup: Maybe<ServerEmployeeGroup>
  /** insert data into the table: "employeeGroup" */
  insertEmployeeGroups: Maybe<ServerEmployeeGroupMutationResponse>
  /** insert a single row into the table: "employeeSchedule" */
  insertEmployeeSchedule: Maybe<ServerEmployeeSchedule>
  /** insert data into the table: "employeeSchedule" */
  insertEmployeeSchedules: Maybe<ServerEmployeeScheduleMutationResponse>
  /** insert a single row into the table: "employeeSettings" */
  insertEmployeeSettings: Maybe<ServerEmployeeSettings>
  /** insert data into the table: "employee" */
  insertEmployees: Maybe<ServerEmployeeMutationResponse>
  /** insert data into the table: "expaneInfoCategory" */
  insertExpaneInfoCategories: Maybe<ServerExpaneInfoCategoryMutationResponse>
  /** insert a single row into the table: "expensesReason" */
  insertExpensesReason: Maybe<ServerExpensesReason>
  /** insert data into the table: "expensesReason" */
  insertExpensesReasons: Maybe<ServerExpensesReasonMutationResponse>
  /** insert a single row into the table: "human" */
  insertHuman: Maybe<ServerHuman>
  /** insert data into the table: "human" */
  insertHumans: Maybe<ServerHumanMutationResponse>
  /** insert data into the table: "inventory" */
  insertInventories: Maybe<ServerInventoryMutationResponse>
  /** insert a single row into the table: "inventory" */
  insertInventory: Maybe<ServerInventory>
  /** insert a single row into the table: "inventoryProduct" */
  insertInventoryProduct: Maybe<ServerInventoryProduct>
  /** insert data into the table: "inventoryProduct" */
  insertInventoryProducts: Maybe<ServerInventoryProductMutationResponse>
  /** insert a single row into the table: "lead" */
  insertLead: Maybe<ServerLead>
  /** insert data into the table: "lead" */
  insertLeads: Maybe<ServerLeadMutationResponse>
  /** insert a single row into the table: "location" */
  insertLocation: Maybe<ServerLocation>
  /** insert a single row into the table: "locationGroup" */
  insertLocationGroup: Maybe<ServerLocationGroup>
  /** insert data into the table: "location" */
  insertLocations: Maybe<ServerLocationMutationResponse>
  /** insert data into the table: "clientBranchArchived" */
  insertManyClientBranchArchived: Maybe<ServerClientBranchArchivedMutationResponse>
  /** insert a single row into the table: "movement" */
  insertMovement: Maybe<ServerMovement>
  /** insert data into the table: "movementProduct" */
  insertMovementProducts: Maybe<ServerMovementProductMutationResponse>
  /** insert data into the table: "movement" */
  insertMovements: Maybe<ServerMovementMutationResponse>
  /** insert data into the table: "employeeSettings" */
  insertMultipleEmployeeSettings: Maybe<ServerEmployeeSettingsMutationResponse>
  /** insert data into the table: "locationGroup" */
  insertMultipleLocationGroups: Maybe<ServerLocationGroupMutationResponse>
  /** insert data into the table: "salaryIssue" */
  insertMultipleSalaryIssues: Maybe<ServerSalaryIssueMutationResponse>
  /** insert data into the table: "salarySetting" */
  insertMultipleSalarySetting: Maybe<ServerSalarySettingMutationResponse>
  /** insert data into the table: "salarySettingService" */
  insertMultipleSalarySettingService: Maybe<ServerSalarySettingServiceMutationResponse>
  /** insert data into the table: "unitedBooking" */
  insertMultiplyUnitedBookings: Maybe<ServerUnitedBookingMutationResponse>
  /** insert a single row into the table: "notification" */
  insertNotification: Maybe<ServerNotification>
  /** insert data into the table: "notification" */
  insertNotifications: Maybe<ServerNotificationMutationResponse>
  /** insert a single row into the table: "expaneInfoCategory" */
  insertOneExpaneInfoCategory: Maybe<ServerExpaneInfoCategory>
  /** insert a single row into the table: "onlineBookingURL" */
  insertOneOnlineBookingURL: Maybe<ServerOnlineBookingUrl>
  /** insert a single row into the table: "promoCode" */
  insertOnePromoCode: Maybe<ServerPromoCode>
  /** insert a single row into the table: "review" */
  insertOneReview: Maybe<ServerReview>
  /** insert a single row into the table: "super" */
  insertOneSuper: Maybe<ServerSuper>
  /** insert data into the table: "onlineBookingURL" */
  insertOnlineBookingURLs: Maybe<ServerOnlineBookingUrlMutationResponse>
  /** insert a single row into the table: "product" */
  insertProduct: Maybe<ServerProduct>
  /** insert a single row into the table: "productGroup" */
  insertProductGroup: Maybe<ServerProductGroup>
  /** insert a single row into the table: "productGroupEmployeeGroup" */
  insertProductGroupEmployeeGroup: Maybe<ServerProductGroupEmployeeGroup>
  /** insert data into the table: "productGroupEmployeeGroup" */
  insertProductGroupEmployeeGroups: Maybe<ServerProductGroupEmployeeGroupMutationResponse>
  /** insert data into the table: "productGroup" */
  insertProductGroups: Maybe<ServerProductGroupMutationResponse>
  /** insert data into the table: "product" */
  insertProducts: Maybe<ServerProductMutationResponse>
  /** insert data into the table: "promoCode" */
  insertPromoCodes: Maybe<ServerPromoCodeMutationResponse>
  /** insert a single row into the table: "recurringBooking" */
  insertRecurringBooking: Maybe<ServerRecurringBooking>
  /** insert data into the table: "recurringBooking" */
  insertRecurringBookings: Maybe<ServerRecurringBookingMutationResponse>
  /** insert a single row into the table: "referrer" */
  insertReferrer: Maybe<ServerReferrer>
  /** insert data into the table: "referrer" */
  insertReferrers: Maybe<ServerReferrerMutationResponse>
  /** insert a single row into the table: "revenueReason" */
  insertRevenueReason: Maybe<ServerRevenueReason>
  /** insert data into the table: "revenueReason" */
  insertRevenueReasons: Maybe<ServerRevenueReasonMutationResponse>
  /** insert data into the table: "review" */
  insertReviews: Maybe<ServerReviewMutationResponse>
  /** insert a single row into the table: "rolePermission" */
  insertRolePermission: Maybe<ServerRolePermission>
  /** insert data into the table: "rolePermission" */
  insertRolePermissions: Maybe<ServerRolePermissionMutationResponse>
  /** insert a single row into the table: "salaryIssue" */
  insertSalaryIssue: Maybe<ServerSalaryIssue>
  /** insert a single row into the table: "salarySetting" */
  insertSalarySetting: Maybe<ServerSalarySetting>
  /** insert a single row into the table: "salarySettingService" */
  insertSalarySettingService: Maybe<ServerSalarySettingService>
  /** insert a single row into the table: "schedule" */
  insertSchedule: Maybe<ServerSchedule>
  /** insert data into the table: "schedule" */
  insertSchedules: Maybe<ServerScheduleMutationResponse>
  /** insert a single row into the table: "service" */
  insertService: Maybe<ServerService>
  /** insert a single row into the table: "serviceEmployee" */
  insertServiceEmployee: Maybe<ServerServiceEmployee>
  /** insert data into the table: "serviceEmployee" */
  insertServiceEmployees: Maybe<ServerServiceEmployeeMutationResponse>
  /** insert a single row into the table: "serviceGroup" */
  insertServiceGroup: Maybe<ServerServiceGroup>
  /** insert data into the table: "serviceGroup" */
  insertServiceGroups: Maybe<ServerServiceGroupMutationResponse>
  /** insert a single row into the table: "serviceLocation" */
  insertServiceLocation: Maybe<ServerServiceLocation>
  /** insert data into the table: "serviceLocation" */
  insertServiceLocations: Maybe<ServerServiceLocationMutationResponse>
  /** insert a single row into the table: "servicePermission" */
  insertServicePermission: Maybe<ServerServicePermission>
  /** insert data into the table: "servicePermission" */
  insertServicePermissions: Maybe<ServerServicePermissionMutationResponse>
  /** insert a single row into the table: "serviceProduct" */
  insertServiceProduct: Maybe<ServerServiceProduct>
  /** insert data into the table: "serviceProduct" */
  insertServiceProducts: Maybe<ServerServiceProductMutationResponse>
  /** insert data into the table: "service" */
  insertServices: Maybe<ServerServiceMutationResponse>
  /** insert a single row into the table: "storage" */
  insertStorage: Maybe<ServerStorage>
  /** insert a single row into the table: "storageEmployeeGroup" */
  insertStorageEmployeeGroup: Maybe<ServerStorageEmployeeGroup>
  /** insert data into the table: "storageEmployeeGroup" */
  insertStorageEmployeeGroups: Maybe<ServerStorageEmployeeGroupMutationResponse>
  /** insert data into the table: "storage" */
  insertStorages: Maybe<ServerStorageMutationResponse>
  /** insert data into the table: "super" */
  insertSupers: Maybe<ServerSuperMutationResponse>
  /** insert a single row into the table: "supplier" */
  insertSupplier: Maybe<ServerSupplier>
  /** insert data into the table: "supplier" */
  insertSuppliers: Maybe<ServerSupplierMutationResponse>
  /** insert a single row into the table: "tag" */
  insertTag: Maybe<ServerTag>
  /** insert data into the table: "tag" */
  insertTags: Maybe<ServerTagMutationResponse>
  /** insert a single row into the table: "timeOff" */
  insertTimeOff: Maybe<ServerTimeOff>
  /** insert a single row into the table: "timeOffReason" */
  insertTimeOffReason: Maybe<ServerTimeOffReason>
  /** insert a single row into the table: "timeOffReasonEmployeeGroup" */
  insertTimeOffReasonEmployeeGroupOne: Maybe<ServerTimeOffReasonEmployeeGroup>
  /** insert data into the table: "timeOffReasonEmployeeGroup" */
  insertTimeOffReasonEmployeeGroups: Maybe<ServerTimeOffReasonEmployeeGroupMutationResponse>
  /** insert data into the table: "timeOffReason" */
  insertTimeOffReasons: Maybe<ServerTimeOffReasonMutationResponse>
  /** insert data into the table: "timeOff" */
  insertTimeOffs: Maybe<ServerTimeOffMutationResponse>
  /** insert a single row into the table: "transaction" */
  insertTransaction: Maybe<ServerTransaction>
  /** insert a single row into the table: "transactionCard" */
  insertTransactionCard: Maybe<ServerTransactionCard>
  /** insert data into the table: "transactionCard" */
  insertTransactionCards: Maybe<ServerTransactionCardMutationResponse>
  /** insert a single row into the table: "transactionReceipt" */
  insertTransactionReceipt: Maybe<ServerTransactionReceipt>
  /** insert data into the table: "transactionReceipt" */
  insertTransactionReceipts: Maybe<ServerTransactionReceiptMutationResponse>
  /** insert a single row into the table: "transactionService" */
  insertTransactionService: Maybe<ServerTransactionService>
  /** insert data into the table: "transactionService" */
  insertTransactionServices: Maybe<ServerTransactionServiceMutationResponse>
  /** insert data into the table: "transaction" */
  insertTransactions: Maybe<ServerTransactionMutationResponse>
  /** insert a single row into the table: "unitedBooking" */
  insertUnitedBooking: Maybe<ServerUnitedBooking>
  /** insert a single row into the table: "userExpoNotification" */
  insertUserExpoNotification: Maybe<ServerUserExpoNotification>
  /** insert data into the table: "userExpoNotification" */
  insertUserExpoNotifications: Maybe<ServerUserExpoNotificationMutationResponse>
  /** insert a single row into the table: "waitingList" */
  insertWaitingList: Maybe<ServerWaitingList>
  /** insert a single row into the table: "waitingListEmployee" */
  insertWaitingListEmployee: Maybe<ServerWaitingListEmployee>
  /** insert data into the table: "waitingList" */
  insertWaitingLists: Maybe<ServerWaitingListMutationResponse>
  /** insert data into the table: "waitingListEmployee" */
  insertWaitingListsEmployees: Maybe<ServerWaitingListEmployeeMutationResponse>
  manipulateSuperAdmin: Maybe<ServerCloudFunctionResult>
  paySalary: Maybe<ServerPaySalaryResult>
  setCurrentBusiness: Maybe<ServerSetCurrentBusinessResult>
  setCurrentBusinessAsClient: Maybe<ServerSetCurrentBusinessAsClientResult>
  setCurrentClaimsForReferrer: Maybe<ServerSetCurrentClaimsForReferrerResult>
  stopEmployeeSchedule: Maybe<ServerOutput>
  /** update single row of the table: "account" */
  updateAccountById: Maybe<ServerAccount>
  /** update data of the table: "account" */
  updateAccounts: Maybe<ServerAccountMutationResponse>
  /** update single row of the table: "address" */
  updateAddressById: Maybe<ServerAddress>
  /** update data of the table: "address" */
  updateAddresses: Maybe<ServerAddressMutationResponse>
  /** update single row of the table: "bankDetails" */
  updateBankDetailsById: Maybe<ServerBankDetails>
  /** update data of the table: "bankDetails" */
  updateBanksDetails: Maybe<ServerBankDetailsMutationResponse>
  /** update single row of the table: "booking" */
  updateBookingById: Maybe<ServerBooking>
  /** update data of the table: "bookingClientSettings" */
  updateBookingClientSettings: Maybe<ServerBookingClientSettingsMutationResponse>
  /** update single row of the table: "bookingClientSettings" */
  updateBookingClientSettingsById: Maybe<ServerBookingClientSettings>
  /** update single row of the table: "bookingProduct" */
  updateBookingProductById: Maybe<ServerBookingProduct>
  /** update data of the table: "bookingProduct" */
  updateBookingProducts: Maybe<ServerBookingProductMutationResponse>
  /** update data of the table: "bookingStatusView" */
  updateBookingStatuses: Maybe<ServerBookingStatusViewMutationResponse>
  /** update data of the table: "booking" */
  updateBookings: Maybe<ServerBookingMutationResponse>
  /** update single row of the table: "branch" */
  updateBranchById: Maybe<ServerBranch>
  /** update data of the table: "branch" */
  updateBranches: Maybe<ServerBranchMutationResponse>
  /** update single row of the table: "business" */
  updateBusinessById: Maybe<ServerBusiness>
  /** update data of the table: "businessIsTestView" */
  updateBusinessIsTestView: Maybe<ServerBusinessIsTestViewMutationResponse>
  /** update single row of the table: "businessLanding" */
  updateBusinessLandingById: Maybe<ServerBusinessLanding>
  /** update data of the table: "businessLanding" */
  updateBusinessLandings: Maybe<ServerBusinessLandingMutationResponse>
  /** update single row of the table: "businessSettings" */
  updateBusinessSettingsById: Maybe<ServerBusinessSettings>
  /** update data of the table: "business" */
  updateBusinesses: Maybe<ServerBusinessMutationResponse>
  /** update single row of the table: "card" */
  updateCardById: Maybe<ServerCard>
  /** update single row of the table: "cardTemplate" */
  updateCardTemplateById: Maybe<ServerCardTemplate>
  /** update single row of the table: "cardTemplateService" */
  updateCardTemplateServiceById: Maybe<ServerCardTemplateService>
  /** update data of the table: "cardTemplateService" */
  updateCardTemplateServices: Maybe<ServerCardTemplateServiceMutationResponse>
  /** update data of the table: "cardTemplate" */
  updateCardTemplates: Maybe<ServerCardTemplateMutationResponse>
  /** update data of the table: "cardTemplateNameView" */
  updateCardTemplatesName: Maybe<ServerCardTemplateNameViewMutationResponse>
  /** update data of the table: "card" */
  updateCards: Maybe<ServerCardMutationResponse>
  /** update single row of the table: "client" */
  updateClientById: Maybe<ServerClient>
  /** update single row of the table: "clientDocument" */
  updateClientDocumentById: Maybe<ServerClientDocument>
  /** update data of the table: "clientDocument" */
  updateClientDocuments: Maybe<ServerClientDocumentMutationResponse>
  /** update data of the table: "clientEmailView" */
  updateClientEmailsView: Maybe<ServerClientEmailViewMutationResponse>
  /** update single row of the table: "clientNote" */
  updateClientNoteById: Maybe<ServerClientNote>
  /** update data of the table: "clientNote" */
  updateClientNotes: Maybe<ServerClientNoteMutationResponse>
  /** update single row of the table: "clientRelation" */
  updateClientRelationById: Maybe<ServerClientRelation>
  /** update data of the table: "clientRelation" */
  updateClientRelations: Maybe<ServerClientRelationMutationResponse>
  /** update single row of the table: "clientSettings" */
  updateClientSettingsById: Maybe<ServerClientSettings>
  /** update single row of the table: "clientTag" */
  updateClientTagById: Maybe<ServerClientTag>
  /** update data of the table: "clientTag" */
  updateClientTags: Maybe<ServerClientTagMutationResponse>
  /** update data of the table: "client" */
  updateClients: Maybe<ServerClientMutationResponse>
  /** update data of the table: "clientSettings" */
  updateClientsSettings: Maybe<ServerClientSettingsMutationResponse>
  /** update single row of the table: "dynamicScheduleDate" */
  updateDynamicScheduleDateById: Maybe<ServerDynamicScheduleDate>
  /** update data of the table: "dynamicScheduleDate" */
  updateDynamicScheduleDates: Maybe<ServerDynamicScheduleDateMutationResponse>
  /** update data of the table: "employeeBusinessOwnerView" */
  updateEmployeeBusinessOwners: Maybe<ServerEmployeeBusinessOwnerViewMutationResponse>
  /** update single row of the table: "employee" */
  updateEmployeeById: Maybe<ServerEmployee>
  /** update data of the table: "employeeEmailView" */
  updateEmployeeEmails: Maybe<ServerEmployeeEmailViewMutationResponse>
  /** update data of the table: "employeeGroupArchiveView" */
  updateEmployeeGroupArchives: Maybe<ServerEmployeeGroupArchiveViewMutationResponse>
  /** update single row of the table: "employeeGroup" */
  updateEmployeeGroupById: Maybe<ServerEmployeeGroup>
  /** update data of the table: "employeeGroupOwnerView" */
  updateEmployeeGroupOwnerView: Maybe<ServerEmployeeGroupOwnerViewMutationResponse>
  /** update multiples rows of table: "employeeGroupOwnerView" */
  updateEmployeeGroupOwnerViewMany: Maybe<
    Array<Maybe<ServerEmployeeGroupOwnerViewMutationResponse>>
  >
  /** update data of the table: "employeeGroup" */
  updateEmployeeGroups: Maybe<ServerEmployeeGroupMutationResponse>
  /** update single row of the table: "employeeSettings" */
  updateEmployeeSettingsById: Maybe<ServerEmployeeSettings>
  /** update data of the table: "employee" */
  updateEmployees: Maybe<ServerEmployeeMutationResponse>
  /** update single row of the table: "expaneInfo" */
  updateExpaneInfoById: Maybe<ServerExpaneInfo>
  /** update data of the table: "expaneInfoCategory" */
  updateExpaneInfoCategories: Maybe<ServerExpaneInfoCategoryMutationResponse>
  /** update single row of the table: "expaneInfoCategory" */
  updateExpaneInfoCategoryById: Maybe<ServerExpaneInfoCategory>
  /** update data of the table: "expaneInfo" */
  updateExpaneInfos: Maybe<ServerExpaneInfoMutationResponse>
  /** update single row of the table: "expensesReason" */
  updateExpensesReasonById: Maybe<ServerExpensesReason>
  /** update data of the table: "expensesReason" */
  updateExpensesReasons: Maybe<ServerExpensesReasonMutationResponse>
  /** update single row of the table: "human" */
  updateHumanById: Maybe<ServerHuman>
  /** update data of the table: "human" */
  updateHumans: Maybe<ServerHumanMutationResponse>
  /** update data of the table: "inventory" */
  updateInventories: Maybe<ServerInventoryMutationResponse>
  /** update single row of the table: "inventory" */
  updateInventoryById: Maybe<ServerInventory>
  /** update single row of the table: "lead" */
  updateLeadById: Maybe<ServerLead>
  /** update data of the table: "lead" */
  updateLeads: Maybe<ServerLeadMutationResponse>
  /** update single row of the table: "location" */
  updateLocationById: Maybe<ServerLocation>
  /** update single row of the table: "locationGroup" */
  updateLocationGroupById: Maybe<ServerLocationGroup>
  /** update data of the table: "location" */
  updateLocations: Maybe<ServerLocationMutationResponse>
  /** update multiples rows of table: "bookingClientSettings" */
  updateManyBookingClientSettings: Maybe<Array<Maybe<ServerBookingClientSettingsMutationResponse>>>
  /** update multiples rows of table: "businessIsTestView" */
  updateManyBusinessIsTestView: Maybe<Array<Maybe<ServerBusinessIsTestViewMutationResponse>>>
  /** update multiples rows of table: "businessLanding" */
  updateManyBusinessLandings: Maybe<Array<Maybe<ServerBusinessLandingMutationResponse>>>
  /** update multiples rows of table: "clientEmailView" */
  updateManyClientEmailsView: Maybe<Array<Maybe<ServerClientEmailViewMutationResponse>>>
  /** update multiples rows of table: "dynamicScheduleDate" */
  updateManyDynamicScheduleDates: Maybe<Array<Maybe<ServerDynamicScheduleDateMutationResponse>>>
  /** update multiples rows of table: "expaneInfoCategory" */
  updateManyExpaneInfoCategories: Maybe<Array<Maybe<ServerExpaneInfoCategoryMutationResponse>>>
  /** update multiples rows of table: "expaneInfo" */
  updateManyExpaneInfos: Maybe<Array<Maybe<ServerExpaneInfoMutationResponse>>>
  /** update multiples rows of table: "onlineBookingURL" */
  updateManyOnlineBookingURLs: Maybe<Array<Maybe<ServerOnlineBookingUrlMutationResponse>>>
  /** update multiples rows of table: "productGroup" */
  updateManyProductGroups: Maybe<Array<Maybe<ServerProductGroupMutationResponse>>>
  /** update multiples rows of table: "promoCode" */
  updateManyPromoCodes: Maybe<Array<Maybe<ServerPromoCodeMutationResponse>>>
  /** update multiples rows of table: "promoCodeReferrerView" */
  updateManyPromoCodesReferrerView: Maybe<Array<Maybe<ServerPromoCodeReferrerViewMutationResponse>>>
  /** update multiples rows of table: "referrer" */
  updateManyReferrers: Maybe<Array<Maybe<ServerReferrerMutationResponse>>>
  /** update multiples rows of table: "review" */
  updateManyReviews: Maybe<Array<Maybe<ServerReviewMutationResponse>>>
  /** update multiples rows of table: "serviceGroup" */
  updateManyServiceGroups: Maybe<Array<Maybe<ServerServiceGroupMutationResponse>>>
  /** update multiples rows of table: "super" */
  updateManySupers: Maybe<Array<Maybe<ServerSuperMutationResponse>>>
  updateModulesSettings: Maybe<ServerUpdateModulesSettingsResult>
  /** update single row of the table: "movement" */
  updateMovementById: Maybe<ServerMovement>
  /** update data of the table: "movement" */
  updateMovements: Maybe<ServerMovementMutationResponse>
  /** update data of the table: "businessSettings" */
  updateMultipleBusinessSettings: Maybe<ServerBusinessSettingsMutationResponse>
  /** update data of the table: "employeeSettings" */
  updateMultipleEmployeeSettings: Maybe<ServerEmployeeSettingsMutationResponse>
  /** update data of the table: "locationGroup" */
  updateMultipleLocationGroups: Maybe<ServerLocationGroupMutationResponse>
  /** update data of the table: "salarySetting" */
  updateMultipleSalarySetting: Maybe<ServerSalarySettingMutationResponse>
  /** update single row of the table: "notification" */
  updateNotificationById: Maybe<ServerNotification>
  /** update data of the table: "notification" */
  updateNotifications: Maybe<ServerNotificationMutationResponse>
  /** update single row of the table: "onlineBookingURL" */
  updateOnlineBookingURLById: Maybe<ServerOnlineBookingUrl>
  /** update data of the table: "onlineBookingURL" */
  updateOnlineBookingURLs: Maybe<ServerOnlineBookingUrlMutationResponse>
  updatePOS: Maybe<ServerCloudFunctionResult>
  /** update data of the table: "productArchiveView" */
  updateProductArchives: Maybe<ServerProductArchiveViewMutationResponse>
  /** update single row of the table: "product" */
  updateProductById: Maybe<ServerProduct>
  /** update data of the table: "productGroupArchiveView" */
  updateProductGroupArchives: Maybe<ServerProductGroupArchiveViewMutationResponse>
  /** update single row of the table: "productGroup" */
  updateProductGroupById: Maybe<ServerProductGroup>
  /** update data of the table: "productGroup" */
  updateProductGroups: Maybe<ServerProductGroupMutationResponse>
  /** update data of the table: "product" */
  updateProducts: Maybe<ServerProductMutationResponse>
  /** update single row of the table: "promoCode" */
  updatePromoCodeById: Maybe<ServerPromoCode>
  /** update data of the table: "promoCode" */
  updatePromoCodes: Maybe<ServerPromoCodeMutationResponse>
  /** update data of the table: "promoCodeReferrerView" */
  updatePromoCodesReferrerView: Maybe<ServerPromoCodeReferrerViewMutationResponse>
  /** update single row of the table: "referrer" */
  updateReferrerById: Maybe<ServerReferrer>
  /** update data of the table: "referrer" */
  updateReferrers: Maybe<ServerReferrerMutationResponse>
  /** update single row of the table: "revenueReason" */
  updateRevenueReasonById: Maybe<ServerRevenueReason>
  /** update data of the table: "revenueReason" */
  updateRevenueReasons: Maybe<ServerRevenueReasonMutationResponse>
  /** update single row of the table: "review" */
  updateReviewById: Maybe<ServerReview>
  /** update data of the table: "review" */
  updateReviews: Maybe<ServerReviewMutationResponse>
  /** update single row of the table: "salarySetting" */
  updateSalarySettingById: Maybe<ServerSalarySetting>
  /** update single row of the table: "schedule" */
  updateScheduleById: Maybe<ServerSchedule>
  /** update data of the table: "schedule" */
  updateSchedules: Maybe<ServerScheduleMutationResponse>
  /** update single row of the table: "service" */
  updateServiceById: Maybe<ServerService>
  /** update single row of the table: "serviceEmployee" */
  updateServiceEmployeeById: Maybe<ServerServiceEmployee>
  /** update data of the table: "serviceEmployee" */
  updateServiceEmployees: Maybe<ServerServiceEmployeeMutationResponse>
  /** update single row of the table: "serviceGroup" */
  updateServiceGroupById: Maybe<ServerServiceGroup>
  /** update data of the table: "serviceGroup" */
  updateServiceGroups: Maybe<ServerServiceGroupMutationResponse>
  /** update single row of the table: "serviceLocation" */
  updateServiceLocationById: Maybe<ServerServiceLocation>
  /** update data of the table: "serviceLocation" */
  updateServiceLocations: Maybe<ServerServiceLocationMutationResponse>
  /** update single row of the table: "servicePermission" */
  updateServicePermissionById: Maybe<ServerServicePermission>
  /** update data of the table: "servicePermission" */
  updateServicePermissions: Maybe<ServerServicePermissionMutationResponse>
  /** update single row of the table: "serviceProduct" */
  updateServiceProductById: Maybe<ServerServiceProduct>
  /** update data of the table: "serviceProduct" */
  updateServiceProducts: Maybe<ServerServiceProductMutationResponse>
  /** update data of the table: "service" */
  updateServices: Maybe<ServerServiceMutationResponse>
  /** update single row of the table: "storage" */
  updateStorageById: Maybe<ServerStorage>
  /** update data of the table: "storage" */
  updateStorages: Maybe<ServerStorageMutationResponse>
  /** update single row of the table: "super" */
  updateSuperById: Maybe<ServerSuper>
  /** update data of the table: "super" */
  updateSupers: Maybe<ServerSuperMutationResponse>
  /** update data of the table: "supplier" */
  updateSuppliers: Maybe<ServerSupplierMutationResponse>
  /** update single row of the table: "supplier" */
  updateSuppliersById: Maybe<ServerSupplier>
  /** update single row of the table: "tag" */
  updateTagById: Maybe<ServerTag>
  /** update data of the table: "tag" */
  updateTags: Maybe<ServerTagMutationResponse>
  /** update single row of the table: "timeOff" */
  updateTimeOffById: Maybe<ServerTimeOff>
  /** update single row of the table: "timeOffReason" */
  updateTimeOffReasonById: Maybe<ServerTimeOffReason>
  /** update data of the table: "timeOffReason" */
  updateTimeOffReasons: Maybe<ServerTimeOffReasonMutationResponse>
  /** update data of the table: "timeOff" */
  updateTimeOffs: Maybe<ServerTimeOffMutationResponse>
  /** update single row of the table: "transactionCard" */
  updateTransactionCardById: Maybe<ServerTransactionCard>
  /** update data of the table: "transactionCard" */
  updateTransactionCards: Maybe<ServerTransactionCardMutationResponse>
  /** update data of the table: "userExpoNotification" */
  updateUserExpoNotification: Maybe<ServerUserExpoNotificationMutationResponse>
  /** update single row of the table: "userExpoNotification" */
  updateUserExpoNotificationByEmail: Maybe<ServerUserExpoNotification>
  /** update single row of the table: "waitingList" */
  updateWaitingListById: Maybe<ServerWaitingList>
  /** update data of the table: "waitingList" */
  updateWaitingLists: Maybe<ServerWaitingListMutationResponse>
  /** update multiples rows of table: "account" */
  update_account_many: Maybe<Array<Maybe<ServerAccountMutationResponse>>>
  /** update multiples rows of table: "address" */
  update_address_many: Maybe<Array<Maybe<ServerAddressMutationResponse>>>
  /** update multiples rows of table: "bankDetails" */
  update_bankDetails_many: Maybe<Array<Maybe<ServerBankDetailsMutationResponse>>>
  /** update multiples rows of table: "bookingProduct" */
  update_bookingProduct_many: Maybe<Array<Maybe<ServerBookingProductMutationResponse>>>
  /** update multiples rows of table: "bookingStatusView" */
  update_bookingStatusView_many: Maybe<Array<Maybe<ServerBookingStatusViewMutationResponse>>>
  /** update multiples rows of table: "booking" */
  update_booking_many: Maybe<Array<Maybe<ServerBookingMutationResponse>>>
  /** update multiples rows of table: "branch" */
  update_branch_many: Maybe<Array<Maybe<ServerBranchMutationResponse>>>
  /** update multiples rows of table: "businessSettings" */
  update_businessSettings_many: Maybe<Array<Maybe<ServerBusinessSettingsMutationResponse>>>
  /** update multiples rows of table: "business" */
  update_business_many: Maybe<Array<Maybe<ServerBusinessMutationResponse>>>
  /** update multiples rows of table: "cardTemplateNameView" */
  update_cardTemplateNameView_many: Maybe<Array<Maybe<ServerCardTemplateNameViewMutationResponse>>>
  /** update multiples rows of table: "cardTemplateService" */
  update_cardTemplateService_many: Maybe<Array<Maybe<ServerCardTemplateServiceMutationResponse>>>
  /** update multiples rows of table: "cardTemplate" */
  update_cardTemplate_many: Maybe<Array<Maybe<ServerCardTemplateMutationResponse>>>
  /** update multiples rows of table: "card" */
  update_card_many: Maybe<Array<Maybe<ServerCardMutationResponse>>>
  /** update multiples rows of table: "clientDocument" */
  update_clientDocument_many: Maybe<Array<Maybe<ServerClientDocumentMutationResponse>>>
  /** update multiples rows of table: "clientNote" */
  update_clientNote_many: Maybe<Array<Maybe<ServerClientNoteMutationResponse>>>
  /** update multiples rows of table: "clientRelation" */
  update_clientRelation_many: Maybe<Array<Maybe<ServerClientRelationMutationResponse>>>
  /** update multiples rows of table: "clientSettings" */
  update_clientSettings_many: Maybe<Array<Maybe<ServerClientSettingsMutationResponse>>>
  /** update multiples rows of table: "clientTag" */
  update_clientTag_many: Maybe<Array<Maybe<ServerClientTagMutationResponse>>>
  /** update multiples rows of table: "client" */
  update_client_many: Maybe<Array<Maybe<ServerClientMutationResponse>>>
  /** update multiples rows of table: "employeeBusinessOwnerView" */
  update_employeeBusinessOwnerView_many: Maybe<
    Array<Maybe<ServerEmployeeBusinessOwnerViewMutationResponse>>
  >
  /** update multiples rows of table: "employeeEmailView" */
  update_employeeEmailView_many: Maybe<Array<Maybe<ServerEmployeeEmailViewMutationResponse>>>
  /** update multiples rows of table: "employeeGroupArchiveView" */
  update_employeeGroupArchiveView_many: Maybe<
    Array<Maybe<ServerEmployeeGroupArchiveViewMutationResponse>>
  >
  /** update multiples rows of table: "employeeGroup" */
  update_employeeGroup_many: Maybe<Array<Maybe<ServerEmployeeGroupMutationResponse>>>
  /** update multiples rows of table: "employeeSettings" */
  update_employeeSettings_many: Maybe<Array<Maybe<ServerEmployeeSettingsMutationResponse>>>
  /** update multiples rows of table: "employee" */
  update_employee_many: Maybe<Array<Maybe<ServerEmployeeMutationResponse>>>
  /** update multiples rows of table: "expensesReason" */
  update_expensesReason_many: Maybe<Array<Maybe<ServerExpensesReasonMutationResponse>>>
  /** update multiples rows of table: "human" */
  update_human_many: Maybe<Array<Maybe<ServerHumanMutationResponse>>>
  /** update multiples rows of table: "inventory" */
  update_inventory_many: Maybe<Array<Maybe<ServerInventoryMutationResponse>>>
  /** update multiples rows of table: "lead" */
  update_lead_many: Maybe<Array<Maybe<ServerLeadMutationResponse>>>
  /** update multiples rows of table: "locationGroup" */
  update_locationGroup_many: Maybe<Array<Maybe<ServerLocationGroupMutationResponse>>>
  /** update multiples rows of table: "location" */
  update_location_many: Maybe<Array<Maybe<ServerLocationMutationResponse>>>
  /** update multiples rows of table: "movement" */
  update_movement_many: Maybe<Array<Maybe<ServerMovementMutationResponse>>>
  /** update multiples rows of table: "notification" */
  update_notification_many: Maybe<Array<Maybe<ServerNotificationMutationResponse>>>
  /** update multiples rows of table: "productArchiveView" */
  update_productArchiveView_many: Maybe<Array<Maybe<ServerProductArchiveViewMutationResponse>>>
  /** update multiples rows of table: "productGroupArchiveView" */
  update_productGroupArchiveView_many: Maybe<
    Array<Maybe<ServerProductGroupArchiveViewMutationResponse>>
  >
  /** update multiples rows of table: "product" */
  update_product_many: Maybe<Array<Maybe<ServerProductMutationResponse>>>
  /** update multiples rows of table: "revenueReason" */
  update_revenueReason_many: Maybe<Array<Maybe<ServerRevenueReasonMutationResponse>>>
  /** update multiples rows of table: "salarySetting" */
  update_salarySetting_many: Maybe<Array<Maybe<ServerSalarySettingMutationResponse>>>
  /** update multiples rows of table: "schedule" */
  update_schedule_many: Maybe<Array<Maybe<ServerScheduleMutationResponse>>>
  /** update multiples rows of table: "serviceEmployee" */
  update_serviceEmployee_many: Maybe<Array<Maybe<ServerServiceEmployeeMutationResponse>>>
  /** update multiples rows of table: "serviceLocation" */
  update_serviceLocation_many: Maybe<Array<Maybe<ServerServiceLocationMutationResponse>>>
  /** update multiples rows of table: "servicePermission" */
  update_servicePermission_many: Maybe<Array<Maybe<ServerServicePermissionMutationResponse>>>
  /** update multiples rows of table: "serviceProduct" */
  update_serviceProduct_many: Maybe<Array<Maybe<ServerServiceProductMutationResponse>>>
  /** update multiples rows of table: "service" */
  update_service_many: Maybe<Array<Maybe<ServerServiceMutationResponse>>>
  /** update multiples rows of table: "storage" */
  update_storage_many: Maybe<Array<Maybe<ServerStorageMutationResponse>>>
  /** update multiples rows of table: "supplier" */
  update_supplier_many: Maybe<Array<Maybe<ServerSupplierMutationResponse>>>
  /** update multiples rows of table: "tag" */
  update_tag_many: Maybe<Array<Maybe<ServerTagMutationResponse>>>
  /** update multiples rows of table: "timeOffReason" */
  update_timeOffReason_many: Maybe<Array<Maybe<ServerTimeOffReasonMutationResponse>>>
  /** update multiples rows of table: "timeOff" */
  update_timeOff_many: Maybe<Array<Maybe<ServerTimeOffMutationResponse>>>
  /** update multiples rows of table: "transactionCard" */
  update_transactionCard_many: Maybe<Array<Maybe<ServerTransactionCardMutationResponse>>>
  /** update multiples rows of table: "userExpoNotification" */
  update_userExpoNotification_many: Maybe<Array<Maybe<ServerUserExpoNotificationMutationResponse>>>
  /** update multiples rows of table: "waitingList" */
  update_waitingList_many: Maybe<Array<Maybe<ServerWaitingListMutationResponse>>>
  uploadFile: Maybe<ServerUploadFileResult>
}

/** mutation root */
export type ServerMutationRootActivateGiftCardArgs = {
  branchId: Scalars['Int']
  code: Scalars['String']
}

/** mutation root */
export type ServerMutationRootAnonCreateBookingArgs = {
  branchId: Scalars['Int']
  businessId: Scalars['Int']
  clientEmail?: InputMaybe<Scalars['String']>
  clientFirstName: Scalars['String']
  clientLang: ServerLanguages
  clientLastName: Scalars['String']
  clientNote?: InputMaybe<Scalars['String']>
  clientPhone: Scalars['String']
  date: Scalars['timestamptz']
  employeeId?: InputMaybe<Scalars['Int']>
  locationId: Scalars['Int']
  serviceIds: Array<Scalars['Int']>
}

/** mutation root */
export type ServerMutationRootArchiveEmployeeArgs = {
  archiveType: ServerArchiveEmployeeTypes
  employeeId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootArchiveLocationArgs = {
  archiveType: ServerArchiveTypes
  locationId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootArchiveLocationGroupArgs = {
  archiveType: ServerArchiveTypes
  locationGroupId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootArchiveServiceArgs = {
  archiveType: ServerArchiveTypes
  serviceId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootArchiveServiceGroupArgs = {
  archiveType: ServerArchiveTypes
  serviceGroupId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootBootstrapCalendarArgs = {
  clientId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootBranchImportProductsArgs = {
  productGroupIds: Array<Scalars['Int']>
  productIds: Array<Scalars['Int']>
  toBranchId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootBranchImportServicesArgs = {
  serviceDtos: Array<ServerServiceImportDto>
  serviceGroupIds: Array<Scalars['Int']>
  toBranchId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootCheckPromoCodeArgs = {
  promoCode: Scalars['String']
}

/** mutation root */
export type ServerMutationRootCheckboxCashierSigninArgs = {
  softwarePOSId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootCheckboxCashierSignoutArgs = {
  softwarePOSId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootCheckboxCloseShiftArgs = {
  softwarePOSId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootCheckboxCreateBulkReceiptsArgs = {
  clientId: Scalars['Int']
  receipts: Array<ServerCreateReceiptDto>
  softwarePOSId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootCheckboxCreateReceiptArgs = {
  clientId: Scalars['Int']
  receipt: ServerCreateReceiptDto
  softwarePOSId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootCheckboxCreateServiceReceiptArgs = {
  softwarePOSId: Scalars['Int']
  type: ServerCreateServiceReceiptType
  value: Scalars['Float']
}

/** mutation root */
export type ServerMutationRootCheckboxOpenShiftArgs = {
  softwarePOSId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootCreateBookingArgs = {
  branchId: Scalars['Int']
  clientNote?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['timestamptz']>
  dates?: InputMaybe<Array<Scalars['timestamptz']>>
  employeeId?: InputMaybe<Scalars['Int']>
  locationId: Scalars['Int']
  serviceIds: Array<Scalars['Int']>
}

/** mutation root */
export type ServerMutationRootCreateBranchArgs = {
  accountName: Scalars['String']
  address?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  geocode?: InputMaybe<Scalars['jsonb']>
  name: Scalars['String']
  phone?: InputMaybe<Scalars['String']>
  scheduleId: Scalars['Int']
  storageName?: InputMaybe<Scalars['String']>
  timezone: Scalars['String']
}

/** mutation root */
export type ServerMutationRootCreateBusinessArgs = {
  branchAddress?: InputMaybe<Scalars['String']>
  branchGeo?: InputMaybe<Scalars['point']>
  branchPhone?: InputMaybe<Scalars['String']>
  businessName: Scalars['String']
  currency: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  lang?: InputMaybe<ServerLanguages>
  modulesSettings?: InputMaybe<ServerModulesSettingsInput>
  ownerFirstName: Scalars['String']
  ownerLastName: Scalars['String']
  ownerPhone?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['String']>
  planId?: InputMaybe<Scalars['Int']>
  promoCode?: InputMaybe<Scalars['String']>
  timezone: Scalars['String']
}

/** mutation root */
export type ServerMutationRootCreateNewClientArgs = {
  additionalInformation?: InputMaybe<Scalars['String']>
  branchPhone: Scalars['String']
  firstName: Scalars['String']
  lang?: InputMaybe<ServerLanguages>
  lastName?: InputMaybe<Scalars['String']>
  phone: Scalars['String']
}

/** mutation root */
export type ServerMutationRootDeleteAccountEmployeeGroupByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteAccountEmployeeGroupsArgs = {
  where: ServerAccountEmployeeGroupBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteBookingClientByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteBookingClientsArgs = {
  where: ServerBookingClientBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteBookingProductByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteBookingProductsArgs = {
  where: ServerBookingProductBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteBookingServiceByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteBookingServicesArgs = {
  where: ServerBookingServiceBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteCardByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteCardTemplateServiceByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteCardTemplateServicesArgs = {
  where: ServerCardTemplateServiceBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteCardsArgs = {
  where: ServerCardBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteClientArgs = {
  deleteType: ServerDeleteTypes
}

/** mutation root */
export type ServerMutationRootDeleteClientBranchArchivedArgs = {
  where: ServerClientBranchArchivedBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteClientBranchArchivedByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteClientRelationByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteClientRelationsArgs = {
  where: ServerClientRelationBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteClientTagByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteClientTagsArgs = {
  where: ServerClientTagBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteDynamicScheduleDateByIdArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type ServerMutationRootDeleteDynamicScheduleDatesArgs = {
  where: ServerDynamicScheduleDateBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteExpaneInfoCategoriesArgs = {
  where: ServerExpaneInfoCategoryBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteExpaneInfoCategoryByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteFileArgs = {
  url: Scalars['String']
}

/** mutation root */
export type ServerMutationRootDeleteInventoryProductByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteInventoryProductsArgs = {
  where: ServerInventoryProductBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteOnlineBookingUrlByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteOnlineBookingUrLsArgs = {
  where: ServerOnlineBookingUrlBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteProductGroupEmployeeGroupByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteProductGroupEmployeeGroupsArgs = {
  where: ServerProductGroupEmployeeGroupBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteRolePermissionByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteRolePermissionsArgs = {
  where: ServerRolePermissionBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteServiceEmployeeByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteServiceEmployeesArgs = {
  where: ServerServiceEmployeeBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteServiceLocationByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteServiceLocationsArgs = {
  where: ServerServiceLocationBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteServicePermissionByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteServicePermissionsArgs = {
  where: ServerServicePermissionBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteServiceProductByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteServiceProductsArgs = {
  where: ServerServiceProductBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteStorageEmployeeGroupByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteStorageEmployeeGroupsArgs = {
  where: ServerStorageEmployeeGroupBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteSuperByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteSupersArgs = {
  where: ServerSuperBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteTimeOffReasonEmployeeGroupByIdArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteTimeOffReasonEmployeeGroupsArgs = {
  where: ServerTimeOffReasonEmployeeGroupBoolExp
}

/** mutation root */
export type ServerMutationRootDeleteWaitingListEmployeeArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootDeleteWaitingListsEmployeesArgs = {
  where: ServerWaitingListEmployeeBoolExp
}

/** mutation root */
export type ServerMutationRootExtendBillingArgs = {
  period: ServerPeriod
  planId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootImportDataArgs = {
  base64File: Scalars['String']
  branchId: Scalars['Int']
  businessId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootInserMovementProductArgs = {
  object: ServerMovementProductInsertInput
  on_conflict?: InputMaybe<ServerMovementProductOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertAccountArgs = {
  object: ServerAccountInsertInput
  on_conflict?: InputMaybe<ServerAccountOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertAccountEmployeeGroupArgs = {
  object: ServerAccountEmployeeGroupInsertInput
  on_conflict?: InputMaybe<ServerAccountEmployeeGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertAccountEmployeeGroupsArgs = {
  objects: Array<ServerAccountEmployeeGroupInsertInput>
  on_conflict?: InputMaybe<ServerAccountEmployeeGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertAccountsArgs = {
  objects: Array<ServerAccountInsertInput>
  on_conflict?: InputMaybe<ServerAccountOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertAddressArgs = {
  object: ServerAddressInsertInput
  on_conflict?: InputMaybe<ServerAddressOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertAddressesArgs = {
  objects: Array<ServerAddressInsertInput>
  on_conflict?: InputMaybe<ServerAddressOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBankDetailsArgs = {
  object: ServerBankDetailsInsertInput
  on_conflict?: InputMaybe<ServerBankDetailsOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBanksDetailsArgs = {
  objects: Array<ServerBankDetailsInsertInput>
  on_conflict?: InputMaybe<ServerBankDetailsOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBookingArgs = {
  object: ServerBookingInsertInput
  on_conflict?: InputMaybe<ServerBookingOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBookingClientByIdArgs = {
  object: ServerBookingClientInsertInput
  on_conflict?: InputMaybe<ServerBookingClientOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBookingClientsArgs = {
  objects: Array<ServerBookingClientInsertInput>
  on_conflict?: InputMaybe<ServerBookingClientOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBookingProductArgs = {
  object: ServerBookingProductInsertInput
  on_conflict?: InputMaybe<ServerBookingProductOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBookingProductsArgs = {
  objects: Array<ServerBookingProductInsertInput>
  on_conflict?: InputMaybe<ServerBookingProductOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBookingServiceArgs = {
  object: ServerBookingServiceInsertInput
  on_conflict?: InputMaybe<ServerBookingServiceOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBookingServicesArgs = {
  objects: Array<ServerBookingServiceInsertInput>
  on_conflict?: InputMaybe<ServerBookingServiceOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBookingsArgs = {
  objects: Array<ServerBookingInsertInput>
  on_conflict?: InputMaybe<ServerBookingOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBranchArgs = {
  object: ServerBranchInsertInput
  on_conflict?: InputMaybe<ServerBranchOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBranchesArgs = {
  objects: Array<ServerBranchInsertInput>
  on_conflict?: InputMaybe<ServerBranchOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBusinessPromoCodeByIdArgs = {
  object: ServerBusinessPromoCodeInsertInput
  on_conflict?: InputMaybe<ServerBusinessPromoCodeOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertBusinessPromoCodesArgs = {
  objects: Array<ServerBusinessPromoCodeInsertInput>
  on_conflict?: InputMaybe<ServerBusinessPromoCodeOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertCardArgs = {
  object: ServerCardInsertInput
  on_conflict?: InputMaybe<ServerCardOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertCardTemplateArgs = {
  object: ServerCardTemplateInsertInput
  on_conflict?: InputMaybe<ServerCardTemplateOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertCardTemplateServiceArgs = {
  object: ServerCardTemplateServiceInsertInput
  on_conflict?: InputMaybe<ServerCardTemplateServiceOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertCardTemplateServicesArgs = {
  objects: Array<ServerCardTemplateServiceInsertInput>
  on_conflict?: InputMaybe<ServerCardTemplateServiceOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertCardTemplatesArgs = {
  objects: Array<ServerCardTemplateInsertInput>
  on_conflict?: InputMaybe<ServerCardTemplateOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertCardsArgs = {
  objects: Array<ServerCardInsertInput>
  on_conflict?: InputMaybe<ServerCardOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientArgs = {
  object: ServerClientInsertInput
  on_conflict?: InputMaybe<ServerClientOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientBranchArchivedArgs = {
  object: ServerClientBranchArchivedInsertInput
  on_conflict?: InputMaybe<ServerClientBranchArchivedOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientDocumentArgs = {
  object: ServerClientDocumentInsertInput
  on_conflict?: InputMaybe<ServerClientDocumentOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientDocumentsArgs = {
  objects: Array<ServerClientDocumentInsertInput>
  on_conflict?: InputMaybe<ServerClientDocumentOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientNoteArgs = {
  object: ServerClientNoteInsertInput
  on_conflict?: InputMaybe<ServerClientNoteOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientNotesArgs = {
  objects: Array<ServerClientNoteInsertInput>
  on_conflict?: InputMaybe<ServerClientNoteOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientRelationArgs = {
  object: ServerClientRelationInsertInput
  on_conflict?: InputMaybe<ServerClientRelationOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientRelationsArgs = {
  objects: Array<ServerClientRelationInsertInput>
  on_conflict?: InputMaybe<ServerClientRelationOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientSettingsArgs = {
  object: ServerClientSettingsInsertInput
  on_conflict?: InputMaybe<ServerClientSettingsOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientTagArgs = {
  object: ServerClientTagInsertInput
  on_conflict?: InputMaybe<ServerClientTagOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientTagsArgs = {
  objects: Array<ServerClientTagInsertInput>
  on_conflict?: InputMaybe<ServerClientTagOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientsArgs = {
  objects: Array<ServerClientInsertInput>
  on_conflict?: InputMaybe<ServerClientOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertClientsSettingsArgs = {
  objects: Array<ServerClientSettingsInsertInput>
  on_conflict?: InputMaybe<ServerClientSettingsOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertDynamicScheduleDateArgs = {
  object: ServerDynamicScheduleDateInsertInput
  on_conflict?: InputMaybe<ServerDynamicScheduleDateOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertDynamicScheduleDatesArgs = {
  objects: Array<ServerDynamicScheduleDateInsertInput>
  on_conflict?: InputMaybe<ServerDynamicScheduleDateOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertEmployeeArgs = {
  object: ServerEmployeeInsertInput
  on_conflict?: InputMaybe<ServerEmployeeOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertEmployeeGroupArgs = {
  object: ServerEmployeeGroupInsertInput
  on_conflict?: InputMaybe<ServerEmployeeGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertEmployeeGroupsArgs = {
  objects: Array<ServerEmployeeGroupInsertInput>
  on_conflict?: InputMaybe<ServerEmployeeGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertEmployeeScheduleArgs = {
  object: ServerEmployeeScheduleInsertInput
  on_conflict?: InputMaybe<ServerEmployeeScheduleOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertEmployeeSchedulesArgs = {
  objects: Array<ServerEmployeeScheduleInsertInput>
  on_conflict?: InputMaybe<ServerEmployeeScheduleOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertEmployeeSettingsArgs = {
  object: ServerEmployeeSettingsInsertInput
  on_conflict?: InputMaybe<ServerEmployeeSettingsOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertEmployeesArgs = {
  objects: Array<ServerEmployeeInsertInput>
  on_conflict?: InputMaybe<ServerEmployeeOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertExpaneInfoCategoriesArgs = {
  objects: Array<ServerExpaneInfoCategoryInsertInput>
  on_conflict?: InputMaybe<ServerExpaneInfoCategoryOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertExpensesReasonArgs = {
  object: ServerExpensesReasonInsertInput
  on_conflict?: InputMaybe<ServerExpensesReasonOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertExpensesReasonsArgs = {
  objects: Array<ServerExpensesReasonInsertInput>
  on_conflict?: InputMaybe<ServerExpensesReasonOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertHumanArgs = {
  object: ServerHumanInsertInput
  on_conflict?: InputMaybe<ServerHumanOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertHumansArgs = {
  objects: Array<ServerHumanInsertInput>
  on_conflict?: InputMaybe<ServerHumanOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertInventoriesArgs = {
  objects: Array<ServerInventoryInsertInput>
  on_conflict?: InputMaybe<ServerInventoryOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertInventoryArgs = {
  object: ServerInventoryInsertInput
  on_conflict?: InputMaybe<ServerInventoryOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertInventoryProductArgs = {
  object: ServerInventoryProductInsertInput
  on_conflict?: InputMaybe<ServerInventoryProductOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertInventoryProductsArgs = {
  objects: Array<ServerInventoryProductInsertInput>
  on_conflict?: InputMaybe<ServerInventoryProductOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertLeadArgs = {
  object: ServerLeadInsertInput
  on_conflict?: InputMaybe<ServerLeadOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertLeadsArgs = {
  objects: Array<ServerLeadInsertInput>
  on_conflict?: InputMaybe<ServerLeadOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertLocationArgs = {
  object: ServerLocationInsertInput
  on_conflict?: InputMaybe<ServerLocationOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertLocationGroupArgs = {
  object: ServerLocationGroupInsertInput
  on_conflict?: InputMaybe<ServerLocationGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertLocationsArgs = {
  objects: Array<ServerLocationInsertInput>
  on_conflict?: InputMaybe<ServerLocationOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertManyClientBranchArchivedArgs = {
  objects: Array<ServerClientBranchArchivedInsertInput>
  on_conflict?: InputMaybe<ServerClientBranchArchivedOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertMovementArgs = {
  object: ServerMovementInsertInput
  on_conflict?: InputMaybe<ServerMovementOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertMovementProductsArgs = {
  objects: Array<ServerMovementProductInsertInput>
  on_conflict?: InputMaybe<ServerMovementProductOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertMovementsArgs = {
  objects: Array<ServerMovementInsertInput>
  on_conflict?: InputMaybe<ServerMovementOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertMultipleEmployeeSettingsArgs = {
  objects: Array<ServerEmployeeSettingsInsertInput>
  on_conflict?: InputMaybe<ServerEmployeeSettingsOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertMultipleLocationGroupsArgs = {
  objects: Array<ServerLocationGroupInsertInput>
  on_conflict?: InputMaybe<ServerLocationGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertMultipleSalaryIssuesArgs = {
  objects: Array<ServerSalaryIssueInsertInput>
  on_conflict?: InputMaybe<ServerSalaryIssueOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertMultipleSalarySettingArgs = {
  objects: Array<ServerSalarySettingInsertInput>
  on_conflict?: InputMaybe<ServerSalarySettingOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertMultipleSalarySettingServiceArgs = {
  objects: Array<ServerSalarySettingServiceInsertInput>
  on_conflict?: InputMaybe<ServerSalarySettingServiceOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertMultiplyUnitedBookingsArgs = {
  objects: Array<ServerUnitedBookingInsertInput>
  on_conflict?: InputMaybe<ServerUnitedBookingOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertNotificationArgs = {
  object: ServerNotificationInsertInput
  on_conflict?: InputMaybe<ServerNotificationOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertNotificationsArgs = {
  objects: Array<ServerNotificationInsertInput>
  on_conflict?: InputMaybe<ServerNotificationOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertOneExpaneInfoCategoryArgs = {
  object: ServerExpaneInfoCategoryInsertInput
  on_conflict?: InputMaybe<ServerExpaneInfoCategoryOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertOneOnlineBookingUrlArgs = {
  object: ServerOnlineBookingUrlInsertInput
  on_conflict?: InputMaybe<ServerOnlineBookingUrlOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertOnePromoCodeArgs = {
  object: ServerPromoCodeInsertInput
  on_conflict?: InputMaybe<ServerPromoCodeOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertOneReviewArgs = {
  object: ServerReviewInsertInput
  on_conflict?: InputMaybe<ServerReviewOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertOneSuperArgs = {
  object: ServerSuperInsertInput
  on_conflict?: InputMaybe<ServerSuperOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertOnlineBookingUrLsArgs = {
  objects: Array<ServerOnlineBookingUrlInsertInput>
  on_conflict?: InputMaybe<ServerOnlineBookingUrlOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertProductArgs = {
  object: ServerProductInsertInput
  on_conflict?: InputMaybe<ServerProductOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertProductGroupArgs = {
  object: ServerProductGroupInsertInput
  on_conflict?: InputMaybe<ServerProductGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertProductGroupEmployeeGroupArgs = {
  object: ServerProductGroupEmployeeGroupInsertInput
  on_conflict?: InputMaybe<ServerProductGroupEmployeeGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertProductGroupEmployeeGroupsArgs = {
  objects: Array<ServerProductGroupEmployeeGroupInsertInput>
  on_conflict?: InputMaybe<ServerProductGroupEmployeeGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertProductGroupsArgs = {
  objects: Array<ServerProductGroupInsertInput>
  on_conflict?: InputMaybe<ServerProductGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertProductsArgs = {
  objects: Array<ServerProductInsertInput>
  on_conflict?: InputMaybe<ServerProductOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertPromoCodesArgs = {
  objects: Array<ServerPromoCodeInsertInput>
  on_conflict?: InputMaybe<ServerPromoCodeOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertRecurringBookingArgs = {
  object: ServerRecurringBookingInsertInput
  on_conflict?: InputMaybe<ServerRecurringBookingOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertRecurringBookingsArgs = {
  objects: Array<ServerRecurringBookingInsertInput>
  on_conflict?: InputMaybe<ServerRecurringBookingOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertReferrerArgs = {
  object: ServerReferrerInsertInput
  on_conflict?: InputMaybe<ServerReferrerOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertReferrersArgs = {
  objects: Array<ServerReferrerInsertInput>
  on_conflict?: InputMaybe<ServerReferrerOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertRevenueReasonArgs = {
  object: ServerRevenueReasonInsertInput
  on_conflict?: InputMaybe<ServerRevenueReasonOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertRevenueReasonsArgs = {
  objects: Array<ServerRevenueReasonInsertInput>
  on_conflict?: InputMaybe<ServerRevenueReasonOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertReviewsArgs = {
  objects: Array<ServerReviewInsertInput>
  on_conflict?: InputMaybe<ServerReviewOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertRolePermissionArgs = {
  object: ServerRolePermissionInsertInput
  on_conflict?: InputMaybe<ServerRolePermissionOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertRolePermissionsArgs = {
  objects: Array<ServerRolePermissionInsertInput>
  on_conflict?: InputMaybe<ServerRolePermissionOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertSalaryIssueArgs = {
  object: ServerSalaryIssueInsertInput
  on_conflict?: InputMaybe<ServerSalaryIssueOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertSalarySettingArgs = {
  object: ServerSalarySettingInsertInput
  on_conflict?: InputMaybe<ServerSalarySettingOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertSalarySettingServiceArgs = {
  object: ServerSalarySettingServiceInsertInput
  on_conflict?: InputMaybe<ServerSalarySettingServiceOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertScheduleArgs = {
  object: ServerScheduleInsertInput
  on_conflict?: InputMaybe<ServerScheduleOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertSchedulesArgs = {
  objects: Array<ServerScheduleInsertInput>
  on_conflict?: InputMaybe<ServerScheduleOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertServiceArgs = {
  object: ServerServiceInsertInput
  on_conflict?: InputMaybe<ServerServiceOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertServiceEmployeeArgs = {
  object: ServerServiceEmployeeInsertInput
  on_conflict?: InputMaybe<ServerServiceEmployeeOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertServiceEmployeesArgs = {
  objects: Array<ServerServiceEmployeeInsertInput>
  on_conflict?: InputMaybe<ServerServiceEmployeeOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertServiceGroupArgs = {
  object: ServerServiceGroupInsertInput
  on_conflict?: InputMaybe<ServerServiceGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertServiceGroupsArgs = {
  objects: Array<ServerServiceGroupInsertInput>
  on_conflict?: InputMaybe<ServerServiceGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertServiceLocationArgs = {
  object: ServerServiceLocationInsertInput
  on_conflict?: InputMaybe<ServerServiceLocationOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertServiceLocationsArgs = {
  objects: Array<ServerServiceLocationInsertInput>
  on_conflict?: InputMaybe<ServerServiceLocationOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertServicePermissionArgs = {
  object: ServerServicePermissionInsertInput
  on_conflict?: InputMaybe<ServerServicePermissionOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertServicePermissionsArgs = {
  objects: Array<ServerServicePermissionInsertInput>
  on_conflict?: InputMaybe<ServerServicePermissionOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertServiceProductArgs = {
  object: ServerServiceProductInsertInput
  on_conflict?: InputMaybe<ServerServiceProductOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertServiceProductsArgs = {
  objects: Array<ServerServiceProductInsertInput>
  on_conflict?: InputMaybe<ServerServiceProductOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertServicesArgs = {
  objects: Array<ServerServiceInsertInput>
  on_conflict?: InputMaybe<ServerServiceOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertStorageArgs = {
  object: ServerStorageInsertInput
  on_conflict?: InputMaybe<ServerStorageOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertStorageEmployeeGroupArgs = {
  object: ServerStorageEmployeeGroupInsertInput
  on_conflict?: InputMaybe<ServerStorageEmployeeGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertStorageEmployeeGroupsArgs = {
  objects: Array<ServerStorageEmployeeGroupInsertInput>
  on_conflict?: InputMaybe<ServerStorageEmployeeGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertStoragesArgs = {
  objects: Array<ServerStorageInsertInput>
  on_conflict?: InputMaybe<ServerStorageOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertSupersArgs = {
  objects: Array<ServerSuperInsertInput>
  on_conflict?: InputMaybe<ServerSuperOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertSupplierArgs = {
  object: ServerSupplierInsertInput
  on_conflict?: InputMaybe<ServerSupplierOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertSuppliersArgs = {
  objects: Array<ServerSupplierInsertInput>
  on_conflict?: InputMaybe<ServerSupplierOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTagArgs = {
  object: ServerTagInsertInput
  on_conflict?: InputMaybe<ServerTagOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTagsArgs = {
  objects: Array<ServerTagInsertInput>
  on_conflict?: InputMaybe<ServerTagOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTimeOffArgs = {
  object: ServerTimeOffInsertInput
  on_conflict?: InputMaybe<ServerTimeOffOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTimeOffReasonArgs = {
  object: ServerTimeOffReasonInsertInput
  on_conflict?: InputMaybe<ServerTimeOffReasonOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTimeOffReasonEmployeeGroupOneArgs = {
  object: ServerTimeOffReasonEmployeeGroupInsertInput
  on_conflict?: InputMaybe<ServerTimeOffReasonEmployeeGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTimeOffReasonEmployeeGroupsArgs = {
  objects: Array<ServerTimeOffReasonEmployeeGroupInsertInput>
  on_conflict?: InputMaybe<ServerTimeOffReasonEmployeeGroupOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTimeOffReasonsArgs = {
  objects: Array<ServerTimeOffReasonInsertInput>
  on_conflict?: InputMaybe<ServerTimeOffReasonOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTimeOffsArgs = {
  objects: Array<ServerTimeOffInsertInput>
  on_conflict?: InputMaybe<ServerTimeOffOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTransactionArgs = {
  object: ServerTransactionInsertInput
  on_conflict?: InputMaybe<ServerTransactionOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTransactionCardArgs = {
  object: ServerTransactionCardInsertInput
  on_conflict?: InputMaybe<ServerTransactionCardOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTransactionCardsArgs = {
  objects: Array<ServerTransactionCardInsertInput>
  on_conflict?: InputMaybe<ServerTransactionCardOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTransactionReceiptArgs = {
  object: ServerTransactionReceiptInsertInput
  on_conflict?: InputMaybe<ServerTransactionReceiptOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTransactionReceiptsArgs = {
  objects: Array<ServerTransactionReceiptInsertInput>
  on_conflict?: InputMaybe<ServerTransactionReceiptOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTransactionServiceArgs = {
  object: ServerTransactionServiceInsertInput
  on_conflict?: InputMaybe<ServerTransactionServiceOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTransactionServicesArgs = {
  objects: Array<ServerTransactionServiceInsertInput>
  on_conflict?: InputMaybe<ServerTransactionServiceOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertTransactionsArgs = {
  objects: Array<ServerTransactionInsertInput>
  on_conflict?: InputMaybe<ServerTransactionOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertUnitedBookingArgs = {
  object: ServerUnitedBookingInsertInput
  on_conflict?: InputMaybe<ServerUnitedBookingOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertUserExpoNotificationArgs = {
  object: ServerUserExpoNotificationInsertInput
  on_conflict?: InputMaybe<ServerUserExpoNotificationOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertUserExpoNotificationsArgs = {
  objects: Array<ServerUserExpoNotificationInsertInput>
  on_conflict?: InputMaybe<ServerUserExpoNotificationOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertWaitingListArgs = {
  object: ServerWaitingListInsertInput
  on_conflict?: InputMaybe<ServerWaitingListOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertWaitingListEmployeeArgs = {
  object: ServerWaitingListEmployeeInsertInput
  on_conflict?: InputMaybe<ServerWaitingListEmployeeOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertWaitingListsArgs = {
  objects: Array<ServerWaitingListInsertInput>
  on_conflict?: InputMaybe<ServerWaitingListOnConflict>
}

/** mutation root */
export type ServerMutationRootInsertWaitingListsEmployeesArgs = {
  objects: Array<ServerWaitingListEmployeeInsertInput>
  on_conflict?: InputMaybe<ServerWaitingListEmployeeOnConflict>
}

/** mutation root */
export type ServerMutationRootManipulateSuperAdminArgs = {
  type: ServerManipulateSuperAdminTypes
}

/** mutation root */
export type ServerMutationRootPaySalaryArgs = {
  accountId: Scalars['Int']
  employeeIds: Array<Scalars['Int']>
  endPeriod: Scalars['timestamptz']
  salaryRateIssues: Array<ServerSalaryRateIssue>
  startPeriod: Scalars['timestamptz']
}

/** mutation root */
export type ServerMutationRootSetCurrentBusinessArgs = {
  businessId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootSetCurrentBusinessAsClientArgs = {
  businessId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootStopEmployeeScheduleArgs = {
  endDate: Scalars['timestamptz']
  scheduleId: Scalars['Int']
}

/** mutation root */
export type ServerMutationRootUpdateAccountByIdArgs = {
  _inc?: InputMaybe<ServerAccountIncInput>
  _set?: InputMaybe<ServerAccountSetInput>
  pk_columns: ServerAccountPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateAccountsArgs = {
  _inc?: InputMaybe<ServerAccountIncInput>
  _set?: InputMaybe<ServerAccountSetInput>
  where: ServerAccountBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateAddressByIdArgs = {
  _set?: InputMaybe<ServerAddressSetInput>
  pk_columns: ServerAddressPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateAddressesArgs = {
  _set?: InputMaybe<ServerAddressSetInput>
  where: ServerAddressBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateBankDetailsByIdArgs = {
  _inc?: InputMaybe<ServerBankDetailsIncInput>
  _set?: InputMaybe<ServerBankDetailsSetInput>
  pk_columns: ServerBankDetailsPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateBanksDetailsArgs = {
  _inc?: InputMaybe<ServerBankDetailsIncInput>
  _set?: InputMaybe<ServerBankDetailsSetInput>
  where: ServerBankDetailsBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateBookingByIdArgs = {
  _inc?: InputMaybe<ServerBookingIncInput>
  _set?: InputMaybe<ServerBookingSetInput>
  pk_columns: ServerBookingPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateBookingClientSettingsArgs = {
  _inc?: InputMaybe<ServerBookingClientSettingsIncInput>
  _set?: InputMaybe<ServerBookingClientSettingsSetInput>
  where: ServerBookingClientSettingsBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateBookingClientSettingsByIdArgs = {
  _inc?: InputMaybe<ServerBookingClientSettingsIncInput>
  _set?: InputMaybe<ServerBookingClientSettingsSetInput>
  pk_columns: ServerBookingClientSettingsPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateBookingProductByIdArgs = {
  _inc?: InputMaybe<ServerBookingProductIncInput>
  _set?: InputMaybe<ServerBookingProductSetInput>
  pk_columns: ServerBookingProductPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateBookingProductsArgs = {
  _inc?: InputMaybe<ServerBookingProductIncInput>
  _set?: InputMaybe<ServerBookingProductSetInput>
  where: ServerBookingProductBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateBookingStatusesArgs = {
  _inc?: InputMaybe<ServerBookingStatusViewIncInput>
  _set?: InputMaybe<ServerBookingStatusViewSetInput>
  where: ServerBookingStatusViewBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateBookingsArgs = {
  _inc?: InputMaybe<ServerBookingIncInput>
  _set?: InputMaybe<ServerBookingSetInput>
  where: ServerBookingBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateBranchByIdArgs = {
  _append?: InputMaybe<ServerBranchAppendInput>
  _delete_at_path?: InputMaybe<ServerBranchDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerBranchDeleteElemInput>
  _delete_key?: InputMaybe<ServerBranchDeleteKeyInput>
  _inc?: InputMaybe<ServerBranchIncInput>
  _prepend?: InputMaybe<ServerBranchPrependInput>
  _set?: InputMaybe<ServerBranchSetInput>
  pk_columns: ServerBranchPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateBranchesArgs = {
  _append?: InputMaybe<ServerBranchAppendInput>
  _delete_at_path?: InputMaybe<ServerBranchDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerBranchDeleteElemInput>
  _delete_key?: InputMaybe<ServerBranchDeleteKeyInput>
  _inc?: InputMaybe<ServerBranchIncInput>
  _prepend?: InputMaybe<ServerBranchPrependInput>
  _set?: InputMaybe<ServerBranchSetInput>
  where: ServerBranchBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateBusinessByIdArgs = {
  _inc?: InputMaybe<ServerBusinessIncInput>
  _set?: InputMaybe<ServerBusinessSetInput>
  pk_columns: ServerBusinessPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateBusinessIsTestViewArgs = {
  _set?: InputMaybe<ServerBusinessIsTestViewSetInput>
  where: ServerBusinessIsTestViewBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateBusinessLandingByIdArgs = {
  _append?: InputMaybe<ServerBusinessLandingAppendInput>
  _delete_at_path?: InputMaybe<ServerBusinessLandingDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerBusinessLandingDeleteElemInput>
  _delete_key?: InputMaybe<ServerBusinessLandingDeleteKeyInput>
  _prepend?: InputMaybe<ServerBusinessLandingPrependInput>
  _set?: InputMaybe<ServerBusinessLandingSetInput>
  pk_columns: ServerBusinessLandingPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateBusinessLandingsArgs = {
  _append?: InputMaybe<ServerBusinessLandingAppendInput>
  _delete_at_path?: InputMaybe<ServerBusinessLandingDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerBusinessLandingDeleteElemInput>
  _delete_key?: InputMaybe<ServerBusinessLandingDeleteKeyInput>
  _prepend?: InputMaybe<ServerBusinessLandingPrependInput>
  _set?: InputMaybe<ServerBusinessLandingSetInput>
  where: ServerBusinessLandingBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateBusinessSettingsByIdArgs = {
  _append?: InputMaybe<ServerBusinessSettingsAppendInput>
  _delete_at_path?: InputMaybe<ServerBusinessSettingsDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerBusinessSettingsDeleteElemInput>
  _delete_key?: InputMaybe<ServerBusinessSettingsDeleteKeyInput>
  _prepend?: InputMaybe<ServerBusinessSettingsPrependInput>
  _set?: InputMaybe<ServerBusinessSettingsSetInput>
  pk_columns: ServerBusinessSettingsPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateBusinessesArgs = {
  _inc?: InputMaybe<ServerBusinessIncInput>
  _set?: InputMaybe<ServerBusinessSetInput>
  where: ServerBusinessBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateCardByIdArgs = {
  _inc?: InputMaybe<ServerCardIncInput>
  _set?: InputMaybe<ServerCardSetInput>
  pk_columns: ServerCardPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateCardTemplateByIdArgs = {
  _inc?: InputMaybe<ServerCardTemplateIncInput>
  _set?: InputMaybe<ServerCardTemplateSetInput>
  pk_columns: ServerCardTemplatePkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateCardTemplateServiceByIdArgs = {
  _inc?: InputMaybe<ServerCardTemplateServiceIncInput>
  _set?: InputMaybe<ServerCardTemplateServiceSetInput>
  pk_columns: ServerCardTemplateServicePkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateCardTemplateServicesArgs = {
  _inc?: InputMaybe<ServerCardTemplateServiceIncInput>
  _set?: InputMaybe<ServerCardTemplateServiceSetInput>
  where: ServerCardTemplateServiceBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateCardTemplatesArgs = {
  _inc?: InputMaybe<ServerCardTemplateIncInput>
  _set?: InputMaybe<ServerCardTemplateSetInput>
  where: ServerCardTemplateBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateCardTemplatesNameArgs = {
  _set?: InputMaybe<ServerCardTemplateNameViewSetInput>
  where: ServerCardTemplateNameViewBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateCardsArgs = {
  _inc?: InputMaybe<ServerCardIncInput>
  _set?: InputMaybe<ServerCardSetInput>
  where: ServerCardBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateClientByIdArgs = {
  _inc?: InputMaybe<ServerClientIncInput>
  _set?: InputMaybe<ServerClientSetInput>
  pk_columns: ServerClientPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateClientDocumentByIdArgs = {
  _inc?: InputMaybe<ServerClientDocumentIncInput>
  _set?: InputMaybe<ServerClientDocumentSetInput>
  pk_columns: ServerClientDocumentPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateClientDocumentsArgs = {
  _inc?: InputMaybe<ServerClientDocumentIncInput>
  _set?: InputMaybe<ServerClientDocumentSetInput>
  where: ServerClientDocumentBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateClientEmailsViewArgs = {
  _set?: InputMaybe<ServerClientEmailViewSetInput>
  where: ServerClientEmailViewBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateClientNoteByIdArgs = {
  _set?: InputMaybe<ServerClientNoteSetInput>
  pk_columns: ServerClientNotePkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateClientNotesArgs = {
  _set?: InputMaybe<ServerClientNoteSetInput>
  where: ServerClientNoteBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateClientRelationByIdArgs = {
  _inc?: InputMaybe<ServerClientRelationIncInput>
  _set?: InputMaybe<ServerClientRelationSetInput>
  pk_columns: ServerClientRelationPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateClientRelationsArgs = {
  _inc?: InputMaybe<ServerClientRelationIncInput>
  _set?: InputMaybe<ServerClientRelationSetInput>
  where: ServerClientRelationBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateClientSettingsByIdArgs = {
  _append?: InputMaybe<ServerClientSettingsAppendInput>
  _delete_at_path?: InputMaybe<ServerClientSettingsDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerClientSettingsDeleteElemInput>
  _delete_key?: InputMaybe<ServerClientSettingsDeleteKeyInput>
  _prepend?: InputMaybe<ServerClientSettingsPrependInput>
  _set?: InputMaybe<ServerClientSettingsSetInput>
  pk_columns: ServerClientSettingsPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateClientTagByIdArgs = {
  _inc?: InputMaybe<ServerClientTagIncInput>
  _set?: InputMaybe<ServerClientTagSetInput>
  pk_columns: ServerClientTagPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateClientTagsArgs = {
  _inc?: InputMaybe<ServerClientTagIncInput>
  _set?: InputMaybe<ServerClientTagSetInput>
  where: ServerClientTagBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateClientsArgs = {
  _inc?: InputMaybe<ServerClientIncInput>
  _set?: InputMaybe<ServerClientSetInput>
  where: ServerClientBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateClientsSettingsArgs = {
  _append?: InputMaybe<ServerClientSettingsAppendInput>
  _delete_at_path?: InputMaybe<ServerClientSettingsDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerClientSettingsDeleteElemInput>
  _delete_key?: InputMaybe<ServerClientSettingsDeleteKeyInput>
  _prepend?: InputMaybe<ServerClientSettingsPrependInput>
  _set?: InputMaybe<ServerClientSettingsSetInput>
  where: ServerClientSettingsBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateDynamicScheduleDateByIdArgs = {
  _inc?: InputMaybe<ServerDynamicScheduleDateIncInput>
  _set?: InputMaybe<ServerDynamicScheduleDateSetInput>
  pk_columns: ServerDynamicScheduleDatePkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateDynamicScheduleDatesArgs = {
  _inc?: InputMaybe<ServerDynamicScheduleDateIncInput>
  _set?: InputMaybe<ServerDynamicScheduleDateSetInput>
  where: ServerDynamicScheduleDateBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeBusinessOwnersArgs = {
  _set?: InputMaybe<ServerEmployeeBusinessOwnerViewSetInput>
  where: ServerEmployeeBusinessOwnerViewBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeByIdArgs = {
  _inc?: InputMaybe<ServerEmployeeIncInput>
  _set?: InputMaybe<ServerEmployeeSetInput>
  pk_columns: ServerEmployeePkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeEmailsArgs = {
  _set?: InputMaybe<ServerEmployeeEmailViewSetInput>
  where: ServerEmployeeEmailViewBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeGroupArchivesArgs = {
  _set?: InputMaybe<ServerEmployeeGroupArchiveViewSetInput>
  where: ServerEmployeeGroupArchiveViewBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeGroupByIdArgs = {
  _inc?: InputMaybe<ServerEmployeeGroupIncInput>
  _set?: InputMaybe<ServerEmployeeGroupSetInput>
  pk_columns: ServerEmployeeGroupPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeGroupOwnerViewArgs = {
  _inc?: InputMaybe<ServerEmployeeGroupOwnerViewIncInput>
  _set?: InputMaybe<ServerEmployeeGroupOwnerViewSetInput>
  where: ServerEmployeeGroupOwnerViewBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeGroupOwnerViewManyArgs = {
  updates: Array<ServerEmployeeGroupOwnerViewUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeGroupsArgs = {
  _inc?: InputMaybe<ServerEmployeeGroupIncInput>
  _set?: InputMaybe<ServerEmployeeGroupSetInput>
  where: ServerEmployeeGroupBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeSettingsByIdArgs = {
  _append?: InputMaybe<ServerEmployeeSettingsAppendInput>
  _delete_at_path?: InputMaybe<ServerEmployeeSettingsDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerEmployeeSettingsDeleteElemInput>
  _delete_key?: InputMaybe<ServerEmployeeSettingsDeleteKeyInput>
  _prepend?: InputMaybe<ServerEmployeeSettingsPrependInput>
  _set?: InputMaybe<ServerEmployeeSettingsSetInput>
  pk_columns: ServerEmployeeSettingsPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateEmployeesArgs = {
  _inc?: InputMaybe<ServerEmployeeIncInput>
  _set?: InputMaybe<ServerEmployeeSetInput>
  where: ServerEmployeeBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateExpaneInfoByIdArgs = {
  _inc?: InputMaybe<ServerExpaneInfoIncInput>
  _set?: InputMaybe<ServerExpaneInfoSetInput>
  pk_columns: ServerExpaneInfoPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateExpaneInfoCategoriesArgs = {
  _set?: InputMaybe<ServerExpaneInfoCategorySetInput>
  where: ServerExpaneInfoCategoryBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateExpaneInfoCategoryByIdArgs = {
  _set?: InputMaybe<ServerExpaneInfoCategorySetInput>
  pk_columns: ServerExpaneInfoCategoryPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateExpaneInfosArgs = {
  _inc?: InputMaybe<ServerExpaneInfoIncInput>
  _set?: InputMaybe<ServerExpaneInfoSetInput>
  where: ServerExpaneInfoBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateExpensesReasonByIdArgs = {
  _set?: InputMaybe<ServerExpensesReasonSetInput>
  pk_columns: ServerExpensesReasonPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateExpensesReasonsArgs = {
  _set?: InputMaybe<ServerExpensesReasonSetInput>
  where: ServerExpensesReasonBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateHumanByIdArgs = {
  _inc?: InputMaybe<ServerHumanIncInput>
  _set?: InputMaybe<ServerHumanSetInput>
  pk_columns: ServerHumanPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateHumansArgs = {
  _inc?: InputMaybe<ServerHumanIncInput>
  _set?: InputMaybe<ServerHumanSetInput>
  where: ServerHumanBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateInventoriesArgs = {
  _set?: InputMaybe<ServerInventorySetInput>
  where: ServerInventoryBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateInventoryByIdArgs = {
  _set?: InputMaybe<ServerInventorySetInput>
  pk_columns: ServerInventoryPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateLeadByIdArgs = {
  _set?: InputMaybe<ServerLeadSetInput>
  pk_columns: ServerLeadPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateLeadsArgs = {
  _set?: InputMaybe<ServerLeadSetInput>
  where: ServerLeadBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateLocationByIdArgs = {
  _inc?: InputMaybe<ServerLocationIncInput>
  _set?: InputMaybe<ServerLocationSetInput>
  pk_columns: ServerLocationPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateLocationGroupByIdArgs = {
  _set?: InputMaybe<ServerLocationGroupSetInput>
  pk_columns: ServerLocationGroupPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateLocationsArgs = {
  _inc?: InputMaybe<ServerLocationIncInput>
  _set?: InputMaybe<ServerLocationSetInput>
  where: ServerLocationBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateManyBookingClientSettingsArgs = {
  updates: Array<ServerBookingClientSettingsUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyBusinessIsTestViewArgs = {
  updates: Array<ServerBusinessIsTestViewUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyBusinessLandingsArgs = {
  updates: Array<ServerBusinessLandingUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyClientEmailsViewArgs = {
  updates: Array<ServerClientEmailViewUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyDynamicScheduleDatesArgs = {
  updates: Array<ServerDynamicScheduleDateUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyExpaneInfoCategoriesArgs = {
  updates: Array<ServerExpaneInfoCategoryUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyExpaneInfosArgs = {
  updates: Array<ServerExpaneInfoUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyOnlineBookingUrLsArgs = {
  updates: Array<ServerOnlineBookingUrlUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyProductGroupsArgs = {
  updates: Array<ServerProductGroupUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyPromoCodesArgs = {
  updates: Array<ServerPromoCodeUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyPromoCodesReferrerViewArgs = {
  updates: Array<ServerPromoCodeReferrerViewUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyReferrersArgs = {
  updates: Array<ServerReferrerUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyReviewsArgs = {
  updates: Array<ServerReviewUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManyServiceGroupsArgs = {
  updates: Array<ServerServiceGroupUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateManySupersArgs = {
  updates: Array<ServerSuperUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateModulesSettingsArgs = {
  modules: ServerModulesSettingsInput
  type: ServerUpdateModulesSettingsTypes
}

/** mutation root */
export type ServerMutationRootUpdateMovementByIdArgs = {
  _set?: InputMaybe<ServerMovementSetInput>
  pk_columns: ServerMovementPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateMovementsArgs = {
  _set?: InputMaybe<ServerMovementSetInput>
  where: ServerMovementBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateMultipleBusinessSettingsArgs = {
  _append?: InputMaybe<ServerBusinessSettingsAppendInput>
  _delete_at_path?: InputMaybe<ServerBusinessSettingsDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerBusinessSettingsDeleteElemInput>
  _delete_key?: InputMaybe<ServerBusinessSettingsDeleteKeyInput>
  _prepend?: InputMaybe<ServerBusinessSettingsPrependInput>
  _set?: InputMaybe<ServerBusinessSettingsSetInput>
  where: ServerBusinessSettingsBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateMultipleEmployeeSettingsArgs = {
  _append?: InputMaybe<ServerEmployeeSettingsAppendInput>
  _delete_at_path?: InputMaybe<ServerEmployeeSettingsDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerEmployeeSettingsDeleteElemInput>
  _delete_key?: InputMaybe<ServerEmployeeSettingsDeleteKeyInput>
  _prepend?: InputMaybe<ServerEmployeeSettingsPrependInput>
  _set?: InputMaybe<ServerEmployeeSettingsSetInput>
  where: ServerEmployeeSettingsBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateMultipleLocationGroupsArgs = {
  _set?: InputMaybe<ServerLocationGroupSetInput>
  where: ServerLocationGroupBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateMultipleSalarySettingArgs = {
  _set?: InputMaybe<ServerSalarySettingSetInput>
  where: ServerSalarySettingBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateNotificationByIdArgs = {
  _inc?: InputMaybe<ServerNotificationIncInput>
  _set?: InputMaybe<ServerNotificationSetInput>
  pk_columns: ServerNotificationPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateNotificationsArgs = {
  _inc?: InputMaybe<ServerNotificationIncInput>
  _set?: InputMaybe<ServerNotificationSetInput>
  where: ServerNotificationBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateOnlineBookingUrlByIdArgs = {
  _inc?: InputMaybe<ServerOnlineBookingUrlIncInput>
  _set?: InputMaybe<ServerOnlineBookingUrlSetInput>
  pk_columns: ServerOnlineBookingUrlPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateOnlineBookingUrLsArgs = {
  _inc?: InputMaybe<ServerOnlineBookingUrlIncInput>
  _set?: InputMaybe<ServerOnlineBookingUrlSetInput>
  where: ServerOnlineBookingUrlBoolExp
}

/** mutation root */
export type ServerMutationRootUpdatePosArgs = {
  accounts?: InputMaybe<Array<Scalars['Int']>>
  branchId: Scalars['Int']
  licenseKey?: InputMaybe<Scalars['String']>
  login?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  softwarePOSId?: InputMaybe<Scalars['Int']>
  type: ServerUpdatePosTypes
}

/** mutation root */
export type ServerMutationRootUpdateProductArchivesArgs = {
  _set?: InputMaybe<ServerProductArchiveViewSetInput>
  where: ServerProductArchiveViewBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateProductByIdArgs = {
  _inc?: InputMaybe<ServerProductIncInput>
  _set?: InputMaybe<ServerProductSetInput>
  pk_columns: ServerProductPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateProductGroupArchivesArgs = {
  _set?: InputMaybe<ServerProductGroupArchiveViewSetInput>
  where: ServerProductGroupArchiveViewBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateProductGroupByIdArgs = {
  _inc?: InputMaybe<ServerProductGroupIncInput>
  _set?: InputMaybe<ServerProductGroupSetInput>
  pk_columns: ServerProductGroupPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateProductGroupsArgs = {
  _inc?: InputMaybe<ServerProductGroupIncInput>
  _set?: InputMaybe<ServerProductGroupSetInput>
  where: ServerProductGroupBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateProductsArgs = {
  _inc?: InputMaybe<ServerProductIncInput>
  _set?: InputMaybe<ServerProductSetInput>
  where: ServerProductBoolExp
}

/** mutation root */
export type ServerMutationRootUpdatePromoCodeByIdArgs = {
  _set?: InputMaybe<ServerPromoCodeSetInput>
  pk_columns: ServerPromoCodePkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdatePromoCodesArgs = {
  _set?: InputMaybe<ServerPromoCodeSetInput>
  where: ServerPromoCodeBoolExp
}

/** mutation root */
export type ServerMutationRootUpdatePromoCodesReferrerViewArgs = {
  _set?: InputMaybe<ServerPromoCodeReferrerViewSetInput>
  where: ServerPromoCodeReferrerViewBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateReferrerByIdArgs = {
  _inc?: InputMaybe<ServerReferrerIncInput>
  _set?: InputMaybe<ServerReferrerSetInput>
  pk_columns: ServerReferrerPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateReferrersArgs = {
  _inc?: InputMaybe<ServerReferrerIncInput>
  _set?: InputMaybe<ServerReferrerSetInput>
  where: ServerReferrerBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateRevenueReasonByIdArgs = {
  _set?: InputMaybe<ServerRevenueReasonSetInput>
  pk_columns: ServerRevenueReasonPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateRevenueReasonsArgs = {
  _set?: InputMaybe<ServerRevenueReasonSetInput>
  where: ServerRevenueReasonBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateReviewByIdArgs = {
  _set?: InputMaybe<ServerReviewSetInput>
  pk_columns: ServerReviewPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateReviewsArgs = {
  _set?: InputMaybe<ServerReviewSetInput>
  where: ServerReviewBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateSalarySettingByIdArgs = {
  _set?: InputMaybe<ServerSalarySettingSetInput>
  pk_columns: ServerSalarySettingPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateScheduleByIdArgs = {
  _append?: InputMaybe<ServerScheduleAppendInput>
  _delete_at_path?: InputMaybe<ServerScheduleDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerScheduleDeleteElemInput>
  _delete_key?: InputMaybe<ServerScheduleDeleteKeyInput>
  _inc?: InputMaybe<ServerScheduleIncInput>
  _prepend?: InputMaybe<ServerSchedulePrependInput>
  _set?: InputMaybe<ServerScheduleSetInput>
  pk_columns: ServerSchedulePkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateSchedulesArgs = {
  _append?: InputMaybe<ServerScheduleAppendInput>
  _delete_at_path?: InputMaybe<ServerScheduleDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerScheduleDeleteElemInput>
  _delete_key?: InputMaybe<ServerScheduleDeleteKeyInput>
  _inc?: InputMaybe<ServerScheduleIncInput>
  _prepend?: InputMaybe<ServerSchedulePrependInput>
  _set?: InputMaybe<ServerScheduleSetInput>
  where: ServerScheduleBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateServiceByIdArgs = {
  _inc?: InputMaybe<ServerServiceIncInput>
  _set?: InputMaybe<ServerServiceSetInput>
  pk_columns: ServerServicePkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateServiceEmployeeByIdArgs = {
  _inc?: InputMaybe<ServerServiceEmployeeIncInput>
  _set?: InputMaybe<ServerServiceEmployeeSetInput>
  pk_columns: ServerServiceEmployeePkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateServiceEmployeesArgs = {
  _inc?: InputMaybe<ServerServiceEmployeeIncInput>
  _set?: InputMaybe<ServerServiceEmployeeSetInput>
  where: ServerServiceEmployeeBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateServiceGroupByIdArgs = {
  _inc?: InputMaybe<ServerServiceGroupIncInput>
  _set?: InputMaybe<ServerServiceGroupSetInput>
  pk_columns: ServerServiceGroupPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateServiceGroupsArgs = {
  _inc?: InputMaybe<ServerServiceGroupIncInput>
  _set?: InputMaybe<ServerServiceGroupSetInput>
  where: ServerServiceGroupBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateServiceLocationByIdArgs = {
  _inc?: InputMaybe<ServerServiceLocationIncInput>
  _set?: InputMaybe<ServerServiceLocationSetInput>
  pk_columns: ServerServiceLocationPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateServiceLocationsArgs = {
  _inc?: InputMaybe<ServerServiceLocationIncInput>
  _set?: InputMaybe<ServerServiceLocationSetInput>
  where: ServerServiceLocationBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateServicePermissionByIdArgs = {
  _inc?: InputMaybe<ServerServicePermissionIncInput>
  _set?: InputMaybe<ServerServicePermissionSetInput>
  pk_columns: ServerServicePermissionPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateServicePermissionsArgs = {
  _inc?: InputMaybe<ServerServicePermissionIncInput>
  _set?: InputMaybe<ServerServicePermissionSetInput>
  where: ServerServicePermissionBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateServiceProductByIdArgs = {
  _inc?: InputMaybe<ServerServiceProductIncInput>
  _set?: InputMaybe<ServerServiceProductSetInput>
  pk_columns: ServerServiceProductPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateServiceProductsArgs = {
  _inc?: InputMaybe<ServerServiceProductIncInput>
  _set?: InputMaybe<ServerServiceProductSetInput>
  where: ServerServiceProductBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateServicesArgs = {
  _inc?: InputMaybe<ServerServiceIncInput>
  _set?: InputMaybe<ServerServiceSetInput>
  where: ServerServiceBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateStorageByIdArgs = {
  _inc?: InputMaybe<ServerStorageIncInput>
  _set?: InputMaybe<ServerStorageSetInput>
  pk_columns: ServerStoragePkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateStoragesArgs = {
  _inc?: InputMaybe<ServerStorageIncInput>
  _set?: InputMaybe<ServerStorageSetInput>
  where: ServerStorageBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateSuperByIdArgs = {
  _set?: InputMaybe<ServerSuperSetInput>
  pk_columns: ServerSuperPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateSupersArgs = {
  _set?: InputMaybe<ServerSuperSetInput>
  where: ServerSuperBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateSuppliersArgs = {
  _inc?: InputMaybe<ServerSupplierIncInput>
  _set?: InputMaybe<ServerSupplierSetInput>
  where: ServerSupplierBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateSuppliersByIdArgs = {
  _inc?: InputMaybe<ServerSupplierIncInput>
  _set?: InputMaybe<ServerSupplierSetInput>
  pk_columns: ServerSupplierPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateTagByIdArgs = {
  _set?: InputMaybe<ServerTagSetInput>
  pk_columns: ServerTagPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateTagsArgs = {
  _set?: InputMaybe<ServerTagSetInput>
  where: ServerTagBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateTimeOffByIdArgs = {
  _inc?: InputMaybe<ServerTimeOffIncInput>
  _set?: InputMaybe<ServerTimeOffSetInput>
  pk_columns: ServerTimeOffPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateTimeOffReasonByIdArgs = {
  _set?: InputMaybe<ServerTimeOffReasonSetInput>
  pk_columns: ServerTimeOffReasonPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateTimeOffReasonsArgs = {
  _set?: InputMaybe<ServerTimeOffReasonSetInput>
  where: ServerTimeOffReasonBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateTimeOffsArgs = {
  _inc?: InputMaybe<ServerTimeOffIncInput>
  _set?: InputMaybe<ServerTimeOffSetInput>
  where: ServerTimeOffBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateTransactionCardByIdArgs = {
  _inc?: InputMaybe<ServerTransactionCardIncInput>
  _set?: InputMaybe<ServerTransactionCardSetInput>
  pk_columns: ServerTransactionCardPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateTransactionCardsArgs = {
  _inc?: InputMaybe<ServerTransactionCardIncInput>
  _set?: InputMaybe<ServerTransactionCardSetInput>
  where: ServerTransactionCardBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateUserExpoNotificationArgs = {
  _set?: InputMaybe<ServerUserExpoNotificationSetInput>
  where: ServerUserExpoNotificationBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateUserExpoNotificationByEmailArgs = {
  _set?: InputMaybe<ServerUserExpoNotificationSetInput>
  pk_columns: ServerUserExpoNotificationPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateWaitingListByIdArgs = {
  _append?: InputMaybe<ServerWaitingListAppendInput>
  _delete_at_path?: InputMaybe<ServerWaitingListDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerWaitingListDeleteElemInput>
  _delete_key?: InputMaybe<ServerWaitingListDeleteKeyInput>
  _inc?: InputMaybe<ServerWaitingListIncInput>
  _prepend?: InputMaybe<ServerWaitingListPrependInput>
  _set?: InputMaybe<ServerWaitingListSetInput>
  pk_columns: ServerWaitingListPkColumnsInput
}

/** mutation root */
export type ServerMutationRootUpdateWaitingListsArgs = {
  _append?: InputMaybe<ServerWaitingListAppendInput>
  _delete_at_path?: InputMaybe<ServerWaitingListDeleteAtPathInput>
  _delete_elem?: InputMaybe<ServerWaitingListDeleteElemInput>
  _delete_key?: InputMaybe<ServerWaitingListDeleteKeyInput>
  _inc?: InputMaybe<ServerWaitingListIncInput>
  _prepend?: InputMaybe<ServerWaitingListPrependInput>
  _set?: InputMaybe<ServerWaitingListSetInput>
  where: ServerWaitingListBoolExp
}

/** mutation root */
export type ServerMutationRootUpdateAccountManyArgs = {
  updates: Array<ServerAccountUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateAddressManyArgs = {
  updates: Array<ServerAddressUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateBankDetailsManyArgs = {
  updates: Array<ServerBankDetailsUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateBookingProductManyArgs = {
  updates: Array<ServerBookingProductUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateBookingStatusViewManyArgs = {
  updates: Array<ServerBookingStatusViewUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateBookingManyArgs = {
  updates: Array<ServerBookingUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateBranchManyArgs = {
  updates: Array<ServerBranchUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateBusinessSettingsManyArgs = {
  updates: Array<ServerBusinessSettingsUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateBusinessManyArgs = {
  updates: Array<ServerBusinessUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateCardTemplateNameViewManyArgs = {
  updates: Array<ServerCardTemplateNameViewUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateCardTemplateServiceManyArgs = {
  updates: Array<ServerCardTemplateServiceUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateCardTemplateManyArgs = {
  updates: Array<ServerCardTemplateUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateCardManyArgs = {
  updates: Array<ServerCardUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateClientDocumentManyArgs = {
  updates: Array<ServerClientDocumentUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateClientNoteManyArgs = {
  updates: Array<ServerClientNoteUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateClientRelationManyArgs = {
  updates: Array<ServerClientRelationUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateClientSettingsManyArgs = {
  updates: Array<ServerClientSettingsUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateClientTagManyArgs = {
  updates: Array<ServerClientTagUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateClientManyArgs = {
  updates: Array<ServerClientUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeBusinessOwnerViewManyArgs = {
  updates: Array<ServerEmployeeBusinessOwnerViewUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeEmailViewManyArgs = {
  updates: Array<ServerEmployeeEmailViewUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeGroupArchiveViewManyArgs = {
  updates: Array<ServerEmployeeGroupArchiveViewUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeGroupManyArgs = {
  updates: Array<ServerEmployeeGroupUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeSettingsManyArgs = {
  updates: Array<ServerEmployeeSettingsUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateEmployeeManyArgs = {
  updates: Array<ServerEmployeeUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateExpensesReasonManyArgs = {
  updates: Array<ServerExpensesReasonUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateHumanManyArgs = {
  updates: Array<ServerHumanUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateInventoryManyArgs = {
  updates: Array<ServerInventoryUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateLeadManyArgs = {
  updates: Array<ServerLeadUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateLocationGroupManyArgs = {
  updates: Array<ServerLocationGroupUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateLocationManyArgs = {
  updates: Array<ServerLocationUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateMovementManyArgs = {
  updates: Array<ServerMovementUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateNotificationManyArgs = {
  updates: Array<ServerNotificationUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateProductArchiveViewManyArgs = {
  updates: Array<ServerProductArchiveViewUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateProductGroupArchiveViewManyArgs = {
  updates: Array<ServerProductGroupArchiveViewUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateProductManyArgs = {
  updates: Array<ServerProductUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateRevenueReasonManyArgs = {
  updates: Array<ServerRevenueReasonUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateSalarySettingManyArgs = {
  updates: Array<ServerSalarySettingUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateScheduleManyArgs = {
  updates: Array<ServerScheduleUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateServiceEmployeeManyArgs = {
  updates: Array<ServerServiceEmployeeUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateServiceLocationManyArgs = {
  updates: Array<ServerServiceLocationUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateServicePermissionManyArgs = {
  updates: Array<ServerServicePermissionUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateServiceProductManyArgs = {
  updates: Array<ServerServiceProductUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateServiceManyArgs = {
  updates: Array<ServerServiceUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateStorageManyArgs = {
  updates: Array<ServerStorageUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateSupplierManyArgs = {
  updates: Array<ServerSupplierUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateTagManyArgs = {
  updates: Array<ServerTagUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateTimeOffReasonManyArgs = {
  updates: Array<ServerTimeOffReasonUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateTimeOffManyArgs = {
  updates: Array<ServerTimeOffUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateTransactionCardManyArgs = {
  updates: Array<ServerTransactionCardUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateUserExpoNotificationManyArgs = {
  updates: Array<ServerUserExpoNotificationUpdates>
}

/** mutation root */
export type ServerMutationRootUpdateWaitingListManyArgs = {
  updates: Array<ServerWaitingListUpdates>
}

/** mutation root */
export type ServerMutationRootUploadFileArgs = {
  base64File: Scalars['String']
  isDocument?: InputMaybe<Scalars['Boolean']>
  withoutBusiness?: InputMaybe<Scalars['Boolean']>
}

/** columns and relationships of "notification" */
export type ServerNotification = {
  /** An object relationship */
  author: Maybe<ServerEmployee>
  authorId: Maybe<Scalars['Int']>
  /** An object relationship */
  booking: Maybe<ServerBooking>
  bookingId: Maybe<Scalars['Int']>
  /** An object relationship */
  client: Maybe<ServerClient>
  clientId: Maybe<Scalars['Int']>
  /** An object relationship */
  employee: Maybe<ServerEmployee>
  /** An object relationship */
  employeeGroup: Maybe<ServerEmployeeGroup>
  employeeGroupId: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  /** Статус для оповещений в моб приложении */
  expoStatus: Maybe<Scalars['Int']>
  id: Scalars['Int']
  message: Scalars['String']
  /** An object relationship */
  review: Maybe<ServerReview>
  reviewId: Maybe<Scalars['Int']>
  /** время отправки сообщения */
  sentAt: Maybe<Scalars['timestamptz']>
  /** Статус для оповещений через sms */
  smsStatus: Maybe<Scalars['Int']>
  /** Статус для внутренних оповещений */
  status: Maybe<Scalars['smallint']>
  /** Статус для оповещений в telegram */
  telegramStatus: Maybe<Scalars['Int']>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type: Scalars['smallint']
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation: Maybe<Scalars['smallint']>
  /** Статус для оповещений в viber */
  viberStatus: Maybe<Scalars['Int']>
  /** An object relationship */
  waitingList: Maybe<ServerWaitingList>
  waitingListId: Maybe<Scalars['Int']>
}

/** aggregated selection of "notification" */
export type ServerNotificationAggregate = {
  aggregate: Maybe<ServerNotificationAggregateFields>
  nodes: Array<ServerNotification>
}

export type ServerNotificationAggregateBoolExp = {
  count?: InputMaybe<ServerNotificationAggregateBoolExpCount>
}

export type ServerNotificationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServerNotificationSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerNotificationBoolExp>
  predicate: ServerIntComparisonExp
}

/** aggregate fields of "notification" */
export type ServerNotificationAggregateFields = {
  avg: Maybe<ServerNotificationAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerNotificationMaxFields>
  min: Maybe<ServerNotificationMinFields>
  stddev: Maybe<ServerNotificationStddevFields>
  stddev_pop: Maybe<ServerNotificationStddevPopFields>
  stddev_samp: Maybe<ServerNotificationStddevSampFields>
  sum: Maybe<ServerNotificationSumFields>
  var_pop: Maybe<ServerNotificationVarPopFields>
  var_samp: Maybe<ServerNotificationVarSampFields>
  variance: Maybe<ServerNotificationVarianceFields>
}

/** aggregate fields of "notification" */
export type ServerNotificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerNotificationSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "notification" */
export type ServerNotificationAggregateOrderBy = {
  avg?: InputMaybe<ServerNotificationAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerNotificationMaxOrderBy>
  min?: InputMaybe<ServerNotificationMinOrderBy>
  stddev?: InputMaybe<ServerNotificationStddevOrderBy>
  stddev_pop?: InputMaybe<ServerNotificationStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerNotificationStddevSampOrderBy>
  sum?: InputMaybe<ServerNotificationSumOrderBy>
  var_pop?: InputMaybe<ServerNotificationVarPopOrderBy>
  var_samp?: InputMaybe<ServerNotificationVarSampOrderBy>
  variance?: InputMaybe<ServerNotificationVarianceOrderBy>
}

/** input type for inserting array relation for remote table "notification" */
export type ServerNotificationArrRelInsertInput = {
  data: Array<ServerNotificationInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerNotificationOnConflict>
}

/** aggregate avg on columns */
export type ServerNotificationAvgFields = {
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeGroupId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  /** Статус для оповещений в моб приложении */
  expoStatus: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  reviewId: Maybe<Scalars['Float']>
  /** Статус для оповещений через sms */
  smsStatus: Maybe<Scalars['Float']>
  /** Статус для внутренних оповещений */
  status: Maybe<Scalars['Float']>
  /** Статус для оповещений в telegram */
  telegramStatus: Maybe<Scalars['Float']>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type: Maybe<Scalars['Float']>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation: Maybe<Scalars['Float']>
  /** Статус для оповещений в viber */
  viberStatus: Maybe<Scalars['Float']>
  waitingListId: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "notification" */
export type ServerNotificationAvgOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в моб приложении */
  expoStatus?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  reviewId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений через sms */
  smsStatus?: InputMaybe<ServerOrderBy>
  /** Статус для внутренних оповещений */
  status?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в telegram */
  telegramStatus?: InputMaybe<ServerOrderBy>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type?: InputMaybe<ServerOrderBy>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в viber */
  viberStatus?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type ServerNotificationBoolExp = {
  _and?: InputMaybe<Array<ServerNotificationBoolExp>>
  _not?: InputMaybe<ServerNotificationBoolExp>
  _or?: InputMaybe<Array<ServerNotificationBoolExp>>
  author?: InputMaybe<ServerEmployeeBoolExp>
  authorId?: InputMaybe<ServerIntComparisonExp>
  booking?: InputMaybe<ServerBookingBoolExp>
  bookingId?: InputMaybe<ServerIntComparisonExp>
  client?: InputMaybe<ServerClientBoolExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeGroup?: InputMaybe<ServerEmployeeGroupBoolExp>
  employeeGroupId?: InputMaybe<ServerIntComparisonExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  expoStatus?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  message?: InputMaybe<ServerStringComparisonExp>
  review?: InputMaybe<ServerReviewBoolExp>
  reviewId?: InputMaybe<ServerIntComparisonExp>
  sentAt?: InputMaybe<ServerTimestamptzComparisonExp>
  smsStatus?: InputMaybe<ServerIntComparisonExp>
  status?: InputMaybe<ServerSmallintComparisonExp>
  telegramStatus?: InputMaybe<ServerIntComparisonExp>
  type?: InputMaybe<ServerSmallintComparisonExp>
  typeVariation?: InputMaybe<ServerSmallintComparisonExp>
  viberStatus?: InputMaybe<ServerIntComparisonExp>
  waitingList?: InputMaybe<ServerWaitingListBoolExp>
  waitingListId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "notification" */
export enum ServerNotificationConstraint {
  /** unique or primary key constraint on columns "id" */
  NotificationPkey = 'notification_pkey',
}

/** input type for incrementing numeric columns in table "notification" */
export type ServerNotificationIncInput = {
  /** Статус для внутренних оповещений */
  status?: InputMaybe<Scalars['smallint']>
}

/** input type for inserting data into table "notification" */
export type ServerNotificationInsertInput = {
  author?: InputMaybe<ServerEmployeeObjRelInsertInput>
  booking?: InputMaybe<ServerBookingObjRelInsertInput>
  bookingId?: InputMaybe<Scalars['Int']>
  client?: InputMaybe<ServerClientObjRelInsertInput>
  clientId?: InputMaybe<Scalars['Int']>
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employeeGroup?: InputMaybe<ServerEmployeeGroupObjRelInsertInput>
  employeeGroupId?: InputMaybe<Scalars['Int']>
  employeeId?: InputMaybe<Scalars['Int']>
  message?: InputMaybe<Scalars['String']>
  review?: InputMaybe<ServerReviewObjRelInsertInput>
  /** Статус для оповещений через sms */
  smsStatus?: InputMaybe<Scalars['Int']>
  /** Статус для оповещений в telegram */
  telegramStatus?: InputMaybe<Scalars['Int']>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type?: InputMaybe<Scalars['smallint']>
  /** Статус для оповещений в viber */
  viberStatus?: InputMaybe<Scalars['Int']>
  waitingList?: InputMaybe<ServerWaitingListObjRelInsertInput>
  waitingListId?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type ServerNotificationMaxFields = {
  authorId: Maybe<Scalars['Int']>
  bookingId: Maybe<Scalars['Int']>
  clientId: Maybe<Scalars['Int']>
  employeeGroupId: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  /** Статус для оповещений в моб приложении */
  expoStatus: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  message: Maybe<Scalars['String']>
  reviewId: Maybe<Scalars['Int']>
  /** время отправки сообщения */
  sentAt: Maybe<Scalars['timestamptz']>
  /** Статус для оповещений через sms */
  smsStatus: Maybe<Scalars['Int']>
  /** Статус для внутренних оповещений */
  status: Maybe<Scalars['smallint']>
  /** Статус для оповещений в telegram */
  telegramStatus: Maybe<Scalars['Int']>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type: Maybe<Scalars['smallint']>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation: Maybe<Scalars['smallint']>
  /** Статус для оповещений в viber */
  viberStatus: Maybe<Scalars['Int']>
  waitingListId: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "notification" */
export type ServerNotificationMaxOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в моб приложении */
  expoStatus?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  message?: InputMaybe<ServerOrderBy>
  reviewId?: InputMaybe<ServerOrderBy>
  /** время отправки сообщения */
  sentAt?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений через sms */
  smsStatus?: InputMaybe<ServerOrderBy>
  /** Статус для внутренних оповещений */
  status?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в telegram */
  telegramStatus?: InputMaybe<ServerOrderBy>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type?: InputMaybe<ServerOrderBy>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в viber */
  viberStatus?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** aggregate min on columns */
export type ServerNotificationMinFields = {
  authorId: Maybe<Scalars['Int']>
  bookingId: Maybe<Scalars['Int']>
  clientId: Maybe<Scalars['Int']>
  employeeGroupId: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  /** Статус для оповещений в моб приложении */
  expoStatus: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  message: Maybe<Scalars['String']>
  reviewId: Maybe<Scalars['Int']>
  /** время отправки сообщения */
  sentAt: Maybe<Scalars['timestamptz']>
  /** Статус для оповещений через sms */
  smsStatus: Maybe<Scalars['Int']>
  /** Статус для внутренних оповещений */
  status: Maybe<Scalars['smallint']>
  /** Статус для оповещений в telegram */
  telegramStatus: Maybe<Scalars['Int']>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type: Maybe<Scalars['smallint']>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation: Maybe<Scalars['smallint']>
  /** Статус для оповещений в viber */
  viberStatus: Maybe<Scalars['Int']>
  waitingListId: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "notification" */
export type ServerNotificationMinOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в моб приложении */
  expoStatus?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  message?: InputMaybe<ServerOrderBy>
  reviewId?: InputMaybe<ServerOrderBy>
  /** время отправки сообщения */
  sentAt?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений через sms */
  smsStatus?: InputMaybe<ServerOrderBy>
  /** Статус для внутренних оповещений */
  status?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в telegram */
  telegramStatus?: InputMaybe<ServerOrderBy>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type?: InputMaybe<ServerOrderBy>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в viber */
  viberStatus?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "notification" */
export type ServerNotificationMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerNotification>
}

/** on_conflict condition type for table "notification" */
export type ServerNotificationOnConflict = {
  constraint: ServerNotificationConstraint
  update_columns?: Array<ServerNotificationUpdateColumn>
  where?: InputMaybe<ServerNotificationBoolExp>
}

/** Ordering options when selecting data from "notification". */
export type ServerNotificationOrderBy = {
  author?: InputMaybe<ServerEmployeeOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  booking?: InputMaybe<ServerBookingOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeGroup?: InputMaybe<ServerEmployeeGroupOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  expoStatus?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  message?: InputMaybe<ServerOrderBy>
  review?: InputMaybe<ServerReviewOrderBy>
  reviewId?: InputMaybe<ServerOrderBy>
  sentAt?: InputMaybe<ServerOrderBy>
  smsStatus?: InputMaybe<ServerOrderBy>
  status?: InputMaybe<ServerOrderBy>
  telegramStatus?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  typeVariation?: InputMaybe<ServerOrderBy>
  viberStatus?: InputMaybe<ServerOrderBy>
  waitingList?: InputMaybe<ServerWaitingListOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: notification */
export type ServerNotificationPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "notification" */
export enum ServerNotificationSelectColumn {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  BookingId = 'bookingId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  EmployeeGroupId = 'employeeGroupId',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  ExpoStatus = 'expoStatus',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ReviewId = 'reviewId',
  /** column name */
  SentAt = 'sentAt',
  /** column name */
  SmsStatus = 'smsStatus',
  /** column name */
  Status = 'status',
  /** column name */
  TelegramStatus = 'telegramStatus',
  /** column name */
  Type = 'type',
  /** column name */
  TypeVariation = 'typeVariation',
  /** column name */
  ViberStatus = 'viberStatus',
  /** column name */
  WaitingListId = 'waitingListId',
}

/** input type for updating data in table "notification" */
export type ServerNotificationSetInput = {
  /** Статус для внутренних оповещений */
  status?: InputMaybe<Scalars['smallint']>
}

/** aggregate stddev on columns */
export type ServerNotificationStddevFields = {
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeGroupId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  /** Статус для оповещений в моб приложении */
  expoStatus: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  reviewId: Maybe<Scalars['Float']>
  /** Статус для оповещений через sms */
  smsStatus: Maybe<Scalars['Float']>
  /** Статус для внутренних оповещений */
  status: Maybe<Scalars['Float']>
  /** Статус для оповещений в telegram */
  telegramStatus: Maybe<Scalars['Float']>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type: Maybe<Scalars['Float']>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation: Maybe<Scalars['Float']>
  /** Статус для оповещений в viber */
  viberStatus: Maybe<Scalars['Float']>
  waitingListId: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "notification" */
export type ServerNotificationStddevOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в моб приложении */
  expoStatus?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  reviewId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений через sms */
  smsStatus?: InputMaybe<ServerOrderBy>
  /** Статус для внутренних оповещений */
  status?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в telegram */
  telegramStatus?: InputMaybe<ServerOrderBy>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type?: InputMaybe<ServerOrderBy>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в viber */
  viberStatus?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_pop on columns */
export type ServerNotificationStddevPopFields = {
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeGroupId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  /** Статус для оповещений в моб приложении */
  expoStatus: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  reviewId: Maybe<Scalars['Float']>
  /** Статус для оповещений через sms */
  smsStatus: Maybe<Scalars['Float']>
  /** Статус для внутренних оповещений */
  status: Maybe<Scalars['Float']>
  /** Статус для оповещений в telegram */
  telegramStatus: Maybe<Scalars['Float']>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type: Maybe<Scalars['Float']>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation: Maybe<Scalars['Float']>
  /** Статус для оповещений в viber */
  viberStatus: Maybe<Scalars['Float']>
  waitingListId: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "notification" */
export type ServerNotificationStddevPopOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в моб приложении */
  expoStatus?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  reviewId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений через sms */
  smsStatus?: InputMaybe<ServerOrderBy>
  /** Статус для внутренних оповещений */
  status?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в telegram */
  telegramStatus?: InputMaybe<ServerOrderBy>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type?: InputMaybe<ServerOrderBy>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в viber */
  viberStatus?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_samp on columns */
export type ServerNotificationStddevSampFields = {
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeGroupId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  /** Статус для оповещений в моб приложении */
  expoStatus: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  reviewId: Maybe<Scalars['Float']>
  /** Статус для оповещений через sms */
  smsStatus: Maybe<Scalars['Float']>
  /** Статус для внутренних оповещений */
  status: Maybe<Scalars['Float']>
  /** Статус для оповещений в telegram */
  telegramStatus: Maybe<Scalars['Float']>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type: Maybe<Scalars['Float']>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation: Maybe<Scalars['Float']>
  /** Статус для оповещений в viber */
  viberStatus: Maybe<Scalars['Float']>
  waitingListId: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "notification" */
export type ServerNotificationStddevSampOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в моб приложении */
  expoStatus?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  reviewId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений через sms */
  smsStatus?: InputMaybe<ServerOrderBy>
  /** Статус для внутренних оповещений */
  status?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в telegram */
  telegramStatus?: InputMaybe<ServerOrderBy>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type?: InputMaybe<ServerOrderBy>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в viber */
  viberStatus?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** aggregate sum on columns */
export type ServerNotificationSumFields = {
  authorId: Maybe<Scalars['Int']>
  bookingId: Maybe<Scalars['Int']>
  clientId: Maybe<Scalars['Int']>
  employeeGroupId: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  /** Статус для оповещений в моб приложении */
  expoStatus: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  reviewId: Maybe<Scalars['Int']>
  /** Статус для оповещений через sms */
  smsStatus: Maybe<Scalars['Int']>
  /** Статус для внутренних оповещений */
  status: Maybe<Scalars['smallint']>
  /** Статус для оповещений в telegram */
  telegramStatus: Maybe<Scalars['Int']>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type: Maybe<Scalars['smallint']>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation: Maybe<Scalars['smallint']>
  /** Статус для оповещений в viber */
  viberStatus: Maybe<Scalars['Int']>
  waitingListId: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "notification" */
export type ServerNotificationSumOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в моб приложении */
  expoStatus?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  reviewId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений через sms */
  smsStatus?: InputMaybe<ServerOrderBy>
  /** Статус для внутренних оповещений */
  status?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в telegram */
  telegramStatus?: InputMaybe<ServerOrderBy>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type?: InputMaybe<ServerOrderBy>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в viber */
  viberStatus?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "notification" */
export enum ServerNotificationUpdateColumn {
  /** column name */
  Status = 'status',
}

export type ServerNotificationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerNotificationIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerNotificationSetInput>
  /** filter the rows which have to be updated */
  where: ServerNotificationBoolExp
}

/** aggregate var_pop on columns */
export type ServerNotificationVarPopFields = {
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeGroupId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  /** Статус для оповещений в моб приложении */
  expoStatus: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  reviewId: Maybe<Scalars['Float']>
  /** Статус для оповещений через sms */
  smsStatus: Maybe<Scalars['Float']>
  /** Статус для внутренних оповещений */
  status: Maybe<Scalars['Float']>
  /** Статус для оповещений в telegram */
  telegramStatus: Maybe<Scalars['Float']>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type: Maybe<Scalars['Float']>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation: Maybe<Scalars['Float']>
  /** Статус для оповещений в viber */
  viberStatus: Maybe<Scalars['Float']>
  waitingListId: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "notification" */
export type ServerNotificationVarPopOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в моб приложении */
  expoStatus?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  reviewId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений через sms */
  smsStatus?: InputMaybe<ServerOrderBy>
  /** Статус для внутренних оповещений */
  status?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в telegram */
  telegramStatus?: InputMaybe<ServerOrderBy>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type?: InputMaybe<ServerOrderBy>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в viber */
  viberStatus?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** aggregate var_samp on columns */
export type ServerNotificationVarSampFields = {
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeGroupId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  /** Статус для оповещений в моб приложении */
  expoStatus: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  reviewId: Maybe<Scalars['Float']>
  /** Статус для оповещений через sms */
  smsStatus: Maybe<Scalars['Float']>
  /** Статус для внутренних оповещений */
  status: Maybe<Scalars['Float']>
  /** Статус для оповещений в telegram */
  telegramStatus: Maybe<Scalars['Float']>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type: Maybe<Scalars['Float']>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation: Maybe<Scalars['Float']>
  /** Статус для оповещений в viber */
  viberStatus: Maybe<Scalars['Float']>
  waitingListId: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "notification" */
export type ServerNotificationVarSampOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в моб приложении */
  expoStatus?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  reviewId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений через sms */
  smsStatus?: InputMaybe<ServerOrderBy>
  /** Статус для внутренних оповещений */
  status?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в telegram */
  telegramStatus?: InputMaybe<ServerOrderBy>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type?: InputMaybe<ServerOrderBy>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в viber */
  viberStatus?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** aggregate variance on columns */
export type ServerNotificationVarianceFields = {
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeGroupId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  /** Статус для оповещений в моб приложении */
  expoStatus: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  reviewId: Maybe<Scalars['Float']>
  /** Статус для оповещений через sms */
  smsStatus: Maybe<Scalars['Float']>
  /** Статус для внутренних оповещений */
  status: Maybe<Scalars['Float']>
  /** Статус для оповещений в telegram */
  telegramStatus: Maybe<Scalars['Float']>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type: Maybe<Scalars['Float']>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation: Maybe<Scalars['Float']>
  /** Статус для оповещений в viber */
  viberStatus: Maybe<Scalars['Float']>
  waitingListId: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "notification" */
export type ServerNotificationVarianceOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в моб приложении */
  expoStatus?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  reviewId?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений через sms */
  smsStatus?: InputMaybe<ServerOrderBy>
  /** Статус для внутренних оповещений */
  status?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в telegram */
  telegramStatus?: InputMaybe<ServerOrderBy>
  /** 1 - toClient, 2 - waitingList, 3 - bookingCreatedByClient, 4 - toEmployee, 5 - billingReminder, 6 - review */
  type?: InputMaybe<ServerOrderBy>
  /** 0 - bookingInsert, 1 - bookingUpdateTime, 2 - bookingCancel */
  typeVariation?: InputMaybe<ServerOrderBy>
  /** Статус для оповещений в viber */
  viberStatus?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type ServerNumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>
  _gt?: InputMaybe<Scalars['numeric']>
  _gte?: InputMaybe<Scalars['numeric']>
  _in?: InputMaybe<Array<Scalars['numeric']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['numeric']>
  _lte?: InputMaybe<Scalars['numeric']>
  _neq?: InputMaybe<Scalars['numeric']>
  _nin?: InputMaybe<Array<Scalars['numeric']>>
}

/** columns and relationships of "onlineBookingURL" */
export type ServerOnlineBookingUrl = {
  branchId: Scalars['Int']
  businessId: Scalars['Int']
  description: Maybe<Scalars['String']>
  disableEmployeeSelection: Maybe<Scalars['Boolean']>
  employeeId: Maybe<Scalars['Int']>
  id: Scalars['Int']
  name: Scalars['String']
  serviceId: Maybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "onlineBookingURL". All fields are combined with a logical 'AND'. */
export type ServerOnlineBookingUrlBoolExp = {
  _and?: InputMaybe<Array<ServerOnlineBookingUrlBoolExp>>
  _not?: InputMaybe<ServerOnlineBookingUrlBoolExp>
  _or?: InputMaybe<Array<ServerOnlineBookingUrlBoolExp>>
  branchId?: InputMaybe<ServerIntComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  disableEmployeeSelection?: InputMaybe<ServerBooleanComparisonExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  serviceId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "onlineBookingURL" */
export enum ServerOnlineBookingUrlConstraint {
  /** unique or primary key constraint on columns "id" */
  OnlineBookingUrlPkey = 'onlineBookingURL_pkey',
}

/** input type for incrementing numeric columns in table "onlineBookingURL" */
export type ServerOnlineBookingUrlIncInput = {
  branchId?: InputMaybe<Scalars['Int']>
  employeeId?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "onlineBookingURL" */
export type ServerOnlineBookingUrlInsertInput = {
  branchId?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  disableEmployeeSelection?: InputMaybe<Scalars['Boolean']>
  employeeId?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** response of any mutation on the table "onlineBookingURL" */
export type ServerOnlineBookingUrlMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerOnlineBookingUrl>
}

/** on_conflict condition type for table "onlineBookingURL" */
export type ServerOnlineBookingUrlOnConflict = {
  constraint: ServerOnlineBookingUrlConstraint
  update_columns?: Array<ServerOnlineBookingUrlUpdateColumn>
  where?: InputMaybe<ServerOnlineBookingUrlBoolExp>
}

/** Ordering options when selecting data from "onlineBookingURL". */
export type ServerOnlineBookingUrlOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  disableEmployeeSelection?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: onlineBookingURL */
export type ServerOnlineBookingUrlPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "onlineBookingURL" */
export enum ServerOnlineBookingUrlSelectColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Description = 'description',
  /** column name */
  DisableEmployeeSelection = 'disableEmployeeSelection',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ServiceId = 'serviceId',
}

/** input type for updating data in table "onlineBookingURL" */
export type ServerOnlineBookingUrlSetInput = {
  branchId?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  disableEmployeeSelection?: InputMaybe<Scalars['Boolean']>
  employeeId?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** Streaming cursor of the table "onlineBookingURL" */
export type ServerOnlineBookingUrlStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerOnlineBookingUrlStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerOnlineBookingUrlStreamCursorValueInput = {
  branchId?: InputMaybe<Scalars['Int']>
  businessId?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  disableEmployeeSelection?: InputMaybe<Scalars['Boolean']>
  employeeId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** update columns of table "onlineBookingURL" */
export enum ServerOnlineBookingUrlUpdateColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  Description = 'description',
  /** column name */
  DisableEmployeeSelection = 'disableEmployeeSelection',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  Name = 'name',
  /** column name */
  ServiceId = 'serviceId',
}

export type ServerOnlineBookingUrlUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerOnlineBookingUrlIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerOnlineBookingUrlSetInput>
  /** filter the rows which have to be updated */
  where: ServerOnlineBookingUrlBoolExp
}

/** column ordering options */
export enum ServerOrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** Billing plans */
export type ServerPlan = {
  id: Scalars['Int']
  maxEmployees: Maybe<Scalars['Int']>
  /** Maximum amount of money on all accounts */
  maxTurnover: Maybe<Scalars['numeric']>
  monthlyCost: Scalars['numeric']
  name: Scalars['String']
}

/** Boolean expression to filter rows from the table "plan". All fields are combined with a logical 'AND'. */
export type ServerPlanBoolExp = {
  _and?: InputMaybe<Array<ServerPlanBoolExp>>
  _not?: InputMaybe<ServerPlanBoolExp>
  _or?: InputMaybe<Array<ServerPlanBoolExp>>
  id?: InputMaybe<ServerIntComparisonExp>
  maxEmployees?: InputMaybe<ServerIntComparisonExp>
  maxTurnover?: InputMaybe<ServerNumericComparisonExp>
  monthlyCost?: InputMaybe<ServerNumericComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "plan". */
export type ServerPlanOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  maxEmployees?: InputMaybe<ServerOrderBy>
  maxTurnover?: InputMaybe<ServerOrderBy>
  monthlyCost?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** select columns of table "plan" */
export enum ServerPlanSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MaxEmployees = 'maxEmployees',
  /** column name */
  MaxTurnover = 'maxTurnover',
  /** column name */
  MonthlyCost = 'monthlyCost',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "plan" */
export type ServerPlanStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerPlanStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerPlanStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>
  maxEmployees?: InputMaybe<Scalars['Int']>
  /** Maximum amount of money on all accounts */
  maxTurnover?: InputMaybe<Scalars['numeric']>
  monthlyCost?: InputMaybe<Scalars['numeric']>
  name?: InputMaybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "point". All fields are combined with logical 'AND'. */
export type ServerPointComparisonExp = {
  _eq?: InputMaybe<Scalars['point']>
  _gt?: InputMaybe<Scalars['point']>
  _gte?: InputMaybe<Scalars['point']>
  _in?: InputMaybe<Array<Scalars['point']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['point']>
  _lte?: InputMaybe<Scalars['point']>
  _neq?: InputMaybe<Scalars['point']>
  _nin?: InputMaybe<Array<Scalars['point']>>
}

/** Товары (vendorCode - артикул) */
export type ServerProduct = {
  archived: Maybe<Scalars['timestamptz']>
  barcode: Maybe<Scalars['String']>
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  costPrice: Scalars['numeric']
  createdAt: Scalars['timestamptz']
  criticalQuantity: Scalars['Int']
  description: Maybe<Scalars['String']>
  forSale: Scalars['Boolean']
  groupId: Maybe<Scalars['Int']>
  id: Scalars['Int']
  /** An array relationship */
  movementProducts: Array<ServerMovementProduct>
  /** An aggregate relationship */
  movementProducts_aggregate: ServerMovementProductAggregate
  name: Scalars['String']
  photo: Maybe<Scalars['String']>
  price: Scalars['numeric']
  /** An object relationship */
  productGroup: Maybe<ServerProductGroup>
  /** An array relationship */
  serviceProducts: Array<ServerServiceProduct>
  unit: Scalars['Int']
  vendorCode: Maybe<Scalars['String']>
}

/** Товары (vendorCode - артикул) */
export type ServerProductMovementProductsArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementProductOrderBy>>
  where?: InputMaybe<ServerMovementProductBoolExp>
}

/** Товары (vendorCode - артикул) */
export type ServerProductMovementProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementProductOrderBy>>
  where?: InputMaybe<ServerMovementProductBoolExp>
}

/** Товары (vendorCode - артикул) */
export type ServerProductServiceProductsArgs = {
  distinct_on?: InputMaybe<Array<ServerServiceProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServiceProductOrderBy>>
  where?: InputMaybe<ServerServiceProductBoolExp>
}

/** columns and relationships of "productArchiveView" */
export type ServerProductArchiveView = {
  archived: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "productArchiveView". All fields are combined with a logical 'AND'. */
export type ServerProductArchiveViewBoolExp = {
  _and?: InputMaybe<Array<ServerProductArchiveViewBoolExp>>
  _not?: InputMaybe<ServerProductArchiveViewBoolExp>
  _or?: InputMaybe<Array<ServerProductArchiveViewBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
}

/** response of any mutation on the table "productArchiveView" */
export type ServerProductArchiveViewMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerProductArchiveView>
}

/** Ordering options when selecting data from "productArchiveView". */
export type ServerProductArchiveViewOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** select columns of table "productArchiveView" */
export enum ServerProductArchiveViewSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "productArchiveView" */
export type ServerProductArchiveViewSetInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "productArchiveView" */
export type ServerProductArchiveViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerProductArchiveViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerProductArchiveViewStreamCursorValueInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['Int']>
}

export type ServerProductArchiveViewUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerProductArchiveViewSetInput>
  /** filter the rows which have to be updated */
  where: ServerProductArchiveViewBoolExp
}

/** Группы товаров */
export type ServerProductGroup = {
  archived: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  /** An array relationship */
  childGroups: Array<ServerProductGroup>
  createdAt: Scalars['timestamptz']
  description: Maybe<Scalars['String']>
  id: Scalars['Int']
  name: Scalars['String']
  /** An object relationship */
  parentGroup: Maybe<ServerProductGroup>
  parentId: Maybe<Scalars['Int']>
  /** An array relationship */
  productGroupEmployeeGroups: Array<ServerProductGroupEmployeeGroup>
  /** An array relationship */
  products: Array<ServerProduct>
  /** An aggregate relationship */
  products_aggregate: ServerProductAggregate
}

/** Группы товаров */
export type ServerProductGroupChildGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerProductGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductGroupOrderBy>>
  where?: InputMaybe<ServerProductGroupBoolExp>
}

/** Группы товаров */
export type ServerProductGroupProductGroupEmployeeGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerProductGroupEmployeeGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductGroupEmployeeGroupOrderBy>>
  where?: InputMaybe<ServerProductGroupEmployeeGroupBoolExp>
}

/** Группы товаров */
export type ServerProductGroupProductsArgs = {
  distinct_on?: InputMaybe<Array<ServerProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductOrderBy>>
  where?: InputMaybe<ServerProductBoolExp>
}

/** Группы товаров */
export type ServerProductGroupProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductOrderBy>>
  where?: InputMaybe<ServerProductBoolExp>
}

/** columns and relationships of "productGroupArchiveView" */
export type ServerProductGroupArchiveView = {
  archived: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "productGroupArchiveView". All fields are combined with a logical 'AND'. */
export type ServerProductGroupArchiveViewBoolExp = {
  _and?: InputMaybe<Array<ServerProductGroupArchiveViewBoolExp>>
  _not?: InputMaybe<ServerProductGroupArchiveViewBoolExp>
  _or?: InputMaybe<Array<ServerProductGroupArchiveViewBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
}

/** response of any mutation on the table "productGroupArchiveView" */
export type ServerProductGroupArchiveViewMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerProductGroupArchiveView>
}

/** Ordering options when selecting data from "productGroupArchiveView". */
export type ServerProductGroupArchiveViewOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** select columns of table "productGroupArchiveView" */
export enum ServerProductGroupArchiveViewSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "productGroupArchiveView" */
export type ServerProductGroupArchiveViewSetInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "productGroupArchiveView" */
export type ServerProductGroupArchiveViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerProductGroupArchiveViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerProductGroupArchiveViewStreamCursorValueInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['Int']>
}

export type ServerProductGroupArchiveViewUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerProductGroupArchiveViewSetInput>
  /** filter the rows which have to be updated */
  where: ServerProductGroupArchiveViewBoolExp
}

/** columns and relationships of "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroup = {
  /** An object relationship */
  business: ServerBusiness
  /** An object relationship */
  employeeGroup: ServerEmployeeGroup
  employeeGroupId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  productGroup: ServerProductGroup
  productGroupId: Scalars['Int']
}

/** order by aggregate values of table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupAggregateOrderBy = {
  avg?: InputMaybe<ServerProductGroupEmployeeGroupAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerProductGroupEmployeeGroupMaxOrderBy>
  min?: InputMaybe<ServerProductGroupEmployeeGroupMinOrderBy>
  stddev?: InputMaybe<ServerProductGroupEmployeeGroupStddevOrderBy>
  stddev_pop?: InputMaybe<ServerProductGroupEmployeeGroupStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerProductGroupEmployeeGroupStddevSampOrderBy>
  sum?: InputMaybe<ServerProductGroupEmployeeGroupSumOrderBy>
  var_pop?: InputMaybe<ServerProductGroupEmployeeGroupVarPopOrderBy>
  var_samp?: InputMaybe<ServerProductGroupEmployeeGroupVarSampOrderBy>
  variance?: InputMaybe<ServerProductGroupEmployeeGroupVarianceOrderBy>
}

/** input type for inserting array relation for remote table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupArrRelInsertInput = {
  data: Array<ServerProductGroupEmployeeGroupInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerProductGroupEmployeeGroupOnConflict>
}

/** order by avg() on columns of table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupAvgOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productGroupId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "productGroupEmployeeGroup". All fields are combined with a logical 'AND'. */
export type ServerProductGroupEmployeeGroupBoolExp = {
  _and?: InputMaybe<Array<ServerProductGroupEmployeeGroupBoolExp>>
  _not?: InputMaybe<ServerProductGroupEmployeeGroupBoolExp>
  _or?: InputMaybe<Array<ServerProductGroupEmployeeGroupBoolExp>>
  business?: InputMaybe<ServerBusinessBoolExp>
  employeeGroup?: InputMaybe<ServerEmployeeGroupBoolExp>
  employeeGroupId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  productGroup?: InputMaybe<ServerProductGroupBoolExp>
  productGroupId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "productGroupEmployeeGroup" */
export enum ServerProductGroupEmployeeGroupConstraint {
  /** unique or primary key constraint on columns "id" */
  ProductGroupEmployeeGroupPkey = 'productGroupEmployeeGroup_pkey',
}

/** input type for inserting data into table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupInsertInput = {
  employeeGroup?: InputMaybe<ServerEmployeeGroupObjRelInsertInput>
  employeeGroupId?: InputMaybe<Scalars['Int']>
  productGroup?: InputMaybe<ServerProductGroupObjRelInsertInput>
  productGroupId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupMaxOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productGroupId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupMinOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productGroupId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerProductGroupEmployeeGroup>
}

/** on_conflict condition type for table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupOnConflict = {
  constraint: ServerProductGroupEmployeeGroupConstraint
  update_columns?: Array<ServerProductGroupEmployeeGroupUpdateColumn>
  where?: InputMaybe<ServerProductGroupEmployeeGroupBoolExp>
}

/** Ordering options when selecting data from "productGroupEmployeeGroup". */
export type ServerProductGroupEmployeeGroupOrderBy = {
  business?: InputMaybe<ServerBusinessOrderBy>
  employeeGroup?: InputMaybe<ServerEmployeeGroupOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productGroup?: InputMaybe<ServerProductGroupOrderBy>
  productGroupId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "productGroupEmployeeGroup" */
export enum ServerProductGroupEmployeeGroupSelectColumn {
  /** column name */
  EmployeeGroupId = 'employeeGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  ProductGroupId = 'productGroupId',
}

/** order by stddev() on columns of table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupStddevOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productGroupId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupStddevPopOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productGroupId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupStddevSampOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productGroupId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupSumOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productGroupId?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "productGroupEmployeeGroup" (current role has no relevant permissions) */
export enum ServerProductGroupEmployeeGroupUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupVarPopOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productGroupId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupVarSampOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productGroupId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "productGroupEmployeeGroup" */
export type ServerProductGroupEmployeeGroupVarianceOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productGroupId?: InputMaybe<ServerOrderBy>
}

/** order by aggregate values of table "productGroup" */
export type ServerProductGroupAggregateOrderBy = {
  avg?: InputMaybe<ServerProductGroupAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerProductGroupMaxOrderBy>
  min?: InputMaybe<ServerProductGroupMinOrderBy>
  stddev?: InputMaybe<ServerProductGroupStddevOrderBy>
  stddev_pop?: InputMaybe<ServerProductGroupStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerProductGroupStddevSampOrderBy>
  sum?: InputMaybe<ServerProductGroupSumOrderBy>
  var_pop?: InputMaybe<ServerProductGroupVarPopOrderBy>
  var_samp?: InputMaybe<ServerProductGroupVarSampOrderBy>
  variance?: InputMaybe<ServerProductGroupVarianceOrderBy>
}

/** input type for inserting array relation for remote table "productGroup" */
export type ServerProductGroupArrRelInsertInput = {
  data: Array<ServerProductGroupInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerProductGroupOnConflict>
}

/** order by avg() on columns of table "productGroup" */
export type ServerProductGroupAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "productGroup". All fields are combined with a logical 'AND'. */
export type ServerProductGroupBoolExp = {
  _and?: InputMaybe<Array<ServerProductGroupBoolExp>>
  _not?: InputMaybe<ServerProductGroupBoolExp>
  _or?: InputMaybe<Array<ServerProductGroupBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  childGroups?: InputMaybe<ServerProductGroupBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  parentGroup?: InputMaybe<ServerProductGroupBoolExp>
  parentId?: InputMaybe<ServerIntComparisonExp>
  productGroupEmployeeGroups?: InputMaybe<ServerProductGroupEmployeeGroupBoolExp>
  products?: InputMaybe<ServerProductBoolExp>
  products_aggregate?: InputMaybe<ServerProductAggregateBoolExp>
}

/** unique or primary key constraints on table "productGroup" */
export enum ServerProductGroupConstraint {
  /** unique or primary key constraint on columns "id" */
  GoodsGroupPkey = 'goodsGroup_pkey',
}

/** input type for incrementing numeric columns in table "productGroup" */
export type ServerProductGroupIncInput = {
  parentId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "productGroup" */
export type ServerProductGroupInsertInput = {
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  childGroups?: InputMaybe<ServerProductGroupArrRelInsertInput>
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  parentGroup?: InputMaybe<ServerProductGroupObjRelInsertInput>
  parentId?: InputMaybe<Scalars['Int']>
  productGroupEmployeeGroups?: InputMaybe<ServerProductGroupEmployeeGroupArrRelInsertInput>
  products?: InputMaybe<ServerProductArrRelInsertInput>
}

/** order by max() on columns of table "productGroup" */
export type ServerProductGroupMaxOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "productGroup" */
export type ServerProductGroupMinOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "productGroup" */
export type ServerProductGroupMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerProductGroup>
}

/** input type for inserting object relation for remote table "productGroup" */
export type ServerProductGroupObjRelInsertInput = {
  data: ServerProductGroupInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerProductGroupOnConflict>
}

/** on_conflict condition type for table "productGroup" */
export type ServerProductGroupOnConflict = {
  constraint: ServerProductGroupConstraint
  update_columns?: Array<ServerProductGroupUpdateColumn>
  where?: InputMaybe<ServerProductGroupBoolExp>
}

/** Ordering options when selecting data from "productGroup". */
export type ServerProductGroupOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  childGroups_aggregate?: InputMaybe<ServerProductGroupAggregateOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  parentGroup?: InputMaybe<ServerProductGroupOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  productGroupEmployeeGroups_aggregate?: InputMaybe<ServerProductGroupEmployeeGroupAggregateOrderBy>
  products_aggregate?: InputMaybe<ServerProductAggregateOrderBy>
}

/** primary key columns input for table: productGroup */
export type ServerProductGroupPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "productGroup" */
export enum ServerProductGroupSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
}

/** input type for updating data in table "productGroup" */
export type ServerProductGroupSetInput = {
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  parentId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "productGroup" */
export type ServerProductGroupStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "productGroup" */
export type ServerProductGroupStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "productGroup" */
export type ServerProductGroupStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "productGroup" */
export type ServerProductGroupSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "productGroup" */
export enum ServerProductGroupUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
}

export type ServerProductGroupUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerProductGroupIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerProductGroupSetInput>
  /** filter the rows which have to be updated */
  where: ServerProductGroupBoolExp
}

/** order by var_pop() on columns of table "productGroup" */
export type ServerProductGroupVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "productGroup" */
export type ServerProductGroupVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "productGroup" */
export type ServerProductGroupVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
}

/** aggregated selection of "product" */
export type ServerProductAggregate = {
  aggregate: Maybe<ServerProductAggregateFields>
  nodes: Array<ServerProduct>
}

export type ServerProductAggregateBoolExp = {
  bool_and?: InputMaybe<ServerProductAggregateBoolExpBoolAnd>
  bool_or?: InputMaybe<ServerProductAggregateBoolExpBoolOr>
  count?: InputMaybe<ServerProductAggregateBoolExpCount>
}

export type ServerProductAggregateBoolExpBoolAnd = {
  arguments: ServerProductSelectColumnProductAggregateBoolExpBoolAndArgumentsColumns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerProductBoolExp>
  predicate: ServerBooleanComparisonExp
}

export type ServerProductAggregateBoolExpBoolOr = {
  arguments: ServerProductSelectColumnProductAggregateBoolExpBoolOrArgumentsColumns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerProductBoolExp>
  predicate: ServerBooleanComparisonExp
}

export type ServerProductAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServerProductSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerProductBoolExp>
  predicate: ServerIntComparisonExp
}

/** aggregate fields of "product" */
export type ServerProductAggregateFields = {
  avg: Maybe<ServerProductAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerProductMaxFields>
  min: Maybe<ServerProductMinFields>
  stddev: Maybe<ServerProductStddevFields>
  stddev_pop: Maybe<ServerProductStddevPopFields>
  stddev_samp: Maybe<ServerProductStddevSampFields>
  sum: Maybe<ServerProductSumFields>
  var_pop: Maybe<ServerProductVarPopFields>
  var_samp: Maybe<ServerProductVarSampFields>
  variance: Maybe<ServerProductVarianceFields>
}

/** aggregate fields of "product" */
export type ServerProductAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerProductSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "product" */
export type ServerProductAggregateOrderBy = {
  avg?: InputMaybe<ServerProductAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerProductMaxOrderBy>
  min?: InputMaybe<ServerProductMinOrderBy>
  stddev?: InputMaybe<ServerProductStddevOrderBy>
  stddev_pop?: InputMaybe<ServerProductStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerProductStddevSampOrderBy>
  sum?: InputMaybe<ServerProductSumOrderBy>
  var_pop?: InputMaybe<ServerProductVarPopOrderBy>
  var_samp?: InputMaybe<ServerProductVarSampOrderBy>
  variance?: InputMaybe<ServerProductVarianceOrderBy>
}

/** input type for inserting array relation for remote table "product" */
export type ServerProductArrRelInsertInput = {
  data: Array<ServerProductInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerProductOnConflict>
}

/** aggregate avg on columns */
export type ServerProductAvgFields = {
  branchId: Maybe<Scalars['Float']>
  costPrice: Maybe<Scalars['Float']>
  criticalQuantity: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  unit: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "product" */
export type ServerProductAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  criticalQuantity?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  unit?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type ServerProductBoolExp = {
  _and?: InputMaybe<Array<ServerProductBoolExp>>
  _not?: InputMaybe<ServerProductBoolExp>
  _or?: InputMaybe<Array<ServerProductBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  barcode?: InputMaybe<ServerStringComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  costPrice?: InputMaybe<ServerNumericComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  criticalQuantity?: InputMaybe<ServerIntComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  forSale?: InputMaybe<ServerBooleanComparisonExp>
  groupId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  movementProducts?: InputMaybe<ServerMovementProductBoolExp>
  movementProducts_aggregate?: InputMaybe<ServerMovementProductAggregateBoolExp>
  name?: InputMaybe<ServerStringComparisonExp>
  photo?: InputMaybe<ServerStringComparisonExp>
  price?: InputMaybe<ServerNumericComparisonExp>
  productGroup?: InputMaybe<ServerProductGroupBoolExp>
  serviceProducts?: InputMaybe<ServerServiceProductBoolExp>
  unit?: InputMaybe<ServerIntComparisonExp>
  vendorCode?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "product" */
export enum ServerProductConstraint {
  /** unique or primary key constraint on columns "id" */
  GoodsPkey = 'goods_pkey',
}

/** input type for incrementing numeric columns in table "product" */
export type ServerProductIncInput = {
  costPrice?: InputMaybe<Scalars['numeric']>
  criticalQuantity?: InputMaybe<Scalars['Int']>
  groupId?: InputMaybe<Scalars['Int']>
  price?: InputMaybe<Scalars['numeric']>
  unit?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "product" */
export type ServerProductInsertInput = {
  barcode?: InputMaybe<Scalars['String']>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  costPrice?: InputMaybe<Scalars['numeric']>
  criticalQuantity?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  forSale?: InputMaybe<Scalars['Boolean']>
  groupId?: InputMaybe<Scalars['Int']>
  movementProducts?: InputMaybe<ServerMovementProductArrRelInsertInput>
  name?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['String']>
  price?: InputMaybe<Scalars['numeric']>
  productGroup?: InputMaybe<ServerProductGroupObjRelInsertInput>
  serviceProducts?: InputMaybe<ServerServiceProductArrRelInsertInput>
  unit?: InputMaybe<Scalars['Int']>
  vendorCode?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type ServerProductMaxFields = {
  archived: Maybe<Scalars['timestamptz']>
  barcode: Maybe<Scalars['String']>
  branchId: Maybe<Scalars['Int']>
  costPrice: Maybe<Scalars['numeric']>
  createdAt: Maybe<Scalars['timestamptz']>
  criticalQuantity: Maybe<Scalars['Int']>
  description: Maybe<Scalars['String']>
  groupId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  photo: Maybe<Scalars['String']>
  price: Maybe<Scalars['numeric']>
  unit: Maybe<Scalars['Int']>
  vendorCode: Maybe<Scalars['String']>
}

/** order by max() on columns of table "product" */
export type ServerProductMaxOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  barcode?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  criticalQuantity?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  photo?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  unit?: InputMaybe<ServerOrderBy>
  vendorCode?: InputMaybe<ServerOrderBy>
}

/** aggregate min on columns */
export type ServerProductMinFields = {
  archived: Maybe<Scalars['timestamptz']>
  barcode: Maybe<Scalars['String']>
  branchId: Maybe<Scalars['Int']>
  costPrice: Maybe<Scalars['numeric']>
  createdAt: Maybe<Scalars['timestamptz']>
  criticalQuantity: Maybe<Scalars['Int']>
  description: Maybe<Scalars['String']>
  groupId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  photo: Maybe<Scalars['String']>
  price: Maybe<Scalars['numeric']>
  unit: Maybe<Scalars['Int']>
  vendorCode: Maybe<Scalars['String']>
}

/** order by min() on columns of table "product" */
export type ServerProductMinOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  barcode?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  criticalQuantity?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  photo?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  unit?: InputMaybe<ServerOrderBy>
  vendorCode?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "product" */
export type ServerProductMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerProduct>
}

/** input type for inserting object relation for remote table "product" */
export type ServerProductObjRelInsertInput = {
  data: ServerProductInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerProductOnConflict>
}

/** on_conflict condition type for table "product" */
export type ServerProductOnConflict = {
  constraint: ServerProductConstraint
  update_columns?: Array<ServerProductUpdateColumn>
  where?: InputMaybe<ServerProductBoolExp>
}

/** Ordering options when selecting data from "product". */
export type ServerProductOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  barcode?: InputMaybe<ServerOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  criticalQuantity?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  forSale?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementProducts_aggregate?: InputMaybe<ServerMovementProductAggregateOrderBy>
  name?: InputMaybe<ServerOrderBy>
  photo?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  productGroup?: InputMaybe<ServerProductGroupOrderBy>
  serviceProducts_aggregate?: InputMaybe<ServerServiceProductAggregateOrderBy>
  unit?: InputMaybe<ServerOrderBy>
  vendorCode?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: product */
export type ServerProductPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "product" */
export enum ServerProductSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  Barcode = 'barcode',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CriticalQuantity = 'criticalQuantity',
  /** column name */
  Description = 'description',
  /** column name */
  ForSale = 'forSale',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo',
  /** column name */
  Price = 'price',
  /** column name */
  Unit = 'unit',
  /** column name */
  VendorCode = 'vendorCode',
}

/** select "product_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product" */
export enum ServerProductSelectColumnProductAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  ForSale = 'forSale',
}

/** select "product_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product" */
export enum ServerProductSelectColumnProductAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  ForSale = 'forSale',
}

/** input type for updating data in table "product" */
export type ServerProductSetInput = {
  barcode?: InputMaybe<Scalars['String']>
  costPrice?: InputMaybe<Scalars['numeric']>
  criticalQuantity?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  forSale?: InputMaybe<Scalars['Boolean']>
  groupId?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['String']>
  price?: InputMaybe<Scalars['numeric']>
  unit?: InputMaybe<Scalars['Int']>
  vendorCode?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type ServerProductStddevFields = {
  branchId: Maybe<Scalars['Float']>
  costPrice: Maybe<Scalars['Float']>
  criticalQuantity: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  unit: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "product" */
export type ServerProductStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  criticalQuantity?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  unit?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_pop on columns */
export type ServerProductStddevPopFields = {
  branchId: Maybe<Scalars['Float']>
  costPrice: Maybe<Scalars['Float']>
  criticalQuantity: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  unit: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "product" */
export type ServerProductStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  criticalQuantity?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  unit?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_samp on columns */
export type ServerProductStddevSampFields = {
  branchId: Maybe<Scalars['Float']>
  costPrice: Maybe<Scalars['Float']>
  criticalQuantity: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  unit: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "product" */
export type ServerProductStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  criticalQuantity?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  unit?: InputMaybe<ServerOrderBy>
}

/** aggregate sum on columns */
export type ServerProductSumFields = {
  branchId: Maybe<Scalars['Int']>
  costPrice: Maybe<Scalars['numeric']>
  criticalQuantity: Maybe<Scalars['Int']>
  groupId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  price: Maybe<Scalars['numeric']>
  unit: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "product" */
export type ServerProductSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  criticalQuantity?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  unit?: InputMaybe<ServerOrderBy>
}

/** update columns of table "product" */
export enum ServerProductUpdateColumn {
  /** column name */
  Barcode = 'barcode',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  CriticalQuantity = 'criticalQuantity',
  /** column name */
  Description = 'description',
  /** column name */
  ForSale = 'forSale',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo',
  /** column name */
  Price = 'price',
  /** column name */
  Unit = 'unit',
  /** column name */
  VendorCode = 'vendorCode',
}

export type ServerProductUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerProductIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerProductSetInput>
  /** filter the rows which have to be updated */
  where: ServerProductBoolExp
}

/** aggregate var_pop on columns */
export type ServerProductVarPopFields = {
  branchId: Maybe<Scalars['Float']>
  costPrice: Maybe<Scalars['Float']>
  criticalQuantity: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  unit: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "product" */
export type ServerProductVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  criticalQuantity?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  unit?: InputMaybe<ServerOrderBy>
}

/** aggregate var_samp on columns */
export type ServerProductVarSampFields = {
  branchId: Maybe<Scalars['Float']>
  costPrice: Maybe<Scalars['Float']>
  criticalQuantity: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  unit: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "product" */
export type ServerProductVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  criticalQuantity?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  unit?: InputMaybe<ServerOrderBy>
}

/** aggregate variance on columns */
export type ServerProductVarianceFields = {
  branchId: Maybe<Scalars['Float']>
  costPrice: Maybe<Scalars['Float']>
  criticalQuantity: Maybe<Scalars['Float']>
  groupId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  price: Maybe<Scalars['Float']>
  unit: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "product" */
export type ServerProductVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  criticalQuantity?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  unit?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "promoCode" */
export type ServerPromoCode = {
  /** An array relationship */
  businessPromoCodes: Array<ServerBusinessPromoCode>
  createdAt: Scalars['timestamptz']
  description: Maybe<Scalars['String']>
  /** % */
  discount: Maybe<Scalars['Int']>
  endAt: Maybe<Scalars['timestamptz']>
  id: Scalars['String']
  /** An object relationship */
  referrer: Maybe<ServerReferrer>
  referrerId: Maybe<Scalars['Int']>
  /** always in days */
  term: Maybe<Scalars['Int']>
}

/** columns and relationships of "promoCode" */
export type ServerPromoCodeBusinessPromoCodesArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessPromoCodeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessPromoCodeOrderBy>>
  where?: InputMaybe<ServerBusinessPromoCodeBoolExp>
}

/** columns and relationships of "promoCodeReferrerView" */
export type ServerPromoCodeReferrerView = {
  /** An array relationship */
  businessPromoCodes: Array<ServerBusinessPromoCodeReferrerView>
  createdAt: Maybe<Scalars['timestamptz']>
  description: Maybe<Scalars['String']>
  discount: Maybe<Scalars['Int']>
  endAt: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['String']>
  /** An object relationship */
  referrer: Maybe<ServerReferrerView>
  referrerId: Maybe<Scalars['Int']>
  term: Maybe<Scalars['Int']>
}

/** columns and relationships of "promoCodeReferrerView" */
export type ServerPromoCodeReferrerViewBusinessPromoCodesArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessPromoCodeReferrerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessPromoCodeReferrerViewOrderBy>>
  where?: InputMaybe<ServerBusinessPromoCodeReferrerViewBoolExp>
}

/** Boolean expression to filter rows from the table "promoCodeReferrerView". All fields are combined with a logical 'AND'. */
export type ServerPromoCodeReferrerViewBoolExp = {
  _and?: InputMaybe<Array<ServerPromoCodeReferrerViewBoolExp>>
  _not?: InputMaybe<ServerPromoCodeReferrerViewBoolExp>
  _or?: InputMaybe<Array<ServerPromoCodeReferrerViewBoolExp>>
  businessPromoCodes?: InputMaybe<ServerBusinessPromoCodeReferrerViewBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  discount?: InputMaybe<ServerIntComparisonExp>
  endAt?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerStringComparisonExp>
  referrer?: InputMaybe<ServerReferrerViewBoolExp>
  referrerId?: InputMaybe<ServerIntComparisonExp>
  term?: InputMaybe<ServerIntComparisonExp>
}

/** response of any mutation on the table "promoCodeReferrerView" */
export type ServerPromoCodeReferrerViewMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerPromoCodeReferrerView>
}

/** Ordering options when selecting data from "promoCodeReferrerView". */
export type ServerPromoCodeReferrerViewOrderBy = {
  businessPromoCodes_aggregate?: InputMaybe<ServerBusinessPromoCodeReferrerViewAggregateOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  endAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  referrer?: InputMaybe<ServerReferrerViewOrderBy>
  referrerId?: InputMaybe<ServerOrderBy>
  term?: InputMaybe<ServerOrderBy>
}

/** select columns of table "promoCodeReferrerView" */
export enum ServerPromoCodeReferrerViewSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  EndAt = 'endAt',
  /** column name */
  Id = 'id',
  /** column name */
  ReferrerId = 'referrerId',
  /** column name */
  Term = 'term',
}

/** input type for updating data in table "promoCodeReferrerView" */
export type ServerPromoCodeReferrerViewSetInput = {
  description?: InputMaybe<Scalars['String']>
  endAt?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "promoCodeReferrerView" */
export type ServerPromoCodeReferrerViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerPromoCodeReferrerViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerPromoCodeReferrerViewStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  discount?: InputMaybe<Scalars['Int']>
  endAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['String']>
  referrerId?: InputMaybe<Scalars['Int']>
  term?: InputMaybe<Scalars['Int']>
}

export type ServerPromoCodeReferrerViewUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerPromoCodeReferrerViewSetInput>
  /** filter the rows which have to be updated */
  where: ServerPromoCodeReferrerViewBoolExp
}

/** order by aggregate values of table "promoCode" */
export type ServerPromoCodeAggregateOrderBy = {
  avg?: InputMaybe<ServerPromoCodeAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerPromoCodeMaxOrderBy>
  min?: InputMaybe<ServerPromoCodeMinOrderBy>
  stddev?: InputMaybe<ServerPromoCodeStddevOrderBy>
  stddev_pop?: InputMaybe<ServerPromoCodeStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerPromoCodeStddevSampOrderBy>
  sum?: InputMaybe<ServerPromoCodeSumOrderBy>
  var_pop?: InputMaybe<ServerPromoCodeVarPopOrderBy>
  var_samp?: InputMaybe<ServerPromoCodeVarSampOrderBy>
  variance?: InputMaybe<ServerPromoCodeVarianceOrderBy>
}

/** input type for inserting array relation for remote table "promoCode" */
export type ServerPromoCodeArrRelInsertInput = {
  data: Array<ServerPromoCodeInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerPromoCodeOnConflict>
}

/** order by avg() on columns of table "promoCode" */
export type ServerPromoCodeAvgOrderBy = {
  /** % */
  discount?: InputMaybe<ServerOrderBy>
  referrerId?: InputMaybe<ServerOrderBy>
  /** always in days */
  term?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "promoCode". All fields are combined with a logical 'AND'. */
export type ServerPromoCodeBoolExp = {
  _and?: InputMaybe<Array<ServerPromoCodeBoolExp>>
  _not?: InputMaybe<ServerPromoCodeBoolExp>
  _or?: InputMaybe<Array<ServerPromoCodeBoolExp>>
  businessPromoCodes?: InputMaybe<ServerBusinessPromoCodeBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  discount?: InputMaybe<ServerIntComparisonExp>
  endAt?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerStringComparisonExp>
  referrer?: InputMaybe<ServerReferrerBoolExp>
  referrerId?: InputMaybe<ServerIntComparisonExp>
  term?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "promoCode" */
export enum ServerPromoCodeConstraint {
  /** unique or primary key constraint on columns "id" */
  PromoCodePkey = 'promoCode_pkey',
}

/** input type for inserting data into table "promoCode" */
export type ServerPromoCodeInsertInput = {
  businessPromoCodes?: InputMaybe<ServerBusinessPromoCodeArrRelInsertInput>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  /** % */
  discount?: InputMaybe<Scalars['Int']>
  endAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['String']>
  referrer?: InputMaybe<ServerReferrerObjRelInsertInput>
  referrerId?: InputMaybe<Scalars['Int']>
  /** always in days */
  term?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "promoCode" */
export type ServerPromoCodeMaxOrderBy = {
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  /** % */
  discount?: InputMaybe<ServerOrderBy>
  endAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  referrerId?: InputMaybe<ServerOrderBy>
  /** always in days */
  term?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "promoCode" */
export type ServerPromoCodeMinOrderBy = {
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  /** % */
  discount?: InputMaybe<ServerOrderBy>
  endAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  referrerId?: InputMaybe<ServerOrderBy>
  /** always in days */
  term?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "promoCode" */
export type ServerPromoCodeMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerPromoCode>
}

/** input type for inserting object relation for remote table "promoCode" */
export type ServerPromoCodeObjRelInsertInput = {
  data: ServerPromoCodeInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerPromoCodeOnConflict>
}

/** on_conflict condition type for table "promoCode" */
export type ServerPromoCodeOnConflict = {
  constraint: ServerPromoCodeConstraint
  update_columns?: Array<ServerPromoCodeUpdateColumn>
  where?: InputMaybe<ServerPromoCodeBoolExp>
}

/** Ordering options when selecting data from "promoCode". */
export type ServerPromoCodeOrderBy = {
  businessPromoCodes_aggregate?: InputMaybe<ServerBusinessPromoCodeAggregateOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  endAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  referrer?: InputMaybe<ServerReferrerOrderBy>
  referrerId?: InputMaybe<ServerOrderBy>
  term?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: promoCode */
export type ServerPromoCodePkColumnsInput = {
  id: Scalars['String']
}

/** select columns of table "promoCode" */
export enum ServerPromoCodeSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  EndAt = 'endAt',
  /** column name */
  Id = 'id',
  /** column name */
  ReferrerId = 'referrerId',
  /** column name */
  Term = 'term',
}

/** input type for updating data in table "promoCode" */
export type ServerPromoCodeSetInput = {
  description?: InputMaybe<Scalars['String']>
  endAt?: InputMaybe<Scalars['timestamptz']>
}

/** order by stddev() on columns of table "promoCode" */
export type ServerPromoCodeStddevOrderBy = {
  /** % */
  discount?: InputMaybe<ServerOrderBy>
  referrerId?: InputMaybe<ServerOrderBy>
  /** always in days */
  term?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "promoCode" */
export type ServerPromoCodeStddevPopOrderBy = {
  /** % */
  discount?: InputMaybe<ServerOrderBy>
  referrerId?: InputMaybe<ServerOrderBy>
  /** always in days */
  term?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "promoCode" */
export type ServerPromoCodeStddevSampOrderBy = {
  /** % */
  discount?: InputMaybe<ServerOrderBy>
  referrerId?: InputMaybe<ServerOrderBy>
  /** always in days */
  term?: InputMaybe<ServerOrderBy>
}

/** Streaming cursor of the table "promoCode" */
export type ServerPromoCodeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerPromoCodeStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerPromoCodeStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  /** % */
  discount?: InputMaybe<Scalars['Int']>
  endAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['String']>
  referrerId?: InputMaybe<Scalars['Int']>
  /** always in days */
  term?: InputMaybe<Scalars['Int']>
}

/** order by sum() on columns of table "promoCode" */
export type ServerPromoCodeSumOrderBy = {
  /** % */
  discount?: InputMaybe<ServerOrderBy>
  referrerId?: InputMaybe<ServerOrderBy>
  /** always in days */
  term?: InputMaybe<ServerOrderBy>
}

/** update columns of table "promoCode" */
export enum ServerPromoCodeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  EndAt = 'endAt',
}

export type ServerPromoCodeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerPromoCodeSetInput>
  /** filter the rows which have to be updated */
  where: ServerPromoCodeBoolExp
}

/** order by var_pop() on columns of table "promoCode" */
export type ServerPromoCodeVarPopOrderBy = {
  /** % */
  discount?: InputMaybe<ServerOrderBy>
  referrerId?: InputMaybe<ServerOrderBy>
  /** always in days */
  term?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "promoCode" */
export type ServerPromoCodeVarSampOrderBy = {
  /** % */
  discount?: InputMaybe<ServerOrderBy>
  referrerId?: InputMaybe<ServerOrderBy>
  /** always in days */
  term?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "promoCode" */
export type ServerPromoCodeVarianceOrderBy = {
  /** % */
  discount?: InputMaybe<ServerOrderBy>
  referrerId?: InputMaybe<ServerOrderBy>
  /** always in days */
  term?: InputMaybe<ServerOrderBy>
}

export type ServerQueryRoot = {
  /** fetch data from the table: "account" using primary key columns */
  accountById: Maybe<ServerAccount>
  /** fetch data from the table: "accountSoftwarePOS" using primary key columns */
  accountSoftwarePOSById: Maybe<ServerAccountSoftwarePos>
  /** An array relationship */
  accountSoftwarePOSs: Array<ServerAccountSoftwarePos>
  /** An array relationship */
  accounts: Array<ServerAccount>
  /** fetch data from the table: "address" using primary key columns */
  addressById: Maybe<ServerAddress>
  /** fetch data from the table: "address" */
  addresses: Array<ServerAddress>
  /** fetch data from the table: "bankDetails" using primary key columns */
  bankDetailsById: Maybe<ServerBankDetails>
  /** fetch data from the table: "bankDetails" */
  banksDetails: Array<ServerBankDetails>
  /** fetch data from the table: "billingInfo" using primary key columns */
  billingInfoById: Maybe<ServerBillingInfo>
  /** An array relationship */
  billingInfos: Array<ServerBillingInfo>
  /** fetch data from the table: "booking" using primary key columns */
  bookingById: Maybe<ServerBooking>
  /** fetch data from the table: "bookingClientSettings" */
  bookingClientSettings: Array<ServerBookingClientSettings>
  /** fetch data from the table: "bookingClientSettings" using primary key columns */
  bookingClientSettingsById: Maybe<ServerBookingClientSettings>
  /** fetch data from the table: "bookingClientSettingsView" */
  bookingClientSettingsView: Array<ServerBookingClientSettingsView>
  /** fetch data from the table: "bookingReviewView" */
  bookingReviews: Array<ServerBookingReviewView>
  /** fetch data from the table: "bookingStatusView" */
  bookingStatuses: Array<ServerBookingStatusView>
  /** An array relationship */
  bookings: Array<ServerBooking>
  /** fetch aggregated fields from the table: "booking" */
  bookingsAggregate: ServerBookingAggregate
  /** fetch data from the table: "branch" using primary key columns */
  branchById: Maybe<ServerBranch>
  /** fetch data from the table: "branchToSwitchAsClientView" */
  branchToSwitchAsClientView: Array<ServerBranchToSwitchAsClientView>
  /** fetch data from the table: "branchView" */
  branchView: Array<ServerBranchView>
  /** An array relationship */
  branches: Array<ServerBranch>
  /** fetch data from the table: "business" using primary key columns */
  businessById: Maybe<ServerBusiness>
  /** fetch data from the table: "businessCurrentPlanIdView" */
  businessCurrentPlanIdView: Array<ServerBusinessCurrentPlanIdView>
  /** fetch data from the table: "businessInfoView" */
  businessInfoView: Array<ServerBusinessInfoView>
  /** fetch data from the table: "businessInfoReferrerView" */
  businessInfosReferrerView: Array<ServerBusinessInfoReferrerView>
  /** fetch data from the table: "businessIsTestView" */
  businessIsTestView: Array<ServerBusinessIsTestView>
  /** fetch data from the table: "businessLanding" using primary key columns */
  businessLandingById: Maybe<ServerBusinessLanding>
  /** fetch data from the table: "businessLandingView" */
  businessLandingViews: Array<ServerBusinessLandingView>
  /** fetch data from the table: "businessLanding" */
  businessLandings: Array<ServerBusinessLanding>
  /** fetch data from the table: "businessPromoCode" using primary key columns */
  businessPromoCodeById: Maybe<ServerBusinessPromoCode>
  /** An array relationship */
  businessPromoCodes: Array<ServerBusinessPromoCode>
  /** fetch data from the table: "businessPromoCodeReferrerView" */
  businessPromoCodesReferrerView: Array<ServerBusinessPromoCodeReferrerView>
  /** fetch data from the table: "businessPromoCodeView" */
  businessPromoCodesView: Array<ServerBusinessPromoCodeView>
  /** fetch data from the table: "businessSettings" using primary key columns */
  businessSettingsById: Maybe<ServerBusinessSettings>
  /** fetch data from the table: "businessTelegramIntegrationView" */
  businessTelegramIntegrationView: Array<ServerBusinessTelegramIntegrationView>
  /** fetch data from the table: "businessToSwitch" */
  businessToSwitch: Array<ServerBusinessToSwitch>
  /** fetch data from the table: "businessType" using primary key columns */
  businessTypeById: Maybe<ServerBusinessType>
  /** fetch data from the table: "businessType" */
  businessTypes: Array<ServerBusinessType>
  /** fetch data from the table: "business" */
  businesses: Array<ServerBusiness>
  /** fetch aggregated fields from the table: "business" */
  businessesAggregate: ServerBusinessAggregate
  /** fetch data from the table: "card" using primary key columns */
  cardById: Maybe<ServerCard>
  /** fetch data from the table: "cardTemplate" using primary key columns */
  cardTemplateById: Maybe<ServerCardTemplate>
  /** fetch data from the table: "cardTemplateNameView" */
  cardTemplateName: Array<ServerCardTemplateNameView>
  /** fetch data from the table: "cardTemplate" */
  cardTemplates: Array<ServerCardTemplate>
  /** An array relationship */
  cards: Array<ServerCard>
  checkAvailabilityForBooking: Maybe<Array<Maybe<ServerTimeOption>>>
  checkAvailabilityForBookingAnonymously: Maybe<ServerCheckAvailabilityForBookingResult>
  checkFreeTimeForMonth: Maybe<ServerCheckFreeTimeForMonthResult>
  checkFreeTimeForMonthAnonymously: Maybe<ServerCheckFreeTimeForMonthResult>
  checkNearestFreeTime: Maybe<ServerCheckNearestFreeTimeResult>
  checkNearestFreeTimeAnonymously: Maybe<ServerCheckNearestFreeTimeResult>
  checkboxGetShifts: Maybe<ServerCheckboxGetShiftsResult>
  /** fetch data from the table: "client" using primary key columns */
  clientById: Maybe<ServerClient>
  /** fetch data from the table: "clientDocument" using primary key columns */
  clientDocumentById: Maybe<ServerClientDocument>
  /** An array relationship */
  clientDocuments: Array<ServerClientDocument>
  /** fetch data from the table: "clientEmailView" */
  clientEmailsView: Array<ServerClientEmailView>
  /** fetch data from the table: "clientNote" using primary key columns */
  clientNoteById: Maybe<ServerClientNote>
  /** An array relationship */
  clientNotes: Array<ServerClientNote>
  /** fetch data from the table: "clientPhoneView" */
  clientPhoneView: Array<ServerClientPhoneView>
  /** fetch data from the table: "clientRelation" using primary key columns */
  clientRelationById: Maybe<ServerClientRelation>
  /** fetch data from the table: "clientRelation" */
  clientRelations: Array<ServerClientRelation>
  /** fetch data from the table: "clientSettings" using primary key columns */
  clientSettingsById: Maybe<ServerClientSettings>
  /** fetch data from the table: "clientTag" using primary key columns */
  clientTagById: Maybe<ServerClientTag>
  /** An array relationship */
  clientTags: Array<ServerClientTag>
  /** An array relationship */
  clients: Array<ServerClient>
  /** fetch aggregated fields from the table: "client" */
  clientsAggregate: ServerClientAggregate
  /** fetch data from the table: "clientBalanceView" */
  clientsBalance: Array<ServerClientBalanceView>
  /** fetch data from the table: "clientSettings" */
  clientsSettings: Array<ServerClientSettings>
  /** fetch data from the table: "employeeBusinessOwnerView" */
  employeeBusinessOwners: Array<ServerEmployeeBusinessOwnerView>
  /** fetch data from the table: "employee" using primary key columns */
  employeeById: Maybe<ServerEmployee>
  /** fetch data from the table: "employeeEmailView" */
  employeeEmails: Array<ServerEmployeeEmailView>
  /** fetch data from the table: "employeeGroupArchiveView" */
  employeeGroupArchives: Array<ServerEmployeeGroupArchiveView>
  /** fetch data from the table: "employeeGroup" using primary key columns */
  employeeGroupById: Maybe<ServerEmployeeGroup>
  /** fetch data from the table: "employeeGroupOwnerView" */
  employeeGroupOwnerView: Array<ServerEmployeeGroupOwnerView>
  /** An array relationship */
  employeeGroups: Array<ServerEmployeeGroup>
  /** fetch data from the table: "employeeReviewView" */
  employeeReviews: Array<ServerEmployeeReviewView>
  /** fetch data from the table: "employeeSchedule" using primary key columns */
  employeeScheduleById: Maybe<ServerEmployeeSchedule>
  /** An array relationship */
  employeeSchedules: Array<ServerEmployeeSchedule>
  /** fetch aggregated fields from the table: "employeeSchedule" */
  employeeSchedulesAggregate: ServerEmployeeScheduleAggregate
  /** fetch data from the table: "employeeSettings" */
  employeeSettings: Array<ServerEmployeeSettings>
  /** fetch data from the table: "employeeSettings" using primary key columns */
  employeeSettingsById: Maybe<ServerEmployeeSettings>
  /** An array relationship */
  employees: Array<ServerEmployee>
  /** fetch aggregated fields from the table: "employee" */
  employeesAggregate: ServerEmployeeAggregate
  /** fetch data from the table: "expaneInfo" using primary key columns */
  expaneInfoById: Maybe<ServerExpaneInfo>
  /** fetch data from the table: "expaneInfoCategory" */
  expaneInfoCategories: Array<ServerExpaneInfoCategory>
  /** fetch data from the table: "expaneInfoCategory" using primary key columns */
  expaneInfoCategoryById: Maybe<ServerExpaneInfoCategory>
  /** fetch data from the table: "expaneInfo" */
  expaneInfos: Array<ServerExpaneInfo>
  /** fetch data from the table: "expensesReason" using primary key columns */
  expensesReasonById: Maybe<ServerExpensesReason>
  /** fetch data from the table: "expensesReason" */
  expensesReasons: Array<ServerExpensesReason>
  /** fetch data from the table: "featureFlags" */
  featureFlags: Array<ServerFeatureFlags>
  generateCustomFirebaseToken: ServerCustomFirebaseTokenResponse
  getAvailableClients: ServerGetAvailableClientsResponse
  /** execute function "getBusinessToSwitch" which returns "businessToSwitch" */
  getBusinessToSwitch: Array<ServerBusinessToSwitch>
  getLeftovers: Maybe<ServerGetLeftoversOutput>
  /** fetch data from the table: "human" using primary key columns */
  humanById: Maybe<ServerHuman>
  /** fetch data from the table: "human" */
  humans: Array<ServerHuman>
  /** fetch data from the table: "inventory" */
  inventories: Array<ServerInventory>
  /** fetch aggregated fields from the table: "inventory" */
  inventoriesAggregate: ServerInventoryAggregate
  /** fetch data from the table: "inventory" using primary key columns */
  inventoryById: Maybe<ServerInventory>
  /** fetch data from the table: "lead" using primary key columns */
  leadById: Maybe<ServerLead>
  /** fetch data from the table: "lead" */
  leads: Array<ServerLead>
  /** fetch data from the table: "location" using primary key columns */
  locationById: Maybe<ServerLocation>
  /** fetch data from the table: "locationGroup" using primary key columns */
  locationGroupById: Maybe<ServerLocationGroup>
  /** fetch data from the table: "locationGroup" */
  locationGroups: Array<ServerLocationGroup>
  /** An array relationship */
  locations: Array<ServerLocation>
  /** fetch data from the table: "movement" using primary key columns */
  movementById: Maybe<ServerMovement>
  /** fetch data from the table: "movementProduct" using primary key columns */
  movementProductById: Maybe<ServerMovementProduct>
  /** An array relationship */
  movementProducts: Array<ServerMovementProduct>
  /** fetch aggregated fields from the table: "movementProduct" */
  movementProductsAggregate: ServerMovementProductAggregate
  /** An array relationship */
  movements: Array<ServerMovement>
  /** fetch aggregated fields from the table: "movement" */
  movementsAggregate: ServerMovementAggregate
  /** fetch data from the table: "businessSettings" */
  multipleBusinessSettings: Array<ServerBusinessSettings>
  /** fetch data from the table: "notification" using primary key columns */
  notificationById: Maybe<ServerNotification>
  /** An array relationship */
  notifications: Array<ServerNotification>
  /** fetch aggregated fields from the table: "notification" */
  notificationsAggregate: ServerNotificationAggregate
  /** fetch data from the table: "onlineBookingURL" using primary key columns */
  onlineBookingURLById: Maybe<ServerOnlineBookingUrl>
  /** fetch data from the table: "onlineBookingURL" */
  onlineBookingURLs: Array<ServerOnlineBookingUrl>
  /** fetch data from the table: "plan" using primary key columns */
  planById: Maybe<ServerPlan>
  /** fetch data from the table: "plan" */
  plans: Array<ServerPlan>
  /** fetch data from the table: "productArchiveView" */
  productArchives: Array<ServerProductArchiveView>
  /** fetch data from the table: "product" using primary key columns */
  productById: Maybe<ServerProduct>
  /** fetch data from the table: "productGroupArchiveView" */
  productGroupArchives: Array<ServerProductGroupArchiveView>
  /** fetch data from the table: "productGroup" using primary key columns */
  productGroupById: Maybe<ServerProductGroup>
  /** fetch data from the table: "productGroup" */
  productGroups: Array<ServerProductGroup>
  /** An array relationship */
  products: Array<ServerProduct>
  /** fetch aggregated fields from the table: "product" */
  productsAggregate: ServerProductAggregate
  /** fetch data from the table: "promoCode" using primary key columns */
  promoCodeById: Maybe<ServerPromoCode>
  /** An array relationship */
  promoCodes: Array<ServerPromoCode>
  /** fetch data from the table: "promoCodeReferrerView" */
  promoCodesReferrerView: Array<ServerPromoCodeReferrerView>
  /** fetch data from the table: "recurringBooking" using primary key columns */
  recurringBookingById: Maybe<ServerRecurringBooking>
  /** fetch data from the table: "recurringBooking" */
  recurringBookings: Array<ServerRecurringBooking>
  /** fetch data from the table: "referrer" using primary key columns */
  referrerById: Maybe<ServerReferrer>
  /** fetch data from the table: "referrer" */
  referrers: Array<ServerReferrer>
  /** fetch data from the table: "referrerView" */
  referrersView: Array<ServerReferrerView>
  /** fetch data from the table: "revenueReason" using primary key columns */
  revenueReasonById: Maybe<ServerRevenueReason>
  /** fetch data from the table: "revenueReason" */
  revenueReasons: Array<ServerRevenueReason>
  /** fetch data from the table: "review" using primary key columns */
  reviewById: Maybe<ServerReview>
  /** fetch data from the table: "review" */
  reviews: Array<ServerReview>
  /** fetch aggregated fields from the table: "review" */
  reviewsAggregate: ServerReviewAggregate
  /** fetch data from the table: "role" using primary key columns */
  roleById: Maybe<ServerRole>
  /** fetch data from the table: "rolePermission" */
  rolePermission: Array<ServerRolePermission>
  /** fetch data from the table: "rolePermission" using primary key columns */
  rolePermissionById: Maybe<ServerRolePermission>
  /** fetch data from the table: "role" */
  roles: Array<ServerRole>
  /** fetch data from the table: "salaryIssue" using primary key columns */
  salaryIssueById: Maybe<ServerSalaryIssue>
  /** An array relationship */
  salaryIssues: Array<ServerSalaryIssue>
  /** fetch data from the table: "salarySetting" using primary key columns */
  salarySettingById: Maybe<ServerSalarySetting>
  /** An array relationship */
  salarySettings: Array<ServerSalarySetting>
  /** fetch aggregated fields from the table: "salarySetting" */
  salarySettingsAggregate: ServerSalarySettingAggregate
  /** fetch data from the table: "schedule" using primary key columns */
  scheduleById: Maybe<ServerSchedule>
  /** fetch data from the table: "schedule" */
  schedules: Array<ServerSchedule>
  /** fetch data from the table: "serverStatus" */
  serverStatus: Array<ServerServerStatus>
  /** fetch data from the table: "serverStatus" using primary key columns */
  serverStatusById: Maybe<ServerServerStatus>
  /** fetch data from the table: "service" using primary key columns */
  serviceById: Maybe<ServerService>
  /** fetch data from the table: "serviceGroup" using primary key columns */
  serviceGroupById: Maybe<ServerServiceGroup>
  /** fetch data from the table: "serviceGroup" */
  serviceGroups: Array<ServerServiceGroup>
  /** An array relationship */
  services: Array<ServerService>
  /** fetch data from the table: "softwarePOS" using primary key columns */
  softwarePOSById: Maybe<ServerSoftwarePos>
  /** fetch data from the table: "softwarePOS" */
  softwarePOSs: Array<ServerSoftwarePos>
  /** fetch data from the table: "storage" using primary key columns */
  storageById: Maybe<ServerStorage>
  /** An array relationship */
  storages: Array<ServerStorage>
  /** fetch data from the table: "subscriptionPeriod" using primary key columns */
  subscriptionPeriodById: Maybe<ServerSubscriptionPeriod>
  /** fetch data from the table: "subscriptionPeriod" */
  subscriptionPeriods: Array<ServerSubscriptionPeriod>
  /** fetch data from the table: "super" using primary key columns */
  superById: Maybe<ServerSuper>
  /** fetch data from the table: "super" */
  supers: Array<ServerSuper>
  /** fetch data from the table: "supplier" using primary key columns */
  supplierById: Maybe<ServerSupplier>
  /** fetch data from the table: "supplier" */
  suppliers: Array<ServerSupplier>
  /** fetch data from the table: "tag" using primary key columns */
  tagById: Maybe<ServerTag>
  /** fetch data from the table: "tag" */
  tags: Array<ServerTag>
  /** fetch data from the table: "timeOff" using primary key columns */
  timeOffById: Maybe<ServerTimeOff>
  /** fetch data from the table: "timeOffReason" using primary key columns */
  timeOffReasonById: Maybe<ServerTimeOffReason>
  /** fetch data from the table: "timeOffReason" */
  timeOffReasons: Array<ServerTimeOffReason>
  /** fetch data from the table: "timeOff" */
  timeOffs: Array<ServerTimeOff>
  /** fetch data from the table: "transaction" using primary key columns */
  transactionById: Maybe<ServerTransaction>
  /** An array relationship */
  transactions: Array<ServerTransaction>
  /** fetch aggregated fields from the table: "transaction" */
  transactionsAggregate: ServerTransactionAggregate
  /** fetch data from the table: "unitedBooking" using primary key columns */
  unitedBookingById: Maybe<ServerUnitedBooking>
  /** fetch data from the table: "unitedBooking" */
  unitedBookings: Array<ServerUnitedBooking>
  /** fetch data from the table: "userExpoNotification" using primary key columns */
  userExpoNotificationByEmail: Maybe<ServerUserExpoNotification>
  /** fetch data from the table: "userExpoNotification" */
  userExpoNotifications: Array<ServerUserExpoNotification>
  validateBookings: Maybe<ServerValidateBookingOutput>
  /** fetch data from the table: "waitingList" using primary key columns */
  waitingListById: Maybe<ServerWaitingList>
  /** An array relationship */
  waitingLists: Array<ServerWaitingList>
}

export type ServerQueryRootAccountByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootAccountSoftwarePosByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootAccountSoftwarePoSsArgs = {
  distinct_on?: InputMaybe<Array<ServerAccountSoftwarePosSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerAccountSoftwarePosOrderBy>>
  where?: InputMaybe<ServerAccountSoftwarePosBoolExp>
}

export type ServerQueryRootAccountsArgs = {
  distinct_on?: InputMaybe<Array<ServerAccountSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerAccountOrderBy>>
  where?: InputMaybe<ServerAccountBoolExp>
}

export type ServerQueryRootAddressByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootAddressesArgs = {
  distinct_on?: InputMaybe<Array<ServerAddressSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerAddressOrderBy>>
  where?: InputMaybe<ServerAddressBoolExp>
}

export type ServerQueryRootBankDetailsByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootBanksDetailsArgs = {
  distinct_on?: InputMaybe<Array<ServerBankDetailsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBankDetailsOrderBy>>
  where?: InputMaybe<ServerBankDetailsBoolExp>
}

export type ServerQueryRootBillingInfoByIdArgs = {
  id: Scalars['uuid']
}

export type ServerQueryRootBillingInfosArgs = {
  distinct_on?: InputMaybe<Array<ServerBillingInfoSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBillingInfoOrderBy>>
  where?: InputMaybe<ServerBillingInfoBoolExp>
}

export type ServerQueryRootBookingByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootBookingClientSettingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingClientSettingsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingClientSettingsOrderBy>>
  where?: InputMaybe<ServerBookingClientSettingsBoolExp>
}

export type ServerQueryRootBookingClientSettingsByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootBookingClientSettingsViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingClientSettingsViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingClientSettingsViewOrderBy>>
  where?: InputMaybe<ServerBookingClientSettingsViewBoolExp>
}

export type ServerQueryRootBookingReviewsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingReviewViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingReviewViewOrderBy>>
  where?: InputMaybe<ServerBookingReviewViewBoolExp>
}

export type ServerQueryRootBookingStatusesArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingStatusViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingStatusViewOrderBy>>
  where?: InputMaybe<ServerBookingStatusViewBoolExp>
}

export type ServerQueryRootBookingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

export type ServerQueryRootBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

export type ServerQueryRootBranchByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootBranchToSwitchAsClientViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBranchToSwitchAsClientViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBranchToSwitchAsClientViewOrderBy>>
  where?: InputMaybe<ServerBranchToSwitchAsClientViewBoolExp>
}

export type ServerQueryRootBranchViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBranchViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBranchViewOrderBy>>
  where?: InputMaybe<ServerBranchViewBoolExp>
}

export type ServerQueryRootBranchesArgs = {
  distinct_on?: InputMaybe<Array<ServerBranchSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBranchOrderBy>>
  where?: InputMaybe<ServerBranchBoolExp>
}

export type ServerQueryRootBusinessByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootBusinessCurrentPlanIdViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessCurrentPlanIdViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessCurrentPlanIdViewOrderBy>>
  where?: InputMaybe<ServerBusinessCurrentPlanIdViewBoolExp>
}

export type ServerQueryRootBusinessInfoViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessInfoViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessInfoViewOrderBy>>
  where?: InputMaybe<ServerBusinessInfoViewBoolExp>
}

export type ServerQueryRootBusinessInfosReferrerViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessInfoReferrerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessInfoReferrerViewOrderBy>>
  where?: InputMaybe<ServerBusinessInfoReferrerViewBoolExp>
}

export type ServerQueryRootBusinessIsTestViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessIsTestViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessIsTestViewOrderBy>>
  where?: InputMaybe<ServerBusinessIsTestViewBoolExp>
}

export type ServerQueryRootBusinessLandingByIdArgs = {
  id: Scalars['uuid']
}

export type ServerQueryRootBusinessLandingViewsArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessLandingViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessLandingViewOrderBy>>
  where?: InputMaybe<ServerBusinessLandingViewBoolExp>
}

export type ServerQueryRootBusinessLandingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessLandingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessLandingOrderBy>>
  where?: InputMaybe<ServerBusinessLandingBoolExp>
}

export type ServerQueryRootBusinessPromoCodeByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootBusinessPromoCodesArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessPromoCodeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessPromoCodeOrderBy>>
  where?: InputMaybe<ServerBusinessPromoCodeBoolExp>
}

export type ServerQueryRootBusinessPromoCodesReferrerViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessPromoCodeReferrerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessPromoCodeReferrerViewOrderBy>>
  where?: InputMaybe<ServerBusinessPromoCodeReferrerViewBoolExp>
}

export type ServerQueryRootBusinessPromoCodesViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessPromoCodeViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessPromoCodeViewOrderBy>>
  where?: InputMaybe<ServerBusinessPromoCodeViewBoolExp>
}

export type ServerQueryRootBusinessSettingsByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootBusinessTelegramIntegrationViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessTelegramIntegrationViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessTelegramIntegrationViewOrderBy>>
  where?: InputMaybe<ServerBusinessTelegramIntegrationViewBoolExp>
}

export type ServerQueryRootBusinessToSwitchArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessToSwitchSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessToSwitchOrderBy>>
  where?: InputMaybe<ServerBusinessToSwitchBoolExp>
}

export type ServerQueryRootBusinessTypeByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootBusinessTypesArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessTypeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessTypeOrderBy>>
  where?: InputMaybe<ServerBusinessTypeBoolExp>
}

export type ServerQueryRootBusinessesArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessOrderBy>>
  where?: InputMaybe<ServerBusinessBoolExp>
}

export type ServerQueryRootBusinessesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessOrderBy>>
  where?: InputMaybe<ServerBusinessBoolExp>
}

export type ServerQueryRootCardByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootCardTemplateByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootCardTemplateNameArgs = {
  distinct_on?: InputMaybe<Array<ServerCardTemplateNameViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerCardTemplateNameViewOrderBy>>
  where?: InputMaybe<ServerCardTemplateNameViewBoolExp>
}

export type ServerQueryRootCardTemplatesArgs = {
  distinct_on?: InputMaybe<Array<ServerCardTemplateSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerCardTemplateOrderBy>>
  where?: InputMaybe<ServerCardTemplateBoolExp>
}

export type ServerQueryRootCardsArgs = {
  distinct_on?: InputMaybe<Array<ServerCardSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerCardOrderBy>>
  where?: InputMaybe<ServerCardBoolExp>
}

export type ServerQueryRootCheckAvailabilityForBookingArgs = {
  branchId: Scalars['Int']
  clientId?: InputMaybe<Scalars['Int']>
  clientIds?: InputMaybe<Array<Scalars['Int']>>
  date: Scalars['timestamptz']
  employeeId?: InputMaybe<Scalars['Int']>
  locationId?: InputMaybe<Scalars['Int']>
  role: ServerCheckAvailabilityForBookingRoles
  servicesIds: Array<Scalars['Int']>
}

export type ServerQueryRootCheckAvailabilityForBookingAnonymouslyArgs = {
  branchId: Scalars['Int']
  businessId: Scalars['Int']
  date: Scalars['timestamptz']
  employeeId?: InputMaybe<Scalars['Int']>
  serviceIds: Array<Scalars['Int']>
}

export type ServerQueryRootCheckFreeTimeForMonthArgs = {
  branchId: Scalars['Int']
  employeeId?: InputMaybe<Scalars['Int']>
  serviceIds: Array<Scalars['Int']>
}

export type ServerQueryRootCheckFreeTimeForMonthAnonymouslyArgs = {
  branchId: Scalars['Int']
  businessId: Scalars['Int']
  employeeId?: InputMaybe<Scalars['Int']>
  serviceIds: Array<Scalars['Int']>
}

export type ServerQueryRootCheckNearestFreeTimeArgs = {
  branchId: Scalars['Int']
  serviceIds: Array<Scalars['Int']>
}

export type ServerQueryRootCheckNearestFreeTimeAnonymouslyArgs = {
  branchId: Scalars['Int']
  businessId: Scalars['Int']
  serviceIds: Array<Scalars['Int']>
}

export type ServerQueryRootCheckboxGetShiftsArgs = {
  softwarePOSId: Scalars['Int']
}

export type ServerQueryRootClientByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootClientDocumentByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootClientDocumentsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientDocumentSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientDocumentOrderBy>>
  where?: InputMaybe<ServerClientDocumentBoolExp>
}

export type ServerQueryRootClientEmailsViewArgs = {
  distinct_on?: InputMaybe<Array<ServerClientEmailViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientEmailViewOrderBy>>
  where?: InputMaybe<ServerClientEmailViewBoolExp>
}

export type ServerQueryRootClientNoteByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootClientNotesArgs = {
  distinct_on?: InputMaybe<Array<ServerClientNoteSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientNoteOrderBy>>
  where?: InputMaybe<ServerClientNoteBoolExp>
}

export type ServerQueryRootClientPhoneViewArgs = {
  distinct_on?: InputMaybe<Array<ServerClientPhoneViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientPhoneViewOrderBy>>
  where?: InputMaybe<ServerClientPhoneViewBoolExp>
}

export type ServerQueryRootClientRelationByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootClientRelationsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientRelationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientRelationOrderBy>>
  where?: InputMaybe<ServerClientRelationBoolExp>
}

export type ServerQueryRootClientSettingsByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootClientTagByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootClientTagsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientTagSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientTagOrderBy>>
  where?: InputMaybe<ServerClientTagBoolExp>
}

export type ServerQueryRootClientsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientOrderBy>>
  where?: InputMaybe<ServerClientBoolExp>
}

export type ServerQueryRootClientsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerClientSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientOrderBy>>
  where?: InputMaybe<ServerClientBoolExp>
}

export type ServerQueryRootClientsBalanceArgs = {
  distinct_on?: InputMaybe<Array<ServerClientBalanceViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientBalanceViewOrderBy>>
  where?: InputMaybe<ServerClientBalanceViewBoolExp>
}

export type ServerQueryRootClientsSettingsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientSettingsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientSettingsOrderBy>>
  where?: InputMaybe<ServerClientSettingsBoolExp>
}

export type ServerQueryRootEmployeeBusinessOwnersArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeBusinessOwnerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeBusinessOwnerViewOrderBy>>
  where?: InputMaybe<ServerEmployeeBusinessOwnerViewBoolExp>
}

export type ServerQueryRootEmployeeByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootEmployeeEmailsArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeEmailViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeEmailViewOrderBy>>
  where?: InputMaybe<ServerEmployeeEmailViewBoolExp>
}

export type ServerQueryRootEmployeeGroupArchivesArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeGroupArchiveViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeGroupArchiveViewOrderBy>>
  where?: InputMaybe<ServerEmployeeGroupArchiveViewBoolExp>
}

export type ServerQueryRootEmployeeGroupByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootEmployeeGroupOwnerViewArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeGroupOwnerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeGroupOwnerViewOrderBy>>
  where?: InputMaybe<ServerEmployeeGroupOwnerViewBoolExp>
}

export type ServerQueryRootEmployeeGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeGroupOrderBy>>
  where?: InputMaybe<ServerEmployeeGroupBoolExp>
}

export type ServerQueryRootEmployeeReviewsArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeReviewViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeReviewViewOrderBy>>
  where?: InputMaybe<ServerEmployeeReviewViewBoolExp>
}

export type ServerQueryRootEmployeeScheduleByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootEmployeeSchedulesArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeScheduleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeScheduleOrderBy>>
  where?: InputMaybe<ServerEmployeeScheduleBoolExp>
}

export type ServerQueryRootEmployeeSchedulesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeScheduleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeScheduleOrderBy>>
  where?: InputMaybe<ServerEmployeeScheduleBoolExp>
}

export type ServerQueryRootEmployeeSettingsArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeSettingsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeSettingsOrderBy>>
  where?: InputMaybe<ServerEmployeeSettingsBoolExp>
}

export type ServerQueryRootEmployeeSettingsByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootEmployeesArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeOrderBy>>
  where?: InputMaybe<ServerEmployeeBoolExp>
}

export type ServerQueryRootEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeOrderBy>>
  where?: InputMaybe<ServerEmployeeBoolExp>
}

export type ServerQueryRootExpaneInfoByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootExpaneInfoCategoriesArgs = {
  distinct_on?: InputMaybe<Array<ServerExpaneInfoCategorySelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerExpaneInfoCategoryOrderBy>>
  where?: InputMaybe<ServerExpaneInfoCategoryBoolExp>
}

export type ServerQueryRootExpaneInfoCategoryByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootExpaneInfosArgs = {
  distinct_on?: InputMaybe<Array<ServerExpaneInfoSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerExpaneInfoOrderBy>>
  where?: InputMaybe<ServerExpaneInfoBoolExp>
}

export type ServerQueryRootExpensesReasonByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootExpensesReasonsArgs = {
  distinct_on?: InputMaybe<Array<ServerExpensesReasonSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerExpensesReasonOrderBy>>
  where?: InputMaybe<ServerExpensesReasonBoolExp>
}

export type ServerQueryRootFeatureFlagsArgs = {
  distinct_on?: InputMaybe<Array<ServerFeatureFlagsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerFeatureFlagsOrderBy>>
  where?: InputMaybe<ServerFeatureFlagsBoolExp>
}

export type ServerQueryRootGetAvailableClientsArgs = {
  branchId: Scalars['Int']
  endDate: Scalars['timestamptz']
  startDate: Scalars['timestamptz']
}

export type ServerQueryRootGetBusinessToSwitchArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessToSwitchSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessToSwitchOrderBy>>
  where?: InputMaybe<ServerBusinessToSwitchBoolExp>
}

export type ServerQueryRootGetLeftoversArgs = {
  branchId: Scalars['Int']
  storageId?: InputMaybe<Scalars['Int']>
  toDate?: InputMaybe<Scalars['timestamptz']>
}

export type ServerQueryRootHumanByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootHumansArgs = {
  distinct_on?: InputMaybe<Array<ServerHumanSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerHumanOrderBy>>
  where?: InputMaybe<ServerHumanBoolExp>
}

export type ServerQueryRootInventoriesArgs = {
  distinct_on?: InputMaybe<Array<ServerInventorySelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerInventoryOrderBy>>
  where?: InputMaybe<ServerInventoryBoolExp>
}

export type ServerQueryRootInventoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerInventorySelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerInventoryOrderBy>>
  where?: InputMaybe<ServerInventoryBoolExp>
}

export type ServerQueryRootInventoryByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootLeadByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootLeadsArgs = {
  distinct_on?: InputMaybe<Array<ServerLeadSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerLeadOrderBy>>
  where?: InputMaybe<ServerLeadBoolExp>
}

export type ServerQueryRootLocationByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootLocationGroupByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootLocationGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerLocationGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerLocationGroupOrderBy>>
  where?: InputMaybe<ServerLocationGroupBoolExp>
}

export type ServerQueryRootLocationsArgs = {
  distinct_on?: InputMaybe<Array<ServerLocationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerLocationOrderBy>>
  where?: InputMaybe<ServerLocationBoolExp>
}

export type ServerQueryRootMovementByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootMovementProductByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootMovementProductsArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementProductOrderBy>>
  where?: InputMaybe<ServerMovementProductBoolExp>
}

export type ServerQueryRootMovementProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementProductOrderBy>>
  where?: InputMaybe<ServerMovementProductBoolExp>
}

export type ServerQueryRootMovementsArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementOrderBy>>
  where?: InputMaybe<ServerMovementBoolExp>
}

export type ServerQueryRootMovementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementOrderBy>>
  where?: InputMaybe<ServerMovementBoolExp>
}

export type ServerQueryRootMultipleBusinessSettingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessSettingsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessSettingsOrderBy>>
  where?: InputMaybe<ServerBusinessSettingsBoolExp>
}

export type ServerQueryRootNotificationByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<ServerNotificationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerNotificationOrderBy>>
  where?: InputMaybe<ServerNotificationBoolExp>
}

export type ServerQueryRootNotificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerNotificationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerNotificationOrderBy>>
  where?: InputMaybe<ServerNotificationBoolExp>
}

export type ServerQueryRootOnlineBookingUrlByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootOnlineBookingUrLsArgs = {
  distinct_on?: InputMaybe<Array<ServerOnlineBookingUrlSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerOnlineBookingUrlOrderBy>>
  where?: InputMaybe<ServerOnlineBookingUrlBoolExp>
}

export type ServerQueryRootPlanByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootPlansArgs = {
  distinct_on?: InputMaybe<Array<ServerPlanSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerPlanOrderBy>>
  where?: InputMaybe<ServerPlanBoolExp>
}

export type ServerQueryRootProductArchivesArgs = {
  distinct_on?: InputMaybe<Array<ServerProductArchiveViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductArchiveViewOrderBy>>
  where?: InputMaybe<ServerProductArchiveViewBoolExp>
}

export type ServerQueryRootProductByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootProductGroupArchivesArgs = {
  distinct_on?: InputMaybe<Array<ServerProductGroupArchiveViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductGroupArchiveViewOrderBy>>
  where?: InputMaybe<ServerProductGroupArchiveViewBoolExp>
}

export type ServerQueryRootProductGroupByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootProductGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerProductGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductGroupOrderBy>>
  where?: InputMaybe<ServerProductGroupBoolExp>
}

export type ServerQueryRootProductsArgs = {
  distinct_on?: InputMaybe<Array<ServerProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductOrderBy>>
  where?: InputMaybe<ServerProductBoolExp>
}

export type ServerQueryRootProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductOrderBy>>
  where?: InputMaybe<ServerProductBoolExp>
}

export type ServerQueryRootPromoCodeByIdArgs = {
  id: Scalars['String']
}

export type ServerQueryRootPromoCodesArgs = {
  distinct_on?: InputMaybe<Array<ServerPromoCodeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerPromoCodeOrderBy>>
  where?: InputMaybe<ServerPromoCodeBoolExp>
}

export type ServerQueryRootPromoCodesReferrerViewArgs = {
  distinct_on?: InputMaybe<Array<ServerPromoCodeReferrerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerPromoCodeReferrerViewOrderBy>>
  where?: InputMaybe<ServerPromoCodeReferrerViewBoolExp>
}

export type ServerQueryRootRecurringBookingByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootRecurringBookingsArgs = {
  distinct_on?: InputMaybe<Array<ServerRecurringBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerRecurringBookingOrderBy>>
  where?: InputMaybe<ServerRecurringBookingBoolExp>
}

export type ServerQueryRootReferrerByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootReferrersArgs = {
  distinct_on?: InputMaybe<Array<ServerReferrerSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerReferrerOrderBy>>
  where?: InputMaybe<ServerReferrerBoolExp>
}

export type ServerQueryRootReferrersViewArgs = {
  distinct_on?: InputMaybe<Array<ServerReferrerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerReferrerViewOrderBy>>
  where?: InputMaybe<ServerReferrerViewBoolExp>
}

export type ServerQueryRootRevenueReasonByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootRevenueReasonsArgs = {
  distinct_on?: InputMaybe<Array<ServerRevenueReasonSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerRevenueReasonOrderBy>>
  where?: InputMaybe<ServerRevenueReasonBoolExp>
}

export type ServerQueryRootReviewByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootReviewsArgs = {
  distinct_on?: InputMaybe<Array<ServerReviewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerReviewOrderBy>>
  where?: InputMaybe<ServerReviewBoolExp>
}

export type ServerQueryRootReviewsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerReviewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerReviewOrderBy>>
  where?: InputMaybe<ServerReviewBoolExp>
}

export type ServerQueryRootRoleByIdArgs = {
  id: Scalars['String']
}

export type ServerQueryRootRolePermissionArgs = {
  distinct_on?: InputMaybe<Array<ServerRolePermissionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerRolePermissionOrderBy>>
  where?: InputMaybe<ServerRolePermissionBoolExp>
}

export type ServerQueryRootRolePermissionByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootRolesArgs = {
  distinct_on?: InputMaybe<Array<ServerRoleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerRoleOrderBy>>
  where?: InputMaybe<ServerRoleBoolExp>
}

export type ServerQueryRootSalaryIssueByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootSalaryIssuesArgs = {
  distinct_on?: InputMaybe<Array<ServerSalaryIssueSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSalaryIssueOrderBy>>
  where?: InputMaybe<ServerSalaryIssueBoolExp>
}

export type ServerQueryRootSalarySettingByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootSalarySettingsArgs = {
  distinct_on?: InputMaybe<Array<ServerSalarySettingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSalarySettingOrderBy>>
  where?: InputMaybe<ServerSalarySettingBoolExp>
}

export type ServerQueryRootSalarySettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerSalarySettingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSalarySettingOrderBy>>
  where?: InputMaybe<ServerSalarySettingBoolExp>
}

export type ServerQueryRootScheduleByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootSchedulesArgs = {
  distinct_on?: InputMaybe<Array<ServerScheduleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerScheduleOrderBy>>
  where?: InputMaybe<ServerScheduleBoolExp>
}

export type ServerQueryRootServerStatusArgs = {
  distinct_on?: InputMaybe<Array<ServerServerStatusSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServerStatusOrderBy>>
  where?: InputMaybe<ServerServerStatusBoolExp>
}

export type ServerQueryRootServerStatusByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootServiceByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootServiceGroupByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootServiceGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerServiceGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServiceGroupOrderBy>>
  where?: InputMaybe<ServerServiceGroupBoolExp>
}

export type ServerQueryRootServicesArgs = {
  distinct_on?: InputMaybe<Array<ServerServiceSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServiceOrderBy>>
  where?: InputMaybe<ServerServiceBoolExp>
}

export type ServerQueryRootSoftwarePosByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootSoftwarePoSsArgs = {
  distinct_on?: InputMaybe<Array<ServerSoftwarePosSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSoftwarePosOrderBy>>
  where?: InputMaybe<ServerSoftwarePosBoolExp>
}

export type ServerQueryRootStorageByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootStoragesArgs = {
  distinct_on?: InputMaybe<Array<ServerStorageSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerStorageOrderBy>>
  where?: InputMaybe<ServerStorageBoolExp>
}

export type ServerQueryRootSubscriptionPeriodByIdArgs = {
  period: Scalars['String']
}

export type ServerQueryRootSubscriptionPeriodsArgs = {
  distinct_on?: InputMaybe<Array<ServerSubscriptionPeriodSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSubscriptionPeriodOrderBy>>
  where?: InputMaybe<ServerSubscriptionPeriodBoolExp>
}

export type ServerQueryRootSuperByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootSupersArgs = {
  distinct_on?: InputMaybe<Array<ServerSuperSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSuperOrderBy>>
  where?: InputMaybe<ServerSuperBoolExp>
}

export type ServerQueryRootSupplierByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootSuppliersArgs = {
  distinct_on?: InputMaybe<Array<ServerSupplierSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSupplierOrderBy>>
  where?: InputMaybe<ServerSupplierBoolExp>
}

export type ServerQueryRootTagByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootTagsArgs = {
  distinct_on?: InputMaybe<Array<ServerTagSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTagOrderBy>>
  where?: InputMaybe<ServerTagBoolExp>
}

export type ServerQueryRootTimeOffByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootTimeOffReasonByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootTimeOffReasonsArgs = {
  distinct_on?: InputMaybe<Array<ServerTimeOffReasonSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTimeOffReasonOrderBy>>
  where?: InputMaybe<ServerTimeOffReasonBoolExp>
}

export type ServerQueryRootTimeOffsArgs = {
  distinct_on?: InputMaybe<Array<ServerTimeOffSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTimeOffOrderBy>>
  where?: InputMaybe<ServerTimeOffBoolExp>
}

export type ServerQueryRootTransactionByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootTransactionsArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

export type ServerQueryRootTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

export type ServerQueryRootUnitedBookingByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootUnitedBookingsArgs = {
  distinct_on?: InputMaybe<Array<ServerUnitedBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerUnitedBookingOrderBy>>
  where?: InputMaybe<ServerUnitedBookingBoolExp>
}

export type ServerQueryRootUserExpoNotificationByEmailArgs = {
  email: Scalars['String']
}

export type ServerQueryRootUserExpoNotificationsArgs = {
  distinct_on?: InputMaybe<Array<ServerUserExpoNotificationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerUserExpoNotificationOrderBy>>
  where?: InputMaybe<ServerUserExpoNotificationBoolExp>
}

export type ServerQueryRootValidateBookingsArgs = {
  data: Array<ServerValidateBookingInput>
}

export type ServerQueryRootWaitingListByIdArgs = {
  id: Scalars['Int']
}

export type ServerQueryRootWaitingListsArgs = {
  distinct_on?: InputMaybe<Array<ServerWaitingListSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerWaitingListOrderBy>>
  where?: InputMaybe<ServerWaitingListBoolExp>
}

/** columns and relationships of "recurringBooking" */
export type ServerRecurringBooking = {
  /** An array relationship */
  bookings: Array<ServerBooking>
  /** An aggregate relationship */
  bookings_aggregate: ServerBookingAggregate
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  id: Scalars['Int']
}

/** columns and relationships of "recurringBooking" */
export type ServerRecurringBookingBookingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** columns and relationships of "recurringBooking" */
export type ServerRecurringBookingBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** Boolean expression to filter rows from the table "recurringBooking". All fields are combined with a logical 'AND'. */
export type ServerRecurringBookingBoolExp = {
  _and?: InputMaybe<Array<ServerRecurringBookingBoolExp>>
  _not?: InputMaybe<ServerRecurringBookingBoolExp>
  _or?: InputMaybe<Array<ServerRecurringBookingBoolExp>>
  bookings?: InputMaybe<ServerBookingBoolExp>
  bookings_aggregate?: InputMaybe<ServerBookingAggregateBoolExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "recurringBooking" */
export enum ServerRecurringBookingConstraint {
  /** unique or primary key constraint on columns "id" */
  RecurringBookingPkey = 'recurringBooking_pkey',
}

/** input type for inserting data into table "recurringBooking" */
export type ServerRecurringBookingInsertInput = {
  bookings?: InputMaybe<ServerBookingArrRelInsertInput>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
}

/** response of any mutation on the table "recurringBooking" */
export type ServerRecurringBookingMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerRecurringBooking>
}

/** input type for inserting object relation for remote table "recurringBooking" */
export type ServerRecurringBookingObjRelInsertInput = {
  data: ServerRecurringBookingInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerRecurringBookingOnConflict>
}

/** on_conflict condition type for table "recurringBooking" */
export type ServerRecurringBookingOnConflict = {
  constraint: ServerRecurringBookingConstraint
  update_columns?: Array<ServerRecurringBookingUpdateColumn>
  where?: InputMaybe<ServerRecurringBookingBoolExp>
}

/** Ordering options when selecting data from "recurringBooking". */
export type ServerRecurringBookingOrderBy = {
  bookings_aggregate?: InputMaybe<ServerBookingAggregateOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** select columns of table "recurringBooking" */
export enum ServerRecurringBookingSelectColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "recurringBooking" */
export type ServerRecurringBookingStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerRecurringBookingStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerRecurringBookingStreamCursorValueInput = {
  branchId?: InputMaybe<Scalars['Int']>
  businessId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
}

/** placeholder for update columns of table "recurringBooking" (current role has no relevant permissions) */
export enum ServerRecurringBookingUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "referrer" */
export type ServerReferrer = {
  createdAt: Scalars['timestamptz']
  email: Scalars['String']
  id: Scalars['Int']
  /** % */
  maxDiscount: Scalars['Int']
  name: Scalars['String']
  percentageReward: Scalars['Int']
  /** An array relationship */
  promoCodes: Array<ServerPromoCode>
}

/** columns and relationships of "referrer" */
export type ServerReferrerPromoCodesArgs = {
  distinct_on?: InputMaybe<Array<ServerPromoCodeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerPromoCodeOrderBy>>
  where?: InputMaybe<ServerPromoCodeBoolExp>
}

/** columns and relationships of "referrerView" */
export type ServerReferrerView = {
  createdAt: Maybe<Scalars['timestamptz']>
  email: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  maxDiscount: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  percentageReward: Maybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "referrerView". All fields are combined with a logical 'AND'. */
export type ServerReferrerViewBoolExp = {
  _and?: InputMaybe<Array<ServerReferrerViewBoolExp>>
  _not?: InputMaybe<ServerReferrerViewBoolExp>
  _or?: InputMaybe<Array<ServerReferrerViewBoolExp>>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  email?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  maxDiscount?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  percentageReward?: InputMaybe<ServerIntComparisonExp>
}

/** Ordering options when selecting data from "referrerView". */
export type ServerReferrerViewOrderBy = {
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  percentageReward?: InputMaybe<ServerOrderBy>
}

/** select columns of table "referrerView" */
export enum ServerReferrerViewSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  MaxDiscount = 'maxDiscount',
  /** column name */
  Name = 'name',
  /** column name */
  PercentageReward = 'percentageReward',
}

/** Streaming cursor of the table "referrerView" */
export type ServerReferrerViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerReferrerViewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerReferrerViewStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  maxDiscount?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  percentageReward?: InputMaybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "referrer". All fields are combined with a logical 'AND'. */
export type ServerReferrerBoolExp = {
  _and?: InputMaybe<Array<ServerReferrerBoolExp>>
  _not?: InputMaybe<ServerReferrerBoolExp>
  _or?: InputMaybe<Array<ServerReferrerBoolExp>>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  email?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  maxDiscount?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  percentageReward?: InputMaybe<ServerIntComparisonExp>
  promoCodes?: InputMaybe<ServerPromoCodeBoolExp>
}

/** unique or primary key constraints on table "referrer" */
export enum ServerReferrerConstraint {
  /** unique or primary key constraint on columns "email" */
  ReferrerEmailKey = 'referrer_email_key',
  /** unique or primary key constraint on columns "id" */
  ReferrerPkey = 'referrer_pkey',
}

/** input type for incrementing numeric columns in table "referrer" */
export type ServerReferrerIncInput = {
  id?: InputMaybe<Scalars['Int']>
  /** % */
  maxDiscount?: InputMaybe<Scalars['Int']>
  percentageReward?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "referrer" */
export type ServerReferrerInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  /** % */
  maxDiscount?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  percentageReward?: InputMaybe<Scalars['Int']>
  promoCodes?: InputMaybe<ServerPromoCodeArrRelInsertInput>
}

/** response of any mutation on the table "referrer" */
export type ServerReferrerMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerReferrer>
}

/** input type for inserting object relation for remote table "referrer" */
export type ServerReferrerObjRelInsertInput = {
  data: ServerReferrerInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerReferrerOnConflict>
}

/** on_conflict condition type for table "referrer" */
export type ServerReferrerOnConflict = {
  constraint: ServerReferrerConstraint
  update_columns?: Array<ServerReferrerUpdateColumn>
  where?: InputMaybe<ServerReferrerBoolExp>
}

/** Ordering options when selecting data from "referrer". */
export type ServerReferrerOrderBy = {
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  maxDiscount?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  percentageReward?: InputMaybe<ServerOrderBy>
  promoCodes_aggregate?: InputMaybe<ServerPromoCodeAggregateOrderBy>
}

/** primary key columns input for table: referrer */
export type ServerReferrerPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "referrer" */
export enum ServerReferrerSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  MaxDiscount = 'maxDiscount',
  /** column name */
  Name = 'name',
  /** column name */
  PercentageReward = 'percentageReward',
}

/** input type for updating data in table "referrer" */
export type ServerReferrerSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  /** % */
  maxDiscount?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  percentageReward?: InputMaybe<Scalars['Int']>
}

/** Streaming cursor of the table "referrer" */
export type ServerReferrerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerReferrerStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerReferrerStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  /** % */
  maxDiscount?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  percentageReward?: InputMaybe<Scalars['Int']>
}

/** update columns of table "referrer" */
export enum ServerReferrerUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  MaxDiscount = 'maxDiscount',
  /** column name */
  Name = 'name',
  /** column name */
  PercentageReward = 'percentageReward',
}

export type ServerReferrerUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerReferrerIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerReferrerSetInput>
  /** filter the rows which have to be updated */
  where: ServerReferrerBoolExp
}

/** columns and relationships of "revenueReason" */
export type ServerRevenueReason = {
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  businessId: Scalars['Int']
  id: Scalars['Int']
  name: Scalars['String']
}

/** Boolean expression to filter rows from the table "revenueReason". All fields are combined with a logical 'AND'. */
export type ServerRevenueReasonBoolExp = {
  _and?: InputMaybe<Array<ServerRevenueReasonBoolExp>>
  _not?: InputMaybe<ServerRevenueReasonBoolExp>
  _or?: InputMaybe<Array<ServerRevenueReasonBoolExp>>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "revenueReason" */
export enum ServerRevenueReasonConstraint {
  /** unique or primary key constraint on columns "id" */
  RevenueReasonPkey = 'revenueReason_pkey',
}

/** input type for inserting data into table "revenueReason" */
export type ServerRevenueReasonInsertInput = {
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "revenueReason" */
export type ServerRevenueReasonMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerRevenueReason>
}

/** input type for inserting object relation for remote table "revenueReason" */
export type ServerRevenueReasonObjRelInsertInput = {
  data: ServerRevenueReasonInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerRevenueReasonOnConflict>
}

/** on_conflict condition type for table "revenueReason" */
export type ServerRevenueReasonOnConflict = {
  constraint: ServerRevenueReasonConstraint
  update_columns?: Array<ServerRevenueReasonUpdateColumn>
  where?: InputMaybe<ServerRevenueReasonBoolExp>
}

/** Ordering options when selecting data from "revenueReason". */
export type ServerRevenueReasonOrderBy = {
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: revenueReason */
export type ServerRevenueReasonPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "revenueReason" */
export enum ServerRevenueReasonSelectColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "revenueReason" */
export type ServerRevenueReasonSetInput = {
  name?: InputMaybe<Scalars['String']>
}

/** update columns of table "revenueReason" */
export enum ServerRevenueReasonUpdateColumn {
  /** column name */
  Name = 'name',
}

export type ServerRevenueReasonUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerRevenueReasonSetInput>
  /** filter the rows which have to be updated */
  where: ServerRevenueReasonBoolExp
}

/** columns and relationships of "review" */
export type ServerReview = {
  approved: Scalars['Boolean']
  approvedBy: Maybe<Scalars['Int']>
  /** An object relationship */
  approvedByEmployee: Maybe<ServerEmployee>
  archived: Maybe<Scalars['timestamptz']>
  archivedBy: Maybe<Scalars['Int']>
  /** An object relationship */
  archivedByEmployee: Maybe<ServerEmployee>
  /** An object relationship */
  booking: ServerBooking
  bookingId: Scalars['Int']
  /** An object relationship */
  client: ServerClient
  clientId: Scalars['Int']
  createdAt: Scalars['timestamptz']
  id: Scalars['Int']
  rating: Scalars['smallint']
  text: Scalars['String']
  updatedAt: Maybe<Scalars['timestamptz']>
}

/** aggregated selection of "review" */
export type ServerReviewAggregate = {
  aggregate: Maybe<ServerReviewAggregateFields>
  nodes: Array<ServerReview>
}

/** aggregate fields of "review" */
export type ServerReviewAggregateFields = {
  avg: Maybe<ServerReviewAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerReviewMaxFields>
  min: Maybe<ServerReviewMinFields>
  stddev: Maybe<ServerReviewStddevFields>
  stddev_pop: Maybe<ServerReviewStddevPopFields>
  stddev_samp: Maybe<ServerReviewStddevSampFields>
  sum: Maybe<ServerReviewSumFields>
  var_pop: Maybe<ServerReviewVarPopFields>
  var_samp: Maybe<ServerReviewVarSampFields>
  variance: Maybe<ServerReviewVarianceFields>
}

/** aggregate fields of "review" */
export type ServerReviewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerReviewSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type ServerReviewAvgFields = {
  approvedBy: Maybe<Scalars['Float']>
  archivedBy: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  rating: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "review". All fields are combined with a logical 'AND'. */
export type ServerReviewBoolExp = {
  _and?: InputMaybe<Array<ServerReviewBoolExp>>
  _not?: InputMaybe<ServerReviewBoolExp>
  _or?: InputMaybe<Array<ServerReviewBoolExp>>
  approved?: InputMaybe<ServerBooleanComparisonExp>
  approvedBy?: InputMaybe<ServerIntComparisonExp>
  approvedByEmployee?: InputMaybe<ServerEmployeeBoolExp>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  archivedBy?: InputMaybe<ServerIntComparisonExp>
  archivedByEmployee?: InputMaybe<ServerEmployeeBoolExp>
  booking?: InputMaybe<ServerBookingBoolExp>
  bookingId?: InputMaybe<ServerIntComparisonExp>
  client?: InputMaybe<ServerClientBoolExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  rating?: InputMaybe<ServerSmallintComparisonExp>
  text?: InputMaybe<ServerStringComparisonExp>
  updatedAt?: InputMaybe<ServerTimestamptzComparisonExp>
}

/** unique or primary key constraints on table "review" */
export enum ServerReviewConstraint {
  /** unique or primary key constraint on columns "bookingId" */
  ReviewBookingIdKey = 'review_bookingId_key',
  /** unique or primary key constraint on columns "id" */
  ReviewPkey = 'review_pkey',
}

/** input type for inserting data into table "review" */
export type ServerReviewInsertInput = {
  approvedByEmployee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  archivedByEmployee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  booking?: InputMaybe<ServerBookingObjRelInsertInput>
  bookingId?: InputMaybe<Scalars['Int']>
  client?: InputMaybe<ServerClientObjRelInsertInput>
  clientId?: InputMaybe<Scalars['Int']>
  rating?: InputMaybe<Scalars['smallint']>
  text?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type ServerReviewMaxFields = {
  approvedBy: Maybe<Scalars['Int']>
  archived: Maybe<Scalars['timestamptz']>
  archivedBy: Maybe<Scalars['Int']>
  bookingId: Maybe<Scalars['Int']>
  clientId: Maybe<Scalars['Int']>
  createdAt: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
  rating: Maybe<Scalars['smallint']>
  text: Maybe<Scalars['String']>
  updatedAt: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type ServerReviewMinFields = {
  approvedBy: Maybe<Scalars['Int']>
  archived: Maybe<Scalars['timestamptz']>
  archivedBy: Maybe<Scalars['Int']>
  bookingId: Maybe<Scalars['Int']>
  clientId: Maybe<Scalars['Int']>
  createdAt: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
  rating: Maybe<Scalars['smallint']>
  text: Maybe<Scalars['String']>
  updatedAt: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "review" */
export type ServerReviewMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerReview>
}

/** input type for inserting object relation for remote table "review" */
export type ServerReviewObjRelInsertInput = {
  data: ServerReviewInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerReviewOnConflict>
}

/** on_conflict condition type for table "review" */
export type ServerReviewOnConflict = {
  constraint: ServerReviewConstraint
  update_columns?: Array<ServerReviewUpdateColumn>
  where?: InputMaybe<ServerReviewBoolExp>
}

/** Ordering options when selecting data from "review". */
export type ServerReviewOrderBy = {
  approved?: InputMaybe<ServerOrderBy>
  approvedBy?: InputMaybe<ServerOrderBy>
  approvedByEmployee?: InputMaybe<ServerEmployeeOrderBy>
  archived?: InputMaybe<ServerOrderBy>
  archivedBy?: InputMaybe<ServerOrderBy>
  archivedByEmployee?: InputMaybe<ServerEmployeeOrderBy>
  booking?: InputMaybe<ServerBookingOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rating?: InputMaybe<ServerOrderBy>
  text?: InputMaybe<ServerOrderBy>
  updatedAt?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: review */
export type ServerReviewPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "review" */
export enum ServerReviewSelectColumn {
  /** column name */
  Approved = 'approved',
  /** column name */
  ApprovedBy = 'approvedBy',
  /** column name */
  Archived = 'archived',
  /** column name */
  ArchivedBy = 'archivedBy',
  /** column name */
  BookingId = 'bookingId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "review" */
export type ServerReviewSetInput = {
  approved?: InputMaybe<Scalars['Boolean']>
  archived?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type ServerReviewStddevFields = {
  approvedBy: Maybe<Scalars['Float']>
  archivedBy: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  rating: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type ServerReviewStddevPopFields = {
  approvedBy: Maybe<Scalars['Float']>
  archivedBy: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  rating: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type ServerReviewStddevSampFields = {
  approvedBy: Maybe<Scalars['Float']>
  archivedBy: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  rating: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "review" */
export type ServerReviewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerReviewStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerReviewStreamCursorValueInput = {
  approved?: InputMaybe<Scalars['Boolean']>
  approvedBy?: InputMaybe<Scalars['Int']>
  archived?: InputMaybe<Scalars['timestamptz']>
  archivedBy?: InputMaybe<Scalars['Int']>
  bookingId?: InputMaybe<Scalars['Int']>
  clientId?: InputMaybe<Scalars['Int']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['Int']>
  rating?: InputMaybe<Scalars['smallint']>
  text?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type ServerReviewSumFields = {
  approvedBy: Maybe<Scalars['Int']>
  archivedBy: Maybe<Scalars['Int']>
  bookingId: Maybe<Scalars['Int']>
  clientId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  rating: Maybe<Scalars['smallint']>
}

/** update columns of table "review" */
export enum ServerReviewUpdateColumn {
  /** column name */
  Approved = 'approved',
  /** column name */
  Archived = 'archived',
}

export type ServerReviewUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerReviewSetInput>
  /** filter the rows which have to be updated */
  where: ServerReviewBoolExp
}

/** aggregate var_pop on columns */
export type ServerReviewVarPopFields = {
  approvedBy: Maybe<Scalars['Float']>
  archivedBy: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  rating: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type ServerReviewVarSampFields = {
  approvedBy: Maybe<Scalars['Float']>
  archivedBy: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  rating: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type ServerReviewVarianceFields = {
  approvedBy: Maybe<Scalars['Float']>
  archivedBy: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  rating: Maybe<Scalars['Float']>
}

/** Роли пользователей */
export type ServerRole = {
  /** An array relationship */
  employeeGroups: Array<ServerEmployeeGroup>
  id: Scalars['String']
  name: Maybe<Scalars['String']>
  /** An array relationship */
  rolePermissions: Array<ServerRolePermission>
}

/** Роли пользователей */
export type ServerRoleEmployeeGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeGroupOrderBy>>
  where?: InputMaybe<ServerEmployeeGroupBoolExp>
}

/** Роли пользователей */
export type ServerRoleRolePermissionsArgs = {
  distinct_on?: InputMaybe<Array<ServerRolePermissionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerRolePermissionOrderBy>>
  where?: InputMaybe<ServerRolePermissionBoolExp>
}

/** columns and relationships of "rolePermission" */
export type ServerRolePermission = {
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  id: Scalars['Int']
  permissionId: Scalars['String']
  /** An object relationship */
  role: ServerRole
  roleId: Scalars['String']
}

/** order by aggregate values of table "rolePermission" */
export type ServerRolePermissionAggregateOrderBy = {
  avg?: InputMaybe<ServerRolePermissionAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerRolePermissionMaxOrderBy>
  min?: InputMaybe<ServerRolePermissionMinOrderBy>
  stddev?: InputMaybe<ServerRolePermissionStddevOrderBy>
  stddev_pop?: InputMaybe<ServerRolePermissionStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerRolePermissionStddevSampOrderBy>
  sum?: InputMaybe<ServerRolePermissionSumOrderBy>
  var_pop?: InputMaybe<ServerRolePermissionVarPopOrderBy>
  var_samp?: InputMaybe<ServerRolePermissionVarSampOrderBy>
  variance?: InputMaybe<ServerRolePermissionVarianceOrderBy>
}

/** order by avg() on columns of table "rolePermission" */
export type ServerRolePermissionAvgOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "rolePermission". All fields are combined with a logical 'AND'. */
export type ServerRolePermissionBoolExp = {
  _and?: InputMaybe<Array<ServerRolePermissionBoolExp>>
  _not?: InputMaybe<ServerRolePermissionBoolExp>
  _or?: InputMaybe<Array<ServerRolePermissionBoolExp>>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  permissionId?: InputMaybe<ServerStringComparisonExp>
  role?: InputMaybe<ServerRoleBoolExp>
  roleId?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "rolePermission" */
export enum ServerRolePermissionConstraint {
  /** unique or primary key constraint on columns "permissionId", "roleId", "businessId" */
  RolePermissionRoleIdPermissionIdBusinessIdKey = 'rolePermission_roleId_permissionId_businessId_key',
  /** unique or primary key constraint on columns "id" */
  RolesPermissionsPkey = 'rolesPermissions_pkey',
}

/** input type for inserting data into table "rolePermission" */
export type ServerRolePermissionInsertInput = {
  permissionId?: InputMaybe<Scalars['String']>
  roleId?: InputMaybe<Scalars['String']>
}

/** order by max() on columns of table "rolePermission" */
export type ServerRolePermissionMaxOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  permissionId?: InputMaybe<ServerOrderBy>
  roleId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "rolePermission" */
export type ServerRolePermissionMinOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  permissionId?: InputMaybe<ServerOrderBy>
  roleId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "rolePermission" */
export type ServerRolePermissionMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerRolePermission>
}

/** on_conflict condition type for table "rolePermission" */
export type ServerRolePermissionOnConflict = {
  constraint: ServerRolePermissionConstraint
  update_columns?: Array<ServerRolePermissionUpdateColumn>
  where?: InputMaybe<ServerRolePermissionBoolExp>
}

/** Ordering options when selecting data from "rolePermission". */
export type ServerRolePermissionOrderBy = {
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  permissionId?: InputMaybe<ServerOrderBy>
  role?: InputMaybe<ServerRoleOrderBy>
  roleId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "rolePermission" */
export enum ServerRolePermissionSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permissionId',
  /** column name */
  RoleId = 'roleId',
}

/** order by stddev() on columns of table "rolePermission" */
export type ServerRolePermissionStddevOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "rolePermission" */
export type ServerRolePermissionStddevPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "rolePermission" */
export type ServerRolePermissionStddevSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "rolePermission" */
export type ServerRolePermissionSumOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "rolePermission" (current role has no relevant permissions) */
export enum ServerRolePermissionUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "rolePermission" */
export type ServerRolePermissionVarPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "rolePermission" */
export type ServerRolePermissionVarSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "rolePermission" */
export type ServerRolePermissionVarianceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type ServerRoleBoolExp = {
  _and?: InputMaybe<Array<ServerRoleBoolExp>>
  _not?: InputMaybe<ServerRoleBoolExp>
  _or?: InputMaybe<Array<ServerRoleBoolExp>>
  employeeGroups?: InputMaybe<ServerEmployeeGroupBoolExp>
  id?: InputMaybe<ServerStringComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  rolePermissions?: InputMaybe<ServerRolePermissionBoolExp>
}

/** Ordering options when selecting data from "role". */
export type ServerRoleOrderBy = {
  employeeGroups_aggregate?: InputMaybe<ServerEmployeeGroupAggregateOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  rolePermissions_aggregate?: InputMaybe<ServerRolePermissionAggregateOrderBy>
}

/** select columns of table "role" */
export enum ServerRoleSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "role" */
export type ServerRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerRoleStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerRoleStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "salaryIssue" */
export type ServerSalaryIssue = {
  /** An object relationship */
  author: Maybe<ServerEmployee>
  authorId: Maybe<Scalars['Int']>
  /** An object relationship */
  booking: Maybe<ServerBooking>
  /** Запись, если начисление за услугу в записи */
  bookingId: Maybe<Scalars['Int']>
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** Используется для премий и штрафов */
  comment: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  date: Scalars['timestamptz']
  /** An object relationship */
  employee: ServerEmployee
  employeeId: Scalars['Int']
  /** Конец периода(для ставок) */
  endPeriod: Maybe<Scalars['timestamptz']>
  id: Scalars['Int']
  /** An object relationship */
  paymentTransaction: Maybe<ServerTransaction>
  paymentTransactionId: Maybe<Scalars['Int']>
  /** An object relationship */
  paymentTransactionService: Maybe<ServerTransactionService>
  paymentTransactionServiceId: Maybe<Scalars['Int']>
  /** An object relationship */
  salarySetting: Maybe<ServerSalarySetting>
  salarySettingId: Maybe<Scalars['Int']>
  /** An object relationship */
  salarySettingService: Maybe<ServerSalarySettingService>
  /** Настройка зарплаты по которой было начисление за услугу */
  salarySettingServiceId: Maybe<Scalars['Int']>
  /** An object relationship */
  salaryTransaction: Maybe<ServerTransaction>
  /** Транзакция оплаты этого начисления */
  salaryTransactionId: Maybe<Scalars['Int']>
  /** Старт периода(для ставок) */
  startPeriod: Maybe<Scalars['timestamptz']>
  /** Тип начисления (премия, штраф, ставка, за сервис и тд) */
  type: Scalars['smallint']
  value: Scalars['numeric']
  /** A computed field, executes function "calculate_salary_issue_when_dependent_on_realized_value" */
  valueWhenDependentOnRealized: Maybe<Scalars['numeric']>
}

/** order by aggregate values of table "salaryIssue" */
export type ServerSalaryIssueAggregateOrderBy = {
  avg?: InputMaybe<ServerSalaryIssueAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerSalaryIssueMaxOrderBy>
  min?: InputMaybe<ServerSalaryIssueMinOrderBy>
  stddev?: InputMaybe<ServerSalaryIssueStddevOrderBy>
  stddev_pop?: InputMaybe<ServerSalaryIssueStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerSalaryIssueStddevSampOrderBy>
  sum?: InputMaybe<ServerSalaryIssueSumOrderBy>
  var_pop?: InputMaybe<ServerSalaryIssueVarPopOrderBy>
  var_samp?: InputMaybe<ServerSalaryIssueVarSampOrderBy>
  variance?: InputMaybe<ServerSalaryIssueVarianceOrderBy>
}

/** input type for inserting array relation for remote table "salaryIssue" */
export type ServerSalaryIssueArrRelInsertInput = {
  data: Array<ServerSalaryIssueInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerSalaryIssueOnConflict>
}

/** order by avg() on columns of table "salaryIssue" */
export type ServerSalaryIssueAvgOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  /** Запись, если начисление за услугу в записи */
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  paymentTransactionId?: InputMaybe<ServerOrderBy>
  paymentTransactionServiceId?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  /** Настройка зарплаты по которой было начисление за услугу */
  salarySettingServiceId?: InputMaybe<ServerOrderBy>
  /** Транзакция оплаты этого начисления */
  salaryTransactionId?: InputMaybe<ServerOrderBy>
  /** Тип начисления (премия, штраф, ставка, за сервис и тд) */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "salaryIssue". All fields are combined with a logical 'AND'. */
export type ServerSalaryIssueBoolExp = {
  _and?: InputMaybe<Array<ServerSalaryIssueBoolExp>>
  _not?: InputMaybe<ServerSalaryIssueBoolExp>
  _or?: InputMaybe<Array<ServerSalaryIssueBoolExp>>
  author?: InputMaybe<ServerEmployeeBoolExp>
  authorId?: InputMaybe<ServerIntComparisonExp>
  booking?: InputMaybe<ServerBookingBoolExp>
  bookingId?: InputMaybe<ServerIntComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  comment?: InputMaybe<ServerStringComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  date?: InputMaybe<ServerTimestamptzComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  endPeriod?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  paymentTransaction?: InputMaybe<ServerTransactionBoolExp>
  paymentTransactionId?: InputMaybe<ServerIntComparisonExp>
  paymentTransactionService?: InputMaybe<ServerTransactionServiceBoolExp>
  paymentTransactionServiceId?: InputMaybe<ServerIntComparisonExp>
  salarySetting?: InputMaybe<ServerSalarySettingBoolExp>
  salarySettingId?: InputMaybe<ServerIntComparisonExp>
  salarySettingService?: InputMaybe<ServerSalarySettingServiceBoolExp>
  salarySettingServiceId?: InputMaybe<ServerIntComparisonExp>
  salaryTransaction?: InputMaybe<ServerTransactionBoolExp>
  salaryTransactionId?: InputMaybe<ServerIntComparisonExp>
  startPeriod?: InputMaybe<ServerTimestamptzComparisonExp>
  type?: InputMaybe<ServerSmallintComparisonExp>
  value?: InputMaybe<ServerNumericComparisonExp>
  valueWhenDependentOnRealized?: InputMaybe<ServerNumericComparisonExp>
}

/** unique or primary key constraints on table "salaryIssue" */
export enum ServerSalaryIssueConstraint {
  /** unique or primary key constraint on columns "id" */
  SalaryIssuePkey = 'salaryIssue_pkey',
}

/** input type for inserting data into table "salaryIssue" */
export type ServerSalaryIssueInsertInput = {
  author?: InputMaybe<ServerEmployeeObjRelInsertInput>
  booking?: InputMaybe<ServerBookingObjRelInsertInput>
  /** Запись, если начисление за услугу в записи */
  bookingId?: InputMaybe<Scalars['Int']>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  /** Используется для премий и штрафов */
  comment?: InputMaybe<Scalars['String']>
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employeeId?: InputMaybe<Scalars['Int']>
  /** Конец периода(для ставок) */
  endPeriod?: InputMaybe<Scalars['timestamptz']>
  paymentTransaction?: InputMaybe<ServerTransactionObjRelInsertInput>
  paymentTransactionService?: InputMaybe<ServerTransactionServiceObjRelInsertInput>
  salarySetting?: InputMaybe<ServerSalarySettingObjRelInsertInput>
  salarySettingId?: InputMaybe<Scalars['Int']>
  salarySettingService?: InputMaybe<ServerSalarySettingServiceObjRelInsertInput>
  /** Настройка зарплаты по которой было начисление за услугу */
  salarySettingServiceId?: InputMaybe<Scalars['Int']>
  salaryTransaction?: InputMaybe<ServerTransactionObjRelInsertInput>
  /** Транзакция оплаты этого начисления */
  salaryTransactionId?: InputMaybe<Scalars['Int']>
  /** Старт периода(для ставок) */
  startPeriod?: InputMaybe<Scalars['timestamptz']>
  /** Тип начисления (премия, штраф, ставка, за сервис и тд) */
  type?: InputMaybe<Scalars['smallint']>
  value?: InputMaybe<Scalars['numeric']>
}

/** order by max() on columns of table "salaryIssue" */
export type ServerSalaryIssueMaxOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  /** Запись, если начисление за услугу в записи */
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  /** Используется для премий и штрафов */
  comment?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  date?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Конец периода(для ставок) */
  endPeriod?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  paymentTransactionId?: InputMaybe<ServerOrderBy>
  paymentTransactionServiceId?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  /** Настройка зарплаты по которой было начисление за услугу */
  salarySettingServiceId?: InputMaybe<ServerOrderBy>
  /** Транзакция оплаты этого начисления */
  salaryTransactionId?: InputMaybe<ServerOrderBy>
  /** Старт периода(для ставок) */
  startPeriod?: InputMaybe<ServerOrderBy>
  /** Тип начисления (премия, штраф, ставка, за сервис и тд) */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "salaryIssue" */
export type ServerSalaryIssueMinOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  /** Запись, если начисление за услугу в записи */
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  /** Используется для премий и штрафов */
  comment?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  date?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Конец периода(для ставок) */
  endPeriod?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  paymentTransactionId?: InputMaybe<ServerOrderBy>
  paymentTransactionServiceId?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  /** Настройка зарплаты по которой было начисление за услугу */
  salarySettingServiceId?: InputMaybe<ServerOrderBy>
  /** Транзакция оплаты этого начисления */
  salaryTransactionId?: InputMaybe<ServerOrderBy>
  /** Старт периода(для ставок) */
  startPeriod?: InputMaybe<ServerOrderBy>
  /** Тип начисления (премия, штраф, ставка, за сервис и тд) */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "salaryIssue" */
export type ServerSalaryIssueMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerSalaryIssue>
}

/** on_conflict condition type for table "salaryIssue" */
export type ServerSalaryIssueOnConflict = {
  constraint: ServerSalaryIssueConstraint
  update_columns?: Array<ServerSalaryIssueUpdateColumn>
  where?: InputMaybe<ServerSalaryIssueBoolExp>
}

/** Ordering options when selecting data from "salaryIssue". */
export type ServerSalaryIssueOrderBy = {
  author?: InputMaybe<ServerEmployeeOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  booking?: InputMaybe<ServerBookingOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  comment?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  date?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  endPeriod?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  paymentTransaction?: InputMaybe<ServerTransactionOrderBy>
  paymentTransactionId?: InputMaybe<ServerOrderBy>
  paymentTransactionService?: InputMaybe<ServerTransactionServiceOrderBy>
  paymentTransactionServiceId?: InputMaybe<ServerOrderBy>
  salarySetting?: InputMaybe<ServerSalarySettingOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  salarySettingService?: InputMaybe<ServerSalarySettingServiceOrderBy>
  salarySettingServiceId?: InputMaybe<ServerOrderBy>
  salaryTransaction?: InputMaybe<ServerTransactionOrderBy>
  salaryTransactionId?: InputMaybe<ServerOrderBy>
  startPeriod?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
  valueWhenDependentOnRealized?: InputMaybe<ServerOrderBy>
}

/** select columns of table "salaryIssue" */
export enum ServerSalaryIssueSelectColumn {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  BookingId = 'bookingId',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Date = 'date',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  EndPeriod = 'endPeriod',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentTransactionId = 'paymentTransactionId',
  /** column name */
  PaymentTransactionServiceId = 'paymentTransactionServiceId',
  /** column name */
  SalarySettingId = 'salarySettingId',
  /** column name */
  SalarySettingServiceId = 'salarySettingServiceId',
  /** column name */
  SalaryTransactionId = 'salaryTransactionId',
  /** column name */
  StartPeriod = 'startPeriod',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value',
}

/** order by stddev() on columns of table "salaryIssue" */
export type ServerSalaryIssueStddevOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  /** Запись, если начисление за услугу в записи */
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  paymentTransactionId?: InputMaybe<ServerOrderBy>
  paymentTransactionServiceId?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  /** Настройка зарплаты по которой было начисление за услугу */
  salarySettingServiceId?: InputMaybe<ServerOrderBy>
  /** Транзакция оплаты этого начисления */
  salaryTransactionId?: InputMaybe<ServerOrderBy>
  /** Тип начисления (премия, штраф, ставка, за сервис и тд) */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "salaryIssue" */
export type ServerSalaryIssueStddevPopOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  /** Запись, если начисление за услугу в записи */
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  paymentTransactionId?: InputMaybe<ServerOrderBy>
  paymentTransactionServiceId?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  /** Настройка зарплаты по которой было начисление за услугу */
  salarySettingServiceId?: InputMaybe<ServerOrderBy>
  /** Транзакция оплаты этого начисления */
  salaryTransactionId?: InputMaybe<ServerOrderBy>
  /** Тип начисления (премия, штраф, ставка, за сервис и тд) */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "salaryIssue" */
export type ServerSalaryIssueStddevSampOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  /** Запись, если начисление за услугу в записи */
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  paymentTransactionId?: InputMaybe<ServerOrderBy>
  paymentTransactionServiceId?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  /** Настройка зарплаты по которой было начисление за услугу */
  salarySettingServiceId?: InputMaybe<ServerOrderBy>
  /** Транзакция оплаты этого начисления */
  salaryTransactionId?: InputMaybe<ServerOrderBy>
  /** Тип начисления (премия, штраф, ставка, за сервис и тд) */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "salaryIssue" */
export type ServerSalaryIssueSumOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  /** Запись, если начисление за услугу в записи */
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  paymentTransactionId?: InputMaybe<ServerOrderBy>
  paymentTransactionServiceId?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  /** Настройка зарплаты по которой было начисление за услугу */
  salarySettingServiceId?: InputMaybe<ServerOrderBy>
  /** Транзакция оплаты этого начисления */
  salaryTransactionId?: InputMaybe<ServerOrderBy>
  /** Тип начисления (премия, штраф, ставка, за сервис и тд) */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "salaryIssue" (current role has no relevant permissions) */
export enum ServerSalaryIssueUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "salaryIssue" */
export type ServerSalaryIssueVarPopOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  /** Запись, если начисление за услугу в записи */
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  paymentTransactionId?: InputMaybe<ServerOrderBy>
  paymentTransactionServiceId?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  /** Настройка зарплаты по которой было начисление за услугу */
  salarySettingServiceId?: InputMaybe<ServerOrderBy>
  /** Транзакция оплаты этого начисления */
  salaryTransactionId?: InputMaybe<ServerOrderBy>
  /** Тип начисления (премия, штраф, ставка, за сервис и тд) */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "salaryIssue" */
export type ServerSalaryIssueVarSampOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  /** Запись, если начисление за услугу в записи */
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  paymentTransactionId?: InputMaybe<ServerOrderBy>
  paymentTransactionServiceId?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  /** Настройка зарплаты по которой было начисление за услугу */
  salarySettingServiceId?: InputMaybe<ServerOrderBy>
  /** Транзакция оплаты этого начисления */
  salaryTransactionId?: InputMaybe<ServerOrderBy>
  /** Тип начисления (премия, штраф, ставка, за сервис и тд) */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "salaryIssue" */
export type ServerSalaryIssueVarianceOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  /** Запись, если начисление за услугу в записи */
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  paymentTransactionId?: InputMaybe<ServerOrderBy>
  paymentTransactionServiceId?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  /** Настройка зарплаты по которой было начисление за услугу */
  salarySettingServiceId?: InputMaybe<ServerOrderBy>
  /** Транзакция оплаты этого начисления */
  salaryTransactionId?: InputMaybe<ServerOrderBy>
  /** Тип начисления (премия, штраф, ставка, за сервис и тд) */
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "salarySetting" */
export type ServerSalarySetting = {
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** An object relationship */
  employee: ServerEmployee
  employeeId: Scalars['Int']
  /** Дата, проставляемая при архивации сотрудника */
  end: Maybe<Scalars['timestamptz']>
  id: Scalars['Int']
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType: Scalars['smallint']
  rateValue: Scalars['numeric']
  referralPercentage: Scalars['Int']
  /** An array relationship */
  salarySettingServices: Array<ServerSalarySettingService>
  start: Scalars['timestamptz']
}

/** columns and relationships of "salarySetting" */
export type ServerSalarySettingSalarySettingServicesArgs = {
  distinct_on?: InputMaybe<Array<ServerSalarySettingServiceSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSalarySettingServiceOrderBy>>
  where?: InputMaybe<ServerSalarySettingServiceBoolExp>
}

/** columns and relationships of "salarySettingService" */
export type ServerSalarySettingService = {
  businessId: Scalars['Int']
  dependsOnRealizedValue: Scalars['Boolean']
  /** 1 - fixed, 2 - percent */
  fallbackType: Maybe<Scalars['smallint']>
  fallbackValue: Maybe<Scalars['Int']>
  id: Scalars['Int']
  /** An object relationship */
  salarySetting: ServerSalarySetting
  salarySettingId: Scalars['Int']
  /** An object relationship */
  service: ServerService
  serviceId: Scalars['Int']
  /** Тип формулы */
  type: Scalars['smallint']
  /** В зависимости от формулы это может быть процент или значение */
  value: Scalars['numeric']
}

/** order by aggregate values of table "salarySettingService" */
export type ServerSalarySettingServiceAggregateOrderBy = {
  avg?: InputMaybe<ServerSalarySettingServiceAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerSalarySettingServiceMaxOrderBy>
  min?: InputMaybe<ServerSalarySettingServiceMinOrderBy>
  stddev?: InputMaybe<ServerSalarySettingServiceStddevOrderBy>
  stddev_pop?: InputMaybe<ServerSalarySettingServiceStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerSalarySettingServiceStddevSampOrderBy>
  sum?: InputMaybe<ServerSalarySettingServiceSumOrderBy>
  var_pop?: InputMaybe<ServerSalarySettingServiceVarPopOrderBy>
  var_samp?: InputMaybe<ServerSalarySettingServiceVarSampOrderBy>
  variance?: InputMaybe<ServerSalarySettingServiceVarianceOrderBy>
}

/** input type for inserting array relation for remote table "salarySettingService" */
export type ServerSalarySettingServiceArrRelInsertInput = {
  data: Array<ServerSalarySettingServiceInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerSalarySettingServiceOnConflict>
}

/** order by avg() on columns of table "salarySettingService" */
export type ServerSalarySettingServiceAvgOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  /** 1 - fixed, 2 - percent */
  fallbackType?: InputMaybe<ServerOrderBy>
  fallbackValue?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  /** Тип формулы */
  type?: InputMaybe<ServerOrderBy>
  /** В зависимости от формулы это может быть процент или значение */
  value?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "salarySettingService". All fields are combined with a logical 'AND'. */
export type ServerSalarySettingServiceBoolExp = {
  _and?: InputMaybe<Array<ServerSalarySettingServiceBoolExp>>
  _not?: InputMaybe<ServerSalarySettingServiceBoolExp>
  _or?: InputMaybe<Array<ServerSalarySettingServiceBoolExp>>
  businessId?: InputMaybe<ServerIntComparisonExp>
  dependsOnRealizedValue?: InputMaybe<ServerBooleanComparisonExp>
  fallbackType?: InputMaybe<ServerSmallintComparisonExp>
  fallbackValue?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  salarySetting?: InputMaybe<ServerSalarySettingBoolExp>
  salarySettingId?: InputMaybe<ServerIntComparisonExp>
  service?: InputMaybe<ServerServiceBoolExp>
  serviceId?: InputMaybe<ServerIntComparisonExp>
  type?: InputMaybe<ServerSmallintComparisonExp>
  value?: InputMaybe<ServerNumericComparisonExp>
}

/** unique or primary key constraints on table "salarySettingService" */
export enum ServerSalarySettingServiceConstraint {
  /** unique or primary key constraint on columns "id" */
  SalarySettingServicePkey = 'salarySettingService_pkey',
}

/** input type for inserting data into table "salarySettingService" */
export type ServerSalarySettingServiceInsertInput = {
  dependsOnRealizedValue?: InputMaybe<Scalars['Boolean']>
  /** 1 - fixed, 2 - percent */
  fallbackType?: InputMaybe<Scalars['smallint']>
  fallbackValue?: InputMaybe<Scalars['Int']>
  salarySetting?: InputMaybe<ServerSalarySettingObjRelInsertInput>
  salarySettingId?: InputMaybe<Scalars['Int']>
  service?: InputMaybe<ServerServiceObjRelInsertInput>
  serviceId?: InputMaybe<Scalars['Int']>
  /** Тип формулы */
  type?: InputMaybe<Scalars['smallint']>
  /** В зависимости от формулы это может быть процент или значение */
  value?: InputMaybe<Scalars['numeric']>
}

/** order by max() on columns of table "salarySettingService" */
export type ServerSalarySettingServiceMaxOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  /** 1 - fixed, 2 - percent */
  fallbackType?: InputMaybe<ServerOrderBy>
  fallbackValue?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  /** Тип формулы */
  type?: InputMaybe<ServerOrderBy>
  /** В зависимости от формулы это может быть процент или значение */
  value?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "salarySettingService" */
export type ServerSalarySettingServiceMinOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  /** 1 - fixed, 2 - percent */
  fallbackType?: InputMaybe<ServerOrderBy>
  fallbackValue?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  /** Тип формулы */
  type?: InputMaybe<ServerOrderBy>
  /** В зависимости от формулы это может быть процент или значение */
  value?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "salarySettingService" */
export type ServerSalarySettingServiceMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerSalarySettingService>
}

/** input type for inserting object relation for remote table "salarySettingService" */
export type ServerSalarySettingServiceObjRelInsertInput = {
  data: ServerSalarySettingServiceInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerSalarySettingServiceOnConflict>
}

/** on_conflict condition type for table "salarySettingService" */
export type ServerSalarySettingServiceOnConflict = {
  constraint: ServerSalarySettingServiceConstraint
  update_columns?: Array<ServerSalarySettingServiceUpdateColumn>
  where?: InputMaybe<ServerSalarySettingServiceBoolExp>
}

/** Ordering options when selecting data from "salarySettingService". */
export type ServerSalarySettingServiceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  dependsOnRealizedValue?: InputMaybe<ServerOrderBy>
  fallbackType?: InputMaybe<ServerOrderBy>
  fallbackValue?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  salarySetting?: InputMaybe<ServerSalarySettingOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  service?: InputMaybe<ServerServiceOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  value?: InputMaybe<ServerOrderBy>
}

/** select columns of table "salarySettingService" */
export enum ServerSalarySettingServiceSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  DependsOnRealizedValue = 'dependsOnRealizedValue',
  /** column name */
  FallbackType = 'fallbackType',
  /** column name */
  FallbackValue = 'fallbackValue',
  /** column name */
  Id = 'id',
  /** column name */
  SalarySettingId = 'salarySettingId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value',
}

/** order by stddev() on columns of table "salarySettingService" */
export type ServerSalarySettingServiceStddevOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  /** 1 - fixed, 2 - percent */
  fallbackType?: InputMaybe<ServerOrderBy>
  fallbackValue?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  /** Тип формулы */
  type?: InputMaybe<ServerOrderBy>
  /** В зависимости от формулы это может быть процент или значение */
  value?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "salarySettingService" */
export type ServerSalarySettingServiceStddevPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  /** 1 - fixed, 2 - percent */
  fallbackType?: InputMaybe<ServerOrderBy>
  fallbackValue?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  /** Тип формулы */
  type?: InputMaybe<ServerOrderBy>
  /** В зависимости от формулы это может быть процент или значение */
  value?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "salarySettingService" */
export type ServerSalarySettingServiceStddevSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  /** 1 - fixed, 2 - percent */
  fallbackType?: InputMaybe<ServerOrderBy>
  fallbackValue?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  /** Тип формулы */
  type?: InputMaybe<ServerOrderBy>
  /** В зависимости от формулы это может быть процент или значение */
  value?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "salarySettingService" */
export type ServerSalarySettingServiceSumOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  /** 1 - fixed, 2 - percent */
  fallbackType?: InputMaybe<ServerOrderBy>
  fallbackValue?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  /** Тип формулы */
  type?: InputMaybe<ServerOrderBy>
  /** В зависимости от формулы это может быть процент или значение */
  value?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "salarySettingService" (current role has no relevant permissions) */
export enum ServerSalarySettingServiceUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "salarySettingService" */
export type ServerSalarySettingServiceVarPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  /** 1 - fixed, 2 - percent */
  fallbackType?: InputMaybe<ServerOrderBy>
  fallbackValue?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  /** Тип формулы */
  type?: InputMaybe<ServerOrderBy>
  /** В зависимости от формулы это может быть процент или значение */
  value?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "salarySettingService" */
export type ServerSalarySettingServiceVarSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  /** 1 - fixed, 2 - percent */
  fallbackType?: InputMaybe<ServerOrderBy>
  fallbackValue?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  /** Тип формулы */
  type?: InputMaybe<ServerOrderBy>
  /** В зависимости от формулы это может быть процент или значение */
  value?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "salarySettingService" */
export type ServerSalarySettingServiceVarianceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  /** 1 - fixed, 2 - percent */
  fallbackType?: InputMaybe<ServerOrderBy>
  fallbackValue?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  salarySettingId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  /** Тип формулы */
  type?: InputMaybe<ServerOrderBy>
  /** В зависимости от формулы это может быть процент или значение */
  value?: InputMaybe<ServerOrderBy>
}

/** aggregated selection of "salarySetting" */
export type ServerSalarySettingAggregate = {
  aggregate: Maybe<ServerSalarySettingAggregateFields>
  nodes: Array<ServerSalarySetting>
}

export type ServerSalarySettingAggregateBoolExp = {
  count?: InputMaybe<ServerSalarySettingAggregateBoolExpCount>
}

export type ServerSalarySettingAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServerSalarySettingSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerSalarySettingBoolExp>
  predicate: ServerIntComparisonExp
}

/** aggregate fields of "salarySetting" */
export type ServerSalarySettingAggregateFields = {
  avg: Maybe<ServerSalarySettingAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerSalarySettingMaxFields>
  min: Maybe<ServerSalarySettingMinFields>
  stddev: Maybe<ServerSalarySettingStddevFields>
  stddev_pop: Maybe<ServerSalarySettingStddevPopFields>
  stddev_samp: Maybe<ServerSalarySettingStddevSampFields>
  sum: Maybe<ServerSalarySettingSumFields>
  var_pop: Maybe<ServerSalarySettingVarPopFields>
  var_samp: Maybe<ServerSalarySettingVarSampFields>
  variance: Maybe<ServerSalarySettingVarianceFields>
}

/** aggregate fields of "salarySetting" */
export type ServerSalarySettingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerSalarySettingSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "salarySetting" */
export type ServerSalarySettingAggregateOrderBy = {
  avg?: InputMaybe<ServerSalarySettingAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerSalarySettingMaxOrderBy>
  min?: InputMaybe<ServerSalarySettingMinOrderBy>
  stddev?: InputMaybe<ServerSalarySettingStddevOrderBy>
  stddev_pop?: InputMaybe<ServerSalarySettingStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerSalarySettingStddevSampOrderBy>
  sum?: InputMaybe<ServerSalarySettingSumOrderBy>
  var_pop?: InputMaybe<ServerSalarySettingVarPopOrderBy>
  var_samp?: InputMaybe<ServerSalarySettingVarSampOrderBy>
  variance?: InputMaybe<ServerSalarySettingVarianceOrderBy>
}

/** input type for inserting array relation for remote table "salarySetting" */
export type ServerSalarySettingArrRelInsertInput = {
  data: Array<ServerSalarySettingInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerSalarySettingOnConflict>
}

/** aggregate avg on columns */
export type ServerSalarySettingAvgFields = {
  branchId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType: Maybe<Scalars['Float']>
  rateValue: Maybe<Scalars['Float']>
  referralPercentage: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "salarySetting" */
export type ServerSalarySettingAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType?: InputMaybe<ServerOrderBy>
  rateValue?: InputMaybe<ServerOrderBy>
  referralPercentage?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "salarySetting". All fields are combined with a logical 'AND'. */
export type ServerSalarySettingBoolExp = {
  _and?: InputMaybe<Array<ServerSalarySettingBoolExp>>
  _not?: InputMaybe<ServerSalarySettingBoolExp>
  _or?: InputMaybe<Array<ServerSalarySettingBoolExp>>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  end?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  rateType?: InputMaybe<ServerSmallintComparisonExp>
  rateValue?: InputMaybe<ServerNumericComparisonExp>
  referralPercentage?: InputMaybe<ServerIntComparisonExp>
  salarySettingServices?: InputMaybe<ServerSalarySettingServiceBoolExp>
  start?: InputMaybe<ServerTimestamptzComparisonExp>
}

/** unique or primary key constraints on table "salarySetting" */
export enum ServerSalarySettingConstraint {
  /** unique or primary key constraint on columns "id" */
  SalarySettingPkey = 'salarySetting_pkey',
}

/** input type for inserting data into table "salarySetting" */
export type ServerSalarySettingInsertInput = {
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employeeId?: InputMaybe<Scalars['Int']>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType?: InputMaybe<Scalars['smallint']>
  rateValue?: InputMaybe<Scalars['numeric']>
  referralPercentage?: InputMaybe<Scalars['Int']>
  salarySettingServices?: InputMaybe<ServerSalarySettingServiceArrRelInsertInput>
  start?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type ServerSalarySettingMaxFields = {
  branchId: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  /** Дата, проставляемая при архивации сотрудника */
  end: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType: Maybe<Scalars['smallint']>
  rateValue: Maybe<Scalars['numeric']>
  referralPercentage: Maybe<Scalars['Int']>
  start: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "salarySetting" */
export type ServerSalarySettingMaxOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Дата, проставляемая при архивации сотрудника */
  end?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType?: InputMaybe<ServerOrderBy>
  rateValue?: InputMaybe<ServerOrderBy>
  referralPercentage?: InputMaybe<ServerOrderBy>
  start?: InputMaybe<ServerOrderBy>
}

/** aggregate min on columns */
export type ServerSalarySettingMinFields = {
  branchId: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  /** Дата, проставляемая при архивации сотрудника */
  end: Maybe<Scalars['timestamptz']>
  id: Maybe<Scalars['Int']>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType: Maybe<Scalars['smallint']>
  rateValue: Maybe<Scalars['numeric']>
  referralPercentage: Maybe<Scalars['Int']>
  start: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "salarySetting" */
export type ServerSalarySettingMinOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  /** Дата, проставляемая при архивации сотрудника */
  end?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType?: InputMaybe<ServerOrderBy>
  rateValue?: InputMaybe<ServerOrderBy>
  referralPercentage?: InputMaybe<ServerOrderBy>
  start?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "salarySetting" */
export type ServerSalarySettingMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerSalarySetting>
}

/** input type for inserting object relation for remote table "salarySetting" */
export type ServerSalarySettingObjRelInsertInput = {
  data: ServerSalarySettingInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerSalarySettingOnConflict>
}

/** on_conflict condition type for table "salarySetting" */
export type ServerSalarySettingOnConflict = {
  constraint: ServerSalarySettingConstraint
  update_columns?: Array<ServerSalarySettingUpdateColumn>
  where?: InputMaybe<ServerSalarySettingBoolExp>
}

/** Ordering options when selecting data from "salarySetting". */
export type ServerSalarySettingOrderBy = {
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  end?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  rateType?: InputMaybe<ServerOrderBy>
  rateValue?: InputMaybe<ServerOrderBy>
  referralPercentage?: InputMaybe<ServerOrderBy>
  salarySettingServices_aggregate?: InputMaybe<ServerSalarySettingServiceAggregateOrderBy>
  start?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: salarySetting */
export type ServerSalarySettingPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "salarySetting" */
export enum ServerSalarySettingSelectColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  RateType = 'rateType',
  /** column name */
  RateValue = 'rateValue',
  /** column name */
  ReferralPercentage = 'referralPercentage',
  /** column name */
  Start = 'start',
}

/** input type for updating data in table "salarySetting" */
export type ServerSalarySettingSetInput = {
  /** Дата, проставляемая при архивации сотрудника */
  end?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type ServerSalarySettingStddevFields = {
  branchId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType: Maybe<Scalars['Float']>
  rateValue: Maybe<Scalars['Float']>
  referralPercentage: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "salarySetting" */
export type ServerSalarySettingStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType?: InputMaybe<ServerOrderBy>
  rateValue?: InputMaybe<ServerOrderBy>
  referralPercentage?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_pop on columns */
export type ServerSalarySettingStddevPopFields = {
  branchId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType: Maybe<Scalars['Float']>
  rateValue: Maybe<Scalars['Float']>
  referralPercentage: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "salarySetting" */
export type ServerSalarySettingStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType?: InputMaybe<ServerOrderBy>
  rateValue?: InputMaybe<ServerOrderBy>
  referralPercentage?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_samp on columns */
export type ServerSalarySettingStddevSampFields = {
  branchId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType: Maybe<Scalars['Float']>
  rateValue: Maybe<Scalars['Float']>
  referralPercentage: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "salarySetting" */
export type ServerSalarySettingStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType?: InputMaybe<ServerOrderBy>
  rateValue?: InputMaybe<ServerOrderBy>
  referralPercentage?: InputMaybe<ServerOrderBy>
}

/** aggregate sum on columns */
export type ServerSalarySettingSumFields = {
  branchId: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType: Maybe<Scalars['smallint']>
  rateValue: Maybe<Scalars['numeric']>
  referralPercentage: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "salarySetting" */
export type ServerSalarySettingSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType?: InputMaybe<ServerOrderBy>
  rateValue?: InputMaybe<ServerOrderBy>
  referralPercentage?: InputMaybe<ServerOrderBy>
}

/** update columns of table "salarySetting" */
export enum ServerSalarySettingUpdateColumn {
  /** column name */
  End = 'end',
}

export type ServerSalarySettingUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerSalarySettingSetInput>
  /** filter the rows which have to be updated */
  where: ServerSalarySettingBoolExp
}

/** aggregate var_pop on columns */
export type ServerSalarySettingVarPopFields = {
  branchId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType: Maybe<Scalars['Float']>
  rateValue: Maybe<Scalars['Float']>
  referralPercentage: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "salarySetting" */
export type ServerSalarySettingVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType?: InputMaybe<ServerOrderBy>
  rateValue?: InputMaybe<ServerOrderBy>
  referralPercentage?: InputMaybe<ServerOrderBy>
}

/** aggregate var_samp on columns */
export type ServerSalarySettingVarSampFields = {
  branchId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType: Maybe<Scalars['Float']>
  rateValue: Maybe<Scalars['Float']>
  referralPercentage: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "salarySetting" */
export type ServerSalarySettingVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType?: InputMaybe<ServerOrderBy>
  rateValue?: InputMaybe<ServerOrderBy>
  referralPercentage?: InputMaybe<ServerOrderBy>
}

/** aggregate variance on columns */
export type ServerSalarySettingVarianceFields = {
  branchId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType: Maybe<Scalars['Float']>
  rateValue: Maybe<Scalars['Float']>
  referralPercentage: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "salarySetting" */
export type ServerSalarySettingVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  /** 1 - месячная, 2 - дневная, 3 - за час */
  rateType?: InputMaybe<ServerOrderBy>
  rateValue?: InputMaybe<ServerOrderBy>
  referralPercentage?: InputMaybe<ServerOrderBy>
}

/** Расписания */
export type ServerSchedule = {
  archived: Maybe<Scalars['timestamptz']>
  /** An array relationship */
  branch: Array<ServerBranch>
  createdAt: Scalars['timestamptz']
  data: Scalars['jsonb']
  /** An array relationship */
  employeeSchedules: Array<ServerEmployeeSchedule>
  /** An aggregate relationship */
  employeeSchedules_aggregate: ServerEmployeeScheduleAggregate
  id: Scalars['Int']
  name: Scalars['String']
  type: Scalars['Int']
}

/** Расписания */
export type ServerScheduleBranchArgs = {
  distinct_on?: InputMaybe<Array<ServerBranchSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBranchOrderBy>>
  where?: InputMaybe<ServerBranchBoolExp>
}

/** Расписания */
export type ServerScheduleDataArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** Расписания */
export type ServerScheduleEmployeeSchedulesArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeScheduleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeScheduleOrderBy>>
  where?: InputMaybe<ServerEmployeeScheduleBoolExp>
}

/** Расписания */
export type ServerScheduleEmployeeSchedulesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeScheduleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeScheduleOrderBy>>
  where?: InputMaybe<ServerEmployeeScheduleBoolExp>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type ServerScheduleAppendInput = {
  data?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "schedule". All fields are combined with a logical 'AND'. */
export type ServerScheduleBoolExp = {
  _and?: InputMaybe<Array<ServerScheduleBoolExp>>
  _not?: InputMaybe<ServerScheduleBoolExp>
  _or?: InputMaybe<Array<ServerScheduleBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  data?: InputMaybe<ServerJsonbComparisonExp>
  employeeSchedules?: InputMaybe<ServerEmployeeScheduleBoolExp>
  employeeSchedules_aggregate?: InputMaybe<ServerEmployeeScheduleAggregateBoolExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  type?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "schedule" */
export enum ServerScheduleConstraint {
  /** unique or primary key constraint on columns "id" */
  SchedulesPkey = 'schedules_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ServerScheduleDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ServerScheduleDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ServerScheduleDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "schedule" */
export type ServerScheduleIncInput = {
  type?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "schedule" */
export type ServerScheduleInsertInput = {
  branch?: InputMaybe<ServerBranchArrRelInsertInput>
  data?: InputMaybe<Scalars['jsonb']>
  employeeSchedules?: InputMaybe<ServerEmployeeScheduleArrRelInsertInput>
  name?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['Int']>
}

/** response of any mutation on the table "schedule" */
export type ServerScheduleMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerSchedule>
}

/** input type for inserting object relation for remote table "schedule" */
export type ServerScheduleObjRelInsertInput = {
  data: ServerScheduleInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerScheduleOnConflict>
}

/** on_conflict condition type for table "schedule" */
export type ServerScheduleOnConflict = {
  constraint: ServerScheduleConstraint
  update_columns?: Array<ServerScheduleUpdateColumn>
  where?: InputMaybe<ServerScheduleBoolExp>
}

/** Ordering options when selecting data from "schedule". */
export type ServerScheduleOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branch_aggregate?: InputMaybe<ServerBranchAggregateOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  data?: InputMaybe<ServerOrderBy>
  employeeSchedules_aggregate?: InputMaybe<ServerEmployeeScheduleAggregateOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: schedule */
export type ServerSchedulePkColumnsInput = {
  id: Scalars['Int']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ServerSchedulePrependInput = {
  data?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "schedule" */
export enum ServerScheduleSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "schedule" */
export type ServerScheduleSetInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
  data?: InputMaybe<Scalars['jsonb']>
  name?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['Int']>
}

/** update columns of table "schedule" */
export enum ServerScheduleUpdateColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  Data = 'data',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
}

export type ServerScheduleUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ServerScheduleAppendInput>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ServerScheduleDeleteAtPathInput>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ServerScheduleDeleteElemInput>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ServerScheduleDeleteKeyInput>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerScheduleIncInput>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ServerSchedulePrependInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerScheduleSetInput>
  /** filter the rows which have to be updated */
  where: ServerScheduleBoolExp
}

/** Статус сервера */
export type ServerServerStatus = {
  id: Scalars['Int']
  offline: Scalars['Boolean']
  serverVersion: Scalars['Int']
}

/** Boolean expression to filter rows from the table "serverStatus". All fields are combined with a logical 'AND'. */
export type ServerServerStatusBoolExp = {
  _and?: InputMaybe<Array<ServerServerStatusBoolExp>>
  _not?: InputMaybe<ServerServerStatusBoolExp>
  _or?: InputMaybe<Array<ServerServerStatusBoolExp>>
  id?: InputMaybe<ServerIntComparisonExp>
  offline?: InputMaybe<ServerBooleanComparisonExp>
  serverVersion?: InputMaybe<ServerIntComparisonExp>
}

/** Ordering options when selecting data from "serverStatus". */
export type ServerServerStatusOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  offline?: InputMaybe<ServerOrderBy>
  serverVersion?: InputMaybe<ServerOrderBy>
}

/** select columns of table "serverStatus" */
export enum ServerServerStatusSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Offline = 'offline',
  /** column name */
  ServerVersion = 'serverVersion',
}

/** Streaming cursor of the table "serverStatus" */
export type ServerServerStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerServerStatusStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerServerStatusStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>
  offline?: InputMaybe<Scalars['Boolean']>
  serverVersion?: InputMaybe<Scalars['Int']>
}

/** Услуги */
export type ServerService = {
  archived: Maybe<Scalars['timestamptz']>
  /** услуги, которые клиент может создавать */
  availableForClient: Scalars['Boolean']
  availableToAllBranches: Scalars['Boolean']
  /** An array relationship */
  bookingServices: Array<ServerBookingService>
  /** An object relationship */
  branch: Maybe<ServerBranch>
  branchId: Maybe<Scalars['Int']>
  /** An object relationship */
  business: ServerBusiness
  /** An array relationship */
  cardTemplateServices: Array<ServerCardTemplateService>
  color: Maybe<Scalars['String']>
  costPrice: Scalars['numeric']
  createdAt: Scalars['timestamptz']
  defaultDuration: Scalars['Int']
  description: Maybe<Scalars['String']>
  /** An array relationship */
  groupBookings: Array<ServerBooking>
  /** An aggregate relationship */
  groupBookings_aggregate: ServerBookingAggregate
  groupId: Maybe<Scalars['Int']>
  id: Scalars['Int']
  name: Scalars['String']
  picture: Maybe<Scalars['String']>
  price: Scalars['numeric']
  /** An array relationship */
  serviceEmployees: Array<ServerServiceEmployee>
  /** An object relationship */
  serviceGroup: Maybe<ServerServiceGroup>
  /** An array relationship */
  serviceLocations: Array<ServerServiceLocation>
  /** An array relationship */
  servicePermissions: Array<ServerServicePermission>
  /** An array relationship */
  serviceProducts: Array<ServerServiceProduct>
  /** An array relationship */
  transactionsServices: Array<ServerTransactionService>
  /** 1 - service, 2 - group service, 3 - for sale */
  type: Scalars['smallint']
}

/** Услуги */
export type ServerServiceBookingServicesArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingServiceSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingServiceOrderBy>>
  where?: InputMaybe<ServerBookingServiceBoolExp>
}

/** Услуги */
export type ServerServiceCardTemplateServicesArgs = {
  distinct_on?: InputMaybe<Array<ServerCardTemplateServiceSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerCardTemplateServiceOrderBy>>
  where?: InputMaybe<ServerCardTemplateServiceBoolExp>
}

/** Услуги */
export type ServerServiceGroupBookingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** Услуги */
export type ServerServiceGroupBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** Услуги */
export type ServerServiceServiceEmployeesArgs = {
  distinct_on?: InputMaybe<Array<ServerServiceEmployeeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServiceEmployeeOrderBy>>
  where?: InputMaybe<ServerServiceEmployeeBoolExp>
}

/** Услуги */
export type ServerServiceServiceLocationsArgs = {
  distinct_on?: InputMaybe<Array<ServerServiceLocationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServiceLocationOrderBy>>
  where?: InputMaybe<ServerServiceLocationBoolExp>
}

/** Услуги */
export type ServerServiceServicePermissionsArgs = {
  distinct_on?: InputMaybe<Array<ServerServicePermissionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServicePermissionOrderBy>>
  where?: InputMaybe<ServerServicePermissionBoolExp>
}

/** Услуги */
export type ServerServiceServiceProductsArgs = {
  distinct_on?: InputMaybe<Array<ServerServiceProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServiceProductOrderBy>>
  where?: InputMaybe<ServerServiceProductBoolExp>
}

/** Услуги */
export type ServerServiceTransactionsServicesArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionServiceSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionServiceOrderBy>>
  where?: InputMaybe<ServerTransactionServiceBoolExp>
}

/** Кто из сотрудников может предоставить конкретную услугу */
export type ServerServiceEmployee = {
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  /** An object relationship */
  employee: ServerEmployee
  employeeId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  service: ServerService
  serviceId: Scalars['Int']
}

/** order by aggregate values of table "serviceEmployee" */
export type ServerServiceEmployeeAggregateOrderBy = {
  avg?: InputMaybe<ServerServiceEmployeeAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerServiceEmployeeMaxOrderBy>
  min?: InputMaybe<ServerServiceEmployeeMinOrderBy>
  stddev?: InputMaybe<ServerServiceEmployeeStddevOrderBy>
  stddev_pop?: InputMaybe<ServerServiceEmployeeStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerServiceEmployeeStddevSampOrderBy>
  sum?: InputMaybe<ServerServiceEmployeeSumOrderBy>
  var_pop?: InputMaybe<ServerServiceEmployeeVarPopOrderBy>
  var_samp?: InputMaybe<ServerServiceEmployeeVarSampOrderBy>
  variance?: InputMaybe<ServerServiceEmployeeVarianceOrderBy>
}

/** input type for inserting array relation for remote table "serviceEmployee" */
export type ServerServiceEmployeeArrRelInsertInput = {
  data: Array<ServerServiceEmployeeInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerServiceEmployeeOnConflict>
}

/** order by avg() on columns of table "serviceEmployee" */
export type ServerServiceEmployeeAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "serviceEmployee". All fields are combined with a logical 'AND'. */
export type ServerServiceEmployeeBoolExp = {
  _and?: InputMaybe<Array<ServerServiceEmployeeBoolExp>>
  _not?: InputMaybe<ServerServiceEmployeeBoolExp>
  _or?: InputMaybe<Array<ServerServiceEmployeeBoolExp>>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  service?: InputMaybe<ServerServiceBoolExp>
  serviceId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "serviceEmployee" */
export enum ServerServiceEmployeeConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceEmployeePkey = 'service_employee_pkey',
}

/** input type for incrementing numeric columns in table "serviceEmployee" */
export type ServerServiceEmployeeIncInput = {
  employeeId?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "serviceEmployee" */
export type ServerServiceEmployeeInsertInput = {
  branchId?: InputMaybe<Scalars['Int']>
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employeeId?: InputMaybe<Scalars['Int']>
  service?: InputMaybe<ServerServiceObjRelInsertInput>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "serviceEmployee" */
export type ServerServiceEmployeeMaxOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "serviceEmployee" */
export type ServerServiceEmployeeMinOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "serviceEmployee" */
export type ServerServiceEmployeeMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerServiceEmployee>
}

/** on_conflict condition type for table "serviceEmployee" */
export type ServerServiceEmployeeOnConflict = {
  constraint: ServerServiceEmployeeConstraint
  update_columns?: Array<ServerServiceEmployeeUpdateColumn>
  where?: InputMaybe<ServerServiceEmployeeBoolExp>
}

/** Ordering options when selecting data from "serviceEmployee". */
export type ServerServiceEmployeeOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  service?: InputMaybe<ServerServiceOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: serviceEmployee */
export type ServerServiceEmployeePkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "serviceEmployee" */
export enum ServerServiceEmployeeSelectColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
}

/** input type for updating data in table "serviceEmployee" */
export type ServerServiceEmployeeSetInput = {
  employeeId?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "serviceEmployee" */
export type ServerServiceEmployeeStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "serviceEmployee" */
export type ServerServiceEmployeeStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "serviceEmployee" */
export type ServerServiceEmployeeStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "serviceEmployee" */
export type ServerServiceEmployeeSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "serviceEmployee" */
export enum ServerServiceEmployeeUpdateColumn {
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  ServiceId = 'serviceId',
}

export type ServerServiceEmployeeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerServiceEmployeeIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerServiceEmployeeSetInput>
  /** filter the rows which have to be updated */
  where: ServerServiceEmployeeBoolExp
}

/** order by var_pop() on columns of table "serviceEmployee" */
export type ServerServiceEmployeeVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "serviceEmployee" */
export type ServerServiceEmployeeVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "serviceEmployee" */
export type ServerServiceEmployeeVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** Группы услуг */
export type ServerServiceGroup = {
  archived: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  createdAt: Scalars['timestamptz']
  description: Maybe<Scalars['String']>
  id: Scalars['Int']
  name: Scalars['String']
  parentId: Maybe<Scalars['Int']>
  /** An array relationship */
  services: Array<ServerService>
}

/** Группы услуг */
export type ServerServiceGroupServicesArgs = {
  distinct_on?: InputMaybe<Array<ServerServiceSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServiceOrderBy>>
  where?: InputMaybe<ServerServiceBoolExp>
}

/** Boolean expression to filter rows from the table "serviceGroup". All fields are combined with a logical 'AND'. */
export type ServerServiceGroupBoolExp = {
  _and?: InputMaybe<Array<ServerServiceGroupBoolExp>>
  _not?: InputMaybe<ServerServiceGroupBoolExp>
  _or?: InputMaybe<Array<ServerServiceGroupBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  parentId?: InputMaybe<ServerIntComparisonExp>
  services?: InputMaybe<ServerServiceBoolExp>
}

/** unique or primary key constraints on table "serviceGroup" */
export enum ServerServiceGroupConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceGroupsPkey = 'serviceGroups_pkey',
}

/** input type for incrementing numeric columns in table "serviceGroup" */
export type ServerServiceGroupIncInput = {
  parentId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "serviceGroup" */
export type ServerServiceGroupInsertInput = {
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  parentId?: InputMaybe<Scalars['Int']>
  services?: InputMaybe<ServerServiceArrRelInsertInput>
}

/** response of any mutation on the table "serviceGroup" */
export type ServerServiceGroupMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerServiceGroup>
}

/** input type for inserting object relation for remote table "serviceGroup" */
export type ServerServiceGroupObjRelInsertInput = {
  data: ServerServiceGroupInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerServiceGroupOnConflict>
}

/** on_conflict condition type for table "serviceGroup" */
export type ServerServiceGroupOnConflict = {
  constraint: ServerServiceGroupConstraint
  update_columns?: Array<ServerServiceGroupUpdateColumn>
  where?: InputMaybe<ServerServiceGroupBoolExp>
}

/** Ordering options when selecting data from "serviceGroup". */
export type ServerServiceGroupOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  services_aggregate?: InputMaybe<ServerServiceAggregateOrderBy>
}

/** primary key columns input for table: serviceGroup */
export type ServerServiceGroupPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "serviceGroup" */
export enum ServerServiceGroupSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
}

/** input type for updating data in table "serviceGroup" */
export type ServerServiceGroupSetInput = {
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  parentId?: InputMaybe<Scalars['Int']>
}

/** update columns of table "serviceGroup" */
export enum ServerServiceGroupUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
}

export type ServerServiceGroupUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerServiceGroupIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerServiceGroupSetInput>
  /** filter the rows which have to be updated */
  where: ServerServiceGroupBoolExp
}

/** На какой локации может предоставляться услуга  */
export type ServerServiceLocation = {
  branchId: Scalars['Int']
  businessId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  location: ServerLocation
  locationId: Scalars['Int']
  /** An object relationship */
  service: ServerService
  serviceId: Scalars['Int']
}

/** order by aggregate values of table "serviceLocation" */
export type ServerServiceLocationAggregateOrderBy = {
  avg?: InputMaybe<ServerServiceLocationAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerServiceLocationMaxOrderBy>
  min?: InputMaybe<ServerServiceLocationMinOrderBy>
  stddev?: InputMaybe<ServerServiceLocationStddevOrderBy>
  stddev_pop?: InputMaybe<ServerServiceLocationStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerServiceLocationStddevSampOrderBy>
  sum?: InputMaybe<ServerServiceLocationSumOrderBy>
  var_pop?: InputMaybe<ServerServiceLocationVarPopOrderBy>
  var_samp?: InputMaybe<ServerServiceLocationVarSampOrderBy>
  variance?: InputMaybe<ServerServiceLocationVarianceOrderBy>
}

/** input type for inserting array relation for remote table "serviceLocation" */
export type ServerServiceLocationArrRelInsertInput = {
  data: Array<ServerServiceLocationInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerServiceLocationOnConflict>
}

/** order by avg() on columns of table "serviceLocation" */
export type ServerServiceLocationAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "serviceLocation". All fields are combined with a logical 'AND'. */
export type ServerServiceLocationBoolExp = {
  _and?: InputMaybe<Array<ServerServiceLocationBoolExp>>
  _not?: InputMaybe<ServerServiceLocationBoolExp>
  _or?: InputMaybe<Array<ServerServiceLocationBoolExp>>
  branchId?: InputMaybe<ServerIntComparisonExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  location?: InputMaybe<ServerLocationBoolExp>
  locationId?: InputMaybe<ServerIntComparisonExp>
  service?: InputMaybe<ServerServiceBoolExp>
  serviceId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "serviceLocation" */
export enum ServerServiceLocationConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceLocationPkey = 'service_location_pkey',
}

/** input type for incrementing numeric columns in table "serviceLocation" */
export type ServerServiceLocationIncInput = {
  locationId?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "serviceLocation" */
export type ServerServiceLocationInsertInput = {
  branchId?: InputMaybe<Scalars['Int']>
  location?: InputMaybe<ServerLocationObjRelInsertInput>
  locationId?: InputMaybe<Scalars['Int']>
  service?: InputMaybe<ServerServiceObjRelInsertInput>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "serviceLocation" */
export type ServerServiceLocationMaxOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "serviceLocation" */
export type ServerServiceLocationMinOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "serviceLocation" */
export type ServerServiceLocationMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerServiceLocation>
}

/** on_conflict condition type for table "serviceLocation" */
export type ServerServiceLocationOnConflict = {
  constraint: ServerServiceLocationConstraint
  update_columns?: Array<ServerServiceLocationUpdateColumn>
  where?: InputMaybe<ServerServiceLocationBoolExp>
}

/** Ordering options when selecting data from "serviceLocation". */
export type ServerServiceLocationOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  location?: InputMaybe<ServerLocationOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  service?: InputMaybe<ServerServiceOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: serviceLocation */
export type ServerServiceLocationPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "serviceLocation" */
export enum ServerServiceLocationSelectColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  ServiceId = 'serviceId',
}

/** input type for updating data in table "serviceLocation" */
export type ServerServiceLocationSetInput = {
  locationId?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "serviceLocation" */
export type ServerServiceLocationStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "serviceLocation" */
export type ServerServiceLocationStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "serviceLocation" */
export type ServerServiceLocationStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "serviceLocation" */
export type ServerServiceLocationSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "serviceLocation" */
export enum ServerServiceLocationUpdateColumn {
  /** column name */
  LocationId = 'locationId',
  /** column name */
  ServiceId = 'serviceId',
}

export type ServerServiceLocationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerServiceLocationIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerServiceLocationSetInput>
  /** filter the rows which have to be updated */
  where: ServerServiceLocationBoolExp
}

/** order by var_pop() on columns of table "serviceLocation" */
export type ServerServiceLocationVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "serviceLocation" */
export type ServerServiceLocationVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "serviceLocation" */
export type ServerServiceLocationVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  locationId?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** Кто может продавать услугу */
export type ServerServicePermission = {
  businessId: Scalars['Int']
  /** An object relationship */
  employee: Maybe<ServerEmployee>
  employeeId: Maybe<Scalars['Int']>
  id: Scalars['Int']
  /** An object relationship */
  service: ServerService
  serviceId: Scalars['Int']
}

/** order by aggregate values of table "servicePermission" */
export type ServerServicePermissionAggregateOrderBy = {
  avg?: InputMaybe<ServerServicePermissionAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerServicePermissionMaxOrderBy>
  min?: InputMaybe<ServerServicePermissionMinOrderBy>
  stddev?: InputMaybe<ServerServicePermissionStddevOrderBy>
  stddev_pop?: InputMaybe<ServerServicePermissionStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerServicePermissionStddevSampOrderBy>
  sum?: InputMaybe<ServerServicePermissionSumOrderBy>
  var_pop?: InputMaybe<ServerServicePermissionVarPopOrderBy>
  var_samp?: InputMaybe<ServerServicePermissionVarSampOrderBy>
  variance?: InputMaybe<ServerServicePermissionVarianceOrderBy>
}

/** input type for inserting array relation for remote table "servicePermission" */
export type ServerServicePermissionArrRelInsertInput = {
  data: Array<ServerServicePermissionInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerServicePermissionOnConflict>
}

/** order by avg() on columns of table "servicePermission" */
export type ServerServicePermissionAvgOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "servicePermission". All fields are combined with a logical 'AND'. */
export type ServerServicePermissionBoolExp = {
  _and?: InputMaybe<Array<ServerServicePermissionBoolExp>>
  _not?: InputMaybe<ServerServicePermissionBoolExp>
  _or?: InputMaybe<Array<ServerServicePermissionBoolExp>>
  businessId?: InputMaybe<ServerIntComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  service?: InputMaybe<ServerServiceBoolExp>
  serviceId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "servicePermission" */
export enum ServerServicePermissionConstraint {
  /** unique or primary key constraint on columns "id" */
  ServicePermissionsPkey = 'servicePermissions_pkey',
}

/** input type for incrementing numeric columns in table "servicePermission" */
export type ServerServicePermissionIncInput = {
  employeeId?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "servicePermission" */
export type ServerServicePermissionInsertInput = {
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employeeId?: InputMaybe<Scalars['Int']>
  service?: InputMaybe<ServerServiceObjRelInsertInput>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "servicePermission" */
export type ServerServicePermissionMaxOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "servicePermission" */
export type ServerServicePermissionMinOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "servicePermission" */
export type ServerServicePermissionMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerServicePermission>
}

/** on_conflict condition type for table "servicePermission" */
export type ServerServicePermissionOnConflict = {
  constraint: ServerServicePermissionConstraint
  update_columns?: Array<ServerServicePermissionUpdateColumn>
  where?: InputMaybe<ServerServicePermissionBoolExp>
}

/** Ordering options when selecting data from "servicePermission". */
export type ServerServicePermissionOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  service?: InputMaybe<ServerServiceOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: servicePermission */
export type ServerServicePermissionPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "servicePermission" */
export enum ServerServicePermissionSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
}

/** input type for updating data in table "servicePermission" */
export type ServerServicePermissionSetInput = {
  employeeId?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "servicePermission" */
export type ServerServicePermissionStddevOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "servicePermission" */
export type ServerServicePermissionStddevPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "servicePermission" */
export type ServerServicePermissionStddevSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "servicePermission" */
export type ServerServicePermissionSumOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "servicePermission" */
export enum ServerServicePermissionUpdateColumn {
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  ServiceId = 'serviceId',
}

export type ServerServicePermissionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerServicePermissionIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerServicePermissionSetInput>
  /** filter the rows which have to be updated */
  where: ServerServicePermissionBoolExp
}

/** order by var_pop() on columns of table "servicePermission" */
export type ServerServicePermissionVarPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "servicePermission" */
export type ServerServicePermissionVarSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "servicePermission" */
export type ServerServicePermissionVarianceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** Товары для услуг (расходники) */
export type ServerServiceProduct = {
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  product: ServerProduct
  productId: Scalars['Int']
  quantity: Scalars['Int']
  /** An object relationship */
  service: ServerService
  serviceId: Scalars['Int']
}

/** order by aggregate values of table "serviceProduct" */
export type ServerServiceProductAggregateOrderBy = {
  avg?: InputMaybe<ServerServiceProductAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerServiceProductMaxOrderBy>
  min?: InputMaybe<ServerServiceProductMinOrderBy>
  stddev?: InputMaybe<ServerServiceProductStddevOrderBy>
  stddev_pop?: InputMaybe<ServerServiceProductStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerServiceProductStddevSampOrderBy>
  sum?: InputMaybe<ServerServiceProductSumOrderBy>
  var_pop?: InputMaybe<ServerServiceProductVarPopOrderBy>
  var_samp?: InputMaybe<ServerServiceProductVarSampOrderBy>
  variance?: InputMaybe<ServerServiceProductVarianceOrderBy>
}

/** input type for inserting array relation for remote table "serviceProduct" */
export type ServerServiceProductArrRelInsertInput = {
  data: Array<ServerServiceProductInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerServiceProductOnConflict>
}

/** order by avg() on columns of table "serviceProduct" */
export type ServerServiceProductAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "serviceProduct". All fields are combined with a logical 'AND'. */
export type ServerServiceProductBoolExp = {
  _and?: InputMaybe<Array<ServerServiceProductBoolExp>>
  _not?: InputMaybe<ServerServiceProductBoolExp>
  _or?: InputMaybe<Array<ServerServiceProductBoolExp>>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  product?: InputMaybe<ServerProductBoolExp>
  productId?: InputMaybe<ServerIntComparisonExp>
  quantity?: InputMaybe<ServerIntComparisonExp>
  service?: InputMaybe<ServerServiceBoolExp>
  serviceId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "serviceProduct" */
export enum ServerServiceProductConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceProductPkey = 'serviceProduct_pkey',
}

/** input type for incrementing numeric columns in table "serviceProduct" */
export type ServerServiceProductIncInput = {
  productId?: InputMaybe<Scalars['Int']>
  quantity?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "serviceProduct" */
export type ServerServiceProductInsertInput = {
  branchId?: InputMaybe<Scalars['Int']>
  product?: InputMaybe<ServerProductObjRelInsertInput>
  productId?: InputMaybe<Scalars['Int']>
  quantity?: InputMaybe<Scalars['Int']>
  service?: InputMaybe<ServerServiceObjRelInsertInput>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "serviceProduct" */
export type ServerServiceProductMaxOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "serviceProduct" */
export type ServerServiceProductMinOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "serviceProduct" */
export type ServerServiceProductMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerServiceProduct>
}

/** on_conflict condition type for table "serviceProduct" */
export type ServerServiceProductOnConflict = {
  constraint: ServerServiceProductConstraint
  update_columns?: Array<ServerServiceProductUpdateColumn>
  where?: InputMaybe<ServerServiceProductBoolExp>
}

/** Ordering options when selecting data from "serviceProduct". */
export type ServerServiceProductOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  product?: InputMaybe<ServerProductOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
  service?: InputMaybe<ServerServiceOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: serviceProduct */
export type ServerServiceProductPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "serviceProduct" */
export enum ServerServiceProductSelectColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceId = 'serviceId',
}

/** input type for updating data in table "serviceProduct" */
export type ServerServiceProductSetInput = {
  productId?: InputMaybe<Scalars['Int']>
  quantity?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "serviceProduct" */
export type ServerServiceProductStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "serviceProduct" */
export type ServerServiceProductStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "serviceProduct" */
export type ServerServiceProductStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "serviceProduct" */
export type ServerServiceProductSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "serviceProduct" */
export enum ServerServiceProductUpdateColumn {
  /** column name */
  ProductId = 'productId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceId = 'serviceId',
}

export type ServerServiceProductUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerServiceProductIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerServiceProductSetInput>
  /** filter the rows which have to be updated */
  where: ServerServiceProductBoolExp
}

/** order by var_pop() on columns of table "serviceProduct" */
export type ServerServiceProductVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "serviceProduct" */
export type ServerServiceProductVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "serviceProduct" */
export type ServerServiceProductVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  productId?: InputMaybe<ServerOrderBy>
  quantity?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by aggregate values of table "service" */
export type ServerServiceAggregateOrderBy = {
  avg?: InputMaybe<ServerServiceAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerServiceMaxOrderBy>
  min?: InputMaybe<ServerServiceMinOrderBy>
  stddev?: InputMaybe<ServerServiceStddevOrderBy>
  stddev_pop?: InputMaybe<ServerServiceStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerServiceStddevSampOrderBy>
  sum?: InputMaybe<ServerServiceSumOrderBy>
  var_pop?: InputMaybe<ServerServiceVarPopOrderBy>
  var_samp?: InputMaybe<ServerServiceVarSampOrderBy>
  variance?: InputMaybe<ServerServiceVarianceOrderBy>
}

/** input type for inserting array relation for remote table "service" */
export type ServerServiceArrRelInsertInput = {
  data: Array<ServerServiceInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerServiceOnConflict>
}

/** order by avg() on columns of table "service" */
export type ServerServiceAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  defaultDuration?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "service". All fields are combined with a logical 'AND'. */
export type ServerServiceBoolExp = {
  _and?: InputMaybe<Array<ServerServiceBoolExp>>
  _not?: InputMaybe<ServerServiceBoolExp>
  _or?: InputMaybe<Array<ServerServiceBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  availableForClient?: InputMaybe<ServerBooleanComparisonExp>
  availableToAllBranches?: InputMaybe<ServerBooleanComparisonExp>
  bookingServices?: InputMaybe<ServerBookingServiceBoolExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  cardTemplateServices?: InputMaybe<ServerCardTemplateServiceBoolExp>
  color?: InputMaybe<ServerStringComparisonExp>
  costPrice?: InputMaybe<ServerNumericComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  defaultDuration?: InputMaybe<ServerIntComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  groupBookings?: InputMaybe<ServerBookingBoolExp>
  groupBookings_aggregate?: InputMaybe<ServerBookingAggregateBoolExp>
  groupId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  picture?: InputMaybe<ServerStringComparisonExp>
  price?: InputMaybe<ServerNumericComparisonExp>
  serviceEmployees?: InputMaybe<ServerServiceEmployeeBoolExp>
  serviceGroup?: InputMaybe<ServerServiceGroupBoolExp>
  serviceLocations?: InputMaybe<ServerServiceLocationBoolExp>
  servicePermissions?: InputMaybe<ServerServicePermissionBoolExp>
  serviceProducts?: InputMaybe<ServerServiceProductBoolExp>
  transactionsServices?: InputMaybe<ServerTransactionServiceBoolExp>
  type?: InputMaybe<ServerSmallintComparisonExp>
}

/** unique or primary key constraints on table "service" */
export enum ServerServiceConstraint {
  /** unique or primary key constraint on columns "id" */
  ServicesPkey = 'services_pkey',
}

/** input type for incrementing numeric columns in table "service" */
export type ServerServiceIncInput = {
  branchId?: InputMaybe<Scalars['Int']>
  costPrice?: InputMaybe<Scalars['numeric']>
  defaultDuration?: InputMaybe<Scalars['Int']>
  groupId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  price?: InputMaybe<Scalars['numeric']>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<Scalars['smallint']>
}

/** input type for inserting data into table "service" */
export type ServerServiceInsertInput = {
  /** услуги, которые клиент может создавать */
  availableForClient?: InputMaybe<Scalars['Boolean']>
  availableToAllBranches?: InputMaybe<Scalars['Boolean']>
  bookingServices?: InputMaybe<ServerBookingServiceArrRelInsertInput>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  cardTemplateServices?: InputMaybe<ServerCardTemplateServiceArrRelInsertInput>
  color?: InputMaybe<Scalars['String']>
  costPrice?: InputMaybe<Scalars['numeric']>
  defaultDuration?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  groupBookings?: InputMaybe<ServerBookingArrRelInsertInput>
  groupId?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  picture?: InputMaybe<Scalars['String']>
  price?: InputMaybe<Scalars['numeric']>
  serviceEmployees?: InputMaybe<ServerServiceEmployeeArrRelInsertInput>
  serviceGroup?: InputMaybe<ServerServiceGroupObjRelInsertInput>
  serviceLocations?: InputMaybe<ServerServiceLocationArrRelInsertInput>
  servicePermissions?: InputMaybe<ServerServicePermissionArrRelInsertInput>
  serviceProducts?: InputMaybe<ServerServiceProductArrRelInsertInput>
  transactionsServices?: InputMaybe<ServerTransactionServiceArrRelInsertInput>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<Scalars['smallint']>
}

/** order by max() on columns of table "service" */
export type ServerServiceMaxOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  color?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  defaultDuration?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  picture?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "service" */
export type ServerServiceMinOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  color?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  defaultDuration?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  picture?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "service" */
export type ServerServiceMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerService>
}

/** input type for inserting object relation for remote table "service" */
export type ServerServiceObjRelInsertInput = {
  data: ServerServiceInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerServiceOnConflict>
}

/** on_conflict condition type for table "service" */
export type ServerServiceOnConflict = {
  constraint: ServerServiceConstraint
  update_columns?: Array<ServerServiceUpdateColumn>
  where?: InputMaybe<ServerServiceBoolExp>
}

/** Ordering options when selecting data from "service". */
export type ServerServiceOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  availableForClient?: InputMaybe<ServerOrderBy>
  availableToAllBranches?: InputMaybe<ServerOrderBy>
  bookingServices_aggregate?: InputMaybe<ServerBookingServiceAggregateOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  cardTemplateServices_aggregate?: InputMaybe<ServerCardTemplateServiceAggregateOrderBy>
  color?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  defaultDuration?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  groupBookings_aggregate?: InputMaybe<ServerBookingAggregateOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  picture?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  serviceEmployees_aggregate?: InputMaybe<ServerServiceEmployeeAggregateOrderBy>
  serviceGroup?: InputMaybe<ServerServiceGroupOrderBy>
  serviceLocations_aggregate?: InputMaybe<ServerServiceLocationAggregateOrderBy>
  servicePermissions_aggregate?: InputMaybe<ServerServicePermissionAggregateOrderBy>
  serviceProducts_aggregate?: InputMaybe<ServerServiceProductAggregateOrderBy>
  transactionsServices_aggregate?: InputMaybe<ServerTransactionServiceAggregateOrderBy>
  type?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: service */
export type ServerServicePkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "service" */
export enum ServerServiceSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  AvailableForClient = 'availableForClient',
  /** column name */
  AvailableToAllBranches = 'availableToAllBranches',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  Color = 'color',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DefaultDuration = 'defaultDuration',
  /** column name */
  Description = 'description',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Picture = 'picture',
  /** column name */
  Price = 'price',
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "service" */
export type ServerServiceSetInput = {
  /** услуги, которые клиент может создавать */
  availableForClient?: InputMaybe<Scalars['Boolean']>
  branchId?: InputMaybe<Scalars['Int']>
  color?: InputMaybe<Scalars['String']>
  costPrice?: InputMaybe<Scalars['numeric']>
  defaultDuration?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  groupId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  picture?: InputMaybe<Scalars['String']>
  price?: InputMaybe<Scalars['numeric']>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<Scalars['smallint']>
}

/** order by stddev() on columns of table "service" */
export type ServerServiceStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  defaultDuration?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "service" */
export type ServerServiceStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  defaultDuration?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "service" */
export type ServerServiceStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  defaultDuration?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "service" */
export type ServerServiceSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  defaultDuration?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<ServerOrderBy>
}

/** update columns of table "service" */
export enum ServerServiceUpdateColumn {
  /** column name */
  AvailableForClient = 'availableForClient',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  Color = 'color',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  DefaultDuration = 'defaultDuration',
  /** column name */
  Description = 'description',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Picture = 'picture',
  /** column name */
  Price = 'price',
  /** column name */
  Type = 'type',
}

export type ServerServiceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerServiceIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerServiceSetInput>
  /** filter the rows which have to be updated */
  where: ServerServiceBoolExp
}

/** order by var_pop() on columns of table "service" */
export type ServerServiceVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  defaultDuration?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "service" */
export type ServerServiceVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  defaultDuration?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "service" */
export type ServerServiceVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  costPrice?: InputMaybe<ServerOrderBy>
  defaultDuration?: InputMaybe<ServerOrderBy>
  groupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  /** 1 - service, 2 - group service, 3 - for sale */
  type?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type ServerSmallintComparisonExp = {
  _eq?: InputMaybe<Scalars['smallint']>
  _gt?: InputMaybe<Scalars['smallint']>
  _gte?: InputMaybe<Scalars['smallint']>
  _in?: InputMaybe<Array<Scalars['smallint']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['smallint']>
  _lte?: InputMaybe<Scalars['smallint']>
  _neq?: InputMaybe<Scalars['smallint']>
  _nin?: InputMaybe<Array<Scalars['smallint']>>
}

/** columns and relationships of "softwarePOS" */
export type ServerSoftwarePos = {
  /** An array relationship */
  accountSoftwarePOSs: Array<ServerAccountSoftwarePos>
  authorId: Scalars['Int']
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  createdAt: Scalars['timestamptz']
  /** An object relationship */
  employee: ServerEmployee
  id: Scalars['Int']
  licenseKey: Scalars['String']
  login: Scalars['String']
  name: Scalars['String']
  password: Scalars['String']
  token: Maybe<Scalars['String']>
}

/** columns and relationships of "softwarePOS" */
export type ServerSoftwarePosAccountSoftwarePoSsArgs = {
  distinct_on?: InputMaybe<Array<ServerAccountSoftwarePosSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerAccountSoftwarePosOrderBy>>
  where?: InputMaybe<ServerAccountSoftwarePosBoolExp>
}

/** Boolean expression to filter rows from the table "softwarePOS". All fields are combined with a logical 'AND'. */
export type ServerSoftwarePosBoolExp = {
  _and?: InputMaybe<Array<ServerSoftwarePosBoolExp>>
  _not?: InputMaybe<ServerSoftwarePosBoolExp>
  _or?: InputMaybe<Array<ServerSoftwarePosBoolExp>>
  accountSoftwarePOSs?: InputMaybe<ServerAccountSoftwarePosBoolExp>
  authorId?: InputMaybe<ServerIntComparisonExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  id?: InputMaybe<ServerIntComparisonExp>
  licenseKey?: InputMaybe<ServerStringComparisonExp>
  login?: InputMaybe<ServerStringComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  password?: InputMaybe<ServerStringComparisonExp>
  token?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "softwarePOS". */
export type ServerSoftwarePosOrderBy = {
  accountSoftwarePOSs_aggregate?: InputMaybe<ServerAccountSoftwarePosAggregateOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  id?: InputMaybe<ServerOrderBy>
  licenseKey?: InputMaybe<ServerOrderBy>
  login?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  password?: InputMaybe<ServerOrderBy>
  token?: InputMaybe<ServerOrderBy>
}

/** select columns of table "softwarePOS" */
export enum ServerSoftwarePosSelectColumn {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LicenseKey = 'licenseKey',
  /** column name */
  Login = 'login',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  Token = 'token',
}

/** Streaming cursor of the table "softwarePOS" */
export type ServerSoftwarePosStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerSoftwarePosStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerSoftwarePosStreamCursorValueInput = {
  authorId?: InputMaybe<Scalars['Int']>
  branchId?: InputMaybe<Scalars['Int']>
  businessId?: InputMaybe<Scalars['Int']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['Int']>
  licenseKey?: InputMaybe<Scalars['String']>
  login?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  token?: InputMaybe<Scalars['String']>
}

/** Склады */
export type ServerStorage = {
  archived: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  createdAt: Scalars['timestamptz']
  description: Maybe<Scalars['String']>
  id: Scalars['Int']
  /** An array relationship */
  incomingMovement: Array<ServerMovement>
  /** An aggregate relationship */
  incomingMovement_aggregate: ServerMovementAggregate
  name: Scalars['String']
  /** An array relationship */
  outgoingMovement: Array<ServerMovement>
  /** An aggregate relationship */
  outgoingMovement_aggregate: ServerMovementAggregate
  /** An array relationship */
  storageEmployeeGroups: Array<ServerStorageEmployeeGroup>
}

/** Склады */
export type ServerStorageIncomingMovementArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementOrderBy>>
  where?: InputMaybe<ServerMovementBoolExp>
}

/** Склады */
export type ServerStorageIncomingMovementAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementOrderBy>>
  where?: InputMaybe<ServerMovementBoolExp>
}

/** Склады */
export type ServerStorageOutgoingMovementArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementOrderBy>>
  where?: InputMaybe<ServerMovementBoolExp>
}

/** Склады */
export type ServerStorageOutgoingMovementAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementOrderBy>>
  where?: InputMaybe<ServerMovementBoolExp>
}

/** Склады */
export type ServerStorageStorageEmployeeGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerStorageEmployeeGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerStorageEmployeeGroupOrderBy>>
  where?: InputMaybe<ServerStorageEmployeeGroupBoolExp>
}

/** Какие группы сотрудников могут видеть склад */
export type ServerStorageEmployeeGroup = {
  /** An object relationship */
  business: ServerBusiness
  /** An object relationship */
  employeeGroup: ServerEmployeeGroup
  employeeGroupId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  storage: ServerStorage
  storageId: Scalars['Int']
}

/** order by aggregate values of table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupAggregateOrderBy = {
  avg?: InputMaybe<ServerStorageEmployeeGroupAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerStorageEmployeeGroupMaxOrderBy>
  min?: InputMaybe<ServerStorageEmployeeGroupMinOrderBy>
  stddev?: InputMaybe<ServerStorageEmployeeGroupStddevOrderBy>
  stddev_pop?: InputMaybe<ServerStorageEmployeeGroupStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerStorageEmployeeGroupStddevSampOrderBy>
  sum?: InputMaybe<ServerStorageEmployeeGroupSumOrderBy>
  var_pop?: InputMaybe<ServerStorageEmployeeGroupVarPopOrderBy>
  var_samp?: InputMaybe<ServerStorageEmployeeGroupVarSampOrderBy>
  variance?: InputMaybe<ServerStorageEmployeeGroupVarianceOrderBy>
}

/** input type for inserting array relation for remote table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupArrRelInsertInput = {
  data: Array<ServerStorageEmployeeGroupInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerStorageEmployeeGroupOnConflict>
}

/** order by avg() on columns of table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupAvgOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "storageEmployeeGroup". All fields are combined with a logical 'AND'. */
export type ServerStorageEmployeeGroupBoolExp = {
  _and?: InputMaybe<Array<ServerStorageEmployeeGroupBoolExp>>
  _not?: InputMaybe<ServerStorageEmployeeGroupBoolExp>
  _or?: InputMaybe<Array<ServerStorageEmployeeGroupBoolExp>>
  business?: InputMaybe<ServerBusinessBoolExp>
  employeeGroup?: InputMaybe<ServerEmployeeGroupBoolExp>
  employeeGroupId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  storage?: InputMaybe<ServerStorageBoolExp>
  storageId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "storageEmployeeGroup" */
export enum ServerStorageEmployeeGroupConstraint {
  /** unique or primary key constraint on columns "id" */
  StorageEmployeePkey = 'storageEmployee_pkey',
}

/** input type for inserting data into table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupInsertInput = {
  employeeGroup?: InputMaybe<ServerEmployeeGroupObjRelInsertInput>
  employeeGroupId?: InputMaybe<Scalars['Int']>
  storage?: InputMaybe<ServerStorageObjRelInsertInput>
  storageId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupMaxOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupMinOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerStorageEmployeeGroup>
}

/** on_conflict condition type for table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupOnConflict = {
  constraint: ServerStorageEmployeeGroupConstraint
  update_columns?: Array<ServerStorageEmployeeGroupUpdateColumn>
  where?: InputMaybe<ServerStorageEmployeeGroupBoolExp>
}

/** Ordering options when selecting data from "storageEmployeeGroup". */
export type ServerStorageEmployeeGroupOrderBy = {
  business?: InputMaybe<ServerBusinessOrderBy>
  employeeGroup?: InputMaybe<ServerEmployeeGroupOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  storage?: InputMaybe<ServerStorageOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "storageEmployeeGroup" */
export enum ServerStorageEmployeeGroupSelectColumn {
  /** column name */
  EmployeeGroupId = 'employeeGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  StorageId = 'storageId',
}

/** order by stddev() on columns of table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupStddevOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupStddevPopOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupStddevSampOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupSumOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "storageEmployeeGroup" (current role has no relevant permissions) */
export enum ServerStorageEmployeeGroupUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupVarPopOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupVarSampOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "storageEmployeeGroup" */
export type ServerStorageEmployeeGroupVarianceOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  storageId?: InputMaybe<ServerOrderBy>
}

/** order by aggregate values of table "storage" */
export type ServerStorageAggregateOrderBy = {
  avg?: InputMaybe<ServerStorageAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerStorageMaxOrderBy>
  min?: InputMaybe<ServerStorageMinOrderBy>
  stddev?: InputMaybe<ServerStorageStddevOrderBy>
  stddev_pop?: InputMaybe<ServerStorageStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerStorageStddevSampOrderBy>
  sum?: InputMaybe<ServerStorageSumOrderBy>
  var_pop?: InputMaybe<ServerStorageVarPopOrderBy>
  var_samp?: InputMaybe<ServerStorageVarSampOrderBy>
  variance?: InputMaybe<ServerStorageVarianceOrderBy>
}

/** input type for inserting array relation for remote table "storage" */
export type ServerStorageArrRelInsertInput = {
  data: Array<ServerStorageInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerStorageOnConflict>
}

/** order by avg() on columns of table "storage" */
export type ServerStorageAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "storage". All fields are combined with a logical 'AND'. */
export type ServerStorageBoolExp = {
  _and?: InputMaybe<Array<ServerStorageBoolExp>>
  _not?: InputMaybe<ServerStorageBoolExp>
  _or?: InputMaybe<Array<ServerStorageBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  incomingMovement?: InputMaybe<ServerMovementBoolExp>
  incomingMovement_aggregate?: InputMaybe<ServerMovementAggregateBoolExp>
  name?: InputMaybe<ServerStringComparisonExp>
  outgoingMovement?: InputMaybe<ServerMovementBoolExp>
  outgoingMovement_aggregate?: InputMaybe<ServerMovementAggregateBoolExp>
  storageEmployeeGroups?: InputMaybe<ServerStorageEmployeeGroupBoolExp>
}

/** unique or primary key constraints on table "storage" */
export enum ServerStorageConstraint {
  /** unique or primary key constraint on columns "id" */
  StoragesPkey = 'storages_pkey',
}

/** input type for incrementing numeric columns in table "storage" */
export type ServerStorageIncInput = {
  branchId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "storage" */
export type ServerStorageInsertInput = {
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  incomingMovement?: InputMaybe<ServerMovementArrRelInsertInput>
  name?: InputMaybe<Scalars['String']>
  outgoingMovement?: InputMaybe<ServerMovementArrRelInsertInput>
  storageEmployeeGroups?: InputMaybe<ServerStorageEmployeeGroupArrRelInsertInput>
}

/** order by max() on columns of table "storage" */
export type ServerStorageMaxOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "storage" */
export type ServerStorageMinOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "storage" */
export type ServerStorageMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerStorage>
}

/** input type for inserting object relation for remote table "storage" */
export type ServerStorageObjRelInsertInput = {
  data: ServerStorageInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerStorageOnConflict>
}

/** on_conflict condition type for table "storage" */
export type ServerStorageOnConflict = {
  constraint: ServerStorageConstraint
  update_columns?: Array<ServerStorageUpdateColumn>
  where?: InputMaybe<ServerStorageBoolExp>
}

/** Ordering options when selecting data from "storage". */
export type ServerStorageOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  incomingMovement_aggregate?: InputMaybe<ServerMovementAggregateOrderBy>
  name?: InputMaybe<ServerOrderBy>
  outgoingMovement_aggregate?: InputMaybe<ServerMovementAggregateOrderBy>
  storageEmployeeGroups_aggregate?: InputMaybe<ServerStorageEmployeeGroupAggregateOrderBy>
}

/** primary key columns input for table: storage */
export type ServerStoragePkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "storage" */
export enum ServerStorageSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "storage" */
export type ServerStorageSetInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
  branchId?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

/** order by stddev() on columns of table "storage" */
export type ServerStorageStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "storage" */
export type ServerStorageStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "storage" */
export type ServerStorageStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "storage" */
export type ServerStorageSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** update columns of table "storage" */
export enum ServerStorageUpdateColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type ServerStorageUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerStorageIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerStorageSetInput>
  /** filter the rows which have to be updated */
  where: ServerStorageBoolExp
}

/** order by var_pop() on columns of table "storage" */
export type ServerStorageVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "storage" */
export type ServerStorageVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "storage" */
export type ServerStorageVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "subscriptionPeriod" */
export type ServerSubscriptionPeriod = {
  additionalMonths: Scalars['Int']
  months: Scalars['Int']
  period: Scalars['String']
}

/** Boolean expression to filter rows from the table "subscriptionPeriod". All fields are combined with a logical 'AND'. */
export type ServerSubscriptionPeriodBoolExp = {
  _and?: InputMaybe<Array<ServerSubscriptionPeriodBoolExp>>
  _not?: InputMaybe<ServerSubscriptionPeriodBoolExp>
  _or?: InputMaybe<Array<ServerSubscriptionPeriodBoolExp>>
  additionalMonths?: InputMaybe<ServerIntComparisonExp>
  months?: InputMaybe<ServerIntComparisonExp>
  period?: InputMaybe<ServerStringComparisonExp>
}

/** Ordering options when selecting data from "subscriptionPeriod". */
export type ServerSubscriptionPeriodOrderBy = {
  additionalMonths?: InputMaybe<ServerOrderBy>
  months?: InputMaybe<ServerOrderBy>
  period?: InputMaybe<ServerOrderBy>
}

/** select columns of table "subscriptionPeriod" */
export enum ServerSubscriptionPeriodSelectColumn {
  /** column name */
  AdditionalMonths = 'additionalMonths',
  /** column name */
  Months = 'months',
  /** column name */
  Period = 'period',
}

/** Streaming cursor of the table "subscriptionPeriod" */
export type ServerSubscriptionPeriodStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerSubscriptionPeriodStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerSubscriptionPeriodStreamCursorValueInput = {
  additionalMonths?: InputMaybe<Scalars['Int']>
  months?: InputMaybe<Scalars['Int']>
  period?: InputMaybe<Scalars['String']>
}

export type ServerSubscriptionRoot = {
  /** fetch data from the table: "account" using primary key columns */
  accountById: Maybe<ServerAccount>
  /** fetch data from the table: "accountSoftwarePOS" using primary key columns */
  accountSoftwarePOSById: Maybe<ServerAccountSoftwarePos>
  /** An array relationship */
  accountSoftwarePOSs: Array<ServerAccountSoftwarePos>
  /** fetch data from the table in a streaming manner: "accountSoftwarePOS" */
  accountSoftwarePOSsStream: Array<ServerAccountSoftwarePos>
  /** An array relationship */
  accounts: Array<ServerAccount>
  /** fetch data from the table: "address" using primary key columns */
  addressById: Maybe<ServerAddress>
  /** fetch data from the table: "address" */
  addresses: Array<ServerAddress>
  /** fetch data from the table: "bankDetails" using primary key columns */
  bankDetailsById: Maybe<ServerBankDetails>
  /** fetch data from the table: "bankDetails" */
  banksDetails: Array<ServerBankDetails>
  /** fetch data from the table: "billingInfo" using primary key columns */
  billingInfoById: Maybe<ServerBillingInfo>
  /** An array relationship */
  billingInfos: Array<ServerBillingInfo>
  /** fetch data from the table: "booking" using primary key columns */
  bookingById: Maybe<ServerBooking>
  /** fetch data from the table: "bookingClientSettings" */
  bookingClientSettings: Array<ServerBookingClientSettings>
  /** fetch data from the table: "bookingClientSettings" using primary key columns */
  bookingClientSettingsById: Maybe<ServerBookingClientSettings>
  /** fetch data from the table in a streaming manner: "bookingClientSettings" */
  bookingClientSettingsStream: Array<ServerBookingClientSettings>
  /** fetch data from the table: "bookingClientSettingsView" */
  bookingClientSettingsView: Array<ServerBookingClientSettingsView>
  /** fetch data from the table in a streaming manner: "bookingClientSettingsView" */
  bookingClientSettingsViewStream: Array<ServerBookingClientSettingsView>
  /** fetch data from the table: "bookingReviewView" */
  bookingReviews: Array<ServerBookingReviewView>
  /** fetch data from the table in a streaming manner: "bookingReviewView" */
  bookingReviewsStream: Array<ServerBookingReviewView>
  /** fetch data from the table in a streaming manner: "bookingStatusView" */
  bookingStatusView_stream: Array<ServerBookingStatusView>
  /** fetch data from the table: "bookingStatusView" */
  bookingStatuses: Array<ServerBookingStatusView>
  /** An array relationship */
  bookings: Array<ServerBooking>
  /** fetch aggregated fields from the table: "booking" */
  bookingsAggregate: ServerBookingAggregate
  /** fetch data from the table: "branch" using primary key columns */
  branchById: Maybe<ServerBranch>
  /** fetch data from the table: "branchToSwitchAsClientView" */
  branchToSwitchAsClientView: Array<ServerBranchToSwitchAsClientView>
  /** fetch data from the table in a streaming manner: "branchToSwitchAsClientView" */
  branchToSwitchAsClientViewStream: Array<ServerBranchToSwitchAsClientView>
  /** fetch data from the table: "branchView" */
  branchView: Array<ServerBranchView>
  /** fetch data from the table in a streaming manner: "branchView" */
  branchViewStream: Array<ServerBranchView>
  /** An array relationship */
  branches: Array<ServerBranch>
  /** fetch data from the table: "business" using primary key columns */
  businessById: Maybe<ServerBusiness>
  /** fetch data from the table: "businessCurrentPlanIdView" */
  businessCurrentPlanIdView: Array<ServerBusinessCurrentPlanIdView>
  /** fetch data from the table in a streaming manner: "businessCurrentPlanIdView" */
  businessCurrentPlanIdViewStream: Array<ServerBusinessCurrentPlanIdView>
  /** fetch data from the table: "businessInfoView" */
  businessInfoView: Array<ServerBusinessInfoView>
  /** fetch data from the table in a streaming manner: "businessInfoView" */
  businessInfoViewStream: Array<ServerBusinessInfoView>
  /** fetch data from the table: "businessInfoReferrerView" */
  businessInfosReferrerView: Array<ServerBusinessInfoReferrerView>
  /** fetch data from the table in a streaming manner: "businessInfoReferrerView" */
  businessInfosReferrerViewStream: Array<ServerBusinessInfoReferrerView>
  /** fetch data from the table: "businessIsTestView" */
  businessIsTestView: Array<ServerBusinessIsTestView>
  /** fetch data from the table in a streaming manner: "businessIsTestView" */
  businessIsTestViewStream: Array<ServerBusinessIsTestView>
  /** fetch data from the table: "businessLanding" using primary key columns */
  businessLandingById: Maybe<ServerBusinessLanding>
  /** fetch data from the table: "businessLandingView" */
  businessLandingViews: Array<ServerBusinessLandingView>
  /** fetch data from the table in a streaming manner: "businessLandingView" */
  businessLandingViewsStream: Array<ServerBusinessLandingView>
  /** fetch data from the table: "businessLanding" */
  businessLandings: Array<ServerBusinessLanding>
  /** fetch data from the table in a streaming manner: "businessLanding" */
  businessLandingsStream: Array<ServerBusinessLanding>
  /** fetch data from the table: "businessPromoCode" using primary key columns */
  businessPromoCodeById: Maybe<ServerBusinessPromoCode>
  /** An array relationship */
  businessPromoCodes: Array<ServerBusinessPromoCode>
  /** fetch data from the table: "businessPromoCodeReferrerView" */
  businessPromoCodesReferrerView: Array<ServerBusinessPromoCodeReferrerView>
  /** fetch data from the table in a streaming manner: "businessPromoCodeReferrerView" */
  businessPromoCodesReferrerViewStream: Array<ServerBusinessPromoCodeReferrerView>
  /** fetch data from the table in a streaming manner: "businessPromoCode" */
  businessPromoCodesStream: Array<ServerBusinessPromoCode>
  /** fetch data from the table: "businessPromoCodeView" */
  businessPromoCodesView: Array<ServerBusinessPromoCodeView>
  /** fetch data from the table in a streaming manner: "businessPromoCodeView" */
  businessPromoCodesViewStream: Array<ServerBusinessPromoCodeView>
  /** fetch data from the table: "businessSettings" using primary key columns */
  businessSettingsById: Maybe<ServerBusinessSettings>
  /** fetch data from the table: "businessTelegramIntegrationView" */
  businessTelegramIntegrationView: Array<ServerBusinessTelegramIntegrationView>
  /** fetch data from the table in a streaming manner: "businessTelegramIntegrationView" */
  businessTelegramIntegrationViewStream: Array<ServerBusinessTelegramIntegrationView>
  /** fetch data from the table: "businessToSwitch" */
  businessToSwitch: Array<ServerBusinessToSwitch>
  /** fetch data from the table in a streaming manner: "businessToSwitch" */
  businessToSwitch_stream: Array<ServerBusinessToSwitch>
  /** fetch data from the table: "businessType" using primary key columns */
  businessTypeById: Maybe<ServerBusinessType>
  /** fetch data from the table in a streaming manner: "businessType" */
  businessType_stream: Array<ServerBusinessType>
  /** fetch data from the table: "businessType" */
  businessTypes: Array<ServerBusinessType>
  /** fetch data from the table: "business" */
  businesses: Array<ServerBusiness>
  /** fetch data from the table: "card" using primary key columns */
  cardById: Maybe<ServerCard>
  /** fetch data from the table: "cardTemplate" using primary key columns */
  cardTemplateById: Maybe<ServerCardTemplate>
  /** fetch data from the table: "cardTemplateNameView" */
  cardTemplateName: Array<ServerCardTemplateNameView>
  /** fetch data from the table in a streaming manner: "cardTemplateNameView" */
  cardTemplateNameView_stream: Array<ServerCardTemplateNameView>
  /** fetch data from the table: "cardTemplate" */
  cardTemplates: Array<ServerCardTemplate>
  /** An array relationship */
  cards: Array<ServerCard>
  /** fetch data from the table in a streaming manner: "clientBalanceView" */
  clientBalanceView_stream: Array<ServerClientBalanceView>
  /** fetch data from the table: "client" using primary key columns */
  clientById: Maybe<ServerClient>
  /** fetch data from the table: "clientDocument" using primary key columns */
  clientDocumentById: Maybe<ServerClientDocument>
  /** An array relationship */
  clientDocuments: Array<ServerClientDocument>
  /** fetch data from the table: "clientEmailView" */
  clientEmailsView: Array<ServerClientEmailView>
  /** fetch data from the table in a streaming manner: "clientEmailView" */
  clientEmailsViewStream: Array<ServerClientEmailView>
  /** fetch data from the table: "clientNote" using primary key columns */
  clientNoteById: Maybe<ServerClientNote>
  /** An array relationship */
  clientNotes: Array<ServerClientNote>
  /** fetch data from the table: "clientPhoneView" */
  clientPhoneView: Array<ServerClientPhoneView>
  /** fetch data from the table in a streaming manner: "clientPhoneView" */
  clientPhoneViewStream: Array<ServerClientPhoneView>
  /** fetch data from the table: "clientRelation" using primary key columns */
  clientRelationById: Maybe<ServerClientRelation>
  /** fetch data from the table: "clientRelation" */
  clientRelations: Array<ServerClientRelation>
  /** fetch data from the table: "clientSettings" using primary key columns */
  clientSettingsById: Maybe<ServerClientSettings>
  /** fetch data from the table: "clientTag" using primary key columns */
  clientTagById: Maybe<ServerClientTag>
  /** An array relationship */
  clientTags: Array<ServerClientTag>
  /** An array relationship */
  clients: Array<ServerClient>
  /** fetch aggregated fields from the table: "client" */
  clientsAggregate: ServerClientAggregate
  /** fetch data from the table: "clientBalanceView" */
  clientsBalance: Array<ServerClientBalanceView>
  /** fetch data from the table: "clientSettings" */
  clientsSettings: Array<ServerClientSettings>
  /** fetch data from the table in a streaming manner: "employeeBusinessOwnerView" */
  employeeBusinessOwnerView_stream: Array<ServerEmployeeBusinessOwnerView>
  /** fetch data from the table: "employeeBusinessOwnerView" */
  employeeBusinessOwners: Array<ServerEmployeeBusinessOwnerView>
  /** fetch data from the table: "employee" using primary key columns */
  employeeById: Maybe<ServerEmployee>
  /** fetch data from the table in a streaming manner: "employeeEmailView" */
  employeeEmailView_stream: Array<ServerEmployeeEmailView>
  /** fetch data from the table: "employeeEmailView" */
  employeeEmails: Array<ServerEmployeeEmailView>
  /** fetch data from the table in a streaming manner: "employeeGroupArchiveView" */
  employeeGroupArchiveView_stream: Array<ServerEmployeeGroupArchiveView>
  /** fetch data from the table: "employeeGroupArchiveView" */
  employeeGroupArchives: Array<ServerEmployeeGroupArchiveView>
  /** fetch data from the table: "employeeGroup" using primary key columns */
  employeeGroupById: Maybe<ServerEmployeeGroup>
  /** fetch data from the table: "employeeGroupOwnerView" */
  employeeGroupOwnerView: Array<ServerEmployeeGroupOwnerView>
  /** fetch data from the table in a streaming manner: "employeeGroupOwnerView" */
  employeeGroupOwnerViewStream: Array<ServerEmployeeGroupOwnerView>
  /** An array relationship */
  employeeGroups: Array<ServerEmployeeGroup>
  /** fetch data from the table: "employeeReviewView" */
  employeeReviews: Array<ServerEmployeeReviewView>
  /** fetch data from the table in a streaming manner: "employeeReviewView" */
  employeeReviewsStream: Array<ServerEmployeeReviewView>
  /** fetch data from the table: "employeeSchedule" using primary key columns */
  employeeScheduleById: Maybe<ServerEmployeeSchedule>
  /** An array relationship */
  employeeSchedules: Array<ServerEmployeeSchedule>
  /** fetch data from the table: "employeeSettings" */
  employeeSettings: Array<ServerEmployeeSettings>
  /** fetch data from the table: "employeeSettings" using primary key columns */
  employeeSettingsById: Maybe<ServerEmployeeSettings>
  /** An array relationship */
  employees: Array<ServerEmployee>
  /** fetch aggregated fields from the table: "employee" */
  employeesAggregate: ServerEmployeeAggregate
  /** fetch data from the table: "expaneInfo" using primary key columns */
  expaneInfoById: Maybe<ServerExpaneInfo>
  /** fetch data from the table: "expaneInfoCategory" */
  expaneInfoCategories: Array<ServerExpaneInfoCategory>
  /** fetch data from the table in a streaming manner: "expaneInfoCategory" */
  expaneInfoCategoriesStream: Array<ServerExpaneInfoCategory>
  /** fetch data from the table: "expaneInfoCategory" using primary key columns */
  expaneInfoCategoryById: Maybe<ServerExpaneInfoCategory>
  /** fetch data from the table in a streaming manner: "expaneInfo" */
  expaneInfoStream: Array<ServerExpaneInfo>
  /** fetch data from the table: "expaneInfo" */
  expaneInfos: Array<ServerExpaneInfo>
  /** fetch data from the table: "expensesReason" using primary key columns */
  expensesReasonById: Maybe<ServerExpensesReason>
  /** fetch data from the table: "expensesReason" */
  expensesReasons: Array<ServerExpensesReason>
  /** fetch data from the table: "featureFlags" */
  featureFlags: Array<ServerFeatureFlags>
  /** fetch data from the table in a streaming manner: "featureFlags" */
  featureFlagsStream: Array<ServerFeatureFlags>
  /** execute function "getBusinessToSwitch" which returns "businessToSwitch" */
  getBusinessToSwitch: Array<ServerBusinessToSwitch>
  /** fetch data from the table: "human" using primary key columns */
  humanById: Maybe<ServerHuman>
  /** fetch data from the table: "human" */
  humans: Array<ServerHuman>
  /** fetch data from the table: "inventory" */
  inventories: Array<ServerInventory>
  /** fetch aggregated fields from the table: "inventory" */
  inventoriesAggregate: ServerInventoryAggregate
  /** fetch data from the table: "inventory" using primary key columns */
  inventoryById: Maybe<ServerInventory>
  /** fetch data from the table: "lead" using primary key columns */
  leadById: Maybe<ServerLead>
  /** fetch data from the table: "lead" */
  leads: Array<ServerLead>
  /** fetch data from the table: "location" using primary key columns */
  locationById: Maybe<ServerLocation>
  /** fetch data from the table: "locationGroup" using primary key columns */
  locationGroupById: Maybe<ServerLocationGroup>
  /** fetch data from the table: "locationGroup" */
  locationGroups: Array<ServerLocationGroup>
  /** An array relationship */
  locations: Array<ServerLocation>
  /** fetch data from the table: "movement" using primary key columns */
  movementById: Maybe<ServerMovement>
  /** fetch data from the table: "movementProduct" using primary key columns */
  movementProductById: Maybe<ServerMovementProduct>
  /** An array relationship */
  movementProducts: Array<ServerMovementProduct>
  /** fetch aggregated fields from the table: "movementProduct" */
  movementProductsAggregate: ServerMovementProductAggregate
  /** An array relationship */
  movements: Array<ServerMovement>
  /** fetch aggregated fields from the table: "movement" */
  movementsAggregate: ServerMovementAggregate
  /** fetch data from the table: "businessSettings" */
  multipleBusinessSettings: Array<ServerBusinessSettings>
  /** fetch data from the table: "notification" using primary key columns */
  notificationById: Maybe<ServerNotification>
  /** An array relationship */
  notifications: Array<ServerNotification>
  /** fetch aggregated fields from the table: "notification" */
  notificationsAggregate: ServerNotificationAggregate
  /** fetch data from the table: "onlineBookingURL" using primary key columns */
  onlineBookingURLById: Maybe<ServerOnlineBookingUrl>
  /** fetch data from the table: "onlineBookingURL" */
  onlineBookingURLs: Array<ServerOnlineBookingUrl>
  /** fetch data from the table in a streaming manner: "onlineBookingURL" */
  onlineBookingURLsStream: Array<ServerOnlineBookingUrl>
  /** fetch data from the table: "plan" using primary key columns */
  planById: Maybe<ServerPlan>
  /** fetch data from the table in a streaming manner: "plan" */
  plan_stream: Array<ServerPlan>
  /** fetch data from the table: "plan" */
  plans: Array<ServerPlan>
  /** fetch data from the table in a streaming manner: "productArchiveView" */
  productArchiveView_stream: Array<ServerProductArchiveView>
  /** fetch data from the table: "productArchiveView" */
  productArchives: Array<ServerProductArchiveView>
  /** fetch data from the table: "product" using primary key columns */
  productById: Maybe<ServerProduct>
  /** fetch data from the table in a streaming manner: "productGroupArchiveView" */
  productGroupArchiveView_stream: Array<ServerProductGroupArchiveView>
  /** fetch data from the table: "productGroupArchiveView" */
  productGroupArchives: Array<ServerProductGroupArchiveView>
  /** fetch data from the table: "productGroup" using primary key columns */
  productGroupById: Maybe<ServerProductGroup>
  /** fetch data from the table: "productGroup" */
  productGroups: Array<ServerProductGroup>
  /** An array relationship */
  products: Array<ServerProduct>
  /** fetch aggregated fields from the table: "product" */
  productsAggregate: ServerProductAggregate
  /** fetch data from the table: "promoCode" using primary key columns */
  promoCodeById: Maybe<ServerPromoCode>
  /** An array relationship */
  promoCodes: Array<ServerPromoCode>
  /** fetch data from the table: "promoCodeReferrerView" */
  promoCodesReferrerView: Array<ServerPromoCodeReferrerView>
  /** fetch data from the table in a streaming manner: "promoCodeReferrerView" */
  promoCodesReferrerViewStream: Array<ServerPromoCodeReferrerView>
  /** fetch data from the table in a streaming manner: "promoCode" */
  promoCodesStream: Array<ServerPromoCode>
  /** fetch data from the table: "recurringBooking" using primary key columns */
  recurringBookingById: Maybe<ServerRecurringBooking>
  /** fetch data from the table: "recurringBooking" */
  recurringBookings: Array<ServerRecurringBooking>
  /** fetch data from the table in a streaming manner: "recurringBooking" */
  recurringBookingsStream: Array<ServerRecurringBooking>
  /** fetch data from the table: "referrer" using primary key columns */
  referrerById: Maybe<ServerReferrer>
  /** fetch data from the table: "referrer" */
  referrers: Array<ServerReferrer>
  /** fetch data from the table in a streaming manner: "referrer" */
  referrersStream: Array<ServerReferrer>
  /** fetch data from the table: "referrerView" */
  referrersView: Array<ServerReferrerView>
  /** fetch data from the table in a streaming manner: "referrerView" */
  referrersViewStream: Array<ServerReferrerView>
  /** fetch data from the table: "revenueReason" using primary key columns */
  revenueReasonById: Maybe<ServerRevenueReason>
  /** fetch data from the table: "revenueReason" */
  revenueReasons: Array<ServerRevenueReason>
  /** fetch data from the table: "review" using primary key columns */
  reviewById: Maybe<ServerReview>
  /** fetch data from the table: "review" */
  reviews: Array<ServerReview>
  /** fetch aggregated fields from the table: "review" */
  reviewsAggregate: ServerReviewAggregate
  /** fetch data from the table in a streaming manner: "review" */
  reviewsStream: Array<ServerReview>
  /** fetch data from the table: "role" using primary key columns */
  roleById: Maybe<ServerRole>
  /** fetch data from the table: "rolePermission" */
  rolePermission: Array<ServerRolePermission>
  /** fetch data from the table: "rolePermission" using primary key columns */
  rolePermissionById: Maybe<ServerRolePermission>
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<ServerRole>
  /** fetch data from the table: "role" */
  roles: Array<ServerRole>
  /** fetch data from the table: "salaryIssue" using primary key columns */
  salaryIssueById: Maybe<ServerSalaryIssue>
  /** An array relationship */
  salaryIssues: Array<ServerSalaryIssue>
  /** fetch data from the table: "salarySetting" using primary key columns */
  salarySettingById: Maybe<ServerSalarySetting>
  /** An array relationship */
  salarySettings: Array<ServerSalarySetting>
  /** fetch aggregated fields from the table: "salarySetting" */
  salarySettingsAggregate: ServerSalarySettingAggregate
  /** fetch data from the table: "schedule" using primary key columns */
  scheduleById: Maybe<ServerSchedule>
  /** fetch data from the table: "schedule" */
  schedules: Array<ServerSchedule>
  /** fetch data from the table: "serverStatus" */
  serverStatus: Array<ServerServerStatus>
  /** fetch data from the table: "serverStatus" using primary key columns */
  serverStatusById: Maybe<ServerServerStatus>
  /** fetch data from the table in a streaming manner: "serverStatus" */
  serverStatus_stream: Array<ServerServerStatus>
  /** fetch data from the table: "service" using primary key columns */
  serviceById: Maybe<ServerService>
  /** fetch data from the table: "serviceGroup" using primary key columns */
  serviceGroupById: Maybe<ServerServiceGroup>
  /** fetch data from the table: "serviceGroup" */
  serviceGroups: Array<ServerServiceGroup>
  /** An array relationship */
  services: Array<ServerService>
  /** fetch data from the table: "softwarePOS" using primary key columns */
  softwarePOSById: Maybe<ServerSoftwarePos>
  /** fetch data from the table: "softwarePOS" */
  softwarePOSs: Array<ServerSoftwarePos>
  /** fetch data from the table in a streaming manner: "softwarePOS" */
  softwarePOSsStream: Array<ServerSoftwarePos>
  /** fetch data from the table: "storage" using primary key columns */
  storageById: Maybe<ServerStorage>
  /** An array relationship */
  storages: Array<ServerStorage>
  /** fetch data from the table: "subscriptionPeriod" using primary key columns */
  subscriptionPeriodById: Maybe<ServerSubscriptionPeriod>
  /** fetch data from the table: "subscriptionPeriod" */
  subscriptionPeriods: Array<ServerSubscriptionPeriod>
  /** fetch data from the table in a streaming manner: "subscriptionPeriod" */
  subscriptionPeriodsStream: Array<ServerSubscriptionPeriod>
  /** fetch data from the table: "super" using primary key columns */
  superById: Maybe<ServerSuper>
  /** fetch data from the table: "super" */
  supers: Array<ServerSuper>
  /** fetch data from the table in a streaming manner: "super" */
  supersStream: Array<ServerSuper>
  /** fetch data from the table: "supplier" using primary key columns */
  supplierById: Maybe<ServerSupplier>
  /** fetch data from the table: "supplier" */
  suppliers: Array<ServerSupplier>
  /** fetch data from the table: "tag" using primary key columns */
  tagById: Maybe<ServerTag>
  /** fetch data from the table: "tag" */
  tags: Array<ServerTag>
  /** fetch data from the table: "timeOff" using primary key columns */
  timeOffById: Maybe<ServerTimeOff>
  /** fetch data from the table: "timeOffReason" using primary key columns */
  timeOffReasonById: Maybe<ServerTimeOffReason>
  /** fetch data from the table: "timeOffReason" */
  timeOffReasons: Array<ServerTimeOffReason>
  /** fetch data from the table: "timeOff" */
  timeOffs: Array<ServerTimeOff>
  /** fetch data from the table: "transaction" using primary key columns */
  transactionById: Maybe<ServerTransaction>
  /** An array relationship */
  transactions: Array<ServerTransaction>
  /** fetch aggregated fields from the table: "transaction" */
  transactionsAggregate: ServerTransactionAggregate
  /** fetch data from the table: "unitedBooking" using primary key columns */
  unitedBookingById: Maybe<ServerUnitedBooking>
  /** fetch data from the table: "unitedBooking" */
  unitedBookings: Array<ServerUnitedBooking>
  /** fetch data from the table: "userExpoNotification" using primary key columns */
  userExpoNotificationByEmail: Maybe<ServerUserExpoNotification>
  /** fetch data from the table: "userExpoNotification" */
  userExpoNotifications: Array<ServerUserExpoNotification>
  /** fetch data from the table: "waitingList" using primary key columns */
  waitingListById: Maybe<ServerWaitingList>
  /** An array relationship */
  waitingLists: Array<ServerWaitingList>
}

export type ServerSubscriptionRootAccountByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootAccountSoftwarePosByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootAccountSoftwarePoSsArgs = {
  distinct_on?: InputMaybe<Array<ServerAccountSoftwarePosSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerAccountSoftwarePosOrderBy>>
  where?: InputMaybe<ServerAccountSoftwarePosBoolExp>
}

export type ServerSubscriptionRootAccountSoftwarePoSsStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerAccountSoftwarePosStreamCursorInput>>
  where?: InputMaybe<ServerAccountSoftwarePosBoolExp>
}

export type ServerSubscriptionRootAccountsArgs = {
  distinct_on?: InputMaybe<Array<ServerAccountSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerAccountOrderBy>>
  where?: InputMaybe<ServerAccountBoolExp>
}

export type ServerSubscriptionRootAddressByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootAddressesArgs = {
  distinct_on?: InputMaybe<Array<ServerAddressSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerAddressOrderBy>>
  where?: InputMaybe<ServerAddressBoolExp>
}

export type ServerSubscriptionRootBankDetailsByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootBanksDetailsArgs = {
  distinct_on?: InputMaybe<Array<ServerBankDetailsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBankDetailsOrderBy>>
  where?: InputMaybe<ServerBankDetailsBoolExp>
}

export type ServerSubscriptionRootBillingInfoByIdArgs = {
  id: Scalars['uuid']
}

export type ServerSubscriptionRootBillingInfosArgs = {
  distinct_on?: InputMaybe<Array<ServerBillingInfoSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBillingInfoOrderBy>>
  where?: InputMaybe<ServerBillingInfoBoolExp>
}

export type ServerSubscriptionRootBookingByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootBookingClientSettingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingClientSettingsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingClientSettingsOrderBy>>
  where?: InputMaybe<ServerBookingClientSettingsBoolExp>
}

export type ServerSubscriptionRootBookingClientSettingsByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootBookingClientSettingsStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBookingClientSettingsStreamCursorInput>>
  where?: InputMaybe<ServerBookingClientSettingsBoolExp>
}

export type ServerSubscriptionRootBookingClientSettingsViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingClientSettingsViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingClientSettingsViewOrderBy>>
  where?: InputMaybe<ServerBookingClientSettingsViewBoolExp>
}

export type ServerSubscriptionRootBookingClientSettingsViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBookingClientSettingsViewStreamCursorInput>>
  where?: InputMaybe<ServerBookingClientSettingsViewBoolExp>
}

export type ServerSubscriptionRootBookingReviewsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingReviewViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingReviewViewOrderBy>>
  where?: InputMaybe<ServerBookingReviewViewBoolExp>
}

export type ServerSubscriptionRootBookingReviewsStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBookingReviewViewStreamCursorInput>>
  where?: InputMaybe<ServerBookingReviewViewBoolExp>
}

export type ServerSubscriptionRootBookingStatusViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBookingStatusViewStreamCursorInput>>
  where?: InputMaybe<ServerBookingStatusViewBoolExp>
}

export type ServerSubscriptionRootBookingStatusesArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingStatusViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingStatusViewOrderBy>>
  where?: InputMaybe<ServerBookingStatusViewBoolExp>
}

export type ServerSubscriptionRootBookingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

export type ServerSubscriptionRootBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

export type ServerSubscriptionRootBranchByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootBranchToSwitchAsClientViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBranchToSwitchAsClientViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBranchToSwitchAsClientViewOrderBy>>
  where?: InputMaybe<ServerBranchToSwitchAsClientViewBoolExp>
}

export type ServerSubscriptionRootBranchToSwitchAsClientViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBranchToSwitchAsClientViewStreamCursorInput>>
  where?: InputMaybe<ServerBranchToSwitchAsClientViewBoolExp>
}

export type ServerSubscriptionRootBranchViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBranchViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBranchViewOrderBy>>
  where?: InputMaybe<ServerBranchViewBoolExp>
}

export type ServerSubscriptionRootBranchViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBranchViewStreamCursorInput>>
  where?: InputMaybe<ServerBranchViewBoolExp>
}

export type ServerSubscriptionRootBranchesArgs = {
  distinct_on?: InputMaybe<Array<ServerBranchSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBranchOrderBy>>
  where?: InputMaybe<ServerBranchBoolExp>
}

export type ServerSubscriptionRootBusinessByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootBusinessCurrentPlanIdViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessCurrentPlanIdViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessCurrentPlanIdViewOrderBy>>
  where?: InputMaybe<ServerBusinessCurrentPlanIdViewBoolExp>
}

export type ServerSubscriptionRootBusinessCurrentPlanIdViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBusinessCurrentPlanIdViewStreamCursorInput>>
  where?: InputMaybe<ServerBusinessCurrentPlanIdViewBoolExp>
}

export type ServerSubscriptionRootBusinessInfoViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessInfoViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessInfoViewOrderBy>>
  where?: InputMaybe<ServerBusinessInfoViewBoolExp>
}

export type ServerSubscriptionRootBusinessInfoViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBusinessInfoViewStreamCursorInput>>
  where?: InputMaybe<ServerBusinessInfoViewBoolExp>
}

export type ServerSubscriptionRootBusinessInfosReferrerViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessInfoReferrerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessInfoReferrerViewOrderBy>>
  where?: InputMaybe<ServerBusinessInfoReferrerViewBoolExp>
}

export type ServerSubscriptionRootBusinessInfosReferrerViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBusinessInfoReferrerViewStreamCursorInput>>
  where?: InputMaybe<ServerBusinessInfoReferrerViewBoolExp>
}

export type ServerSubscriptionRootBusinessIsTestViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessIsTestViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessIsTestViewOrderBy>>
  where?: InputMaybe<ServerBusinessIsTestViewBoolExp>
}

export type ServerSubscriptionRootBusinessIsTestViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBusinessIsTestViewStreamCursorInput>>
  where?: InputMaybe<ServerBusinessIsTestViewBoolExp>
}

export type ServerSubscriptionRootBusinessLandingByIdArgs = {
  id: Scalars['uuid']
}

export type ServerSubscriptionRootBusinessLandingViewsArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessLandingViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessLandingViewOrderBy>>
  where?: InputMaybe<ServerBusinessLandingViewBoolExp>
}

export type ServerSubscriptionRootBusinessLandingViewsStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBusinessLandingViewStreamCursorInput>>
  where?: InputMaybe<ServerBusinessLandingViewBoolExp>
}

export type ServerSubscriptionRootBusinessLandingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessLandingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessLandingOrderBy>>
  where?: InputMaybe<ServerBusinessLandingBoolExp>
}

export type ServerSubscriptionRootBusinessLandingsStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBusinessLandingStreamCursorInput>>
  where?: InputMaybe<ServerBusinessLandingBoolExp>
}

export type ServerSubscriptionRootBusinessPromoCodeByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootBusinessPromoCodesArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessPromoCodeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessPromoCodeOrderBy>>
  where?: InputMaybe<ServerBusinessPromoCodeBoolExp>
}

export type ServerSubscriptionRootBusinessPromoCodesReferrerViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessPromoCodeReferrerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessPromoCodeReferrerViewOrderBy>>
  where?: InputMaybe<ServerBusinessPromoCodeReferrerViewBoolExp>
}

export type ServerSubscriptionRootBusinessPromoCodesReferrerViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBusinessPromoCodeReferrerViewStreamCursorInput>>
  where?: InputMaybe<ServerBusinessPromoCodeReferrerViewBoolExp>
}

export type ServerSubscriptionRootBusinessPromoCodesStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBusinessPromoCodeStreamCursorInput>>
  where?: InputMaybe<ServerBusinessPromoCodeBoolExp>
}

export type ServerSubscriptionRootBusinessPromoCodesViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessPromoCodeViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessPromoCodeViewOrderBy>>
  where?: InputMaybe<ServerBusinessPromoCodeViewBoolExp>
}

export type ServerSubscriptionRootBusinessPromoCodesViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBusinessPromoCodeViewStreamCursorInput>>
  where?: InputMaybe<ServerBusinessPromoCodeViewBoolExp>
}

export type ServerSubscriptionRootBusinessSettingsByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootBusinessTelegramIntegrationViewArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessTelegramIntegrationViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessTelegramIntegrationViewOrderBy>>
  where?: InputMaybe<ServerBusinessTelegramIntegrationViewBoolExp>
}

export type ServerSubscriptionRootBusinessTelegramIntegrationViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBusinessTelegramIntegrationViewStreamCursorInput>>
  where?: InputMaybe<ServerBusinessTelegramIntegrationViewBoolExp>
}

export type ServerSubscriptionRootBusinessToSwitchArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessToSwitchSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessToSwitchOrderBy>>
  where?: InputMaybe<ServerBusinessToSwitchBoolExp>
}

export type ServerSubscriptionRootBusinessToSwitchStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBusinessToSwitchStreamCursorInput>>
  where?: InputMaybe<ServerBusinessToSwitchBoolExp>
}

export type ServerSubscriptionRootBusinessTypeByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootBusinessTypeStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerBusinessTypeStreamCursorInput>>
  where?: InputMaybe<ServerBusinessTypeBoolExp>
}

export type ServerSubscriptionRootBusinessTypesArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessTypeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessTypeOrderBy>>
  where?: InputMaybe<ServerBusinessTypeBoolExp>
}

export type ServerSubscriptionRootBusinessesArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessOrderBy>>
  where?: InputMaybe<ServerBusinessBoolExp>
}

export type ServerSubscriptionRootCardByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootCardTemplateByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootCardTemplateNameArgs = {
  distinct_on?: InputMaybe<Array<ServerCardTemplateNameViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerCardTemplateNameViewOrderBy>>
  where?: InputMaybe<ServerCardTemplateNameViewBoolExp>
}

export type ServerSubscriptionRootCardTemplateNameViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerCardTemplateNameViewStreamCursorInput>>
  where?: InputMaybe<ServerCardTemplateNameViewBoolExp>
}

export type ServerSubscriptionRootCardTemplatesArgs = {
  distinct_on?: InputMaybe<Array<ServerCardTemplateSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerCardTemplateOrderBy>>
  where?: InputMaybe<ServerCardTemplateBoolExp>
}

export type ServerSubscriptionRootCardsArgs = {
  distinct_on?: InputMaybe<Array<ServerCardSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerCardOrderBy>>
  where?: InputMaybe<ServerCardBoolExp>
}

export type ServerSubscriptionRootClientBalanceViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerClientBalanceViewStreamCursorInput>>
  where?: InputMaybe<ServerClientBalanceViewBoolExp>
}

export type ServerSubscriptionRootClientByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootClientDocumentByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootClientDocumentsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientDocumentSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientDocumentOrderBy>>
  where?: InputMaybe<ServerClientDocumentBoolExp>
}

export type ServerSubscriptionRootClientEmailsViewArgs = {
  distinct_on?: InputMaybe<Array<ServerClientEmailViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientEmailViewOrderBy>>
  where?: InputMaybe<ServerClientEmailViewBoolExp>
}

export type ServerSubscriptionRootClientEmailsViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerClientEmailViewStreamCursorInput>>
  where?: InputMaybe<ServerClientEmailViewBoolExp>
}

export type ServerSubscriptionRootClientNoteByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootClientNotesArgs = {
  distinct_on?: InputMaybe<Array<ServerClientNoteSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientNoteOrderBy>>
  where?: InputMaybe<ServerClientNoteBoolExp>
}

export type ServerSubscriptionRootClientPhoneViewArgs = {
  distinct_on?: InputMaybe<Array<ServerClientPhoneViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientPhoneViewOrderBy>>
  where?: InputMaybe<ServerClientPhoneViewBoolExp>
}

export type ServerSubscriptionRootClientPhoneViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerClientPhoneViewStreamCursorInput>>
  where?: InputMaybe<ServerClientPhoneViewBoolExp>
}

export type ServerSubscriptionRootClientRelationByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootClientRelationsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientRelationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientRelationOrderBy>>
  where?: InputMaybe<ServerClientRelationBoolExp>
}

export type ServerSubscriptionRootClientSettingsByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootClientTagByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootClientTagsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientTagSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientTagOrderBy>>
  where?: InputMaybe<ServerClientTagBoolExp>
}

export type ServerSubscriptionRootClientsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientOrderBy>>
  where?: InputMaybe<ServerClientBoolExp>
}

export type ServerSubscriptionRootClientsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerClientSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientOrderBy>>
  where?: InputMaybe<ServerClientBoolExp>
}

export type ServerSubscriptionRootClientsBalanceArgs = {
  distinct_on?: InputMaybe<Array<ServerClientBalanceViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientBalanceViewOrderBy>>
  where?: InputMaybe<ServerClientBalanceViewBoolExp>
}

export type ServerSubscriptionRootClientsSettingsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientSettingsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientSettingsOrderBy>>
  where?: InputMaybe<ServerClientSettingsBoolExp>
}

export type ServerSubscriptionRootEmployeeBusinessOwnerViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerEmployeeBusinessOwnerViewStreamCursorInput>>
  where?: InputMaybe<ServerEmployeeBusinessOwnerViewBoolExp>
}

export type ServerSubscriptionRootEmployeeBusinessOwnersArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeBusinessOwnerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeBusinessOwnerViewOrderBy>>
  where?: InputMaybe<ServerEmployeeBusinessOwnerViewBoolExp>
}

export type ServerSubscriptionRootEmployeeByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootEmployeeEmailViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerEmployeeEmailViewStreamCursorInput>>
  where?: InputMaybe<ServerEmployeeEmailViewBoolExp>
}

export type ServerSubscriptionRootEmployeeEmailsArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeEmailViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeEmailViewOrderBy>>
  where?: InputMaybe<ServerEmployeeEmailViewBoolExp>
}

export type ServerSubscriptionRootEmployeeGroupArchiveViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerEmployeeGroupArchiveViewStreamCursorInput>>
  where?: InputMaybe<ServerEmployeeGroupArchiveViewBoolExp>
}

export type ServerSubscriptionRootEmployeeGroupArchivesArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeGroupArchiveViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeGroupArchiveViewOrderBy>>
  where?: InputMaybe<ServerEmployeeGroupArchiveViewBoolExp>
}

export type ServerSubscriptionRootEmployeeGroupByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootEmployeeGroupOwnerViewArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeGroupOwnerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeGroupOwnerViewOrderBy>>
  where?: InputMaybe<ServerEmployeeGroupOwnerViewBoolExp>
}

export type ServerSubscriptionRootEmployeeGroupOwnerViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerEmployeeGroupOwnerViewStreamCursorInput>>
  where?: InputMaybe<ServerEmployeeGroupOwnerViewBoolExp>
}

export type ServerSubscriptionRootEmployeeGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeGroupOrderBy>>
  where?: InputMaybe<ServerEmployeeGroupBoolExp>
}

export type ServerSubscriptionRootEmployeeReviewsArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeReviewViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeReviewViewOrderBy>>
  where?: InputMaybe<ServerEmployeeReviewViewBoolExp>
}

export type ServerSubscriptionRootEmployeeReviewsStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerEmployeeReviewViewStreamCursorInput>>
  where?: InputMaybe<ServerEmployeeReviewViewBoolExp>
}

export type ServerSubscriptionRootEmployeeScheduleByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootEmployeeSchedulesArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeScheduleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeScheduleOrderBy>>
  where?: InputMaybe<ServerEmployeeScheduleBoolExp>
}

export type ServerSubscriptionRootEmployeeSettingsArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeSettingsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeSettingsOrderBy>>
  where?: InputMaybe<ServerEmployeeSettingsBoolExp>
}

export type ServerSubscriptionRootEmployeeSettingsByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootEmployeesArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeOrderBy>>
  where?: InputMaybe<ServerEmployeeBoolExp>
}

export type ServerSubscriptionRootEmployeesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerEmployeeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerEmployeeOrderBy>>
  where?: InputMaybe<ServerEmployeeBoolExp>
}

export type ServerSubscriptionRootExpaneInfoByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootExpaneInfoCategoriesArgs = {
  distinct_on?: InputMaybe<Array<ServerExpaneInfoCategorySelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerExpaneInfoCategoryOrderBy>>
  where?: InputMaybe<ServerExpaneInfoCategoryBoolExp>
}

export type ServerSubscriptionRootExpaneInfoCategoriesStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerExpaneInfoCategoryStreamCursorInput>>
  where?: InputMaybe<ServerExpaneInfoCategoryBoolExp>
}

export type ServerSubscriptionRootExpaneInfoCategoryByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootExpaneInfoStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerExpaneInfoStreamCursorInput>>
  where?: InputMaybe<ServerExpaneInfoBoolExp>
}

export type ServerSubscriptionRootExpaneInfosArgs = {
  distinct_on?: InputMaybe<Array<ServerExpaneInfoSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerExpaneInfoOrderBy>>
  where?: InputMaybe<ServerExpaneInfoBoolExp>
}

export type ServerSubscriptionRootExpensesReasonByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootExpensesReasonsArgs = {
  distinct_on?: InputMaybe<Array<ServerExpensesReasonSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerExpensesReasonOrderBy>>
  where?: InputMaybe<ServerExpensesReasonBoolExp>
}

export type ServerSubscriptionRootFeatureFlagsArgs = {
  distinct_on?: InputMaybe<Array<ServerFeatureFlagsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerFeatureFlagsOrderBy>>
  where?: InputMaybe<ServerFeatureFlagsBoolExp>
}

export type ServerSubscriptionRootFeatureFlagsStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerFeatureFlagsStreamCursorInput>>
  where?: InputMaybe<ServerFeatureFlagsBoolExp>
}

export type ServerSubscriptionRootGetBusinessToSwitchArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessToSwitchSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessToSwitchOrderBy>>
  where?: InputMaybe<ServerBusinessToSwitchBoolExp>
}

export type ServerSubscriptionRootHumanByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootHumansArgs = {
  distinct_on?: InputMaybe<Array<ServerHumanSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerHumanOrderBy>>
  where?: InputMaybe<ServerHumanBoolExp>
}

export type ServerSubscriptionRootInventoriesArgs = {
  distinct_on?: InputMaybe<Array<ServerInventorySelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerInventoryOrderBy>>
  where?: InputMaybe<ServerInventoryBoolExp>
}

export type ServerSubscriptionRootInventoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerInventorySelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerInventoryOrderBy>>
  where?: InputMaybe<ServerInventoryBoolExp>
}

export type ServerSubscriptionRootInventoryByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootLeadByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootLeadsArgs = {
  distinct_on?: InputMaybe<Array<ServerLeadSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerLeadOrderBy>>
  where?: InputMaybe<ServerLeadBoolExp>
}

export type ServerSubscriptionRootLocationByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootLocationGroupByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootLocationGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerLocationGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerLocationGroupOrderBy>>
  where?: InputMaybe<ServerLocationGroupBoolExp>
}

export type ServerSubscriptionRootLocationsArgs = {
  distinct_on?: InputMaybe<Array<ServerLocationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerLocationOrderBy>>
  where?: InputMaybe<ServerLocationBoolExp>
}

export type ServerSubscriptionRootMovementByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootMovementProductByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootMovementProductsArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementProductOrderBy>>
  where?: InputMaybe<ServerMovementProductBoolExp>
}

export type ServerSubscriptionRootMovementProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementProductOrderBy>>
  where?: InputMaybe<ServerMovementProductBoolExp>
}

export type ServerSubscriptionRootMovementsArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementOrderBy>>
  where?: InputMaybe<ServerMovementBoolExp>
}

export type ServerSubscriptionRootMovementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementOrderBy>>
  where?: InputMaybe<ServerMovementBoolExp>
}

export type ServerSubscriptionRootMultipleBusinessSettingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBusinessSettingsSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBusinessSettingsOrderBy>>
  where?: InputMaybe<ServerBusinessSettingsBoolExp>
}

export type ServerSubscriptionRootNotificationByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<ServerNotificationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerNotificationOrderBy>>
  where?: InputMaybe<ServerNotificationBoolExp>
}

export type ServerSubscriptionRootNotificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerNotificationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerNotificationOrderBy>>
  where?: InputMaybe<ServerNotificationBoolExp>
}

export type ServerSubscriptionRootOnlineBookingUrlByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootOnlineBookingUrLsArgs = {
  distinct_on?: InputMaybe<Array<ServerOnlineBookingUrlSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerOnlineBookingUrlOrderBy>>
  where?: InputMaybe<ServerOnlineBookingUrlBoolExp>
}

export type ServerSubscriptionRootOnlineBookingUrLsStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerOnlineBookingUrlStreamCursorInput>>
  where?: InputMaybe<ServerOnlineBookingUrlBoolExp>
}

export type ServerSubscriptionRootPlanByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootPlanStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerPlanStreamCursorInput>>
  where?: InputMaybe<ServerPlanBoolExp>
}

export type ServerSubscriptionRootPlansArgs = {
  distinct_on?: InputMaybe<Array<ServerPlanSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerPlanOrderBy>>
  where?: InputMaybe<ServerPlanBoolExp>
}

export type ServerSubscriptionRootProductArchiveViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerProductArchiveViewStreamCursorInput>>
  where?: InputMaybe<ServerProductArchiveViewBoolExp>
}

export type ServerSubscriptionRootProductArchivesArgs = {
  distinct_on?: InputMaybe<Array<ServerProductArchiveViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductArchiveViewOrderBy>>
  where?: InputMaybe<ServerProductArchiveViewBoolExp>
}

export type ServerSubscriptionRootProductByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootProductGroupArchiveViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerProductGroupArchiveViewStreamCursorInput>>
  where?: InputMaybe<ServerProductGroupArchiveViewBoolExp>
}

export type ServerSubscriptionRootProductGroupArchivesArgs = {
  distinct_on?: InputMaybe<Array<ServerProductGroupArchiveViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductGroupArchiveViewOrderBy>>
  where?: InputMaybe<ServerProductGroupArchiveViewBoolExp>
}

export type ServerSubscriptionRootProductGroupByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootProductGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerProductGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductGroupOrderBy>>
  where?: InputMaybe<ServerProductGroupBoolExp>
}

export type ServerSubscriptionRootProductsArgs = {
  distinct_on?: InputMaybe<Array<ServerProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductOrderBy>>
  where?: InputMaybe<ServerProductBoolExp>
}

export type ServerSubscriptionRootProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerProductSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerProductOrderBy>>
  where?: InputMaybe<ServerProductBoolExp>
}

export type ServerSubscriptionRootPromoCodeByIdArgs = {
  id: Scalars['String']
}

export type ServerSubscriptionRootPromoCodesArgs = {
  distinct_on?: InputMaybe<Array<ServerPromoCodeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerPromoCodeOrderBy>>
  where?: InputMaybe<ServerPromoCodeBoolExp>
}

export type ServerSubscriptionRootPromoCodesReferrerViewArgs = {
  distinct_on?: InputMaybe<Array<ServerPromoCodeReferrerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerPromoCodeReferrerViewOrderBy>>
  where?: InputMaybe<ServerPromoCodeReferrerViewBoolExp>
}

export type ServerSubscriptionRootPromoCodesReferrerViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerPromoCodeReferrerViewStreamCursorInput>>
  where?: InputMaybe<ServerPromoCodeReferrerViewBoolExp>
}

export type ServerSubscriptionRootPromoCodesStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerPromoCodeStreamCursorInput>>
  where?: InputMaybe<ServerPromoCodeBoolExp>
}

export type ServerSubscriptionRootRecurringBookingByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootRecurringBookingsArgs = {
  distinct_on?: InputMaybe<Array<ServerRecurringBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerRecurringBookingOrderBy>>
  where?: InputMaybe<ServerRecurringBookingBoolExp>
}

export type ServerSubscriptionRootRecurringBookingsStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerRecurringBookingStreamCursorInput>>
  where?: InputMaybe<ServerRecurringBookingBoolExp>
}

export type ServerSubscriptionRootReferrerByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootReferrersArgs = {
  distinct_on?: InputMaybe<Array<ServerReferrerSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerReferrerOrderBy>>
  where?: InputMaybe<ServerReferrerBoolExp>
}

export type ServerSubscriptionRootReferrersStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerReferrerStreamCursorInput>>
  where?: InputMaybe<ServerReferrerBoolExp>
}

export type ServerSubscriptionRootReferrersViewArgs = {
  distinct_on?: InputMaybe<Array<ServerReferrerViewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerReferrerViewOrderBy>>
  where?: InputMaybe<ServerReferrerViewBoolExp>
}

export type ServerSubscriptionRootReferrersViewStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerReferrerViewStreamCursorInput>>
  where?: InputMaybe<ServerReferrerViewBoolExp>
}

export type ServerSubscriptionRootRevenueReasonByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootRevenueReasonsArgs = {
  distinct_on?: InputMaybe<Array<ServerRevenueReasonSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerRevenueReasonOrderBy>>
  where?: InputMaybe<ServerRevenueReasonBoolExp>
}

export type ServerSubscriptionRootReviewByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootReviewsArgs = {
  distinct_on?: InputMaybe<Array<ServerReviewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerReviewOrderBy>>
  where?: InputMaybe<ServerReviewBoolExp>
}

export type ServerSubscriptionRootReviewsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerReviewSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerReviewOrderBy>>
  where?: InputMaybe<ServerReviewBoolExp>
}

export type ServerSubscriptionRootReviewsStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerReviewStreamCursorInput>>
  where?: InputMaybe<ServerReviewBoolExp>
}

export type ServerSubscriptionRootRoleByIdArgs = {
  id: Scalars['String']
}

export type ServerSubscriptionRootRolePermissionArgs = {
  distinct_on?: InputMaybe<Array<ServerRolePermissionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerRolePermissionOrderBy>>
  where?: InputMaybe<ServerRolePermissionBoolExp>
}

export type ServerSubscriptionRootRolePermissionByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootRoleStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerRoleStreamCursorInput>>
  where?: InputMaybe<ServerRoleBoolExp>
}

export type ServerSubscriptionRootRolesArgs = {
  distinct_on?: InputMaybe<Array<ServerRoleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerRoleOrderBy>>
  where?: InputMaybe<ServerRoleBoolExp>
}

export type ServerSubscriptionRootSalaryIssueByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootSalaryIssuesArgs = {
  distinct_on?: InputMaybe<Array<ServerSalaryIssueSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSalaryIssueOrderBy>>
  where?: InputMaybe<ServerSalaryIssueBoolExp>
}

export type ServerSubscriptionRootSalarySettingByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootSalarySettingsArgs = {
  distinct_on?: InputMaybe<Array<ServerSalarySettingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSalarySettingOrderBy>>
  where?: InputMaybe<ServerSalarySettingBoolExp>
}

export type ServerSubscriptionRootSalarySettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerSalarySettingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSalarySettingOrderBy>>
  where?: InputMaybe<ServerSalarySettingBoolExp>
}

export type ServerSubscriptionRootScheduleByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootSchedulesArgs = {
  distinct_on?: InputMaybe<Array<ServerScheduleSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerScheduleOrderBy>>
  where?: InputMaybe<ServerScheduleBoolExp>
}

export type ServerSubscriptionRootServerStatusArgs = {
  distinct_on?: InputMaybe<Array<ServerServerStatusSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServerStatusOrderBy>>
  where?: InputMaybe<ServerServerStatusBoolExp>
}

export type ServerSubscriptionRootServerStatusByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootServerStatusStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerServerStatusStreamCursorInput>>
  where?: InputMaybe<ServerServerStatusBoolExp>
}

export type ServerSubscriptionRootServiceByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootServiceGroupByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootServiceGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerServiceGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServiceGroupOrderBy>>
  where?: InputMaybe<ServerServiceGroupBoolExp>
}

export type ServerSubscriptionRootServicesArgs = {
  distinct_on?: InputMaybe<Array<ServerServiceSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerServiceOrderBy>>
  where?: InputMaybe<ServerServiceBoolExp>
}

export type ServerSubscriptionRootSoftwarePosByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootSoftwarePoSsArgs = {
  distinct_on?: InputMaybe<Array<ServerSoftwarePosSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSoftwarePosOrderBy>>
  where?: InputMaybe<ServerSoftwarePosBoolExp>
}

export type ServerSubscriptionRootSoftwarePoSsStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerSoftwarePosStreamCursorInput>>
  where?: InputMaybe<ServerSoftwarePosBoolExp>
}

export type ServerSubscriptionRootStorageByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootStoragesArgs = {
  distinct_on?: InputMaybe<Array<ServerStorageSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerStorageOrderBy>>
  where?: InputMaybe<ServerStorageBoolExp>
}

export type ServerSubscriptionRootSubscriptionPeriodByIdArgs = {
  period: Scalars['String']
}

export type ServerSubscriptionRootSubscriptionPeriodsArgs = {
  distinct_on?: InputMaybe<Array<ServerSubscriptionPeriodSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSubscriptionPeriodOrderBy>>
  where?: InputMaybe<ServerSubscriptionPeriodBoolExp>
}

export type ServerSubscriptionRootSubscriptionPeriodsStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerSubscriptionPeriodStreamCursorInput>>
  where?: InputMaybe<ServerSubscriptionPeriodBoolExp>
}

export type ServerSubscriptionRootSuperByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootSupersArgs = {
  distinct_on?: InputMaybe<Array<ServerSuperSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSuperOrderBy>>
  where?: InputMaybe<ServerSuperBoolExp>
}

export type ServerSubscriptionRootSupersStreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<ServerSuperStreamCursorInput>>
  where?: InputMaybe<ServerSuperBoolExp>
}

export type ServerSubscriptionRootSupplierByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootSuppliersArgs = {
  distinct_on?: InputMaybe<Array<ServerSupplierSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSupplierOrderBy>>
  where?: InputMaybe<ServerSupplierBoolExp>
}

export type ServerSubscriptionRootTagByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootTagsArgs = {
  distinct_on?: InputMaybe<Array<ServerTagSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTagOrderBy>>
  where?: InputMaybe<ServerTagBoolExp>
}

export type ServerSubscriptionRootTimeOffByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootTimeOffReasonByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootTimeOffReasonsArgs = {
  distinct_on?: InputMaybe<Array<ServerTimeOffReasonSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTimeOffReasonOrderBy>>
  where?: InputMaybe<ServerTimeOffReasonBoolExp>
}

export type ServerSubscriptionRootTimeOffsArgs = {
  distinct_on?: InputMaybe<Array<ServerTimeOffSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTimeOffOrderBy>>
  where?: InputMaybe<ServerTimeOffBoolExp>
}

export type ServerSubscriptionRootTransactionByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootTransactionsArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

export type ServerSubscriptionRootTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

export type ServerSubscriptionRootUnitedBookingByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootUnitedBookingsArgs = {
  distinct_on?: InputMaybe<Array<ServerUnitedBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerUnitedBookingOrderBy>>
  where?: InputMaybe<ServerUnitedBookingBoolExp>
}

export type ServerSubscriptionRootUserExpoNotificationByEmailArgs = {
  email: Scalars['String']
}

export type ServerSubscriptionRootUserExpoNotificationsArgs = {
  distinct_on?: InputMaybe<Array<ServerUserExpoNotificationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerUserExpoNotificationOrderBy>>
  where?: InputMaybe<ServerUserExpoNotificationBoolExp>
}

export type ServerSubscriptionRootWaitingListByIdArgs = {
  id: Scalars['Int']
}

export type ServerSubscriptionRootWaitingListsArgs = {
  distinct_on?: InputMaybe<Array<ServerWaitingListSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerWaitingListOrderBy>>
  where?: InputMaybe<ServerWaitingListBoolExp>
}

/** columns and relationships of "super" */
export type ServerSuper = {
  canEdit: Scalars['Boolean']
  createdAt: Scalars['timestamptz']
  email: Scalars['String']
  enabled: Scalars['Boolean']
  firstName: Maybe<Scalars['String']>
  id: Scalars['Int']
  secondName: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "super". All fields are combined with a logical 'AND'. */
export type ServerSuperBoolExp = {
  _and?: InputMaybe<Array<ServerSuperBoolExp>>
  _not?: InputMaybe<ServerSuperBoolExp>
  _or?: InputMaybe<Array<ServerSuperBoolExp>>
  canEdit?: InputMaybe<ServerBooleanComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  email?: InputMaybe<ServerStringComparisonExp>
  enabled?: InputMaybe<ServerBooleanComparisonExp>
  firstName?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  secondName?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "super" */
export enum ServerSuperConstraint {
  /** unique or primary key constraint on columns "email" */
  SuperEmailKey = 'super_email_key',
  /** unique or primary key constraint on columns "id" */
  SuperPkey = 'super_pkey',
}

/** input type for inserting data into table "super" */
export type ServerSuperInsertInput = {
  canEdit?: InputMaybe<Scalars['Boolean']>
  email?: InputMaybe<Scalars['String']>
  enabled?: InputMaybe<Scalars['Boolean']>
  firstName?: InputMaybe<Scalars['String']>
  secondName?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "super" */
export type ServerSuperMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerSuper>
}

/** on_conflict condition type for table "super" */
export type ServerSuperOnConflict = {
  constraint: ServerSuperConstraint
  update_columns?: Array<ServerSuperUpdateColumn>
  where?: InputMaybe<ServerSuperBoolExp>
}

/** Ordering options when selecting data from "super". */
export type ServerSuperOrderBy = {
  canEdit?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  enabled?: InputMaybe<ServerOrderBy>
  firstName?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  secondName?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: super */
export type ServerSuperPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "super" */
export enum ServerSuperSelectColumn {
  /** column name */
  CanEdit = 'canEdit',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  SecondName = 'secondName',
}

/** input type for updating data in table "super" */
export type ServerSuperSetInput = {
  canEdit?: InputMaybe<Scalars['Boolean']>
  email?: InputMaybe<Scalars['String']>
  enabled?: InputMaybe<Scalars['Boolean']>
  firstName?: InputMaybe<Scalars['String']>
  secondName?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "super" */
export type ServerSuperStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServerSuperStreamCursorValueInput
  /** cursor ordering */
  ordering?: InputMaybe<ServerCursorOrdering>
}

/** Initial value of the column from where the streaming should start */
export type ServerSuperStreamCursorValueInput = {
  canEdit?: InputMaybe<Scalars['Boolean']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  enabled?: InputMaybe<Scalars['Boolean']>
  firstName?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  secondName?: InputMaybe<Scalars['String']>
}

/** update columns of table "super" */
export enum ServerSuperUpdateColumn {
  /** column name */
  CanEdit = 'canEdit',
  /** column name */
  Email = 'email',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  SecondName = 'secondName',
}

export type ServerSuperUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerSuperSetInput>
  /** filter the rows which have to be updated */
  where: ServerSuperBoolExp
}

/** Поставщики */
export type ServerSupplier = {
  /** An object relationship */
  address: Maybe<ServerAddress>
  addressId: Maybe<Scalars['Int']>
  archived: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  bankDetails: Maybe<ServerBankDetails>
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  createdAt: Scalars['timestamptz']
  email: Maybe<Scalars['String']>
  id: Scalars['Int']
  /** An array relationship */
  movements: Array<ServerMovement>
  /** An aggregate relationship */
  movements_aggregate: ServerMovementAggregate
  name: Scalars['String']
  note: Maybe<Scalars['String']>
  phone: Scalars['String']
  /** An array relationship */
  supplierContactFaces: Array<ServerHuman>
  webSite: Maybe<Scalars['String']>
}

/** Поставщики */
export type ServerSupplierMovementsArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementOrderBy>>
  where?: InputMaybe<ServerMovementBoolExp>
}

/** Поставщики */
export type ServerSupplierMovementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerMovementSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerMovementOrderBy>>
  where?: InputMaybe<ServerMovementBoolExp>
}

/** Поставщики */
export type ServerSupplierSupplierContactFacesArgs = {
  distinct_on?: InputMaybe<Array<ServerHumanSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerHumanOrderBy>>
  where?: InputMaybe<ServerHumanBoolExp>
}

/** Boolean expression to filter rows from the table "supplier". All fields are combined with a logical 'AND'. */
export type ServerSupplierBoolExp = {
  _and?: InputMaybe<Array<ServerSupplierBoolExp>>
  _not?: InputMaybe<ServerSupplierBoolExp>
  _or?: InputMaybe<Array<ServerSupplierBoolExp>>
  address?: InputMaybe<ServerAddressBoolExp>
  addressId?: InputMaybe<ServerIntComparisonExp>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  bankDetails?: InputMaybe<ServerBankDetailsBoolExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  email?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  movements?: InputMaybe<ServerMovementBoolExp>
  movements_aggregate?: InputMaybe<ServerMovementAggregateBoolExp>
  name?: InputMaybe<ServerStringComparisonExp>
  note?: InputMaybe<ServerStringComparisonExp>
  phone?: InputMaybe<ServerStringComparisonExp>
  supplierContactFaces?: InputMaybe<ServerHumanBoolExp>
  webSite?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "supplier" */
export enum ServerSupplierConstraint {
  /** unique or primary key constraint on columns "id" */
  SuppliersPkey = 'suppliers_pkey',
}

/** input type for incrementing numeric columns in table "supplier" */
export type ServerSupplierIncInput = {
  addressId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "supplier" */
export type ServerSupplierInsertInput = {
  address?: InputMaybe<ServerAddressObjRelInsertInput>
  addressId?: InputMaybe<Scalars['Int']>
  bankDetails?: InputMaybe<ServerBankDetailsObjRelInsertInput>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  email?: InputMaybe<Scalars['String']>
  movements?: InputMaybe<ServerMovementArrRelInsertInput>
  name?: InputMaybe<Scalars['String']>
  note?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  supplierContactFaces?: InputMaybe<ServerHumanArrRelInsertInput>
  webSite?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "supplier" */
export type ServerSupplierMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerSupplier>
}

/** input type for inserting object relation for remote table "supplier" */
export type ServerSupplierObjRelInsertInput = {
  data: ServerSupplierInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerSupplierOnConflict>
}

/** on_conflict condition type for table "supplier" */
export type ServerSupplierOnConflict = {
  constraint: ServerSupplierConstraint
  update_columns?: Array<ServerSupplierUpdateColumn>
  where?: InputMaybe<ServerSupplierBoolExp>
}

/** Ordering options when selecting data from "supplier". */
export type ServerSupplierOrderBy = {
  address?: InputMaybe<ServerAddressOrderBy>
  addressId?: InputMaybe<ServerOrderBy>
  archived?: InputMaybe<ServerOrderBy>
  bankDetails?: InputMaybe<ServerBankDetailsOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  email?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movements_aggregate?: InputMaybe<ServerMovementAggregateOrderBy>
  name?: InputMaybe<ServerOrderBy>
  note?: InputMaybe<ServerOrderBy>
  phone?: InputMaybe<ServerOrderBy>
  supplierContactFaces_aggregate?: InputMaybe<ServerHumanAggregateOrderBy>
  webSite?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: supplier */
export type ServerSupplierPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "supplier" */
export enum ServerSupplierSelectColumn {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  Archived = 'archived',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  Phone = 'phone',
  /** column name */
  WebSite = 'webSite',
}

/** input type for updating data in table "supplier" */
export type ServerSupplierSetInput = {
  addressId?: InputMaybe<Scalars['Int']>
  archived?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  note?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  webSite?: InputMaybe<Scalars['String']>
}

/** update columns of table "supplier" */
export enum ServerSupplierUpdateColumn {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  Archived = 'archived',
  /** column name */
  Email = 'email',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  Phone = 'phone',
  /** column name */
  WebSite = 'webSite',
}

export type ServerSupplierUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerSupplierIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerSupplierSetInput>
  /** filter the rows which have to be updated */
  where: ServerSupplierBoolExp
}

/** Виды тэгов для клиентов */
export type ServerTag = {
  archived: Maybe<Scalars['timestamptz']>
  /** An array relationship */
  clientTags: Array<ServerClientTag>
  color: Scalars['String']
  createdAt: Scalars['timestamptz']
  description: Maybe<Scalars['String']>
  id: Scalars['Int']
  name: Scalars['String']
}

/** Виды тэгов для клиентов */
export type ServerTagClientTagsArgs = {
  distinct_on?: InputMaybe<Array<ServerClientTagSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerClientTagOrderBy>>
  where?: InputMaybe<ServerClientTagBoolExp>
}

/** Boolean expression to filter rows from the table "tag". All fields are combined with a logical 'AND'. */
export type ServerTagBoolExp = {
  _and?: InputMaybe<Array<ServerTagBoolExp>>
  _not?: InputMaybe<ServerTagBoolExp>
  _or?: InputMaybe<Array<ServerTagBoolExp>>
  archived?: InputMaybe<ServerTimestamptzComparisonExp>
  clientTags?: InputMaybe<ServerClientTagBoolExp>
  color?: InputMaybe<ServerStringComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "tag" */
export enum ServerTagConstraint {
  /** unique or primary key constraint on columns "id" */
  TagsPkey = 'tags_pkey',
}

/** input type for inserting data into table "tag" */
export type ServerTagInsertInput = {
  clientTags?: InputMaybe<ServerClientTagArrRelInsertInput>
  color?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "tag" */
export type ServerTagMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerTag>
}

/** input type for inserting object relation for remote table "tag" */
export type ServerTagObjRelInsertInput = {
  data: ServerTagInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerTagOnConflict>
}

/** on_conflict condition type for table "tag" */
export type ServerTagOnConflict = {
  constraint: ServerTagConstraint
  update_columns?: Array<ServerTagUpdateColumn>
  where?: InputMaybe<ServerTagBoolExp>
}

/** Ordering options when selecting data from "tag". */
export type ServerTagOrderBy = {
  archived?: InputMaybe<ServerOrderBy>
  clientTags_aggregate?: InputMaybe<ServerClientTagAggregateOrderBy>
  color?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: tag */
export type ServerTagPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "tag" */
export enum ServerTagSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "tag" */
export type ServerTagSetInput = {
  archived?: InputMaybe<Scalars['timestamptz']>
  color?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

/** update columns of table "tag" */
export enum ServerTagUpdateColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  Color = 'color',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type ServerTagUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerTagSetInput>
  /** filter the rows which have to be updated */
  where: ServerTagBoolExp
}

/** Отгулы */
export type ServerTimeOff = {
  /** An object relationship */
  author: ServerEmployee
  authorId: Scalars['Int']
  canceledDate: Maybe<Scalars['timestamptz']>
  createdAt: Scalars['timestamptz']
  /** An object relationship */
  employee: ServerEmployee
  employeeId: Scalars['Int']
  end: Scalars['timestamptz']
  id: Scalars['Int']
  start: Scalars['timestamptz']
  /** An object relationship */
  timeOffReason: ServerTimeOffReason
  timeOffReasonId: Scalars['Int']
}

/** columns and relationships of "timeOffReason" */
export type ServerTimeOffReason = {
  id: Scalars['Int']
  name: Scalars['String']
  /** An array relationship */
  timeOffReasonEmployeeGroups: Array<ServerTimeOffReasonEmployeeGroup>
}

/** columns and relationships of "timeOffReason" */
export type ServerTimeOffReasonTimeOffReasonEmployeeGroupsArgs = {
  distinct_on?: InputMaybe<Array<ServerTimeOffReasonEmployeeGroupSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTimeOffReasonEmployeeGroupOrderBy>>
  where?: InputMaybe<ServerTimeOffReasonEmployeeGroupBoolExp>
}

/** Какие группы сотрудников, какие отгулы могут проставлять */
export type ServerTimeOffReasonEmployeeGroup = {
  /** An object relationship */
  employeeGroup: ServerEmployeeGroup
  employeeGroupId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  timeOffReason: ServerTimeOffReason
  timeOffReasonId: Scalars['Int']
}

/** order by aggregate values of table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupAggregateOrderBy = {
  avg?: InputMaybe<ServerTimeOffReasonEmployeeGroupAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerTimeOffReasonEmployeeGroupMaxOrderBy>
  min?: InputMaybe<ServerTimeOffReasonEmployeeGroupMinOrderBy>
  stddev?: InputMaybe<ServerTimeOffReasonEmployeeGroupStddevOrderBy>
  stddev_pop?: InputMaybe<ServerTimeOffReasonEmployeeGroupStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerTimeOffReasonEmployeeGroupStddevSampOrderBy>
  sum?: InputMaybe<ServerTimeOffReasonEmployeeGroupSumOrderBy>
  var_pop?: InputMaybe<ServerTimeOffReasonEmployeeGroupVarPopOrderBy>
  var_samp?: InputMaybe<ServerTimeOffReasonEmployeeGroupVarSampOrderBy>
  variance?: InputMaybe<ServerTimeOffReasonEmployeeGroupVarianceOrderBy>
}

/** input type for inserting array relation for remote table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupArrRelInsertInput = {
  data: Array<ServerTimeOffReasonEmployeeGroupInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerTimeOffReasonEmployeeGroupOnConflict>
}

/** order by avg() on columns of table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupAvgOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "timeOffReasonEmployeeGroup". All fields are combined with a logical 'AND'. */
export type ServerTimeOffReasonEmployeeGroupBoolExp = {
  _and?: InputMaybe<Array<ServerTimeOffReasonEmployeeGroupBoolExp>>
  _not?: InputMaybe<ServerTimeOffReasonEmployeeGroupBoolExp>
  _or?: InputMaybe<Array<ServerTimeOffReasonEmployeeGroupBoolExp>>
  employeeGroup?: InputMaybe<ServerEmployeeGroupBoolExp>
  employeeGroupId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  timeOffReason?: InputMaybe<ServerTimeOffReasonBoolExp>
  timeOffReasonId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "timeOffReasonEmployeeGroup" */
export enum ServerTimeOffReasonEmployeeGroupConstraint {
  /** unique or primary key constraint on columns "id" */
  TimeOffReasonEmployeeGroupPkey = 'timeOffReasonEmployeeGroup_pkey',
}

/** input type for inserting data into table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupInsertInput = {
  employeeGroup?: InputMaybe<ServerEmployeeGroupObjRelInsertInput>
  employeeGroupId?: InputMaybe<Scalars['Int']>
  timeOffReason?: InputMaybe<ServerTimeOffReasonObjRelInsertInput>
  timeOffReasonId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupMaxOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupMinOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerTimeOffReasonEmployeeGroup>
}

/** on_conflict condition type for table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupOnConflict = {
  constraint: ServerTimeOffReasonEmployeeGroupConstraint
  update_columns?: Array<ServerTimeOffReasonEmployeeGroupUpdateColumn>
  where?: InputMaybe<ServerTimeOffReasonEmployeeGroupBoolExp>
}

/** Ordering options when selecting data from "timeOffReasonEmployeeGroup". */
export type ServerTimeOffReasonEmployeeGroupOrderBy = {
  employeeGroup?: InputMaybe<ServerEmployeeGroupOrderBy>
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReason?: InputMaybe<ServerTimeOffReasonOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "timeOffReasonEmployeeGroup" */
export enum ServerTimeOffReasonEmployeeGroupSelectColumn {
  /** column name */
  EmployeeGroupId = 'employeeGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  TimeOffReasonId = 'timeOffReasonId',
}

/** order by stddev() on columns of table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupStddevOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupStddevPopOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupStddevSampOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupSumOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "timeOffReasonEmployeeGroup" (current role has no relevant permissions) */
export enum ServerTimeOffReasonEmployeeGroupUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupVarPopOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupVarSampOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "timeOffReasonEmployeeGroup" */
export type ServerTimeOffReasonEmployeeGroupVarianceOrderBy = {
  employeeGroupId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "timeOffReason". All fields are combined with a logical 'AND'. */
export type ServerTimeOffReasonBoolExp = {
  _and?: InputMaybe<Array<ServerTimeOffReasonBoolExp>>
  _not?: InputMaybe<ServerTimeOffReasonBoolExp>
  _or?: InputMaybe<Array<ServerTimeOffReasonBoolExp>>
  id?: InputMaybe<ServerIntComparisonExp>
  name?: InputMaybe<ServerStringComparisonExp>
  timeOffReasonEmployeeGroups?: InputMaybe<ServerTimeOffReasonEmployeeGroupBoolExp>
}

/** unique or primary key constraints on table "timeOffReason" */
export enum ServerTimeOffReasonConstraint {
  /** unique or primary key constraint on columns "id" */
  TimeOffReasonPkey = 'timeOffReason_pkey',
}

/** input type for inserting data into table "timeOffReason" */
export type ServerTimeOffReasonInsertInput = {
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  timeOffReasonEmployeeGroups?: InputMaybe<ServerTimeOffReasonEmployeeGroupArrRelInsertInput>
}

/** response of any mutation on the table "timeOffReason" */
export type ServerTimeOffReasonMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerTimeOffReason>
}

/** input type for inserting object relation for remote table "timeOffReason" */
export type ServerTimeOffReasonObjRelInsertInput = {
  data: ServerTimeOffReasonInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerTimeOffReasonOnConflict>
}

/** on_conflict condition type for table "timeOffReason" */
export type ServerTimeOffReasonOnConflict = {
  constraint: ServerTimeOffReasonConstraint
  update_columns?: Array<ServerTimeOffReasonUpdateColumn>
  where?: InputMaybe<ServerTimeOffReasonBoolExp>
}

/** Ordering options when selecting data from "timeOffReason". */
export type ServerTimeOffReasonOrderBy = {
  id?: InputMaybe<ServerOrderBy>
  name?: InputMaybe<ServerOrderBy>
  timeOffReasonEmployeeGroups_aggregate?: InputMaybe<ServerTimeOffReasonEmployeeGroupAggregateOrderBy>
}

/** primary key columns input for table: timeOffReason */
export type ServerTimeOffReasonPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "timeOffReason" */
export enum ServerTimeOffReasonSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "timeOffReason" */
export type ServerTimeOffReasonSetInput = {
  name?: InputMaybe<Scalars['String']>
}

/** update columns of table "timeOffReason" */
export enum ServerTimeOffReasonUpdateColumn {
  /** column name */
  Name = 'name',
}

export type ServerTimeOffReasonUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerTimeOffReasonSetInput>
  /** filter the rows which have to be updated */
  where: ServerTimeOffReasonBoolExp
}

/** order by aggregate values of table "timeOff" */
export type ServerTimeOffAggregateOrderBy = {
  avg?: InputMaybe<ServerTimeOffAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerTimeOffMaxOrderBy>
  min?: InputMaybe<ServerTimeOffMinOrderBy>
  stddev?: InputMaybe<ServerTimeOffStddevOrderBy>
  stddev_pop?: InputMaybe<ServerTimeOffStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerTimeOffStddevSampOrderBy>
  sum?: InputMaybe<ServerTimeOffSumOrderBy>
  var_pop?: InputMaybe<ServerTimeOffVarPopOrderBy>
  var_samp?: InputMaybe<ServerTimeOffVarSampOrderBy>
  variance?: InputMaybe<ServerTimeOffVarianceOrderBy>
}

/** input type for inserting array relation for remote table "timeOff" */
export type ServerTimeOffArrRelInsertInput = {
  data: Array<ServerTimeOffInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerTimeOffOnConflict>
}

/** order by avg() on columns of table "timeOff" */
export type ServerTimeOffAvgOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "timeOff". All fields are combined with a logical 'AND'. */
export type ServerTimeOffBoolExp = {
  _and?: InputMaybe<Array<ServerTimeOffBoolExp>>
  _not?: InputMaybe<ServerTimeOffBoolExp>
  _or?: InputMaybe<Array<ServerTimeOffBoolExp>>
  author?: InputMaybe<ServerEmployeeBoolExp>
  authorId?: InputMaybe<ServerIntComparisonExp>
  canceledDate?: InputMaybe<ServerTimestamptzComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  end?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  start?: InputMaybe<ServerTimestamptzComparisonExp>
  timeOffReason?: InputMaybe<ServerTimeOffReasonBoolExp>
  timeOffReasonId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "timeOff" */
export enum ServerTimeOffConstraint {
  /** unique or primary key constraint on columns "id" */
  TimeoffsPkey = 'timeoffs_pkey',
}

/** input type for incrementing numeric columns in table "timeOff" */
export type ServerTimeOffIncInput = {
  employeeId?: InputMaybe<Scalars['Int']>
  timeOffReasonId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "timeOff" */
export type ServerTimeOffInsertInput = {
  author?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employeeId?: InputMaybe<Scalars['Int']>
  end?: InputMaybe<Scalars['timestamptz']>
  start?: InputMaybe<Scalars['timestamptz']>
  timeOffReason?: InputMaybe<ServerTimeOffReasonObjRelInsertInput>
  timeOffReasonId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "timeOff" */
export type ServerTimeOffMaxOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  canceledDate?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  end?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  start?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "timeOff" */
export type ServerTimeOffMinOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  canceledDate?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  end?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  start?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "timeOff" */
export type ServerTimeOffMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerTimeOff>
}

/** on_conflict condition type for table "timeOff" */
export type ServerTimeOffOnConflict = {
  constraint: ServerTimeOffConstraint
  update_columns?: Array<ServerTimeOffUpdateColumn>
  where?: InputMaybe<ServerTimeOffBoolExp>
}

/** Ordering options when selecting data from "timeOff". */
export type ServerTimeOffOrderBy = {
  author?: InputMaybe<ServerEmployeeOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  canceledDate?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  end?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  start?: InputMaybe<ServerOrderBy>
  timeOffReason?: InputMaybe<ServerTimeOffReasonOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: timeOff */
export type ServerTimeOffPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "timeOff" */
export enum ServerTimeOffSelectColumn {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  CanceledDate = 'canceledDate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  Start = 'start',
  /** column name */
  TimeOffReasonId = 'timeOffReasonId',
}

/** input type for updating data in table "timeOff" */
export type ServerTimeOffSetInput = {
  canceledDate?: InputMaybe<Scalars['timestamptz']>
  employeeId?: InputMaybe<Scalars['Int']>
  end?: InputMaybe<Scalars['timestamptz']>
  start?: InputMaybe<Scalars['timestamptz']>
  timeOffReasonId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "timeOff" */
export type ServerTimeOffStddevOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "timeOff" */
export type ServerTimeOffStddevPopOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "timeOff" */
export type ServerTimeOffStddevSampOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "timeOff" */
export type ServerTimeOffSumOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "timeOff" */
export enum ServerTimeOffUpdateColumn {
  /** column name */
  CanceledDate = 'canceledDate',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  End = 'end',
  /** column name */
  Start = 'start',
  /** column name */
  TimeOffReasonId = 'timeOffReasonId',
}

export type ServerTimeOffUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerTimeOffIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerTimeOffSetInput>
  /** filter the rows which have to be updated */
  where: ServerTimeOffBoolExp
}

/** order by var_pop() on columns of table "timeOff" */
export type ServerTimeOffVarPopOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "timeOff" */
export type ServerTimeOffVarSampOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "timeOff" */
export type ServerTimeOffVarianceOrderBy = {
  authorId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  timeOffReasonId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type ServerTimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

/** Транзакции по счетам */
export type ServerTransaction = {
  amount: Scalars['numeric']
  /** An object relationship */
  author: Maybe<ServerEmployee>
  authorId: Maybe<Scalars['Int']>
  /** An object relationship */
  booking: Maybe<ServerBooking>
  bookingId: Maybe<Scalars['Int']>
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  cardId: Maybe<Scalars['Int']>
  /** An array relationship */
  childTransactions: Array<ServerTransaction>
  /** An aggregate relationship */
  childTransactions_aggregate: ServerTransactionAggregate
  /** An object relationship */
  client: Maybe<ServerClient>
  /** An object relationship */
  clientCard: Maybe<ServerCard>
  clientId: Maybe<Scalars['Int']>
  comment: Maybe<Scalars['String']>
  /** Дата фактического создания. Используется только для определения транзакций не за фактическую дату. */
  createdAt: Scalars['timestamptz']
  /** Дата транзакции. Используется для всех подсчётов. */
  date: Scalars['timestamptz']
  /** An object relationship */
  employee: Maybe<ServerEmployee>
  employeeId: Maybe<Scalars['Int']>
  endPeriod: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  expensesReason: Maybe<ServerExpensesReason>
  expensesReasonId: Maybe<Scalars['Int']>
  /** An object relationship */
  fromAccount: Maybe<ServerAccount>
  fromAccountId: Maybe<Scalars['Int']>
  id: Scalars['Int']
  /** An object relationship */
  movement: Maybe<ServerMovement>
  movementId: Maybe<Scalars['Int']>
  parentId: Maybe<Scalars['Int']>
  /** An object relationship */
  parentTransaction: Maybe<ServerTransaction>
  /** An object relationship */
  revenueReason: Maybe<ServerRevenueReason>
  revenueReasonId: Maybe<Scalars['Int']>
  /** An array relationship */
  salaryIssues: Array<ServerSalaryIssue>
  startPeriod: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  toAccount: Maybe<ServerAccount>
  toAccountId: Maybe<Scalars['Int']>
  /** An array relationship */
  transactionReceipts: Array<ServerTransactionReceipt>
  /** An array relationship */
  transactionsCards: Array<ServerTransactionCard>
  /** An array relationship */
  transactionsServices: Array<ServerTransactionService>
  type: Scalars['smallint']
  typeVariation: Maybe<Scalars['smallint']>
}

/** Транзакции по счетам */
export type ServerTransactionChildTransactionsArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

/** Транзакции по счетам */
export type ServerTransactionChildTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionOrderBy>>
  where?: InputMaybe<ServerTransactionBoolExp>
}

/** Транзакции по счетам */
export type ServerTransactionSalaryIssuesArgs = {
  distinct_on?: InputMaybe<Array<ServerSalaryIssueSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerSalaryIssueOrderBy>>
  where?: InputMaybe<ServerSalaryIssueBoolExp>
}

/** Транзакции по счетам */
export type ServerTransactionTransactionReceiptsArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionReceiptSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionReceiptOrderBy>>
  where?: InputMaybe<ServerTransactionReceiptBoolExp>
}

/** Транзакции по счетам */
export type ServerTransactionTransactionsCardsArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionCardSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionCardOrderBy>>
  where?: InputMaybe<ServerTransactionCardBoolExp>
}

/** Транзакции по счетам */
export type ServerTransactionTransactionsServicesArgs = {
  distinct_on?: InputMaybe<Array<ServerTransactionServiceSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerTransactionServiceOrderBy>>
  where?: InputMaybe<ServerTransactionServiceBoolExp>
}

/** columns and relationships of "transactionCard" */
export type ServerTransactionCard = {
  /** An object relationship */
  card: ServerCard
  cardId: Scalars['Int']
  discount: Maybe<Scalars['numeric']>
  id: Scalars['Int']
  price: Scalars['numeric']
  /** An object relationship */
  transaction: ServerTransaction
  transactionId: Scalars['Int']
}

/** order by aggregate values of table "transactionCard" */
export type ServerTransactionCardAggregateOrderBy = {
  avg?: InputMaybe<ServerTransactionCardAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerTransactionCardMaxOrderBy>
  min?: InputMaybe<ServerTransactionCardMinOrderBy>
  stddev?: InputMaybe<ServerTransactionCardStddevOrderBy>
  stddev_pop?: InputMaybe<ServerTransactionCardStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerTransactionCardStddevSampOrderBy>
  sum?: InputMaybe<ServerTransactionCardSumOrderBy>
  var_pop?: InputMaybe<ServerTransactionCardVarPopOrderBy>
  var_samp?: InputMaybe<ServerTransactionCardVarSampOrderBy>
  variance?: InputMaybe<ServerTransactionCardVarianceOrderBy>
}

/** input type for inserting array relation for remote table "transactionCard" */
export type ServerTransactionCardArrRelInsertInput = {
  data: Array<ServerTransactionCardInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerTransactionCardOnConflict>
}

/** order by avg() on columns of table "transactionCard" */
export type ServerTransactionCardAvgOrderBy = {
  cardId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "transactionCard". All fields are combined with a logical 'AND'. */
export type ServerTransactionCardBoolExp = {
  _and?: InputMaybe<Array<ServerTransactionCardBoolExp>>
  _not?: InputMaybe<ServerTransactionCardBoolExp>
  _or?: InputMaybe<Array<ServerTransactionCardBoolExp>>
  card?: InputMaybe<ServerCardBoolExp>
  cardId?: InputMaybe<ServerIntComparisonExp>
  discount?: InputMaybe<ServerNumericComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  price?: InputMaybe<ServerNumericComparisonExp>
  transaction?: InputMaybe<ServerTransactionBoolExp>
  transactionId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "transactionCard" */
export enum ServerTransactionCardConstraint {
  /** unique or primary key constraint on columns "id" */
  TransactionsSubscriptionsPkey = 'transactionsSubscriptions_pkey',
}

/** input type for incrementing numeric columns in table "transactionCard" */
export type ServerTransactionCardIncInput = {
  cardId?: InputMaybe<Scalars['Int']>
  discount?: InputMaybe<Scalars['numeric']>
  price?: InputMaybe<Scalars['numeric']>
  transactionId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "transactionCard" */
export type ServerTransactionCardInsertInput = {
  card?: InputMaybe<ServerCardObjRelInsertInput>
  cardId?: InputMaybe<Scalars['Int']>
  discount?: InputMaybe<Scalars['numeric']>
  price?: InputMaybe<Scalars['numeric']>
  transaction?: InputMaybe<ServerTransactionObjRelInsertInput>
  transactionId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "transactionCard" */
export type ServerTransactionCardMaxOrderBy = {
  cardId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "transactionCard" */
export type ServerTransactionCardMinOrderBy = {
  cardId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "transactionCard" */
export type ServerTransactionCardMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerTransactionCard>
}

/** on_conflict condition type for table "transactionCard" */
export type ServerTransactionCardOnConflict = {
  constraint: ServerTransactionCardConstraint
  update_columns?: Array<ServerTransactionCardUpdateColumn>
  where?: InputMaybe<ServerTransactionCardBoolExp>
}

/** Ordering options when selecting data from "transactionCard". */
export type ServerTransactionCardOrderBy = {
  card?: InputMaybe<ServerCardOrderBy>
  cardId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  transaction?: InputMaybe<ServerTransactionOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: transactionCard */
export type ServerTransactionCardPkColumnsInput = {
  id: Scalars['Int']
}

/** select columns of table "transactionCard" */
export enum ServerTransactionCardSelectColumn {
  /** column name */
  CardId = 'cardId',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  TransactionId = 'transactionId',
}

/** input type for updating data in table "transactionCard" */
export type ServerTransactionCardSetInput = {
  cardId?: InputMaybe<Scalars['Int']>
  discount?: InputMaybe<Scalars['numeric']>
  price?: InputMaybe<Scalars['numeric']>
  transactionId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "transactionCard" */
export type ServerTransactionCardStddevOrderBy = {
  cardId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "transactionCard" */
export type ServerTransactionCardStddevPopOrderBy = {
  cardId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "transactionCard" */
export type ServerTransactionCardStddevSampOrderBy = {
  cardId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "transactionCard" */
export type ServerTransactionCardSumOrderBy = {
  cardId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "transactionCard" */
export enum ServerTransactionCardUpdateColumn {
  /** column name */
  CardId = 'cardId',
  /** column name */
  Discount = 'discount',
  /** column name */
  Price = 'price',
  /** column name */
  TransactionId = 'transactionId',
}

export type ServerTransactionCardUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerTransactionCardIncInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerTransactionCardSetInput>
  /** filter the rows which have to be updated */
  where: ServerTransactionCardBoolExp
}

/** order by var_pop() on columns of table "transactionCard" */
export type ServerTransactionCardVarPopOrderBy = {
  cardId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "transactionCard" */
export type ServerTransactionCardVarSampOrderBy = {
  cardId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "transactionCard" */
export type ServerTransactionCardVarianceOrderBy = {
  cardId?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "transactionReceipt" */
export type ServerTransactionReceipt = {
  /** An object relationship */
  business: ServerBusiness
  businessId: Scalars['Int']
  id: Scalars['Int']
  receiptId: Scalars['String']
  /** An object relationship */
  softwarePOS: ServerSoftwarePos
  softwarePOSId: Scalars['Int']
  /** An object relationship */
  transaction: ServerTransaction
  transactionId: Scalars['Int']
}

/** order by aggregate values of table "transactionReceipt" */
export type ServerTransactionReceiptAggregateOrderBy = {
  avg?: InputMaybe<ServerTransactionReceiptAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerTransactionReceiptMaxOrderBy>
  min?: InputMaybe<ServerTransactionReceiptMinOrderBy>
  stddev?: InputMaybe<ServerTransactionReceiptStddevOrderBy>
  stddev_pop?: InputMaybe<ServerTransactionReceiptStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerTransactionReceiptStddevSampOrderBy>
  sum?: InputMaybe<ServerTransactionReceiptSumOrderBy>
  var_pop?: InputMaybe<ServerTransactionReceiptVarPopOrderBy>
  var_samp?: InputMaybe<ServerTransactionReceiptVarSampOrderBy>
  variance?: InputMaybe<ServerTransactionReceiptVarianceOrderBy>
}

/** input type for inserting array relation for remote table "transactionReceipt" */
export type ServerTransactionReceiptArrRelInsertInput = {
  data: Array<ServerTransactionReceiptInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerTransactionReceiptOnConflict>
}

/** order by avg() on columns of table "transactionReceipt" */
export type ServerTransactionReceiptAvgOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "transactionReceipt". All fields are combined with a logical 'AND'. */
export type ServerTransactionReceiptBoolExp = {
  _and?: InputMaybe<Array<ServerTransactionReceiptBoolExp>>
  _not?: InputMaybe<ServerTransactionReceiptBoolExp>
  _or?: InputMaybe<Array<ServerTransactionReceiptBoolExp>>
  business?: InputMaybe<ServerBusinessBoolExp>
  businessId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  receiptId?: InputMaybe<ServerStringComparisonExp>
  softwarePOS?: InputMaybe<ServerSoftwarePosBoolExp>
  softwarePOSId?: InputMaybe<ServerIntComparisonExp>
  transaction?: InputMaybe<ServerTransactionBoolExp>
  transactionId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "transactionReceipt" */
export enum ServerTransactionReceiptConstraint {
  /** unique or primary key constraint on columns "id" */
  TransactionReceiptPkey = 'transactionReceipt_pkey',
}

/** input type for inserting data into table "transactionReceipt" */
export type ServerTransactionReceiptInsertInput = {
  receiptId?: InputMaybe<Scalars['String']>
  softwarePOSId?: InputMaybe<Scalars['Int']>
  transaction?: InputMaybe<ServerTransactionObjRelInsertInput>
  transactionId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "transactionReceipt" */
export type ServerTransactionReceiptMaxOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  receiptId?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "transactionReceipt" */
export type ServerTransactionReceiptMinOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  receiptId?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "transactionReceipt" */
export type ServerTransactionReceiptMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerTransactionReceipt>
}

/** on_conflict condition type for table "transactionReceipt" */
export type ServerTransactionReceiptOnConflict = {
  constraint: ServerTransactionReceiptConstraint
  update_columns?: Array<ServerTransactionReceiptUpdateColumn>
  where?: InputMaybe<ServerTransactionReceiptBoolExp>
}

/** Ordering options when selecting data from "transactionReceipt". */
export type ServerTransactionReceiptOrderBy = {
  business?: InputMaybe<ServerBusinessOrderBy>
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  receiptId?: InputMaybe<ServerOrderBy>
  softwarePOS?: InputMaybe<ServerSoftwarePosOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
  transaction?: InputMaybe<ServerTransactionOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "transactionReceipt" */
export enum ServerTransactionReceiptSelectColumn {
  /** column name */
  BusinessId = 'businessId',
  /** column name */
  Id = 'id',
  /** column name */
  ReceiptId = 'receiptId',
  /** column name */
  SoftwarePosId = 'softwarePOSId',
  /** column name */
  TransactionId = 'transactionId',
}

/** order by stddev() on columns of table "transactionReceipt" */
export type ServerTransactionReceiptStddevOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "transactionReceipt" */
export type ServerTransactionReceiptStddevPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "transactionReceipt" */
export type ServerTransactionReceiptStddevSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "transactionReceipt" */
export type ServerTransactionReceiptSumOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "transactionReceipt" (current role has no relevant permissions) */
export enum ServerTransactionReceiptUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "transactionReceipt" */
export type ServerTransactionReceiptVarPopOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "transactionReceipt" */
export type ServerTransactionReceiptVarSampOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "transactionReceipt" */
export type ServerTransactionReceiptVarianceOrderBy = {
  businessId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  softwarePOSId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "transactionService" */
export type ServerTransactionService = {
  costPrice: Scalars['numeric']
  discount: Maybe<Scalars['numeric']>
  id: Scalars['Int']
  price: Scalars['numeric']
  /** An object relationship */
  service: ServerService
  serviceId: Scalars['Int']
  /** An object relationship */
  transaction: ServerTransaction
  transactionId: Scalars['Int']
}

/** order by aggregate values of table "transactionService" */
export type ServerTransactionServiceAggregateOrderBy = {
  avg?: InputMaybe<ServerTransactionServiceAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerTransactionServiceMaxOrderBy>
  min?: InputMaybe<ServerTransactionServiceMinOrderBy>
  stddev?: InputMaybe<ServerTransactionServiceStddevOrderBy>
  stddev_pop?: InputMaybe<ServerTransactionServiceStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerTransactionServiceStddevSampOrderBy>
  sum?: InputMaybe<ServerTransactionServiceSumOrderBy>
  var_pop?: InputMaybe<ServerTransactionServiceVarPopOrderBy>
  var_samp?: InputMaybe<ServerTransactionServiceVarSampOrderBy>
  variance?: InputMaybe<ServerTransactionServiceVarianceOrderBy>
}

/** input type for inserting array relation for remote table "transactionService" */
export type ServerTransactionServiceArrRelInsertInput = {
  data: Array<ServerTransactionServiceInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerTransactionServiceOnConflict>
}

/** order by avg() on columns of table "transactionService" */
export type ServerTransactionServiceAvgOrderBy = {
  costPrice?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "transactionService". All fields are combined with a logical 'AND'. */
export type ServerTransactionServiceBoolExp = {
  _and?: InputMaybe<Array<ServerTransactionServiceBoolExp>>
  _not?: InputMaybe<ServerTransactionServiceBoolExp>
  _or?: InputMaybe<Array<ServerTransactionServiceBoolExp>>
  costPrice?: InputMaybe<ServerNumericComparisonExp>
  discount?: InputMaybe<ServerNumericComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  price?: InputMaybe<ServerNumericComparisonExp>
  service?: InputMaybe<ServerServiceBoolExp>
  serviceId?: InputMaybe<ServerIntComparisonExp>
  transaction?: InputMaybe<ServerTransactionBoolExp>
  transactionId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "transactionService" */
export enum ServerTransactionServiceConstraint {
  /** unique or primary key constraint on columns "id" */
  TransactionsServicesPkey = 'transactionsServices_pkey',
}

/** input type for inserting data into table "transactionService" */
export type ServerTransactionServiceInsertInput = {
  costPrice?: InputMaybe<Scalars['numeric']>
  discount?: InputMaybe<Scalars['numeric']>
  price?: InputMaybe<Scalars['numeric']>
  service?: InputMaybe<ServerServiceObjRelInsertInput>
  serviceId?: InputMaybe<Scalars['Int']>
  transaction?: InputMaybe<ServerTransactionObjRelInsertInput>
  transactionId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "transactionService" */
export type ServerTransactionServiceMaxOrderBy = {
  costPrice?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "transactionService" */
export type ServerTransactionServiceMinOrderBy = {
  costPrice?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "transactionService" */
export type ServerTransactionServiceMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerTransactionService>
}

/** input type for inserting object relation for remote table "transactionService" */
export type ServerTransactionServiceObjRelInsertInput = {
  data: ServerTransactionServiceInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerTransactionServiceOnConflict>
}

/** on_conflict condition type for table "transactionService" */
export type ServerTransactionServiceOnConflict = {
  constraint: ServerTransactionServiceConstraint
  update_columns?: Array<ServerTransactionServiceUpdateColumn>
  where?: InputMaybe<ServerTransactionServiceBoolExp>
}

/** Ordering options when selecting data from "transactionService". */
export type ServerTransactionServiceOrderBy = {
  costPrice?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  service?: InputMaybe<ServerServiceOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  transaction?: InputMaybe<ServerTransactionOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "transactionService" */
export enum ServerTransactionServiceSelectColumn {
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  TransactionId = 'transactionId',
}

/** order by stddev() on columns of table "transactionService" */
export type ServerTransactionServiceStddevOrderBy = {
  costPrice?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "transactionService" */
export type ServerTransactionServiceStddevPopOrderBy = {
  costPrice?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "transactionService" */
export type ServerTransactionServiceStddevSampOrderBy = {
  costPrice?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "transactionService" */
export type ServerTransactionServiceSumOrderBy = {
  costPrice?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "transactionService" (current role has no relevant permissions) */
export enum ServerTransactionServiceUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "transactionService" */
export type ServerTransactionServiceVarPopOrderBy = {
  costPrice?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "transactionService" */
export type ServerTransactionServiceVarSampOrderBy = {
  costPrice?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "transactionService" */
export type ServerTransactionServiceVarianceOrderBy = {
  costPrice?: InputMaybe<ServerOrderBy>
  discount?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  price?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
  transactionId?: InputMaybe<ServerOrderBy>
}

/** aggregated selection of "transaction" */
export type ServerTransactionAggregate = {
  aggregate: Maybe<ServerTransactionAggregateFields>
  nodes: Array<ServerTransaction>
}

export type ServerTransactionAggregateBoolExp = {
  count?: InputMaybe<ServerTransactionAggregateBoolExpCount>
}

export type ServerTransactionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServerTransactionSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<ServerTransactionBoolExp>
  predicate: ServerIntComparisonExp
}

/** aggregate fields of "transaction" */
export type ServerTransactionAggregateFields = {
  avg: Maybe<ServerTransactionAvgFields>
  count: Scalars['Int']
  max: Maybe<ServerTransactionMaxFields>
  min: Maybe<ServerTransactionMinFields>
  stddev: Maybe<ServerTransactionStddevFields>
  stddev_pop: Maybe<ServerTransactionStddevPopFields>
  stddev_samp: Maybe<ServerTransactionStddevSampFields>
  sum: Maybe<ServerTransactionSumFields>
  var_pop: Maybe<ServerTransactionVarPopFields>
  var_samp: Maybe<ServerTransactionVarSampFields>
  variance: Maybe<ServerTransactionVarianceFields>
}

/** aggregate fields of "transaction" */
export type ServerTransactionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerTransactionSelectColumn>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "transaction" */
export type ServerTransactionAggregateOrderBy = {
  avg?: InputMaybe<ServerTransactionAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerTransactionMaxOrderBy>
  min?: InputMaybe<ServerTransactionMinOrderBy>
  stddev?: InputMaybe<ServerTransactionStddevOrderBy>
  stddev_pop?: InputMaybe<ServerTransactionStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerTransactionStddevSampOrderBy>
  sum?: InputMaybe<ServerTransactionSumOrderBy>
  var_pop?: InputMaybe<ServerTransactionVarPopOrderBy>
  var_samp?: InputMaybe<ServerTransactionVarSampOrderBy>
  variance?: InputMaybe<ServerTransactionVarianceOrderBy>
}

/** input type for inserting array relation for remote table "transaction" */
export type ServerTransactionArrRelInsertInput = {
  data: Array<ServerTransactionInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerTransactionOnConflict>
}

/** aggregate avg on columns */
export type ServerTransactionAvgFields = {
  amount: Maybe<Scalars['Float']>
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  cardId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  expensesReasonId: Maybe<Scalars['Float']>
  fromAccountId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  parentId: Maybe<Scalars['Float']>
  revenueReasonId: Maybe<Scalars['Float']>
  toAccountId: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
  typeVariation: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "transaction" */
export type ServerTransactionAvgOrderBy = {
  amount?: InputMaybe<ServerOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cardId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  expensesReasonId?: InputMaybe<ServerOrderBy>
  fromAccountId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  revenueReasonId?: InputMaybe<ServerOrderBy>
  toAccountId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  typeVariation?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "transaction". All fields are combined with a logical 'AND'. */
export type ServerTransactionBoolExp = {
  _and?: InputMaybe<Array<ServerTransactionBoolExp>>
  _not?: InputMaybe<ServerTransactionBoolExp>
  _or?: InputMaybe<Array<ServerTransactionBoolExp>>
  amount?: InputMaybe<ServerNumericComparisonExp>
  author?: InputMaybe<ServerEmployeeBoolExp>
  authorId?: InputMaybe<ServerIntComparisonExp>
  booking?: InputMaybe<ServerBookingBoolExp>
  bookingId?: InputMaybe<ServerIntComparisonExp>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  cardId?: InputMaybe<ServerIntComparisonExp>
  childTransactions?: InputMaybe<ServerTransactionBoolExp>
  childTransactions_aggregate?: InputMaybe<ServerTransactionAggregateBoolExp>
  client?: InputMaybe<ServerClientBoolExp>
  clientCard?: InputMaybe<ServerCardBoolExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  comment?: InputMaybe<ServerStringComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  date?: InputMaybe<ServerTimestamptzComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  endPeriod?: InputMaybe<ServerTimestamptzComparisonExp>
  expensesReason?: InputMaybe<ServerExpensesReasonBoolExp>
  expensesReasonId?: InputMaybe<ServerIntComparisonExp>
  fromAccount?: InputMaybe<ServerAccountBoolExp>
  fromAccountId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  movement?: InputMaybe<ServerMovementBoolExp>
  movementId?: InputMaybe<ServerIntComparisonExp>
  parentId?: InputMaybe<ServerIntComparisonExp>
  parentTransaction?: InputMaybe<ServerTransactionBoolExp>
  revenueReason?: InputMaybe<ServerRevenueReasonBoolExp>
  revenueReasonId?: InputMaybe<ServerIntComparisonExp>
  salaryIssues?: InputMaybe<ServerSalaryIssueBoolExp>
  startPeriod?: InputMaybe<ServerTimestamptzComparisonExp>
  toAccount?: InputMaybe<ServerAccountBoolExp>
  toAccountId?: InputMaybe<ServerIntComparisonExp>
  transactionReceipts?: InputMaybe<ServerTransactionReceiptBoolExp>
  transactionsCards?: InputMaybe<ServerTransactionCardBoolExp>
  transactionsServices?: InputMaybe<ServerTransactionServiceBoolExp>
  type?: InputMaybe<ServerSmallintComparisonExp>
  typeVariation?: InputMaybe<ServerSmallintComparisonExp>
}

/** unique or primary key constraints on table "transaction" */
export enum ServerTransactionConstraint {
  /** unique or primary key constraint on columns "id" */
  TransactionsPkey = 'transactions_pkey',
}

/** input type for inserting data into table "transaction" */
export type ServerTransactionInsertInput = {
  amount?: InputMaybe<Scalars['numeric']>
  author?: InputMaybe<ServerEmployeeObjRelInsertInput>
  booking?: InputMaybe<ServerBookingObjRelInsertInput>
  bookingId?: InputMaybe<Scalars['Int']>
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  cardId?: InputMaybe<Scalars['Int']>
  childTransactions?: InputMaybe<ServerTransactionArrRelInsertInput>
  client?: InputMaybe<ServerClientObjRelInsertInput>
  clientCard?: InputMaybe<ServerCardObjRelInsertInput>
  clientId?: InputMaybe<Scalars['Int']>
  comment?: InputMaybe<Scalars['String']>
  /** Дата транзакции. Используется для всех подсчётов. */
  date?: InputMaybe<Scalars['timestamptz']>
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employeeId?: InputMaybe<Scalars['Int']>
  endPeriod?: InputMaybe<Scalars['timestamptz']>
  expensesReason?: InputMaybe<ServerExpensesReasonObjRelInsertInput>
  expensesReasonId?: InputMaybe<Scalars['Int']>
  fromAccount?: InputMaybe<ServerAccountObjRelInsertInput>
  fromAccountId?: InputMaybe<Scalars['Int']>
  movement?: InputMaybe<ServerMovementObjRelInsertInput>
  movementId?: InputMaybe<Scalars['Int']>
  parentId?: InputMaybe<Scalars['Int']>
  parentTransaction?: InputMaybe<ServerTransactionObjRelInsertInput>
  revenueReason?: InputMaybe<ServerRevenueReasonObjRelInsertInput>
  revenueReasonId?: InputMaybe<Scalars['Int']>
  salaryIssues?: InputMaybe<ServerSalaryIssueArrRelInsertInput>
  startPeriod?: InputMaybe<Scalars['timestamptz']>
  toAccount?: InputMaybe<ServerAccountObjRelInsertInput>
  toAccountId?: InputMaybe<Scalars['Int']>
  transactionReceipts?: InputMaybe<ServerTransactionReceiptArrRelInsertInput>
  transactionsCards?: InputMaybe<ServerTransactionCardArrRelInsertInput>
  transactionsServices?: InputMaybe<ServerTransactionServiceArrRelInsertInput>
  type?: InputMaybe<Scalars['smallint']>
  typeVariation?: InputMaybe<Scalars['smallint']>
}

/** aggregate max on columns */
export type ServerTransactionMaxFields = {
  amount: Maybe<Scalars['numeric']>
  authorId: Maybe<Scalars['Int']>
  bookingId: Maybe<Scalars['Int']>
  branchId: Maybe<Scalars['Int']>
  cardId: Maybe<Scalars['Int']>
  clientId: Maybe<Scalars['Int']>
  comment: Maybe<Scalars['String']>
  /** Дата фактического создания. Используется только для определения транзакций не за фактическую дату. */
  createdAt: Maybe<Scalars['timestamptz']>
  /** Дата транзакции. Используется для всех подсчётов. */
  date: Maybe<Scalars['timestamptz']>
  employeeId: Maybe<Scalars['Int']>
  endPeriod: Maybe<Scalars['timestamptz']>
  expensesReasonId: Maybe<Scalars['Int']>
  fromAccountId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  movementId: Maybe<Scalars['Int']>
  parentId: Maybe<Scalars['Int']>
  revenueReasonId: Maybe<Scalars['Int']>
  startPeriod: Maybe<Scalars['timestamptz']>
  toAccountId: Maybe<Scalars['Int']>
  type: Maybe<Scalars['smallint']>
  typeVariation: Maybe<Scalars['smallint']>
}

/** order by max() on columns of table "transaction" */
export type ServerTransactionMaxOrderBy = {
  amount?: InputMaybe<ServerOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cardId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  comment?: InputMaybe<ServerOrderBy>
  /** Дата фактического создания. Используется только для определения транзакций не за фактическую дату. */
  createdAt?: InputMaybe<ServerOrderBy>
  /** Дата транзакции. Используется для всех подсчётов. */
  date?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  endPeriod?: InputMaybe<ServerOrderBy>
  expensesReasonId?: InputMaybe<ServerOrderBy>
  fromAccountId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  revenueReasonId?: InputMaybe<ServerOrderBy>
  startPeriod?: InputMaybe<ServerOrderBy>
  toAccountId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  typeVariation?: InputMaybe<ServerOrderBy>
}

/** aggregate min on columns */
export type ServerTransactionMinFields = {
  amount: Maybe<Scalars['numeric']>
  authorId: Maybe<Scalars['Int']>
  bookingId: Maybe<Scalars['Int']>
  branchId: Maybe<Scalars['Int']>
  cardId: Maybe<Scalars['Int']>
  clientId: Maybe<Scalars['Int']>
  comment: Maybe<Scalars['String']>
  /** Дата фактического создания. Используется только для определения транзакций не за фактическую дату. */
  createdAt: Maybe<Scalars['timestamptz']>
  /** Дата транзакции. Используется для всех подсчётов. */
  date: Maybe<Scalars['timestamptz']>
  employeeId: Maybe<Scalars['Int']>
  endPeriod: Maybe<Scalars['timestamptz']>
  expensesReasonId: Maybe<Scalars['Int']>
  fromAccountId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  movementId: Maybe<Scalars['Int']>
  parentId: Maybe<Scalars['Int']>
  revenueReasonId: Maybe<Scalars['Int']>
  startPeriod: Maybe<Scalars['timestamptz']>
  toAccountId: Maybe<Scalars['Int']>
  type: Maybe<Scalars['smallint']>
  typeVariation: Maybe<Scalars['smallint']>
}

/** order by min() on columns of table "transaction" */
export type ServerTransactionMinOrderBy = {
  amount?: InputMaybe<ServerOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cardId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  comment?: InputMaybe<ServerOrderBy>
  /** Дата фактического создания. Используется только для определения транзакций не за фактическую дату. */
  createdAt?: InputMaybe<ServerOrderBy>
  /** Дата транзакции. Используется для всех подсчётов. */
  date?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  endPeriod?: InputMaybe<ServerOrderBy>
  expensesReasonId?: InputMaybe<ServerOrderBy>
  fromAccountId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  revenueReasonId?: InputMaybe<ServerOrderBy>
  startPeriod?: InputMaybe<ServerOrderBy>
  toAccountId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  typeVariation?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "transaction" */
export type ServerTransactionMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerTransaction>
}

/** input type for inserting object relation for remote table "transaction" */
export type ServerTransactionObjRelInsertInput = {
  data: ServerTransactionInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerTransactionOnConflict>
}

/** on_conflict condition type for table "transaction" */
export type ServerTransactionOnConflict = {
  constraint: ServerTransactionConstraint
  update_columns?: Array<ServerTransactionUpdateColumn>
  where?: InputMaybe<ServerTransactionBoolExp>
}

/** Ordering options when selecting data from "transaction". */
export type ServerTransactionOrderBy = {
  amount?: InputMaybe<ServerOrderBy>
  author?: InputMaybe<ServerEmployeeOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  booking?: InputMaybe<ServerBookingOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cardId?: InputMaybe<ServerOrderBy>
  childTransactions_aggregate?: InputMaybe<ServerTransactionAggregateOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  clientCard?: InputMaybe<ServerCardOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  comment?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  date?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  endPeriod?: InputMaybe<ServerOrderBy>
  expensesReason?: InputMaybe<ServerExpensesReasonOrderBy>
  expensesReasonId?: InputMaybe<ServerOrderBy>
  fromAccount?: InputMaybe<ServerAccountOrderBy>
  fromAccountId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movement?: InputMaybe<ServerMovementOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  parentTransaction?: InputMaybe<ServerTransactionOrderBy>
  revenueReason?: InputMaybe<ServerRevenueReasonOrderBy>
  revenueReasonId?: InputMaybe<ServerOrderBy>
  salaryIssues_aggregate?: InputMaybe<ServerSalaryIssueAggregateOrderBy>
  startPeriod?: InputMaybe<ServerOrderBy>
  toAccount?: InputMaybe<ServerAccountOrderBy>
  toAccountId?: InputMaybe<ServerOrderBy>
  transactionReceipts_aggregate?: InputMaybe<ServerTransactionReceiptAggregateOrderBy>
  transactionsCards_aggregate?: InputMaybe<ServerTransactionCardAggregateOrderBy>
  transactionsServices_aggregate?: InputMaybe<ServerTransactionServiceAggregateOrderBy>
  type?: InputMaybe<ServerOrderBy>
  typeVariation?: InputMaybe<ServerOrderBy>
}

/** select columns of table "transaction" */
export enum ServerTransactionSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  BookingId = 'bookingId',
  /** column name */
  BranchId = 'branchId',
  /** column name */
  CardId = 'cardId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Date = 'date',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  EndPeriod = 'endPeriod',
  /** column name */
  ExpensesReasonId = 'expensesReasonId',
  /** column name */
  FromAccountId = 'fromAccountId',
  /** column name */
  Id = 'id',
  /** column name */
  MovementId = 'movementId',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  RevenueReasonId = 'revenueReasonId',
  /** column name */
  StartPeriod = 'startPeriod',
  /** column name */
  ToAccountId = 'toAccountId',
  /** column name */
  Type = 'type',
  /** column name */
  TypeVariation = 'typeVariation',
}

/** aggregate stddev on columns */
export type ServerTransactionStddevFields = {
  amount: Maybe<Scalars['Float']>
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  cardId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  expensesReasonId: Maybe<Scalars['Float']>
  fromAccountId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  parentId: Maybe<Scalars['Float']>
  revenueReasonId: Maybe<Scalars['Float']>
  toAccountId: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
  typeVariation: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "transaction" */
export type ServerTransactionStddevOrderBy = {
  amount?: InputMaybe<ServerOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cardId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  expensesReasonId?: InputMaybe<ServerOrderBy>
  fromAccountId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  revenueReasonId?: InputMaybe<ServerOrderBy>
  toAccountId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  typeVariation?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_pop on columns */
export type ServerTransactionStddevPopFields = {
  amount: Maybe<Scalars['Float']>
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  cardId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  expensesReasonId: Maybe<Scalars['Float']>
  fromAccountId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  parentId: Maybe<Scalars['Float']>
  revenueReasonId: Maybe<Scalars['Float']>
  toAccountId: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
  typeVariation: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "transaction" */
export type ServerTransactionStddevPopOrderBy = {
  amount?: InputMaybe<ServerOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cardId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  expensesReasonId?: InputMaybe<ServerOrderBy>
  fromAccountId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  revenueReasonId?: InputMaybe<ServerOrderBy>
  toAccountId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  typeVariation?: InputMaybe<ServerOrderBy>
}

/** aggregate stddev_samp on columns */
export type ServerTransactionStddevSampFields = {
  amount: Maybe<Scalars['Float']>
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  cardId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  expensesReasonId: Maybe<Scalars['Float']>
  fromAccountId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  parentId: Maybe<Scalars['Float']>
  revenueReasonId: Maybe<Scalars['Float']>
  toAccountId: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
  typeVariation: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "transaction" */
export type ServerTransactionStddevSampOrderBy = {
  amount?: InputMaybe<ServerOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cardId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  expensesReasonId?: InputMaybe<ServerOrderBy>
  fromAccountId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  revenueReasonId?: InputMaybe<ServerOrderBy>
  toAccountId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  typeVariation?: InputMaybe<ServerOrderBy>
}

/** aggregate sum on columns */
export type ServerTransactionSumFields = {
  amount: Maybe<Scalars['numeric']>
  authorId: Maybe<Scalars['Int']>
  bookingId: Maybe<Scalars['Int']>
  branchId: Maybe<Scalars['Int']>
  cardId: Maybe<Scalars['Int']>
  clientId: Maybe<Scalars['Int']>
  employeeId: Maybe<Scalars['Int']>
  expensesReasonId: Maybe<Scalars['Int']>
  fromAccountId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  movementId: Maybe<Scalars['Int']>
  parentId: Maybe<Scalars['Int']>
  revenueReasonId: Maybe<Scalars['Int']>
  toAccountId: Maybe<Scalars['Int']>
  type: Maybe<Scalars['smallint']>
  typeVariation: Maybe<Scalars['smallint']>
}

/** order by sum() on columns of table "transaction" */
export type ServerTransactionSumOrderBy = {
  amount?: InputMaybe<ServerOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cardId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  expensesReasonId?: InputMaybe<ServerOrderBy>
  fromAccountId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  revenueReasonId?: InputMaybe<ServerOrderBy>
  toAccountId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  typeVariation?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "transaction" (current role has no relevant permissions) */
export enum ServerTransactionUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** aggregate var_pop on columns */
export type ServerTransactionVarPopFields = {
  amount: Maybe<Scalars['Float']>
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  cardId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  expensesReasonId: Maybe<Scalars['Float']>
  fromAccountId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  parentId: Maybe<Scalars['Float']>
  revenueReasonId: Maybe<Scalars['Float']>
  toAccountId: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
  typeVariation: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "transaction" */
export type ServerTransactionVarPopOrderBy = {
  amount?: InputMaybe<ServerOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cardId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  expensesReasonId?: InputMaybe<ServerOrderBy>
  fromAccountId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  revenueReasonId?: InputMaybe<ServerOrderBy>
  toAccountId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  typeVariation?: InputMaybe<ServerOrderBy>
}

/** aggregate var_samp on columns */
export type ServerTransactionVarSampFields = {
  amount: Maybe<Scalars['Float']>
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  cardId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  expensesReasonId: Maybe<Scalars['Float']>
  fromAccountId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  parentId: Maybe<Scalars['Float']>
  revenueReasonId: Maybe<Scalars['Float']>
  toAccountId: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
  typeVariation: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "transaction" */
export type ServerTransactionVarSampOrderBy = {
  amount?: InputMaybe<ServerOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cardId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  expensesReasonId?: InputMaybe<ServerOrderBy>
  fromAccountId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  revenueReasonId?: InputMaybe<ServerOrderBy>
  toAccountId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  typeVariation?: InputMaybe<ServerOrderBy>
}

/** aggregate variance on columns */
export type ServerTransactionVarianceFields = {
  amount: Maybe<Scalars['Float']>
  authorId: Maybe<Scalars['Float']>
  bookingId: Maybe<Scalars['Float']>
  branchId: Maybe<Scalars['Float']>
  cardId: Maybe<Scalars['Float']>
  clientId: Maybe<Scalars['Float']>
  employeeId: Maybe<Scalars['Float']>
  expensesReasonId: Maybe<Scalars['Float']>
  fromAccountId: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Float']>
  movementId: Maybe<Scalars['Float']>
  parentId: Maybe<Scalars['Float']>
  revenueReasonId: Maybe<Scalars['Float']>
  toAccountId: Maybe<Scalars['Float']>
  type: Maybe<Scalars['Float']>
  typeVariation: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "transaction" */
export type ServerTransactionVarianceOrderBy = {
  amount?: InputMaybe<ServerOrderBy>
  authorId?: InputMaybe<ServerOrderBy>
  bookingId?: InputMaybe<ServerOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  cardId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  expensesReasonId?: InputMaybe<ServerOrderBy>
  fromAccountId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  movementId?: InputMaybe<ServerOrderBy>
  parentId?: InputMaybe<ServerOrderBy>
  revenueReasonId?: InputMaybe<ServerOrderBy>
  toAccountId?: InputMaybe<ServerOrderBy>
  type?: InputMaybe<ServerOrderBy>
  typeVariation?: InputMaybe<ServerOrderBy>
}

/** columns and relationships of "unitedBooking" */
export type ServerUnitedBooking = {
  /** An array relationship */
  bookings: Array<ServerBooking>
  /** An aggregate relationship */
  bookings_aggregate: ServerBookingAggregate
  createdAt: Scalars['timestamptz']
  id: Scalars['Int']
}

/** columns and relationships of "unitedBooking" */
export type ServerUnitedBookingBookingsArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** columns and relationships of "unitedBooking" */
export type ServerUnitedBookingBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerBookingSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerBookingOrderBy>>
  where?: InputMaybe<ServerBookingBoolExp>
}

/** Boolean expression to filter rows from the table "unitedBooking". All fields are combined with a logical 'AND'. */
export type ServerUnitedBookingBoolExp = {
  _and?: InputMaybe<Array<ServerUnitedBookingBoolExp>>
  _not?: InputMaybe<ServerUnitedBookingBoolExp>
  _or?: InputMaybe<Array<ServerUnitedBookingBoolExp>>
  bookings?: InputMaybe<ServerBookingBoolExp>
  bookings_aggregate?: InputMaybe<ServerBookingAggregateBoolExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "unitedBooking" */
export enum ServerUnitedBookingConstraint {
  /** unique or primary key constraint on columns "id" */
  UnitedBookingPkey = 'unitedBooking_pkey',
}

/** input type for inserting data into table "unitedBooking" */
export type ServerUnitedBookingInsertInput = {
  bookings?: InputMaybe<ServerBookingArrRelInsertInput>
}

/** response of any mutation on the table "unitedBooking" */
export type ServerUnitedBookingMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerUnitedBooking>
}

/** input type for inserting object relation for remote table "unitedBooking" */
export type ServerUnitedBookingObjRelInsertInput = {
  data: ServerUnitedBookingInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerUnitedBookingOnConflict>
}

/** on_conflict condition type for table "unitedBooking" */
export type ServerUnitedBookingOnConflict = {
  constraint: ServerUnitedBookingConstraint
  update_columns?: Array<ServerUnitedBookingUpdateColumn>
  where?: InputMaybe<ServerUnitedBookingBoolExp>
}

/** Ordering options when selecting data from "unitedBooking". */
export type ServerUnitedBookingOrderBy = {
  bookings_aggregate?: InputMaybe<ServerBookingAggregateOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
}

/** select columns of table "unitedBooking" */
export enum ServerUnitedBookingSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
}

/** placeholder for update columns of table "unitedBooking" (current role has no relevant permissions) */
export enum ServerUnitedBookingUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** Tokens used in sending notifications to mobile apps */
export type ServerUserExpoNotification = {
  email: Scalars['String']
  expoPushToken: Scalars['String']
}

/** Boolean expression to filter rows from the table "userExpoNotification". All fields are combined with a logical 'AND'. */
export type ServerUserExpoNotificationBoolExp = {
  _and?: InputMaybe<Array<ServerUserExpoNotificationBoolExp>>
  _not?: InputMaybe<ServerUserExpoNotificationBoolExp>
  _or?: InputMaybe<Array<ServerUserExpoNotificationBoolExp>>
  email?: InputMaybe<ServerStringComparisonExp>
  expoPushToken?: InputMaybe<ServerStringComparisonExp>
}

/** unique or primary key constraints on table "userExpoNotification" */
export enum ServerUserExpoNotificationConstraint {
  /** unique or primary key constraint on columns "email" */
  UserExpoNotificationPkey = 'userExpoNotification_pkey',
}

/** input type for inserting data into table "userExpoNotification" */
export type ServerUserExpoNotificationInsertInput = {
  expoPushToken?: InputMaybe<Scalars['String']>
}

/** response of any mutation on the table "userExpoNotification" */
export type ServerUserExpoNotificationMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerUserExpoNotification>
}

/** on_conflict condition type for table "userExpoNotification" */
export type ServerUserExpoNotificationOnConflict = {
  constraint: ServerUserExpoNotificationConstraint
  update_columns?: Array<ServerUserExpoNotificationUpdateColumn>
  where?: InputMaybe<ServerUserExpoNotificationBoolExp>
}

/** Ordering options when selecting data from "userExpoNotification". */
export type ServerUserExpoNotificationOrderBy = {
  email?: InputMaybe<ServerOrderBy>
  expoPushToken?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: userExpoNotification */
export type ServerUserExpoNotificationPkColumnsInput = {
  email: Scalars['String']
}

/** select columns of table "userExpoNotification" */
export enum ServerUserExpoNotificationSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  ExpoPushToken = 'expoPushToken',
}

/** input type for updating data in table "userExpoNotification" */
export type ServerUserExpoNotificationSetInput = {
  email?: InputMaybe<Scalars['String']>
  expoPushToken?: InputMaybe<Scalars['String']>
}

/** update columns of table "userExpoNotification" */
export enum ServerUserExpoNotificationUpdateColumn {
  /** column name */
  Email = 'email',
  /** column name */
  ExpoPushToken = 'expoPushToken',
}

export type ServerUserExpoNotificationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerUserExpoNotificationSetInput>
  /** filter the rows which have to be updated */
  where: ServerUserExpoNotificationBoolExp
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type ServerUuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

/** columns and relationships of "waitingList" */
export type ServerWaitingList = {
  /** An object relationship */
  branch: ServerBranch
  branchId: Scalars['Int']
  /** An object relationship */
  business: ServerBusiness
  /** An object relationship */
  client: ServerClient
  clientId: Scalars['Int']
  closedAt: Maybe<Scalars['timestamptz']>
  createdAt: Scalars['timestamptz']
  dates: Scalars['jsonb']
  description: Maybe<Scalars['String']>
  /** An object relationship */
  employee: Maybe<ServerEmployee>
  employeeId: Maybe<Scalars['Int']>
  id: Scalars['Int']
  /** An array relationship */
  notifications: Array<ServerNotification>
  /** An aggregate relationship */
  notifications_aggregate: ServerNotificationAggregate
  /** An array relationship */
  responsibleEmployees: Array<ServerWaitingListEmployee>
  /** An object relationship */
  service: ServerService
  serviceId: Scalars['Int']
}

/** columns and relationships of "waitingList" */
export type ServerWaitingListDatesArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "waitingList" */
export type ServerWaitingListNotificationsArgs = {
  distinct_on?: InputMaybe<Array<ServerNotificationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerNotificationOrderBy>>
  where?: InputMaybe<ServerNotificationBoolExp>
}

/** columns and relationships of "waitingList" */
export type ServerWaitingListNotificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerNotificationSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerNotificationOrderBy>>
  where?: InputMaybe<ServerNotificationBoolExp>
}

/** columns and relationships of "waitingList" */
export type ServerWaitingListResponsibleEmployeesArgs = {
  distinct_on?: InputMaybe<Array<ServerWaitingListEmployeeSelectColumn>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ServerWaitingListEmployeeOrderBy>>
  where?: InputMaybe<ServerWaitingListEmployeeBoolExp>
}

/** Ответственные сотрудники */
export type ServerWaitingListEmployee = {
  /** An object relationship */
  employee: ServerEmployee
  employeeId: Scalars['Int']
  id: Scalars['Int']
  /** An object relationship */
  waitingList: ServerWaitingList
  waitingListId: Scalars['Int']
}

/** order by aggregate values of table "waitingListEmployee" */
export type ServerWaitingListEmployeeAggregateOrderBy = {
  avg?: InputMaybe<ServerWaitingListEmployeeAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerWaitingListEmployeeMaxOrderBy>
  min?: InputMaybe<ServerWaitingListEmployeeMinOrderBy>
  stddev?: InputMaybe<ServerWaitingListEmployeeStddevOrderBy>
  stddev_pop?: InputMaybe<ServerWaitingListEmployeeStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerWaitingListEmployeeStddevSampOrderBy>
  sum?: InputMaybe<ServerWaitingListEmployeeSumOrderBy>
  var_pop?: InputMaybe<ServerWaitingListEmployeeVarPopOrderBy>
  var_samp?: InputMaybe<ServerWaitingListEmployeeVarSampOrderBy>
  variance?: InputMaybe<ServerWaitingListEmployeeVarianceOrderBy>
}

/** input type for inserting array relation for remote table "waitingListEmployee" */
export type ServerWaitingListEmployeeArrRelInsertInput = {
  data: Array<ServerWaitingListEmployeeInsertInput>
  /** upsert condition */
  on_conflict?: InputMaybe<ServerWaitingListEmployeeOnConflict>
}

/** order by avg() on columns of table "waitingListEmployee" */
export type ServerWaitingListEmployeeAvgOrderBy = {
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "waitingListEmployee". All fields are combined with a logical 'AND'. */
export type ServerWaitingListEmployeeBoolExp = {
  _and?: InputMaybe<Array<ServerWaitingListEmployeeBoolExp>>
  _not?: InputMaybe<ServerWaitingListEmployeeBoolExp>
  _or?: InputMaybe<Array<ServerWaitingListEmployeeBoolExp>>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  waitingList?: InputMaybe<ServerWaitingListBoolExp>
  waitingListId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "waitingListEmployee" */
export enum ServerWaitingListEmployeeConstraint {
  /** unique or primary key constraint on columns "id" */
  WaitingListEmployeePkey = 'waitingListEmployee_pkey',
}

/** input type for inserting data into table "waitingListEmployee" */
export type ServerWaitingListEmployeeInsertInput = {
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employeeId?: InputMaybe<Scalars['Int']>
  waitingList?: InputMaybe<ServerWaitingListObjRelInsertInput>
  waitingListId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "waitingListEmployee" */
export type ServerWaitingListEmployeeMaxOrderBy = {
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "waitingListEmployee" */
export type ServerWaitingListEmployeeMinOrderBy = {
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "waitingListEmployee" */
export type ServerWaitingListEmployeeMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerWaitingListEmployee>
}

/** on_conflict condition type for table "waitingListEmployee" */
export type ServerWaitingListEmployeeOnConflict = {
  constraint: ServerWaitingListEmployeeConstraint
  update_columns?: Array<ServerWaitingListEmployeeUpdateColumn>
  where?: InputMaybe<ServerWaitingListEmployeeBoolExp>
}

/** Ordering options when selecting data from "waitingListEmployee". */
export type ServerWaitingListEmployeeOrderBy = {
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  waitingList?: InputMaybe<ServerWaitingListOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** select columns of table "waitingListEmployee" */
export enum ServerWaitingListEmployeeSelectColumn {
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  Id = 'id',
  /** column name */
  WaitingListId = 'waitingListId',
}

/** order by stddev() on columns of table "waitingListEmployee" */
export type ServerWaitingListEmployeeStddevOrderBy = {
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "waitingListEmployee" */
export type ServerWaitingListEmployeeStddevPopOrderBy = {
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "waitingListEmployee" */
export type ServerWaitingListEmployeeStddevSampOrderBy = {
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "waitingListEmployee" */
export type ServerWaitingListEmployeeSumOrderBy = {
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** placeholder for update columns of table "waitingListEmployee" (current role has no relevant permissions) */
export enum ServerWaitingListEmployeeUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "waitingListEmployee" */
export type ServerWaitingListEmployeeVarPopOrderBy = {
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "waitingListEmployee" */
export type ServerWaitingListEmployeeVarSampOrderBy = {
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "waitingListEmployee" */
export type ServerWaitingListEmployeeVarianceOrderBy = {
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  waitingListId?: InputMaybe<ServerOrderBy>
}

/** order by aggregate values of table "waitingList" */
export type ServerWaitingListAggregateOrderBy = {
  avg?: InputMaybe<ServerWaitingListAvgOrderBy>
  count?: InputMaybe<ServerOrderBy>
  max?: InputMaybe<ServerWaitingListMaxOrderBy>
  min?: InputMaybe<ServerWaitingListMinOrderBy>
  stddev?: InputMaybe<ServerWaitingListStddevOrderBy>
  stddev_pop?: InputMaybe<ServerWaitingListStddevPopOrderBy>
  stddev_samp?: InputMaybe<ServerWaitingListStddevSampOrderBy>
  sum?: InputMaybe<ServerWaitingListSumOrderBy>
  var_pop?: InputMaybe<ServerWaitingListVarPopOrderBy>
  var_samp?: InputMaybe<ServerWaitingListVarSampOrderBy>
  variance?: InputMaybe<ServerWaitingListVarianceOrderBy>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type ServerWaitingListAppendInput = {
  dates?: InputMaybe<Scalars['jsonb']>
}

/** order by avg() on columns of table "waitingList" */
export type ServerWaitingListAvgOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** Boolean expression to filter rows from the table "waitingList". All fields are combined with a logical 'AND'. */
export type ServerWaitingListBoolExp = {
  _and?: InputMaybe<Array<ServerWaitingListBoolExp>>
  _not?: InputMaybe<ServerWaitingListBoolExp>
  _or?: InputMaybe<Array<ServerWaitingListBoolExp>>
  branch?: InputMaybe<ServerBranchBoolExp>
  branchId?: InputMaybe<ServerIntComparisonExp>
  business?: InputMaybe<ServerBusinessBoolExp>
  client?: InputMaybe<ServerClientBoolExp>
  clientId?: InputMaybe<ServerIntComparisonExp>
  closedAt?: InputMaybe<ServerTimestamptzComparisonExp>
  createdAt?: InputMaybe<ServerTimestamptzComparisonExp>
  dates?: InputMaybe<ServerJsonbComparisonExp>
  description?: InputMaybe<ServerStringComparisonExp>
  employee?: InputMaybe<ServerEmployeeBoolExp>
  employeeId?: InputMaybe<ServerIntComparisonExp>
  id?: InputMaybe<ServerIntComparisonExp>
  notifications?: InputMaybe<ServerNotificationBoolExp>
  notifications_aggregate?: InputMaybe<ServerNotificationAggregateBoolExp>
  responsibleEmployees?: InputMaybe<ServerWaitingListEmployeeBoolExp>
  service?: InputMaybe<ServerServiceBoolExp>
  serviceId?: InputMaybe<ServerIntComparisonExp>
}

/** unique or primary key constraints on table "waitingList" */
export enum ServerWaitingListConstraint {
  /** unique or primary key constraint on columns "id" */
  WaitingListPkey = 'waitingList_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ServerWaitingListDeleteAtPathInput = {
  dates?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ServerWaitingListDeleteElemInput = {
  dates?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ServerWaitingListDeleteKeyInput = {
  dates?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "waitingList" */
export type ServerWaitingListIncInput = {
  clientId?: InputMaybe<Scalars['Int']>
  employeeId?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "waitingList" */
export type ServerWaitingListInsertInput = {
  branch?: InputMaybe<ServerBranchObjRelInsertInput>
  branchId?: InputMaybe<Scalars['Int']>
  client?: InputMaybe<ServerClientObjRelInsertInput>
  clientId?: InputMaybe<Scalars['Int']>
  dates?: InputMaybe<Scalars['jsonb']>
  description?: InputMaybe<Scalars['String']>
  employee?: InputMaybe<ServerEmployeeObjRelInsertInput>
  employeeId?: InputMaybe<Scalars['Int']>
  notifications?: InputMaybe<ServerNotificationArrRelInsertInput>
  responsibleEmployees?: InputMaybe<ServerWaitingListEmployeeArrRelInsertInput>
  service?: InputMaybe<ServerServiceObjRelInsertInput>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** order by max() on columns of table "waitingList" */
export type ServerWaitingListMaxOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  closedAt?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by min() on columns of table "waitingList" */
export type ServerWaitingListMinOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  closedAt?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** response of any mutation on the table "waitingList" */
export type ServerWaitingListMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<ServerWaitingList>
}

/** input type for inserting object relation for remote table "waitingList" */
export type ServerWaitingListObjRelInsertInput = {
  data: ServerWaitingListInsertInput
  /** upsert condition */
  on_conflict?: InputMaybe<ServerWaitingListOnConflict>
}

/** on_conflict condition type for table "waitingList" */
export type ServerWaitingListOnConflict = {
  constraint: ServerWaitingListConstraint
  update_columns?: Array<ServerWaitingListUpdateColumn>
  where?: InputMaybe<ServerWaitingListBoolExp>
}

/** Ordering options when selecting data from "waitingList". */
export type ServerWaitingListOrderBy = {
  branch?: InputMaybe<ServerBranchOrderBy>
  branchId?: InputMaybe<ServerOrderBy>
  business?: InputMaybe<ServerBusinessOrderBy>
  client?: InputMaybe<ServerClientOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  closedAt?: InputMaybe<ServerOrderBy>
  createdAt?: InputMaybe<ServerOrderBy>
  dates?: InputMaybe<ServerOrderBy>
  description?: InputMaybe<ServerOrderBy>
  employee?: InputMaybe<ServerEmployeeOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  notifications_aggregate?: InputMaybe<ServerNotificationAggregateOrderBy>
  responsibleEmployees_aggregate?: InputMaybe<ServerWaitingListEmployeeAggregateOrderBy>
  service?: InputMaybe<ServerServiceOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** primary key columns input for table: waitingList */
export type ServerWaitingListPkColumnsInput = {
  id: Scalars['Int']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ServerWaitingListPrependInput = {
  dates?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "waitingList" */
export enum ServerWaitingListSelectColumn {
  /** column name */
  BranchId = 'branchId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClosedAt = 'closedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Dates = 'dates',
  /** column name */
  Description = 'description',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
}

/** input type for updating data in table "waitingList" */
export type ServerWaitingListSetInput = {
  clientId?: InputMaybe<Scalars['Int']>
  closedAt?: InputMaybe<Scalars['timestamptz']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  dates?: InputMaybe<Scalars['jsonb']>
  description?: InputMaybe<Scalars['String']>
  employeeId?: InputMaybe<Scalars['Int']>
  serviceId?: InputMaybe<Scalars['Int']>
}

/** order by stddev() on columns of table "waitingList" */
export type ServerWaitingListStddevOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_pop() on columns of table "waitingList" */
export type ServerWaitingListStddevPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by stddev_samp() on columns of table "waitingList" */
export type ServerWaitingListStddevSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by sum() on columns of table "waitingList" */
export type ServerWaitingListSumOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** update columns of table "waitingList" */
export enum ServerWaitingListUpdateColumn {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClosedAt = 'closedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Dates = 'dates',
  /** column name */
  Description = 'description',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  ServiceId = 'serviceId',
}

export type ServerWaitingListUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ServerWaitingListAppendInput>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ServerWaitingListDeleteAtPathInput>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ServerWaitingListDeleteElemInput>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ServerWaitingListDeleteKeyInput>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServerWaitingListIncInput>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ServerWaitingListPrependInput>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServerWaitingListSetInput>
  /** filter the rows which have to be updated */
  where: ServerWaitingListBoolExp
}

/** order by var_pop() on columns of table "waitingList" */
export type ServerWaitingListVarPopOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by var_samp() on columns of table "waitingList" */
export type ServerWaitingListVarSampOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

/** order by variance() on columns of table "waitingList" */
export type ServerWaitingListVarianceOrderBy = {
  branchId?: InputMaybe<ServerOrderBy>
  clientId?: InputMaybe<ServerOrderBy>
  employeeId?: InputMaybe<ServerOrderBy>
  id?: InputMaybe<ServerOrderBy>
  serviceId?: InputMaybe<ServerOrderBy>
}

export type ServerAccountType = {
  id: number
  name: string
  description: string | null
  cash: boolean
  commission: number | null
  forPayments: boolean
  maximumDebt: number
  balance: number | null
  withdrawalToAccountId: number | null
  defaultForBranch: { defaultAccountId: number | null } | null
  accountSoftwarePOS: Array<{ softwarePOS: { id: number; name: string } }>
}

export type ServerAccountByIdType = {
  withdrawalToAccountId: number | null
  exchangeAmount: number | null
  id: number
  name: string
  description: string | null
  cash: boolean
  commission: number | null
  forPayments: boolean
  maximumDebt: number
  balance: number | null
  accountEmployeeGroups: Array<{ employeeGroup: { id: number; name: string } }>
  defaultForBranch: { defaultAccountId: number | null } | null
  accountSoftwarePOS: Array<{ softwarePOS: { id: number; name: string } }>
}

export type ServerBillingInfoType = {
  id: string
  fromDate: Date
  toDate: Date
  value: number
  paid: boolean
  planId: number
}

export type ServerBookingAsClientType = {
  id: number
  isGroupBooking: boolean
  isPartlyPaid: boolean | null
  startDate: Date
  duration: number
  clientNote: string | null
  location: {
    id: number
    name: string
    maxEmployees: number
    maxClients: number
    serviceLocations: Array<{ serviceId: number }>
  }
  bookingServices: Array<{
    service: {
      id: number
      name: string
      type: number
      picture: string | null
      groupId: number | null
      color: string | null
      defaultDuration: number
      price: number
      availableForClient: boolean
      serviceEmployees: Array<{ employeeId: number }>
      serviceLocations: Array<{ locationId: number }>
      serviceGroup: { id: number; name: string } | null
    }
  }>
  service: {
    id: number
    name: string
    type: number
    picture: string | null
    groupId: number | null
    color: string | null
    defaultDuration: number
    price: number
    availableForClient: boolean
    serviceEmployees: Array<{ employeeId: number }>
    serviceLocations: Array<{ locationId: number }>
    serviceGroup: { id: number; name: string } | null
  } | null
  employee: { id: number; firstName: string; lastName: string; photo: string | null } | null
  bookingClients: Array<{ clientId: number }>
  review: { clientId: number } | null
}

export type ServerBookingProductType = {
  id: number
  productId: number
  quantity: number
  product: {
    id: number
    photo: string | null
    name: string
    price: number
    unit: number
    criticalQuantity: number
  }
}

export type ServerBookingByIdType = {
  id: number
  duration: number
  startDate: Date
  note: string | null
  status: number | null
  isGroupBooking: boolean
  canceledDate: Date | null
  canceledReason: string | null
  isPaid: boolean | null
  branchId: number
  clientNote: string | null
  author: { firstName: string; lastName: string } | null
  client: {
    birthDate: Date | null
    id: number
    firstName: string
    lastName: string | null
    photo: string | null
    leadId: number | null
    createdAt: Date
    clientSettings: { preferredConnections: any } | null
    clientTags: Array<{ tag: { id: number; name: string; color: string } }>
  } | null
  bookingClients: Array<{
    id: number
    client: {
      birthDate: Date | null
      id: number
      firstName: string
      lastName: string | null
      photo: string | null
      leadId: number | null
      createdAt: Date
      clientSettings: { preferredConnections: any } | null
      clientTags: Array<{ tag: { id: number; name: string; color: string } }>
    }
  }>
  location: {
    id: number
    name: string
    groupId: number | null
    description: string | null
    maxClients: number
    maxEmployees: number
    archived: Date | null
    defaultForBranch: { id: number } | null
    locationGroup: { id: number; name: string } | null
  }
  employee: {
    id: number
    firstName: string
    middleName: string | null
    lastName: string
    phone: string | null
    photo: string | null
    groupId: number | null
    email: string | null
    birthDate: Date | null
    gender: string | null
    hasTelegram: boolean | null
    hasViber: boolean | null
    employeeSchedules: Array<{
      id: number
      isDynamic: boolean
      startDate: Date
      endDate: Date | null
      employeeId: number
      schedule: { id: number; name: string; type: number; data: any; archived: Date | null } | null
      dynamicDates: Array<{ id: string; date: Date; timingStart: number; timingEnd: number }>
      branch: { id: number; name: string }
    }>
    employeeGroup: { storageId: number | null } | null
    timeoffs: Array<{ id: number; start: Date; end: Date }>
  } | null
  service: {
    id: number
    name: string
    defaultDuration: number
    price: number
    costPrice: number
    groupId: number | null
    color: string | null
    picture: string | null
    type: number
    serviceProducts: Array<{ productId: number; quantity: number; product: { name: string } }>
  } | null
  bookingServices: Array<{
    id: number
    service: {
      id: number
      name: string
      defaultDuration: number
      price: number
      costPrice: number
      groupId: number | null
      color: string | null
      picture: string | null
      type: number
      serviceProducts: Array<{ productId: number; quantity: number; product: { name: string } }>
    }
  }>
  transactions: Array<{
    id: number
    amount: number
    type: number
    typeVariation: number | null
    clientId: number | null
    employeeId: number | null
    cardId: number | null
    date: Date
    parentId: number | null
    bookingId: number | null
    transactionsServices: Array<{
      serviceId: number
      price: number
      discount: number | null
      service: { name: string; serviceProducts: Array<{ productId: number; quantity: number }> }
    }>
    transactionsCards: Array<{
      cardId: number
      price: number
      card: { type: number; cardTemplate: { name: string } }
    }>
    movement: {
      fromStorageId: number | null
      number: string
      type: number
      fulfilled: boolean
      supplier: { name: string } | null
      movementProducts: Array<{
        productId: number
        price: number
        discount: number | null
        quantity: number
        product: { id: number; name: string; unit: number; forSale: boolean }
      }>
    } | null
    transactionReceipts: Array<{ receiptId: string }>
  }>
  bookingProducts: Array<{
    id: number
    productId: number
    quantity: number
    product: {
      id: number
      photo: string | null
      name: string
      price: number
      unit: number
      criticalQuantity: number
    }
  }>
  salaryIssues: Array<{ salaryTransactionId: number | null }>
  unitedBooking: { id: number } | null
  recurringBooking: { id: number } | null
}

export type ServerBookingHistoryType = {
  id: number
  isPaid: boolean | null
  isGroupBooking: boolean
  createdAt: Date
  startDate: Date
  note: string | null
  clientNote: string | null
  canceledReason: string | null
  canceledDate: Date | null
  author: { firstName: string; lastName: string } | null
  employee: { firstName: string; lastName: string } | null
  bookingServices: Array<{ service: { name: string; id: number; price: number } }>
  service: { id: number; price: number; name: string } | null
  recurringBooking: { id: number } | null
}

export type ServerBookingInReviewType = {
  isGroupBooking: boolean
  service: { name: string } | null
  bookingServices: Array<{ service: { name: string } }>
  employee: { firstName: string; lastName: string; photo: string | null } | null
}

export type ServerBookingClientSettingsBriefType = {
  allowableUpdateTime: number | null
  allowableTimeForEditingStartDate: number | null
  allowedToCreateWithFunds: boolean | null
  allowedToCreateWithoutFunds: boolean | null
  allowedToCreateWithSubscription: boolean | null
  note: string | null
  isClientNoteEnabled: boolean | null
}

export type ServerBookingClientSettingsType = {
  id: number
  allowableUpdateTime: number
  allowableTimeForEditingStartDate: number
  allowedToCreateWithFunds: boolean
  allowedToCreateWithoutFunds: boolean
  allowedToCreateWithSubscription: boolean
  isNoteEnabled: boolean
  note: string | null
  isClientNoteEnabled: boolean
}

export type ServerAnonymouslyBookingClientSettingsType = {
  businessId: number | null
  allowedToCreateWithoutFunds: boolean | null
  note: string | null
  isClientNoteEnabled: boolean | null
}

export type ServerBranchFinancialSettingsType = {
  id: number
  currency: string
  displayCoins: boolean
}

export type ServerBranchBriefType = {
  id: number
  name: string
  timezone: string
  business: { id: number }
}

export type ServerBranchType = {
  description: string | null
  createdAt: Date
  geocode: any | null
  address: string | null
  phone: string | null
  locationId: number | null
  id: number
  name: string
  timezone: string
  currency: string
  displayCoins: boolean
  defaultAccount: { id: number } | null
  defaultStorage: { id: number } | null
  locations: Array<{ id: number }>
  schedule: { id: number; name: string; type: number; data: any; archived: Date | null } | null
  business: { id: number }
}

export type ServerAnonymouslyBranchType = {
  id: number
  name: string
  timezone: string
  address: string | null
  phone: string | null
  currency: string
  geocode: any | null
  scheduleData: any | null
}

export type ServerBusinessByIdType = {
  id: number
  onHold: Date | null
  name: string
  photo: string | null
}

export type ServerBusinessWithOwnerIdType = {
  id: number
  ownerId: number | null
  name: string
  photo: string | null
}

export type ServerAnonymouslyBusinessByIdType = {
  id: number
  name: string
  branches: Array<{
    id: number
    name: string
    timezone: string
    address: string | null
    phone: string | null
    currency: string
    geocode: any | null
    scheduleData: any | null
  }>
}

export type ServerBusinessLandingSettingsType = {
  id: string
  bannerTitle: string | null
  bannerDescription: string | null
  bannerImage: string | null
  enabled: boolean
  images: any
  path: string | null
  facebook: string | null
  telegram: string | null
  instagram: string | null
  viber: string | null
  theme: string
}

export type ServerAnonymouslyBusinessLandingSettingsType = {
  bannerDescription: string | null
  bannerImage: string | null
  bannerTitle: string | null
  businessId: number | null
  facebook: string | null
  images: any | null
  instagram: string | null
  path: string | null
  telegram: string | null
  theme: string | null
  viber: string | null
}

export type ServerBusinessSettingsBriefType = { hasSms: boolean | null }

export type ServerBusinessSettingsType = {
  id: number
  messageTemplate: string | null
  smsToken: string | null
  binotelIntegration: any | null
  hideClientsDeposit: boolean | null
  telegramIntegration: any
  hasSms: boolean | null
}

export type ServerSubscriptionType = {
  id: number
  activatedAt: Date | null
  canceledDate: Date | null
  subscriptionInfo: any | null
  cardPeriod: number
  type: number
  code: string | null
  cardTemplate: {
    id: number
    name: string
    price: number
    cardTemplateServices: Array<{
      servicePrice: number
      serviceQuantity: number
      service: { id: number; name: string; serviceEmployees: Array<{ id: number }> }
    }>
  }
}

export type ServerGiftCardType = {
  id: number
  type: number
  code: string | null
  activatedAt: Date | null
  canceledDate: Date | null
  cardPeriod: number
  createdAt: Date
  cardTemplate: { id: number; name: string; price: number }
}

export type ServerCardType = {
  id: number
  activatedAt: Date | null
  canceledDate: Date | null
  createdAt: Date
  clientId: number | null
  subscriptionInfo: any | null
  cardPeriod: number
  type: number
  code: string | null
  transactionCards: Array<{ transactionId: number }>
  cardTemplate: {
    id: number
    name: string
    price: number
    cardPeriod: number
    cardTemplateServices: Array<{
      servicePrice: number
      serviceQuantity: number
      serviceId: number
      service: { id: number; name: string }
    }>
  }
}

export type ServerCardTemplateBriefType = { id: number; name: string; price: number; type: number }

export type ServerCardBriefType = {
  id: number
  activatedAt: Date | null
  canceledDate: Date | null
  createdAt: Date
  subscriptionInfo: any | null
  cardPeriod: number
}

export type ServerCardTemplateType = {
  availableToAllBranches: boolean
  cardPeriod: number
  archived: Date | null
  id: number
  name: string
  price: number
  type: number
  cardTemplateServices: Array<{ servicePrice: number; serviceQuantity: number; serviceId: number }>
  cards: Array<{
    id: number
    activatedAt: Date | null
    canceledDate: Date | null
    createdAt: Date
    subscriptionInfo: any | null
    cardPeriod: number
  }>
}

export type ServerClientBriefType = {
  id: number
  firstName: string
  lastName: string | null
  birthDate: Date | null
  photo: string | null
  emailView: { email: string | null; alreadyLoggedIn: boolean | null } | null
  clientTags: Array<{ tag: { id: number; name: string; color: string } }>
}

export type ServerClientBriefWithTagType = {
  id: number
  firstName: string
  lastName: string | null
  photo: string | null
  leadId: number | null
  createdAt: Date
  clientSettings: { preferredConnections: any } | null
  clientTags: Array<{ tag: { id: number; name: string; color: string } }>
}

export type ServerClientBriefForCalendarType = {
  birthDate: Date | null
  id: number
  firstName: string
  lastName: string | null
  photo: string | null
  leadId: number | null
  createdAt: Date
  clientSettings: { preferredConnections: any } | null
  clientTags: Array<{ tag: { id: number; name: string; color: string } }>
}

export type ServerClientByIdType = {
  birthDate: Date | null
  firstName: string
  gender: string | null
  id: number
  lastName: string | null
  middleName: string | null
  photo: string | null
  maximumDebt: number | null
  managingEmployeeId: number | null
  calendarId: string | null
  hasTelegram: boolean | null
  hasViber: boolean | null
  emailView: { email: string | null; alreadyLoggedIn: boolean | null } | null
  clientTags: Array<{ tag: { id: number; name: string; color: string } }>
  lead: { id: number; name: string } | null
  archivedInBranches: Array<{ branchId: number }>
  clientRelationsWhereClientIsFirst: Array<{
    type: number
    secondClient: { id: number; firstName: string; lastName: string | null }
  }>
  clientRelationsWhereClientIsSecond: Array<{
    type: number
    firstClient: { id: number; firstName: string; lastName: string | null }
  }>
  clientSettings: { preferredConnections: any; notificationSettings: any } | null
}

export type ServerClientBriefsAndCardsType = {
  id: number
  firstName: string
  lastName: string | null
  birthDate: Date | null
  photo: string | null
  cards: Array<{
    id: number
    activatedAt: Date | null
    canceledDate: Date | null
    createdAt: Date
    clientId: number | null
    subscriptionInfo: any | null
    cardPeriod: number
    type: number
    code: string | null
    transactionCards: Array<{ transactionId: number }>
    cardTemplate: {
      id: number
      name: string
      price: number
      cardPeriod: number
      cardTemplateServices: Array<{
        servicePrice: number
        serviceQuantity: number
        serviceId: number
        service: { id: number; name: string }
      }>
    }
  }>
  emailView: { email: string | null; alreadyLoggedIn: boolean | null } | null
  clientTags: Array<{ tag: { id: number; name: string; color: string } }>
}

export type ServerMyClientType = {
  id: number
  firstName: string
  lastName: string | null
  birthDate: Date | null
  photo: string | null
  phone: string | null
  additionalInformation: string | null
  calendarId: string | null
  hasViber: boolean | null
  hasTelegram: boolean | null
  emailView: { email: string | null; alreadyLoggedIn: boolean | null } | null
  clientSettings: {
    id: number
    lang: string | null
    notificationSettings: any
    preferredConnections: any
  } | null
  subscriptions: Array<{
    id: number
    activatedAt: Date | null
    canceledDate: Date | null
    subscriptionInfo: any | null
    cardPeriod: number
    type: number
    code: string | null
    cardTemplate: {
      id: number
      name: string
      price: number
      cardTemplateServices: Array<{
        servicePrice: number
        serviceQuantity: number
        service: { id: number; name: string; serviceEmployees: Array<{ id: number }> }
      }>
    }
  }>
  giftCards: Array<{
    id: number
    type: number
    code: string | null
    activatedAt: Date | null
    canceledDate: Date | null
    cardPeriod: number
    createdAt: Date
    cardTemplate: { id: number; name: string; price: number }
  }>
}

export type ServerClientWithSubscriptionsType = {
  birthDate: Date | null
  firstName: string
  gender: string | null
  id: number
  lastName: string | null
  middleName: string | null
  photo: string | null
  maximumDebt: number | null
  managingEmployeeId: number | null
  calendarId: string | null
  hasTelegram: boolean | null
  hasViber: boolean | null
  subscriptions: Array<{
    id: number
    activatedAt: Date | null
    canceledDate: Date | null
    subscriptionInfo: any | null
    cardPeriod: number
    type: number
    code: string | null
    cardTemplate: {
      id: number
      name: string
      price: number
      cardTemplateServices: Array<{
        servicePrice: number
        serviceQuantity: number
        service: { id: number; name: string; serviceEmployees: Array<{ id: number }> }
      }>
    }
  }>
  emailView: { email: string | null; alreadyLoggedIn: boolean | null } | null
  clientTags: Array<{ tag: { id: number; name: string; color: string } }>
  lead: { id: number; name: string } | null
  archivedInBranches: Array<{ branchId: number }>
  clientRelationsWhereClientIsFirst: Array<{
    type: number
    secondClient: { id: number; firstName: string; lastName: string | null }
  }>
  clientRelationsWhereClientIsSecond: Array<{
    type: number
    firstClient: { id: number; firstName: string; lastName: string | null }
  }>
  clientSettings: { preferredConnections: any; notificationSettings: any } | null
}

export type ServerBriefClientDocumentType = { startDate: Date | null; endDate: Date | null }

export type ServerClientDocumentType = {
  id: number
  name: string
  type: number
  clientId: number
  createdAt: Date
  startDate: Date | null
  endDate: Date | null
  fileUrl: string | null
  managingEmployeeId: number | null
  author: { firstName: string; lastName: string }
}

export type ServerClientNoteBriefType = {
  id: number
  employeeId: number
  text: string
  createdAt: Date
  edited: boolean | null
}

export type ServerClientNoteType = {
  text: string
  id: number
  edited: boolean | null
  createdAt: Date
  clientId: number
  employee: {
    id: number
    firstName: string
    lastName: string
    photo: string | null
    archived: Date | null
  }
}

export type ServerClientSettingsType = {
  id: number
  preferredConnections: any
  notificationSettings: any
  lang: string | null
}

export type ServerDynamicScheduleDateType = {
  id: string
  date: Date
  timingStart: number
  timingEnd: number
}

export type ServerEmployeeBriefType = {
  id: number
  firstName: string
  lastName: string
  photo: string | null
  groupId: number | null
}

export type ServerEmployeeBriefWithBranchType = {
  id: number
  firstName: string
  lastName: string
  photo: string | null
  employeeSchedules: Array<{ branchId: number }>
}

export type ServerEmployeeType = {
  id: number
  firstName: string
  middleName: string | null
  lastName: string
  phone: string | null
  photo: string | null
  groupId: number | null
  email: string | null
  birthDate: Date | null
  gender: string | null
  hasTelegram: boolean | null
  hasViber: boolean | null
}

export type ServerEmployeeForMobileCalendarType = {
  id: number
  firstName: string
  lastName: string
  employeeSchedules: Array<{
    id: number
    isDynamic: boolean
    startDate: Date
    endDate: Date | null
    employeeId: number
    schedule: { id: number; name: string; type: number; data: any; archived: Date | null } | null
    dynamicDates: Array<{ id: string; date: Date; timingStart: number; timingEnd: number }>
    branch: { id: number; name: string }
  }>
  serviceEmployees: Array<{ service: { id: number } }>
}

export type ServerEmployeeWithScheduleType = {
  id: number
  firstName: string
  middleName: string | null
  lastName: string
  phone: string | null
  photo: string | null
  groupId: number | null
  email: string | null
  birthDate: Date | null
  gender: string | null
  hasTelegram: boolean | null
  hasViber: boolean | null
  employeeSchedules: Array<{
    id: number
    isDynamic: boolean
    startDate: Date
    endDate: Date | null
    employeeId: number
    schedule: { id: number; name: string; type: number; data: any; archived: Date | null } | null
    dynamicDates: Array<{ id: string; date: Date; timingStart: number; timingEnd: number }>
    branch: { id: number; name: string }
  }>
  employeeGroup: { storageId: number | null } | null
  timeoffs: Array<{ id: number; start: Date; end: Date }>
}

export type ServerEmployeeExtendedType = {
  archived: Date | null
  birthDate: Date | null
  id: number
  firstName: string
  middleName: string | null
  lastName: string
  phone: string | null
  photo: string | null
  groupId: number | null
  email: string | null
  gender: string | null
  hasTelegram: boolean | null
  hasViber: boolean | null
  employeeGroup: { id: number; name: string; storageId: number | null } | null
  serviceEmployees: Array<{
    service: { id: number; name: string; serviceLocations: Array<{ locationId: number }> }
  }>
  timeoffs: Array<{
    id: number
    start: Date
    end: Date
    employeeId: number
    timeOffReasonId: number
    timeOffReason: { name: string }
  }>
  salarySettings_aggregate: { aggregate: { count: number } | null }
  employeeSchedules: Array<{
    id: number
    isDynamic: boolean
    startDate: Date
    endDate: Date | null
    employeeId: number
    schedule: { id: number; name: string; type: number; data: any; archived: Date | null } | null
    dynamicDates: Array<{ id: string; date: Date; timingStart: number; timingEnd: number }>
    branch: { id: number; name: string }
  }>
}

export type ServerEmployeeByIdWithServiceEmployeesType = {
  information: string | null
  id: number
  firstName: string
  lastName: string
  photo: string | null
  groupId: number | null
  serviceEmployees: Array<{ serviceId: number }>
}

export type ServerEmployeeByIdExtendedType = {
  archived: Date | null
  preferredPayment: number | null
  status: string | null
  information: string | null
  id: number
  firstName: string
  middleName: string | null
  lastName: string
  phone: string | null
  photo: string | null
  groupId: number | null
  email: string | null
  birthDate: Date | null
  gender: string | null
  hasTelegram: boolean | null
  hasViber: boolean | null
  employeeSchedules: Array<{
    id: number
    isDynamic: boolean
    startDate: Date
    endDate: Date | null
    employeeId: number
    schedule: { id: number; name: string; type: number; data: any; archived: Date | null } | null
    dynamicDates: Array<{ id: string; date: Date; timingStart: number; timingEnd: number }>
    branch: { id: number; name: string }
  }>
  serviceEmployees: Array<{
    branchId: number
    service: { id: number; name: string; groupId: number | null }
  }>
  ownBusiness: { id: number } | null
  timeoffs: Array<{ id: number; start: Date; end: Date }>
  employeeGroup: { name: string; maxDiscount: number | null; roleId: string } | null
  bookings: Array<{ serviceId: number | null; bookingServices: Array<{ serviceId: number }> }>
  employeeSettings: { preferredConnections: any } | null
}

export type ServerEmployeeReviewsType = {
  reviewRating: { averageRating: number | null; quantity: number | null } | null
  reviews: Array<{
    id: number | null
    text: string | null
    rating: number | null
    clientFirstName: string | null
    clientLastName: string | null
  }>
}

export type ServerAnonymouslyEmployeeType = {
  id: number
  firstName: string
  lastName: string
  photo: string | null
  information: string | null
  serviceEmployees: Array<{ serviceId: number }>
  reviewRating: { averageRating: number | null; quantity: number | null } | null
  reviews: Array<{
    id: number | null
    text: string | null
    rating: number | null
    clientFirstName: string | null
    clientLastName: string | null
  }>
}

export type ServerEmployeeGroupBriefsType = { id: number; name: string }

export type ServerEmployeeInEmployeeGroupType = {
  id: number
  lastName: string
  firstName: string
  archived: Date | null
  serviceEmployees: Array<{ id: number; serviceId: number }>
  employeeSchedules: Array<{
    id: number
    isDynamic: boolean
    startDate: Date
    endDate: Date | null
    employeeId: number
    schedule: { id: number; name: string; type: number; data: any; archived: Date | null } | null
    dynamicDates: Array<{ id: string; date: Date; timingStart: number; timingEnd: number }>
    branch: { id: number; name: string }
  }>
  salarySettings: Array<{
    id: number
    employeeId: number
    rateType: number
    rateValue: number
    referralPercentage: number
    start: Date
    end: Date | null
    employee: { preferredPayment: number | null }
    salarySettingServices: Array<{
      id: number
      type: number
      value: number
      fallbackType: number | null
      fallbackValue: number | null
      dependsOnRealizedValue: boolean
      service: { id: number; name: string }
    }>
  }>
}

export type ServerEmployeeAllBranchesInEmployeeGroupType = {
  id: number
  lastName: string
  firstName: string
  archived: Date | null
  employeeSchedules: Array<{ branchId: number }>
}

export type ServerEmployeeGroupType = {
  id: number
  name: string
  roleId: string
  description: string | null
  archived: Date | null
  employees: Array<{
    id: number
    lastName: string
    firstName: string
    archived: Date | null
    serviceEmployees: Array<{ id: number; serviceId: number }>
    employeeSchedules: Array<{
      id: number
      isDynamic: boolean
      startDate: Date
      endDate: Date | null
      employeeId: number
      schedule: { id: number; name: string; type: number; data: any; archived: Date | null } | null
      dynamicDates: Array<{ id: string; date: Date; timingStart: number; timingEnd: number }>
      branch: { id: number; name: string }
    }>
    salarySettings: Array<{
      id: number
      employeeId: number
      rateType: number
      rateValue: number
      referralPercentage: number
      start: Date
      end: Date | null
      employee: { preferredPayment: number | null }
      salarySettingServices: Array<{
        id: number
        type: number
        value: number
        fallbackType: number | null
        fallbackValue: number | null
        dependsOnRealizedValue: boolean
        service: { id: number; name: string }
      }>
    }>
  }>
}

export type ServerEmployeeAllBranchesGroupType = {
  id: number
  name: string
  roleId: string
  description: string | null
  archived: Date | null
  employees: Array<{
    id: number
    lastName: string
    firstName: string
    archived: Date | null
    employeeSchedules: Array<{ branchId: number }>
  }>
}

export type ServerArchivedEmployeeGroupType = {
  id: number
  name: string
  roleId: string
  description: string | null
  archived: Date | null
  employees: Array<{ id: number; lastName: string; firstName: string }>
}

export type ServerEmployeeGroupByIdType = {
  id: number
  name: string
  roleId: string
  description: string | null
  maxDiscount: number | null
  archived: Date | null
  storageId: number | null
  employees: Array<{
    id: number
    archived: Date | null
    employeeSchedules: Array<{ branchId: number; endDate: Date | null }>
  }>
}

export type ServerEmployeeSettingsType = {
  id: number
  notificationSettings: any
  preferredConnections: any
  lang: string | null
}

export type ServerExpaneInfoType = {
  id: number
  image: string | null
  titleEn: string
  titleUk: string
  titleRu: string
  descriptionEn: string | null
  descriptionUk: string | null
  descriptionRu: string | null
  percentageOfSatisfied: number
  activeUsers: number
  teamMembers: number
  companyGrowthPercentage: number
}

export type ServerExpaneInfoCategoryType = {
  id: number
  image: string
  titleEn: string
  titleUk: string
  titleRu: string
}

export type ServerExpensesReasonType = { id: number; name: string }

export type ServerInventoryType = {
  id: number
  inventoryDate: Date
  number: string
  storage: { id: number; name: string }
}

export type ServerInventoryProductType = {
  actualQuantity: number
  calculatedQuantity: number
  price: number
  product: {
    id: number
    name: string
    vendorCode: string | null
    unit: number
    price: number
    costPrice: number
  }
}

export type ServerInventoryByIdType = {
  id: number
  fulfilled: boolean
  inventoryDate: Date
  number: string
  storage: { id: number; name: string }
  movements: Array<{
    id: number
    type: number
    createdAt: Date
    movementProducts: Array<{ productId: number; quantity: number; product: { unit: number } }>
  }>
  inventoryProducts: Array<{
    actualQuantity: number
    calculatedQuantity: number
    price: number
    product: {
      id: number
      name: string
      vendorCode: string | null
      unit: number
      price: number
      costPrice: number
    }
  }>
}

export type ServerLeadType = { id: number; name: string }

export type ServerLocationBriefType = {
  id: number
  name: string
  maxEmployees: number
  maxClients: number
  serviceLocations: Array<{ serviceId: number }>
}

export type ServerLocationAllBranchesBriefType = { id: number; name: string; branchId: number }

export type ServerLocationByIdType = {
  id: number
  name: string
  groupId: number | null
  description: string | null
  maxClients: number
  maxEmployees: number
  archived: Date | null
  defaultForBranch: { id: number } | null
  locationGroup: { id: number; name: string } | null
}

export type ServerLocationWithServiceIdsType = {
  id: number
  name: string
  groupId: number | null
  description: string | null
  maxClients: number
  maxEmployees: number
  archived: Date | null
  serviceLocations: Array<{ serviceId: number }>
  defaultForBranch: { id: number } | null
  locationGroup: { id: number; name: string } | null
}

export type ServerLocationGroupType = {
  description: string | null
  id: number
  name: string
  archived: Date | null
}

export type ServerMovementType = {
  id: number
  type: number
  number: string
  description: string | null
  fulfilled: boolean
  transportCosts: number | null
  discount: number | null
  discountType: number | null
  createdAt: Date
  supplierId: number | null
  fromStorageId: number | null
  toStorageId: number | null
  transactions: Array<{ type: number; amount: number }>
  supplier: { name: string } | null
  fromStorage: { name: string } | null
  toStorage: { name: string } | null
  movementProducts: Array<{
    productId: number
    quantity: number
    price: number
    product: { id: number; name: string; unit: number }
  }>
  author: { firstName: string; lastName: string }
}

export type ServerMovementBriefType = {
  fromStorageId: number | null
  number: string
  type: number
  fulfilled: boolean
  supplier: { name: string } | null
  movementProducts: Array<{
    productId: number
    price: number
    discount: number | null
    quantity: number
    product: { id: number; name: string; unit: number; forSale: boolean }
  }>
}

export type ServerClientNotificationType = {
  id: number
  message: string
  sentAt: Date | null
  expoStatus: number | null
  telegramStatus: number | null
  viberStatus: number | null
  smsStatus: number | null
  author: { firstName: string; lastName: string } | null
}

export type ServerNotificationType = {
  id: number
  type: number
  typeVariation: number | null
  message: string
  sentAt: Date | null
  status: number | null
  bookingId: number | null
  waitingList: {
    service: { name: string; id: number }
    client: { firstName: string; lastName: string | null; id: number }
  } | null
  booking: {
    id: number
    duration: number
    startDate: Date
    clientNote: string | null
    branch: { name: string }
    client: { firstName: string; lastName: string | null; id: number } | null
    employee: { firstName: string; lastName: string } | null
    bookingServices: Array<{ service: { name: string } }>
  } | null
  review: {
    id: number
    rating: number
    text: string
    createdAt: Date
    approved: boolean
    archived: Date | null
    approvedByEmployee: { firstName: string; lastName: string; photo: string | null } | null
    client: { firstName: string; lastName: string | null; photo: string | null }
    booking: {
      isGroupBooking: boolean
      service: { name: string } | null
      bookingServices: Array<{ service: { name: string } }>
      employee: { firstName: string; lastName: string; photo: string | null } | null
    }
  } | null
}

export type ServerOnlineBookingUrlType = {
  id: number
  name: string
  description: string | null
  businessId: number
  branchId: number
  employeeId: number | null
  serviceId: number | null
  disableEmployeeSelection: boolean | null
}

export type ServerPlanType = {
  id: number
  name: string
  monthlyCost: number
  maxTurnover: number | null
  maxEmployees: number | null
}

export type ServerProductBriefType = {
  id: number
  name: string
  price: number
  groupId: number | null
  photo: string | null
  archived: Date | null
}

export type ServerProductType = {
  costPrice: number
  vendorCode: string | null
  description: string | null
  barcode: string | null
  forSale: boolean
  criticalQuantity: number
  unit: number
  id: number
  name: string
  price: number
  groupId: number | null
  photo: string | null
  archived: Date | null
  serviceProducts: Array<{ serviceId: number }>
  productGroup: { id: number; name: string } | null
}

export type ServerProductGroupType = {
  id: number
  name: string
  description: string | null
  parentId: number | null
  archived: Date | null
  products: Array<{
    costPrice: number
    vendorCode: string | null
    description: string | null
    barcode: string | null
    forSale: boolean
    criticalQuantity: number
    unit: number
    id: number
    name: string
    price: number
    groupId: number | null
    photo: string | null
    archived: Date | null
    serviceProducts: Array<{ serviceId: number }>
    productGroup: { id: number; name: string } | null
  }>
  productGroupEmployeeGroups: Array<{ employeeGroup: { id: number; name: string } }>
}

export type ServerProductGroupBriefNotArchivedType = {
  id: number
  name: string
  products: Array<{
    costPrice: number
    vendorCode: string | null
    description: string | null
    barcode: string | null
    forSale: boolean
    criticalQuantity: number
    unit: number
    id: number
    name: string
    price: number
    groupId: number | null
    photo: string | null
    archived: Date | null
    serviceProducts: Array<{ serviceId: number }>
    productGroup: { id: number; name: string } | null
  }>
}

export type ServerProductGroupBriefArchivedType = {
  id: number
  name: string
  products: Array<{
    costPrice: number
    vendorCode: string | null
    description: string | null
    barcode: string | null
    forSale: boolean
    criticalQuantity: number
    unit: number
    id: number
    name: string
    price: number
    groupId: number | null
    photo: string | null
    archived: Date | null
    serviceProducts: Array<{ serviceId: number }>
    productGroup: { id: number; name: string } | null
  }>
}

export type ServerPromoCodeType = {
  id: string
  createdAt: Date
  description: string | null
  endAt: Date | null
  term: number | null
  discount: number | null
  businessPromoCodes: Array<{ businessId: number }>
  referrer: { id: number; name: string; email: string } | null
}

export type ServerPromoCodeAsReferrerType = {
  id: string | null
  createdAt: Date | null
  description: string | null
  endAt: Date | null
  term: number | null
  discount: number | null
  businessPromoCodes: Array<{ businessId: number | null }>
  referrer: { id: number | null; name: string | null; email: string | null } | null
}

export type ServerRecurringBookingByIdType = {
  id: number
  startDate: Date
  status: number | null
  canceledDate: Date | null
  duration: number
  bookingServices: Array<{
    id: number
    service: {
      id: number
      name: string
      defaultDuration: number
      price: number
      costPrice: number
      groupId: number | null
      color: string | null
      picture: string | null
      type: number
      serviceProducts: Array<{ productId: number; quantity: number; product: { name: string } }>
    }
  }>
  bookingProducts: Array<{
    id: number
    productId: number
    quantity: number
    product: {
      id: number
      photo: string | null
      name: string
      price: number
      unit: number
      criticalQuantity: number
    }
  }>
  transactions: Array<{
    id: number
    amount: number
    type: number
    typeVariation: number | null
    clientId: number | null
    employeeId: number | null
    cardId: number | null
    date: Date
    parentId: number | null
    bookingId: number | null
    transactionsServices: Array<{
      serviceId: number
      price: number
      discount: number | null
      service: { name: string; serviceProducts: Array<{ productId: number; quantity: number }> }
    }>
    transactionsCards: Array<{
      cardId: number
      price: number
      card: { type: number; cardTemplate: { name: string } }
    }>
    movement: {
      fromStorageId: number | null
      number: string
      type: number
      fulfilled: boolean
      supplier: { name: string } | null
      movementProducts: Array<{
        productId: number
        price: number
        discount: number | null
        quantity: number
        product: { id: number; name: string; unit: number; forSale: boolean }
      }>
    } | null
    transactionReceipts: Array<{ receiptId: string }>
  }>
}

export type ServerReferrerType = {
  id: number
  name: string
  email: string
  percentageReward: number
  createdAt: Date
  maxDiscount: number
}

export type ServerReferrerAsReferrerType = {
  id: number | null
  name: string | null
  email: string | null
  percentageReward: number | null
  createdAt: Date | null
  maxDiscount: number | null
}

export type ServerRevenueReasonType = { id: number; name: string }

export type ServerBookingReviewType = {
  id: number | null
  rating: number | null
  text: string | null
  clientFirstName: string | null
  clientLastName: string | null
  clientPhoto: string | null
  createdAt: Date | null
  employee: { id: number; firstName: string; lastName: string } | null
}

export type ServerEmployeeReviewType = {
  employeeId: number | null
  firstName: string | null
  lastName: string | null
  quantity: number | null
  averageRating: number | null
}

export type ServerReviewType = {
  id: number
  rating: number
  text: string
  createdAt: Date
  approved: boolean
  archived: Date | null
  approvedByEmployee: { firstName: string; lastName: string; photo: string | null } | null
  client: { firstName: string; lastName: string | null; photo: string | null }
  booking: {
    isGroupBooking: boolean
    service: { name: string } | null
    bookingServices: Array<{ service: { name: string } }>
    employee: { firstName: string; lastName: string; photo: string | null } | null
  }
}

export type ServerReviewAsClientType = {
  id: number
  rating: number
  text: string
  createdAt: Date
  approved: boolean
  archived: Date | null
  booking: {
    isGroupBooking: boolean
    service: { name: string } | null
    bookingServices: Array<{ service: { name: string } }>
    employee: { firstName: string; lastName: string; photo: string | null } | null
  }
}

export type ServerRolePermissionType = { id: number; roleId: string; permissionId: string }

export type ServerSalaryIssueType = {
  id: number
  employeeId: number
  date: Date
  value: number
  valueWhenDependentOnRealized: number | null
  type: number
  comment: string | null
  startPeriod: Date | null
  endPeriod: Date | null
  salaryTransactionId: number | null
  salarySettingId: number | null
  paymentTransactionId: number | null
  salarySettingService: { service: { name: string; price: number; id: number } } | null
  paymentTransactionService: { price: number; service: { name: string } } | null
  paymentTransaction: {
    cardId: number | null
    client: { firstName: string; lastName: string | null } | null
  } | null
}

export type ServerSalarySettingType = {
  id: number
  employeeId: number
  rateType: number
  rateValue: number
  referralPercentage: number
  start: Date
  end: Date | null
  employee: { preferredPayment: number | null }
  salarySettingServices: Array<{
    id: number
    type: number
    value: number
    fallbackType: number | null
    fallbackValue: number | null
    dependsOnRealizedValue: boolean
    service: { id: number; name: string }
  }>
}

export type ServerSalaryRateSettingType = {
  id: number
  employeeId: number
  rateType: number
  rateValue: number
  referralPercentage: number
  start: Date
  end: Date | null
}

export type ServerLastSalarySettingType = { id: number; employeeId: number; rateType: number }

export type ServerScheduleType = {
  id: number
  name: string
  type: number
  data: any
  archived: Date | null
}

export type ServerEmployeeScheduleType = {
  id: number
  isDynamic: boolean
  startDate: Date
  endDate: Date | null
  employeeId: number
  schedule: { id: number; name: string; type: number; data: any; archived: Date | null } | null
  dynamicDates: Array<{ id: string; date: Date; timingStart: number; timingEnd: number }>
  branch: { id: number; name: string }
}

export type ServerInterbranchServiceByIdType = {
  id: number
  name: string
  defaultDuration: number
  description: string | null
  price: number
  costPrice: number
  picture: string | null
  groupId: number | null
  archived: Date | null
  type: number
  color: string | null
  availableForClient: boolean
  availableToAllBranches: boolean
  serviceEmployees: Array<{
    id: number
    branchId: number
    employee: { id: number; firstName: string; lastName: string; groupId: number | null }
  }>
  serviceLocations: Array<{ id: number; branchId: number; location: { id: number; name: string } }>
  serviceProducts: Array<{
    id: number
    productId: number
    quantity: number
    branchId: number
    product: {
      id: number
      photo: string | null
      name: string
      price: number
      unit: number
      criticalQuantity: number
      groupId: number | null
    }
  }>
  cardTemplateServices: Array<{ id: number }>
  bookingServices: Array<{
    booking: { branchId: number; locationId: number; employeeId: number | null }
  }>
  groupBookings: Array<{ locationId: number; employeeId: number | null; branchId: number }>
  transactionsServices: Array<{ id: number }>
}

export type ServerExtendedInterbranchServiceType = {
  defaultDuration: number
  description: string | null
  id: number
  name: string
  price: number
  costPrice: number
  picture: string | null
  groupId: number | null
  archived: Date | null
  type: number
  availableToAllBranches: boolean
  serviceEmployees: Array<{
    id: number
    employee: { id: number; firstName: string; lastName: string; groupId: number | null }
  }>
  serviceLocations: Array<{ location: { id: number; name: string } }>
  serviceProducts: Array<{
    id: number
    productId: number
    quantity: number
    product: {
      id: number
      photo: string | null
      name: string
      price: number
      unit: number
      criticalQuantity: number
      groupId: number | null
    }
  }>
  servicePermissions: Array<{ employee: { id: number } | null }>
  cardTemplateServices: Array<{ id: number }>
}

export type ServerServiceType = {
  id: number
  name: string
  type: number
  picture: string | null
  groupId: number | null
  color: string | null
  defaultDuration: number
  price: number
  availableForClient: boolean
  serviceEmployees: Array<{ employeeId: number }>
  serviceLocations: Array<{ locationId: number }>
  serviceGroup: { id: number; name: string } | null
}

export type ServerServiceBriefWithProductType = {
  id: number
  name: string
  defaultDuration: number
  price: number
  costPrice: number
  groupId: number | null
  color: string | null
  picture: string | null
  type: number
  serviceProducts: Array<{ productId: number; quantity: number; product: { name: string } }>
}

export type ServerServiceBriefWithEmployeeType = {
  id: number
  name: string
  picture: string | null
  type: number
  branchId: number | null
  serviceEmployees: Array<{ employeeId: number }>
}

export type ServerServiceBriefWithProductAndEmployeeType = {
  id: number
  name: string
  defaultDuration: number
  price: number
  costPrice: number
  groupId: number | null
  color: string | null
  picture: string | null
  type: number
  serviceEmployees: Array<{ employee: { id: number; firstName: string; lastName: string } }>
  serviceProducts: Array<{ productId: number; quantity: number; product: { name: string } }>
}

export type ServerExtendedServiceType = {
  defaultDuration: number
  description: string | null
  id: number
  name: string
  price: number
  costPrice: number
  picture: string | null
  groupId: number | null
  archived: Date | null
  type: number
  availableToAllBranches: boolean
  serviceEmployees: Array<{
    id: number
    employee: { id: number; firstName: string; lastName: string; groupId: number | null }
  }>
  serviceLocations: Array<{ location: { id: number; name: string } }>
  serviceProducts: Array<{
    id: number
    productId: number
    quantity: number
    product: {
      id: number
      photo: string | null
      name: string
      price: number
      unit: number
      criticalQuantity: number
      groupId: number | null
    }
  }>
  servicePermissions: Array<{ employee: { id: number } | null }>
  cardTemplateServices: Array<{ id: number }>
}

export type ServerServiceByIdType = {
  archived: Date | null
  color: string | null
  availableForClient: boolean
  defaultDuration: number
  description: string | null
  id: number
  name: string
  price: number
  costPrice: number
  picture: string | null
  groupId: number | null
  type: number
  availableToAllBranches: boolean
  servicePermissions: Array<{
    employee: {
      firstName: string
      lastName: string
      id: number
      employeeGroup: { id: number } | null
    } | null
  }>
  bookingServices: Array<{ booking: { locationId: number; employeeId: number | null } }>
  groupBookings: Array<{ locationId: number; employeeId: number | null }>
  transactionsServices: Array<{ id: number }>
  serviceEmployees: Array<{
    id: number
    employee: { id: number; firstName: string; lastName: string; groupId: number | null }
  }>
  serviceLocations: Array<{ location: { id: number; name: string } }>
  serviceProducts: Array<{
    id: number
    productId: number
    quantity: number
    product: {
      id: number
      photo: string | null
      name: string
      price: number
      unit: number
      criticalQuantity: number
      groupId: number | null
    }
  }>
  cardTemplateServices: Array<{ id: number }>
}

export type ServerAnonymouslyServiceType = {
  id: number
  name: string
  description: string | null
  type: number
  price: number
  defaultDuration: number
  picture: string | null
  groupId: number | null
  availableForClient: boolean
  serviceEmployees: Array<{ employeeId: number }>
  serviceLocations: Array<{ locationId: number }>
  serviceGroup: { id: number; name: string } | null
}

export type ServerServiceGroupType = {
  description: string | null
  id: number
  name: string
  parentId: number | null
  archived: Date | null
  services: Array<{ id: number; name: string; archived: Date | null }>
}

export type ServerSoftwarePosType = {
  id: number
  name: string
  login: string
  password: string
  token: string | null
  licenseKey: string
  authorId: number
  accountSoftwarePOSs: Array<{ id: number; accountId: number }>
}

export type ServerAccountSoftwarePosType = { id: number; accountId: number; softwarePOSId: number }

export type ServerStorageType = {
  id: number
  name: string
  description: string | null
  storageEmployeeGroups: Array<{ employeeGroup: { id: number; name: string } }>
}

export type ServerSubscriptionPeriodType = {
  period: string
  months: number
  additionalMonths: number
}

export type ServerSupplierBriefType = {
  id: number
  name: string
  phone: string
  email: string | null
  webSite: string | null
  note: string | null
  addressId: number | null
}

export type ServerSupplierBriefForImportType = {
  id: number
  name: string
  phone: string
  email: string | null
  webSite: string | null
  note: string | null
  addressId: number | null
  address: {
    postcode: string | null
    country: string
    city: string
    street: string
    house: string
    office: string | null
  } | null
}

export type ServerSupplierBriefWithMovementsType = {
  id: number
  name: string
  phone: string
  email: string | null
  webSite: string | null
  note: string | null
  addressId: number | null
  movements: Array<{
    discount: number | null
    discountType: number | null
    transportCosts: number | null
    movementProducts: Array<{ quantity: number; price: number; product: { unit: number } }>
    transactions: Array<{ type: number; amount: number }>
  }>
}

export type ServerAddressType = {
  id: number
  postcode: string | null
  country: string
  city: string
  street: string
  house: string
  office: string | null
}

export type ServerBankDetailsType = {
  id: number
  name: string
  edrpou: string
  itn: string | null
  vatCertificate: string | null
  accountType: number | null
  accountNumber: string | null
  bic: string
  iban: string
}

export type ServerSupplierContactFacesType = {
  id: number
  firstName: string
  lastName: string | null
  middleName: string | null
  phone: string
  email: string | null
  note: string | null
}

export type ServerSupplierType = {
  id: number
  name: string
  phone: string
  email: string | null
  webSite: string | null
  note: string | null
  addressId: number | null
  address: {
    id: number
    postcode: string | null
    country: string
    city: string
    street: string
    house: string
    office: string | null
  } | null
  bankDetails: {
    id: number
    name: string
    edrpou: string
    itn: string | null
    vatCertificate: string | null
    accountType: number | null
    accountNumber: string | null
    bic: string
    iban: string
  } | null
  supplierContactFaces: Array<{
    id: number
    firstName: string
    lastName: string | null
    middleName: string | null
    phone: string
    email: string | null
    note: string | null
  }>
}

export type ServerMovementInSupplierMutualSettlementType = {
  id: number
  createdAt: Date
  number: string
  transportCosts: number | null
  discount: number | null
  discountType: number | null
  type: number
  description: string | null
  movementProducts: Array<{ quantity: number; price: number; product: { unit: number } }>
  transactions: Array<{
    id: number
    type: number
    amount: number
    date: Date
    comment: string | null
  }>
}

export type ServerSupplierMutualSettlementType = {
  id: number
  name: string
  phone: string
  email: string | null
  webSite: string | null
  note: string | null
  addressId: number | null
  movements: Array<{
    id: number
    createdAt: Date
    number: string
    transportCosts: number | null
    discount: number | null
    discountType: number | null
    type: number
    description: string | null
    movementProducts: Array<{ quantity: number; price: number; product: { unit: number } }>
    transactions: Array<{
      id: number
      type: number
      amount: number
      date: Date
      comment: string | null
    }>
  }>
}

export type ServerTagBriefType = { id: number; name: string; color: string }

export type ServerTagType = {
  description: string | null
  archived: Date | null
  id: number
  name: string
  color: string
  clientTags: Array<{ client: { firstName: string; lastName: string | null } }>
}

export type ServerTimeOffBriefType = { id: number; start: Date; end: Date }

export type ServerTimeOffType = {
  employeeId: number
  timeOffReasonId: number
  id: number
  start: Date
  end: Date
  employee: { firstName: string; lastName: string }
  timeOffReason: { name: string }
}

export type ServerTimeOffByIdType = {
  employeeId: number
  timeOffReasonId: number
  id: number
  start: Date
  end: Date
  author: { firstName: string; lastName: string }
  employee: { firstName: string; lastName: string }
  timeOffReason: { name: string }
}

export type ServerTimeOffReasonType = {
  id: number
  name: string
  timeOffReasonEmployeeGroups: Array<{ employeeGroupId: number; employeeGroup: { name: string } }>
}

export type ServerTransactionBriefType = {
  id: number
  amount: number
  date: Date
  type: number
  typeVariation: number | null
  employeeId: number | null
}

export type ServerTransactionByIdType = {
  bookingId: number | null
  comment: string | null
  cardId: number | null
  parentId: number | null
  startPeriod: Date | null
  endPeriod: Date | null
  id: number
  amount: number
  date: Date
  type: number
  typeVariation: number | null
  employeeId: number | null
  booking: { status: number | null } | null
  transactionsServices: Array<{
    price: number
    discount: number | null
    service: {
      id: number
      name: string
      serviceProducts: Array<{ productId: number; quantity: number }>
    }
  }>
  toAccount: { id: number; name: string } | null
  fromAccount: { id: number; name: string } | null
  client: { id: number; firstName: string; lastName: string | null } | null
  employee: { lastName: string; firstName: string; id: number } | null
  author: { lastName: string; firstName: string } | null
  revenueReason: { name: string } | null
  expensesReason: { name: string } | null
  transactionsCards: Array<{
    price: number
    discount: number | null
    card: {
      type: number
      id: number
      activatedAt: Date | null
      cardTemplate: { id: number; name: string; price: number; type: number }
    }
  }>
  movement: {
    fromStorageId: number | null
    number: string
    type: number
    fulfilled: boolean
    supplier: { name: string } | null
    movementProducts: Array<{
      productId: number
      price: number
      discount: number | null
      quantity: number
      product: { id: number; name: string; unit: number; forSale: boolean }
    }>
  } | null
  transactionReceipts: Array<{ receiptId: string }>
}

export type ServerTransactionByEmployeeIdType = {
  id: number
  date: Date
  amount: number
  type: number
  cardId: number | null
  transactionsServices: Array<{ price: number; service: { name: string } }>
  transactionsCards: Array<{
    price: number
    card: {
      type: number
      id: number
      cardTemplate: { id: number; name: string; price: number; type: number }
    }
  }>
}

export type ServerTransactionToCalcBalanceType = {
  id: number
  amount: number
  type: number
  typeVariation: number | null
  date: Date
  fromAccountId: number | null
  toAccountId: number | null
}

export type ServerTransactionBriefWithCardsType = {
  parentId: number | null
  cardId: number | null
  id: number
  amount: number
  date: Date
  type: number
  typeVariation: number | null
  employeeId: number | null
  client: { id: number } | null
  transactionsCards: Array<{
    price: number
    discount: number | null
    card: {
      type: number
      id: number
      cardTemplate: { id: number; name: string; price: number; type: number }
    }
  }>
}

export type ServerTransactionBriefWithMovementsType = {
  parentId: number | null
  id: number
  amount: number
  date: Date
  type: number
  typeVariation: number | null
  employeeId: number | null
  client: { id: number } | null
  movement: {
    fromStorageId: number | null
    number: string
    type: number
    fulfilled: boolean
    supplier: { name: string } | null
    movementProducts: Array<{
      productId: number
      price: number
      discount: number | null
      quantity: number
      product: { id: number; name: string; unit: number; forSale: boolean }
    }>
  } | null
}

export type ServerTransactionBriefWithClientType = {
  cardId: number | null
  clientId: number | null
  id: number
  amount: number
  date: Date
  type: number
  typeVariation: number | null
  employeeId: number | null
  client: { id: number } | null
}

export type ServerTransactionType = {
  toAccountId: number | null
  fromAccountId: number | null
  parentId: number | null
  cardId: number | null
  clientId: number | null
  employeeId: number | null
  typeVariation: number | null
  startPeriod: Date | null
  endPeriod: Date | null
  bookingId: number | null
  id: number
  amount: number
  date: Date
  type: number
  fromAccount: { id: number; name: string } | null
  toAccount: { id: number; name: string } | null
  author: { firstName: string; lastName: string } | null
  transactionsCards: Array<{
    price: number
    discount: number | null
    card: {
      type: number
      id: number
      cardTemplate: { id: number; name: string; price: number; type: number }
    }
  }>
  client: { id: number; firstName: string; lastName: string | null } | null
  employee: { lastName: string; firstName: string; id: number; groupId: number | null } | null
  transactionsServices: Array<{
    price: number
    discount: number | null
    costPrice: number
    service: { name: string; id: number; price: number; type: number }
  }>
  revenueReason: { id: number; name: string } | null
  expensesReason: { id: number; name: string } | null
  movement: {
    fromStorageId: number | null
    number: string
    type: number
    fulfilled: boolean
    supplier: { name: string } | null
    movementProducts: Array<{
      productId: number
      price: number
      discount: number | null
      quantity: number
      product: { id: number; name: string; unit: number; forSale: boolean }
    }>
  } | null
}

export type ServerTransactionInBookingType = {
  id: number
  amount: number
  type: number
  typeVariation: number | null
  clientId: number | null
  employeeId: number | null
  cardId: number | null
  date: Date
  parentId: number | null
  bookingId: number | null
  transactionsServices: Array<{
    serviceId: number
    price: number
    discount: number | null
    service: { name: string; serviceProducts: Array<{ productId: number; quantity: number }> }
  }>
  transactionsCards: Array<{
    cardId: number
    price: number
    card: { type: number; cardTemplate: { name: string } }
  }>
  movement: {
    fromStorageId: number | null
    number: string
    type: number
    fulfilled: boolean
    supplier: { name: string } | null
    movementProducts: Array<{
      productId: number
      price: number
      discount: number | null
      quantity: number
      product: { id: number; name: string; unit: number; forSale: boolean }
    }>
  } | null
  transactionReceipts: Array<{ receiptId: string }>
}

export type ServerTransactionSalaryType = {
  id: number
  amount: number
  type: number
  startPeriod: Date | null
  endPeriod: Date | null
  employeeId: number | null
  date: Date
  comment: string | null
  employee: { lastName: string; firstName: string; id: number; groupId: number | null } | null
}

export type ServerTransactionBriefWithBookingLocationType = {
  id: number
  amount: number
  date: Date
  type: number
  typeVariation: number | null
  bookingId: number | null
  booking: { location: { id: number } } | null
}

export type ServerWaitingListType = {
  id: number
  createdAt: Date
  description: string | null
  dates: any
  service: { id: number; name: string }
  employee: { id: number; firstName: string; lastName: string } | null
  client: { id: number; firstName: string; lastName: string | null }
  responsibleEmployees: Array<{
    employee: { id: number; firstName: string; lastName: string; groupId: number | null }
  }>
}
